<template>
<div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation 
        :id="$appNavigation.currentOptionMenuId" 
        :title="(sub ? 'SUB ' : '') + 'Interface Tables bearbeiten'"
        :actions="headerActions" 
        @action-SAVE="save" 
    />

    <div class="box__container">
        <div class="box__title">
            Daten
        </div>
        <InputField v-if="id != null" v-model="id" disabled label="ID"/>
        <InputField v-model="form.interfaceName" :disabled="!interfaceEditEnable" label="Interfacename"/>
        <InputCheckBoxGroup 
            :values="[
                { label: 'fehlerhafte Datensätze beim automatischen Einlesen im System belassen', path: 'keepErrors'},
                { label: 'fehlerhafte Datensätze werden nicht in \'Dateien in Verarbeitung\' angezeigt', path: 'hideErrors'},
                { label: 'Mehrfachauswahl', path: 'multiple'},
                { label: 'Interface ist aktiv', path: 'active'},
                { label: 'Interface ist nicht sichtbar', path: 'invisible'},
                { label: 'Interf. liest UNICODE (keine Auswirkungen aufs manuelle Datei Einlesen)', path: 'unicode'},
            ]"
            :value="form"
        />
        <ComboBox v-if="gesellschaftOptions" v-model="form.gesellschaft" :values="gesellschaftOptions" :disabled="!interfaceEditEnable" label="Gesellschaft"/>
        <InputTextArea v-model="form.checkStatement" :disabled="!interfaceEditEnable" label="Check Statement" autoGrow :rows="3"/>
        <InputTextArea v-model="form.preFileCheckStatement" :disabled="!interfaceEditEnable" label="Pre File Check Statement" autoGrow :rows="3"/>
        <InputTextArea v-model="form.description" :disabled="!interfaceEditEnable" label="Beschreibung" autoGrow :rows="3"/>
        <InputToggleSwitch v-model="form.xml" :disabled="!interfaceEditEnable" label="ist XML-Interface"/>
        <InputField v-if="form.xml" v-model="form.xPath" :disabled="!interfaceEditEnable" label="XPath"/>
        <InputField v-if="form.xml" v-model="form.nameCheck" :disabled="!interfaceEditEnable" label="Namecheck"/>
    </div>
    <div v-if="form.multiple" class="box__container">
        <span v-if="loading || !multiRows.length" class="box__title">
            Multitab
        </span>
        <BaseButton v-if="id != null" :disabled="!interfaceEditEnable" @click="newMulti" style="float: right; margin-bottom: 0">
            Neue Daten anlegen
        </BaseButton>
        <GhostLoading v-if="loading" type="table"/>
        <Table v-else-if="multiRows.length" style="clear: both;"
            tableId="6adb2f8c-388c-4eb0-990e-1b7806c52511"
            title="Multitab"
            :headers="multiHeaders"
            :rows="multiRows"
            rowId="lfdno"
            :rowsPerPage="20"
            @action-OPEN="actionOpenMulti"
            @action-EDIT="actionEditMulti"
            @action-DELETE="deleteMulti"
        />
        <NoData v-else/>
    </div>


    <div class="box__container">
        <span v-if="loading || !felderRows.length" class="box__title">
            Felder
        </span>
        <BaseButton v-if="id != null" :disabled="!interfaceEditEnable" @click="newFeld" style="float: right; margin-bottom: 0">
            Neues Feld anlegen
        </BaseButton>
        <GhostLoading v-if="loading" type="table"/>
        <Table v-else-if="felderRows.length" style="clear: both;"
            tableId="6adb2f8c-388c-4eb0-990e-1b7806c52512"
            title="Felder"
            :headers="felderHeaders"
            :rows="felderRows"
            rowId="name"
            :rowsPerPage="20"
            @action-EDIT="actionEditFeld"
            @action-DELETE="deleteFeld"
        />
        <NoData v-else/>
    </div>

    <InterfaceMultiModal v-if="multiEdit" :interfaceId="id" :multi="multiEdit" @close="multiEdit = null; reloadTables()"/>
    <InterfaceFeldModal v-if="feldEdit" :interfaceId="id" :feld="feldEdit" @close="feldEdit = null; reloadTables()"/>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import BIPRO_TYPES from '@/store/bipro/types'

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InterfaceMultiModal from '@/components/bipro/InterfaceMultiModal.vue';
import InterfaceFeldModal from '@/components/bipro/InterfaceFeldModal.vue';

import {TextColumn, NumberColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import axios from 'axios';
import { mapGetters } from 'vuex';
import {PhPencilLine, PhArrowFatRight, PhTrash} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const axiosConfig = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        InputToggleSwitch,
        InputCheckBoxGroup,
        ComboBox,
        InputField,
        InputTextArea,
        Table,
        GhostLoading,
        NoData,
        BaseButton,
        InterfaceMultiModal,
        InterfaceFeldModal,
    },
    data() {
        return {
            loading: false,
            id: null,
            sub: false,
            form: {},
            multiHeaders: {
                lockedLeft: [
                    //NumberColumn("id", "ID").addCellProps({separator: ""}).alignLeft(),
                    NumberColumn("lfdno", "Lfd. Nr.").addCellProps({separator: ""}).alignLeft(),
                ],
                center: [
                    NumberColumn("newItabSid", "Neue ID"),
                    TextColumn("tableName", "Neues Interface"),
                    TextColumn("checkStatement", "Check Statement"),
                    TextColumn("description", "Beschreibung"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            felderHeaders: {
                lockedLeft: [
                    TextColumn("name", "Feldname"),
                ],
                center: [
                    TextColumn("conversion", "Konvertierungsstring"),
                    TextColumn("art", "Art"),
                    TextColumn("delimiter", "Trennzeichen"),
                    NumberColumn("occurance", "Feld Nr."),
                    NumberColumn("position", "Position"),
                    NumberColumn("len", "Feldlänge"),
                    TextColumn("selection", "XPath"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            multiEdit: null,
            feldEdit: null,
        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS_INTERFACE,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('SAVE', 'Speichern')
                    .withDisabled(() => !this.interfaceEditEnable),
            ];
        },
        interfaceEditEnable() {
            return this.isIntern && !this.loading;
        },
        multiRows() {
            return (this.form?.multiInterfaces || []).map(row => {
                const actions = [];
                actions.push(SimpleAction("OPEN", PhArrowFatRight, "Interface öffnen"));
                actions.push(SimpleAction("EDIT", PhPencilLine, this.interfaceEditEnable ? "Interface editieren" : "Interface anschauen"));
                if (this.interfaceEditEnable) {
                    actions.push(ConfirmedAction("DELETE", PhTrash, "Daten löschen", "Soll das Multiinterface Nr. " + row.lfdno + ", " + row.tableName + " wirklich gelöscht werden?", "Multiinterface löschen"));
                }
                return {
                    ...row,
                    raw: row,
                    actions,
                };
            });
        },
        felderRows() {
            return (this.form?.felder || []).map(row => {
                const actions = [];
                actions.push(SimpleAction("EDIT", PhPencilLine, this.interfaceEditEnable ? "Daten editieren" : "Daten anschauen"));
                if (this.interfaceEditEnable) {
                    actions.push(ConfirmedAction("DELETE", PhTrash, "Daten löschen", "Soll das Feld " + row.name + " wirklich gelöscht werden?", "Feld löschen"));
                }
                let art;
                switch (row.art) {
                    case 0: art = "Trenner"; break;
                    case 1: art = "Position"; break;
                    default: art = "XML"; break;
                }
                return {
                    ...row,
                    raw: row,
                    art,
                    actions,
                };
            });
        },
    },
    watch: {
        '$route.query.id': {
            handler: function(id) {
                this.id = parseInt(id);
                this.sub = !!this.$route.query.sub;
                this.loadData(false);
            }
        }
    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS_INTERFACE);
        if (this.$route.query.id != undefined) {
            this.id = parseInt(this.$route.query.id);
            this.loadData(false);
        }
    },
    methods: {
        loadData(onlyTables) {
            if (this.id == null)
                return;
            this.loading = true;
            try {
                axios.get(`${process.env.VUE_APP_API}/gdv_interface/interface?id=${this.id}`, axiosConfig)
                .then(response => {
                    if (onlyTables) {
                        this.form.multiInterfaces = response.data.multiInterfaces;
                        this.form.felder = response.data.felder;
                    } else {
                        this.form = response.data;
                    }
                }).catch(error => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Beim Laden der Daten ist ein Fehler aufgetreten.', 'danger'));
                })
            } finally {
                this.loading = false;
            }
        },
        reloadTables() {
            this.loadData(true);
        },
        save() {
            this.loading = true;
            try {
                let url = `${process.env.VUE_APP_API}/gdv_interface/interface`;
                if (this.id != null)
                    url += `?id=${this.id}`;
                axios.post(url, this.form, axiosConfig)
                .then(response => {
                    this.id = response.data;
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Das Interface wurde erfolgreich gespeichert.', 'success'));
                })
            } finally {
                this.loading = false;
            }
        },
        newMulti() {
            this.multiEdit = {};
        },
        actionOpenMulti(row) {
            this.multiEdit = row.raw;
        },
        actionEditMulti(row) {
            this.$addBreadcrumb({
                label: 'zurück zum vorherigen Interface',
                breadcrumb: 'SUB Interface',
            });
            this.$router.push({path: "/intern/gdv/gdv-interface", query: {id: row.newItabSid, sub: true}});
        },
        deleteMulti(row) {
            axios.delete(`${process.env.VUE_APP_API}/gdv_interface/delete_multi?interface=${this.id}&lfdno=${row.lfdno}`, axiosConfig)
            .then(_ => {
                this.reloadTables();
            })
        },
        newFeld() {
            this.feldEdit = {
                art: 0,
            };
        },
        actionEditFeld(row) {
            this.feldEdit = row.raw;
        },
        deleteFeld(row) {
            axios.delete(`${process.env.VUE_APP_API}/gdv_interface/delete_feld?interface=${this.id}&name=${row.name}`, axiosConfig)
            .then(_ => {
                this.reloadTables();
            })
        },
    },
}
</script>
