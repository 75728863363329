var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Vermögensverwaltung"
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("VVWertpapiereList", {
            attrs: {
              positions: _vm.positions,
              schemaId: _vm.schemaId,
              isLoading: _vm.isLoading
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }