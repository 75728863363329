var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.navigateTo("new")
          }
        }
      }),
      _c(
        "div",
        [
          !_vm.scenarios ||
          !_vm.scenarios.allRetirementScenarios ||
          !_vm.isBalanceLoaded ||
          !_vm.retirementYearSet
            ? _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col col-md-6" },
                    [
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          useBoxContainer: "",
                          config: { block: { height: "250px" } }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-md-6" },
                    [
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          useBoxContainer: "",
                          config: { block: { height: "250px" } }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm.rows.length
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.scenariosList, function(scenario, i) {
                  return _c("div", { key: i, staticClass: "col col-md-6" }, [
                    _c("div", { staticClass: "box__container" }, [
                      _c("div", { staticClass: "box__title" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.goToScenario(scenario.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scenario.name) +
                                " | " +
                                _vm._s(scenario.updatedDate1) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _vm.chartsData[i] && _vm.chartsData[i].apexSeries
                            ? _c("RetirementChart", {
                                attrs: {
                                  data: {
                                    data: _vm.chartsData[i].apexSeries,
                                    series: _vm.chartsData[i].apexSeries,
                                    xaxis: _vm.chartsData[i].apexXaxis,
                                    annotations:
                                      _vm.chartsData[i].apexAnnotations,
                                    markers: _vm.chartsData[i].apexMarkers,
                                    dataLabels: { enabled: false },
                                    stroke: { curve: "smooth" },
                                    autoUpdateSeries: true
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                }),
                0
              )
            : _c("NoData")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "hidden" },
        [
          _c("BalanceSheetView", {
            attrs: {
              isSelectableView: true,
              selectedItems: _vm.selectedBalance
            },
            on: {
              setBalanceItems: _vm.setBalanceItems,
              setBalance: _vm.setBalance,
              init: _vm.setBalance,
              selectedItems: _vm.onbalanceselectedItems,
              balanceSum: _vm.setAllBalance
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }