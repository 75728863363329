<template>
  <div></div>
</template>

<script>
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types';
import store from '@/store';

/**
 * This component is only to open the Vergleichsrechner item links
 */
export default {
  async beforeRouteEnter(to, from, next) {
    const path = to?.query?.path;
    await store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path });
    next(false);
  },
}
</script>
