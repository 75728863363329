var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.title, id: _vm.$appNavigation.currentOptionMenuId }
      }),
      _c("div", { staticClass: "flex-container mb-4" }, [
        _c(
          "div",
          { staticClass: "flex-table-item mr-4" },
          [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: Object.keys(_vm.labels).length
                  }
                })
              : _vm.values && Object.keys(_vm.values).length > 0
              ? _c(
                  "div",
                  [
                    _vm.isBeratungsmappe
                      ? _c("dl", { staticClass: "key-value--item" }, [
                          _c("dt", { staticClass: "value--item-label" }, [
                            _vm._v("Mappenstatus:")
                          ]),
                          _c("dd", { staticClass: "value--item-value" }, [
                            this.eingegangenesDokument.beratungsmappeHasError
                              ? _c("span", { staticClass: "color-danger" }, [
                                  _vm._v("Negativ")
                                ])
                              : _c("span", { staticClass: "color-success" }, [
                                  _vm._v("Positiv")
                                ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.isBeratungsmappe
                      ? _c("dl", { staticClass: "key-value--item" }, [
                          _c("dt", { staticClass: "value--item-label" }, [
                            _vm._v("Anlegerprofil:")
                          ]),
                          _c("dd", { staticClass: "value--item-value" }, [
                            this.eingegangenesDokument.beratungsmappeIsAPCorrect
                              ? _c("span", { staticClass: "color-success" }, [
                                  _vm._v("Fehlerfrei")
                                ])
                              : _c("span", { staticClass: "color-danger" }, [
                                  _vm._v("Fehlt/fehlerhaft")
                                ])
                          ])
                        ])
                      : _vm._e(),
                    _c("dl", { staticClass: "key-value--item" }, [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v("Rahmenvereinbarung:")
                      ]),
                      _c("dd", { staticClass: "value--item-value" }, [
                        this.eingegangenesDokument.RAHMENVEREINBARUNG
                          ? _c("span", { staticClass: "color-success" }, [
                              _vm._v("Ja")
                            ])
                          : _c("span", { staticClass: "color-danger" }, [
                              _vm._v("Nein")
                            ])
                      ])
                    ]),
                    _c("dl", { staticClass: "key-value--item" }, [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v("Informationsblatt:")
                      ]),
                      _c("dd", { staticClass: "value--item-value" }, [
                        this.eingegangenesDokument.INFORMATIONSBLATT
                          ? _c("span", { staticClass: "color-success" }, [
                              _vm._v("Ja")
                            ])
                          : _c("span", { staticClass: "color-danger" }, [
                              _vm._v("Nein")
                            ])
                      ])
                    ]),
                    _c("dl", { staticClass: "key-value--item" }, [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v("Ausweis vorhanden:")
                      ]),
                      _c("dd", { staticClass: "value--item-value" }, [
                        this.eingegangenesDokument
                          .LEGITIMIERTER_AUSWEIS_VORHANDEN
                          ? _c("span", { staticClass: "color-success" }, [
                              _vm._v("Ja")
                            ])
                          : _c("span", { staticClass: "color-danger" }, [
                              _vm._v("Nein")
                            ])
                      ])
                    ]),
                    _vm._l(_vm.labels, function(label, key) {
                      return _c("dl", { staticClass: "key-value--item" }, [
                        _c("dt", { staticClass: "value--item-label" }, [
                          _vm._v(_vm._s(label + ": "))
                        ]),
                        _c(
                          "dd",
                          {
                            staticClass: "value--item-value",
                            class: {
                              link:
                                !!_vm.values[key].onClick &&
                                !!_vm.values[key].text
                            },
                            on: {
                              click: function($event) {
                                _vm.values[key].onClick && _vm.values[key].text
                                  ? _vm.values[key].onClick()
                                  : function() {}
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.values[key].text
                                  ? _vm.values[key].text
                                  : "-"
                              )
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-legidocs-item" },
          [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: { type: "block", config: { block: { height: 100 } } }
                })
              : _c(
                  "div",
                  [
                    _c("LegiDocsView", {
                      attrs: {
                        signatureAuthor: _vm.signatureAuthor,
                        kunde: _vm.kunde ? _vm.kunde.person : null,
                        zusaetzlichePersonen: _vm.zusaetzlichePersonen
                      }
                    })
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              repeat: 3,
              type: "block",
              config: { block: { height: 100 } }
            }
          })
        : _c("DokumenteVergleichCards", {
            staticClass: "mb-4",
            on: {
              signatureAuthor: function($event) {
                return _vm.onNewSignatureAuthor($event)
              }
            }
          }),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c("BaseButton", {
            attrs: { label: "Weiter", isSecondary: "" },
            on: { click: _vm.goToNext }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }