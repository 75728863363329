var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "648",
        height: "648",
        viewBox: "0 0 648 648",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M648,324c0,21.63-2.12012,42.76996-6.16992,63.20996-1.26001,6.41003-2.72021,12.75-4.36011,19.02002-3.23999,12.41998-7.20996,24.53998-11.84985,36.33002-.63013,1.62-1.28003,3.22998-1.9502,4.83997-1.6499,4.01001-3.37988,7.98004-5.18994,11.90002-.95996,2.09998-1.94995,4.19-2.96997,6.27002-3.81006,7.84998-7.93994,15.52997-12.36987,23.00995-5.03027,8.52002-10.44019,16.80005-16.22021,24.79004-10.33984,14.33997-21.82983,27.79999-34.32983,40.22998-58.59009,58.35004-139.38013,94.40002-228.59009,94.40002-89.62,0-170.75-36.39001-229.40002-95.20001-12.08997-12.12-23.22998-25.19-33.27991-39.09998-11.59009-16.01001-21.75-33.13-30.29004-51.17004-1.41003-2.95996-2.77002-5.95001-4.08008-8.95996-.16992-.37-.31995-.73004-.47998-1.10004-2.01001-4.63-3.8999-9.32001-5.68994-14.06-1.6001-4.23999-3.12012-8.52997-4.54004-12.85999-2.09998-6.35999-4.01001-12.79999-5.70996-19.33002-1.38-5.26001-2.63-10.57996-3.73999-15.94C2.75,370.87,.44995,350.82996,.07007,330.32001c-.05005-2.10004-.07007-4.21002-.07007-6.32001,0-9.47003,.40991-18.84003,1.19995-28.10004,.28003-3.21997,.6001-6.42999,.96997-9.62,4.09009-35.27997,13.8501-68.82996,28.34009-99.71997,3.72998-7.95001,7.77002-15.72003,12.10999-23.29999,16.05994-28.05005,36.19006-53.47003,59.58997-75.44,3.27002-3.08002,6.6001-6.08002,10-9.01001C169,29.70001,243.03003,0,324,0c178.93994,0,324,145.06,324,324Z",
          fill: "#f2f2f2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "477.38951",
          y: "233.04743",
          width: "12.11047",
          height: "85.98428",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M489.53003,231.95996l-.43994,1.09003-4.12012,10.13-7.57996-3.08002-10.90002-4.41998c-67.65076-21.71457-153.15125-32.82602-185.71997-75.38l-2.12-.85999c-13.62872,11.30832-23.09283,11.96948-23.82996-9.67999l-2.12012-.86005c-55.58493,11.42871-103.65475-21.20618-150.48999-61.07996,3.27002-3.08002,6.6001-6.08002,10-9.01001,65.84491,1.84599,121.995,13.27042,143.98999,58.44l2.13013,.86999,25.58984,10.38,2.14014,.87c56.84302-11.98691,120.70392,31.22052,184.62988,74.94l18.84009,7.64996Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M586.91992,513.37c-10.33984,14.33997-21.82983,27.79999-34.32983,40.22998-144.05005,2.81-317.64014,2.46002-457.99011-.79999-12.08997-12.12-23.22998-25.19-33.27991-39.09998,29.1499-.81,60.10986-1.45001,92.21997-1.95001,12.70996-.21002,25.60999-.38,38.6499-.54004,12.95007-.13995,26.04004-.26996,39.22998-.38h.01001c119.8501-.96997,248.05005-.07996,355.48999,2.54004Z",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M637.46997,406.22998c-3.23999,12.41998-7.20996,24.53998-11.84985,36.33002-.63013,1.62-1.28003,3.22998-1.9502,4.83997h-15.48999v-41.16998h29.29004Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M625.62012,442.56c-.63013,1.62-1.28003,3.22998-1.9502,4.83997-1.6499,4.01001-3.37988,7.98004-5.18994,11.90002-.95996,2.09998-1.94995,4.19-2.96997,6.27002-3.81006,7.84998-7.93994,15.52997-12.36987,23.00995-154.7796,18.03448-298.02216,22.35608-418.82007,0v-46.01996h441.30005Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M618.47998,387.83997v71.46002c-.95996,2.09998-1.94995,4.19-2.96997,6.27002-43.96039-21.4632-84.14825-18.90271-121.77002,0v-156.83002c30.45642-18.20798,61.43658-21.20496,93.25,0l29.22998,73.40997,2.26001,5.69Z",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M513.11542,318.42618h53.28599l.00002,53.28604c-17.56921,9.38489-35.33527,9.18454-53.28601,0-9.25635-17.76202-9.25635-35.52402,0-53.28604Z",
          fill: "#fff"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M606.3656,372.31802c-7.0033,7.88394-19.84515,5.06235-33.909,0l-.00002-53.28597h14.53296l19.37605,53.28597Z",
          fill: "#fff"
        }
      }),
      _c("rect", {
        attrs: {
          x: "515.53746",
          y: "401.98838",
          width: "12.11047",
          height: "23.00989",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M582.75053,352.94105h.60553c6.354,0,11.50494,5.15091,11.50494,11.50491v.00003h0c0,6.354-5.15094,11.50494-11.50494,11.50494h-.60553v-23.00989h0Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M132.24132,442.55845h.60553c6.354,0,11.50494,5.15091,11.50494,11.50491v.00003h0c0,6.354-5.15091,11.50494-11.50491,11.50494h-.60556v-23.00989h0Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M641.83008,387.20996c-1.26001,6.41003-2.72021,12.75-4.36011,19.02002-3.23999,12.41998-7.20996,24.53998-11.84985,36.33002-.63013,1.62-1.28003,3.22998-1.9502,4.83997-1.6499,4.01001-3.37988,7.98004-5.18994,11.90002-.95996,2.09998-1.94995,4.19-2.96997,6.27002h-5.51001l-.6499-18.17004-.17017-4.83997-1-28.19-1.20996-34.04004,9.25,1.82001,25.61011,5.06Z",
          fill: "#d0cde1"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "192.18812",
          cy: "486.76164",
          r: "46.01974",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "192.18812",
          cy: "486.76164",
          r: "24.22092",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M497.37183,437.1087h-.00006v-150.16974h-25.43195c-5.04586,9.51031-7.60763,23.97249-8.51999,41.72262-3.11813,60.66385-52.78591,108.44712-113.52984,108.44712H131.6358v10.89941H469.77631c.7168,7.45941,1.44592,14.94348,2.16351,22.40442l25.43195-.00006v-22.40436h.00006v-10.89941Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M300.71417,386.83661l6.8311-5.02004,41.95916,57.09675-6.83109,5.02002c-31.51196-3.88904-45.3515-23.04822-41.95917-57.09674Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M370.63379,193.78165l7.74572,3.44502-83.79469,246.74161-7.74571-3.44502c10.38477-67.61377,42.2923-191.32068,83.79468-246.74161Z",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "577.90636",
          y: "374.73985",
          width: "12.11047",
          height: "4.84418",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M481.24679,303.62517c.00602-.09038,.01027-.17091-.0052-.25491-.07238-.67333-.66848-1.14579-1.33197-1.07158l-7.80697,.87757-274.19074,9.91254-2.42507,.2667c-.52846,.05771-.95569,.45872-1.05087,.99024-.1181,.65949,.31817,1.28625,.97772,1.40436l279.97237,34.89832c.66825,.06882,1.25457-.42553,1.32352-1.09382l4.53721-45.92942Zm-277.20699,11.21113l267.61865-9.17949,7.02817-.78339-4.26192,43.1443-270.3849-33.18142Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M283.91992,148.5l-5.2699,10.94-71.42004,148.06,47.59009-157.73999,3.51001-11.64001-2.13013-.87-3.5,11.64996-49.09998,163.84003-.5199,2.38c-.12012,.52002,.11987,1.04999,.59985,1.31,.58008,.33002,1.32007,.12,1.64014-.47003l75.44995-155.65997,5.29004-10.92999-2.14014-.87Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M50.78003,316.13l-3.51001-33.13-.95996-9.13-4.96008-46.88-3.00989-28.48999c-.06006-.60999-.58008-1.08002-1.20007-1.08002s-1.14001,.46002-1.21008,1.07001l-3.27002,28.5-6,52.40002-1.10986,9.68994-3.20996,28-4.47009,39.03003c-.01001,.03998-.01001,.08002-.01001,.13-.01001,.65997,.53003,1.21002,1.20007,1.21997l14.55994,.14001,20.21008,.19h.01001c.03992,0,.07983-.01001,.11987-.01001,.67004-.07001,1.15002-.66998,1.08008-1.32996l-4.26001-40.32001Zm-11.97998,39.08997l-5.79004-.04999-12.58008-.12,3.64014-31.78003v-.00995l4-34.88,1.10986-9.70001,5.93005-51.69,1.9801-17.29999,1.82983,17.29999,5.03003,47.53998,.95996,9.13,3.5,33.13,4.08008,38.56-13.68994-.13Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M230.04004,363.96997l-6.52002,3.56-18.63,5.21002-15.62,4.38-33.92004,9.48999-15.62,4.37c-48.54849-7.96399-73.06326,8.79968-96.23999,26.94l-15.62,4.36999-11.63,3.26001c-2.09998-6.35999-4.01001-12.79999-5.70996-19.33002-1.38-5.26001-2.63-10.57996-3.73999-15.94C2.75,370.87,.44995,350.82996,.07007,330.32001c-.05005-2.10004-.07007-4.21002-.07007-6.32001,0-9.47003,.40991-18.84003,1.19995-28.10004,.28003-3.21997,.6001-6.42999,.96997-9.62l24.48999-6.88995,2.52002-.71002,14.77002-4.15002,2.36011-.65997,37.48999-10.54999c28.82269-2.03586,50.77924,10.48157,69.47998,29.89996,0,0,26.62-7.95001,46.20996,6.08002,6.19995,4.45001,11.68994,11.09998,15.41003,20.90997l3.20996,9.25,11.93005,34.51001Z",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "171.48628",
          cy: "378.91004",
          r: "33.96661",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "171.48628",
          cy: "378.91004",
          r: "17.87717",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M44.05005,424.08997c0,11.23999-5.45996,21.20001-13.88013,27.38-1.02991,.76001-2.10986,1.46002-3.21997,2.10004-.16992-.37-.31995-.73004-.47998-1.10004-2.01001-4.63-3.8999-9.32001-5.68994-14.06-1.6001-4.23999-3.12012-8.52997-4.54004-12.85999-2.09998-6.35999-4.01001-12.79999-5.70996-19.33002-1.38-5.26001-2.63-10.57996-3.73999-15.94,1.07996-.10999,2.17993-.15997,3.29004-.15997,16.6499,0,30.51001,11.97998,33.40991,27.79999,.37,2,.56006,4.06,.56006,6.16998Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M27.95996,424.08997c0,5.86005-2.81995,11.06-7.17993,14.32001-1.6001-4.23999-3.12012-8.52997-4.54004-12.85999-2.09998-6.35999-4.01001-12.79999-5.70996-19.33002,9.06995,.22003,16.44995,7.17999,17.33997,16.07001,.05994,.59003,.08997,1.19,.08997,1.79999Z",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M129.26256,294.16404l-105.10001,29.418-8.85499-31.637c13.23325-10.33505,39.27002-14.93408,67.02798-18.76199l46.92702,20.981Z",
          fill: "#fff"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M11.29004,327.17999L.07007,330.32001c-.05005-2.10004-.07007-4.21002-.07007-6.32001,0-9.47003,.40991-18.84003,1.19995-28.10004l1.23999-.34997c10.96094,7.19205,13.73178,17.81033,8.8501,31.63Z",
          fill: "#fff"
        }
      }),
      _c("rect", {
        attrs: {
          x: "32.08977",
          y: "346.22915",
          width: "5.5683",
          height: "10.57977",
          transform: "translate(-93.45884 22.41035) rotate(-15.63709)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M218.10538,329.46163c-9.22327,3.69147-19.69272-.79294-23.38419-10.0162-2.82056-7.04724-.91376-15.1066,4.76566-20.14279,6.19992,4.44595,11.698,11.09473,15.41599,20.90744l3.20255,9.25156Z",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M30.51001,186.56c3.72998-7.95001,7.77002-15.72003,12.10999-23.29999v63.72998h-12.10999v-40.42999Z",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "483.44475",
          cy: "235.46952",
          r: "16.95465",
          fill: "#3f3d56"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }