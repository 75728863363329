<template>
    <div class="box__container">

        <GhostLoading v-if="isLoading && !scrollLoading" type="table" :title="TABLE_TITLE" />
        <PaginatedTable v-else
            tableId="0a133a25-36f5-492d-a293-62ba5ddfd584"
            :title="TABLE_TITLE"
            :headers="headers"
            :pages="pages"
            :pageCount="pageCount"
            :rowCount="totalRows"
            :page="pageIndex"
            :selected="selectedIntern"
            :selectionAsBlacklist="invertSelection"
            @selectionAsBlacklist="invertSelection = $event"
            :sorted="sorted"
            :mobileConfig="{title: 'beschreibung', headers: ['kundenLinkName', 'status']}"
            noDataContent="Keine Informationen"
            @page="setPageIndex"
            @requestPage="loadPage"
            @selected="onSelect"
            @headerAction-CONFIRM="onConfirm"
            @click-kundenLinkName="openCustomerNewTab"
            @click-kontoNr="clickKontoNr"
            @click-gesellschaft="openGesellschaftNewTab"
            @action-dokument="openDocument"
            @action-chat="createChat($event.chatData)"
            @action-emailBearbeitender="createEmail"
            @action-emailKunde="createEmail($event, $event.kundennr)"
            @action-vertrag="openLink"
            @action-vermoegen="openVermoegenNewTab"
            @action-freigabe="dokumentFreigeben($event)"
            @action-sign="signPdf($event)"
            @action-tan="comdirectTan($event)"
            @action-mailsettings="openMailSettings($event)"
            @sort="sortByColumn"
            @action-confirm="confirmSelection"
            :pageSize="ROWS_PER_PAGE_DEFAULT"
            :menuRowsPerPage="menuRowsPerPage"
            @rowsPerPage="onPageSizeChange"
            @onScroll="onScroll"
            @scrollLoading="scrollLoading = $event"
        >
            <template #document="row">
                <DownloadLink
                    v-if="row.docType"
                    :downloadServicePath="downloadServicePath(row)"
                    :filename="row.fileName"
                    :queryParams="{
                        docId: row.dokId,
                        fileId: row.fileId,
                        stornoId: row.stornoId
                    }"
                >
                    <component
                        :is="getDocTypeIcon(row.docType)"
                        :size="22"
                    />
                </DownloadLink>
            </template>
        </PaginatedTable>

        <BaseModal ref="hatFehler" :showCancelButton="false" labelButtonConfirm="Ok" @onConfirmButton="errorStr=null">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                {{errorStr}}
            </template>
        </BaseModal>
        <BaseModal ref="info" :showCancelButton="false" labelButtonConfirm="Ok">
            <template v-slot:modalTitle>Informationen</template>
            <div class="col mb-2" v-html="sanitize(infoText)"></div>
        </BaseModal>
    </div>
</template>

<script>
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import { CurrencyColumn, TextColumn, DateColumn, NumberColumn, ActionColumn, SlotColumn, PillColumn, IconColumn, MENU_ROWS_PER_PAGE } from "@/components/table2/table_util.js";
import Pill from '@/components/core/Pill.vue';
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import { mapGetters } from 'vuex';
import { PhWarning, PhFilePdf, PhFileXls, PhFileArrowDown } from 'phosphor-vue';
import mixin from '@/mixins/stornos/stornos-mixin.js';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import CORE_TYPES from '@/store/core/types'
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import DownloadLink from "../core/download/DownloadLink.vue";

const TABLE_TITLE = 'Wichtige Warnungen, Stornos und Informationen';

export default {
    mixins: [mixin],
    components: {
        PaginatedTable,
        Pill,
        BaseModal,
        GhostLoading,
        NoData,
        PhWarning,
        DownloadLink,
    },
    model: {
        prop: 'selected',
        event: 'selected',
    },
    props: {
        selected: {
            type: Array,
            default: null
        },
        focusOnResult: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TABLE_TITLE,
            documentToOpen: null,
            sorted: null,
            selectedIntern: [],
            invertSelection: false,
            menuRowsPerPage: MENU_ROWS_PER_PAGE,
            scrollLoading: false,
            onScrollEnd: () => {},
        }
    },
    computed: {
        ...mapGetters({
            stornoWarnung: STORNO_WARNUNG_TYPES.GETTERS.STORNO_WARNUNG,
            pageIndex: STORNO_WARNUNG_TYPES.GETTERS.PAGE_INDEX,
            totalRows: STORNO_WARNUNG_TYPES.GETTERS.TOTAL_ROWS,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            isLoading: STORNO_WARNUNG_TYPES.GETTERS.IS_LOADING,
        }),
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
        headers() {
            const kundenLinkName = TextColumn("kundenLinkName", "Name");
            const status = PillColumn("status", "Status", 80);
            const kontoNr = TextColumn("kontoNr", "Konto-/Depot-/Vers.nummer");
            const gesellschaftLink = TextColumn("gesellschaft", "Gesellschaft");
            gesellschaftLink.makeConditionalLink("gesellschaft");
            
            if (this.zugriffKunden) {
                kundenLinkName.makeLink();
                kontoNr.makeConditionalLink("url_or_depotnr")
            }
            
            return {
                lockedLeft: [
                    SlotColumn("document", ""),
                    status.makeAlwaysVisible(),
                    IconColumn("bestaetigt", "bestätigt").makeSortable(),
                    kundenLinkName.makeAlwaysVisible(),
                ],
                center: [
                    kontoNr,
                    TextColumn("art", "Art/Versicherungsstatus").addCellProps({lineClamp: 5}),
                    TextColumn("beschreibung", "Beschreibung", 350).addCellProps({
                        lineClamp: !this.$isSmallScreen ? 5 : 0, 
                        tooltip: row => !this.$isSmallScreen ? row.beschreibung :''}),
                    TextColumn("kategorie", "Dokumentenkategorie"),
                    CurrencyColumn("bruttoanlagesumme", "Bruttoanlage/Beitragssumme"),
                    NumberColumn("anteile", "Anteile", 2),
                    TextColumn("kundennr", "Kundennr"),
                    TextColumn("versicherte", "Versicherte Person / Gegenstand").makeSortable(false),
                    gesellschaftLink,
                    DateColumn("transaktionsDatumStr", "Datum"),
                    DateColumn("lastUpdatedStr", "letzte Aktualisierung"),
                    DateColumn("createDateStr", "Erstellungsdatum"),
                    TextColumn("vermittlername", "Vermittlername"),
                    TextColumn("maklernr", "Vermittlernummer"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            };
        },
        downloadServicePath() {
            return (row) => {
                if (row.docType && row.dokId) {
                    return '/getStornoDocument';
                } else if (row.docType && row.fileId) {
                    return '/get_simple_file';
                }
            }
        },
        pageCount() {
            return Math.ceil(this.totalRows / this.ROWS_PER_PAGE_DEFAULT);
        },
        pages() {
            const result = {};
            const entries = Object.entries(this.stornoWarnung);
            if (entries?.length) {
                entries.forEach(([key, page]) => {
                    result[key] = this.buildPage(page);
                })
            }
            return result;
        },
    },
    watch: {
        selected: {
            handler() {
                this.setSelectedIntern(this.selected);
            },
            immediate: true,
        },
        tablePageSize(value) {
            this.onPageSizeChange(value);
            this.setPageIndex(0);
            this.$nextTick(() => this.loadPage(0));
        },
        isLoading(val) {
            if (!val) {
                // when we are done loading we are also done scrollLoading
                this.onScrollEnd()
            }
        },
    },
    methods: {
        async onScroll(onScrollEnd) {
            this.onScrollEnd = onScrollEnd
            this.loadPage(this.pageIndex + 1)
        },
        openModalConfig() {
            this.$refs.tableResult.openColumnsConfig()
        },
        onSelect(rows) {
            this.selectedIntern = rows;

            const selected = this.findOnlySelected(rows);
            this.$emit("selected", selected);
        },
        onTablePageChange(event) {
            this.loadPage(event?.index);
        },
        setPageIndex(index) {
            this.$store.commit(STORNO_WARNUNG_TYPES.MUTATIONS.UPDATE_PAGE_INDEX, index);
            this.clearInvertSelection();
        },
        loadPage(index) {
            let sortObj = {...this.sorted, sortKey: this.sorted?.key};
            delete sortObj.key;
            if(sortObj.sortKey === 'kundenLinkName'){
                sortObj.sortKey = 'kundenName';
            }
            this.$emit('goToPage',  { pageIndex: index || 0, limit: this.ROWS_PER_PAGE_DEFAULT, ...sortObj })
        },
        sortByColumn(event){
            this.sorted = event;
            let sortKey = event.key;
            if(event.key === 'kundenLinkName'){
                sortKey = 'kundenName';
            }
            this.$emit('sort', {sortKey: sortKey, sortDirection: event.sortDirection, pageIndex: this.pageIndex, limit: this.ROWS_PER_PAGE_DEFAULT})
        },
        clickKontoNr(row) {
            if (row.url)
                this.openLink(row);
            else
                this.openVermoegenNewTab(row);
        },
        findOnlySelected(selected) {
            if(this.invertSelection) {
                const { pages, pageIndex } = this;
                return [ ...pages?.[pageIndex]
                    ?.filter(item => !!item.stornoId && !selected.some(selected => selected.stornoId == item.stornoId)), ];
            } else {
                return [ ...selected, ];
            }
        },
        setSelectedIntern(selected) {
            if(selected?.length > 0) {
                this.selectedIntern = this.findOnlySelected(selected);
            } else {
                this.selectedIntern = [];
                this.invertSelection = false;
            }
        },
        clearInvertSelection() {
            if(this.invertSelection) {
                this.invertSelection = false;
                this.onSelect([]);
            }
        },
        onConfirm() {
            this.$emit('confirm');
        },
        confirmSelection(selection) {
            if (selection?.stornoId) {
                const payload = {
                    stornosToConfirm: [selection?.stornoId],
                    isOverview: false,
                    limit: this.ROWS_PER_PAGE_DEFAULT,
                    pageIndex: this.pageIndex
                }

                this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.CONFIRM_STORNO_WARNUNG, payload);
            }
        },
        getDocTypeIcon(docType) {
            switch (docType) {
                case 'pdf':
                    return PhFilePdf
                case 'xls':
                    return PhFileXls
                default:
                    return PhFileArrowDown
            }
        },
    },
}
</script>

<style>
</style>