<template>
  <div>
      <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

      <PageHeaderTitleNavigation 
        :id="$appNavigation.currentOptionMenuId" 
        title="Kunde löschen" 
        :subtitle="deletedCustomersRows.length ? 'Ergebnis der Kundenlöschung' : 'Bestätigung'"
      />

      <div class="box__container" tid="6e357cea-962a-4cee-8d60-7b779a3139d5"  v-if="!deletedCustomersRows.length">

        <p>Sollen die ausgewählten Kunden wirklich gelöscht werden?</p>

        <Table v-if="rows.length && !deletedCustomersRows.length"
            tableId="fd378e7e-9bcf-4f8d-bc42-2488bbb073c6"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
        />
        <NoData v-else-if="rows.length === 0" /> 
      </div>

      <div v-if="deletedCustomersRows.length">

        <div class="box__container"  v-for="(resultCard, index) in deletedCustomersRows" :key="resultCard.kundennr + index">
          <div class="box__title">{{resultCard.kundennr}} - {{resultCard.name}}</div>

          <ul class="list m-0">
            <li v-for="(message, messageIndex) in resultCard.meldungZusatz" :key="message + messageIndex">{{message}}</li>
          </ul>

        </div>

      </div>

      <BaseButton isSecondary @click="goBack()">
          {{deletedCustomersRows.length ? 'Zurück' : 'Abbrechen'}}
      </BaseButton>

      <BaseButton @click="kundenLoeschen()" v-if="rows.length && !deletedCustomersRows.length">
        Löschen
      </BaseButton>     
      
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    Table,
    NoData,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      kundenLoeschenRestrictions: CUSTOMER_SEARCH_TYPES.GETTERS.KUNDEN_LOESCHEN_RESTRICTIONS,
      removedCustomers: CUSTOMER_SEARCH_TYPES.GETTERS.KUNDEN_LOESCHEN,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("kundennr", "Kundennr"),
          TextColumn("name", "Name"),
          TextColumn("meldungZusatz", "Nachricht"),
        ],
      }
    },
    rows() {
       return this.kundenLoeschenRestrictions.map(item => {
        return {
          kundennr: item.kundennr,
          name: item.name,
          meldungZusatz: item.meldungZusatz,
        };
      }
      );
    },
    deletedCustomersRows() {
       return this.removedCustomers.map(item => {
        return {
          kundennr: item.kundennr,
          name: item.name,
          meldungZusatz: item.meldungZusatz,
        };
      }
      );
    }
  },
  data() {
    return {
      customers: null,
      changingPosition: false,
    };
  },
  methods: {
    kundenLoeschen() {
      let payload = [];

      this.kundenLoeschenRestrictions.forEach(item => {
        payload.push(item.kundennr);
      });

      this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_LOESCHEN, payload);
    },  
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {

  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Kunde löschen', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_KUNDEN_LOESCHEN_DATA);
    next();
  },
};
</script>

<style>

.main__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;

}

.main__container .header {
  flex: 1 1 0px;
}

.main__container .item {
  flex: 1 1 0px;
  /* align-self: center; */
  border: 1px  solid var(--color-text);
  padding: 4px;
}

.main__container .control {
  flex: 0.2 1 0px;
  align-self: center;
  justify-self: center;
  text-align: center;
}




</style>