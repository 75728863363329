<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" 
    />

    <PageHeaderTitleNavigation 
      id="f23501fe-86e6-4cd7-99b2-4884429d7425" 
      title="Auswahl Vertragsart"
      :subtitle="categorySelected && categorySelected.name"
      :defaultMenu="customOptionMenu" 
      :isLoading="loading"
    />

    <div class="box__container" v-if="categorySelected && categorySelected.groups.length" :tid="_generateTidFromString('box__container-suchen')">
      <div class="box__title">Suchen</div>
      <InputField 
        isComponentHalfSize
        placeholder="Suchen nach Bezeichnung"
        v-model="descriptionSearch"/>
    </div>

    <div>
      <div v-for="group in groups" :key="group.id"  class="box__container">
        <div class="group_title">{{ group.name }}</div>
        <div class="masonry-container group items">
          <div v-for="(bezeichnung, subIndex) in filteredBezeichnungen(group)" :key="subIndex" class="item" @click="handleLabelClick(bezeichnung)">
            {{ bezeichnung.label }}
          </div>
        </div>
      </div>
    </div>    

    <AddVersicherungModal v-if="showAddVersicherungModal" @close="showAddVersicherungModal = false"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import UndrawRetire from '@/components/icons/undraw/UndrawRetire.vue';
import UndrawHealth from '@/components/icons/undraw/UndrawHealth.vue';
import UndrawAuto from '@/components/icons/undraw/UndrawAuto.vue';
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import InputField from '@/components/core/forms/InputField.vue'
import AddVersicherungModal from '@/views/versicherungen/AddVersicherungModal.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from '@/store/core/types';

const INITIAL_CUSTOM_OPTION_MENU = [{},{},{},{}];

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    AddVersicherungModal,
    Table,
    InputField,
    PageHeaderTitleNavigation,
  },
  async  mounted() {
    await this.retrieveAllCategories();
  },
  data() {
    return {
      categorySelected: null,
      descriptionSearch: '',
      showAddVersicherungModal: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      allCategories: VERSICHERUNG_TYPES.GETTERS.ALL_CATEGORIES_LIST,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    spartenSequencialOrder() {
      return {
        SACHEN: 1,
        VORSORGE: 2,
        GESUNDHEIT: 3,
        BETRIEBLICH: 4,
        SONSTIG: 5,

      }

    },
    customOptionMenu() {
      if(!this.allCategories?.length) {
        return this.loading ? INITIAL_CUSTOM_OPTION_MENU : [];
      }

      const mappedCategories = this.allCategories.map(category => ({
        position: this.spartenSequencialOrder[category?.category] || 0,
        path: category?.category,
        component: this.componentByCategory(category.category),
        label: category.name,
        componentProps: {
          src: null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        textBold: (this.categorySelected?.category === category?.category),
        action: () => this.selectCategory(category),
      }))

      if (this.isFA) {
        return mappedCategories.sort((a, b) => (a.position- b.position));
      }

      return mappedCategories;
    },
    groups() {
      if(this.categorySelected?.groups?.length){
        return this.categorySelected.groups.filter(group => this.filteredBezeichnungen(group)?.length);
      }
      return [];
    }
  },
  watch: {
    customOptionMenu() {
      this.selectFirstCategory();
    },
  },
  methods:{
    async retrieveAllCategories() {
       try {
         this.loading = true;
         await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_ALL_CATEGORIES);
       } finally {
         this.loading = false;
       }
    },
    filteredBezeichnungen(group) {
      const filteredBezeichnungen = this.descriptionSearch 
          ? group?.bezeichnungen.filter(value => value?.label?.toLowerCase?.().includes(this.descriptionSearch.toLowerCase())) 
          : group?.bezeichnungen;

      // Clone the array before sorting to avoid in-place modification
      // Thus avoid infinite update loop
      return [...(filteredBezeichnungen || [])].sort((a, b) => a.label.localeCompare(b.label));
    },
    headers(group) {
        return {
            center: [
                TextColumn("label", group.name || "Allgemein").makeLink(),
            ],
        }
    },
    componentByCategory(category) {
      switch (category) {
        case "GESUNDHEIT":
          return UndrawHealth;
        case "SACHEN":
          return UndrawAuto;
        case "BETRIEBLICH":
          return UndrawRetire;
        case "VORSORGE":
        case "SONSTIG":
        default:
          return UndrawSecureServer;
      }
    },
    selectCategory(category){
      this.categorySelected = category
    },
    selectFirstCategory() {
      if(this.allCategories?.length && !this.categorySelected) {
        const firstCategorySorted = this.customOptionMenu[0];

        this.selectCategory(this.allCategories.find(cat => cat.category === firstCategorySorted.path));
      }
    },
    handleLabelClick(item) {
      const payload = {category: this.categorySelected.id, group: item.id, groupLabel: item.label};
      if (this.$route.query.gesellid) {
        payload.versicherung = {gesellschaftId: this.$route.query.gesellid}
      }
      if (this.$route.query.vesichnr) {
        if (payload.versicherung) {
          payload.versicherung.nummer = this.$route.query.vesichnr;
        } else {
          payload.versicherung = {nummer: this.$route.query.vesichnr}
        }
      }
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED, payload)
      this.showAddVersicherungModal = true
    }
  }
}
</script>

<style scoped>
.masonry-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; 
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  flex: 1 0 calc(33.333% - 8px); 
  max-width: calc(33.333% - 8px);
  box-sizing: border-box;
  border-bottom: 1px solid var(--big-table-column-border);
  transition: background-color 0.3s ease;
  cursor: pointer; 
}

.group {
  line-height: 32px;
}

.group .items {
  column-count: 1;
}

.group_title {
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--color-box);
  white-space: nowrap; 
  border-bottom: 1px solid var(--big-table-column-border);
  padding: 2px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.item:hover {
  background-color: var(--big-table-highlighted-row);
}

.masonry-container.box__container > * {
    margin-bottom: 0px;
}

@media (max-width: 1200px) {
  .item {
    flex: 1 0 calc(50% - 8px);
    max-width: calc(50% - 8px);
    box-sizing: border-box;
  }

}

@media (max-width: 900px) {
  .item {
    flex: 1 0 calc(100% - 8px);
    box-sizing: border-box;
    max-width: calc(100% - 8px);;
  }

}

</style>