<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Versicherung Block-Bestandsübertrag"
    />

    <GhostLoading v-if="loading" type="block" />
    <div class="box__container" v-else>
      <div class="fileCard">
        <span class="title">Auskunftsvollmacht:</span>

        <DownloadLink
          v-if="hasFileAuskunftsvollmacht"
          :title="selectedVuBlock.fileNameAuskunftsvollmacht"
          target="_blank"
          rel="noopener noreferer"
          downloadServicePath="/vers_uebertrag_block"
          filename="Auskunftsvollmacht.pdf"
          :queryParams="{
            vuBlockId: selectedVuBlock.id,
            type: FILE_AUSKUNFTSVOLLMACHT,
          }"
        />

        <BaseButton
          v-if="hasFileAuskunftsvollmacht && isEditAllowed"
          label="Löschen"
          :isSecondary="true"
          @click="deleteFile($event, FILE_AUSKUNFTSVOLLMACHT)"
        />

        <BaseFileSelect
          v-if="!hasFileAuskunftsvollmacht && isEditAllowed"
          accept=".pdf"
          @files="onFileUpload($event, FILE_AUSKUNFTSVOLLMACHT)"
          isSecondary
          :animated="loadingFileAuskunftsvollmacht"
        >
          Hochladen
        </BaseFileSelect>
      </div>

      <div class="fileCard">
        <span class="title">Einverständniserklärung:</span>

        <DownloadLink
          v-if="hasFileEinverstaendnis"
          :title="selectedVuBlock.fileNameEinverstaendnis"
          target="_blank"
          rel="noopener noreferer"
          downloadServicePath="/vers_uebertrag_block"
          filename="Einverständniserklärung.pdf"
          :queryParams="{
            vuBlockId: selectedVuBlock.id,
            type: FILE_EINVERSTAENDNIS,
          }"
        />

        <BaseButton
          v-if="hasFileEinverstaendnis && isEditAllowed"
          label="Löschen"
          :isSecondary="true"
          @click="deleteFile($event, FILE_EINVERSTAENDNIS)"
        />

        <BaseFileSelect
          v-if="!hasFileEinverstaendnis && isEditAllowed"
          accept=".pdf"
          @files="onFileUpload($event, FILE_EINVERSTAENDNIS)"
          isSecondary
          :animated="loadingFileEinverstaendnis"
        >
          Hochladen
        </BaseFileSelect>
      </div>

      <InputCheckboxItem
        label="mit Rechten und Pflichten"
        v-model="selectedVuBlock.mitRechtenUndPflichten"
        :disabled="!isEditAllowed"
      />
    </div>

    <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
    <Table
      tableId="302248ec-ba7d-41e2-bead-c41ff109da2f"
      :title="TABLE_TITLE"
      class="table-container"
      v-else-if="!loading && tableRows.length"
      :headers="tableHeaders"
      :rows="tableRows"
      :rowsPerPage="20"
      :hidePagination="tableRows.length < 21"
      :exportConfig="{ roottext: TABLE_TITLE }"
      @action="executeAction($event)"
      :dragnDropFilesOnRow="isEditAllowed"
      @files="setFiles($event)"
      @click-statusText="showStatusModal($event)"
      @click-versStatusText="showVersStatusModal($event)"
      @click-filesText="showFilesModal($event)"
      @click-mailsText="showMailsModal($event)"
    />
    <NoData v-else :title="TABLE_TITLE" />

    <BaseModal
      ref="showStatusModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      size="lg"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <Table
        tableId="bb109356-9f47-48d1-a0c2-b9542d74f644"
        title="Status"
        class="table-container"
        v-if="
          selectedVuBlockGesell.statusList &&
          selectedVuBlockGesell.statusList.length
        "
        :rowsPerPage="10"
        :hidePagination="selectedVuBlockGesell.statusList.length < 11"
        :headers="tableStatusHeaders"
        :rows="selectedVuBlockGesell.statusList"
      />
      <NoData v-else />
    </BaseModal>

    <BaseModal
      ref="showVersStatusModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      size="lg"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <Table
        tableId="cfc0f3e2-fe53-4452-ae82-c7615285f57d"
        title="Versicherungen"
        class="table-container"
        v-if="
          selectedVuBlockGesell.versDetails &&
          selectedVuBlockGesell.versDetails.length
        "
        :rowsPerPage="10"
        :hidePagination="selectedVuBlockGesell.versDetails.length < 11"
        :headers="tableVersStatusHeaders"
        :rows="selectedVuBlockGesell.versDetails"
        @click-polNr="openVersVertrag($event)"
      />
      <NoData v-else />
    </BaseModal>

    <BaseModal
      ref="showFilesModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      size="md"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <Table
        tableId="06727a80-9ad7-47ee-8047-35b7ef5865cb"
        title="zus. Dateien"
        class="table-container"
        v-if="tableFilesRows && tableFilesRows.length"
        :rowsPerPage="10"
        :hidePagination="tableFilesRows.length < 11"
        :headers="tableFilesHeaders"
        :rows="tableFilesRows"
        @action="executeAction($event)"
      >
        <template #fileName="row">
          <DownloadLink
            :title="row.fileName"
            :filename="row.fileName"
            downloadServicePath="/vers_uebertrag_block_gesell"
            :queryParams="{
              vubGesellId: selectedVuBlockGesell.id,
              fileId: row.id,
            }"
          />
        </template>
      </Table>

      <NoData v-else />
    </BaseModal>

    <BaseModal
      ref="showMailsModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      size="xl"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <Table
        tableId="e2f9fd03-9e94-4869-8432-8ad14acb4a92"
        title="E-Mails"
        class="table-container"
        v-if="selectedVuBlockGesell.mails && selectedVuBlockGesell.mails.length"
        :rowsPerPage="10"
        :hidePagination="tableMailsRows.length < 11"
        :headers="tableMailsHeaders"
        :rows="tableMailsRows"
        @click-betreff="viewEmail($event)"
      />
      <NoData v-else :noIcon="true" />
    </BaseModal>

    <BaseModal
      ref="editGesellModal"
      labelButtonConfirm="Speichern"
      @onConfirmButton="confirmEditGesell"
      :showCancelButton="true"
      size="md"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <ComboBox v-model="dataEditGesell.status" :values="dataStatusEntries" />

      <InputTextArea v-model="dataEditGesell.bemerkung" label="Bemerkung" />

      <BaseFileSelect
        accept=".pdf, .msg"
        @files="onFileUploadEdit($event)"
        isSecondary
      >
        zusätzliche Datei hochladen
      </BaseFileSelect>

      <BaseButton
        v-if="dataEditGesell.file && dataEditGesell.file.fileName"
        label="Löschen"
        :isSecondary="true"
        @click="() => (dataEditGesell.file = {})"
      />

      <template v-if="dataEditGesell.file && dataEditGesell.file.fileName">
        <hr />
        {{ dataEditGesell.file.fileName }}
        <hr />
      </template>
    </BaseModal>

    <BaseModal
      ref="editCommentForMaklerModal"
      labelButtonConfirm="Speichern"
      @onConfirmButton="confirmEditCommentForMakler"
      :showCancelButton="true"
      size="md"
      :modalTitle="selectedVuBlockGesell.gesellName"
    >
      <InputTextArea v-model="dataEditGesell.bemerkung" label="Bemerkung" />
    </BaseModal>
  </div>
</template> 

<script>
import VUBLOCK_TYPES from "@/store/vuBlock/types";
import CORE_TYPES from "@/store/core/types";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";

import { mapGetters } from "vuex";

import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import NoData from "@/components/core/NoData.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SimpleAction,
  ConfirmedAction,
  DateColumn,
  PillColumn,
  SlotColumn,
} from "@/components/table2/table_util.js";
import { PhEnvelope, PhLock, PhTrash, PhPencilLine } from "phosphor-vue";
import VersUebertragBlock from "@/views/intern/VersUebertragBlock.js";

export default {
  mixins: [VersUebertragBlock],

  components: {
    BaseFileSelect,
    DownloadLink,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputCheckboxItem,
    NoData,
    GhostLoading,
    Table,
    BaseModal,
    InputTextArea,
    ComboBox,
  },

  data: function () {
    return {
      TABLE_TITLE: "Übertragungen",

      doReloadData: true,
      loading: false,
      loadingTable: false,
      loadingFileAuskunftsvollmacht: false,
      loadingFileEinverstaendnis: false,

      dataEditGesell: {
        bemerkung: "",
        status: "",
        file: {},
      },

      dataStatusEntries: [],

      tableStatusHeaders: {
        center: [
          DateColumn("date", "Datum"),
          TextColumn("status", "Status"),
          TextColumn("bemerkung", "Bemerkung"),
        ],
      },

      tableVersStatusHeaders: {
        lockedLeft: [TextColumn("kundeText", "Kunde")],
        center: [
          TextColumn("polNr", "VSNR").makeLink(),
          TextColumn("status", "Status"),
          DateColumn("gueltigAb", "gültig ab"),
        ],
      },

      tableFilesHeaders: {
        lockedLeft: [SlotColumn("fileName", "Dateiname", 200, 1)],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      },

      tableMailsHeaders: {
        lockedLeft: [
          DateColumn("dateSent", "Versendet am"),
          TextColumn("status", "Status"),
        ],
        center: [TextColumn("betreff", "Betreff").makeLink()],
      },

      FILE_ALLGEMEIN: "ALLGEMEIN",

      ACTION_SEND_MAIL: "ACTION_SEND_MAIL",
      ACTION_CLOSE_ENTRY: "ACTION_CLOSE_ENTRY",
      ACTION_DELETE_GESELL_FILE: "ACTION_DELETE_GESELL_FILE",
      ACTION_EDIT_GESELL: "ACTION_EDIT_GESELL",
      ACTION_EDIT_GESELL_FOR_BROKER: "ACTION_EDIT_GESELL_FOR_BROKER",
    };
  },

  mounted() {
    if (this.doReloadData) {
      let vuBlockPathId = this.$route.params.vuBlockId;

      if (
        this.selectedVuBlock?.id == vuBlockPathId &&
        this.selectedVuBlockGesellId
      ) {
        // same vuBlock and currently working on a gesellschaft: only reload the gesellschaft line
        this.$store.dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_GESELL, {
          id: this.selectedVuBlockGesellId,
        });
      } else {
        this.loading = true;

        this.$store
          .dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK, {
            id: vuBlockPathId,
          })
          .finally(() => {
            this.loading = false;
          });
      }

      this.$store
        .dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_GESELL_STATUS_ENTRIES)
        .then((response) => (this.dataStatusEntries = response?.data || []));
    }
  },

  methods: {
    executeAction(actionData) {
      switch (actionData.key) {
        case this.ACTION_DELETE_GESELL_FILE:
          this.$store.dispatch(
            VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK_GESELL_FILE,
            {
              vubGesellId: this.selectedVuBlockGesell.id,
              fileId: actionData.row.id,
            }
          );
          break;

        case this.ACTION_SEND_MAIL:
          this.sendEmail(actionData.row);
          break;

        case this.ACTION_CLOSE_ENTRY:
          this.$store.dispatch(
            VUBLOCK_TYPES.ACTIONS.SET_VUBLOCK_GESELL_FEHLER_ERLEDIGT,
            {
              vuBlockGesellId: actionData.row.id,
            }
          );
          break;

        case this.ACTION_EDIT_GESELL:
          this.showEditGesellModal(actionData.row);
          break;

        case this.ACTION_EDIT_GESELL_FOR_BROKER:
          this.showEditGesellModalForBroker(actionData.row);
          break;
      }
    },

    showStatusModal(row) {
      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );
      this.$refs.showStatusModal.open();
    },

    showVersStatusModal(row) {
      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );
      this.$refs.showVersStatusModal.open();
    },

    showFilesModal(row) {
      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );
      this.$refs.showFilesModal.open();
    },

    showMailsModal(row) {
      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );
      this.$refs.showMailsModal.open();
    },

    showEditGesellModal(row) {
      this.dataEditGesell = {
        status: row.status,
        file: {},
      };

      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );

      this.$refs.editGesellModal.open();
    },

    showEditGesellModalForBroker(row) {
      this.dataEditGesell = {
        status: "",
        bemerkung: "",
        file: {},
      };

      this.$store.commit(
        VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
        row
      );

      this.$refs.editCommentForMaklerModal.open();
    },

    deleteFile(event, type) {
      this.$store.dispatch(VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK_FILE, {
        vuBlockId: this.selectedVuBlock?.id,
        type: type,
      });
    },
    async setFiles(metadata) {
      const files = metadata.files;
      const row = metadata.row;

      await this.onFileUploadEdit(files);
      this.showEditGesellModal(row);
    },
    async onFileUploadEdit(files) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }).then((data) => {
        let base64string = data.split(",").pop();

        this.dataEditGesell.file = {
          fileName: files[0].name,
          data: base64string,
        };
      });
    },

    sendEmail(row) {
      if (row?.id) {
        this.$store.commit(
          VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID,
          row
        );
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG,
          {
            type: "mailcomposer-vers-uebertrag-block-gesell",
            action: "clear",
          }
        );
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: "mailcomposer-vers-uebertrag-block-gesell",
          action: "clear",
        });
        this.$router.push({
          path: `/communication/mailcomposer-vers-uebertrag-block-gesell/${row.id}`,
          query: { replaceContent: true },
        });
      }
    },

    openVersVertrag(event) {
      if (event?.kundennr && event?.id) {
        this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, {
          data: { customerID: event.kundennr },
        });

        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: event.kundennr,
          path: `/home/versicherungen/insurance-group/details/${event.id}`,
        });
      }
    },

    viewEmail(event) {
      if (event?.emailLogId) {
        this.$router.push(
          `/communication/postfach/emails/email/${event.emailLogId}`
        );
      }
    },

    confirmEditGesell() {
      this.$store.dispatch(
        VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK_GESELL_BEMERKUNG,
        {
          vuBlockGesellId: this.selectedVuBlockGesell?.id,
          dataEditGesell: this.dataEditGesell,
        }
      );
    },

    confirmEditCommentForMakler() {
      this.$store.dispatch(
        VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK_GESELL_BEMERKUNG_FOR_BROKER,
        {
          vuBlockGesellId: this.selectedVuBlockGesell?.id,
          dataEditGesell: this.dataEditGesell,
        }
      );
    },
  },

  computed: {
    ...mapGetters({
      selectedVuBlock: VUBLOCK_TYPES.GETTERS.VUBLOCK_SELECTED,
      selectedVuBlockGesell: VUBLOCK_TYPES.GETTERS.VUBLOCK_GESELL_SELECTED,
      selectedVuBlockGesellId: VUBLOCK_TYPES.GETTERS.VUBLOCK_GESELL_ID_SELECTED,
      isEditAllowed: VUBLOCK_TYPES.GETTERS.VIEW_IS_EDIT_ALLOWED, // basically if is_intern; brokers also can add a bemerkung now but are still isEditAllowed false
      isBroker: VUBLOCK_TYPES.GETTERS.VIEW_IS_BROKER,
    }),

    hasFileAuskunftsvollmacht() {
      return this.selectedVuBlock?.fileNameAuskunftsvollmacht;
    },

    hasFileEinverstaendnis() {
      return this.selectedVuBlock?.fileNameEinverstaendnis;
    },

    tableHeaders() {
      return {
        lockedLeft: [TextColumn("gesellName", "Gesellschaft")],
        center: [
          PillColumn("gesellNrStatus", "Unternummer"),
          DateColumn("stand", "Stand"),
          TextColumn("statusText", "Status").makeLink(),
          TextColumn("versStatusText", "Versicherungen").makeLink(),
          TextColumn("filesText", "zus. Dateien").makeLink(),
          ...(this.isBroker
            ? [PillColumn("warnMaklerText", "Nachbearbeitung / Hinweis")]
            : [TextColumn("mailsText", "E-Mails").makeLink()]),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      };
    },

    tableRows() {
      if (!this.selectedVuBlock?.gesellschaften?.length) {
        return [];
      }

      return this.selectedVuBlock?.gesellschaften?.map((data) => ({
        ...data,
        gesellNrStatus: {
          label: data.gesellNrStatus,
          type: data.isError ? "danger" : "",
        },
        warnMaklerText: {
          label: data.warnMaklerText,
          type: "danger",
        },
        filesText: data.isError
          ? ""
          : data.files?.length
          ? data.files?.length + " Dateien"
          : "Keine",
        mailsText: data.isError
          ? ""
          : data.mails?.length
          ? data.mails?.length + " Mails"
          : "Keine",
        actions: this.isEditAllowed
          ? data.isError
            ? [
                ConfirmedAction(
                  this.ACTION_CLOSE_ENTRY,
                  PhLock,
                  "Erledigt / aktiv",
                  `Alle Versicherungen von ${data.gesellName} werden auf Status aktiv gesetzt.`,
                  "Bestätigung",
                  "Ja"
                ),
              ]
            : [
                SimpleAction(
                  this.ACTION_SEND_MAIL,
                  PhEnvelope,
                  "E-Mail versenden"
                ),
                SimpleAction(
                  this.ACTION_EDIT_GESELL,
                  PhPencilLine,
                  "Status / Kommentar / Datei"
                ),
              ]
          : [
              SimpleAction(
                this.ACTION_EDIT_GESELL_FOR_BROKER,
                PhPencilLine,
                "Kommentar"
              ),
            ],
      }));
    },

    tableFilesRows() {
      if (!this.selectedVuBlockGesell?.files?.length) {
        return [];
      }

      return this.selectedVuBlockGesell?.files?.map((data) => ({
        id: data.id,
        fileName: data.fileName,
        actions: this.isEditAllowed
          ? [
              ConfirmedAction(
                this.ACTION_DELETE_GESELL_FILE,
                PhTrash,
                "Löschen",
                `Soll die Datei ${data.fileName} wirklich gelöscht werden?`,
                "Bestätigung",
                "Ja"
              ),
            ]
          : [],
      }));
    },

    tableMailsRows() {
      if (!this.selectedVuBlockGesell?.mails?.length || this.isBroker) {
        // no edit allowed = mails should also not be shown
        return [];
      }

      return this.selectedVuBlockGesell?.mails?.map((data) => ({
        ...data,
        id: data.emailLogId,
        status: data.error ? "Fehler" : data.warn ? data.statusText : "Ok",
      }));
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.matched.find((x) => x.path == "/communication/postfach")) {
      // no reload if back from viewing an archived email
      next((vm) => {
        vm.doReloadData = false;
      });
    } else {
      next((vm) => {
        vm.doReloadData = true;
      });
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (this.isEditAllowed && this.selectedVuBlock?.id) {
      this.$store.dispatch(
        VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK,
        this.selectedVuBlock
      );

      this.$addBreadcrumb({
        label: "zurück zum Übertrag",
        to,
        from,
        breadcrumb: "Versicherung Block-Bestandsübertrag",
      });
    }
    next();
  },
};
</script>

<style scoped>
.fileCard > * {
  margin-right: 1em;
  margin-bottom: 0.5em;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
  overflow: visible;
  text-align: inherit;
  border-top: 1px solid #ccc;
}

hr:first-of-type {
  margin-top: 16px;
}
</style>
