var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Wertpapier beantragen",
          actions: _vm.headerActions
        },
        on: {
          "action-SAVE": function($event) {
            return _vm.save()
          },
          "action-FONDSINFO": function($event) {
            return _vm.gotoFondsinfo(_vm.isin)
          },
          "action-FACTSHEET": function($event) {
            return _vm.openFactsheetFile("FACTSHEET")
          },
          "action-FACTSHEET-KAG": function($event) {
            return _vm.openFactsheetFile("MR")
          },
          "action-KID": function($event) {
            return _vm.openFactsheetFile("KIID")
          },
          "action-VIDEO": function($event) {
            return _vm.video()
          }
        }
      }),
      _vm.statistikHinweise
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Hinweise")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return _vm._l(_vm.statistikHinweise, function(
                          hinweis,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mt-2" },
                            [
                              _c("span", { staticClass: "font-bold" }, [
                                _vm._v(_vm._s(hinweis.label))
                              ]),
                              _c("span", [_vm._v(": " + _vm._s(hinweis.text))])
                            ]
                          )
                        })
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  426523330
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "cards__item--header" }, [
          _c("div", { staticClass: "box__title cards__item--header-title" }, [
            _vm._v(" " + _vm._s(_vm.daten.title) + " ")
          ])
        ]),
        _c("div", { staticClass: "cards__item" }, [
          _c(
            "div",
            { staticClass: "cards__item--info" },
            _vm._l(_vm.stammdaten, function(entry, index) {
              return _c(
                "div",
                { key: "st" + index, staticClass: "cards__item--entry" },
                [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(entry.label) + ":")
                  ]),
                  _c("span", [_vm._v(" " + _vm._s(entry.value))])
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "cards__item--info" },
            _vm._l(_vm.mifid, function(entry, index) {
              return _c(
                "div",
                { key: "mf" + index, staticClass: "cards__item--entry" },
                [
                  _c("div", [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(entry.label) + ":")
                    ]),
                    _c("span", [_vm._v(" " + _vm._s(entry.value))])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _vm.loadingData
        ? _c("GhostLoading", {
            attrs: { type: "block", config: { block: { height: 250 } } }
          })
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.daten.canSave
                ? _c("InputTextArea", {
                    attrs: { label: "Kommentar", isComponentHalfSize: "" },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  })
                : _vm._e(),
              _c("InputField", {
                attrs: {
                  isEmbedded: "",
                  type: "percent",
                  label: "Max. Anteil (optional)",
                  precision: 2,
                  disabled: _vm.daten.isMaxAnteilDisabled,
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.maxAnteil,
                  callback: function($$v) {
                    _vm.maxAnteil = $$v
                  },
                  expression: "maxAnteil"
                }
              }),
              _c("div", [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v("Aktueller Status:")
                ]),
                _c(
                  "span",
                  { staticClass: "font-bold", class: _vm.daten.statusColor },
                  [_vm._v(" " + _vm._s(_vm.daten.aktuelStatus))]
                )
              ]),
              _c("ComboBox", {
                attrs: {
                  label: "Neuer Status",
                  values: _vm.daten.newStatusValues || [],
                  disabled: _vm.daten.isNewStatusDisabled,
                  firstEmpty: false,
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.newStatus,
                  callback: function($$v) {
                    _vm.newStatus = $$v
                  },
                  expression: "newStatus"
                }
              }),
              _vm.daten.aktuellAnlagevolumen &&
              _vm.daten.tableAnlagevolumen &&
              _vm.daten.tableAnlagevolumen.length
                ? _c(
                    "div",
                    [
                      _c("span", { staticClass: "font-bold" }, [
                        _vm._v("Aktuelles Anlagevolumen VV:")
                      ]),
                      _c(
                        "span",
                        [
                          _vm._v(
                            " " + _vm._s(_vm.daten.aktuellAnlagevolumen) + " "
                          ),
                          _c("PhCurrencyEur", { attrs: { size: 16 } })
                        ],
                        1
                      ),
                      _c("Table", {
                        staticClass: "aktuellAnlagevolumenTable",
                        attrs: {
                          headers: _vm.aktuellAnlagevolumenHeaders,
                          rows: _vm.daten.tableAnlagevolumen,
                          rowsPerPage: 20,
                          hidePagination: "",
                          rowId: "aktuellAnlagevolumen"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.daten.wpFehler
                ? _c("div", { staticClass: "font-bold color-danger" }, [
                    _vm._v(" " + _vm._s(_vm.daten.wpFehler) + " ")
                  ])
                : _vm._e()
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "font-bold mt-4" }, [_vm._v("Protokoll")]),
          _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.tableRows,
              rowsPerPage: 20,
              rowId: "vvwertpapierbeantragen"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }