export const MUTATION_PREFIX = 'SCHADENSMELDUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'SCHADENSMELDUNG_ACTIONS_';
export const GETTERS_PREFIX = 'SCHADENSMELDUNG_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_SCHADENSMELDUNG_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_SCHADENSMELDUNG_SUCCESS',
    RETRIEVE_SCHADENSMELDUNG_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_SCHADENSMELDUNG_FAILTURE',
    RETRIEVE_SCHADEN_BEARBSTAND_VALUES: MUTATION_PREFIX + 'RETRIEVE_SCHADEN_BEARBSTAND_VALUES',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SEND_SCHADENSMELDUNG_SUCCESS: MUTATION_PREFIX + 'SEND_SCHADENSMELDUNG_SUCCESS',
    SEND_SCHADENSMELDUNG_INIT_SUCCESS: MUTATION_PREFIX + 'SEND_SCHADENSMELDUNG_INIT_SUCCESS',
    SEND_SCHADENSMELDUNG_FAILTURE: MUTATION_PREFIX + 'SEND_SCHADENSMELDUNG_FAILTURE',
    SEND_BETEILIGTE_SUCCESS: MUTATION_PREFIX + 'SEND_BETEILIGTE_SUCCESS',
    SEND_BETEILIGTE_FAILURE: MUTATION_PREFIX + 'SEND_BETEILIGTE_FAILURE',
    DELETE_BETEILIGTE_SUCCESS: MUTATION_PREFIX + 'DELETE_BETEILIGTE_SUCCESS',
    DELETE_BETEILIGTE_FAILURE: MUTATION_PREFIX + 'DELETE_BETEILIGTE_FAILURE',
    SEND_ANHANG_SUCCESS: MUTATION_PREFIX + 'SEND_ANHANG_SUCCESS',
    SEND_ANHANG_FAILURE: MUTATION_PREFIX + 'SEND_ANHANG_FAILURE',
    DELETE_ANHANG_SUCCESS: MUTATION_PREFIX + 'DELETE_ANHANG_SUCCESS',
    DELETE_ANHANG_FAILURE: MUTATION_PREFIX + 'DELETE_ANHANG_FAILURE',
    SEND_ZAHLUNG_SUCCESS: MUTATION_PREFIX + 'SEND_ZAHLUNG_SUCCESS',
    SEND_ZAHLUNG_FAILURE: MUTATION_PREFIX + 'SEND_ZAHLUNG_FAILURE',
    SEND_BESCHAEDIGT_SUCCESS: MUTATION_PREFIX + 'SEND_BESCHAEDIGT_SUCCESS',
    SEND_BESCHAEDIGT_FAILURE: MUTATION_PREFIX + 'SEND_BESCHAEDIGT_FAILURE',
    DELETE_BESCHAEDIGT_SUCCESS: MUTATION_PREFIX + 'DELETE_BESCHAEDIGT_SUCCESS',
    DELETE_BESCHAEDIGT_FAILURE: MUTATION_PREFIX + 'DELETE_BESCHAEDIGT_FAILURE',
    DELETE_ZAHLUNG_SUCCESS: MUTATION_PREFIX + 'DELETE_ZAHLUNG_SUCCESS',
    DELETE_ZAHLUNG_FAILURE: MUTATION_PREFIX + 'DELETE_ZAHLUNG_FAILURE',
    GET_CONFIG_DATA_SUCCESS: MUTATION_PREFIX + 'GET_CONFIG_DATA_SUCCESS',
    GET_PDF_DATA_SUCCESS: MUTATION_PREFIX + 'GET_PDF_DATA_SUCCESS',
    RESET_SCHADENSMELDUNG: MUTATION_PREFIX + 'RESET_SCHADENSMELDUNG',
    APPEND_SCHADENSMELDUNG_EDITED: MUTATION_PREFIX + 'APPEND_SCHADENSMELDUNG_EDITED',
    RESET_SCHADENSMELDUNG_EDITED: MUTATION_PREFIX + 'RESET_SCHADENSMELDUNG_EDITED',
    SET_ATTACHMENTS: MUTATION_PREFIX + 'SET_ATTACHMENTS',
    BETEILIGTE_EDITED: MUTATION_PREFIX + 'BETEILIGTE_EDITED',
  },
  ACTIONS: {
    RETRIEVE_SCHADENSMELDUNG: ACTIONS_PREFIX + 'RETRIEVE_SCHADENSMELDUNG',
    RETRIEVE_SCHADEN_BEARBSTAND_VALUES: ACTIONS_PREFIX + 'RETRIEVE_SCHADEN_BEARBSTAND_VALUES',
    SEND_SCHADENSMELDUNG: ACTIONS_PREFIX + 'SEND_SCHADENSMELDUNG',
    SEND_BETEILIGTE: ACTIONS_PREFIX + 'SEND_BETEILIGTE',
    DELETE_BETEILIGTE: ACTIONS_PREFIX + 'DELETE_BETEILIGTE',
    SEND_ANHANG: ACTIONS_PREFIX + 'SEND_ANHANG',
    DELETE_ANHANG: ACTIONS_PREFIX + 'DELETE_ANHANG',
    DELETE_ZAHLUNG: ACTIONS_PREFIX + 'DELETE_ZAHLUNG',
    DELETE_BESCHAEDIGT: ACTIONS_PREFIX + 'DELETE_BESCHAEDIGT',
    SEND_ZAHLUNG: ACTIONS_PREFIX + 'SEND_ZAHLUNG',
    SEND_BESCHAEDIGT: ACTIONS_PREFIX + 'SEND_BESCHAEDIGT',
    GET_CONFIG_DATA: ACTIONS_PREFIX + 'GET_CONFIG_DATA',
    GET_PDF_DATA: ACTIONS_PREFIX + 'GET_PDF_DATA',
    MERGE_SCHADEN: ACTIONS_PREFIX + 'MERGE_SCHADEN'
  },
  GETTERS: {
    SCHADENSMELDUNG_LIST: GETTERS_PREFIX + 'SCHADENSMELDUNG_LIST',
    SCHADEN_BEARBSTAND_VALUES: GETTERS_PREFIX + 'SCHADEN_BEARBSTAND_VALUES',
    LAST_INSERTED_BETEILIGTE: GETTERS_PREFIX + 'LAST_INSERTED_BETEILIGTE',
    LAST_DELETED_BETEILIGTE: GETTERS_PREFIX + 'LAST_DELETED_BETEILIGTE',
    LAST_SENT_ANHANG: GETTERS_PREFIX + 'LAST_SENT_ANHANG',
    LAST_DELETED_ANHANG: GETTERS_PREFIX + 'LAST_DELETED_ANHANG',
    LAST_SENT_ZAHLUNG: GETTERS_PREFIX + 'LAST_SENT_ZAHLUNG',
    LAST_SENT_BESCHAEDIGT: GETTERS_PREFIX + 'LAST_SENT_LAST_SENT_BESCHAEDIGT',
    LAST_DELETED_ZAHLUNG: GETTERS_PREFIX + 'LAST_DELETED_ZAHLUNG',
    CONFIG_DATA: GETTERS_PREFIX + 'CONFIG_DATA',
    PDF_DATA: GETTERS_PREFIX + 'PDF_DATA',
    LAST_SENT_BESCHAEDIGT: GETTERS_PREFIX + 'LAST_SENT_BESCHAEDIGT',
    LAST_DELETED_BESCHAEDIGT: GETTERS_PREFIX + 'LAST_DELETED_BESCHAEDIGT',
    SCHADENSMELDUNG: GETTERS_PREFIX + 'SCHADENSMELDUNG',
    SCHADENSMELDUNG_EDITED: GETTERS_PREFIX + 'SCHADENSMELDUNG_EDITED',
    FILES_FROM_DOKUMENTENARCHIV: GETTERS_PREFIX + 'FILES_FROM_DOKUMENTENARCHIV',
    BETEILIGTE_EDITED: GETTERS_PREFIX + 'BETEILIGTE_EDITED',
  }
}