<template>
  <div class="formulare-list__container">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="`Wertpapiere für ${selectedMusterdepot.label || ''}`" 
      :actions="headerActions"
      @action-ADD="addFond()" 
      @action-FONDSVERGLEICH="openFondsvergleich()" 
      @action-IMPORT="$refs.importWertpapiereModal.open()" 
    />

    <div class="box__container" :tid="_generateTidFromString('box__container')">

      <InputField 
        :disabled="loading"
        isComponentHalfSize
        placeholder="Suchen nach Wertpapier / Kategorie / ISIN"
        v-model="descriptionSearch"
      />

    </div>

    <div class="box__container">
      <Table 
        rowId="isin"
        :rowsPerPage="20"
        :headers="headers"
        :rows="rows"
        :headerActions="tableHeaderActions"
        :exportConfig="exportConfig"
        :mobileConfig="{title: 'bezeichnung', headers: ['kategorie', 'isin', 'anteilProzent']}"
        v-model="selectedRows"
        v-if="rows && rows.length"
        @click-bezeichnung="gotoFondsinfo($event.isin)"
        @action-DELETE="deleteWertpapier([$event])"
        @headerAction-BULK_DELETE="deleteWertpapier(selectedRows)"
      >

          <template #fondsAmpel="row">
            <div style="margin-left:11px" :class="row.fondsAmpel"></div>
          </template>

          <template #anteilProzent="row">
            <InputField
              v-if="!row.mobileTableContext"
              type="percent"
              :precision="2"
              style="width:100px"
              :id="'anteilProzent' + row.index"
              :value="row.anteilProzent"
              @change="updateAnteilProzent(row, $event)"
            />
            <span v-else>{{row.anteilProzent || 0}} %</span>
          </template>
      </Table>
      <div v-if="rows && rows.length">
        <div class="box__title">
          Prozent gesamt: <span :class="{'color-danger': percentSum != 100}">{{ percentSum }}</span>
        </div>
        <div class="box__title">
          Durchschnittliche SRI: {{ averageSRI }}
        </div>
      </div>
      <NoData v-else-if="!loading" />
      <GhostLoading type="table" v-else :config="{ table: { rows: 20, }, }"/>
    </div>

    <BaseModal ref="importWertpapiereModal"  labelButtonCancel="Zurück" labelButtonConfirm="Ok"
       @onConfirmButton="importWertpapiere()" @close="resetImportData()">
      <template v-slot:modalTitle>
        Wertpapiere importieren
      </template>
      <DragnDropArea hoverText="Hochladen" @files="onFileChange">
        ISIN-Liste:<br>
        <BaseFileSelect @files="onFileChange" accept='.txt' :class="{'mb-3': !importData.file}">
          Textdatei hochladen
        </BaseFileSelect>
        <div>
          <div class="input-chip" v-if="importData.file">
            <span>{{importData.file && importData.file.fileName}}</span>
            <ph-x-circle class="clickable ml-1" :size="16" @click.stop="importData.file = null" />
          </div>
        </div>
        <div class="mb-3">
          <b>Dateiformat:</b> Textdatei (.txt)<br>
          <b>Dateiaufbau:</b><br>
          <b>Beispiel 1:</b><br>
          ISIN 1<br>
          ISIN 2<br>
          ISIN ...<br>
          <b>Beispiel 2:</b> <br>
          ISIN 1, ISIN 2, ISIN ...<br>
        </div>
        <InputToggleSwitch 
          class="mt-2" 
          style="padding-bottom:0px" 
          v-model="importData.replace"
          label="<b>Alle</b> Wertpapiere ersetzen"
          inLineLabel
        />
      </DragnDropArea>
    </BaseModal>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue'
import axios from 'axios';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import { PhPencilLine, PhCheck, PhXCircle } from "phosphor-vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction, LinkAction} from "@/components/table2/table_util.js";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import NoData from '@/components/core/NoData.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    InputToggleSwitch,
    GhostLoading,
    BaseModal,
    PhPencilLine,
    PhCheck,
    PhXCircle,
    BaseFileSelect,
    DragnDropArea,
    NoData,
  },
  data() {
    return {
      descriptionSearch: '',
      loading: false,
      selectedMusterdepot: {},
      tableRows: [],
      selectedRows: null,
      importData: {
        file: null,
        replace: false,
      },
      destination: null,
      anteilProzentChanged: false,
    }
  },

  computed: {
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Wertpapier hinzufügen').withVisible(() => this.selectedMusterdepot?.owner).withDisabled(() => this.loading),
        PageHeaderSimpleAction('FONDSVERGLEICH', 'Fondsvergleich').withVisible(() => this.selectedMusterdepot?.owner).withDisabled(() => this.loading),
        PageHeaderSimpleAction('IMPORT', 'Wertpapiere importieren').withVisible(() => this.selectedMusterdepot?.owner).withDisabled(() => this.loading),
      ];
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withVisible(() => this.selectedMusterdepot.owner && this.rows.length)
        .withDisabled(() => this.loading || !this.selectedRows.length),
      ];
    },
    averageSRI() {
      let result = 0
      if (this.rows && this.rows.length) {
        this.rows.forEach(row => result += Number(row.risikozahl))
        result = result/this.rows.length
      }

      return result.toFixed(2)
    },
    percentSum() {
      let result = 0
      if (this.rows && this.rows.length) {
        this.rows.forEach(row => result += row.anteilProzent)
      }

      return result
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        name: this.selectedMusterdepot.label, 
        dispatch: this.getExport,
      };
    },  
    headers() {
      const headers = {
          lockedLeft: [
              TextColumn("bezeichnung", "Wertpapier", 400).makeLink(),
              TextColumn("kategorie", "Kategorie", 400),
          ],
          center: [
              SlotColumn("fondsAmpel", "Ampel"),
          ],
          lockedRight: [
            ActionColumn('actions', 'Aktionen')
          ],
      };


      const fundstarsFww = TextColumn("fundstarsFww", "Fundsstars FWW")
      fundstarsFww.classes = 'ml-fundstarsFww'
      headers.center.push(fundstarsFww)

      const risikozahl = TextColumn("risikozahl", "SRI")
      risikozahl.classes = 'ml-risikozahl'
      headers.center.push(risikozahl)

      headers.center.push(TextColumn("isin", "ISIN"))

      if (this.selectedMusterdepot && this.selectedMusterdepot.owner) {
        headers.lockedLeft.push(SlotColumn("anteilProzent", "Anteil (%)"))
      } else {
        const anteilProzent = TextColumn("anteilProzent", "Anteil (%)")
        anteilProzent.classes = 'ml-anteilProzent'
        headers.lockedLeft.push(anteilProzent)
      }

      return headers;
    },
    rows() {
      if (!this.tableRows || !this.tableRows.length)
        return []

      let records = this.tableRows.map(row => {
        let rowWithData = {
            data: row,
            ...row,
        };
        let actions = this.makeActions(rowWithData);
        return {...rowWithData, actions};
      });

      if (this.descriptionSearch.length > 0) {
        const regex = new RegExp(this.descriptionSearch, "i");
        records = records.filter(r => regex.test(r.bezeichnung) || regex.test(r.kategorie) || regex.test(r.isin));
      }

      return records;
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    updateAnteilProzent(row, value) {
      this.anteilProzentChanged = true
      const rowToUpdate = this.tableRows.find(item => item.isin === row.isin)
      rowToUpdate.anteilProzent = Number(value)
    },
    initialize() {
      this.loading = true;

      let serviceUrl = '/musterdepotService/load';

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl + `?musterdepotId=${this.$route.params.musterdepotId}`, config).then(response => {
          if (response && response.data) {
            this.selectedMusterdepot = response.data
            if (this.selectedMusterdepot.owner) {
              this.selectedRows = []
            }
            this.requestWpList()
          }}
      ).catch(() => this.loading = false);
    },
    async getExport(args) {

      await this.saveAnteile()

      let serviceUrl = `/musterdepotService/create_xls_pdf?musterdepotId=${this.selectedMusterdepot.id}&create=${args}`;

      return axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config)
    },
    resetImportData() {
      this.importData = {
        file: null,
        replace: false,
      }
    },
    onFileChange(files) {
      const reader = new FileReader();
      reader.onload = e => {
        let base64string = reader.result.split(",").pop();
        this.importData.file = {
          fileName: files[0].name,
          data: base64string
        }
      }
      reader.readAsDataURL(files[0]);
    },
    importWertpapiere() {
      if (!this.importData?.file) {
        return
      }

      this.loading = true
      const payload = {
        ...this.importData,
        musterdepot: {id: this.selectedMusterdepot.id},
      }
      let serviceUrl = '/musterdepotService/importParts';
      axios.post(`${process.env.VUE_APP_API}` + serviceUrl, payload, config)
        .then(() => this.requestWpList())
        .catch(() => this.loading = false);
    },
    addFond() {
      this.$addBreadcrumb({
        label: 'zurück zur Wertpapierliste', 
        fullPath: this.$route.fullPath,
      });
      this.$router.push(`/intern/mydepot/wertpapiere/${this.selectedMusterdepot.id}/search`)
    },
    openFondsvergleich() {
      this.$addBreadcrumb({
        label: 'zurück zur Wertpapierliste', 
        fullPath: this.$route.fullPath,
      });
      this.$router.push('/beratung/fondsvergleich')
    },
    gotoFondsinfo(isin) {
      this.$addBreadcrumb({
        label: 'zurück zur Wertpapierliste',
        fullPath: this.$route.fullPath,
      });
      const path = `/shared/vermogensubersicht/fondsinfo/${isin}`;
      this.$router.push({ path });
    },
    deleteWertpapier(rows) {
      this.$confirmModal({
        title: rows.length === 1 ? 'Wertpapier' : 'Wertpapiere' + ' entfernen',
        message: rows.length === 1 ? `Wollen Sie das Wertpapier \"${rows[0].bezeichnung}\" wirklich entfernen?` 
          : 'Wollen Sie die ausgewählten Wertpapiere wirklich entfernen?',
        labelButtonConfirm: 'Entfernen',
      }).then(() => {
        this.loading = true

        const isinList = rows.map(row => row.isin)
        const params = makeQueryParam({isinList, musterdepotId: this.selectedMusterdepot.id})

        axios.delete(`${process.env.VUE_APP_API}/musterdepotService/remove_wps?${params}`, config)
        .then(() => {
          isinList.forEach(isin => {
            const index = this.tableRows.findIndex(row => row.isin === isin);
            if (index >= 0) {
              this.tableRows.splice(index, 1);
            }
          })
          this.selectedRows = []
        }).finally(() => this.loading = false)
      }).catch(() => {})
    },
    makeActions(child) {
      const actions = [];

      child.actions.forEach(action => {
        if (action.href) {
          actions.push(LinkAction(action.key, action.icon, action.label, action.href))
        } else {
          actions.push(SimpleAction(action.key, action.icon, action.label))
        }
      })
      return actions;
    },
    saveAnteile() {
      if (!this.anteilProzentChanged)
        return

      this.loading = true

      let serviceUrl = `/musterdepotService/save_anteile?musterdepotId=${this.selectedMusterdepot.id}`;

      return axios.post(`${process.env.VUE_APP_API}` + serviceUrl, {tableRows: this.tableRows}, config)
        .then(() => this.anteilProzentChanged = false)
        .finally(() => this.loading = false);
    },
    async requestWpList() {
      await this.saveAnteile()

      this.loading = true;

      const cancelableRequestConfig = {
        defaultSpinner: true,
        cancelableRequest: {
          title: 'Musterdepot Wertpapiere'
        }
      };

      let serviceUrl = '/musterdepotService/wp_list';

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl + `?musterdepotId=${this.selectedMusterdepot.id}`, cancelableRequestConfig).then(response => {
        if (response && response.data) {
          this.tableRows = response.data
        }
      }).finally(() => this.loading = false)
    },   
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveAnteile()
    next();
  },
}
</script>

<style scoped>
.ampel-rot, .ampel-gelb, .ampel-gruen, .ampel-none{
 height: 16px;
 width: 16px;
 border-radius: 50%;
}
.ampel-rot{
 background-color: var(--color-danger);
}
.ampel-gelb{
 background-color: var(--color-warning);
}
.ampel-gruen{
 background-color: var(--color-success);
}
.ampel-none{
 background-color: transparent;
}
.input-chip {
  border-radius: 4px;
  border: 1px solid var(--color-text);
  padding: 0 4px;
  display: inline-block;
  margin: 10px 0px 4px 0px;
}
::v-deep .ml-risikozahl > .text-cell {
  margin-left: 5px;
}
::v-deep .ml-fundstarsFww > .text-cell {
  margin-left: 45px;
}
::v-deep .ml-anteilProzent > .text-cell {
  margin-left: 20px;
}
</style>