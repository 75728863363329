var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Kundennummern Gesellschaft",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addKundenGesellNr()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "14dd3103-0cdf-4fa7-82a4-5325d84c0d2f",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "click-fremdnr": function($event) {
                    return _vm.openKundenGesellNr($event)
                  },
                  "action-DELETE": function($event) {
                    return _vm.deleteKundenGesellNr($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }