var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Eingegangene Dokumente",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "c44d470b-fa6d-47ad-b9e6-3e90e866c10b",
          metadata: _vm.searchMenu,
          defaultOptions: _vm.defaultOptionsData,
          configFilter: _vm.configFilter,
          isCustomerSearch: false,
          immidiateSearch: "",
          showSaveButton: "",
          isCache: ""
        },
        on: { onFilter: _vm.search }
      }),
      _c(
        "div",
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  title: _vm.tableTitle,
                  config: { table: { rows: 6 } }
                }
              })
            : _vm.rows.length
            ? _c("Table", {
                attrs: {
                  title: _vm.tableTitle,
                  tableId: "7519921b-de14-431a-9fc0-e8c61413c250",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "faxEingangID",
                  rowsPerPage: _vm.rowsPerPage
                },
                on: {
                  "click-bezeichnung": function($event) {
                    return _vm.openDokumentPruefung($event)
                  },
                  "action-OPENLEGIEXTRA": function(row) {
                    return _vm.openDokumentPruefung(row, true)
                  }
                }
              })
            : _c("NoData", { attrs: { title: _vm.tableTitle } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }