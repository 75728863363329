<template>
<div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="BiPRO Kategorien Ausnahmen" />

    <BaseFilter v-if="gesellschaftOptions"
        title="BiPRO Ausnahmen Übersicht"
        filterId="BiPRO Kategorien Ausnahmen"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        @onFilter="onFilter"
    />

    <div class="box__container">
        <GhostLoading v-if="loading" type="table"/>
        <BigTable  v-else-if="rows.length" style="clear: both;"
            selectable
            title="BiPRO Ausnahmen Übersicht"
            tableId="08977d53-c495-4765-bd86-77e5ca8c5d6f"
            :headers="headers"
            :rows="rows"
            rowId="gevoArt"
            :page="page"
            :sortingState="sortingState"
            :headerActions="headerActions"
            :initialPageSize="minNumRows"
            @onClickHeader="onClickHeader"
            @selectedRow="onRowSelection"
            @selectedRows="onAllRowsSelection"
            @headerAction-SAVE="save"


        />
        <NoData v-else/>
    </div>
</div>
</template>

<script>
import BIPRO_TYPES from '@/store/bipro/types'
import LOG_TYPES from '@/store/log/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { mapGetters } from 'vuex';
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";

import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, INTEGER_COLUMN} from '@/components/bigTable/BigTable.vue';
import { PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';


const CONFIG = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        GhostLoading,
        NoData,
        BigTable,
    },
    data() {
        return {
            loading: false,
            selected: [],
            filterConfig: {
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {
            },
            filterParams: {},
            headers: [
                { label: "BiPRO Gevo Art", key: "gevoArt", width: 200, type: INTEGER_COLUMN, position: FIXED_LEFT },
                { label: "BiPRO Unterkategorie", key: "unterKategorie", width: 800, type: STRING_COLUMN, visible: true },
                { label: "Kategorie MSC", key: "hauptKategorie", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Vers. Status MSC.", key: "statusFK", width: 400, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],
            sortingState: { key: "gevoArt", direction: "desc" },
            minNumRows: 100,
            page: 0,
            rows: [],  

        };
    },
    computed: {
        ...mapGetters({
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS,
        }),
        metadata() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'combobox',
                            label: 'Gesellschaft',
                            key: 'gesellschaft',
                            comboOptions: this.gesellschaftOptions,
                        },
                    ],
                },
            ];
        },
        headerActions() {
            const actions = [PageHeaderConfirmedAction('SAVE', 'Änderungen speichern', 'Sollen die Änderungen wirklich gespeichert werden?', 'Änderungen speichern', 'Speichern').withDisabled(() => !this.rows.some(item => item.selected))];
            return actions;
         },
         selectionChanges() {
            const result = [];
            for (const row of this.rows) {
                if (row.checked != this.selected.some(r => r.gevoArt == row.gevoArt)) {
                    result.push({gevoArt: row.gevoArt, checked: !row.checked});
                }
            }
            return result;
        }                    
    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
    },
    methods: {
        onFilter(filterConfig) {
            if (!this.gesellschaftOptions)
                return;
            this.filterParams = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                this.filterParams[fc.key] = value;
            });
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API}/bipro_exceptions/filter`, this.filterParams, CONFIG)
                this.rows = response.data || [];
            } catch (_) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            } finally {
                this.loading = false;
            }
        },
        save() {
            const data = {
                gesellschaft: this.filterParams.gesellschaft,
                rows: this.selectionChanges,
            }
            axios.post(`${process.env.VUE_APP_API}/bipro_exceptions/save`, data, CONFIG)
            .then(_ => {
                this.loadRows();
            });
        },
        onRowSelection(payload) {
            if (payload?.action == "added") {
                // Check if the row is not already selected before adding
                if (!this.selected.some(item => item === payload.row)) {
                    this.selected.push(payload.row);
                }
            } else if (payload?.action == "removed") {
                // Find the index of the row in selected array
                const index = this.selected.findIndex(item => item === payload.row);
                // If found, remove it
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
        },
        onAllRowsSelection(payload)  {
            this.selected = payload;
        },  
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            // TODO: save the sorting state
        }, 

    },
}
</script>
