var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TeleportToBody",
    [
      _c(
        "ResizableModal",
        {
          ref: "resizablePopup",
          attrs: {
            modalTitle: _vm.modalTitle,
            initialWidth: _vm.initialWidth,
            initialHeight: _vm.initialHeight,
            sizeIsPercentage: _vm.sizeIsPercentage,
            startPosition: _vm.startPosition,
            id: _vm.id,
            fullClientHeight: _vm.fullClientHeight,
            saveLastPosition: _vm.shouldSaveLastPosition
          },
          on: {
            onRestore: _vm.onRestore,
            onMinimize: _vm.onMinimize,
            onMaximize: _vm.onMaximize,
            onResize: _vm.resizeObjectContainer,
            onClose: _vm.cleanUpMemory
          },
          scopedSlots: _vm._u([
            {
              key: "header-buttons",
              fn: function() {
                return [
                  _vm.usePDFJs && !_vm.isMinimized
                    ? [
                        _vm.pageCount > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "base-modal__header--button clickable",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.pageNum <= 1
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.prevPage()
                                  }
                                }
                              },
                              [_c("PhCaretLeft", { attrs: { size: 24 } })],
                              1
                            )
                          : _vm._e(),
                        _vm.pageCount > 1
                          ? _c("span", { staticClass: "current__pdf__page" }, [
                              _vm._v(_vm._s(_vm.pageNum))
                            ])
                          : _vm._e(),
                        _vm.pageCount > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "base-modal__header--button clickable",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.pageNum >= _vm.pageCount
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.nextPage()
                                  }
                                }
                              },
                              [_c("PhCaretRight", { attrs: { size: 24 } })],
                              1
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "spacer" }),
                        _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.zoomIn()
                              }
                            }
                          },
                          [
                            _c("PhMagnifyingGlassPlus", { attrs: { size: 24 } })
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.zoomOut()
                              }
                            }
                          },
                          [
                            _c("PhMagnifyingGlassMinus", {
                              attrs: { size: 24 }
                            })
                          ],
                          1
                        ),
                        _c("span", { staticClass: "spacer" })
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.loading || !_vm.objectUrl
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { ref: "objectContainer", staticClass: "object-container" },
            [
              _vm.usePDFJs
                ? _c("canvas", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading,
                        expression: "!loading"
                      }
                    ],
                    ref: "pdfCanvas"
                  })
                : _c(
                    "iframe",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loading,
                          expression: "!loading"
                        }
                      ],
                      attrs: {
                        src: _vm.objectUrl,
                        width: "100%",
                        height: "100%"
                      },
                      on: {
                        load: function($event) {
                          _vm.loading = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        " Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: "
                      ),
                      _c("a", { attrs: { href: _vm.objectUrl } }, [
                        _vm._v("Herunterladen")
                      ]),
                      _vm._v(". ")
                    ]
                  )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }