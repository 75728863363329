<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="getPageTitle" />

    <div tid="5414fd93-6e3c-4b11-850c-380d6a5dd64c" v-if="tarifdatenVorgabe && tarifdatenVorgabe.length">
      <StepperForm
        ref="stepperForm"
        stepType="tab"
        stepperName="tarifdaten-vorgabe"
        :stepperMediator="tabsStepperMediator"
        :hasFertig="false"
        :selectedStepKey="selectedStepKey"
        @set-step="setStepByKey"
      >
        <template #contentTemplate>
          <div class="box__container" >
            <div v-for="component in getStepComponents" :key="component.key">
                <div class="col-9 col-sm-4">
                  <InputField
                    v-if="component.type == 'TEXT'"
                    :label="component.bezeichnung"
                    v-model="component.value"
                    :disabled="component.disabled"
                  />
                  <InputField
                    v-if="component.type == 'TEXT_CURRENCY'"
                    :label="component.bezeichnung"
                    v-model="component.value"
                    type="currency"
                    :disabled="component.disabled"
                  />     
                  <InputField
                    v-if="component.type == 'TEXT_NUMBER'"
                    :label="component.bezeichnung"
                    v-model="component.value"
                    type="number"
                    :disabled="component.disabled"
                  />                                 
                  <DatePickerField
                    v-if="component.type == 'DATE_PICKER'"
                    :label="component.bezeichnung"
                    v-model="component.value"
                    :disabled="component.disabled"
                  />
                  <InputRadioBoxGroup 
                    v-if="component.type == 'SINGLE_SELECTION'"
                    :title="component.bezeichnung"
                    v-model="component.value"
                    :validateUntouched="true"
                    :values="component.possibleValues" />
                  <InputToggleSwitch
                    v-if="component.type == 'SWITCHER'"
                    :label="component.bezeichnung"
                    class="parameter-search__input-toggle-switch"
                    v-model="component.value"
                    :disabled="component.disabled"
                  />
                  <ComboBox
                    v-if="component.type == 'COMBOBOX'"
                    :label="component.bezeichnung"
                    v-model="component.value"
                    :values="component.possibleValues"
                    :firstEmpty="false"
                    :disabled="component.disabled"
                  />
                </div>
            </div>





          </div>


        </template>
      </StepperForm>

    </div>


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERSICHERUNGSTARIFE_TYPES from "@/store/versicherungstarife/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';


export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    InputToggleSwitch,
    BaseButton,
    StepperForm,
    DatePickerField,
    InputRadioBoxGroup
  },
  data: function () {
      return {
        resultCount: null,
        loading: false,
        formData : null,
        selectedStepKey: this.steps?.length ? this.steps[0].stepKey : null,
      }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedTarif: VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_VERSICHERUNGSTARIF,
      tarifDokumente: VERSICHERUNGSTARIFE_TYPES.GETTERS.TARIF_DOKUMENTE,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      tarifdatenVorgabe: VERSICHERUNGSTARIFE_TYPES.GETTERS.TARIFDATEN_VORGABE,
    }),
     steps() {
      if (!this.tarifdatenVorgabe) {
        [];
      }
      const steps = this.tarifdatenVorgabe.map(s => ({
        stepKey: s.gruppe,
        label: s.gruppe,
        totalProgress: 1,
      }));

      const key = "stepKey";
      const uniqueSteps = [...new Map(steps.map(item=> [item[key], item])).values()];
      console.log(uniqueSteps);
      return uniqueSteps;
    },
    tabsStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },    
    getStepComponents() {
      return this.tarifdatenVorgabe.filter(tdv => tdv.gruppe==this.selectedStepKey);
    },
    allComponents() {
      if (!this.getStepComponents)
        return [];
      return Object.keys(this.getStepComponents).reduce((acc, key) => {
        return acc.concat(this.getStepComponents[key]);
      }, []);
    },
    getPageTitle() {
      if (this.selectedTarif?.bezeichnung) {
        return `Tarifdaten Vorgabe | ${this.selectedTarif?.bezeichnung}`;
      }
      return "Tarifdaten Vorgabe";
    }
       
  },
  mounted() {
    this.formData = this.tarifdatenVorgabe;

    if(this.selectedTarif?.id) {
      this.loading = true;

      this.retrieveData(this.selectedTarif?.id);
    }
  },
  methods: {
    retrieveData(tarifId) {
      this.$store.dispatch(
              VERSICHERUNGSTARIFE_TYPES.ACTIONS.VORGABE_TARIFDATEN, {tarifId}
            ).then((response) => {
              this.loading = false;
              this.selectedStepKey = this.steps?.length ? this.steps[0].stepKey : null;
              }
            ); 
    },   
    async saveBeforeLeave() {
      this.$store.dispatch(
              VERSICHERUNGSTARIFE_TYPES.ACTIONS.PERSIST_VORGABE, {tarifId:this.selectedTarif.id, definitions: this.tarifdatenVorgabe}
            );  
    },
   async setStepByKey(stepKey) {
/*       if(this.selectedStepKey === stepKey) {
        return ;
      } */

      this.selectedStepKey = stepKey;

      await this.saveBeforeLeave()
        .then((canChangeStep) => {
          if(canChangeStep){
            this.selectedStepKey = stepKey
          }
        })
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Versicherungstarif Bearbeiten', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    // await this.saveBeforeLeave();

    next();
  },


    
}
</script>