<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Data Control Zuordnungen"
      :actions="headerActions"
      @action-IMPORT="importData"
    />
    <div class="box__container">
      <div class="row">
        <div class="col-sm-3 mt-4">
          <BaseButton @click="neueZuordnung">Neues Produkt DC</BaseButton>
        </div>
        <div class="col-sm-3">
          <FormLabel label="Sparte MSC" /><InputField
            @input="setFilterParam('filterSparte', $event)"
          />
        </div>
        <div class="col-sm-3">
          <FormLabel label="Gesellschaft DC" /><InputField
            @input="setFilterParam('filterGesell', $event)"
          />
        </div>
        <div class="col-sm-3 mt-4">
          <BaseButton @click="filter">Suchen</BaseButton>
        </div>
      </div>
    </div>

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />

      <NoData v-else-if="rowCount == 0" :title="TABLE_TITLE" />

      <template v-else-if="rowCount">
        <Table
          tableId="99aaf2c1-69y9-42f3-9618-978k8ea2f459"
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="rows"
          :exportConfig="exportConfig"
          :rowsPerPage="25"
          rowId="id"
          @click-productDC="editZuordnung"
          @action-DELETE="deleteZuordnung"
        />
      </template>
    </div>
    <BaseModal
      modalTitle="Data Control Zuordnungsliste einlesen"
      ref="importModal"
      :showConfirmButton="true"
      labelButtonConfirm="Importieren"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="saveImportedData"
    >
      <InputRadioBoxGroup
        v-model="fileType"
        :values="[
          { label: 'Zuordnungsliste als csv-Datei(Textdatei)', value: '.csv' },
          { label: 'Zuordnungsliste als xlsx-Datei(Excel)', value: '.xlsx' },
        ]"
        @input="setFileType($event)"
      />
      <span>{{ filename }}</span>
      <BaseFileSelect
        class="ml-3"
        :accept="acceptedFileType"
        @files="importFile"
        :multiple="false"
        isSecondary
      >
        Durchsuchen...
      </BaseFileSelect>
    </BaseModal>
    <BaseModal ref="infoModal" :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <PhInfo style="color: var(--color-success)" /> {{ infoText }}
      </template>
    </BaseModal>
    <BaseModal ref="errorModal" :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <PhWarning style="color: var(--color-danger)" /> Es konnte keine Zeilen eingelesen
        werden, da die Zeilen schon vorhanden sind oder die {{ fileType }}-Datei
        konnte nicht gelesen werden!
      </template>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";
import DCS_SYNCHRONISATION_TYPES from "@/store/dcsSynchronisation/types";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util";
import BaseButton from "@/components/core/BaseButton.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormLabel from "@/components/core/forms/FormLabel";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import { uploadFileChunked } from "@/helpers/upload-helper";
import { PhInfo, PhWarning } from "phosphor-vue";

export default {
  data() {
    return {
      TABLE_TITLE: "Data Control Zuordnungen",
      loading: false,
      headers: {
        lockedLeft: [
          TextColumn("sparteMSC", "Sparte MSC"),
          TextColumn("gesellschaftsnameMSC", "Gesellschaftsname MSC"),
          TextColumn("productDC", "Produkt DC").makeLink(),
          TextColumn("gesellschaftsnameDC", "Gesellschaftsname DC"),
          TextColumn("id", "id").makeHidden(),
        ],
        lockedRight: [ActionColumn("actions")],
      },
      exportConfig: {
        pdf: true,
        xls: true,
        name: "Data Control Zuordnung",
        title: "Data Control Zuordnung",
        dispatch: null,
      },
      filterSparte: "",
      filterGesell: "",
      fileType: ".csv",
      filename: "",
      file: {},
      infoText: "",
    };
  },
  computed: {
    ...mapGetters({
      zuordnungsliste: DCS_SYNCHRONISATION_TYPES.GETTERS.ZUORDNUNGSLISTE,
    }),
    acceptedFileType() {
      return this.fileType;
    },
    rows() {
      return (this.zuordnungsliste?.payload || []).map((row) => {
        const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
        return {
          ...row,
          actions,
        };
      });
    },
    rowCount() {
      return this.zuordnungsliste?.payload?.length || 0;
    },
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction("IMPORT", "Importieren"));
      return actions;
    },
  },
  mounted() {
    var payload = { gesell: "", sparte: "" };
    this.loadZordnungsListe(payload);
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    PaginatedTable,
    NoData,
    GhostLoading,
    Table,
    BaseButton,
    InputField,
    FormLabel,
    BaseModal,
    InputRadioBoxGroup,
    BaseFileSelect,
    PhInfo,
    PhWarning,
  },
  methods: {
    importData() {
      //open modal for importing data from files (.cvs or .xlsx)
      this.$refs.importModal.open();
    },
    saveImportedData() {
      if (this.file) {
        this.loading = true;
        uploadFileChunked(this.file).then((id) => {
          let payload = {};
          if (this.file.name?.endsWith(".csv")) {
            payload = { id: id, fileType: ".csv" };
          } else if (this.file.name?.endsWith(".xlsx")) {
            payload = { id: id, fileType: ".xlsx" };
          }
          this.$store
            .dispatch(
              DCS_SYNCHRONISATION_TYPES.ACTIONS.ZUORDNUNGSLISTE_EINLESEN,
              payload
            )
            .then((response) => {
              if (response && response.data && response.data == "0") {
                this.$refs.errorModal.open();
              } else if (response && response.data && response.data != "0") {
                this.infoText =
                  "Es wurden " + response.data + " Zeilen eingelesen.";
                this.$refs.infoModal.open();
              }
              this.loading = false;
              this.filename = "";
              this.loadZordnungsListe({ gesell: "", sparte: "" });
            });
        });
      }
    },
    importFile(file) {
      this.file = file[0];
      this.filename = file[0].name;
    },
    setFileType(type) {
      this.fileType = type;
    },
    loadZordnungsListe(payload) {
      this.loading = true;
      this.$store
        .dispatch(
          DCS_SYNCHRONISATION_TYPES.ACTIONS.GET_ZUORDNUNGSLISTE,
          payload
        )
        .then(() => (this.loading = false));
    },
    deleteZuordnung(row) {
      this.loading = true;
      this.$store
        .dispatch(DCS_SYNCHRONISATION_TYPES.ACTIONS.DELETE_ZUORDNUNG, row.id)
        .then(() => {
          this.loadZordnungsListe({ gesell: "", sparte: "" });
        })
        .then(() => (this.loading = false));
    },
    editZuordnung(row) {
      if (!row || !row.id) {
        return;
      }
      let path = `/intern/data-control-zuordnungen/produkt/${row.id}`;
      if (this.$route.path != path) {
        this.$router.push({ path: path });
      }
    },
    neueZuordnung() {
      let path = `/intern/data-control-zuordnungen/produkt/neu`;
      if (this.$route.path != path) {
        this.$router.push({ path: path });
      }
    },
    setFilterParam(param, value) {
      if (param == "filterGesell") {
        this.filterGesell = value;
      } else {
        this.filterSparte = value;
      }
    },
    filter() {
      const payload = { gesell: this.filterGesell, sparte: this.filterSparte };
      this.loadZordnungsListe(payload);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from });
    next();
  },
};
</script>