var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.openDocumentUploadModal()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6 mb-2" },
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Quartal",
                      values: _vm.fieldsDefinition.quartal.value,
                      disabled: _vm.fieldsDefinition.quartal.disabled
                    },
                    model: {
                      value: _vm.marktberichtUploaden.quartal,
                      callback: function($$v) {
                        _vm.$set(_vm.marktberichtUploaden, "quartal", $$v)
                      },
                      expression: "marktberichtUploaden.quartal"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-sm-6 mb-2" },
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Jahr",
                      values: _vm.fieldsDefinition.year.value,
                      disabled: _vm.fieldsDefinition.quartal.disabled
                    },
                    model: {
                      value: _vm.marktberichtUploaden.year,
                      callback: function($$v) {
                        _vm.$set(_vm.marktberichtUploaden, "year", $$v)
                      },
                      expression: "marktberichtUploaden.year"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hinweisen } }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "28be5c8b-3311-4c3e-9532-021a7ec708de",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 10,
                      rowId: "datei"
                    },
                    on: {
                      "click-datei": _vm.showDocument,
                      "action-DELETE": _vm.openDeleteDocumentConfirmModal
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("BaseModal", {
        ref: "documentUploadModal",
        attrs: {
          modalTitle: "Datei hochladen",
          labelButtonConfirm: "Hochladen",
          showDefaultButtons: false
        },
        on: {
          onCloseButton: function($event) {
            return _vm.cancelDocumentUpload()
          },
          onEscButton: function($event) {
            return _vm.cancelDocumentUpload()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mb-3" },
                    [
                      _c("BaseFileSelect", {
                        on: {
                          files: function($event) {
                            _vm.fileList = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.fileToUpload && _vm.fileToUpload.name
                    ? _c("div", { staticClass: "col-12 mb-2" }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Dateiname: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.fileToUpload.name))])
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "" },
                    on: { click: _vm.cancelDocumentUpload }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: !_vm.canUpload },
                    on: { click: _vm.uploadDocument }
                  },
                  [_vm._v("Hochladen")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "deleteDocumentConfirmModal",
        attrs: { modalTitle: "Löschen", labelButtonConfirm: "Löschen" },
        on: {
          onCloseButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onCancelButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onEscButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onConfirmButton: function($event) {
            return _vm.deleteDocument()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Wollen Sie das Dokument wirklich löschen? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("span", [
        _vm._v(
          "Für welchen Quartalsbericht soll der Marktbericht verwendet werden?"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }