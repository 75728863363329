var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Schriftverkehr",
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions
        },
        on: {
          "action-NEW_MAIL": function($event) {
            return _vm.handleNewEmail()
          }
        }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Schriftverkehr Filter",
              filterId: "PostfachSchriftverkehrFilter",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  rowId: "commId",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 25,
                  headerActions: _vm.tableHeaderActions,
                  exportConfig: { roottext: "Postfach" },
                  mobileConfig: { title: "name", headers: ["subject", "date"] }
                },
                on: {
                  "click-name": function($event) {
                    return _vm.openCustomerNewTab($event.nummer)
                  },
                  "action-DELETE": _vm.deleteRow,
                  "headerAction-BULK_DELETE": _vm.deleteSelected
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "subject",
                      fn: function(row) {
                        return [
                          !row.mobileTableContext
                            ? _c("DownloadLink", {
                                attrs: {
                                  title: row.subject,
                                  disabled: !row.commId,
                                  downloadServicePath: "/get_simple_file",
                                  filename: row.subject.includes(".pdf")
                                    ? row.subject
                                    : row.subject + ".pdf",
                                  queryParams: {
                                    fileId: row.commId
                                  }
                                }
                              })
                            : _c("div", [_vm._v(_vm._s(row.subject))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3598924696
                ),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 5 } } }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }