<template>
	<div>
		<OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Nicht zugeordnete E-Mail-Adressen" />

		<div class="box__container">
			<GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
			<UnexpectedError v-else-if="error"/>
			<NoData v-else-if="rows.length == 0" :title="TABLE_TITLE" />
			<Table
				tableId="0f076559-668a-42a6-83e1-e3a9de3aa9e8"
				:title="TABLE_TITLE"
				:headers="headers"
				:rows="rows"
				:rowsPerPage="20"
				@action-SEARCH="openCustomerSearch"
				@action-DELETE="deleteEmailRecognized"
				v-else
			/>
			
		</div>
	</div>
</template>

<script>
import EMAIL_NOT_RECOGNIZED_TYPES from '@/store/emailNotRecognized/types'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from '@/components/table2/Table.vue'
import { TextColumn, ActionColumn, ConfirmedAction, SimpleAction } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import { PhMagnifyingGlass, PhTrash, } from 'phosphor-vue';
import NoData from '@/components/core/NoData.vue';
import UnexpectedError from '@/components/core/UnexpectedError.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const TABLE_TITLE = 'E-Mail Adressen in Ihrem Postfach, die nicht zugeordnet werden können';

export default {

	data() {
		return {
			TABLE_TITLE,
			loading: false,
			error: false,
		}
	},

	computed: {
		...mapGetters({
			emailNotRecognized: EMAIL_NOT_RECOGNIZED_TYPES.GETTERS.EMAIL_NOT_RECOGNIZED
		}),
		headers() {
			return {
				lockedLeft: [
					TextColumn("name", "Name").makeAlwaysVisible(),
				],
				center: [
					TextColumn("email", "Adresse"),
					ActionColumn("actions", "")
				],
			};
		},
		rows() {
			const actions = (email) => {
				return [
					SimpleAction('SEARCH', PhMagnifyingGlass, 'Kontakt suchen'),
					ConfirmedAction('DELETE', PhTrash, 'Löschen', `Möchten Sie die Adresse ${email.email} wirklich löschen?`, 'Adresse löschen', 'Löschen'),
				]
			};

			return this.emailNotRecognized?.map(email => ({
				...email,
				actions: actions(email)
			})) || []
		}
	},

	methods: {
		async deleteEmailRecognized(row) {
			try {
				this.error = false
				this.loading = true
				await this.$store.dispatch(EMAIL_NOT_RECOGNIZED_TYPES.ACTIONS.REMOVE_EMAIL_NOT_RECOGNIZED, row.id)
				this.loading = false
			} catch (error) {
				this.loading = false
				this.error = true
			}
		},

		openCustomerSearch(row) {
			this.$router.push({ path: `/customer/customer-search/${row.nameSearchParameter}` });
		},

		async loadEmails() {
			try {
				this.error = false
				this.loading = true
				await this.$store.dispatch(EMAIL_NOT_RECOGNIZED_TYPES.ACTIONS.GET_EMAIL_NOT_RECOGNIZED)
				this.loading = false
			} catch (error) {
				this.loading = false
				this.error = true
			}
		}
	},

	async mounted() {
		await this.loadEmails();
	},

	beforeDestroy() {
		this.$store.commit(EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.RESET_STATE)
	},

	beforeRouteLeave(to, from, next) {
		this.$addBreadcrumb({
			label: 'zurück zur E-Mail-Adressen', 
			fullPath: this.$route.fullPath,
			breadcrumb: 'Kundensuche',
		});

		next();
	},

	components: {
		OptionMenu,
		PageHeaderTitleNavigation,
		Table,
		NoData,
		UnexpectedError,
		GhostLoading,
	}

}
</script>

<style scoped>

</style>