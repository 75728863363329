var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "OptionMenu",
        _vm._b(
          {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          },
          "OptionMenu",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: { id: _vm.$appNavigation.currentOptionMenuId },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._t("title", function() {
                        return [_vm._v("Lizenzen")]
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          },
          _vm.$listeners
        )
      ),
      _c(
        "BoxContainer",
        [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.licensesMapped, function(license, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "col-12 d-flex justify-content-between"
                },
                [
                  _c("span", { staticClass: "text-bold" }, [
                    _vm._v(_vm._s(license.key + ": "))
                  ]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openLicenseFile(license)
                        }
                      }
                    },
                    [_vm._v(_vm._s(license.value))]
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "BaseModal",
            {
              ref: "licenseModal",
              attrs: {
                size: "md",
                modalTitle: "License",
                labelButtonConfirm: "Ok",
                showDefaultButtons: false
              },
              on: { close: _vm.closeModal }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("pre", [_vm._v(_vm._s(_vm.currentLicense))])
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }