import templateRoutes from '@/views/cms/templateRoutes'
import { PageMenuCfg } from '@/menu/menu-utils';

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "home-view" */ '@/components/cms/HomeViewRoot.vue'),
    meta: {
      ...PageMenuCfg().noMenu(),
    },
    children: [
      ...templateRoutes
    ],
  },
  {
    // if nothing matches, goes to HomeView.vue
    path: '*',
    redirect: '/'
  },
]