var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: { action: _vm.handleHeaderAction }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "4a1a843e-b03b-4a02-bfd4-06462cb6408a",
          title: "Honorare filtern",
          metadata: _vm.filterMetadata,
          defaultOptions: _vm.defaultOptionsData
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          }
        }
      }),
      _vm.tableRows.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "cf7b3ede-5436-4497-a3a1-9c5df98235d3" }
            },
            [
              !_vm.loading && _vm.tableRows.length
                ? _c("Table", {
                    staticClass: "table-container",
                    attrs: {
                      tableId: "618e4673-b92d-4b5a-aea7-47836ac4f7b6",
                      headers: _vm.tableHeaders,
                      rows: _vm.tableRows,
                      rowsPerPage: 20,
                      count: _vm.resultCount,
                      exportConfig: _vm.exportHonorare
                    },
                    on: {
                      "action-EDIT_ITEM": function($event) {
                        return _vm.bearbeiten($event)
                      },
                      "action-REMOVE_ITEM": function($event) {
                        return _vm.openDeleteHonorareConfirmModal($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "honorarBestaetigen",
                          fn: function(row) {
                            return [
                              row.status == "CONFIRMED"
                                ? _c("Pill", {
                                    attrs: {
                                      label: row.friendlyStatusName,
                                      type: "success"
                                    }
                                  })
                                : row.status == "PENDING"
                                ? _c("Pill", {
                                    attrs: {
                                      label: row.friendlyStatusName,
                                      type: "warning"
                                    }
                                  })
                                : _c("Pill", {
                                    attrs: {
                                      label: row.friendlyStatusName,
                                      type: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openConfirmationModal(row)
                                      }
                                    }
                                  })
                            ]
                          }
                        },
                        {
                          key: "id",
                          fn: function(row) {
                            return [
                              row.invoiceNr
                                ? _c("DownloadLink", {
                                    attrs: {
                                      asLink: "",
                                      title: row.invoiceNr,
                                      href: _vm.getPDFLink(row)
                                    }
                                  })
                                : _c("span", [_vm._v("Keine")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2817970764
                    )
                  })
                : _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("NoData")
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "markItemConfirmation",
          attrs: {
            modalTitle: "Honorar für Bestätigung vormerken",
            labelButtonConfirm: "Ja"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.markForConfirmation()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.confirmationQuestion) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "abrechnungenbestaetigenConfirmation",
          attrs: { modalTitle: "Honorar bestätigen", labelButtonConfirm: "Ja" },
          on: {
            onConfirmButton: function($event) {
              return _vm.abrechnungenbestaetigen()
            }
          }
        },
        [
          _vm._v(
            " Wollen Sie die für die Bestätigung vorgemerkten Honorare wirklich bestätigen? Hierbei werden dann die Rechnungsnummern vergeben und können nicht mehr geändert werden "
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "keineBestaetigtenAbrechnungenModal",
          attrs: {
            modalTitle: "keine bestätigten Abrechnungen",
            showConfirmButton: false
          }
        },
        [_vm._v(" Es gibt keine bestätigten Abrechnungen zu versenden. ")]
      ),
      _c("HonorareLoeschenConfirmModal", {
        ref: "alleLoeschenConfirmationModal",
        on: {
          confirmed: function($event) {
            return _vm.alleLoeschen($event)
          }
        }
      }),
      _c("HonorareLoeschenConfirmModal", {
        ref: "deleteHonorareConfirmModal",
        on: {
          cancelled: function($event) {
            _vm.honorareToDelete = null
          },
          confirmed: function($event) {
            return _vm.deleteHonorare($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "bestaetigungVorbereitenModal",
          attrs: {
            modalTitle: "Information",
            showCancelButton: false,
            showCloseButton: true,
            labelButtonConfirm: "Ok"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.getFees(_vm.formValues)
            }
          }
        },
        [
          _vm._v(
            " Die Honorare/Serviceleistungen wurden für die Bestätigung vorbereitet. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }