var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _vm.useParentOptionMenu
        ? _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.parentOptionMenuId,
              defaultMenu: _vm.$appNavigation.parentOptionMenu
            }
          })
        : _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          }),
      _c("DashboardPanel", {
        attrs: {
          id: "8ee0460c-2a9f-4a6a-ac4b-837b7a5e62c7",
          data: _vm.dashboardData,
          canEdit: _vm.isEditable,
          ignoreUserLevelConfig: "",
          defaultOptionsMenu: _vm.customOptionsMenu,
          is3Columns: "",
          headerActions: _vm.headerActions,
          noPrimaryAction: false
        },
        on: {
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.pageMetadata.title) + " ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.pageMetadata.subtitle) + " ")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.modalTitle,
            size: "lg",
            showCancelButton: false,
            showConfirmButton: false,
            actions: _vm.baseModalActions
          },
          on: {
            onCloseButton: function($event) {
              return _vm.saveChanges()
            },
            action: _vm.handleModalActions
          }
        },
        [
          _vm.editComponent == "produktdaten" ? _c("Produktdaten") : _vm._e(),
          _vm.editComponent == "anpassungen" ? _c("Anpassungen") : _vm._e(),
          _vm.editComponent == "kommunikationsdaten"
            ? _c("Kommunikationsdaten")
            : _vm._e(),
          _vm.editComponent == "dokumente" ? _c("Dokumente") : _vm._e(),
          _vm.editComponent == "abrechnung" ? _c("Abrechnung") : _vm._e(),
          _vm.editComponent == "aenderungslog" ? _c("Aenderungslog") : _vm._e(),
          _vm.editComponent == "manuelle-struktur"
            ? _c("ManuelleStruktur")
            : _vm._e(),
          _vm.editComponent == "aktivitaet" ? _c("Aktivitaet") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }