<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />
    <PageHeaderTitleNavigation
      :title="filterType === 'contact' ? 'Kontaktsuche' : 'Gesellschaft'"
      :defaultMenu="customOptionMenu"
      :actions="headerActions"
      @action-SELECTION-DONE="handleBackAction"
      @action-NEW-CONTACT="addNewContact()"
    />

    <GenericPersonFilter
      v-if="isLoaded"
      filterId="fa0b41fe-41b0-4c49-bc6e-981c0744d8dd"
      saveKey="contactSearchAdvanced"
      :title="filterType === 'contact' ? 'Kontakte' : 'Gesellschaft'"
      :metadata="searchMenu"
      :isCustomerSearch="false"
      :configFilter="configFilter"
      :isCache="$route.meta.isCache"
      @search="handleSearch($event)"
    />

    <div class="box__container" v-if="filtered" tid="20250edd-3d2c-4ef5-8afc-56c34a2f5fcf">
      <GhostLoading v-if="loading && !scrollLoading" type="table" />

      <UnexpectedError v-else-if="error" />

      <PaginatedTable
        v-else
        tableId="3235ec89-a2c1-59e4-95b9-01331b92d3c0"
        :title="filterType === 'contact' ? 'Kontakte' : 'Gesellschaft'"
        :headers="headers"
        :pages="pages"
        :pageCount="pageCount"
        :rowCount="rowCount"
        :page="pageId"
        :mobileConfig="{title: 'fullName', headers: ['email', 'art'], selectionActive: isExternalSelect, disableClickRow: true}"
        @page="pageId = $event"
        @requestPage="loadPage"
        v-model="selectedRows"
        :selectionAsBlacklist="invertSelection"
        @selectionAsBlacklist="invertSelection = $event"
        @sort="onContactSearchResultSort"
        :sorted="contactSearchSort"
        @onScroll="onScroll"
        @scrollLoading="scrollLoading = $event"
        @click-fullName="handleEdit"
        @action-DELETE="handleDelete"
        @action-EMAIL="handleEmail"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import BROKERDATA_TYPES from '@/store/brokerData/types'
import CORE_TYPES from '@/store/core/types';
import GenericPersonFilter from '@/components/core/GenericPersonFilter.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { searchMenu } from './contactSearchConstants.js';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import PaginatedTable from '@/components/table2/PaginatedTable.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import {
  TextColumn,
  ActionColumn,
  SimpleAction,
} from '@/components/table2/table_util.js';
import UnexpectedError from '@/components/core/UnexpectedError.vue';
import axios from 'axios';
import { ROLES } from '@/router/roles';

export default {
  components: {
    PaginatedTable,
    GenericPersonFilter,
    OptionMenu,
    GhostLoading,
    UnexpectedError,
    PageHeaderTitleNavigation,
  },
  props: {
    isExternalSelect: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: 'contact',
    },
    customOptionMenu: {
      type: Array,
    },
  },
  data() {
    return {
      pageId: 0,
      invertSelection: null,
      selectedRows: null,
      searchParams: {},
      error: false,
      loading: false,
      contactSearchSort: null,
      scrollLoading: false,
      filtered: false,
      isLoaded: true,
    };
  },
  mixins: [InteractiveHelpCommonsMixin],
  watch: {
    filterType(value) {
      // just to reset the filterrows
      this.isLoaded = false
      this.$nextTick(() => {
        this.isLoaded = true
      });
    },
    isExternalSelect: {
      handler(val) {
        this.selectedRows = val ? [] : undefined
        this.invertSelection = val ? false : null
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      contacts: CUSTOMER_SEARCH_TYPES.GETTERS.CONTACTS,
      contactFilterSetup: CUSTOMER_SEARCH_TYPES.GETTERS.CONTACT_FILTER_SETUP,
      tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
    }),
    isTerminkalenderSyncAktiv() {
      return this.$hasRoles([ROLES.TERMINKALENDER_SYNC_AKTIV]);
    },
    searchMenu() {
      return searchMenu(this.filterType, this.contactFilterSetup, this.isTerminkalenderSyncAktiv);
    },
    configFilter() {
      return {
        placeholderForDefSearchInput: '',
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: false,
        filterZurucksetzen: null,
      };
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('SELECTION-DONE', 'Auswahl übernehmen')
          .withVisible(() => this.isExternalSelect)
          .withDisabled(() => !this.isSomethingSelected),
        PageHeaderSimpleAction('NEW-CONTACT', 'Neuer Kontakt')
          .withVisible(() => this.filterType === 'contact'),
      ];
    },
    isSomethingSelected() {
      return !!this.selectedRows && (!this.invertSelection && !!this.selectedRows.length || this.invertSelection && this.selectedRows.length < this.rowCount)
    },
    headers() {
      let headers = {
        lockedLeft: [
          TextColumn('fullName', 'Name').makeLink(row => this.filterType === 'contact' && row.contactFiltrAdditional?.editable),
        ],
        center: [
          TextColumn('email', 'E-Mail'),
          TextColumn('art', 'Art'),
        ],
        lockedRight: [
          ...(this.filterType === 'contact' ? [ActionColumn("actions", "Aktionen")] : []),
        ],
      };
      return headers;
    },
    allPages() {
      if (!this.contacts.pages) return {};
      return Object.fromEntries(
        Object.entries(this.contacts.pages).map(([key, page]) => {
          return [
            key,
            page.flatMap((item) => {
              const result = [
                {
                  customerID: item.user?.userId,
                  userId: item.user?.userId,
                  id: item.user?.userId,
                  email: item.fullName && item.email ? `${item.fullName} <${item.email}>` : (item.email || item.fullName),
                  fullName: item.fullName,
                  name:
                    (item.email?.includes('<') && item.email) || item.fullName,
                  type: item.user?.type || 'PERSON',
                },
              ];
              return result;
            }),
          ];
        })
      );
    },
    pages() {
      const result = {};
      for (const [pageId, rows] of Object.entries(
        this.contacts.pages || {}
      )) {
        result[pageId] = rows.map((row) => {
          let active = null;
          if (row.internData?.active == 'true')
            active = { label: 'aktiv', type: 'info' };
          else if (row.internData?.active == 'false')
            active = { label: 'inaktiv', type: 'danger' };

          let actions = [];
          if (this.filterType === 'contact') {
            actions = [
              ...(row.contactFiltrAdditional?.deletable ? [SimpleAction('DELETE', 'PhTrash', 'Löschen')] : []),
              ...(/*row.canEmail && */row.email ? [SimpleAction('EMAIL', 'PhEnvelopeSimple', 'Neue E-Mail')] : [])
            ]
          }

          return {
            id: row.userId,
            ...row,
            ...(row.internData || {}),
            art: row.label,
            active,
            actions,
          };
        });
      }
      return result;
    },
    maxRowsPerPage() {
      if (this.tablePageSize > 0) {
        return this.tablePageSize;
      }
      return 25;
    },
    pageCount() {
      return Math.ceil(this.contacts?.count / this.maxRowsPerPage);
    },
    currentPage() {
      return this.pages && this.pages[this.page] || [];
    },
    rowCount() {
      return this.contacts?.count || 0;
    },
  },
  created() {
    this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE_SEARCH);
  },
  mounted: function () {
    this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CONTACTS_SETUP);
  },
  methods: {
    onContactSearchResultSort(contactSearchSort) {
      this.contactSearchSort = contactSearchSort;
      this.loadPage(0, true);
    },
    async onScroll(onScrollEnd) {
      try {
        await this.loadPage(this.pageId + 1)
      } finally {
        onScrollEnd()
      }
    },
    handleSearch(params) {
      this.filtered = true
      let zusaetzlicheInfos = []

      Object.entries(params).forEach(([key, value]) => {
        if (key === 'zusInfosFreitext' || key === 'zusInfosCombo') {
          if (params[key] instanceof Array) {
            zusaetzlicheInfos.push(...params[key].map(val => ({
              inverted: !!params['filterNot']?.includes(key),
              value: val
            })))
          } else {
            zusaetzlicheInfos.push({
              inverted: !!params['filterNot']?.includes(key),
              value
            })
          }
        }
      })

      if (zusaetzlicheInfos.length) {
        params['zusaetzlicheInfos'] = zusaetzlicheInfos
      }

      this.searchParams = params;
      this.loadPage(0, true);
    },
    async loadPage(section, force = false, maxCount = null) {
      try {
        this.pageId = section
        if (!force && this.contacts.pages && this.contacts.pages[section])
          return;
        const params = {
          ...this.searchParams,
          section,
          companies: this.filterType === 'company',
          // borkersPersons: this.filterType === 'brokerPersons',
          contacts: this.filterType === 'contact',
          // customers: this.filterType === 'customers',
          // brokers:  this.filterType === 'brokers',
          maxCount: maxCount || this.maxRowsPerPage,
        };
        if (this.contactSearchSort && this.contactSearchSort.key) {
          params.sort = this.contactSearchSort.key
          params.sortDirection = this.contactSearchSort.sortDirection || 'asc'
        } else {
          delete params.sort
          delete params.sortDirection
        }
        this.loading = true;
        this.error = false;
        await this.$store.dispatch(
          CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CONTACT,
          params
        );
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async handleBackAction(setValues = true) {
      if (setValues && this.invertSelection) {
        await this.loadPage(-1, false, 20000);
        // invert the selection
        const selectedRows = Object.values(this.allPages)
          .flat()
          .filter(
            (row) =>
              !this.selectedRows.some(
                (selected) => selected.userId == row.userId
              )
          );
        this.$emit('handleBackAction', selectedRows);
      } else {
        const selectedRows = this.selectedRows.map(item => ({
          userId: item.user?.userId,
          id: item.user?.userId,
          email: item.fullName && item.email ? `${item.fullName} <${item.email}>` : (item.email || item.fullName),
          fullName: item.fullName,
          name:
            (item.email?.includes('<') && item.email) || item.fullName,
          type: item.user?.type || 'PERSON',
        }))
        this.$emit('handleBackAction', setValues ? selectedRows : []);
      }
    },
    handleEdit(row) {
      if (row.contactFiltrAdditional?.contactType) {
        let path = '';
        switch (row.contactFiltrAdditional?.contactType) {
          case 'KONTAKT':
            this.$addBreadcrumb({
                label: 'zurück', 
                fullPath: this.$route.fullPath,
            });
            this.$router.push(`/intern/person/${row.user?.userId}`)
            break;
          case 'KUNDE':
            path = '/persoenlichedaten/customer-data/steps/stammdaten'
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
              customerId: row.contactFiltrAdditional?.desktopUser?.userId,
              path,
            })
            break;
          case 'KUNDE_PERSON':
            path = `/persoenlichedaten/person-data/${row.user?.userId}/stammdaten`
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
              customerId: row.contactFiltrAdditional?.desktopUser?.userId,
              path,
              force: true,
            })
            break;
          case 'GESELLSCHAFT_AP':
          case 'VERMITTLER_AP':
            this.$router.push({path: `/intern/ansprechpartner/${row.user?.userId}`})
            break;
          case 'VERMITTLER': //VERMITTLER
            path = '/intern/vermittlerdaten/vermittlerdaten'
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
              brokerId: row.contactFiltrAdditional?.desktopUser?.userId,
              path,
            });
            break;
        }
      }
    },
    handleDelete(row) {
      if (row.contactFiltrAdditional?.contactType) {
        let url = '';
        let param = '';
        let value = '';
        switch (row.contactFiltrAdditional?.contactType) {
          case 'KONTAKT':
            url = '/ContactFilters/deleteContact'
            param = 'cotactId'
            value = row.contactFiltrAdditional.kontaktId
            break;
          case 'KUNDE_PERSON':
            url = '/ContactFilters/deletePerson'
            param = 'personId'
            value = row.user?.userId
            break;
        }
        const config = {
          defaultSpinner: true,
          params: {
            [param]: value
          }
        };

        axios.delete(`${process.env.VUE_APP_API}` + url, config)
          .then(() => 
              this.loadPage(0, true)
          ).catch(() => this.loading = false)
      }
    },
    handleEmail(row) {
      this.$router.push({path: `/communication/mailcomposer-single`, query: {email: row.email, fullName: row.fullName, userId: row.user?.userId, type: row.user?.type}});
    },
    addNewContact() {
      this.$addBreadcrumb({
          label: 'zurück', 
          fullPath: this.$route.fullPath,
      });
      this.$router.push('/intern/person/newPerson');
    },
  },
};
</script>
