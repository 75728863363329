<template>
<div>
  <PageHeaderTitleNavigation 
    v-if="isBroker" 
    :id="$appNavigation.currentOptionMenuId" 
    title="Vermittler Dashboard" 
    noPrimaryAction
    :actions="headerActions" 
    @action-CONFIG="$refs.brokerDashboardConfigModal.open()" 
  >
    <template #action-TOGGLE-STRUKTUR>
      <InputToggleSwitch label="mit Struktur" inLineLabel inLineAlignment="left" 
        :value="isStruktur" :disabled="!isLoaded" @input="toggleChangeStruktur()" />
    </template>
  </PageHeaderTitleNavigation>

  <div>
    <template v-for="section in sections">
      <div :key="section.section">
        <DashboardPanel
          :id="MAP_SECTION_TO_DASHBOARD_ID[section.section]"
          :title="section.title"
          :data="mapSectionToDashboardData[section.section]"
          @executeAction="executeAction($event)"
        >
          <template v-for="(card, index) of section.cards" v-slot:[card.widgetName]>
            <CardFactory
              :ref="card.widgetName"
              :key="index"
              :index="`${card.chartIndex}`"
              :currentCard="card"
              :currentCardOption="card.option"
              :additionalData="chartsData"
              :title="card.title"
              :labels="card.labels"
              :info="card.info"
              :isError="card.isError"
              :infoLabel="`${section.title} - ${card.option}`"
              :customColors="card.customColors"
              :showTitle="false"
              :showToolbar="false"
              :listView="mapWidgetNameToIsListView[card.widgetName]"
            />
          </template>
        </DashboardPanel>
      </div>
    </template>
  </div>

  <BrokerDashboardConfigModal ref="brokerDashboardConfigModal" :sections="sections" />
</div>
</template>

<script>
import CardFactory from '@/components/dashboard/CoreCard/CardFactory.vue'
import CORE_TYPES from '../../store/core/types';
import DASHBOARD_TYPES from './store/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';
import { mapGetters } from 'vuex'
import validator from '@/mixins/validator';
import {getColorsMixedContrastDecreasing} from '@/helpers/colors-helper';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import BrokerDashboardConfigModal from './BrokerDashboardConfigModal.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PageHeaderSimpleAction, PageHeaderSlotAction, } from '@/components/core/header-title-navigation/page-header-utils';

const barCards = ['Finanzierungsvolumen Immo', 'Anzahl ISINs', 'Gesamtbestand', 'Anzahl Verträge über alle Sparten', 'Bestand Top 3 Gesellschaften', 'Bestand Top 3 Gesellschaften', 'Bestand Top 3 Fonds', 'Anteil Top 3 Gesellschaften am Bestand', 'Anteil Top 3 Fonds am Bestand',]; 
const numCards = ['bei Fondskozept', 'Courtage-Stufe Invest', 'Anzahl App Nutzer', 'Nutzung Fondsshop', 'Anzahl Kunden-Newsletter', 'Anzahl FinanceCloud Nutzer'];

const MAP_SECTION_TO_DASHBOARD_ID = {
  '0': '3db6b604-fb57-431e-b690-4ff9e58018d0',
  '1': '5af0eb39-8d2e-4c61-ac77-43bc02dbb1e6',
  '2': 'c638ee81-4ddd-4d50-a862-3a9708a7b36a',
  '3': 'a12dfdbb-4be9-4613-8612-d68e05a5dc1f',
  '4': 'f5603f38-2ec2-404e-9fb0-5b62e10761b7',
  '5': 'ac3e9cf6-b652-494f-adc9-bdb39da3c44f',
};

const MAP_WIDGET_OPTION_TO_SIZE = {
  'Kundenbindung': 50,
  'Gesamtcourtage': 50,
  'Bestandsübersicht': 50,
  'Bestandkunden': 50,
  'NettomittelzuflussGesamt': 50,
  'CourtageInvestmentfonds': 50,
  'CourtageServicegebühr': 50,
  'TopLagerstelleBestand': 50,
  'TopGesellschaftenBestand': 50,
  'TopFondsBestand': 50,
  'TopLagerstelleNettomittelaufkommen': 50,
  'TopGesellschaftenNettomittelaufkommen': 50,
  'TopFondsNettomittelaufkommen': 50,
  'Kundenübersicht': 50,
  'Aufteilungindenletzten12Monaten': 50,
  'CourtageVersicherungen': 50,
  'AnzahlVerträge': 50,
  'CourtageVersicherungenBestand': 50,
  'CourtageVersicherungenAbschluss': 50,
  'TopLagerstelleBeteiligungen': 50,
  'CourtageBeteiligungen': 50,
  'Abschlussvolumen': 50,
  'CourtageFinanzierungen': 50,
  'Bestand': 100,
};

const MAP_WIDGET_NAME_TO_SIZE = {
  'BrokerAreaChart-Gesamtcourtage': 100,
};


export default {
  props: {
    type: {
      type: String,
      default: 'customer',
    },
    allowConfig: {
      type: Boolean,
      default: true,
    },
    allowListView: {
      type: Boolean,
      default: true,
    },
    disabledCards: {
      type: Object,
      default: () => ({}),
    },
  },
  
  mixins: [validator],
  validators: {},

  data: function () {
    return {
      MAP_SECTION_TO_DASHBOARD_ID,
      defaultCard: 'BrokerOverviewDataCard',
      cards: [],
      renderComponent: true,
      isLoaded: false,
      isInternalSwitcher: false,
      isStruktur: false,
      mapWidgetNameToIsListView: {},
    }
  },

  computed: {
    ...mapGetters({
      brokerDashboardConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BROKER_DASHBOARD,
      savedCards: DASHBOARD_TYPES.GETTERS.GET_USER_DASHBOARD,
      charts: DASHBOARD_TYPES.GETTERS.GET_DASHBOARD_CHARTS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isStructure: DASHBOARD_TYPES.GETTERS.GET_DASHBOARD_CHARTS_STRUCTURE,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('CONFIG', 'Anpassen').withDisabled(() => !this.isLoaded),
        PageHeaderSlotAction('TOGGLE-STRUKTUR', 'mit Struktur'),
      ];
    },
    fullname() {
      return this.$store.state.core.loginData.fullname;
    },
    chartsData() {
      let charts = this.$store.state.dashboard.charts;
      if (charts && this.isFA) {
        charts.data = charts.data.filter(c => c.id !== 'Kostenpflichtige_Produkte')
      }
      return charts && charts.data.reduce((acc, curr) => {
          const keyClean = curr.name.replace('pie#', '').replace('donut#', '').replace('area#', '').replace('stacked#', '').replace('radialBarMultiple#', '').replace('investment#', '').replace('tbl#', '').replace('bar#', '').replace('num#', '').replace('numLarge#', '').replace('vertrag#', '').replace('info2#', '').replace('infoInventory#', '').replace('infoProducts#', '');
          return {
            ...acc,
            [keyClean]: curr.data
          }
        }, {
            ...this.$store.state.dashboard.charts,
            isLoaded: true
        }) || {};
    },

    cardPerSection() {
      if (this.cards?.length) {
        const chartPerSection = this.cards
        .filter(card => this.isCardVisible(card.id))
        .reduce((acc, curr, index) => {
          curr.chartIndex = index
          curr.widgetOption = curr.option.replace(/\s/g, '');
          curr.widgetName = `${curr.card}-${curr.widgetOption}`;
          const currSection = curr.section + ''

          if (!acc[currSection]) {
            acc[currSection] = []
          }
          acc[currSection].push(curr)
          
          return acc
        }, {})

        return chartPerSection
      }
      return []
    },

    defaultSections() {
      const { cardPerSection } = this;
      return Object.keys(cardPerSection)
        .filter(section => this.shouldShowCardSection(section, cardPerSection[section]))
        .map(section => ({
          section,
          id: MAP_SECTION_TO_DASHBOARD_ID[section],
          title: this.getSectionTitle(section),
          cards: cardPerSection[section],
        }));
    },

    defaultNeverConfigured() {
      return this.defaultSections.filter(ds => !this.configuredSections.find(cs => cs.section === ds.section));
    },

    configuredSections() {
      const configuredSections = this.brokerDashboardConfig?.content && JSON.parse(this.brokerDashboardConfig?.content) || [];
      return configuredSections
        .map(cs => {
          const defaultSection = this.defaultSections.find(ds => ds.section === cs.section);
          return defaultSection;
        })
        .filter(cs => !!cs);
    },

    sections() {
      return [...this.configuredSections, ...this.defaultNeverConfigured];
    },

    mapSectionToDashboardData() {
      const cardPerSection = this.cardPerSection || {};
      return Object.keys(cardPerSection).reduce((acc, key) => {
        const section = cardPerSection[key];
        acc[key] = {
          widgets: section?.map(card => ({
            name: card?.widgetName,
            title: this.sectionTitleStruktur(card?.title || card?.option),
            size: MAP_WIDGET_NAME_TO_SIZE[card?.widgetName] || MAP_WIDGET_OPTION_TO_SIZE[card?.widgetOption] || null,
            actions: [
              {
                legend: {
                  icon: 'PhChartPie',
                  key: 'CHART_VIEW',
                  label: 'Diagramm',
                },
                visible: () => card.isLoaded && card.allowListView && this.allowListView && this.mapWidgetNameToIsListView[card?.widgetName],
              },
              {
                legend: {
                  icon: 'PhTable',
                  key: 'LIST_VIEW',
                  label: 'Liste',
                },
                visible: () => card.isLoaded && card.allowListView && this.allowListView && !this.mapWidgetNameToIsListView[card?.widgetName],
              },
              {
                legend: {
                  icon: 'PhQuestion',
                  key: 'INFO',
                  label: 'Info',
                },
                visible: () => card.isLoaded,
              },
            ],
            visible: () => !card.roles || this.hasRoles(card.roles),
            loading: () => !card.isLoaded,
          })),
        };
        return acc;
      }, {});
    },

  },

  watch: {
    charts() {
      this.initCards();
    },
    isStructure(value) {
      this.isStruktur = !!value;
    }
  },

  methods: {
    shouldShowCardSection(section, cardList) {
      const hasCard = cardList?.filter(card => (!card.roles || this.hasRoles(card.roles))).length > 0

      return hasCard && (section > 0 || (this.isByPass && this.isBroker && this.isInternalSwitcher))
    },
    toggleChangeStruktur() {
      if (this.isLoaded) {
        this.isStruktur = !this.isStruktur
        this.changeStruktur(this.isStruktur)
      }
    },
    sectionTitleStruktur(value) {
      if (value === 'Bestandsübersicht') {
        return `Bestandsübersicht${this.isStructure ? ' incl. der Struktur' : ''}`
      }
      return value
    },
    getSectionTitle(section) {
      switch (section) {
        case '1':
          return 'Allgemein'
        case '2': 
          return 'Investment'
        case '3':
          return 'Versicherungen'
        case '4':
          return `Beteiligungen ${this.chartsData.anzahlVertraegeBeteiligungen ? '(' + this.chartsData.anzahlVertraegeBeteiligungen + ')' : ''}`
        case '5': 
          return `Finanzierungen ${this.chartsData.anzahlVertraegeFinanzierungen ? '(' + this.chartsData.anzahlVertraegeFinanzierungen + ')' : ''}`
        default:
          return ''
      }
    },
    changeStruktur(value) {
      this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CHARTS, value ? 1 : 0);
      this.isLoaded = false;
    },
    getSwitcherOptions(index) {
      switch(index) {
        default:
          return [
            {
              title: 'Übersichtsdaten',
              name: 'BrokerOverviewDataCard',
            },
            {
              title: 'Geburtstage',
              name: 'BrokerBirthdayListCard',
            },
          ];
      }
    },
    getCurrentCard(index) {
      return this.cards[index] && this.cards[index] || null;
    },
    getCurrentCardOption(index) {
      return this.cards[index] && this.cards[index].option || '';
    },
    onSelectCard(index, name) {
      if (this.cards[index]) {
        this.cards[index].card = name;
        this.cards = [...this.cards];
        // @todo: dispatch save action
        /* this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.SAVE_DASHBOARD, {
          dashboardCards: this.cards.map(item => `${item.card}`)
        }); */
        this.forceCardsUpdate();
      }
    },
    forceCardsUpdate() {
      // this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    initCards() {
      this.isLoaded = false;
      if (this.$store.state.dashboard.charts) {
        const cards = [];
        this.isLoaded = this.$store.state.dashboard.charts && this.$store.state.dashboard.charts.data.every(item => item.section < 0 || item.isLoaded);
        this.$store.state.dashboard.charts && this.$store.state.dashboard.charts.data.map(item => {
        const chart = item.name;
          if (chart !== 'isLoaded') {
            if (barCards.includes(chart)) {
              cards.push({
                card: 'BrokerBarChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (numCards.includes(chart)) {
              cards.push({
                card: 'BrokerNumberCard',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart === 'multipleCard') {
              cards.push({
                card: 'BrokerMultipleCard',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('num#') >= 0) {
              cards.push({
                card: 'BrokerNumberCard',
                option: chart.replace('num#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('numLarge#') >= 0) {
              cards.push({
                card: 'BrokerNumberLargeCard',
                option: chart.replace('numLarge#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('vertrag#') >= 0) {
              cards.push({
                card: 'BrokerVertragCard',
                option: chart.replace('vertrag#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('infoInventory#') >= 0) {
              cards.push({
                card: 'BrokerInfoInventoryCard',
                option: chart.replace('infoInventory#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('info2#') >= 0) {
              cards.push({
                card: 'BrokerInfo2Card',
                option: chart.replace('info2#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('bar#') >= 0) {
              cards.push({
                card: 'BrokerBarChart',
                option: chart.replace('bar#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('tbl#') >= 0) {
              cards.push({
                card: 'BrokerTableCard',
                option: chart.replace('tbl#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('investment#') >= 0) {
              cards.push({
                card: 'InvestmentMultipleCard',
                option: chart.replace('investment#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('radialBarMultiple#') >= 0) {
              cards.push({
                card: 'RadialBarMultipleCard',
                option: chart.replace('radialBarMultiple#', ''),
                section: item.section,
                all: item.all,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('pie#') >= 0) {
              cards.push({
                card: 'BrokerPieChart',
                option: chart.replace('pie#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('stacked#') >= 0) {
              cards.push({
                card: 'BrokerStackedChart',
                option: chart.replace('stacked#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('donut#') >= 0) {
              cards.push({
                card: 'BrokerDonutChart',
                option: chart.replace('donut#', ''),
                section: item.section,
                all: item.all,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('Top') >= 0) {
              cards.push({
                card: 'BrokerPieChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('Nettomittelzufluss') >= 0) {
              cards.push({
                card: 'BrokerBarChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(4),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf(' ytd') >= 0) {
              cards.push({
                card: 'BrokerAreaChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(1),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('area#') >= 0) {
              cards.push({
                card: 'BrokerAreaChart',
                option: chart.replace('area#', ''),
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(1),
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('Gesamtumsatz') >= 0) {
              cards.push({
                card: 'BrokerStackedChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
            if (chart.indexOf('Gesamtcourtage') >= 0) {
              cards.push({
                card: 'BrokerStackedChart',
                option: chart,
                section: item.section,
                title: item.title,
                labels: item.labels,
                info: item.info,
                isLoaded: item.isLoaded,
                roles: item.roles,
                isError: !!item.isError,
                customColors: getColorsMixedContrastDecreasing(3),
                allowListView: true,
                id: item.id,
              });
              return;
            }
          }
        });
        this.cards = [...cards];
        this.forceCardsUpdate();
      }
    },
    executeAction(event) {
      const widgetName = event?.widget?.name;
      if(!widgetName) return;

      switch(event?.action?.legend?.key) {
        case 'INFO':
          const refs = this.$refs[widgetName];
          const ref = refs?.length > 0 ? refs[0] : null;
          ref?.openInfoModal();
          break;
        case 'LIST_VIEW':
          this.$set(this.mapWidgetNameToIsListView, widgetName, true);
          break;
        case 'CHART_VIEW':
          this.$set(this.mapWidgetNameToIsListView, widgetName, false);
          break;
      }
    },
    isCardVisible(widgetId) {
      return !this.disabledCards
        || this.disabledCards && this.disabledCards[widgetId] !== false;
    },
    loadBrokerDashboardConfig() {
      const payload = {
        configId: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
        configType: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
      };
      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },
  },

  mounted: function () {
    this.loadBrokerDashboardConfig();
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CHARTS, null);
    this.isStruktur = !!this.isStructure;
    this.initCards();
  },

  components: {
    CardFactory,
    DashboardPanel,
    BrokerDashboardConfigModal,
    PageHeaderTitleNavigation,
    InputToggleSwitch,
  },

  provide: function () {
    return {
      getSwitcherOptions: this.getSwitcherOptions,
      cardSelected: this.onSelectCard,
      allowConfig: this.allowConfig,
      allowListView: this.allowListView,
    }
  }
}
</script>

<style scoped>
  .dashboard__cardgroup-container {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .dashboard__cardgroup-item {
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;
    align-content: stretch;
  }

  .dashboard__cardgroup-item > * { 
    margin: 8px;
    width: 100%;
    height: inherit;
  }

  .dashboard__card-item-Kundenbindung {
    flex-basis: 50%;
  }
  .dashboard__card-item-Gesamtcourtage {
    flex-basis: 50%;
  }
  .dashboard__card-item-Bestandsübersicht {
    flex-basis: 33%;
  }
  .dashboard__card-item-Bestandkunden {
    flex-basis: 33%;
  }
  .dashboard__card-item-NettomittelzuflussGesamt {
    flex-basis: 33%;
  }
  .dashboard__card-item-CourtageInvestmentfonds {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageServicegebühr {
    flex-basis: 50%;
  }
  .dashboard__card-item-TopLagerstelleBestand {
    flex-basis: 33%;
  }
  .dashboard__card-item-TopGesellschaftenBestand {
    flex-basis: 33%;
  }
  .dashboard__card-item-TopFondsBestand {
    flex-basis: 33%;
  }
  .dashboard__card-item-TopLagerstelleNettomittelaufkommen {
    flex-basis: 33%;
  }
  .dashboard__card-item-TopGesellschaftenNettomittelaufkommen {
    flex-basis: 33%;
  }
  .dashboard__card-item-TopFondsNettomittelaufkommen {
    flex-basis: 33%;
  }
  .dashboard__card-item-Kundenübersicht {
    flex-basis: 50%;
  }
  .dashboard__card-item-Aufteilungindenletzten12Monaten {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageVersicherungen {
    flex-basis: 50%;
  }
  .dashboard__card-item-AnzahlVerträge {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageVersicherungenBestand {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageVersicherungenAbschluss {
    flex-basis: 50%;
  }
  .dashboard__card-item-TopLagerstelleBeteiligungen {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageBeteiligungen {
    flex-basis: 50%;
  }
  .dashboard__card-item-Abschlussvolumen {
    flex-basis: 50%;
  }
  .dashboard__card-item-CourtageFinanzierungen {
    flex-basis: 50%;
  }

  .ghost {
    background: #cccccc;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    background-image: linear-gradient(90deg,rgba(233,233,233,.644) 0,rgba(181, 180, 180, 0.95) 80px,rgba(233,233,233,.644) 1600px);
    animation: 4s linear infinite ghost-lines;
    min-height: 100px;
  }
  @keyframes ghost-lines {
    0%   { background-position: -100px;  }
    40%  { background-position: 40vw;    }
    100% { background-position: 60vw;    }
  }
  .ghost-section {
    background: #cccccc;
    padding: 16px;
    border-radius: 8px;
  }
  .ghost-content {

  }
  .ghost-content h2 {
    margin: 0;
  }

</style>
<style>
.legend-item {
  display: block;
  font-size: 1em;
}
.legend {
  line-height: 1em;
}
.chart-container h3 {
  margin: 0;
}
.content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 290px;
  position: relative;
}
.button__erweiterte_ansicht {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mt-24 {
  margin-top: 24px;
}
.disabled span {
  color: #cccccc;
}
.dashboard-broker__actions--items {
  min-width: 120px;
}
</style>
