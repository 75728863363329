var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "3db09d10-ccf8-4181-8d8d-407f3d596a3a",
          data: _vm.dashboardData,
          canEdit: _vm.isEditable,
          ignoreUserLevelConfig: "",
          defaultOptionsMenu: _vm.customOptionsMenu,
          is3Columns: "",
          headerActions: _vm.headerActions,
          noPrimaryAction: false
        },
        on: {
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  " " + _vm._s(_vm.kreditform || "Kredite bearbeiten") + " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [void 0]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.modalTitle,
            size: "lg",
            showCancelButton: false,
            showConfirmButton: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.saveChanges()
            }
          }
        },
        [
          _vm.editComponent == "vertragsdaten" ? _c("Vertragsdaten") : _vm._e(),
          _vm.editComponent == "anpassungen" ? _c("Anpassungen") : _vm._e(),
          _vm.editComponent == "abrechnung" ? _c("Abrechnung") : _vm._e(),
          _vm.editComponent == "aktivitaet" ? _c("Aktivitaet") : _vm._e(),
          _vm.editComponent == "dokumente" ? _c("Dokumente") : _vm._e(),
          _vm.editComponent == "prohyp" ? _c("Prohyp") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }