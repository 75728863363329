<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="dynamicTitle" />

    <BaseFilter
      ref="gdvFileImportViewFilter"
      title="Daten einlesen suchen"
      filterId="Daten einlesen suchen"
      :metadata="metadata"
      showSaveButton
      @onFilter="onFilter"
    >
    </BaseFilter>

    <div class="box__container">
      <div v-if="loading || !rows.length">
            <span class="box__title">
                BiPRO Download Zusammenfassungen
            </span>
            <br>
      </div>
        <BaseButton v-if="jobStartAllowed" @click="restartJobs" style="float:right">
        Re/Starte fehlerhafte Jobs
      </BaseButton>
        <GhostLoading v-if="loading" type="table"/>
        <BigTable
        style="clear: both;"
        v-else-if="rows.length"
        :headers="headers" 
        title="BiPRO Download Zusammenfassungen"
        tableId="b898b0fe-2c80-4a19-947e-d3d7d6551092"
        :rows="rows" 
        :initialPageSize="minNumRows"
        :page="page"
        :sortingState="sortingState"
        @onClickHeader="onClickHeader"
        @action-RESTART="actionRestart"
        @action-EXTERNE_ZUGAENGE="openExterneZugangPage"
      >
        <template v-slot:postdokumenteZugeordnet="row">
            <span v-if="row.warningZugeordnet" class="warningClass" title="Daten älter als erwartet. Bitte überprüfen.">{{ row.postdokumenteZugeordnet }}</span>
            <span v-else>{{ row.postdokumenteZugeordnet }}</span>
          </template>
          <template v-slot:postdokumenteNichtZugeordnet="row">
            <span v-if="row.warningNichtZugeordnet" class="warningClass" title="Daten älter als erwartet. Bitte überprüfen.">{{ row.postdokumenteNichtZugeordnet }}</span>
            <span v-else>{{ row.postdokumenteNichtZugeordnet }}</span>
          </template>
          <template v-slot:gdv="row">
            <span v-if="row.warningGDV" class="warningClass" title="Daten älter als erwartet. Bitte überprüfen.">{{ row.gdv }}</span>
            <span v-else>{{ row.gdv }}</span>
          </template>
          <template v-slot:abrechnungen="row">
            <span v-if="row.warningAbrechnungen" class="warningClass" title="Daten älter als erwartet. Bitte überprüfen.">{{ row.abrechnungen }}</span>
            <span v-else>{{ row.abrechnungen }}</span>
          </template>
          <template v-slot:mahnungen="row">
            <span v-if="row.warningMahnungen" class="warningClass" title="Daten älter als erwartet. Bitte überprüfen.">{{ row.mahnungen }}</span>
            <span v-else>{{ row.mahnungen }}</span>
          </template>
      </BigTable>        
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue'

import { mapGetters } from 'vuex';
import axios from 'axios';
import {PhArrowsClockwise, PhCheck, PhX, PhDownloadSimple, PhArrowSquareOut} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import dayjs from 'dayjs'
import BaseFilter from "@/components/core/BaseFilter.vue";
import { makeQueryParam } from '@/helpers/utils-helper';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, DATE_COLUMN, ACTION_COLUMN, SLOT_COLUMN, FIXED_LEFT, Icon, STATIC_ICON_COLUMN} from '@/components/bigTable/BigTable.vue';
import {SimpleAction} from "@/components/table2/table_util.js";


export default {
  mixins: [],
  components: {
    PageHeaderTitleNavigation,
    OptionMenu,
    GhostLoading,
    NoData,
    BaseButton,
    BaseFilter,
    BigTable,
  },
  data() {
    return {
      loading: false,
      jobStartAllowed: false,
      filterParams: {},
      uniqueGesellschaftCount: null,
      headers: [
        { label: "Abh. 24h", key: "icon", width: 100, type: STATIC_ICON_COLUMN, position: FIXED_LEFT },
        { label: "BiPRO Gesellschaft", key: "gesellschaft", width: 250, type: STRING_COLUMN, visible: true },
        { label: "Letzte Abholung", key: "lastSuccess", width: 150, type: DATE_COLUMN, visible: true },
        { label: "Status", key: "status", width: 250, type: STRING_COLUMN, visible: true },
        { label: "PDFs autom. zugeordnet", key: "postdokumenteZugeordnet", width: 230, type: SLOT_COLUMN, visible: true },
        { label: "PDF nicht zugeordnet", key: "postdokumenteNichtZugeordnet", width: 230, type: SLOT_COLUMN, visible: true },
        { label: "GDV", key: "gdv", width: 150, type: SLOT_COLUMN, visible: true },
        { label: "Abrechnungen/Courtagen", key: "abrechnungen", width: 250, type: SLOT_COLUMN, visible: true },
        { label: "Mahnungen/Dynamik", key: "mahnungen", width: 200, type: SLOT_COLUMN, visible: true },
        { label: "Anmeldename", key: "extUserName", width: 220, type: STRING_COLUMN, visible: true },
        { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
      ],      
      minNumRows: 100,
      sortingState: { key: "gesellschaft", direction: "asc" },
      page: 0,
      rows: [],    
    };
  },
  computed: {
        ...mapGetters({
        }),
    metadata() {
      const result = [
        {
					type: 'group',
					key: 'allgemein',
					label: 'Allgemein',
          menuItems: [
            {
                            type: 'text',
                            label: 'Gesellschaft *',
                            key: 'gesellschaft',
              emptyDenied: true,
            },
            {
                            type: 'dateRange',
                            label: 'Lezte Abholung',
                            key: 'lezteAbholung',
              emptyDenied: true,
            },
          ],
        },
      ];

      return result;
    },
    dynamicTitle() {
        let result = "BiPRO Download Zusammenfassungen";

        if (this.uniqueGesellschaftCount) {
            result += ` | ${this.uniqueGesellschaftCount} Anzahl der angebundenen Gesellschaften`
        }

        return result;

    },    
  },
  mounted() {
    this.loadRows();
  },
  methods: {
    onClickHeader(headerMetadata) {
      this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
      // TODO: save the sorting state
    },    
    openExterneZugangPage(whatRow) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
        brokerId: whatRow?.maklernr,
                path: `/intern/configs/externe-zugaenge/configs-extern-zugang-edit/${whatRow?.gesellschaft}/${whatRow?.id}`
      });
    },
    async onFilter(filterConfig) {
      this.filterParams = {};

			filterConfig.forEach(fc => {
        let value = fc.value;

                if (fc?.key == 'lezteAbholung') {
                    this.filterParams.lezteAbholungVon = value?.find(v => v?.key =='min')?.value;
                    this.filterParams.lezteAbholungBis = value?.find(v => v?.key =='max')?.value;
        } else {
          if (value?.length) {
            this.filterParams[fc.key] = value;
          }

        }

      });

      this.loadRows();
    },
    async loadRows() {
      const config = {
        defaultSpinner: true,
      };
      this.loading = true;


      let url = `${process.env.VUE_APP_API}/bipro_download_overview/rows`;

      if (Object.keys(this.filterParams)?.length) {
        url = `${url}?${makeQueryParam(this.filterParams)}`;
      }

      axios.get(url, config).then(response => {

          this.uniqueGesellschaftCount = response?.data?.uniqueGesellschaftCount || 0;
          this.jobStartAllowed = response.data.jobStartAllowed;

          this.rows = (response.data.rows || []).map((row, index) => {
              let dateStr = row?.lastSuccess;

                if (dateStr.length < 16) {
                        dateStr += ' 00:00';
                }

                const givenDateObject = dayjs(dateStr, 'DD.MM.YYYY HH:mm');
                const currentDateObject = dayjs();

                // Calculate the time difference in hours
                const timeDifferenceInHours = Math.abs(currentDateObject.diff(givenDateObject, 'hour'));
                const lastCollectionDateExceeded = timeDifferenceInHours > 72;

                const actions = [];
                if (row.jobStartAllowed) {
                    actions.push(SimpleAction("RESTART", PhArrowsClockwise, "Restarte Job"));
                }
                if (row?.id) {
                  actions.push(SimpleAction("EXTERNE_ZUGAENGE", PhArrowSquareOut, "Externe Zugänge"));
                }

                let icon = Icon(PhCheck, undefined, undefined, undefined, lastCollectionDateExceeded ? "color-danger" : "color-success");

                if (row.status != null) {
                  if (row.status.toLowerCase().includes("alt"))
                    icon = Icon(PhDownloadSimple, undefined, undefined, undefined, lastCollectionDateExceeded ? "color-danger" : "color-success");
                  else
                    icon = Icon(PhX, undefined, undefined, undefined, "color-danger");
                }

                return {
                    index: index + 1,
                    ...row,
                    visible: !row.invisible,
                    icon: [icon],  
                    lastCollectionDateExceeded,
                    actions,
                };
            });


      }).catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
      }).finally(() => {
        this.loading = false;
      })
    },
    actionRestart(row) {
            axios.post(`${process.env.VUE_APP_API}/bipro_download_overview/restart_job?gesellschaft=${row.gesellschaft}`, {}, config)
            .then(_ => {
          this.loadRows();
            })
    },
    restartJobs() {
            axios.post(`${process.env.VUE_APP_API}/bipro_download_overview/restart_all_jobs`, {}, config)
            .then(_ => {
          this.loadRows();
            })
        }
    },
}
</script>
<style scoped>
.warningClass {
  background-color: var(--color-warning);
}
</style>
