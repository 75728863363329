<template>
  <div class="funds-chart__container">
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Marktübersicht"
    />

    <Marktubersicht class="box__container" />
  </div>
</template>

<script>
import Marktubersicht from '@/components/marktubersicht/Marktubersicht.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    Marktubersicht,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
}
</script>
