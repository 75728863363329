var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.ignoreRender
    ? _c(
        "div",
        {
          staticClass: "option-menu__container box__container",
          class: _vm.optionMenuClasses,
          attrs: {
            tid: _vm._generateTidFromString(_vm.$route.path + "box__container")
          }
        },
        [
          _c("div", { staticClass: "option-menu__header" }, [
            _c("div", { staticClass: "option-menu__title box__title" }, [
              _c("div", { staticClass: "menu-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))])
              ])
            ]),
            !_vm.isLoadingActive
              ? _c(
                  "div",
                  { staticClass: "option-menu__toolbar" },
                  [
                    _vm.isToggleViewButtonVisible
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-clear clickable option-menu__toolbar--toggle-view",
                            class: { active: _vm.collapsed },
                            attrs: { type: "button" },
                            on: { click: _vm.toggleView }
                          },
                          [
                            _vm.nextView(_vm.currentView) !== _vm.VIEW_GRID
                              ? _c("PhCaretDown", { attrs: { size: 20 } })
                              : _c("PhDotsNine", {
                                  attrs: { size: _vm.iconSize, weight: "bold" }
                                })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isEditAvailable
                      ? _c(
                          "BaseContextMenu",
                          { staticClass: "option-menu__context-menu" },
                          [
                            _c(
                              "ContextMenuItem",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.openEditModal()
                                  }
                                }
                              },
                              [_vm._v("Anpassen")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "option-menu__content-wrap" }, [
            _c(
              "div",
              { key: _vm.id, staticClass: "option-menu__content" },
              [
                _vm.carouselValues.length
                  ? _c("Carousel", {
                      staticClass: "option-menu__carousel",
                      attrs: {
                        id: _vm.id,
                        values: _vm.carouselValues,
                        options: _vm.carouselOptions,
                        asGrid: _vm.asGrid
                      },
                      on: {
                        ready: function($event) {
                          return _vm.onCarouselReady($event)
                        }
                      }
                    })
                  : _vm.isError
                  ? _c("UnexpectedError", {
                      staticClass: "m-0",
                      attrs: { noIcon: "" }
                    })
                  : _vm.isContentReady
                  ? _c("NoData", { staticClass: "m-0", attrs: { noIcon: "" } })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("OptionMenuEditModal", {
            ref: "optionMenuEditModal",
            attrs: {
              configContext: _vm.MENU_CONFIG_CONTEXT,
              configId: _vm.id,
              defaultMenu: _vm.defaultMenu,
              optionMenu: _vm.optionMenu
            },
            on: {
              onSaveConfig: _vm.onConfigChanged,
              onRestoreDefault: _vm.onConfigChanged
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }