var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "div",
        { staticClass: "calendar__wrap" },
        [
          _vm.isSmallScreen
            ? _c("CalendarNavigation", {
                key: _vm.isSmallScreen,
                attrs: {
                  hideMenu: "",
                  type: _vm.type,
                  title: _vm.title,
                  titleShortening: _vm.titleShortening,
                  disabled: !_vm.compgetWeekCalendarDataReady
                },
                on: {
                  current: _vm.handleCurrent,
                  prev: _vm.handleLeft,
                  next: _vm.handleRight,
                  addTask: _vm.newTask,
                  addAppointment: _vm.newAppointment
                }
              })
            : _c("CalendarNavigation", {
                key: _vm.isSmallScreen,
                attrs: {
                  type: _vm.type,
                  title: _vm.title,
                  titleShortening: _vm.titleShortening,
                  disabled: !_vm.compgetWeekCalendarDataReady
                },
                on: {
                  current: _vm.handleCurrent,
                  prev: _vm.handleLeft,
                  next: _vm.handleRight,
                  addTask: _vm.newTask,
                  addAppointment: _vm.newAppointment
                },
                scopedSlots: _vm._u([
                  {
                    key: "calendarDatePicker",
                    fn: function() {
                      return [
                        _c("CalendarDatePicker", {
                          attrs: {
                            asCalendar: false,
                            disabled: !_vm.compgetWeekCalendarDataReady,
                            monthPicker: _vm.isActive("month"),
                            highlightWeek: _vm.isActive("arbeitswoche")
                              ? "work-week"
                              : _vm.isActive("week")
                          },
                          on: { change: _vm.openDate },
                          model: {
                            value: _vm.selectedDate,
                            callback: function($$v) {
                              _vm.selectedDate = $$v
                            },
                            expression: "selectedDate"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
          _c("div", { ref: "calendarMainEl", staticClass: "calendar__main" }, [
            _c(
              "div",
              { staticClass: "calendar__sidebar" },
              [
                _c("InputField", {
                  attrs: { label: "Suche", type: "text" },
                  on: {
                    searchAction: function($event) {
                      return _vm.loadBySearch()
                    }
                  },
                  model: {
                    value: _vm.searchTerm,
                    callback: function($$v) {
                      _vm.searchTerm = $$v
                    },
                    expression: "searchTerm"
                  }
                }),
                _c("hr"),
                _c(
                  "div",
                  {
                    staticClass: "box__container calendar__sidebar--date-picker"
                  },
                  [
                    _c("CalendarDatePicker", {
                      attrs: {
                        disabled: !_vm.compgetWeekCalendarDataReady,
                        monthPicker: _vm.isActive("month"),
                        highlightWeek: _vm.isActive("arbeitswoche")
                          ? "work-week"
                          : _vm.isActive("week")
                      },
                      on: { change: _vm.openDate },
                      model: {
                        value: _vm.selectedDate,
                        callback: function($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "calendar__content" }, [
              _c(
                "div",
                { staticClass: "box__container m-0" },
                [
                  _vm.isActive("day") || _vm.isSmallScreen
                    ? [
                        _c("Day", {
                          attrs: {
                            searchTerm: _vm.searchTerm,
                            next: _vm.privateNext,
                            prev: _vm.privatePrev,
                            setNextPrev: _vm.setNextPrev(),
                            doRenew: _vm.privateDoRenew,
                            current: _vm.privateCurrent
                          },
                          on: {
                            title: _vm.setTitle,
                            titleShortening: _vm.setTitleShortening,
                            editEvent: _vm.editEvent,
                            addNew: _vm.addNew,
                            dateChanged: function($event) {
                              return _vm.setDate($event)
                            }
                          }
                        })
                      ]
                    : _vm.isActive("week")
                    ? [
                        _c("WeekCalendar", {
                          attrs: {
                            searchTerm: _vm.searchTerm,
                            date: _vm.selectedDate,
                            next: _vm.privateNext,
                            prev: _vm.privatePrev,
                            setNextPrev: _vm.setNextPrev(),
                            doRenew: _vm.privateDoRenew,
                            current: _vm.privateCurrent
                          },
                          on: {
                            title: _vm.setTitle,
                            titleShortening: _vm.setTitleShortening,
                            editEvent: _vm.editEvent,
                            addNew: _vm.addNew,
                            dateChanged: function($event) {
                              _vm.setDate($event)
                              _vm.makeDayElements($event)
                            }
                          }
                        })
                      ]
                    : _vm.isActive("arbeitswoche")
                    ? [
                        _c("WeekCalendar", {
                          attrs: {
                            searchTerm: _vm.searchTerm,
                            date: _vm.selectedDate,
                            next: _vm.privateNext,
                            prev: _vm.privatePrev,
                            setNextPrev: _vm.setNextPrev(),
                            doRenew: _vm.privateDoRenew,
                            current: _vm.privateCurrent
                          },
                          on: {
                            title: _vm.setTitle,
                            titleShortening: _vm.setTitleShortening,
                            editEvent: _vm.editEvent,
                            addNew: _vm.addNew,
                            dateChanged: function($event) {
                              _vm.setDate($event)
                              _vm.makeDayElements($event)
                            }
                          }
                        })
                      ]
                    : _vm.isActive("month")
                    ? [
                        _c("MonthCalendar", {
                          attrs: {
                            searchTerm: _vm.searchTerm,
                            date: _vm.selectedDate,
                            next: _vm.privateNext,
                            prev: _vm.privatePrev,
                            setNextPrev: _vm.setNextPrev(),
                            doRenew: _vm.privateDoRenew,
                            current: _vm.privateCurrent
                          },
                          on: {
                            title: _vm.setTitle,
                            titleShortening: _vm.setTitleShortening,
                            editEvent: _vm.editEvent,
                            addNew: _vm.addNew,
                            dateChanged: function($event) {
                              _vm.setDate($event)
                              _vm.makeDayElements($event)
                            }
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }