<template>
    <div>
        <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            :title="(values.combo_plantyp === '2' ? 'Entnahmeplan' : 'Sparplan') + ' Info'" 
            :actions="headerActions"
            @action-BACK="back"
            @action-CANCEL="abbrechen"
            @action-REMOVE="remove"
        />

        <div class="box__container">
            <div class="d-block">
                <Fields ref="refFields" v-if="showFields"
                    :fields="fields"
                    :combos="combos" 
                    :tableData="{}"
                    :valuesProp="values"
                    :isNotReactive="true"
                    :validators="validators"
                >
                </Fields>
            </div>
        </div>
    </div>
</template>

<script>
import Fields from '@/components/begleitscheine/Fields.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import { required, regex, iban } from "@/mixins/validator/rules";
import CORE_TYPES from '@/store/core/types'
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    components: {
        Fields,
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    computed: {
        ...mapGetters({
            bgs: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
        }),
        isNew() {
            return this.planId === 'neu';
        },
        fields() {
            return this.bgs?.steps?.SPARPLAN?.form || [];
        },
        planId() {
            return this.$route.params.plan_id;
        },
        headerActions() {
            const { isNew, values, } = this;

            const actions = [];
            actions.push(PageHeaderSimpleAction('CANCEL', 'Änderungen verwerfen'));
            if(!isNew) {
                actions.push(PageHeaderSimpleAction('REMOVE', 'Löschen'));
            }
            return actions;
        },
    },
    data() {
        return {
            values: {},
            initial: {},
            combos: {},
            cancel: false,
            showFields: false,
            validators: {
                combo_plantyp: [required('Bitte Plan Typ auswählen')],
                input_bic: [regex(/^\w{8,8}$|^\w{11,11}$|^$/, ' ')],
                input_iban: [iban('IBAN ist ungültig')],
            },
            lastIbanDE: '',
        }
    },
    mounted() {
        this.combos = this.bgs?.combos || {};
        const values = {};
        if (!this.isNew) {
            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_VAL, {planid: this.planId})
            .then(result => {
                if (result?.data?.ok) {
                    Object.keys(result.data).forEach( key => {
                        if (key !== 'ok') {
                            values[key] = result.data[key];
                        }
                    })
                }
            });
        }
        this.fields.forEach(field => {
            if (values[field.key] === undefined) {
                if (field.dataType === 'check') {
                    values[field.key] = false;
                } else {
                    values[field.key] = '';
                }
            }
        })
        this.values = values;
        this.initial = JSON.parse(JSON.stringify(values))
        this.showFields = true;
    },
    methods: {
        remove() {
            const params = {
                ask: 'del_item',
                item: 'Splarplan',
                planid: this.planId,
            };
             this.$confirmModal({
                title: 'Bestätigung', 
                message: 'Soll der Spar-/Entnahmeplan wirklich gelöscht werden?', 
                labelButtonConfirm: 'Ja',
                labelButtonCancel: 'Abbrechen',
                showCancelButton: true,
                })
            .then(() => {
                this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, params)
                    .then(response => {
                        if (response.ok){
                            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.values.param_bgsnr});
                        }
                })
                this.back();
            })
        },
        abbrechen() {
            this.cancel = true;
            this.back();
        },
        back() {
            this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.$route.params.bgsnr}/SPARPLAN?backAction=true`);
        },
    },
    watch: {
        values: {
            handler() {
                if (this.values.input_iban?.length === 22 && this.values.input_iban.substring(0,2) === 'DE'
                && (this.lastIbanDE != this.values.input_iban) && iban().isValid(this.values.input_iban) ) {
                    this.showFields = false;
                    this.lastIbanDE = this.values.input_iban
                    // weg mit führende 0
                    this.values.input_kontonummer = '' + parseInt(this.values.input_iban.substring(12));
                    this.values.input_blz = this.values.input_iban.substring(4,12);
                    this.$nextTick(() => {this.showFields = true;}) // force update Fields
                }
            },
            deep: true,
        },
    },
    beforeRouteLeave(to, from, next) {
        const anyChanges = Object.keys(this.values).some(key => this.initial[key] != this.values[key]);
        if (!this.cancel && anyChanges) {
            if (!this.values['combo_plantyp']) {
                this.$confirmModal({
                    title: 'Plan Typ darf nicht leer sein', 
                    message: 'Bitte wählen Sie den Plan Typ.', 
                    labelButtonConfirm: 'Ok',
                    showCancelButton: false,
                    })
            } else {
                this.values.bgsnr = this.$route.params.bgsnr;
                this.values.ask = 'save_plan';
                this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE, this.values);
                this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
                next();
            }
        } else {
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
            next();
        }
    }

}
</script>

<style>

</style>