<template>
  <div>
    <PageHeaderActionCard title="Ausgang" :showBreadcrumb="false">
      <template #actionButtons>
        <div class="mb-3">
          Hier können Sie Ihren E-Mail Server für den Versand Ihrer Kunden-E-Mails eintragen. Dies hat den Vorteil, dass Ihre E-Mail
          Absenderadresse genau zu dem versendenden Server passt und somit Spam Filter des Kunden Ihre 
          E-Mails unproblematischer durchlassen. Wenn Sie dies nicht wünschen, dann lassen Sie die Einträge auf dieser Seite einfach 
          leer und/ oder entfernen Sie den Haken hinter "Senden per externen Mailserver". Es wird dann der Systemserver für 
          den Versand der Mails verwendet.
        </div>
        <div> Wenn Sie nur vorübergehend die Mails über den Systemserver versenden wollen, dann nehmen Sie nur den Haken 
          "Senden per externen Mailserver" heraus. Später können Sie den Versand über Ihren Server dann wieder aktivieren.
        </div>
      </template>
    </PageHeaderActionCard>

    <div class="box__container">
      <BaseButton v-if="form && form.externen && isMicrosoftlinkVisible" isPrimary @click="getMicrosoftLink()">(Microsoft) Berechtigungen erteilen</BaseButton>

      <ComboBox
        label="Servertyp"
        :values="types"
        v-model="form.type"
        @change="typeChanged($event)"
        :firstEmpty="false"
        isComponentHalfSize
      />
      <InputField label="Server" v-model="form.server" id="form.server" :disabled="disabled || isMicrosoft" isComponentHalfSize validateUntouched />
      <InputField label="Port" v-model="form.port" :disabled="disabled || isMicrosoft" isComponentHalfSize />
      <InputField label="Benutzer" v-model="form.name" id="form.name" :disabled="disabled" isComponentHalfSize validateUntouched @input="updateMicrosoftConfirmed()" />
      <InputField
        label="Passwort"
        v-model="form.passwd"
        :type="passwordType"
        :disabled="disabled || isMicrosoft"
        isComponentHalfSize
        @focus="handlePswField(true)"
      />
      <InputToggleSwitch v-model="form.zertifikatCheck" label="Zertifikatsprüfung durchführen" :disabled="disabled" inLineLabel />
      <InputToggleSwitch v-model="form.externen" label="Senden per externen Mailserver" :disabled="disabled" inLineLabel />
    </div>

    <BaseModal ref="errorModal" 
      labelButtonCancel="Abbrechen"
      labelButtonConfirm="Zurück ohne speichern"
      :showConfirmButton="true"
      @onCancelButton="$refs.errorModal.close()"
      @onCloseButton="$refs.errorModal.close()"
      @onConfirmButton="canLeavePage =true; goBack()">
      
      <template v-slot:accessTitle>
        Fehler
      </template>
      <div>
        Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche.
      </div>
    </BaseModal>
  </div>
</template>

<script>
import E_MAIL_ACCESS_TYPES from "@/store/eMailAccess/types";

import BaseButton from "@/components/core/BaseButton.vue";
import PageHeaderActionCard from "@/components/core/PageHeaderActionCard.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import emailAccessMixin from "@/mixins/email-access-mixin.js";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import BaseModal from '@/components/core/BaseModal.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  mixins: [emailAccessMixin, validator],
  components: {
    ComboBox,
    InputField,
    BaseButton,
    PageHeaderActionCard,
    InputToggleSwitch,
    BaseModal,
  },
  validators: {},
  data() {
    return {
      types: [{label: "smtp", value: 'smtp'}, { label: "microsoft", value: 'msgraph'}, { label: 'keinen Ausgangs-Server', value: '' }],
      showExpanded: false,
      canLeavePage: false,
    };
  },
  mounted() {
    if (this.$route.query.result) {
      this.$store.commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, {
        type: 'msgraph',
        server: this.$route.query.INPUT_AUSGANG_SERVER,
        name: this.$route.query.INPUT_AUSGANG_USER,
        passwd: '',
        port: '',
        microsoftConfirmed: this.$route.query.errorMsg ? false : true,
        zertifikatCheck: this.$route.query.CHECKBOX_OUT_SEND_USE_CERT,
        externen: this.$route.query.CHECKBOX_OUT_SEND,
      })
      if (this.$route.query.errorMsg) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.$route.query.errorMsg, 'danger', false));
      } else if (this.$route.query.CHANGE_STEP) {
        setTimeout(() => this.$router.push('/intern/email-access/eingang'), 500);
      } else {
        setTimeout(() => this.doSave({}), 500);
      }
    } else {
      this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_MS_GRAPH_EMAIL_DATA_OUTPUT);
    }
  },
  computed: {
    disabled() {
      return !this.form.type;
    },
    dynamicValidators() {
      if (this.form?.type && this.$configureValidators) {
        return {}
      }
    },
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(hasValidators) {
        if (this.form?.type) {
          const validators = { 
            form: { 
              server: this.form.type === 'smtp' ? [required('Server ist erforderlich!')] : [],
              name: [required('Benutzername ist erforderlich!')],
              
            }
          };
          this.$configureValidators(validators);
        }
      },
    },
  },
  methods: {
    async save( {pathname, changeStep}) {
      if (this.isChanged() || (this.isMicrosoft && !this.microsoftConfirmed)) {
        if (this.form?.externen && this.validation.invalid){
          this.$confirmModal({
            title: 'Fehler', 
            message: 'Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche.', 
            labelButtonCancel: 'Ok',
            showConfirmButton: false,
          })
        } else {
          const result = await this.doSave( {pathname, changeStep});
          this.canLeavePage = result;
        }
      } else {
        this.canLeavePage = true;
      }
    },
    async doSave( {pathname, changeStep}) {
      const data = {};
      Object.keys(this.form).forEach(key => data[key] = (typeof this.form[key]) === 'string' ? this.form[key]?.trim() : this.form[key]);
      const result = await this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SET_E_MAIL_OUTPUT_SERVER, {data,  pathname, changeStep});
      if (result){
        this.updateInitialBenutzerName()
      }
      return result;
    },
    getMicrosoftLink() {
      this.save({});
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.save({ pathname: this.$route.query.CHANGE_STEP || !this.isMicrosoft ? '/intern/email-access/eingang' : '/intern/email-access/ausgang', 
      changeStep: this.$route.query.CHANGE_STEP || !this.isMicrosoft || !this.form.externen ? false : true});
    if (this.canLeavePage) {
      next();
    }
  },
  
};
</script>
