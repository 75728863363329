var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions,
          title: "Gesellschaften"
        },
        on: { "action-NEW_GESELLSCHAFT": _vm.openCreateGesellschaftModal }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "790ca065-681f-4a9f-952f-ec8c993ca818",
          title: "Gesellschaften filtern",
          metadata: _vm.metadata,
          configFilter: _vm.configFilter,
          immidiateSearch: !_vm.isIntern,
          hasSmartSearch: "",
          showSaveButton: ""
        },
        on: { onFilter: _vm.doFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("Table", {
                attrs: {
                  title: "Gesellschaften",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20,
                  exportConfig: { roottext: "Gesellschaften" },
                  mobileConfig: {}
                },
                on: {
                  "click-id": _vm.goToGesellschaft,
                  "click-label": _vm.goToGesellschaft,
                  "action-DELETE": _vm.deleteGesellschaft
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createGesellschaftModal",
          attrs: {
            modalTitle: "Gesellschaft erstellen",
            labelButtonConfirm: "Erstellen",
            confirmDisabled:
              !_vm.gesellschaftData.id ||
              !_vm.gesellschaftData.name ||
              !_vm.gesellschaftData.art
          },
          on: { onConfirmButton: _vm.createGesellschaft }
        },
        [
          _c("InputField", {
            attrs: { label: "ID", renderDanger: !_vm.gesellschaftData.id },
            model: {
              value: _vm.gesellschaftData.id,
              callback: function($$v) {
                _vm.$set(_vm.gesellschaftData, "id", $$v)
              },
              expression: "gesellschaftData.id"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              renderDanger: !_vm.gesellschaftData.name
            },
            model: {
              value: _vm.gesellschaftData.name,
              callback: function($$v) {
                _vm.$set(_vm.gesellschaftData, "name", $$v)
              },
              expression: "gesellschaftData.name"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Art", values: _vm.artValues },
            model: {
              value: _vm.gesellschaftData.art,
              callback: function($$v) {
                _vm.$set(_vm.gesellschaftData, "art", $$v)
              },
              expression: "gesellschaftData.art"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }