var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Einstellung",
          subtitle: _vm.configDefinitionForm.label,
          actions: _vm.headerActions
        },
        on: {
          "action-EDIT": function($event) {
            return _vm.openDetailsModal()
          },
          "action-PERMISSION": function($event) {
            return _vm.openPermissionModal()
          }
        }
      }),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c("ConfigEditVerticalStep", {
                  ref: "stepper",
                  attrs: {
                    configStep: _vm.configDefinitionForm.steps,
                    configData: _vm.emptyData,
                    selectedStep: _vm.selectedStep,
                    disabled: _vm.isLoading || _vm.isNewConfigDefinition
                  },
                  on: {
                    collapsed: function($event) {
                      _vm.stepperCollapsed = $event
                    },
                    setStep: _vm.setSelectedStep,
                    setSubstep: _vm.setSelectedSubstep,
                    stepEdited: function($event) {
                      return _vm.stepEdited($event)
                    },
                    removeStep: function($event) {
                      return _vm.removeStep($event)
                    },
                    addStep: function($event) {
                      return _vm.addStep()
                    },
                    orderChanged: function($event) {
                      return _vm.onStepOrderChanged($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _vm.loading || _vm.selectedStep
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _vm.loading
                          ? _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: "120px" } }
                              }
                            })
                          : _vm.selectedStepForm.configComponents.length
                          ? _c(
                              "SortableList",
                              {
                                key:
                                  _vm.selectedStepForm.configComponents.length,
                                staticClass: "configs-components__sortable",
                                attrs: {
                                  items: _vm.selectedStepForm.configComponents
                                },
                                on: {
                                  orderChanged: function($event) {
                                    return _vm.onOrderChanged($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "configs-components",
                                    attrs: { "data-sortable-container": "" }
                                  },
                                  _vm._l(
                                    _vm.selectedStepForm.configComponents,
                                    function(component, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: component._uuid || component.id,
                                          staticClass:
                                            "configs-component--item",
                                          class: {
                                            active:
                                              _vm.editEnabled[
                                                component._uuid || component.id
                                              ]
                                          },
                                          attrs: { "data-sortable-item": "" }
                                        },
                                        [
                                          _vm.editEnabled[
                                            component._uuid || component.id
                                          ]
                                            ? [
                                                _c("ConfigEditParamLabel", {
                                                  attrs: {
                                                    param: component,
                                                    disabled: _vm.isLoading
                                                  },
                                                  on: {
                                                    confirm: function($event) {
                                                      _vm.onParamLabelEdited(
                                                        index,
                                                        $event
                                                      )
                                                      _vm.setParamEditEnabled(
                                                        index,
                                                        false
                                                      )
                                                    },
                                                    cancel: function($event) {
                                                      return _vm.setParamEditEnabled(
                                                        index,
                                                        false
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            : [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "configs-component--item-input"
                                                  },
                                                  [
                                                    _c("AntragComponent", {
                                                      attrs: {
                                                        component: component,
                                                        data: _vm.emptyData,
                                                        disabled: "",
                                                        isComponentHalfSizeEnabled:
                                                          ""
                                                      }
                                                    }),
                                                    _vm.isLabelEdited(component)
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "configs-component--item-original-label text-small"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "configs-component--item-original-label--icon"
                                                              },
                                                              [
                                                                _c(
                                                                  "PhNotePencil",
                                                                  {
                                                                    attrs: {
                                                                      size: 16
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "configs-component--item-original-label--text"
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Originaltitel:"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      _vm.sanitize(
                                                                        component.originalLabel
                                                                      )
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "configs-component--item-actions"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "configs-component--item-action--sortable"
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn-clear",
                                                      attrs: {
                                                        type: "button",
                                                        disabled: _vm.isLoading,
                                                        "data-sortable-item-handler":
                                                          ""
                                                      }
                                                    },
                                                    [
                                                      _c("PhList", {
                                                        attrs: { size: 20 }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c("BaseContextMenu", {
                                                staticClass:
                                                  "configs-component--item-action--context-menu",
                                                attrs: {
                                                  disabled: _vm.isLoading,
                                                  placement: "bottom-right"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function() {
                                                        return [
                                                          _vm.isLabelEditVisible(
                                                            component
                                                          )
                                                            ? _c(
                                                                "ContextMenuGroup",
                                                                [
                                                                  _c(
                                                                    "ContextMenuItem",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.isLoading
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.setParamEditEnabled(
                                                                            index,
                                                                            true
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Titel bearbeiten"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.isLabelEdited(
                                                                    component
                                                                  )
                                                                    ? _c(
                                                                        "ContextMenuItem",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm.isLoading
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.resetEditedLabel(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Titel zurücksetzen"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "ContextMenuGroup",
                                                            [
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openAddParamModal(
                                                                        index,
                                                                        "before"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Neue Einstellung davor einfügen"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openAddParamModal(
                                                                        index,
                                                                        "after"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Neue Einstellung danach einfügen"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "ContextMenuGroup",
                                                            [
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addLabel(
                                                                        index,
                                                                        "before"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Titel davor einfügen"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addLabel(
                                                                        index,
                                                                        "after"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Titel danach einfügen"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "ContextMenuGroup",
                                                            [
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addDivider(
                                                                        index,
                                                                        "before"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Trenn-Linie davor einfügen"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addDivider(
                                                                        index,
                                                                        "after"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Trenn-Linie danach einfügen"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "ContextMenuGroup",
                                                            [
                                                              _c(
                                                                "ContextMenuItem",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeComponent(
                                                                        index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Löschen"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedStep
                          ? _c(
                              "div",
                              { staticClass: "configs-component--actions" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-clear clickable",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openAddParamModal()
                                      }
                                    }
                                  },
                                  [
                                    _c("PhPlus", { attrs: { size: 16 } }),
                                    _vm._v(" neuer Parameter ")
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-clear clickable",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addLabelAtLastIndex()
                                      }
                                    }
                                  },
                                  [
                                    _c("PhPlus", { attrs: { size: 16 } }),
                                    _vm._v(" neuer Titel ")
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ConfigDetailsModal", {
        ref: "configDetailsModal",
        on: {
          changed: function($event) {
            return _vm.onDetailsChanged($event)
          }
        }
      }),
      _c("ConfigAddParamModal", {
        ref: "configAddParamModal",
        attrs: { alreadySelected: _vm.selectedComponents },
        on: {
          add: function($event) {
            return _vm.onAdd($event)
          }
        }
      }),
      _c("ConfigPermissionModal", {
        ref: "configPermissionModal",
        attrs: {
          deniedFCConfigType: _vm.deniedFCConfigType,
          allowedFCConfigType: _vm.allowedFCConfigType
        },
        on: {
          changed: function($event) {
            return _vm.onPermissionChanged($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }