var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sortable-table__container" },
    [
      _vm.title || _vm.showContextMenu
        ? _c("PageHeaderTitleNavigation", {
            attrs: {
              isSecondary: "",
              asPrimaryOnly: "",
              actions: _vm.headerActionsIntern
            },
            on: { action: _vm.handleHeaderAction },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      !_vm.$slots.customTableTitle
                        ? _vm._t("table_title", function() {
                            return [_vm._v(_vm._s(_vm.title))]
                          })
                        : _vm._t("customTableTitle")
                    ]
                  },
                  proxy: true
                },
                {
                  key: "action-TABLE__EXPORT-BUTTONS",
                  fn: function() {
                    return [
                      _vm.exportConfig
                        ? _c("ExportButtons", {
                            attrs: {
                              headers: _vm.visibleHeadersFlat,
                              rows: _vm.exportedRows,
                              config: _vm.exportConfig
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "action-TABLE__DROPDOWN-MENU",
                  fn: function() {
                    return [_vm._t("dropdown_menu")]
                  },
                  proxy: true
                },
                _vm._l(_vm.headerActionsInternSlot, function(hAction) {
                  return {
                    key: hAction.value,
                    fn: function() {
                      return [_vm._t(hAction.key, null, null, hAction)]
                    },
                    proxy: true
                  }
                })
              ],
              null,
              true
            )
          })
        : _vm._e(),
      !_vm.rows.length
        ? _c("NoData", {
            attrs: { noIcon: !_vm.noDataIcon, content: _vm.noDataContent }
          })
        : !_vm.cardView
        ? _c("ResponsiveTable", {
            attrs: {
              headers: _vm.visibleHeaders,
              rows: _vm.visibleRows,
              scrollHorizontally: _vm.toggleHorizontalScrolling,
              loadingCollapsed: _vm.loadingCollapsed,
              sortableRows: _vm.sortableRows,
              valign: _vm.valign
            },
            on: {
              files: _vm.handleFile,
              shownHeaders: function($event) {
                return _vm.$emit("shownHeaders", $event)
              },
              allHeaders: function($event) {
                return _vm.$emit("allHeaders", $event)
              },
              toggleRow: function($event) {
                return _vm.$emit("toggleRow", $event)
              },
              enableHorizontalScroll: _vm.onEnableHorizontalScroll,
              orderChanged: _vm.onOrderChanged
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.visibleHeadersFlat, function(ref) {
                  var key = ref.key
                  var label = ref.label
                  var toSortable = ref.toSortable
                  var smallScreenOptions = ref.smallScreenOptions
                  var align = ref.align
                  var title = ref.title
                  return {
                    key: "header_" + key,
                    fn: function(ref) {
                      var cardView = ref.cardView
                      return [
                        _c(
                          "div",
                          {
                            key: key,
                            class: {
                              header: true,
                              clickable: !cardView && !!toSortable
                            },
                            style: {
                              "justify-content": cardView
                                ? "start"
                                : _vm.justifyAlign[align]
                            },
                            attrs: {
                              tid: _vm._generateTidFromString(
                                _vm.tableId + ":" + (key || label)
                              )
                            },
                            on: {
                              click: function() {
                                return (
                                  !cardView && toSortable && _vm.onSort(key)
                                )
                              }
                            }
                          },
                          [
                            label == null
                              ? _vm._t("header_" + key, null, null, {
                                  key: key
                                })
                              : _vm.thHoverDisabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "header_label",
                                    style: {
                                      "-webkit-line-clamp": _vm.maxHeaderLines
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "hide-on-small-screen":
                                            smallScreenOptions &&
                                            smallScreenOptions.hideHeaderLabel
                                        }
                                      },
                                      [_vm._v(_vm._s(label))]
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "header_label",
                                    style: {
                                      "-webkit-line-clamp": _vm.maxHeaderLines
                                    },
                                    attrs: { title: title || label }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "hide-on-small-screen":
                                            smallScreenOptions &&
                                            smallScreenOptions.hideHeaderLabel
                                        }
                                      },
                                      [_vm._v(_vm._s(label))]
                                    )
                                  ]
                                ),
                            !cardView && toSortable && key == _vm.sortConfig.key
                              ? _c(
                                  "span",
                                  { staticClass: "order_button ml-1" },
                                  [
                                    _vm.sortConfig.ascending
                                      ? _c("PhCaretUp", { attrs: { size: 20 } })
                                      : _c("PhCaretDown", {
                                          attrs: { size: 20 }
                                        })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    }
                  }
                }),
                _vm._l(_vm.visibleHeadersFlat, function(ref) {
                  var key = ref.key
                  var rowElement = ref.rowElement
                  var isLink = ref.isLink
                  var isLinkIf = ref.isLinkIf
                  var footer = ref.footer
                  var colorFn = ref.colorFn
                  var props = ref.props
                  return {
                    key: key,
                    fn: function(ref) {
                      var row = ref.row
                      var id = ref.id
                      var collapsed = ref.collapsed
                      return [
                        row.__FOOTER__
                          ? [
                              footer
                                ? _c(
                                    rowElement,
                                    _vm._b(
                                      {
                                        key: key,
                                        tag: "component",
                                        staticClass: "footer",
                                        attrs: {
                                          column: key,
                                          row: row,
                                          tid:
                                            _vm.tableId +
                                            ":" +
                                            key +
                                            ":" +
                                            id +
                                            ":",
                                          collapsed: collapsed
                                        }
                                      },
                                      "component",
                                      props,
                                      false
                                    )
                                  )
                                : _vm._e()
                            ]
                          : [
                              !rowElement
                                ? _vm._t(key, null, null, {
                                    row: row,
                                    id: id,
                                    collapsed: collapsed
                                  })
                                : _vm.isRowLink(row, isLink, isLinkIf)
                                ? _c(
                                    "a",
                                    {
                                      key: key,
                                      attrs: {
                                        tid: _vm._generateTidFromString(
                                          _vm.tableId + ":" + key + ":" + id
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickCell(row, key)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        rowElement,
                                        _vm._b(
                                          {
                                            key: key,
                                            tag: "component",
                                            staticClass: "cell",
                                            attrs: {
                                              column: key,
                                              row: row,
                                              tid:
                                                _vm.tableId +
                                                ":" +
                                                key +
                                                ":" +
                                                id +
                                                ":",
                                              collapsed: collapsed
                                            }
                                          },
                                          "component",
                                          props,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    rowElement,
                                    _vm._b(
                                      {
                                        key: key,
                                        tag: "component",
                                        staticClass: "cell",
                                        class: colorFn && colorFn(row),
                                        attrs: {
                                          column: key,
                                          row: row,
                                          tid:
                                            _vm.tableId +
                                            ":" +
                                            key +
                                            ":" +
                                            id +
                                            ":",
                                          collapsed: collapsed
                                        },
                                        on: { action: _vm.onAction }
                                      },
                                      "component",
                                      props,
                                      false
                                    )
                                  )
                            ]
                      ]
                    }
                  }
                }),
                {
                  key: "bottom__table",
                  fn: function() {
                    return [
                      (_vm.headersSize || _vm.pageSize) && _vm.showWeiterLink
                        ? _c("div", { staticClass: "weitere___link" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToCard()
                                  }
                                }
                              },
                              [_vm._v("Weitere...")]
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _c(
            "SortableList",
            {
              attrs: { items: _vm.visibleRows },
              on: { orderChanged: _vm.onOrderChanged }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card-container",
                  attrs: { "data-sortable-container": _vm.sortableRows }
                },
                _vm._l(_vm.visibleRows, function(ref) {
                  var acceptsFile = ref.acceptsFile
                  var id = ref.id
                  var row = ref.row
                  var loadingCollapsed = ref.loadingCollapsed
                  return _c(
                    "div",
                    {
                      key: id,
                      style: { width: _vm.cardWidth + "px" },
                      attrs: {
                        "data-sortable-item":
                          _vm.sortableRows && !row.__FOOTER__
                      }
                    },
                    [
                      _c(
                        "TableCard",
                        {
                          attrs: {
                            topHeaders: _vm.visibleHeadersFlatComputed,
                            loadingCollapsed: loadingCollapsed,
                            row: row,
                            acceptsFile: acceptsFile
                          },
                          on: { files: _vm.handleFile }
                        },
                        [
                          _c("template", { slot: "_sortable_handler" }, [
                            _vm.sortableRows && !row.__FOOTER__
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "_sortable-handler",
                                    attrs: { "data-sortable-item-handler": "" }
                                  },
                                  [_c("PhList", { attrs: { size: 16 } })],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._l(_vm.visibleHeadersFlatComputed, function(ref) {
                            var key = ref.key
                            var label = ref.label
                            var smallScreenOptions = ref.smallScreenOptions
                            var title = ref.title
                            return _c("template", { slot: "header_" + key }, [
                              _c(
                                "div",
                                {
                                  key: key,
                                  staticClass: "header",
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      _vm.tableId + ":" + (key || label)
                                    )
                                  }
                                },
                                [
                                  label == null
                                    ? _vm._t("header_" + key, null, null, {
                                        key: key
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "header_label",
                                          style: {
                                            "-webkit-line-clamp":
                                              _vm.maxHeaderLines
                                          },
                                          attrs: { title: title || label }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "hide-on-small-screen":
                                                  smallScreenOptions &&
                                                  smallScreenOptions.hideHeaderLabel
                                              }
                                            },
                                            [_vm._v(_vm._s(label))]
                                          )
                                        ]
                                      )
                                ],
                                2
                              )
                            ])
                          }),
                          _vm._l(_vm.visibleHeadersFlatComputed, function(ref) {
                            var key = ref.key
                            var rowElement = ref.rowElement
                            var isLink = ref.isLink
                            var isLinkIf = ref.isLinkIf
                            var colorFn = ref.colorFn
                            var props = ref.props
                            return _c(
                              "template",
                              { slot: key },
                              [
                                [
                                  !rowElement
                                    ? _vm._t(key, null, null, {
                                        row: row,
                                        id: id
                                      })
                                    : _vm.isRowLink(row, isLink, isLinkIf)
                                    ? _c(
                                        "a",
                                        {
                                          key: key,
                                          attrs: {
                                            tid: _vm._generateTidFromString(
                                              _vm.tableId + ":" + key + ":" + id
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickCell(row, key)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            rowElement,
                                            _vm._b(
                                              {
                                                key: key,
                                                tag: "component",
                                                staticClass: "cell",
                                                attrs: {
                                                  column: key,
                                                  row: row,
                                                  tid:
                                                    _vm.tableId +
                                                    ":" +
                                                    key +
                                                    ":" +
                                                    id +
                                                    ":"
                                                }
                                              },
                                              "component",
                                              props,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        rowElement,
                                        _vm._b(
                                          {
                                            key: key,
                                            tag: "component",
                                            staticClass: "cell",
                                            class: colorFn && colorFn(row),
                                            attrs: {
                                              column: key,
                                              row: row,
                                              tid:
                                                _vm.tableId +
                                                ":" +
                                                key +
                                                ":" +
                                                id +
                                                ":"
                                            },
                                            on: { action: _vm.onAction }
                                          },
                                          "component",
                                          props,
                                          false
                                        )
                                      )
                                ]
                              ],
                              2
                            )
                          })
                        ],
                        2
                      ),
                      _vm.headersSize && _vm.showWeiterLink
                        ? _c("div", { staticClass: "weitere___link" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToCard()
                                  }
                                }
                              },
                              [_vm._v("Weitere...")]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]
          ),
      _c("ModalColumnConfig", {
        ref: "columnsConfig",
        attrs: {
          title: _vm.title ? _vm.title + " - Einstellungen" : "Einstellungen",
          tableId: _vm.tableId,
          tableHeaders: _vm.defaultHeaders,
          lockedColumns: _vm.fakeLockedColumns
        },
        on: {
          close: _vm.onCloseColumnsConfig,
          onFinishConfig: _vm.onFinishColumnsConfig,
          onRestoreDefault: _vm.onFinishColumnsConfig
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }