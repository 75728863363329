<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Transaktionsdaten" 
      :actions="headerActions"
      @action-CANCEL="abbrechen"
      @action-COMPLETE="setBgsTransakt('complete_transakt', 0)"
    />

    <div class="box__container d-lg-none col-12">
      <Stepper2
        ref="refStepper2"
        stepType="step"
        :visible="true"
        stepperName="BegleitscheinData"
        :selectedStepKey="selectedStepKey"
        :stepperService="steps"
        @setStep="setStep($event)"
      ></Stepper2>
    </div>

    <BaseCollapsable  v-if="hinweise.length" class="box__container" v-model="showHinweis">
      <template v-slot:title>
        <span class="box__title" :class="{'fc-form-danger': blinkHinweis}">
          {{hinweisTitle()}}
        </span>
      </template>
      <template v-slot:content>
        <div class="d-block">
          <div v-for="(text, idx) in hinweise" :key="idx">
            <div v-html="sanitize(text)"></div>
          </div>
        </div>
      </template>
    </BaseCollapsable>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
        <template #stepper>
            <BoxContainer>
                <VerticalStepper
                    :stepType="'tab'"
                    :visible="true"
                    :stepperName="'BegleitscheinTransakt'"
                    :selectedStepKey="selectedStepKey"
                    :stepperService="steps"
                    :showCollapse="true"
                    @setStep="setStep($event)"
                    @collapsed="stepperCollapsed = $event"
                ></VerticalStepper>
            </BoxContainer>
        </template>
        <template #content>
          <div>
            <ComponentList
              ref="components"
              :components="fields"
              :data="values"
              :comboboxValues="combos"
              :warnings="{}"
              :foreignCurrencies="foreignCurrencies"
              @change="updateValue"
              @isValid="setValid"
            ></ComponentList>
          </div>
          <!-- <Fields class="box__container" ref="components"
            :fields="fields" :valuesProp="values" :combos="combos" :rowsCount="null"
            @onBtClick="onBtClick" :validators="validators"
            @change="changed = $event"
          /> -->
          <div class="box__container antrag-bottom-buttons-container row">
            <div class="col-12 my-1 col-sm-auto" v-if="!hasOnlyOneStep && selectedStepKey !== 'TRANSAKT'">
              <BaseButton isBlock @click="setStep('TRANSAKT')">Zurück</BaseButton>
            </div>
            <div class="col-12 my-1 col-sm-auto" v-if="!hasOnlyOneStep && selectedStepKey !== 'STEUER'">
              <BaseButton isBlock @click="setStep('STEUER')">Weiter</BaseButton>
            </div>
          </div>
        </template>
    </ContentWithStepper>

  </div>
</template>

<script>
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import Fields from '@/components/begleitscheine/Fields.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import { required } from "@/mixins/validator/rules";
import {sanitize} from '@/helpers/string-helper.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import CORE_TYPES from '@/store/core/types';
import Stepper2 from '@/components/stepper/Stepper2.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";

export default {
  name: 'BegleitscheinTransakt',
  validators: {},
  components: {
    BaseCollapsable, Fields, ContentWithStepper, BoxContainer, VerticalStepper, OptionMenu, PageHeaderTitleNavigation, BaseButton, Stepper2, ComponentList
  },
  props: {
  },
  data: function() {
    return {
      bgsnr: '',
      transnr: '0',
      currentSelectedTab: null,
      stepperCollapsed: false,
      seiten: {},
      values: {},
      dbValues: {},
      combos: {},
      enabled: {},
      rowsCount: null,
      hinweise: [],
      blinkHinweis: false,
      showHinweis: false,
      validators: {
        inputtransdat: [required('Bitte Transaktionsdatum erfassen', {forceTouch: true})],
        inputtransaktionsart: [required('Bitte Transaktionsart auswählen', {forceTouch: true})],
        inputbetrachtungsweise: [required('Bitte Betrachtungsweise auswählen', {forceTouch: true})],
        input_wechselkurs: [required('Bitte Wechselkurs erfassen', {forceTouch: true})],
        combo_waehrung: [required('Bitte Fremdwährung auswählen', {forceTouch: true})],
        inputbruttosummeeuro: [required('Bitte Buchungssumme (Euro) erfassen', {forceTouch: true})],
        inputanteile: [required('Bitte Anzahl Anteile erfassen', {forceTouch: true})],
      },
      isValid: true,
    }
  },

  mounted() {
    this.bgsnr = this.$route.params.bgsnr;
    this.transnr = this.$route.params.transnr || '0';
    this.getBgsTransakt();
  },
  beforeRouteLeave(to, from, next) {
    if (this.cancel) {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      next();
      return;
    }
    if (!this.isValid) {
      this.$confirmModal({
        title: 'Fehler', 
        message: 'Bitte alle Pflichtfelder ausfüllen.', 
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      })
    } else {
      this.setBgsTransakt('save_transakt', 2);
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      next();
    }
  },
  computed: {
    fields() {
      return this.seiten && this.seiten[this.selectedStepKey] || [];
    },
    selectedStepKey() {
      return this.$route.params.stepKey;
    },
    steps() {
      const result = [];
      let nr = 1;
      if (this.seiten?.TRANSAKT ) {
        result.push({stepKey: 'TRANSAKT', label:'Transaktionsdaten', current: this.selectedStepKey==='TRANSAKT', totalProgress: nr++});
      }
      if (this.seiten?.STEUER ) {
        result.push({stepKey: 'STEUER', label:'Steuerdaten', current: this.selectedStepKey==='SPARPLAN', totalProgress: nr++});
      }
      return new StepperMediator(result)
    },
    hasOnlyOneStep() {
      return this.steps?.getCurrentStepList()?.length === 1;
    },
    changed() {
      return JSON.stringify(this.dbValues) !== JSON.stringify(this.values);
    },
    headerActions() {
      const { isValid, } = this;

      const actions = [];
      actions.push(PageHeaderSimpleAction('CANCEL', 'Änderungen verwerfen'));
      actions.push(PageHeaderSimpleAction('COMPLETE', 'Vervollständigen'));
      return actions;
    },
    foreignCurrencies() {
      return {
        inputanteile: this.values?.combo_waehrung || '',
        input_zahlungsbrutto: this.values?.combo_waehrung || '',
        inputbruttosumme: this.values?.combo_waehrung || '',
        inputnettosumme: this.values?.combo_waehrung || '',
        input_zahlungsbrutto: this.values?.combo_waehrung || '',
        input_zahlungsnetto: this.values?.combo_waehrung || '',
        inputaabetrag: this.values?.combo_waehrung || '',
      }
    }
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    setValid(value) {
      this.isValid = value;
    },
    getBgsTransakt() {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
        ask: 'get_transakt',
        bgsnr: this.bgsnr || '',
        transnr: this.transnr || '', 
      }).then(response => {
        if (response?.ok) {
          this.values = response.values;
          this.combos = response.combos;
          this.seiten = response.seiten;
          if (this.seiten?.TRANSAKT[0].key === 'Hinweis') {
            this.hinweise = [this.seiten.TRANSAKT[0].label];
            this.seiten.TRANSAKT = this.seiten.TRANSAKT.slice(1);
          }
          if (response.enabled) {
            this.enabled = response.enabled;
          }
          this.setForm(true);
          this.dbValues = JSON.parse(JSON.stringify(this.values));
          this.$updateCurrentBreadcrumb({
            breadcrumb: 'Transaktionsdaten',
          });
      }})
    },
    setBgsTransakt(ask, event) {
      if (ask !== "complete_transakt" && !this.isValid) {
        return;
      }
      if (event) {
        if (!this.changed && this.transnr !== '0') {
          if (event === 2){
            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.bgsnr});
          }
          return;
        }
      }
      const addParam = {ask};
      if (event === 2){
        addParam._refreshBgs = this.values.param_bgsnr;
      }
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB,
        Object.assign( {}, this.values, addParam) ).then(response => {
          if (event !== 2 && response?.ok) {
            if (response.enabled) {
              this.enabled = response.enabled;
            }
            this.values = Object.assign( {}, this.values, response.values) ;
            this.setForm(true);
            this.seiten = Object.assign({}, this.seiten);
            if (ask !== "complete_transakt") {
              this.dbValues = JSON.parse(JSON.stringify(this.values));
            }
          }});
    },
    setStep(step) {
      if (typeof step === 'string') {
        if ( this.selectedStepKey !== step ) {
          this.setBgsTransakt('save_transakt', 1);
          this.setForm(true);
          this.currentSelectedTab = this.steps.getCurrentStep(this.selectedStepKey);

          this.$router.push({path: `/home/vermogensubersicht/bgstransakt/${this.$route.params.bgsnr}/${this.$route.params.transnr}/${step}` });
        }
      }
      if ( this.currentSelectedTab?.label ) {
        this.$updateCurrentBreadcrumb({
          breadcrumb: this.currentSelectedTab.label,
        })
      }
    },
    setForm(checkValues) {
      const form = this.seiten[this.selectedStepKey] || [];
      form.forEach(field => {
        if ( this.enabled?.[field.key] !== undefined) {
          field.disabled = !this.enabled[field.key];
        }
        if (checkValues && this.values[field.key] === undefined) {
          if (field.dataType === 'check') {
            this.values[field.key] = this.values[field.key] || '0';
          } else if (field.dataType === 'combo') {
            this.values[field.key] = this.values[field.key] || (this.combos?.length ? this.combos[0].value : '');
          } else if (field.dataType !== 'button' && field.dataType !== 'info' && field.dataType !== 'label'){
            this.values[field.key] = this.values[field.key]||'';
          }
        }
      });
    },
    onBtClick(field) {
      if ( field.key === 'transaktion_complete') {
        let ok = true;
        if (field.notnull) {
          field.notnull.forEach(f => {
            if (!this.values[f]) {
              ok = false;
            }
          });
        }
        this.setBgsTransakt('complete_transakt', 0);
      }
    },
    hinweisTitle() {
      if (this.hinweise?.length === 1) {
        return '1 Hinweis';
      }
      if (this.hinweise?.length > 1) {
        return this.hinweise.length + ' Hinweise';
      }
      this.showHinweis = false;
      return 'Hinweise';
    },
    abbrechen() {
      this.cancel = true;
      this.back();
    },
    back() {
        this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.$route.params.bgsnr}/TRANSAKT?backAction=true`);
    },
    updateValue(event) {
      if (event?.componentId) {
        this.values[event.componentId] = event.value;
      }
    }
  },
}
</script>

<style scoped>

</style>