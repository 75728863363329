<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      subtitle="ExPost manuelle Kosten" 
      :actions="headerActions" 
      @action-ADD="openAddExPostManuelleModal()" 
    />

    <div class="box__container">
      <div class="box__title">ExPost manuelle Kosten</div>

      <ExPostManuelleTable @edit="openAddExPostManuelleModal($event)" @remove="removeExPostManuelle($event)" />
    </div>

    <AddExPostManuelleModal ref="addExPostManuelleModal" />
  </div>
</template>

<script>
import EX_POST_MANUELLE_TYPES from '@/store/exPostManuelle/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import ExPostManuelleTable from './components/ExPostManuelleTable.vue';
import AddExPostManuelleModal from './components/AddExPostManuelleModal.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  computed: {
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'hinzufügen'),
      ];
    },
  },
  methods: {
    openAddExPostManuelleModal(item) {
      this.$refs.addExPostManuelleModal.open(item);
    },
    removeExPostManuelle(item) {
      this.$store.dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.DELETE, item);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(EX_POST_MANUELLE_TYPES.MUTATIONS.RESET_STATE);

    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ExPostManuelleTable,
    AddExPostManuelleModal,
  },
}
</script>
