<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="eFonds24"
            subtitle="Stammdaten"
            :actions="headerActions"
            @action-UPLOAD="uploadData()"
            @action-OPEN-E-FONDS="openEFonds()"
        />
        
        <div class="row my-0">
            <div class="col-12">
            <AntragSidetext />
            </div>
        </div>

        <div class="box__container">
            <template v-if="accessRights">
                <EntitySelector
                    v-model="personId"
                    isComponentHalfSize
                    isPersonPicker
                    :values="personsCombo"
                    hideNewButton
                    @input="$emit('input', $event)"
                    @change="getStammdaten($event)"
                    @edit="editPerson(personId)"
                />
                <Table
                    :headers="headers"
                    :rows="rows"
                    hidePagination
                >
                </Table>
            </template>
            <GhostLoading v-else-if="loadingList" type="table" />
            <div v-else>Fehlende Berechtigungen für diesen Bereich</div>

        </div>

    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import ANTRAG_TYPES from '@/store/antrag/types';
import ANTRAG_EFONDS_TYPES from '@/store/antragEfonds/types';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import { PageHeaderLoadingAction, PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export const PILL_TYPES_STATUS = {
    OLD_NULL: 'danger',
    DIFFERENT: 'danger',
    NEW_NULL: 'danger',
    BOTH_EMPTY_OR_NULL: 'danger',
    SAME: 'info',
    BOTH_NULL: 'info',
};

export default {
    mixins: [antragMixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        AntragSidetext,
        EntitySelector,
        Table,
        GhostLoading,
    },
    data() {
        return {
            personId: '',
            loading: false,
            loadingList: false,
            data: null,
        }
    },
    mounted() {
        const personId = this.$route.query?.personId || '';
        this.getStammdaten(personId);
    },
    computed: {
        ...mapGetters({
            antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
            antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
        }),
        headerActions() {
            return [
                PageHeaderLoadingAction('UPLOAD', this.uploadBtnTxt, () => this.loading).withDisabled(() => !this.hasChanges),
                PageHeaderSimpleAction('OPEN-E-FONDS', 'eFonds24 Kundenverwaltung').withDisabled(() => !this.kundeRegistered),
            ];
        },
        personsCombo() {
            return this.data?.persons || [];
        },
        headers() {
            return {
                // center: [
                //     PillColumn("status", "Status"),
                // ],
                lockedLeft: [
                    PillColumn("status", "Status", 100, 0.4),
                    TextColumn("feld", "Feld"),
                    this.kundeRegistered ? TextColumn("oldValue", "alter Wert") : null,
                    TextColumn("newValue", "neuer Wert"),
                ],
            };
        },
        rows() {
            return this.data?.stammdaten?.map(row => {
                return {
                    ...row,
                    status: {
                        label: PILL_TYPES_STATUS[row.status] === 'danger' ? 'geändert' : 'ok',
                        type: PILL_TYPES_STATUS[row.status],
                    },
                }
            }) ||  [];
        },
        hasChanges() {
            const test = this.rows?.some(row => row.status?.type === 'danger');
            return this.personId && this.rows?.some(row => row.status?.type === 'danger');
        },
        accessRights() {
            return this.data?.accessRights;
        },
        uploadBtnTxt() {
            return this.kundeRegistered ? 'Daten übermitteln' : 'anlegen';
        },
        kundeRegistered() {
            return this.data?.guid;
        }
    },
    methods: {
        updateValues(data) {
            this.data = data;
            this.personId = data?.personId;
            this.stammdaten = data?.stammdaten;
        },
        uploadData() {
            const payload = Object.assign(this.data || {}, { personId: this.personId });
            this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.UPLOAD_STAMMDATEN, payload).then(data => {
                if (data?.errorStr) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(data.errorStr, 'danger'));
                } else {
                    this.updateValues(data);
                }
            })
            .catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'))
            });
        },
        async openEFonds() {
            if (this.kundeRegistered) {
                this.loading = true;
                await this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_STAMMDATEN, 
                    { guid: this.data?.guid || '',  callbackUrl: window.location.href});
                this.loading = false;
            }
        },
        getStammdaten(personId) {
            this.loadingList = true;
            this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_STAMMDATEN, { personId: personId || '' }).then(data => {
                if (data?.errorStr) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(data.errorStr, 'danger'));
                } 
                this.updateValues(data);
            })
            .catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'))
            })
            .finally(() => this.loadingList = false);
        }
    }
}
</script>

<style>

</style>