var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "ExPost",
          subtitle: _vm.title,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _vm.config.loading
        ? [
            _c(
              "GhostLoading",
              { attrs: { useBoxContainer: "" } },
              [
                _c("Block", { attrs: { height: "50" } }),
                _c("Block", { attrs: { height: "50" } }),
                _c("Block", { attrs: { height: "50" } })
              ],
              1
            )
          ]
        : _vm.display === "SETTINGS"
        ? _c(
            "BoxContainer",
            { attrs: { title: _vm.title } },
            [
              _c("HinweiseUndFehler", {
                attrs: {
                  hints: [
                    {
                      title:
                        "Welche Kunden sollen den ExPost-Kostenausweis sehen / bekommen?",
                      message:
                        "Den ExPost-Kostenausweis erhalten ausschließlich Kunden mit<br />" +
                        "1.) Honorarkosten im MSC und/oder<br />" +
                        "2.) einem Depot bei einer Depotbank, welche nach unseren Informationen " +
                        "keinen eigenen ExPost-Kostenausweis erstellt hat und/oder<br />" +
                        "3.) einem Depot und einer Servicegebührenvereinbarung bei einer " +
                        "Depotbank, welche diese Servicegebühr nach unseren Informationen nicht " +
                        "im eigenen ExPost-Kostenausweis ausgewiesen hat."
                    },
                    {
                      title: "ExPost-Hinweis-E-Mail an Kunden senden",
                      message:
                        "Die oben ausgewählten Kunden erhalten mit dieser Option automatisch eine " +
                        "Hinweis-E-Mail (Standardtext von uns oder individuell von Ihnen " +
                        "hinterlegt) zur Information über das neue Dokument im Dokumentenarchiv " +
                        "sofern der Mailversand an die Kunden möglich ist. Wird der automatische " +
                        "Mailversand deaktiviert und/oder nutzen die Kunden den Kundenzugang " +
                        "nicht, so muss die Benachrichtigung und die postalische Zusendung der " +
                        "ExPost-Kostenausweise in Eigenregie vorgenommen werden."
                    },
                    {
                      title:
                        "Der ExPost-Kostenausweis wird im Namen und mit dem Logo des aktuellen Beraters erzeugt.",
                      message:
                        "Die ExPost-Kostenausweise werden mit dieser Option im Namen und mit Logo des genannten Beraters erzeugt."
                    },
                    {
                      title:
                        "farbliche Hervorhebungen im ExPost-Kostenausweis verwenden",
                      message:
                        "Prozent- und Betragsangaben sowie Erläuterungstexte werden zur besseren Übersichtlichkeit in unterschiedlichen Farben dargestellt."
                    }
                  ]
                }
              }),
              _c("div", { staticClass: "box__title" }, [
                _vm._v(
                  " Hier können Sie einstellen, welche Kunden den ExPost Kostenausweis zur Verfügung gestellt bekommen. "
                )
              ]),
              _c("InputRadioBoxGroup", {
                attrs: { values: _vm.config.values_kosten_modus },
                model: {
                  value: _vm.wichtige_kosten_modus,
                  callback: function($$v) {
                    _vm.wichtige_kosten_modus = $$v
                  },
                  expression: "wichtige_kosten_modus"
                }
              }),
              _c("div", { staticClass: "box__title" }, [
                _vm._v(
                  " Hier können Sie weitere generelle ExPost-Einstellungen vornehmen. "
                )
              ]),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "ExPost-Hinweis-E-Mail an Kunden senden",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.mailversand_aktiv,
                  callback: function($$v) {
                    _vm.mailversand_aktiv = $$v
                  },
                  expression: "mailversand_aktiv"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: { inLineLabel: "", label: _vm.config.label_herausgeber },
                model: {
                  value: _vm.ist_herausgeber,
                  callback: function($$v) {
                    _vm.ist_herausgeber = $$v
                  },
                  expression: "ist_herausgeber"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  inLineLabel: "",
                  label:
                    "farbliche Hervorhebungen im ExPost-Kostenausweis verwenden"
                },
                model: {
                  value: _vm.ist_farbe_aktiv,
                  callback: function($$v) {
                    _vm.ist_farbe_aktiv = $$v
                  },
                  expression: "ist_farbe_aktiv"
                }
              }),
              _c(
                "BaseButton",
                { staticClass: "mt-3 mb-2", on: { click: _vm.saveConfig } },
                [_vm._v("Einstellungen für alle Kunden übernehmen")]
              )
            ],
            1
          )
        : _vm.display === "OVERVIEW"
        ? [
            _c("HinweiseUndFehler", {
              attrs: {
                hints: [
                  {
                    title: "Die rot markierten Kunden",
                    message:
                      "Der Kunde kann den ExPost Auszug nicht als E-Mail erhalten (der Mailversand ist deaktiviert, es ist keine Mailadresse eingetragen, der Kunde darf das Dokumentenarchiv nichts sehen oder der Kunde ist inaktiv)."
                  }
                ]
              }
            }),
            _c("GenericPersonFilter", {
              ref: "filter",
              attrs: {
                filterId: "37054866-c81e-4b03-bc13-f23912c312ce",
                saveKey: "searchExPostKundenUebesicht",
                title: _vm.title,
                metadata: _vm.filterData,
                configFilter: _vm.configFilter,
                predefinedFilter: _vm.predefinedFilter,
                searchFromParam: _vm.searchFromParam
              },
              on: { search: _vm.doSearch }
            }),
            _vm.rows
              ? _c(
                  "BoxContainer",
                  [
                    _vm.rows.length
                      ? _c("Fields", {
                          attrs: { fields: _vm.config.list_form },
                          on: { onBtClick: _vm.serienbrief }
                        })
                      : _vm._e(),
                    _vm.rows.length
                      ? _c("Table", {
                          ref: "viewstatistik",
                          staticClass: "table-container",
                          attrs: {
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowId: "index",
                            rowsPerPage: 25,
                            mobileConfig: { headers: ["key1", "key2", "key3"] }
                          },
                          on: {
                            "action-PDF": _vm.getPDF,
                            "click-key0": _vm.loadCustomer
                          }
                        })
                      : _c("NoData")
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }