<template>
  <div>
    <div id="wrapper">
      <LineChart
        xAxisType="category"
        :chartSeries="series()"
        :xLabels="xLabels"
        :markAreaData="markAreaData"
        :markPointData="markPointData"
        :isTooltip="false"
        :isArea="false"
        height="370px"
      />
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/echarts/LineChart.vue';
import FinancialCalculator from './financialCalculator.js';
import {getColorsMixedContrastDecreasing} from '@/helpers/colors-helper';

export const chartColorScheme = getColorsMixedContrastDecreasing();

export default {
	props: {
		data: {
			type: Object,
			default: () => {},
			required: true,
		},
	},
	data() {
		return {
    };
	},
	mounted() {
  },
  methods: {
    getColor(index) {
      return chartColorScheme[index % chartColorScheme.length];
    },
    series() {
      return this.data.series.map((serie, i) => ({
        name: 'Euro',
        data: serie.data || [],
        ...(i == 0 ? {areaStyle: {}} : {})
      }));
    },
  },
  computed: {
    xLabels() {
      return this.data?.xaxis?.categories || [];
    },
    markAreaData() {
      return (this.data?.annotations?.xaxis || []).map(annotation => [
        {
          name: annotation.label?.text || '',
          xAxis: `${annotation.x}`,
          itemStyle: {
            borderColor: annotation.label?.borderColor,
          },
          label: {
            color: annotation.label?.style?.color,
            backgroundColor: annotation.label?.style?.background,
          },
        },
        {
          xAxis: `${annotation.x}`,
        }
      ]);
    },
    markPointData() {
      return (this.data?.annotations?.points || []).map(point => ({
        coord: [point.x - 1, point.y],
        itemStyle: {
          color: point.marker.fillColor,
          borderWith: 1,
          borderColor: point.marker.strokeColor,
        },
      }));
    },
  },
	components: {
    LineChart,
	}
}
</script>

<style scoped>
</style>
