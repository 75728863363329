var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Zusätzliche Personen")]),
    _vm.isMainPerson
      ? _c(
          "div",
          [
            _vm.isBrokerOrByPass && _vm.isEditable
              ? _c(
                  "BaseButtonsContainer",
                  { staticClass: "mb-3" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.navigateToAddExistentPeople()
                          }
                        }
                      },
                      [_vm._v("Vorhandene Personen hinzufügen")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.openPersonenBeziehungenMatrixModal()
                          }
                        }
                      },
                      [_vm._v("Beziehungen aller Personen")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.newPerson()
                          }
                        }
                      },
                      [_vm._v("Neue Person")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.newPersonIdScan()
                          }
                        }
                      },
                      [_vm._v("Neue Person über Ausweis")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("Table", {
              attrs: {
                title: "Zusätzliche Personen",
                tableId: "2544518d-72be-403f-993a-4ec9defa7010",
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: 10,
                rowId: "personId",
                mobileConfig: {
                  title: "Zusätzliche Personen",
                  headers: ["lastName", "firstName", "relation"]
                },
                exportConfig: { roottext: "Zusaetzliche Personen" }
              },
              on: {
                "click-lastName": function($event) {
                  return _vm.editPerson($event.personId)
                },
                "click-relation": _vm.editPersonBeziehungen,
                "action-DELETE": _vm.deletePersonConfirmation
              }
            }),
            _c(
              "BaseModal",
              {
                ref: "deletePersonConfirmation",
                attrs: {
                  labelButtonConfirm: "Entfernen",
                  size: "sm",
                  modalTitle: "Person entfernen"
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.deletePerson()
                  }
                }
              },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.sanitize(_vm.deleteMessage))
                  }
                })
              ]
            ),
            _c("PersonEditBeziehungenModal", {
              ref: "personEditBeziehungenModal",
              on: {
                saved: function($event) {
                  return _vm.updateData()
                }
              }
            }),
            _c("PersonenBeziehungenMatrixModal", {
              ref: "personenBeziehungenMatrixModal",
              on: {
                saved: function($event) {
                  return _vm.updateData()
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }