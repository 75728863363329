var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nodeId
    ? _c(
        "div",
        { staticClass: "documents-table__container" },
        [
          _vm.isLoading && !_vm.scrollLoading
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  title: _vm.title,
                  config: { table: { rows: 5 } }
                }
              })
            : [
                _c("PaginatedTable", {
                  key: _vm.nodeId,
                  attrs: {
                    title: _vm.title,
                    tableId: _vm.tableId,
                    headers: _vm.headers,
                    pages: _vm.pages,
                    pageCount: _vm.pageCount,
                    rowCount: _vm.resultCount,
                    hidePagination: _vm.pageCount <= 1,
                    page: _vm.currentPage,
                    selectionAsBlacklist: _vm.isSelectedAllInFolder,
                    selected: _vm.showCheckboxes() ? _vm.selectedRows : null,
                    noDataIcon: _vm.showNoDataMessageWithIcon,
                    noDataContent: _vm.NO_DATA_MESSAGE,
                    mobileConfig: {
                      title: "bezeichnung",
                      headers: _vm.mobileHeaders,
                      selectionActive: true
                    },
                    headerActions: _vm.tableHeaderActions,
                    sorted: _vm.sorted
                  },
                  on: {
                    selectionAsBlacklist: function($event) {
                      return _vm.setInvertedList($event)
                    },
                    headerAction: _vm.executeHeaderAction,
                    selected: _vm.setRowSelection,
                    requestPage: _vm.onRequestPage,
                    page: _vm.setPageIndex,
                    "click-sichtbar": _vm.handleClickVisible,
                    "click-vertragsnr": _vm.openVertrag,
                    "click-schadensnr": _vm.openSchaden,
                    action: _vm.handleAction,
                    sort: _vm.onSort,
                    scrollLoading: function($event) {
                      _vm.scrollLoading = $event
                    },
                    onScroll: _vm.onScroll
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "bezeichnung",
                        fn: function(row) {
                          return [
                            row.mobileTableContext
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: { "vertical-align": "middle" }
                                  },
                                  [
                                    !row.exists
                                      ? _c("ph-trash", {
                                          staticClass: "color-danger mr-1 icon",
                                          attrs: { size: 16 }
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(
                                        row.bezeichnung ||
                                          row.fileName ||
                                          "unbenannt"
                                      ) + " "
                                    )
                                  ],
                                  1
                                )
                              : !row.data.fileWithSignedAttachments &&
                                _vm.listHasAction(
                                  row.data.actions,
                                  "DOKUMENT_ANZEIGEN"
                                )
                              ? _c(
                                  "span",
                                  [
                                    !row.exists
                                      ? _c("ph-trash", {
                                          staticClass: "color-danger mr-1 icon",
                                          attrs: { size: 16 }
                                        })
                                      : _vm._e(),
                                    _vm.$isSmallScreen ||
                                    !_vm.canPreviewFile(row)
                                      ? _c("DownloadLink", {
                                          attrs: {
                                            target: "_blank",
                                            rel: "noopener noreferer",
                                            title: row.bezeichnung,
                                            filename:
                                              row.data.fileName ||
                                              row.data.bezeichnung,
                                            downloadServicePath: "/docInfo",
                                            queryParams: {
                                              nodeId: row.nodeId,
                                              idFile: encodeURIComponent(row.id)
                                            }
                                          }
                                        })
                                      : [
                                          _c(
                                            "DownloadLink",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                rel: "noopener noreferer",
                                                title: "In neuem Tab öffnen",
                                                filename:
                                                  row.data.fileName ||
                                                  row.data.bezeichnung,
                                                downloadServicePath: "/docInfo",
                                                queryParams: {
                                                  nodeId: row.nodeId,
                                                  idFile: encodeURIComponent(
                                                    row.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.looksLikePDFFile(row)
                                                ? _c("PhFilePdf", {
                                                    attrs: { size: 16 }
                                                  })
                                                : _c("PhFile", {
                                                    attrs: { size: 16 }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v("  "),
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.fetchObject(row)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(row.bezeichnung))]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm.listHasAction(
                                  row.data.actions,
                                  row.data.actionOnClick
                                )
                              ? _c(
                                  "span",
                                  _vm._l(row.data.actions, function(action) {
                                    return _c(
                                      "span",
                                      {
                                        key: action.legend.label + _vm.uuidv4()
                                      },
                                      [
                                        action.legend.key ===
                                        row.data.actionOnClick
                                          ? _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleExecuteAction(
                                                      {
                                                        action: action,
                                                        value: row
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                !row.exists
                                                  ? _c("ph-trash", {
                                                      staticClass:
                                                        "color-danger mr-1 icon",
                                                      attrs: { size: 16 }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.bezeichnung ||
                                                        row.fileName ||
                                                        "unbenannt"
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: { "vertical-align": "middle" }
                                  },
                                  [
                                    !row.exists
                                      ? _c("ph-trash", {
                                          staticClass: "color-danger mr-1 icon",
                                          attrs: { size: 16 }
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(
                                        row.bezeichnung ||
                                          row.fileName ||
                                          "unbenannt"
                                      ) + " "
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      },
                      {
                        key: "vorlagestatus",
                        fn: function(row) {
                          return [
                            _c("WorkflowPill", {
                              attrs: {
                                currentStatusIcons: row.data.statusActions,
                                statusType: "VORLAGE"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "signaturstatus",
                        fn: function(row) {
                          return [
                            _c("WorkflowPill", {
                              attrs: {
                                currentStatusIcons: row.data.statusActions,
                                statusType: "SIGNATUR"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2095104750
                  )
                }),
                _vm.showCheckboxes() &&
                (_vm.selectedRows.length || _vm.isSelectedAllInFolder)
                  ? _c("span", [
                      _vm._v(
                        " Insgesamt " +
                          _vm._s(
                            _vm.isSelectedAllInFolder
                              ? _vm.resultCount - _vm.selectedRows.length
                              : _vm.selectedRows.length
                          ) +
                          " ausgewählt "
                      )
                    ])
                  : _vm._e()
              ],
          _c("BaseModal", {
            ref: "signoFilesModal",
            attrs: {
              labelButtonCancel: "Zurück",
              showCancelButton: false,
              showConfirmButton: false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modalTitle",
                  fn: function() {
                    return [
                      _vm.signoFiles && _vm.signoFiles.length
                        ? _c("b", [_vm._v("Mehrere Dateien zu signieren")])
                        : _c("b", [_vm._v("Keine Dokumente zur Unterschrift")])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _vm.signoFiles && _vm.signoFiles.length
                        ? _c("p", [
                            _c("b", [
                              _vm._v(
                                "Es liegen folgende Dokumente zur Unterschrift bereit:"
                              )
                            ])
                          ])
                        : _c("p", [
                            _vm._v(
                              "Es liegen keine Dokumente zur Unterschrift bereit."
                            )
                          ]),
                      _vm._l(_vm.signoFiles, function(item) {
                        return _c("div", { key: item.url }, [
                          _c("div", { staticClass: "grid" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _vm.signoModalLoading
                                    ? _c("AnimatedSpinner")
                                    : _vm._e(),
                                  item.signaturStatus &&
                                  item.signaturStatus === "KOMPLETT"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.fileName) +
                                            " (bereits eingereicht) "
                                        )
                                      ])
                                    : item.isPictureAttach
                                    ? _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.signoModalLoading
                                                ? null
                                                : _vm.openZusatzDokModal(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.fileName) + " "
                                          )
                                        ]
                                      )
                                    : _c("DownloadLink", {
                                        attrs: {
                                          href: item.url,
                                          title: item.fileName
                                        }
                                      })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("BaseButton", {
                        attrs: {
                          label: "Zurück",
                          disabled: _vm.signoModalLoading,
                          animated: _vm.signoModalLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.$refs.signoFilesModal.close()
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              485879750
            )
          }),
          _c("BaseModal", {
            ref: "noSignoFilesModal",
            attrs: { labelButtonCancel: "Zurück", showConfirmButton: false },
            scopedSlots: _vm._u(
              [
                {
                  key: "modalTitle",
                  fn: function() {
                    return [
                      _c("b", [
                        _vm._v("Das Dokument ist nicht zur e-Signatur geeignet")
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v("Sie können jedoch das Dokument herunterladen")
                      ]),
                      _c("div", { staticClass: "grid" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _vm.currentActionData &&
                              _vm.currentActionData.document
                                ? _c("DownloadLink", {
                                    attrs: {
                                      title:
                                        _vm.currentActionData.document
                                          .bezeichnung ||
                                        _vm.currentActionData.document
                                          .fileName ||
                                        "unbenannt",
                                      filename:
                                        _vm.currentActionData.document
                                          .fileName ||
                                        _vm.currentActionData.document
                                          .bezeichnung,
                                      downloadServicePath: "/docInfo",
                                      queryParams: {
                                        nodeId:
                                          _vm.currentActionData.document.nodeId,
                                        idFile: encodeURIComponent(
                                          _vm.currentActionData.document.id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2881857561
            )
          }),
          _c("EditDocumentModal", {
            ref: "editDokumentModal",
            attrs: { additionalInputs: _vm.currentEditDocumentInputs },
            on: { confirm: _vm.saveDokument }
          }),
          _c("PictureAttachmentsModal", {
            ref: "pictureAttachmentsModal",
            attrs: { selectedDoc: _vm.selectedDoc },
            on: {
              updateLoadingStatus: function($event) {
                return _vm.updateLoadingStatus($event)
              }
            }
          }),
          _c(
            "BaseModal",
            {
              ref: "showCallRecordsModal",
              attrs: {
                modalTitle: "Aufgezeichnetes Beratungsgespräch",
                showConfirmButton: false,
                labelButtonCancel: "Zurück"
              }
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    (_vm.additionalDocuments &&
                      _vm.additionalDocuments.tableTitle) ||
                      ""
                  )
                )
              ]),
              _c("p", [_c("b", [_vm._v("Video")])]),
              _vm._l(_vm.callRecordList, function(cr, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("DownloadLink", {
                      attrs: {
                        href: _vm.makeCallRecordLink(cr),
                        title: cr.bezeichnung
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "BaseModal",
            {
              ref: "multipleFilesModal",
              attrs: {
                modalTitle: _vm.currentFilename,
                showCancelButton: false,
                showConfirmButton: false
              }
            },
            [
              _c("FileAttachmentsModal", {
                attrs: {
                  signedAttachments: _vm.signedAttachments,
                  unsignedAttachments: _vm.unsignedAttachments
                },
                on: {
                  close: function($event) {
                    return _vm.$refs.multipleFilesModal.close()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "downloadErrorModal",
              attrs: {
                modalTitle: "Fehler",
                labelButtonConfirm: "Ok",
                showCancelButton: false
              }
            },
            [_c("label", [_vm._v(_vm._s(_vm.downloadErrorMessage))])]
          ),
          _c(
            "BaseModal",
            {
              ref: "confirmationModal",
              attrs: {
                modalTitle: "Bestätigung",
                labelButtonConfirm: "Ok",
                showCancelButton: true
              },
              on: {
                onConfirmButton: function($event) {
                  return _vm.onConfirmHeaderActionModal()
                }
              }
            },
            [_c("label", [_vm._v(_vm._s(_vm.confirmationMessage))])]
          ),
          _c(
            "BaseModal",
            {
              ref: "sendEmailModal",
              attrs: {
                modalTitle: "Dokumente versenden",
                showDefaultButtons: false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.closeSendEmailModal()
                              }
                            }
                          },
                          [_vm._v("Abbrechen")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.confirmSendEmail()
                              }
                            }
                          },
                          [_vm._v("An Kunden")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.confirmSendEmail(true)
                              }
                            }
                          },
                          [_vm._v("An Gesellschaft")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1266622414
              )
            },
            [
              _vm._v(
                " An wen möchten Sie die ausgewählten Dokumente versenden? "
              )
            ]
          ),
          _c("EmailVersand", {
            attrs: {
              unregisterEventsBeforeDestroy:
                _vm.unregisterEmailEventsBeforeDestroy
            }
          }),
          _vm.files && _vm.currentAdditionalInputs
            ? _c("DocumentsUploadModal", {
                attrs: {
                  files: _vm.files,
                  additionalInputs: _vm.currentAdditionalInputs,
                  parentId: _vm.nodeId,
                  versicherungId: _vm.versicherungId,
                  schadenId: _vm.schadenId,
                  uploadType: _vm.filesFromDokumentenarchiv.length ? 5 : null,
                  showOnlySchadenDocs: _vm.showOnlySchadenDocs,
                  maxFileSizeMB: _vm.maxFileSizeMB
                },
                on: {
                  close: function($event) {
                    _vm.files = null
                  }
                }
              })
            : _vm._e(),
          _c("PreviewObject", {
            ref: "resizablePopup",
            attrs: {
              fullClientHeight: true,
              saveLastPosition: true,
              modalTitle: _vm.modalTitle,
              objectUrl: _vm.objectUrl,
              signatureUrlMetadata: _vm.signatureUrlMetadata,
              id: "d4fbef9a-7577-47f1-b0a4-38b111f7f426"
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }