<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Konfiguration" 
      :actions="headerActions" 
      @action-DISCARD="discard"
    />

    <StepperForm
      stepType="tab"
      stepperName="configuration"
      :stepperMediator="tabsStepperMediator"
      :selectedStepKey="currentStep"
      :selectedSubstepKey="currentSubstep"
      :hasFertig="false"
      :showSaveSpinner="isLoading"
      @set-step="setStep($event)"
      @set-substep="setSubstep($event)"
    >
      <template #contentTemplate>
        <router-view />
      </template>
    </StepperForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import BROKER_PERMISSIONS_TYPES from '@/store/brokerPermissions/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';

import { isPathAllowed, } from '@/router/guards';
import { StepperMediator } from '@/components/stepper/utils';
import { baseURLFromPath, } from '@/helpers/commonfunctions';
import { STEP_KEY_SEPARATOR, MAP_CONFIG_CONTEXT_LABEL, findMenuConfigStep, } from '@/menu/menu-config-utils';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import EVENT_BUS, { RECALCULATE_MENU_USER_LEVEL, } from '@/event-bus';

const BASE_CONFIGURATION_PATH = '/configuration';

const STEP_MENU_CONFIG_KEY = 'menu-config';
const STEP_WEITERE_ZUGRIFFSRECHTE_KEY = 'weitere-zugriffsrechte';
const STEP_GENERELLER_ZUGRIFF_KEY = 'genereller-zugriff';

const EXTRACT_STEP_FROM_PATH_REGEX = new RegExp(`(${STEP_MENU_CONFIG_KEY}|${STEP_WEITERE_ZUGRIFFSRECHTE_KEY}|${STEP_GENERELLER_ZUGRIFF_KEY})`, 'gi');

export default {
  data() {
    return {
      configuring: false,
    };
  },
  computed: {
    ...mapGetters({
      hasPermissionsEdited: BROKER_PERMISSIONS_TYPES.GETTERS.HAS_PERMISSIONS_EDITED,
      hasAdditionalPermissionsEdited: BROKER_PERMISSIONS_TYPES.GETTERS.HAS_ADDITIONAL_PERMISSIONS_EDITED,
      optionMenuConfigContexts: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_CONTEXTS,
      parentsMenuConfigFn: MENU_CONFIG_TYPES.GETTERS.PARENTS_MENU_CONFIG,
    }),
    hasDataEdited() {
      return this.hasPermissionsEdited || this.hasAdditionalPermissionsEdited;
    },
    headerActions() {
      if([STEP_WEITERE_ZUGRIFFSRECHTE_KEY, STEP_GENERELLER_ZUGRIFF_KEY].indexOf(this.currentStep) >= 0) {
        return [
          PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !this.hasDataEdited),
        ];
      }
      return [];
    },
    isLoading() {
      return this.configuring;
    },
    menuConfigSteps() {
      return this.optionMenuConfigContexts
        .map((configContext, index) => findMenuConfigStep(configContext, STEP_MENU_CONFIG_KEY, MAP_CONFIG_CONTEXT_LABEL?.[configContext], index));
    },
    steps() {
      if(this.configuring) {
        return [];
      }

      const steps = [
        ...this.menuConfigSteps || [],
        {
          stepKey: 'genereller-zugriff',
          label: 'Genereller Zugriff',
          totalProgress: 1,
        },
        {
          stepKey: 'weitere-zugriffsrechte',
          label: 'Weitere Zugriffsrechte',
          totalProgress: 1,
        },
      ];

      const basePath = baseURLFromPath(this.$route.path, BASE_CONFIGURATION_PATH);
      return steps.filter(step => {
        const { stepKey, } = step;
        const stepParts = stepKey.split(STEP_KEY_SEPARATOR);
        const stepPath = stepParts?.[0] || stepKey;
        const path = `${basePath}/${stepPath}`;
        return isPathAllowed(path); // only shows the step if path is allowed
      });
    },
    currentStep() {
      const { path, } = this.$route;
      return this.$route.query.step || path.match(EXTRACT_STEP_FROM_PATH_REGEX)?.[0] || '';
    },
    currentSubstep() {
      return this.$route.query?.substep || null;
    },
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
  },
  watch: {
    $route() {
      this.setFirstStepIfNeeded();
    },
  },
  methods: {
    async init() {
      EVENT_BUS.$on(RECALCULATE_MENU_USER_LEVEL, (data) => {
        this.configureMenuConfig(data.userLevel);
      });

      await this.configureMenuConfig();
      this.setFirstStepIfNeeded();
    },
    discard() {
      this.$store.commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_PERMISSIONS_EDITED);
      this.$store.commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_ADDITIONAL_PERMISSIONS_EDITED);
    },
    async configureMenuConfig(userLevel) {
      try {
        this.configuring = true;

        const { optionMenuConfigContexts, } = this;

        const promises = optionMenuConfigContexts
          .map(async configContext => await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_MENU_CONFIG, {
            configContext,
            userLevel,
            isPermissionConfigurable: true,
          }));

        await Promise.all(promises);
      } finally {
        this.configuring = false;
      }
    },
    findFirstSubstep(stepKey) {
      const currentStep = this.tabsStepperMediator.getCurrentStep(stepKey);
      if(currentStep?.substeps?.length > 0) {
        return this.tabsStepperMediator.findNextSubstepKey(currentStep, -1);
      }
      return undefined;
    },
    async setStep(stepKey) {
      const substepKey = this.findFirstSubstep(stepKey);
      this.setSubstep({
        stepKey,
        substepKey,
      });
    },
    async setSubstep(params) {
      const { stepKey, substepKey, } = params;
      const { currentStep, currentSubstep, } = this;
      if(stepKey === currentStep && substepKey === currentSubstep) {
        return;
      }

      const stepParts = stepKey.split(STEP_KEY_SEPARATOR);
      const stepPath = stepParts?.[0] || stepKey;

      const basePath = baseURLFromPath(this.$route.path, BASE_CONFIGURATION_PATH);
      const path = `${basePath}/${stepPath}`;

      const query = { step: stepKey, substep: substepKey, };
      this.$router.push({ path, query, });
    },
    setFirstStepIfNeeded() {
      const currentStep = this.tabsStepperMediator.getCurrentStep(this.currentStep);
      if(!currentStep) {
        const firstStep = this.tabsStepperMediator.getFirstStep();
        this.setStep(firstStep?.stepKey);
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    EVENT_BUS.$off(RECALCULATE_MENU_USER_LEVEL);
    // reset configContext
    this.optionMenuConfigContexts
      .forEach(configContext => this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.RESET_ALL, { configContext }));

    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    StepperForm,
  },
}
</script>
