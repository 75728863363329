import COMMUNICATION_TYPES from "./types";
import { getInitialState } from "./index";
import Vue from "vue";
import dayjs from "dayjs";

export default {
  [COMMUNICATION_TYPES.MUTATIONS.TEMPLATE](state) {
    state.template = {};
    state.ticketsNachrichten = [];
  },
  [COMMUNICATION_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
  [COMMUNICATION_TYPES.MUTATIONS.UNREAD_COMMUNICATIONS](state, unreadMessages) {
    state.unreadMessages = unreadMessages;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_COMMUNICATION_INSURANCE_LISTS](
    state,
    records
  ) {
    state.communicationInsuranceRecords = records;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_EMAIL](state, { id, data }) {
    Vue.set(state.emails, id, data);
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_SMS](state, { id, data }) {
    Vue.set(state.smss, id, data);
  },

  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TALK](state, data) {
    data.date = new Date(data.startTime);
    Vue.set(state.talks, data.id, data);
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TALK_CATEGORIES](state, talkCategories) {
    state.talkCategories = talkCategories;
  },
  [COMMUNICATION_TYPES.MUTATIONS.GET_DEFAULT_RECEIVER](state, defaultReceiver) {
    state.defaultReceiver = defaultReceiver;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN](
    state,
    ticketsNachrichten
  ) {
    state.ticketsNachrichten = ticketsNachrichten;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_LAZY](
    state,
    payload
  ) {
    state.ticketsNachrichtenLazy = payload;
  },
  [COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME](
    state,
    payload
  ) {
    state.ticketsNachrichtenLastLoadedTime = payload;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_COMBOBOXES](
    state,
    { bearbeiter, statuses }
  ) {
    console.log("LOAD_TICKETS_NACHRICHTEN_COMBOBOXES.bearbeiter:", bearbeiter);
    console.log("LOAD_TICKETS_NACHRICHTEN_COMBOBOXES.statuses:", statuses);
    state.ticketsNachrichtenBearbeiter = bearbeiter;
    state.ticketsNachrichtenStatuses = statuses;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_INFO_SUCCESS](state, payload) {
    state.ticketInfo = payload;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_BEWERTUNG_INFO_SUCCESS](state, payload) {
    state.bewertungInfo = payload;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_COMBOBOXES](
    state,
    { chatPartner, bearbeiter }
  ) {
    if (chatPartner) state.chatPartnerOptions = chatPartner;
    state.bearbeiterOptions = bearbeiter;
  },
  [COMMUNICATION_TYPES.MUTATIONS.DELETE_FROM_POSTFACH](state, { id }) {
    const index = (state.communicationInsuranceRecords || []).findIndex(
      (record) => record.commId == id
    );
    if (index >= 0) state.communicationInsuranceRecords.splice(index, 1);
  },
  [COMMUNICATION_TYPES.MUTATIONS.GET_CONFIGURED_CHATPARTNER_SUCCESS](
    state,
    payload
  ) {
    Vue.set(state, "configuredChatpartner", { ...payload });
  },
  [COMMUNICATION_TYPES.MUTATIONS.SAVE_UNSENT_NACHRICHT](state, unsentNachricht) {
    state.unsentNachricht = unsentNachricht;
  },
  [COMMUNICATION_TYPES.MUTATIONS.LOAD_ABSENCE_NOTE](state, payload) {
    state.absenceNote = {
      enabled: payload.enabled,
      from: payload.date_start ? dayjs(new Date(payload.date_start)).format("DD.MM.YYYY") : null,
      from_time: payload.date_start ? dayjs(new Date(payload.date_start)).format("HH:mm") : null,
      to: payload.date_end ? dayjs(new Date(payload.date_end)).format("DD.MM.YYYY") : null,
      to_time: payload.date_end ? dayjs(new Date(payload.date_end)).format("HH:mm") : null,
      message: payload.message,
    };
  },

  [COMMUNICATION_TYPES.MUTATIONS.SET_CAN_EDIT_THEMA](state, canEditThema) {
    state.canEditThema = canEditThema;
  },

  [COMMUNICATION_TYPES.MUTATIONS.SET_CHAT_THEMA_SUCCESS](state, thema) {
    if (state.ticketInfo && state.ticketInfo.ticket) {
      state.ticketInfo.ticket.thema = thema;
    }
  },

};
