var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Courtageabrechnungen",
          actions: _vm.headerActions
        },
        on: {
          "action-PDF": function($event) {
            return _vm.goto("abrechnungsliste")
          },
          "action-XLSX": function($event) {
            return _vm.goto("buchungspositionen")
          }
        }
      }),
      _vm.init
        ? _c("div", { staticClass: "page" }, [
            _vm.form.hatVersicherung
              ? _c("div", { staticClass: "box__container" }, [
                  _c("h4", { staticClass: "box__title" }, [
                    _vm._v("Versicherungsbereich")
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("ComboBox", {
                        staticClass: "col-12 col-sm-4",
                        attrs: {
                          label: _vm.form.fpcombo1[1],
                          values:
                            _vm.form.combos[_vm.form.fpcombo1[0].toLowerCase()]
                        },
                        on: { change: _vm.onChangefp1 }
                      }),
                      _c("ComboBox", {
                        staticClass: "col-12 col-sm-4",
                        attrs: {
                          label: _vm.form.fpcombo2[1],
                          values: _vm.fpcombo2,
                          sortComboboxValues: false
                        },
                        model: {
                          value: _vm.fpdatum,
                          callback: function($$v) {
                            _vm.fpdatum = $$v
                          },
                          expression: "fpdatum"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("BaseButton", {
                            attrs: {
                              label: _vm.form.fpbutton[1],
                              disabled: !_vm.fpdatum
                            },
                            on: {
                              click: function($event) {
                                return _vm.onClickBtn(
                                  _vm.form.fpbutton[0],
                                  false
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", {}, [
              _c("div", { staticClass: "box__container" }, [
                _vm.form.hatVersicherung
                  ? _c("h4", { staticClass: "box__title" }, [
                      _vm._v("Fondsbereich")
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("ComboBox", {
                      staticClass: "col-12 col-sm-6 col-lg-4 item-column",
                      attrs: {
                        label: _vm.form.comboDatum[1],
                        values:
                          _vm.form.combos[_vm.form.comboDatum[0].toLowerCase()],
                        sortComboboxValues: false
                      },
                      model: {
                        value: _vm.datum,
                        callback: function($$v) {
                          _vm.datum = $$v
                        },
                        expression: "datum"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.rows.length
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.rows, function(row, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          staticClass:
                            "col-12 col-sm-6 col-md-4 item-content text-center"
                        },
                        [
                          _c("div", { staticClass: "box__container" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-cell",
                                on: {
                                  click: function($event) {
                                    return _vm.onClickBtn(row.value, true)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "m-1" },
                                  [
                                    row.icon === "PhFlowArrow"
                                      ? _c("PhFlowArrow", {
                                          staticClass: "icon"
                                        })
                                      : _vm._e(),
                                    row.icon === "PhFilePdf"
                                      ? _c("PhFilePdf", { staticClass: "icon" })
                                      : _vm._e(),
                                    row.icon === "PhFileXls"
                                      ? _c("PhFileXls", { staticClass: "icon" })
                                      : _vm._e(),
                                    row.icon === "PhFileCsv"
                                      ? _c("PhFileCsv", { staticClass: "icon" })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("div", {}, [_vm._v(_vm._s(row.abrechnung))])
                              ]
                            ),
                            _vm.meldung[row.value]
                              ? _c("div", { staticClass: "display-errors" }, [
                                  _vm._v(_vm._s(_vm.meldung[row.value]))
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }