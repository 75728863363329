var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Gesellschaften Ansprechpartner",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "ansprechpartner",
          stepperMediator: _vm.tabsStepperMediator,
          selectedStepKey: _vm.currentStep
        },
        on: {
          "set-step": function($event) {
            return _vm.navigateTo($event)
          },
          "on-fertig": function($event) {
            return _vm.finishEdition()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _c("router-view", {
                  attrs: { loading: _vm.loading },
                  on: {
                    change: function($event) {
                      return _vm.onChange($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }