var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _vm.loading
        ? _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [_c("Block", { attrs: { height: "200" } })],
            1
          )
        : _vm.maklerDok.length
        ? [
            [
              _vm.paraUnvoll
                ? _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v(_vm._s(_vm.paraUnvoll.label))
                      ]),
                      _c("InputRadioBoxGroup", {
                        class: { "radio-danger": !_vm.paraOption },
                        attrs: {
                          validateUntouched: true,
                          values: _vm.paraUnvoll.values,
                          config: { validateUntouched: true }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "after-F",
                              fn: function() {
                                return [
                                  _vm.paraOption == "F"
                                    ? _c("InputField", {
                                        attrs: { validateUntouched: true },
                                        model: {
                                          value: _vm.reg_nr_F,
                                          callback: function($$v) {
                                            _vm.reg_nr_F = $$v
                                          },
                                          expression: "reg_nr_F"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "after-H",
                              fn: function() {
                                return [
                                  _vm.paraOption == "H"
                                    ? _c("InputField", {
                                        attrs: { validateUntouched: true },
                                        model: {
                                          value: _vm.reg_nr_H,
                                          callback: function($$v) {
                                            _vm.reg_nr_H = $$v
                                          },
                                          expression: "reg_nr_H"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "after-KEINE",
                              fn: function() {
                                return [
                                  _vm.paraOption == "KEINE"
                                    ? _c("div", [
                                        _vm._v(
                                          "Sie werden in diesem Fall als Passivmakler hinterlegt und haben keinen Zugriff mehr auf Formulare im Investmentbereich."
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          739348319
                        ),
                        model: {
                          value: _vm.paraOption,
                          callback: function($$v) {
                            _vm.paraOption = $$v
                          },
                          expression: "paraOption"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "document-container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Dokumente des Vertrags")
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.maklerDok, function(mdok) {
                    return _c(
                      "div",
                      {
                        key: mdok.key,
                        staticClass:
                          "makler-document col-12 col-sm-6 col-lg-4 col-xl-3"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c(
                              "div",
                              { staticClass: "makler-document--header" },
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v(_vm._s(mdok.name))
                                ]),
                                _c(
                                  "BaseContextMenu",
                                  {
                                    staticClass: "makler-document--context-menu"
                                  },
                                  [
                                    _c(
                                      "ContextMenuItem",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.uploadDok(mdok)
                                          }
                                        }
                                      },
                                      [_vm._v("Neues Dokument hochladen")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            mdok.file_id
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn-clean clickable",
                                    attrs: {
                                      href: _vm.showDok(mdok),
                                      target: "_blank",
                                      rel: "noopener noreferer"
                                    }
                                  },
                                  [_vm._v(_vm._s(mdok.value))]
                                )
                              : _c("div", [
                                  _vm._v("Es ist kein Dokument hinterlegt.")
                                ]),
                            mdok.error
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: { isLink: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showError(mdok)
                                      }
                                    }
                                  },
                                  [
                                    _c("PhInfo", {
                                      staticClass: "text-danger"
                                    }),
                                    _vm._v(_vm._s(mdok.status))
                                  ],
                                  1
                                )
                              : mdok.status
                              ? _c(
                                  "div",
                                  [
                                    _c("PhInfo", {
                                      staticClass: "text-success"
                                    }),
                                    _vm._v(_vm._s(mdok.status))
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            _vm.personen.length
              ? _vm._l(_vm.personen, function(pers) {
                  return _c("div", { key: pers.key }, [
                    _c("hr"),
                    !pers.items.length
                      ? _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v(_vm._s(pers.name))
                          ]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/intern/vermittlerdaten/vermittlerdaten"
                              }
                            },
                            [_vm._v("Vermittlerdaten")]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "document-container" }, [
                      pers.items.length
                        ? _c("div", { staticClass: "box__title" }, [
                            _vm._v(_vm._s(pers.name))
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(pers.items, function(mdok) {
                          return _c(
                            "div",
                            {
                              key: mdok.key,
                              staticClass:
                                "makler-document col-12 col-sm-6 col-lg-4 col-xl-3"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "box__container" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "makler-document--header" },
                                    [
                                      _c("div", { staticClass: "box__title" }, [
                                        _vm._v(_vm._s(mdok.name))
                                      ]),
                                      _c(
                                        "BaseContextMenu",
                                        {
                                          staticClass:
                                            "makler-document--context-menu"
                                        },
                                        [
                                          _c(
                                            "ContextMenuItem",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.uploadDok(mdok)
                                                }
                                              }
                                            },
                                            [_vm._v("Neues Dokument hochladen")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  mdok.file_id
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn-clean clickable",
                                          attrs: {
                                            href: _vm.showDok(mdok),
                                            target: "_blank",
                                            rel: "noopener noreferer"
                                          }
                                        },
                                        [_vm._v(_vm._s(mdok.value))]
                                      )
                                    : _c("div", [
                                        _vm._v(
                                          "Es ist kein Dokument hinterlegt."
                                        )
                                      ]),
                                  mdok.error
                                    ? _c(
                                        "BaseButton",
                                        {
                                          attrs: { isLink: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showError(mdok)
                                            }
                                          }
                                        },
                                        [
                                          _c("PhInfo", {
                                            staticClass: "text-danger"
                                          }),
                                          _vm._v(_vm._s(mdok.status))
                                        ],
                                        1
                                      )
                                    : mdok.status
                                    ? _c(
                                        "div",
                                        [
                                          _c("PhInfo", {
                                            staticClass: "text-success"
                                          }),
                                          _vm._v(_vm._s(mdok.status))
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                })
              : _vm._e()
          ]
        : _c("div", { staticClass: "box__container" }, [_c("NoData")], 1),
      _c("BaseModal", {
        ref: "errBemerk",
        attrs: {
          modalTitle: _vm.errorTitle,
          showConfirmButton: false,
          labelButtonCancel: "Ok"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_c("div", [_vm._v(_vm._s(_vm.errorText))])]
            },
            proxy: true
          }
        ])
      }),
      _c("DocumentsUploadModal", {
        ref: "uploadForm",
        attrs: {
          files: _vm.uploadFiles,
          additionalInputs: _vm.uploadInputs,
          uploadType: 3,
          multipleFileUpload: false,
          openOnMount: false,
          customUpload: _vm.paraDocUpload
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }