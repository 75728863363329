<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Transaktionen" />

    <BaseFilter
      title="Transaktionenfilter"
      filterId="8d6f9099-9423-4c02-99db-82877e030bd5"
      hasSmartSearch
      showSaveButton
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :predefinedFilter="null"
      :defaultOptions="defaultOptions"
      immidiateSearch
      @onFilter="submit($event)" 
      @changeSubFilter="changeSubFilter"
    />

    <div v-if="!loading && summen.length" >
      <div v-if="!$isSmallScreen" class="depotpositions-categories__container">
        <div v-for="(sum, index) of summen" :key="'sum'+index">
          <div v-if="sum && sum[1] !== ''" class="box__container"  :tid="_generateTidFromString('box__container'+'sum'+index)">
            <div class="text-small text-centered mp-0">{{sum[0]}}</div>
            <div class="text-centered mp-0">
              <span><CurrencyLabel :value="sum[1]"/></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="box__container">
        <template v-for="(sum, index) of summen">
          <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
            <template #value >
              <span>
                <span><CurrencyLabel :value="sum[1]"/></span>
              </span>
            </template>
          </MobileInfoRow>
        </template>
      </div>
    </div>

    <HandleTablePin keyOfPIN="TABELLE_TRANSAKTIONEN" />

    <div class="box__container" tid="5b5f0386-f56f-49bb-b156-e1d2316ded87">
      <Table v-if="!loading && rows.length"
          ref="tblTransakt"
          tableId="1448ac4f-556d-4def-a8fc-ccb5f6dc2926"
          :title="TABLE_TITLE"
          rowId="index"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          :exportConfig="exportConfig"
          :mobileConfig="{title: 'Wertpapiername', headers: ['Umsatzart', 'Datum', 'ISIN', 'Kundennr']}"
          @click-Wertpapiername="navigateToDepotposition"
      />
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>
    
    <BaseModal ref="maxWidthPDFCol"
      modalTitle="Maximale Breite einer PDF-Spalte" :showCancelButton="true"
      labelButtonConfirm="PDF-Transaktionen erzeugen"
      @onConfirmButton="sendPdfData"
      @onCancelButton="cancelSendPdfData"
    >
        <ComboBox class="mt-1" 
          label=""
          v-model="maxWidthPDF" 
          :values="maxWidthPDFOpt"
          :sortComboboxValues="false" 
        />
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import BALANCE_SHEET_TYPES from "@/store/balance-sheet/types";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, NumberColumn, PercentageColumn, ActionColumn} from "@/components/table2/table_util.js";
import { mapNewTableStructure } from '@/store/depotpositions/utils';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { PhPencilLine } from 'phosphor-vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { searchMenu, prepareFilters } from './transactionsSearchConstants.js';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import dayjs from 'dayjs';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import HandleTablePin from '@/components/core/HandleTablePin.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import { buildMessage } from '@/helpers/log-message-helper.js';
import MobileInfoRow from "@/components/core/MobileInfoRow.vue";

const TransactionCategory = {
  OFFENEFONDS: "OFFENEFONDS",
  BETEILIGUNGEN: "BETEILIGUNGEN",
  BANKKONTO: "BANKKONTO",
  STAMMDATEN: "STAMMDATEN"
}

const TABLE_TITLE = 'Transaktionen';

export default {
  mixins: [InteractiveHelpCommonsMixin],
	props: {},
	data() {
		return {
      TABLE_TITLE,
      category: '',
      params: '',
      form: {},
      loading: false,
      selectedFund: null,
      configFilter: {
        placeholderForDefSearchInput: 'ISIN, Lagerstelle, Kontonr',
        defaultSearchInputKeys: ['isin', 'depository', 'account'],
        filterType: 'transactions',
        checkDefaultSearchInput: true,
        hideFirstColumn: true,
      },
      maxWidthPDF: 12,
      maxWidthPDFOpt: [{value: 0, label: 'keine Einschränkung'},
      {value: 8, label: '8 Zentimeter'},
      {value: 9, label: '9 Zentimeter'},
      {value: 10, label: '10 Zentimeter'},
      {value: 11, label: '11 Zentimeter'},
      {value: 12, label: '12 Zentimeter'},
      {value: 13, label: '13 Zentimeter'},
      {value: 14, label: '14 Zentimeter'},
      {value: 15, label: '15 Zentimeter'},
      {value: 16, label: '16 Zentimeter'},
      {value: 17, label: '17 Zentimeter'},
      {value: 18, label: '18 Zentimeter'},
      ],
      saveParams: null,
      pdfResolve: null,
      message: '',
    }
  },
  computed: {
    ...mapGetters({
        transactions: BALANCE_SHEET_TYPES.GETTERS.TRANSACTIONS,
        isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    headers() {
        let lockedLeft = [
                TextColumn("Umsatzart", "Umsatzart", 500).addCellProps({lineClamp: 4}),
                DateColumn("Datum", "Datum"),
                TextColumn("ISIN", "ISIN"),
            ];

        if (this.isBroker || this.isIntern) {
          lockedLeft.push(TextColumn("Kundennr", "Kundennr").makeAlwaysVisible().addCellProps({lineClamp: 4}),)
        }

        lockedLeft.push(TextColumn("Wertpapiername", "Wertpapiername").makeAlwaysVisible().makeLink().addCellProps({lineClamp: 4}));

        const result = {
            lockedLeft,
            center: [
                TextColumn("Kontonr", "Kontonr"),
                NumberColumn("Anteile", "Anteile", 6),
                NumberColumn("Preis", "Preis (WP-W.)", 2),
                NumberColumn("aktueller_Kurs", "Aktueller Kurs", 2),
                TextColumn("Währung", "Währung"),
                NumberColumn("Nettoanlage", "Nettoanlage (WP-Währung)", 2),
                NumberColumn("Bruttoanlage", "Bruttoanlage (WP-Währung)", 2),
                NumberColumn("Wechselkurs", "Wechselkurs", 6),
                CurrencyColumn("Nettoanlage_EUR", "Nettoanlage (EUR)"),
                CurrencyColumn("Bruttoanlage_EUR", "Bruttoanlage (EUR)"),
                PercentageColumn("AA_IN_PROC", "AA in %", 4),
                NumberColumn("AA_IN_WP", "AA in WP-W.", 4),
                PercentageColumn("Rabatt", "Rabatt", 4),
                TextColumn("Gesellschaft", "Gesellschaft"),
                TextColumn("Lagerstelle", "Lagerstelle"),
                TextColumn("Kundenname", "Kundenname"),
                DateColumn("Eröffnungsdatum", "Eröffnungsdatum"),
                DateColumn("Erstellung_Depotposition", "Erstellung Depotposition"),
            ],
            lockedRight: [],
        };
        if (this.isBroker) {
            result.lockedLeft.unshift(TextColumn("Vermittlernr", "Vermittlernr"));
            result.lockedRight.push(ActionColumn("actions", ""));
        }
        if (this.isFA) {
            result.center.push(NumberColumn("Kest", "Kest", 4))
            result.center.push(NumberColumn("Kalk_Zinsen", "Kalk. Zinsen", 4))
            result.center.push(NumberColumn("Sonst_Spesen", "sonst. Spesen", 4))
        }
        return result;
    },
    rows() {
        if (!this.transactions?.tableHeaders)
            return [];
        const tableData = mapNewTableStructure(this.transactions, this.tableHeaders);

        // Just to avoid duplicated keys problem when rows are duplicated
        const records = tableData?.records?.map((row, index) => ({
          ...row,
          index,
        }))

        return records;
    },
    summen() {
      let result = []
      if (this.transactions?.rows?.length) {
        if (this.transactions.rows[0].error) {
          let mess = 'Anlagesumme, Gesamtbestand und G&V können nicht angezeigt werden.';
          Object.entries(this.transactions.rows[0]).forEach(([key,value]) => {
            if (key != 'error') {
              mess += '<br>int.Nt.: '+key+' '+value;
            }
          })
          if (this.message != mess) {
            this.message = mess;
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(mess, 'danger'));
          }
        } else {
          const sum = this.transactions.rows[0];
          sum.gesGV = sum.aktuelleGV + sum.realisierteGV;
          if (!sum.saldo) {
            result = [['Gesamtbestand', sum.bestand]];
          } else if (!sum.summeGuV) {
            result =  [['Anlagesumme', sum.anlagesumme], ['Gewinn(+) / Verlust(-)', sum.gesGV], ['Gesamtbestand', sum.bestand]];
          } else {
            result =  [['Anlagesumme', sum.anlagesumme], ['aktuelle G&V', sum.aktuelleGV], ['realisierte G&V', sum.realisierteGV], ['Gewinn(+) / Verlust(-)', sum.gesGV], ['Gesamtbestand', sum.bestand]];
          }
        }
      }
      return result;
    },
    searchMetadata() {
      return searchMenu(this.isIntern, this.isBroker, this.isFA);
    },
    defaultOptions() {
      const filters = {};
      if (this.isIntern || this.isBroker) {
        filters.letztem_monat = { value: true };
      }
      const params = this.$route.query.params ? JSON.parse(this.$route.query.params) : {};
      const arr = [];
      if (params.dateBegin) {
        arr.push({key: 'min', value: params.dateBegin})
      }
      if (params.dateEnd) {
        arr.push({key: 'min', value: params.dateEnd})
      }
      if (arr.length) {
        filters.zeitraum = { value: arr };
      }
      return filters;
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        csv: true,
        name: TABLE_TITLE, 
        title: TABLE_TITLE,
        dispatch: this.exportAsFile,
      };
    },
  },
  methods: {
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    submit(filters) {
      const query = prepareFilters(filters);
      const requestParams = {
        ...query,
        ...(this.params ? this.params : {}),
        fondsType: this.category,
        bgsNr: this.params.bgsNr,
      };

      this.loading = true;
      this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_TRANSACTIONS, requestParams)
      .finally(() => this.loading = false)
      
    },
    changeSubFilter(row, filterRows) {
      if (row.secondaryKey === 'zeitraum') {
        if (!row.zeitraum_1 && !row.zeitraum_2) {
          const dt = new Date();
          row.zeitraum_2 = dayjs(dt).format('DD.MM.YYYY');
          row.zeitraum_1 = dayjs(new Date(dt.getFullYear() - 1, dt.getMonth(), dt.getDate())).format('DD.MM.YYYY');
        }
      }
      const test = {zeitraum: 1, dieses_jahr: 1, letztem_monat: 1};
      if ( test[row.secondaryKey] && filterRows.length > 1) { // duplicate?
        let key;
        for (let index = filterRows.length - 1; index >= 0 ; index-- ) {
          key = filterRows[index].secondaryKey;
          if (test[key] && filterRows[index]!==row) {
            filterRows.splice(index, 1);
          }
        }
      }
    },
    exportAsFile(fileType) {
      if (!this.rows?.length) {
        return;
      }
      let columns, columnsKeys, exportedRows;
      if (this.$refs?.tblTransakt?.$refs?.sortableTable?.visibleHeadersFlat) {
        columns = this.$refs.tblTransakt.$refs.sortableTable.visibleHeadersFlat.map(header => header.label);
        columnsKeys = this.$refs.tblTransakt.$refs.sortableTable.visibleHeadersFlat.map(header => header.key);
      } else {
        columns = [];
        columns.push(...this.headers.lockedLeft.map(header => header.label));
        columns.push(...this.headers.center.map(header => header.label));
        columnsKeys = [];
        columnsKeys.push(...this.headers.lockedLeft.map(header => header.key));
        columnsKeys.push(...this.headers.center.map(header => header.key));
      }
      if (fileType === 'xls' || fileType === 'csv') {
        columns.push('Transaktionsnr', 'Kundenreferenznr', 'Kundennr');
        columnsKeys.push('transaktions_nr', 'kundenreferenznr', 'kundennr');
      }
      if (this.$refs?.tblTransakt?.$refs?.sortableTable?.exportedRows) {
        exportedRows = this.$refs.tblTransakt.$refs.sortableTable.exportedRows;
      } else {
        exportedRows = this.rows;
      }
      let header;
      exportedRows = exportedRows?.map(row => columnsKeys.map(key => {
        let value = row[key];
        header = this.headers.center.find(c => c.key === key);
        if(!header) {
          header = this.headers.lockedLeft.find(c =>  c.key === key);
        }
        if (header?.exportFn) {
          return header.exportFn(value, row, header)
        }
        return value;
      }));
      const params = {
        create: fileType,
        filename: 'Transaktionen.' + fileType,
        title: TABLE_TITLE,
        columns: columns,
        rows: exportedRows,
        printMaklerLogo: '1',
      };
      if (fileType === 'pdf' && this.isFA) {
        this.saveParams = params;
        this.$refs.maxWidthPDFCol.open();
        return new Promise((resolve, reject) => {
          this.pdfResolve = resolve;
        });
      }
      
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    },
    cancelSendPdfData() {
      if (this.pdfResolve) {
        this.pdfResolve({data: {}});
      }
    },
    sendPdfData() {
      if (this.maxWidthPDF) {
        this.saveParams.maxWidthPDFColumn = this.maxWidthPDF;
      }
      this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, this.saveParams).then(
        response => {
          this.pdfResolve(response)
        }
      );
    },
    navigateToDepotposition(row) {
      this.selectedFund = row.Wertpapiername;
      
      if(this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
        { customerId: row.Kundennr, path: `/home/depotpositionenedit/begleitscheinsteps/${row.action}/POSITION` });
      } else {
        this.navigateTo(`/shared/vermogensubersicht/fondsinfo/${row.ISIN}`);
      }
    },
  },
	mounted() {
    const params = this.$route.params;

    const category = params['category'];
    if (category && category == 'bankkonten'){
      this.category = TransactionCategory.BANKKONTO;
    } else if (category && category == 'stammdaten'){
        this.category = TransactionCategory.STAMMDATEN;
    } else if (category && category == 'beteiligungen') {
      this.category = TransactionCategory.BETEILIGUNGEN;
    } else if (category && category == 'wertpapiere'){
      this.category = TransactionCategory.OFFENEFONDS;
    }

    if (!this.isBroker && !this.isIntern) {
      if (this.$route.query.params) {
        const params = this.$route.query.params;
        const {kontonummer, ...allParams} = JSON.parse(params);
        this.params = {
          ...allParams,
          account: kontonummer
        }
      }
    }
	},
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu den Transaktionen',
      to,
      from,
      breadcrumb: this.selectedFund || '',
    });

    next()
  },
	components: {
    Table,
    OptionMenu,
    BaseFilter,
    PhPencilLine,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    HandleTablePin,
    ComboBox,
    BaseModal,
    CurrencyLabel,
    MobileInfoRow,
	}
}
</script>

<style scoped>
.depotpositions-categories__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 16px;
}
.depotpositions-categories__container div {
  margin: 0;
}
.depotpositions-categories__container > div {
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 0 8px;
}
</style>