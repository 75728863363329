<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Freistellungsaufträge Übersicht" 
      :actions="headerActions" 
      @action-ADD="addItem()"
    />

    <div class="box__container" tid="85cd7ff5-9e47-4216-95f3-567a2ba95b26">
      <Table
        tableId="949def7b-09b0-4bef-8283-9725f4ae1de2"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :mobileConfig="mobileConfig"
        @click-gesellName="bearbeiten"
        @action="executeAction($event)"
      > 
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Freistellungsauftrag löschen" labelButtonConfirm="Löschen">
      Soll der Freistellungsauftrag der Gesellschaft  {{selectedItem.gesellName}} {{selectedItem.gueltigAb}} in Höhe von {{Number(selectedItem.betrag).toLocaleString()}} wirklich gelöscht werden.
    </BaseModal>


  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import FREISTELLUNGSANTRAG_TYPES from "@/store/freistellungsauftrag/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, NumberColumn, DateColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import {PhPencilLine, PhPencil, PhCheck, PhTrash, PhEnvelope} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import { VIEW_ROLES } from '@/router/roles';
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import {toCurrencyString} from '@/filters.js';
import dayjs from 'dayjs';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Freistellungsaufträge';
const DATE_TIME_FORMAT = 'DD.MM.YYYY';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    CurrencyLabel,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        filtered: false,
        loading: true,
        mobileConfig: {
          title: 'gesellschaft',
          headers: ['betrag', 'gueltigAb', 'gueltigBis'],
        },
      }
  },
  computed: {
    ...mapGetters({
      freistellungsantragData: FREISTELLUNGSANTRAG_TYPES.GETTERS.FREISTELLUNGSANTRAG_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedItem: FREISTELLUNGSANTRAG_TYPES.GETTERS.SELECTED_ITEM,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
      freistellungsantragSubtotals: STECKBRIEF_TYPES.GETTERS.FREISTELLUNGSANTRAG_SUBTOTALS,
    }),
    gesellschaften() {
      return this.freistellungsantragData?.gesellschaften || [];
    },
    canSeeSpecialColumns() {
      return this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS) || this.isBroker || this.isIntern;
    },
    tableHeaders() {
      if (this.canSeeSpecialColumns) {
        const result = {
          lockedLeft: [
            TextColumn("gesellName", "Gesellschaft").makeLink().makeAlwaysVisible(),
            TextColumn("kundennr", "Kundennr"),
            TextColumn("id", "Id").makeHidden(),
            DateColumn("gueltigAb", "Gültig von"),
            DateColumn("gueltigBis", "Gültig bis"),
            CurrencyColumn("betrag", "Betrag", 2).withFunctionFooter((acc, sum) => {
              return toCurrencyString(this.freistellungsantragSubtotals?.freistellungsantrag)
            }),
            DateColumn("aktuellesJahr", "Verfügbar ab"),
            NumberColumn("verfuegbar", "Verfügbar im akt. J.", 2),
            NumberColumn("zinsen", "Verbrauchter Freibetrag", 2).withFunctionFooter((acc, sum) => {
              return toCurrencyString(this.freistellungsantragSubtotals?.zinsen)
            }),
            TextColumn("hasBemerkung", "Bemerkung"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };

        if (this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN)) {
          result.lockedLeft.push(TextColumn("fileNo", "Dateinummer"));
        }

        return result;

      }

      return {
        lockedLeft: [
          TextColumn("gesellName", "Gesellschaft").makeLink().makeAlwaysVisible(),
          TextColumn("kundennr", "Kundennr").makeHidden(),
          TextColumn("id", "Id").makeHidden(),
          DateColumn("gueltigAb", "Gültig von"),
          DateColumn("gueltigBis", "Gültig bis"),
          NumberColumn("betrag", "Betrag",2).withFunctionFooter((acc, sum) => {
              return toCurrencyString(this.freistellungsantragSubtotals.freistellungsantrag)
            }),
          DateColumn("aktuellesJahr", "Verfügbar ab"),
          NumberColumn("verfuegbar", "Verfügbar im akt. J.", 2),
          NumberColumn("zinsen", "Verbrauchter Freibetrag", 2),
          TextColumn("hasBemerkung", "Bemerkung"),
        ],
        lockedRight: [],   
      };      
    },       
    tableRows () {
      if (!this.freistellungsantragData?.rows?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      const day = new Date();
      const nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);
      const sortedCurrentRecords = this.freistellungsantragData?.rows?.filter(data => {
        const from = data.gueltigAb ? dayjs(data.gueltigAb, DATE_TIME_FORMAT).toDate() : new Date();
        const to = data.gueltigBis ? dayjs(data.gueltigBis, DATE_TIME_FORMAT).toDate() : nextDay;
        return to >= new Date() && from <= new Date()
      })

      return this.freistellungsantragData?.rows?.map(data => ({
          ...data,
          gesellschaft: data?.freieEingabe || data?.gesellschaft,
          gesellName: data?.freieEingabe || this.getGesellschaftsname(data?.gesellschaft),
          isFreieEingabe: data?.freieEingabe ? true : false,
          actions: this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS) && !data.auftragsNummer ? fullActions : [],
          hasBemerkung: (this.isCustomerOnly && data.bemSichtbarKunde || !this.isCustomerOnly) && data.bemerkung ? 'Ja' : 'Nein',
          customRowStyle: {...(this.isCurrentRecord(data, sortedCurrentRecords) ? { fontWeight: 'bold' } : {}), },
      }));
    },
    defaultOptionsData() {
      return {
          kundennr: {
              value: "",
          },
          gesellschaften: {
              value: "",
          },                    
        }; 
    }, 
    filterMetadata() {
         return [
            {
              type: "group",
              key: "allgemein",
              label: "Allgemein",
              menuItems: [
                {
                  type: 'text',
                  key: 'kundennr',
                  label: 'Kundennr',
                },
              ],
            },     
            {
              type: 'group',
              key: 'gesellschaften',
              label: 'Gesellschaften',
              menuItems: this.gesellschaften || []
            },               
          ];  
    },
    headerActions() {
      if (this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN) || this.canSeeSpecialColumns) {
        return [
          PageHeaderSimpleAction('ADD', 'hinzufügen'),
        ];
      }

      return [];
    },
  },
  async mounted() {
    await this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.RETRIEVE_FREISTELLUNGSANTRAG_SUBTOTALS);
    this.retrieveData();  
  },
  methods: {
    executeAction(actionData) {
      switch (actionData.key) {
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
      }
    },
    getGesellschaftsname(gesellschaft) {
      let name = this.gesellschaften.find( g => g.key === gesellschaft);
      if (name) {
        return name.label;
      }
      return gesellschaft;
    },
    confirmItemRemoval(row) {
      this.$store.commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.SELECTED_ITEM, row);
      this.$refs.removeItemConfirmation.open();
    },
    async removeItem() {
        await this.$store.dispatch(FREISTELLUNGSANTRAG_TYPES.ACTIONS.REMOVE_ROW, { id: this.selectedItem?.id });
        await this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_STECKBRIEF_CUSTOMER_INFO);
        this.retrieveData();
    },
    retrieveData(kundennr) {
      this.loading = true;

      this.$store.dispatch(
              FREISTELLUNGSANTRAG_TYPES.ACTIONS.RETRIEVE_FREISTELLUNGSANTRAG_DATA, {kundennr}
            ).then(() => {
                this.loading = false;
            });  

    },
    bearbeiten(row) {
      // const editPath = `/home/auftragBearbeiten`;

      // if(this.isBroker || this.isIntern) {
      //   this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
      //     { customerId: row?.kundennr, insurances: false, path: `${editPath}/${row?.id}` });
      // } else {
        this.$store.commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.SELECTED_ITEM, row);
        this.$router.push({path: `/home/freistellungsauftrag/auftragBearbeiten` });
      // }
    },
    addItem() {
      this.$store.commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.SELECTED_ITEM, {
        gesellschaft: '',
      });
      this.$router.push({path: `/home/freistellungsauftrag/auftragBearbeiten` });
    },
    filterForm(filterConfig) {
      const kundennr = filterConfig?.find(fc => fc.key=="kundennr");
      this.retrieveData(kundennr?.value) ;
    },
    isCurrentRecord(data, current) {
      return current.map(v => v.id).includes(data.id)
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Freistellungsaufträge Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>