<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation 
      title="Wertpapiersuche"
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="customOptionMenu"
    />

    <WertpapierSearch 
      name="bgsSuche" 
      @confirm-selected="setAuswahl"
      :respond="1" 
      :type="type"
      singleSelection
      isSelectable
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';

export default {
  name: 'BegleitscheinWPSuche',
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    WertpapierSearch,
  },
  props: {
  },
  data() {
    return {
      funds: [],
      type: 'I',
      options: [
        {
          label: 'Investmentfonds',
          value: 'I',
        },
        {
          label: 'Zertifikate',
          value: 'Z',
        },
        {
          label: 'Beteiligungen',
          value: 'B',
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      bgs: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
    }),
    customOptionMenu() {
      return this.options.map(item => ({
        label: item.label,
        textBold: this.type == item.value,
        action: () => this.type = item.value,
      }));
    },
  },
  methods: {
    setAuswahl(funds) {

      funds.forEach(fund => {
        const values = {};
        values.bgsnr = this.bgs.bgsnr
        values.inputisin = fund.isin;
        values.inputwertpapier = fund.fondsname;
        values.inputwkn = fund.old_wkn;
        values.gesellschaft = fund.gesellschaft;
        values.input_schwerpunkt = fund.schwerpunkt;
        this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {fundSuche: values})
      })

      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped>

</style>