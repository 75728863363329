var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "E-Mail Zugang",
          actions: _vm.headerActions
        },
        on: {
          "action-NEW-SERVER": function($event) {
            return _vm.addNewServer()
          }
        }
      }),
      _vm.stepper
        ? _c("div", { staticClass: "row my-0" }, [
            _c("div", { staticClass: "d-lg-none col-12" }, [
              _c(
                "div",
                { staticClass: "antrag-step__container box__container" },
                [
                  _c("Stepper2", {
                    ref: "pieStepper",
                    attrs: {
                      stepType: "step",
                      visible: true,
                      selectedStepKey: _vm.stepKey,
                      stepperService: _vm.stepper
                    },
                    on: { setStep: _vm.setStepByKey }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("ContentWithStepper", {
        scopedSlots: _vm._u(
          [
            _vm.stepper
              ? {
                  key: "stepper",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("VerticalStepper", {
                            ref: "verticalStepper",
                            staticClass: "responsive-stepper",
                            attrs: {
                              stepType: "step",
                              visible: true,
                              selectedStepKey: _vm.stepKey,
                              stepperService: _vm.stepper
                            },
                            on: { setStep: _vm.setStepByKey }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "content",
              fn: function() {
                return [
                  _c("router-view"),
                  _c(
                    "div",
                    { staticClass: "row box__container" },
                    [
                      _vm.stepKey !== "eingang"
                        ? _c(
                            "BaseButton",
                            {
                              attrs: { isPrimary: "" },
                              on: {
                                click: function($event) {
                                  return _vm.prevStep()
                                }
                              }
                            },
                            [_vm._v("Zurück")]
                          )
                        : _vm._e(),
                      _vm.stepKey !== "ausgang"
                        ? _c(
                            "BaseButton",
                            {
                              attrs: { isPrimary: "" },
                              on: {
                                click: function($event) {
                                  return _vm.nextStep()
                                }
                              }
                            },
                            [_vm._v("Weiter")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }