<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
      title="Gesellschaften Ansprechpartner"
      :id="$appNavigation.currentOptionMenuId">
    </PageHeaderTitleNavigation>

    <StepperForm
      stepType="tab"
      stepperName="ansprechpartner"
      :stepperMediator="tabsStepperMediator"
      :selectedStepKey="currentStep"
      @set-step="navigateTo($event)"
      @on-fertig="finishEdition()"
    >
      <template #contentTemplate>
        <router-view @change="onChange($event)" :loading="loading"></router-view>
      </template>
    </StepperForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StepperMediator } from '@/components/stepper/utils';
import CORE_TYPES from '@/store/core/types'
import ANSPRECHPARTNER_TYPES from '@/store/ansprechpartner/types';

import StepperForm from '@/components/stepper/StepperForm.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    StepperForm,
    PageHeaderTitleNavigation,
    OptionMenu,
  },
  data() {
    return {
      currentStep: 'stammdaten',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      ansprechpartner: ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER,
    }),
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    steps() {
      let steps = [
        {
          label: "Personen Daten",
          stepKey: 'stammdaten',
          totalProgress: 1,
        },
      ]

      if(this.isIntern) {
        steps.push(
          {
            label: "Zusatz",
            stepKey: 'zusatz',
            totalProgress: 2,
          },
          {
            label: "Vorlieben",
            stepKey: 'vorlieben',
            totalProgress: 3,
          }
        )
      }

      return steps
    },
  },
  mounted() {
    this.currentStep = this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1) || 'stammdaten'
    this.initAnsprechpartner()
  },
  methods: {
    initAnsprechpartner() {
      this.loading = true
      let personId = (this.$route.params.personId === 'new' ? undefined : this.$route.params.personId) || this.$route.query.personId
      
      const params = makeQueryParam({ personId, gesellschaftId: this.$route.params.gesellId }, true)

      axios.get(`${process.env.VUE_APP_API}/gesellschaftPersonenDatenService/initAnsprechpartner?${params}`, config).then(response => {
        if (response && response.data) {
          this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_ANSPRECHPARTNER, response.data.ansprechpartner);
          this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_ANSPRECHPARTNER_SELECTIONS, response.data.comboboxSelections);
          this.loading = false
        } 
      }).catch(() => this.loading = false)
    },
    navigateTo(path) {
      if(this.$route.fullPath !== path) {
        this.currentStep = path
        this.$router.push({
          path: `${path}`,
          query: this.$route.query,
        });
      }
    },
    onChange(event) {
      this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.UPDATE_ANSPRECHPARTNER_DATA, event);
    },
    doSave() {
      if (this.ansprechpartner.type && this.ansprechpartner.lastName) {
        return this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_ANSPRECHPARTNER);
      }
    },
    finishEdition() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.GO_TO_LAST_BACK_TO_PREVIOUS_PAGE);
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.doSave()
    this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.RESET_STATE);
    next();
  },
}
</script>
