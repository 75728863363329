<template>
    <div v-if="vertragsdatenFieldsDefinition">

        <Table v-if="rows.length"
            :tableId="tableId"
            ref="table"
            cardView
            :headers="headers"
            :rows="rows"
            :hidePagination="true"
        />

    </div>
</template>
<script>
import {TextColumn} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '@/store/core/types';
import { parse } from '@/helpers/number-formatter.js';


export default {
    components: {
        Table,
    },
    data: function () {
        return {
            title: "Vertragsdaten"
        }
    },
    computed: {
        ...mapGetters({
            versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            isFK: CORE_TYPES.GETTERS.IS_FK,
            vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        }),
        headers() {
            const result = {
            center: [],
            };

            if (this.vertragsdatenFieldsDefinition?.maklerGesellNr?.visible) {
                result.center.push(TextColumn("maklerGesellNr", this.isFK ? 'Vermittlernummer Gesellschaft' : 'Courtagenummer'));
            }
            if (this.vertragsdatenFieldsDefinition?.vermittlernummerBemerkung?.visible) {
                result.center.push(TextColumn("vermittlernummerBemerkung", "Bemerkung Vermittlernummer"));
            }
            if (this.vertragsdatenFieldsDefinition?.bezug?.visible) {
                result.center.push(TextColumn("bezug", "Bezug"));
            }
            if (this.vertragsdatenFieldsDefinition?.bemerkungStatus?.visible) {
                result.center.push(TextColumn("bemerkungStatus", "Bemerkung Status"));
            }
            if (this.vertragsdatenFieldsDefinition?.interneNummer?.visible) {
                result.center.push(TextColumn("interneNummer", "Interne Nummer"));
            }
            if (this.vertragsdatenFieldsDefinition?.kundennr?.visible) {
                result.center.push(TextColumn("kundennr", "Kundennummer"));
            }
            if (this.vertragsdatenFieldsDefinition?.geteiltmitKunde?.visible) {
                result.center.push(TextColumn("geteiltmitKunde", "Geteilt mit Kundennummer"));
            }
            if (this.vertragsdatenFieldsDefinition?.erfassungsdatum?.visible) {
                result.center.push(TextColumn("erfassungsdatum", "Erfassungsdatum"));
            }  
            if (this.vertragsdatenFieldsDefinition?.autoUpdate?.visible) {
                result.center.push(TextColumn("autoUpdate", this.vertragsdatenFieldsDefinition?.autoUpdate?.value));
            }  
            if (this.vertragsdatenFieldsDefinition?.letzteGDVAnderung?.visible) {
                result.center.push(TextColumn("letzteGDVAnderung", `Letzte ${this.isFA ? 'OMDS Änderung' : 'GDV-Datenänderung'}`));
            } 
            if (this.vertragsdatenFieldsDefinition?.pramieBrutto?.visible) {
                result.center.push(TextColumn("pramieBruttoJahr", "Bruttoprämie pro Jahr"));
            }
            if (this.vertragsdatenFieldsDefinition?.pramieNettoJahr?.visible) {
                result.center.push(TextColumn("pramieNettoJahr", "Nettoprämie pro Jahr"));
            }  

            if (this.vertragsdatenFieldsDefinition?.bestandsubertragung?.visible) {
                result.center.push(TextColumn("bestandsubertragung", "Bestandsübertragung"));
            }
            if (this.vertragsdatenFieldsDefinition?.alleRechtePflichten?.visible) {
                result.center.push(TextColumn("alleRechtePflichten", "Inkl. aller Rechte/Pflichten"));
            }
            
            if (this.vertragsdatenFieldsDefinition?.korrespondenz?.visible) {
                result.center.push(TextColumn("korrespondenz", "Korrespondenz"));
            }   
            if (this.vertragsdatenFieldsDefinition?.buAbgelehnt?.visible) {
                result.center.push(TextColumn("buAbgelehnt", "BÜ abgelehnt"));
            }   
            if (this.vertragsdatenFieldsDefinition?.letzteAnderung?.visible) {
                result.center.push(TextColumn("letzteAnderung", "Letzte Änderung"));
            }
            if (this.vertragsdatenFieldsDefinition?.beitragszahlungsdauer?.visible) {
                result.center.push(TextColumn("beitragszahlungsdauer", "Beitragszahlungsdauer"));
            }              
            if (this.vertragsdatenFieldsDefinition?.vertragssumme?.visible) {
                result.center.push(TextColumn("vertragssumme", "Vertragssumme"));
            }
                         
        return result;
        },
        rows() {
            const rows = [];
            const row = {};

            row.id = 0;

            if (this.vertragsdatenFieldsDefinition?.maklerGesellNr?.visible) {
                const maklerGesellNr = this.vertragsdatenFieldsDefinition?.maklerGesellNr?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.maklerGesellNr);
                row.maklerGesellNr = maklerGesellNr?.label;
            } 
            if (this.vertragsdatenFieldsDefinition?.vermittlernummerBemerkung?.visible) {
                row.vermittlernummerBemerkung = this.versicherungenDetails?.insurance?.vermittlernummerBemerkung || '-';
            }   
            if (this.vertragsdatenFieldsDefinition?.bezug?.visible) {
                const bezug = this.vertragsdatenFieldsDefinition?.bezug?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.bezug)
                row.bezug = bezug?.label || '-';
            }            
            if (this.vertragsdatenFieldsDefinition?.bemerkungStatus?.visible) {
                row.bemerkungStatus = this.versicherungenDetails?.insurance?.bemerkungStatus || '-';
            }             
            if (this.vertragsdatenFieldsDefinition?.interneNummer?.visible) {
                row.interneNummer = this.versicherungenDetails?.insurance?.id;
            }  
            if (this.vertragsdatenFieldsDefinition?.kundennr?.visible) {
                row.kundennr = this.versicherungenDetails?.insurance?.kundennr
            }
            if (this.vertragsdatenFieldsDefinition?.geteiltmitKunde?.visible) {
                row.geteiltmitKunde = this.versicherungenDetails?.insurance?.geteiltmitKunde || '-';
            }  
            if (this.vertragsdatenFieldsDefinition?.erfassungsdatum?.visible) {
                row.erfassungsdatum = this.versicherungenDetails?.insurance?.erfassungsdatum || './.';
            } 
            if (this.vertragsdatenFieldsDefinition?.autoUpdate?.visible) {
                row.autoUpdate = this.versicherungenDetails?.insurance?.einlesen_updaten? 'Ja' : 'Nein';
            }  
            if (this.vertragsdatenFieldsDefinition?.pramieBrutto?.visible) {
                row.pramieBruttoJahr = this.currency(this.getPraemieJahr(this.versicherungenDetails?.insurance?.praemiebto_zahlweise)) || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.pramieNetto?.visible) {
                row.pramieNetto = this.currency(this.versicherungenDetails?.insurance?.praemiento_zahlweise) || '-';
            }     
            if (this.vertragsdatenFieldsDefinition?.bestandsubertragung?.visible) {
                row.bestandsubertragung = this.versicherungenDetails?.insurance?.bestandsubertragung? 'Ja' : 'Nein';
            }
            if (this.vertragsdatenFieldsDefinition?.alleRechtePflichten?.visible) {
                row.alleRechtePflichten = this.versicherungenDetails?.insurance?.alleRechtePflichten? 'Ja' : 'Nein';
            }            
            if (this.vertragsdatenFieldsDefinition?.korrespondenz?.visible) {
                row.korrespondenz = this.versicherungenDetails?.insurance?.korrespondenz? 'Ja' : 'Nein';
            }   
            if (this.vertragsdatenFieldsDefinition?.buAbgelehnt?.visible) {
                row.buAbgelehnt = this.versicherungenDetails?.insurance?.buAbgelehnt? 'Ja' : 'Nein';
            }   
            if (this.vertragsdatenFieldsDefinition?.letzteAnderung?.visible) {
                row.letzteAnderung = this.versicherungenDetails?.insurance?.letzteAnderung || './.';
            }
            if (this.vertragsdatenFieldsDefinition?.beitragszahlungsdauer?.visible) {
                row.beitragszahlungsdauer = this.versicherungenDetails?.insurance?.beitragszahlungsdauer || '-';
            }            

            // No order defined for the following fields (see ticket MSC-27944)
            
            if (this.vertragsdatenFieldsDefinition?.vertragssumme?.visible) {
                row.vertragssumme = this.currency(this.versicherungenDetails?.insurance?.vertragssumme) || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.pramieNettoJahr?.visible) {
                row.pramieNettoJahr = this.currency(this.pramieNettoJahr) || '-';
            }     
            if (this.vertragsdatenFieldsDefinition?.zahlungsart?.visible) {
                const zahlungsart = this.vertragsdatenFieldsDefinition?.zahlungsart?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.zahlungsart);
                row.zahlungsart = zahlungsart?.label || '-';
            }     
            if (this.vertragsdatenFieldsDefinition?.bankverbindung?.visible) {
                const bankverbindung = this.vertragsdatenFieldsDefinition?.bankverbindung?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.bankverbindung);
                row.bankverbindung = bankverbindung?.label || '-';
            }   
 
            if (this.vertragsdatenFieldsDefinition?.letzteGDVAnderung?.visible) {
                row.letzteGDVAnderung = this.versicherungenDetails?.insurance?.dateUpdatetdGDV || './.';
            } 
            if (this.vertragsdatenFieldsDefinition?.externeKennung?.visible) {
                row.externeKennung = this.versicherungenDetails?.insurance?.externeKennung || '-';
            }       
     
            
            rows.push(row);
            return rows;
        },
        pramieNettoJahr() {
            return this.getPraemieJahr(this.versicherungenDetails?.insurance?.praemiento_zahlweise);
        },
        tableId() {
            const uniqueUUID = '87a21185-5360-4f71-b7f8-583d23be53df';
            const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
            return `${uniqueUUID}-${vertragsparte?.label}`;
        },

    },  
    methods: {
        getPeriodMultiplier(period) {
            switch (period) {
                case "monatlich":
                return 12;
                case "zweimonatlich":
                return 6;
                case "vierteljährlich":
                return 4;
                case "halbjährlich":
                return 2;
                case "jährlich":
                return 1;
                default:
                return 0;
            }
        },
        currency(value) {
            if (isNaN(value) || !value) {
                return value;
            }
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
        },
        getPraemieJahr(praemieMonth) {
            if(praemieMonth) {
                if (typeof(praemieMonth) === 'string') {
                praemieMonth = parse(praemieMonth);
                }
                const zahlrhythm = this.versicherungenDetails?.insurance?.zahlrhythm || "jährlich";
                return (praemieMonth * this.getPeriodMultiplier(zahlrhythm)).toFixed(2);
            }
        },        
    },
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>