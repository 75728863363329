<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="PDF-Kundendaten" />

    <BaseFilter ref="filter" class="mb-0"
      title="zusätzliche Optionen"
      filterId="pdf_kundendaten_filter"
      :configFilter="filterConfig"
      :metadata="filterOptions"
      :defaultOptions="filterDefaultOptions"
      showSaveButton
      @getPDF="getPDF"
    />
    
    <BaseButtonsContainer class="action-button__container">
      <BaseButton @click="filterZurucksetzen()" isSecondary>
        Filter zurücksetzen
      </BaseButton> 
      <BaseButton @click="getPDF(true)">
        Karteikarte anzeigen
      </BaseButton>
      <BaseButton @click="getPDF(false)">
        Formular anzeigen
      </BaseButton> 
    </BaseButtonsContainer>
  </div>
</template>
 
<script >
  // PdfKundendaten.vue
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { VIEW_ROLES } from '@/router/roles';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseFilter, BaseButtonsContainer, BaseButton
  },
  
  mounted() {
    console.log('GETTERS', this.isBypass ) 

    this.addStandard()
  },
  data() {
    return {
      loading: false,
      filterConfig: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isBypass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    filterDefaultOptions() {
      const optionen = {mitPersonen: true};
      return optionen
    },
    filterOptions() {
      const menuItems = [
        {type: 'default', label: 'Briefkopf anzeigen',             key: 'mitBriefkopf', emptyDenied: true},
        {type: 'default', label: 'Faxversion (schwarz / weiss)',   key: 'zeigeFaxversion', emptyDenied: true},
      ];
      if (this.isBypass) {
        menuItems.push({type: 'default', label: 'Bemerkung',       key: 'mitBemerkung', emptyDenied: true})
      }
      menuItems.push(
        {type: 'default', label: 'Zusätzliche Personen',           key: 'mitPersonen', emptyDenied: true},
        {type: 'default', label: 'Bankverbindungen',               key: 'mitBankverbindungen', emptyDenied: true },
        {type: 'default', label: 'IBAN',                           key: 'mitBankIBAN', emptyDenied: true},
        {type: 'default', label: 'BIC',                            key: 'mitBankSWIFT_BIC', emptyDenied: true},
        {type: 'default', label: 'Arbeitgeber',                    key: 'zeigeArbeitgeber', emptyDenied: true},
        {type: 'default', label: 'Zusätzliche Informationen',      key: 'zeigeZusaetzlicheInformationen', emptyDenied: true},
        {type: 'default', label: 'Finanzielle Verhältnisse',       key: 'zeigeFinanzielle', emptyDenied: true},
        {type: 'default', label: 'Einkommen / Sozialversicherung', key: 'zeigeEinkommen_Socialversicherung', emptyDenied: true},
        {type: 'default', label: 'Ohne Zusatz',                    key: 'all_false', emptyDenied: true},
      )
      return [{
        type: 'group',
        key: 'allgemein',
        label: 'Allgemein',
        menuItems
      }]
    },
  },
  methods: {
    filterZurucksetzen() {
      if (this.$refs?.filter?.filterZurucksetzen) {
        this.$refs.filter.filterZurucksetzen();
        this.addStandard()
      }
    },
    addStandard() {
      if (this.isBypass) {
        this.$refs.filter.addRow({key: 'allgemein', secondaryKey: 'mitBemerkung', value: true})
      }
    },
    async getPDF(arg) {
      if (this.$refs?.filter?.getFilterConfig) {
        this.$refs.filter.defaultSearchInputExitDebounce()
        const filterConfig = this.$refs.filter.getFilterConfig();
        this.$refs.filter.saveCacheFilterOptions()

        let params = '';
        filterConfig.forEach(f => { params += ',' + f.key })
        params = {[arg ? 'getPDF' : 'getFormular']: params+','};
        console.log('getPDF', params )
        const response = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.LOAD_PDF_KUNDENDATEN, params);
        if ( response.data.pdf && response.data.filename){
          viewDocument({
            data: response.data.pdf,
            filename: response.data.filename,
            contentType: 'application/pdf',
          });
        }
      }
    },

  },
}


</script>

<style scoped>
::v-deep .action-button__container {
  display: none
}
.action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>