<template>
  <div class="d-none">
  </div>
</template>

<script>
import store from '@/store';
import CORE_TYPES from '@/store/core/types';

import { ROLES } from '@/router/roles';

export default {
  beforeRouteEnter(to, from, next) {
    const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];

    let link = '';
    if (hasRoles([ROLES.FA])) {
      link = 'https://www.finanzadmin.at/info-datenschutz';
    } else {
      link = `https://www.finance-home.de/datenschutz`;
    }
    window.open(link);

    next(false);
  },
}
</script>

<style scoped></style>
