var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.title, id: _vm.$appNavigation.currentOptionMenuId }
      }),
      _c(
        "BaseFilter",
        {
          attrs: {
            filterId: _vm.saveKey,
            metadata: _vm.searchMenu,
            configFilter: _vm.configFilter,
            defaultOptions: _vm.defaultOptions,
            openFilterOnMount: false
          },
          on: {
            onFilter: _vm.handleSearch,
            onFilterZurucksetzen: function($event) {
              return _vm.$emit("onFilterZurucksetzen")
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("EventsFkTable", {
            attrs: { events: _vm.rows, rowsPerPage: 20, title: _vm.title }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }