<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="pageTitle"
      :actions="headerActions"
      @action-CREATE="submit()" />

    <div class="box__container" tid="813cee9d-2008-4830-8181-c1072c7e8436" v-if="settings">
      <p>Bitte wählen Sie die Zusammenstellung Ihrer Vermögensübersicht:</p>

      <InputRadioBoxGroup v-model="form['valueDatumTyp']" :values="[
          {value: 'aktuell', label: 'Aktuelle Vermögensübersicht'},
          {value: 'historisch', label: 'Historische Vermögensübersicht'}
      ]" title="Stichtag" style="width: 100%"
      @input="onChange({value: $event, field: 'valueDatumTyp'})" />

      <DatePickerField 
        isComponentHalfSize
        isValueAsString
        v-if="form['valueDatumTyp']=='historisch'"
        v-model="form['datumVermogensubersicht']" 
        label="Datum Vermögensübersicht" 
        @input="onChange({value: $event, field: 'datumVermogensubersicht'})"/>  

      <InputRadioBoxGroup v-model="form['depotDistribution']" :values="[
          {value: 'userSettings', label: 'Aufteilung der Depots gem. Einstellung beim Kunden'},
          {value: 'allInOne', label: 'Alle Depotpositionen in einer Vermögensübersicht'},
          {value: 'separate', label: 'Depotpositionen auf Depotauszüge der angelegten Depots aufteilen'},
          {value: 'selected', label: 'Nur das aktuell ausgewählte Depot'},
      ]" title="Aufteilung der Depotpositionen" style="width: 100%"
       @input="onChange({value: $event, field: 'depotDistribution'})"/>

      <ComboBox
        v-if="form['depotDistribution'] =='selected'"
        v-model="form['depot']"
        :values="depots"
        @input="onChange({value: $event, field: 'depot'})"
      />

      <InputToggleSwitch 
        label="Transaktionen berücksichtigen" 
        :value="form['inclTransactions']"
        @input="onChange({value: $event, field: 'inclTransactions'})">
      </InputToggleSwitch>     

      <DatePickerField 
        isComponentHalfSize
        isValueAsString
        v-model="form['transactionsFromDate']" 
        label="Datum ab"
        v-if="form['inclTransactions']"
        @input="onChange({value: $event, field: 'transactionsFromDate'})"/>   

      <DatePickerField 
        isComponentHalfSize
        isValueAsString
        v-model="form['transactionsToDate']" 
        label="bis"
        v-if="form['inclTransactions']"
        :disabled="form['valueDatumTyp']!=='historisch'"
        @input="onChange({value: $event, field: 'transactionsToDate'})"/>   

      <InputToggleSwitch 
        label="Vermögensübersicht in Kurzform" 
        :value="form['isShortForm']" 
        @input="onInputToggleSwitch">
      </InputToggleSwitch>

      <div v-if="!isShortForm">
          <h4>Grafischer Verlauf:</h4>

          <InputToggleSwitch 
            label="Grafischen Verlauf anzeigen" 
            :value="form['inclGraphic']"
            @input="onChange({value: $event, field: 'inclGraphic'})">
          </InputToggleSwitch>     

          <InputRadioBoxGroup
            v-model="form['gvPeriod']"
            :values="[
              {value: 'ENTWICKLUNG_MONATE', label: 'Entwicklung der letzten Monate', default: true},
              {value: 'ENTWICKLUNG_AB_DATUM', label: 'Entwicklung ab Datum'},
              {value: 'ENTWICKLUNG_SEIT_BEGINN', label: 'Entwicklung seit Beginn'}]"
            style="width: 100%"
            @input="onChange({value: $event, field: 'gvPeriod'})">
          </InputRadioBoxGroup>    
          <InputField v-if="form['gvPeriod'] == 'ENTWICKLUNG_MONATE'" 
            label="Entwicklung der letzten Monate"
            v-model="form['monthsCount']"
            isComponentHalfSize
            @input="onChange({value: $event, field: 'monthsCount'})"/>             
          <DatePickerField
            isComponentHalfSize
            isValueAsString
            v-if="form['gvPeriod'] == 'ENTWICKLUNG_AB_DATUM'"
            v-model="form['graphFromDate']" 
            label="Entwicklung ab Datum"
            @input="onChange({value: $event, field: 'graphFromDate'})"/>   

          <InputToggleSwitch
            label="Inkl. Wertentwicklung einzelner Depotpositionen" 
            :value="form['inclGraphDetails']"
            @input="onChange({value: $event, field: 'inclGraphDetails'})">
          </InputToggleSwitch>

          <InputToggleSwitch
            v-if="form['showDeckBlattForm']"
            label="Inkl. Deckblatt" 
            :value="form['deckblatt']"
            @input="onChange({value: $event, field: 'deckblatt'})">
          </InputToggleSwitch>
          <InputTextArea
            v-if="form['showDeckBlattForm']"
            label="Nachricht für Kunden auf dem Deckblatt"
            v-model="form['message']"
            @input="onChange({value: $event, field: 'message'})">
          </InputTextArea>

          <InputCheckBoxGroup 
            :values="[
              { label: 'Inkl. Factsheet', path: 'inclFactSheet'},
              { label: 'Inkl. Factsheet KAG / Verkaufsprospekt', path: 'inclKag'},
              { label: 'Inkl. PRIIP-BIB / Produktinformationsblatt', path: 'inclKid'}]"
            :value="form">
          </InputCheckBoxGroup>

      </div>

    <BaseModal
      ref="generatingPDFModal"
      labelButtonCancel="Abbrechen"
      :showCancelButton="true"
      :showConfirmButton="false"
      @onCancelButton="cancelPDFGeneration()"
      @onCloseButton="cancelPDFGeneration()"
      size="lg">
      <template v-slot:modalTitle>
        Vermögensübersicht wird erstellt
      </template>
      <div class="mb-1">Daten werden geladen... Es kann einige Minuten dauern.</div>
    </BaseModal> 

    </div>
    <div v-else class="box__container">
      <GhostLoading :repeat="2">
        <Block type="title" />
        <p><Block /></p>
        <p>
          <Block />
          <Block width="80%" />
          <Block width="80%" />
        </p>
        <p>
          <Block />
          <Block width="80%" />
          <Block width="80%" />
          <Block width="80%" />
          <Block width="80%" />
          <Block width="80%" />
        </p>
        <p>
          <Block />
          <Block width="80%" />
        </p>
      </GhostLoading>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import BALANCE_SHEET_TYPES from "@/store/balance-sheet/types";
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import axios from 'axios';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { uploadFileChunked } from '@/helpers/upload-helper';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { buildMessage } from "@/helpers/log-message-helper";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TYPE_PDF = 'PDF';
const TYPE_MAIL = 'MAIL';
const TYPE_VALIDS = [TYPE_PDF, TYPE_MAIL];

const PDF_FILE_TYPE = 'application/pdf';


export default {
  props: {
    type: {
      type: String,
      default: 'PDF',
      validator: (value) => {
        return TYPE_VALIDS.indexOf(value) >= 0;
      },
    },
  },
	data() {
		return {
      title: 'Spar-/Entnahmepläne',
      form: {
        isShortForm: true,
        depotDistribution: 'userSettings',
        inclTransactions: false,
        transactionsFromDate: null,
        inclGraphic: false,
        gvPeriod: 'ENTWICKLUNG_MONATE',
        monthsCount: null,
        graphFromDate: null,
        inclGraphDetails: null,
        inclFactSheet: null,
        inclKag: null,
        inclKid: null,
        valueDatumTyp: 'aktuell',
        datumVermogensubersicht: null,
        depot: null,
      },
      isShortForm: true,
      settings: false,
      downloadPdf: true,
      cancelToken: null,
      source: null,
    }
  },
  computed: {
    ...mapGetters({
      vermogensubersichtPdf : BALANCE_SHEET_TYPES.GETTERS.VERMOGENSUBERSICHT_PDF,
      depotPdfOptions: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_PDF_OPTIONS,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
    }),
    fileName() {
      return `Vermögensübersicht_${this.fullname.replace(/ /g, '_')}.pdf`;
    },
    isTypeMail() {
      return this.type == TYPE_MAIL;
    },
    pageTitle() {
      switch(this.type) {
        case TYPE_PDF:
          return 'Vermögensübersicht als PDF';
        case TYPE_MAIL:
          return 'Vermögensübersicht als PDF per E-Mail versenden';
      }
      return 'Vermögensübersicht als PDF';
    },
    depots() {
      return (this.depotPdfOptions?.filter?.depots || []).map(item => ({value: item.id, label: item.name}));
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE', 'Vermögensübersicht erstellen')
      ];
    },
  },
  watch: {
    depotPdfOptions: function() {
      this.form = {...this.form, ...this.depotPdfOptions.filter};
      this.isShortForm = this.form.isShortForm;
      this.settings = true;
    }
  },
  methods: {
    onInputToggleSwitch(event) {
      this.isShortForm = event;
      this.onChange({value: event, field: 'isShortForm'})
    },    
    getPdfOptions() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_DEPOT_PDF_OPTIONS, { type: this.type, })
    },
    onChange(event) {
      this.form[event.field] = event.value
    },
    submit() {
      let params;
      if (this.form.isShortForm) {
        params = {
          isShortForm: true,
          depotDistribution: this.form.depotDistribution,
          selectedDepot: this.form.depot,
          inclTransactions: this.form.inclTransactions,
          transactionsFromDate: this.form.transactionsFromDate,
          inclGraphic: false,
          gvPeriod: null,
          monthsCount: null,
          graphFromDate: null,
          inclGraphDetails: false,
          inclFactSheet: false,
          inclKag: false,
          inclKid: false,
          transactionsToDate : this.form.transactionsToDate,
          valueDatumTyp: this.form.valueDatumTyp,
          datumVermogensubersicht : this.form.datumVermogensubersicht,
          deckblatt: this.form.deckblatt,
          message: this.form.message,
        };
      } else {
        params = {
          isShortForm: this.form.isShortForm,
          depotDistribution: this.form.depotDistribution,
          selectedDepot: this.form.depot,
          inclTransactions: this.form.inclTransactions,
          transactionsFromDate: this.form.transactionsFromDate,
          inclGraphic: this.form.inclGraphic,
          gvPeriod: this.form.gvPeriod,
          monthsCount: this.form.monthsCount,
          graphFromDate: this.form.graphFromDate,
          inclGraphDetails: this.form.inclGraphDetails,
          inclFactSheet: this.form.inclFactSheet,
          inclKag: this.form.inclKag,
          inclKid: this.form.inclKid,
          transactionsToDate : this.form.transactionsToDate,
          valueDatumTyp: this.form.valueDatumTyp,
          datumVermogensubersicht : this.form.datumVermogensubersicht,
          deckblatt: this.form.deckblatt,
          message: this.form.message,

        };
      }

      this.requestPDF({ pdfFilter: params });
    },  
    requestPDF(payload) {
      this.showGeneratingPDFModal();
      this.cancelToken = axios.CancelToken;
      this.source = this.cancelToken.source();

      const config = {
        defaultSpinner: true,
        disableDefaultLog: true,
        responseType: 'arraybuffer',
        cancelToken: this.source.token
      };
      
      let url = 'mrsdepotpdf';

      axios.post(process.env.VUE_APP_API + '/../' + url, payload, config).then(response => {
        if (response && response.data) {
          if(this.isTypeMail) {
            this.navigateToMailComposer(response.data);
          } else {
            viewDocument({
              data: response.data,
              contentType: PDF_FILE_TYPE,
              filename: this.fileName,
            })
          }
        } 
        this.hideGeneratingPDFModal();
      }
      ).catch(error => {
        this.hideGeneratingPDFModal();
        if (error.response?.headers?.fehlertext) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.headers.fehlertext, 'danger'));
        }
      })

    },
    cancelPDFGeneration() {
      this.source.cancel('Operation canceled by the user.');
      this.hideGeneratingPDFModal();

    },
    showGeneratingPDFModal() {
       this.$refs.generatingPDFModal.open();
    },
    hideGeneratingPDFModal() {
      this.$refs.generatingPDFModal.close();
    },
    navigateToMailComposer(pdfData) {
      if(!pdfData) return;

      const pdfFile = new File([pdfData], this.fileName, { type: PDF_FILE_TYPE, });
      uploadFileChunked(pdfFile).then(id => {
        const attachment = {
          id,
          name: pdfFile.name,
          uploaded: true,
        };
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [attachment]);

        this.$router.push({
          name: 'mailcomposer',
          params: {
            subject: this.depotPdfOptions?.email?.subject || '',
            content: this.depotPdfOptions?.email?.html || '',
          },
          query: {novorlage: 1}
        });
      });
    },
  },
	mounted() {
    this.getPdfOptions();
	},
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
	components: {
    InputRadioBoxGroup,
    InputToggleSwitch,
    InputCheckBoxGroup,
    InputField,
    DatePickerField,
    ComboBox,
    BaseButton,
    BaseModal,
    GhostLoading,
    Block,
    InputTextArea,
    OptionMenu,
    PageHeaderTitleNavigation,
	}
}
</script>

<style scoped>
.table-wrap {
  width: 100%;
}
</style>
