var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Bestandsübertragung Versicherung",
          actions: _vm.headerActions,
          defaultMenu: _vm.customOptionMenu
        },
        on: {
          "action-MAKE": function($event) {
            return _vm.makeUebertragung()
          },
          "action-ADD": function($event) {
            return _vm.addVersicherung()
          }
        }
      }),
      _vm.isUebertragbarVersPage
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", [
                _vm._v(
                  " Zusätzlich wird der Makler beauftragt, die Betreuung auch der nachfolgend aufgeführten, nicht vom ihm vermittelten Versicherungsverträge zu übernehmen. Für die angehakten Verträge wird mit der aktuell hinterlegten Vollmacht sofort eine Bestandsübertragung ausgelöst: "
                )
              ]),
              _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("Table", {
                    attrs: {
                      rowId: "index",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      mobileConfig: { selectionActive: true },
                      noDataContent:
                        "Es wurden keine übertragbaren Versicherungen gefunden"
                    },
                    on: {
                      clickRow: _vm.editVersicherung,
                      "click-label": _vm.editVersicherung
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "status",
                          fn: function(row) {
                            return [
                              !_vm.hatVollmacht
                                ? _c("ph-x", {
                                    directives: [
                                      {
                                        name: "fc-tooltip",
                                        rawName: "v-fc-tooltip",
                                        value: "keine Vollmacht vorhanden",
                                        expression:
                                          "'keine Vollmacht vorhanden'"
                                      }
                                    ],
                                    staticClass: "color-danger",
                                    attrs: { size: 24 }
                                  })
                                : row.isUebertragMoeglich && row.inUmfang
                                ? _c("ph-check", {
                                    staticClass: "color-success",
                                    attrs: { size: 24 }
                                  })
                                : !row.versNr
                                ? _c("Pill", {
                                    attrs: {
                                      label: "keine Versicherungsnummer",
                                      type: "danger"
                                    }
                                  })
                                : _c("Pill", {
                                    attrs: {
                                      label: row.extraInfo,
                                      type: "danger"
                                    }
                                  })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2535874745
                    ),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", [
                _vm._v(
                  " Die nachfolgenden Versicherungen können leider nicht übertragen werden, weil kein Vertrag mit den entsprechenden Gesellschaften besteht oder eine Übertragung bereits stattfindet. "
                )
              ]),
              _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("Table", {
                    attrs: {
                      headers: _vm.headersDisabled,
                      rows: _vm.rowsDisabled,
                      rowsPerPage: 10,
                      mobileConfig: {}
                    },
                    on: {
                      clickRow: _vm.editVersicherung,
                      "click-label": _vm.editVersicherung
                    }
                  })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }