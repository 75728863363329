import SCHADENSMELDUNG_TYPES from './types';

export default {
  [SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG_LIST](state) {
    return state.schadensmeldungen;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.SCHADEN_BEARBSTAND_VALUES](state) {
    return state.schaden_bearbstand_values;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG](state) {
    return state.schadensmeldung;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_INSERTED_BETEILIGTE](state) {
    return state.beteiligte;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_BETEILIGTE](state) {
    return state.deletedBeteiligteId;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_SENT_ANHANG](state) {
    return state.lastSentAnhang;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_ANHANG](state) {
    return state.deletedAnhangId;
  }, 
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_SENT_ZAHLUNG](state) {
    return state.lastZahlungId;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_ZAHLUNG](state) {
    return state.deletedZahlungId;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_SENT_BESCHAEDIGT](state) {
    return state.lastBeschaedigtId;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.LAST_DELETED_BESCHAEDIGT](state) {
    return state.deletedBeschaedigtId;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA](state) {
    return state.configData || {};
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.PDF_DATA](state) {
    return state.schadenPdfData || {};
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG_EDITED](state) {
    return state.schadensmeldungEdited;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.FILES_FROM_DOKUMENTENARCHIV](state) {
    return state.attachments;
  },
  [SCHADENSMELDUNG_TYPES.GETTERS.BETEILIGTE_EDITED](state) {
    return state.beteiligteEdited;
  },
}