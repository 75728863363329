<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :title="$appNavigation.currentMenu.label"
    />

    <div class="box__container">
      <div class="box__title">Vermittlerwechsel innerhalb der Struktur</div>
    </div>

    <div class="box__container">
      <div class="box__title">
        Hier können Sie den Kunden zu einem anderen Makler Ihrer Struktur
        wechseln. Die Formulare werden wo möglich sofort an die Lagerstellen
        geschickt. Alle Lagerstellen die nicht automatisch umgestellt werden
        können, müssen nach dem Übertrag ggf. mit Kundenunterschrift übertragen
        werden.
      </div>
    </div>
    
    <GenericPersonFilter
      filterId="5fd9b999-4056-4f0a-9863-703b90cdbdb7"
      title="Vermittlersuche"
      saveKey="vermittlerwechsel"
      :metadata="searchMenu"
      @search="handleSearch($event)"
      :isCustomerSearch="false"
      :configFilter="configFilter"
    />
    
    <div class="box__container" v-if="loading.filter !== null">
      <GhostLoading
        v-if="loading.filter"
        type="table"
        title="Vermittlerliste"
      />
      <PaginatedTable v-else-if="!loading.filter"
        title="Vermittlerliste"
        tableId="9815ec29-a2c2-49e4-95b9-04831b92d2c5"
        v-model="selectedRows"
        :headers="headers"
        :pages="pages"
        :pageCount="pageCount"
        :rowCount="rowCount"
        :page="pageId"
        :thHoverDisabled="true"
        @page="pageId = $event"
        @requestPage="loadPage"
        @click-fullName="selectUserId($event.userId)"
        @sort="onBrokerSearchResultSort"
      />
    </div>

    <div class="box__container">
      <BaseButton
        class="mr-2"
        label="Vermittler wechseln"
        :animated="loading.change"
        :disabled="selectedRows.length != 1"
        @click="$refs.changeVermittlerModal.open()"
      />
    </div>

    <div
      class="box__container"
      v-if="lagerstellenList && lagerstellenList.length"
    >
      <div class="box__title">Lagerstellen:</div>
      <div v-for="(lagerstelle, index) in lagerstellenList" :key="index">
        <div style="display: flex">
          <div style="width: 50%">
            {{ lagerstelle.label }}
          </div>
          <div>
            {{ lagerstelle.description }}
          </div>
        </div>
        <hr />
      </div>
    </div>

    <BaseModal
      ref="changeVermittlerModal"
      labelButtonConfirm="Vermittler wechseln"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="changeVermittler()"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="color-danger mr-2" />
        Vermittlerwechsel
      </template>

      <p class="color-danger">Achtung!</p>
      Hiermit wird der Kunde auf den ausgewählten Makler umgebucht
      <b>
        und alle Dokumente werden automatisch an die jeweiligen Depotstellen
        versendet!
      </b>
    </BaseModal>

    <BaseModal
      ref="versandFailedModal"
      modalTitle="Übertrag kann nicht durchgeführt werden"
      labelButtonConfirm="Ticket für Fehlerbehebung erstellen"
      labelButtonCancel="Abbruch (Wechsel nicht durchführen)"
      @onConfirmButton="createTicket()"
    >
      <div v-html="sanitize(versandFailedMessage)"></div>
    </BaseModal>

    <BaseModal
      ref="hinweisModal"
      modalTitle="Wichtige Hinweise"
      labelButtonConfirm="Gelesen"
      @onConfirmButton="sendGelesen()"
      :showCancelButton="false"
      :showCloseButton="false"
    >
      <div v-html="sanitize(this.popupText)"></div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from "@/store/log/types";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import axios from "axios";
import { PhWarning } from "phosphor-vue";
import { sanitize } from "@/helpers/string-helper.js";
import { buildMessage } from "@/helpers/log-message-helper";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";
import GenericPersonFilter from "@/components/core/GenericPersonFilter.vue";
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import { TextColumn, } from "@/components/table2/table_util.js";

const config = {
  defaultSpinner: true,
};

export default {
  components: {
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseModal,
    PhWarning,
    GenericPersonFilter,
    PaginatedTable,
    GhostLoading,
  },
  data() {
    return {
      targetMakler: null,
      loading: {
        init: false,
        change: false,
        filter: null,
      },
      versandFailedMessage: "",
      lagerstellenList: [],
      popupText: "",
      popupSaveParams: "",
      searchMenu: [{
        type: 'group',
        key: 'allgemein',
        label: 'Allgemein',
        menuItems: [
          {
            type: 'text',
            label: 'Vermittlernummer',
            key: 'brokerId',
            emptyDenied: true,
            inputmode: 'numeric',
          },
          {
            type: 'text',
            label: 'Name',
            key: 'surname',
            sortable: true,
            emptyDenied: true,
          },
          {
            type: 'text',
            label: 'Vorname',
            key: 'firstName',
            emptyDenied: true,
          },
          {
            type: 'text',
            label: 'Firma',
            key: 'firma',
            emptyDenied: true,
          },
        ]
      }],
      configFilter: {
        placeholderForDefSearchInput: "Firmenname, Vermittlernummer",
        filterType: "vermittlersuche",
      },
      searchParams: [],
      pageId: 0,
      brokerSearchSort: null,
      selectedRows: [],
    };
  },
  computed: {
    ...mapGetters({
      brokersData: CUSTOMER_SEARCH_TYPES.GETTERS.BROKERS,
      tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    isSomeButtonLoading() {
      return Object.values(this.loading).some((valueLoading) => valueLoading);
    },
    maklernr() {
      return this.loginData?.maklernr || ''
    },
    headers() {
      let headers = {
        lockedLeft: [
          TextColumn("fullName", "Name").makeLink(),
          TextColumn("userId", "Vermittlernummer").makeSortable((cell) => {
            if (cell.indexOf("-") > 0) {
              try {
                cell = cell.replace(/ /g, "");
                const nr = 1000 + parseInt(cell.slice(cell.indexOf("-") + 1));
                return cell.slice(0, cell.indexOf("-")) + nr;
              } catch (e) {
                console.error(e);
              }
            }
            return cell;
          }),
        ],
        center: [
          TextColumn("structureLeader", "Strukturleiter"),
          TextColumn("phone", "Telefon"),
          TextColumn("smsStandard", "Mobilnummer"),
          TextColumn("email", "E-Mail"),
          TextColumn("street", "Straße"),
          TextColumn("plz", "PLZ"),
          TextColumn("location", "Ort"),
          TextColumn("fax", "Fax"),
          TextColumn("regIHK", "IHK-Reg."),
          TextColumn("gwg", "GWG"),
          TextColumn("ze", "ZE"),
        ],
      };
      return headers;
    },
    pages() {
      const result = {};
      for (const [pageId, rows] of Object.entries(
        this.brokersData.pages || {}
      )) {
        if ((rows||[]).find((row) => row.userId == this.maklernr)) {
          this.brokersData.count--;
        }
        result[pageId] = (rows||[]).filter((row) => row.userId != this.maklernr)
        .map((row) => {
          let active = row.inactive
            ? { label: "inaktiv", type: "danger" }
            : { label: "aktiv", type: "info" };

          return {
            id: row.userId,
            ...row,
            ...(row.internData || {}),
            active,
          };
        });
      }
      return result;
    },
    rowCount() {
      return this.brokersData?.count || 0;
    },
    pageCount() {
      if (this.tablePageSize > 0) {
        return Math.ceil(this.rowCount / this.tablePageSize);
      }
      return Math.ceil(this.rowCount / 25);
    },
    hasSelection() {
      return (
        (!this.invertSelection && this.selectedRows?.length > 0) ||
        (this.invertSelection && this.selectedRows?.length < this.rowCount)
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString.replace(/\n/g, "<br>"));
    },
    init() {
      this.loading.init = true;

      axios
        .get(`${process.env.VUE_APP_API}/vermwechselintern/getData`, config)
        .then((response) => {
          if (response && response.data) {
            this.lagerstellenList = response.data.lagerstellenList;
            this.popupText = response.data.popupDataText;
            this.popupSaveParams = response.data.popupDataValue;

            if (this.popupText) this.$refs.hinweisModal.open();
          }
        })
        .finally(() => (this.loading.init = false));
    },
    sendGelesen() {
      console.info(this.popupSaveParams);
      axios
        .get(
          `${process.env.VUE_APP_API}/vermwechselintern/confirmRead?readValue=${this.popupSaveParams}`,
          { defaultSpinner: false }
        );
    },
    changeVermittler() {
      if (this.selectedRows.length != 1) {
        return
      }
      this.targetMakler = this.selectedRows[0].userId;
      this.loading.change = true;

      const payload = {
        maklernr: this.targetMakler,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/vermwechselintern/doWechsel`,
          payload,
          config
        )
        .then((response) => {
          if (response && response.data) {
            this.handleResponse(response.data);
          }
        })
        .catch(() => (this.loading.change = false));
    },
    handleResponse(responseData) {
      this.loading.change = false;

      if (responseData.versandFailedMessage) {
        this.versandFailedMessage = responseData.versandFailedMessage;
        this.$refs.versandFailedModal.open();
      } else {
        this.$confirmModal({
          title: "Vermittlerwechsel durchgeführt",
          message:
            "<p>Der Vermittlerwechsel wurde durchgeführt. Das Ergebnis wird als PDF-Datei geöffnet.</p> Sie werden nun auf den Kunden weitergeleitet",
          labelButtonConfirm: "Ok",
          showCancelButton: false,
          showConfirmButton: true,
        }).finally(() => {
          this.loading.change = true;
          viewDocument({
            data: responseData.dataUebertrag,
            filename: responseData.filename,
            contentType: "application/pdf",
          });
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
            customerId: responseData.kundeNeu,
            skipNewTab: true,
          });
        });
      }
    },
    createTicket() {
      this.loading.init = true;

      const payload = {
        maklernr: this.targetMakler,
        fehlerText: this.versandFailedMessage,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/vermwechselintern/createTicket`,
          payload,
          config
        )
        .then(() => {
          this.$store.dispatch(
            LOG_TYPES.ACTIONS.ADD_MESSAGE,
            buildMessage("Das Ticket wurde erstellt.", "primary")
          );
          this.$router.push("/home");
        })
        .catch(() => (this.loading.init = false));
    },
    handleSearch(params) {
      this.searchParams = params;
      this.loadPage(0, true);
    },
    async loadPage(pageId, force = false, filterType = null) {
      try {
        this.pageId = pageId;
        if (!force && this.brokersData.pages && this.brokersData.pages[pageId]) {
          return;
        }
        let searchParams = JSON.parse(JSON.stringify(this.searchParams));
        searchParams.vermittlerAktiv = true;
        searchParams.keineMaklerPassiv = true;
        const params = {
          ...searchParams,
          pageId,
          maxCount: this.tablePageSize > 0 ? this.tablePageSize : 20,
        };

        if (this.brokerSearchSort?.key) {
          params.sort = this.brokerSearchSort.key;
          params.sortDirection = this.brokerSearchSort.sortDirection || "asc";
        } else {
          delete params.sort;
          delete params.sortDirection;
        }
        if (filterType) {
          params.filterType = filterType;
        }
        params.sendKeys = ['brokerId','surname','firstName','firma','vermittlerAktiv','keineMaklerPassiv','sort', 'sortDirection'];
        this.loading.filter = true;
        await this.$store.dispatch( CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_BROKER, params );
      } finally {
        this.loading.filter = false;
      }
    },
    onBrokerSearchResultSort(brokerSearchSort) {
      this.brokerSearchSort = brokerSearchSort;
      this.loadPage(0, true);
    },
    selectUserId(brokerId) {
      if (this.$route.query.bipro) {
        if (this.userId == brokerId) {
          this.$router.push("/" + this.$route.query.nextUrl);
          return;
        }
      }
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
        brokerId,
        path: this.$route.query.nextUrl,
      });
    },
  },
};
</script>