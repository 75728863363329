var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Nicht berücksichtigte E-Mail-Adressen",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            _vm.hasNew = true
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _vm.emailList.length
          ? _c(
              "div",
              _vm._l(_vm.emailList, function(email, index) {
                return _c("div", { key: index, staticClass: "mb-4" }, [
                  _c("div", { staticClass: "email-items__container" }, [
                    _c(
                      "div",
                      { staticClass: "email-items__input" },
                      [
                        _c("InputField", {
                          attrs: {
                            value: email.label,
                            id: "email" + index,
                            validateUntouched: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateValue(email, $event)
                            },
                            change: function($event) {
                              return _vm.save(email, index, $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "email-items__button" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isLink: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeEmail(email)
                              }
                            }
                          },
                          [_c("PhTrashSimple", { attrs: { size: 16 } })],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          : _c("div", [_vm._v(" Keine Daten. ")]),
        _vm.hasNew
          ? _c("div", { staticClass: "email-items__container" }, [
              _c(
                "div",
                { staticClass: "email-items__input" },
                [
                  _c("InputField", {
                    attrs: { validateUntouched: "" },
                    on: { change: _vm.saveNewAddress },
                    model: {
                      value: _vm.newAdresse,
                      callback: function($$v) {
                        _vm.newAdresse = $$v
                      },
                      expression: "newAdresse"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "email-items__button" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isLink: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeEmail()
                        }
                      }
                    },
                    [_c("PhTrashSimple", { attrs: { size: 16 } })],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }