var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _vm.isNewAntrag || _vm.isFondsfinder
        ? _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: "eFonds24",
              subtitle: "eZeichnung",
              actions: _vm.headerActions
            },
            on: {
              "action-UPLOAD": function($event) {
                return _vm.doUpload()
              }
            }
          })
        : !_vm.isNewAntrag
        ? _c("AntragHeaderTitleNavigation", {
            attrs: {
              title: "eFonds24",
              subtitle: "eZeichnung",
              antragId: _vm.id,
              antrag: _vm.antrag,
              antragData: _vm.data,
              disabled:
                !_vm.data ||
                (!_vm.data.antragsdatenId && !_vm.data.PARAMETER_PROTOKOLL_ID),
              currentStepAdditionalButtons: _vm.buttons
            }
          })
        : _vm._e(),
      !_vm.isFondsfinder
        ? _c("div", [
            _c("div", { staticClass: "row my-0" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("AntragSidetext", {
                    attrs: {
                      antragId: _vm.id,
                      antragData: _vm.data,
                      warnings: _vm.warnings
                    }
                  })
                ],
                1
              )
            ]),
            _vm.hasAccessRights
              ? _c(
                  "div",
                  { staticClass: "box__container p-4" },
                  [
                    _vm.isNewAntrag
                      ? _c("EntitySelector", {
                          attrs: {
                            label: "Zeichner",
                            isComponentHalfSize: "",
                            isPersonPicker: "",
                            values: _vm.persons,
                            hideNewButton: "",
                            disabled: !_vm.isNewAntrag
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("input", $event)
                            },
                            change: function($event) {
                              return _vm.updateStoreData({ personId: $event })
                            },
                            edit: function($event) {
                              return _vm.editPerson(_vm.personId)
                            }
                          },
                          model: {
                            value: _vm.personId,
                            callback: function($$v) {
                              _vm.personId = $$v
                            },
                            expression: "personId"
                          }
                        })
                      : _c("div", [
                          _vm._v(
                            "Zeichner: " +
                              _vm._s(_vm.data && _vm.data.ZeichnerVorname) +
                              " " +
                              _vm._s(_vm.data && _vm.data.ZeichnerNachname)
                          )
                        ]),
                    !_vm.guid
                      ? _c("div", { staticClass: "color-danger" }, [
                          _vm._v(
                            " Die Person ist bei der eFonds-Plattform noch nicht angelegt. Bitte eFonds-Stammdaten anlegen. "
                          )
                        ])
                      : _vm.stammdatenChanged
                      ? _c("div", { staticClass: "color-danger" }, [
                          _vm._v(
                            " Persönliche Daten wurden geändert. Bitte eFonds-Stammdaten anpassen. "
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.openStammdaten()
                              }
                            }
                          },
                          [_c("span", [_vm._v("eFonds-Stammdaten anzeigen")])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasAccessRights
              ? _c(
                  "div",
                  { staticClass: "box__container p-4" },
                  [
                    _c("WertpapierAuswahlUnique", {
                      attrs: {
                        data: _vm.data,
                        antragId: _vm.id,
                        config: _vm.config,
                        hasFactsheet: true,
                        id: "KAUF",
                        label: "Wertpapierauswahl",
                        disabled: !_vm.isNewAntrag
                      },
                      on: {
                        openFondsfinder: function($event) {
                          _vm.isFondsfinder = true
                        },
                        removePosition: function($event) {
                          return _vm.removePosition($event)
                        }
                      }
                    }),
                    _c("div", [
                      _vm._v("FondsInfos: "),
                      _vm.isin && _vm.isin.length
                        ? _c("a", { on: { click: _vm.openInfoInEfonds } }, [
                            _vm._v(
                              "Wertpapier im " +
                                _vm._s(_vm.efondsName) +
                                " System anzeigen"
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", [
                      _vm._v("Einreichung: " + _vm._s(_vm.einreichungStr))
                    ]),
                    !_vm.isNewAntrag
                      ? _c("div", [
                          _vm._v(" Status: "),
                          _vm.data && _vm.data.checkOk
                            ? _c(
                                "span",
                                [
                                  _c("PhCheck", {
                                    staticClass: "color-success mr-1",
                                    attrs: { size: 22 }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c("PhWarning", {
                                    staticClass: "color-danger mr-1",
                                    attrs: { size: 22 }
                                  })
                                ],
                                1
                              ),
                          _vm.data && _vm.data.editButton
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "fc-tooltip",
                                        rawName: "v-fc-tooltip",
                                        value: _vm.statusTooltip,
                                        expression: "statusTooltip"
                                      }
                                    ],
                                    on: { click: _vm.editEzeichnung }
                                  },
                                  [_vm._v(_vm._s(_vm.data && _vm.data.status))]
                                )
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.data && _vm.data.status))
                              ])
                        ])
                      : _vm._e(),
                    _vm.isNewAntrag
                      ? _c("div", { staticClass: "d-flex my-3" }, [
                          _vm._v(" Minimum: " + _vm._s(_vm.minimalStr) + " ")
                        ])
                      : _vm._e(),
                    _c("InputField", {
                      attrs: {
                        label: "Zeichnungssumme",
                        type: "currency",
                        validateUntouched: "",
                        foreign_currency: _vm.getCurrency || "EUR",
                        isComponentHalfSize: "",
                        disabled: !_vm.isNewAntrag || !_vm.isin
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateStoreData({ betrag: $event })
                        }
                      },
                      model: {
                        value: _vm.betrag,
                        callback: function($$v) {
                          _vm.betrag = $$v
                        },
                        expression: "betrag"
                      }
                    }),
                    !_vm.isNewAntrag
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "font-bold" }, [
                              _vm._v("Vorbelegung und Ort der Unterschrift")
                            ]),
                            _c("InputField", {
                              attrs: {
                                label: "Ort",
                                isComponentHalfSize: "",
                                disabled: _vm.data && _vm.data.signed
                              },
                              model: {
                                value: _vm.ort,
                                callback: function($$v) {
                                  _vm.ort = $$v
                                },
                                expression: "ort"
                              }
                            }),
                            _c("DatePickerField", {
                              attrs: {
                                dateFormat: "DD.MM.YYYY",
                                label: "Datum",
                                isComponentHalfSize: "",
                                isValueAsString: "",
                                disabled: _vm.data && _vm.data.signed
                              },
                              model: {
                                value: _vm.datum,
                                callback: function($$v) {
                                  _vm.datum = $$v
                                },
                                expression: "datum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "BaseModal",
                      {
                        ref: "hatFehler",
                        attrs: {
                          labelButtonConfirm: "Ok",
                          showCancelButton: false,
                          showCloseButton: false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modalTitle",
                              fn: function() {
                                return [
                                  _c("i", {
                                    staticClass:
                                      "las la-exclamation-triangle mr-2",
                                    staticStyle: { color: "orangered" }
                                  }),
                                  _vm._v(" Fehler ")
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1372008125
                        )
                      },
                      [_c("div", [_vm._v(_vm._s(_vm.errorStr))])]
                    ),
                    _c(
                      "BaseModal",
                      {
                        ref: "stammdatenChangedModal",
                        attrs: {
                          labelButtonConfirm: "eZeichnung starten",
                          labelButtonCancel: "Abbrechen"
                        },
                        on: {
                          onConfirmButton: function($event) {
                            return _vm.openEFondsZeichnung()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modalTitle",
                              fn: function() {
                                return [
                                  _c("PhInfo", { attrs: { size: "24" } }),
                                  _vm._v(" Information ")
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4007401970
                        )
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "Die Stammdaten zwischen eFonds und smartMSC weichen ab."
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm.isLoadingAntrag
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("GhostLoading", {
                      staticClass: "forms__input--half-size",
                      attrs: { type: "input" }
                    }),
                    _c("GhostLoading", {
                      staticClass: "forms__input--half-size",
                      attrs: { type: "title" }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "box__container" }, [
                  _vm._v("Fehlende Berechtigungen für diesen Bereich")
                ]),
            _vm.hasAccessRights && !_vm.isNewAntrag
              ? _c("div", { staticClass: "box__container p-4" }, [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v("Formulardaten")
                  ]),
                  _vm.rows && _vm.rows.length
                    ? _c(
                        "div",
                        [
                          _c("Table", {
                            attrs: {
                              headers: _vm.headers,
                              rows: _vm.rows,
                              rowsPerPage: 100,
                              hidePagination: "",
                              rowId: "ezeichnung"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", [_vm._v("Keine Daten")])
                ])
              : _vm._e()
          ])
        : _c(
            "div",
            [
              _c("FondsFinderSuchMaske", {
                attrs: { lagerstelleId: _vm.lagerstelle, isBeteiligung: true }
              }),
              _c("FondsFinderPositionsList", {
                ref: "fondsfinderResult",
                attrs: {
                  lagId: _vm.lagerstelle,
                  goBackEnabled: true,
                  singleSelectionProp: true,
                  hasDefaultButton: true
                },
                on: {
                  goBack: function($event) {
                    _vm.isFondsfinder = false
                  },
                  getSelectedOutcome: function($event) {
                    return _vm.setIsin($event)
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }