<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      subtitle="Beratungsdokumentation Versicherungen Textbausteine" 
      :actions="headerActions"
      @action-ADD="openAddMaklerTexteModal()" 
      @action-STRUKTUR-RELEASE="setSichtbarStrukturForAll(true)" 
      @action-STRUKTUR-LOCK="setSichtbarStrukturForAll(false)" 
    />

    <div class="box__container">
      <div class="box__title">Textbaustein</div>

      <ComboBox 
        :value="selectedBereich.id" :values="bereichValues" isComponentHalfSize 
        @change="selectBereich($event)" 
      />
    </div>

    <MaklerTexteTable v-if="selectedBereich.id" :key="selectedBereich.id" />

    <AddMaklerTexteModal ref="addMaklerTexteModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MAKLER_TEXTE_TYPES from '@/store/maklerTexte/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import MaklerTexteTable from './components/MaklerTexteTable.vue';
import AddMaklerTexteModal from './components/AddMaklerTexteModal.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      maklerTextBereich: MAKLER_TEXTE_TYPES.GETTERS.MAKLER_TEXT_BEREICH,
      selectedBereich: MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH,
      listByBereich: MAKLER_TEXTE_TYPES.GETTERS.LIST_BY_BEREICH,
    }),
    bereichValues() {
      return [
        ...this.maklerTextBereich?.map(bereich => ({
          value: bereich.id,
          label: bereich.beschreibungKurz,
        })) || [],
      ];
    },
    hasData() {
      return this.listByBereich(this.selectedBereich.id)?.length > 0;
    },
    headerActions() {
      const { selectedBereich, hasData, saving, } = this;
      return [
        PageHeaderSimpleAction('ADD', 'Neuen Eintrag anlegen').withDisabled(() => !selectedBereich?.id || saving),
        PageHeaderSimpleAction('STRUKTUR-RELEASE', 'Alle für Unterstruktur freigeben').withDisabled(() => !selectedBereich?.id || !hasData || saving),
        PageHeaderSimpleAction('STRUKTUR-LOCK', 'Alle für Unterstruktur sperren').withDisabled(() => !selectedBereich?.id || !hasData || saving),
      ];
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_MAKLER_TEXT_BEREICH);
      this.selectBereich(this.bereichValues?.[0]?.value);
    },
    selectBereich(bereich) {
      this.$store.commit(MAKLER_TEXTE_TYPES.MUTATIONS.SET_SELECTED_BEREICH, bereich);
    },
    openAddMaklerTexteModal() {
      this.$refs.addMaklerTexteModal.open();
    },
    async setSichtbarStrukturForAll(freigabe) {
      try {
        this.saving = true;
        this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.SAVE_SICHTBAR_STRUKTUR_FOR_ALL, {
          freigabe,
        });
      } finally {
        this.saving = false;
      }
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    this.$store.commit(MAKLER_TEXTE_TYPES.MUTATIONS.RESET_STATE);
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ComboBox,
    MaklerTexteTable,
    AddMaklerTexteModal,
  },
}
</script>
