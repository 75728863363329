<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" tid="3c886523-0f75-4a62-bb04-2bd422c441b8" />

    <DashboardPanel 
      id="f4cfdd24-f99c-4a66-bfc0-94faa9724d22"
      title="Kunden-Überblick"
      :data="dashboardData"
      ignoreUserLevelConfig
      @executeAction="handleAction"
    >

      <template #Investment>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isInvestment">
              <div class="label-value--list" v-if="!$isSmallScreen">
                <div class="label-value--item">
                  <div class="label-value--item-label text-left">Bestand</div>
                  <div class="label-value--item-value text-right"><CurrencyLabel :value="info.openFunds"/></div>
                </div>
                <div class="label-value--item"  v-if="!isFA">
                  <div class="label-value--item-label text-left">
                    <router-link to="/home/freistellungsauftrag">Freistellungsauftrag</router-link>
                  </div>
                  <div class="label-value--item-value text-right">
                    <span class="d-flex">
                      <CurrencyLabel :value="info.steckBreifZusatz.freistellungAuftrag"/>&nbsp;/&nbsp;<CurrencyLabel :value="info.steckBreifZusatz.zinsen"/>
                    </span>
                  </div>
                </div>
                <div class="label-value--item align-items-end">
                  <div class="tbl tbl-full">
                    <div class="tbl-row">
                      <div class="tbl-cell tbl-cell-max text-left text-small"></div>
                      <div class="tbl-cell text-right text-small">
                        <span class="hide-on-small-screen">ø Beiträge / Monat</span>
                        <span class="hide-on-large-screen">ø Beiträge / M</span>
                      </div>
                      <div class="tbl-cell text-right text-small">
                        <span class="hide-on-small-screen">Beiträge / Jahr</span>
                        <span class="hide-on-large-screen">Beiträge / J</span>
                      </div>
                    </div>
                    <div class="tbl-row">
                      <div class="tbl-cell break-word text-left">Sparpläne</div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="(info.steckBreifZusatz.sparplanJahrlich.Sparplan/12)"/></div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="info.steckBreifZusatz.sparplanJahrlich.Sparplan"/></div>
                    </div>
                    <div class="tbl-row">
                      <div class="tbl-cell break-word text-left">Entnahmepläne</div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="(info.steckBreifZusatz.sparplanJahrlich.Entnahmeplan/12)"/></div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="info.steckBreifZusatz.sparplanJahrlich.Entnahmeplan"/></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <FlexibleList :rows="mobileInvestmentFlexibleList" linkInFirstRow @onRowClick="handleMobileInvestmentFlexibleListAction">
                  <template #title="row">{{row.title}}</template>

                  <template #value="row">
                    <CurrencyLabel v-if="row.title == 'Bestand'" :value="row.value" class="mr-4"/>
                    <div v-else class="d-flex">
                      <CurrencyLabel :value="row.value.freistellungAuftrag"/>&nbsp;/&nbsp;<CurrencyLabel :value="row.value.zinsen"/>
                    </div>
                  </template>
                </FlexibleList>
                <br/>
                <Table
                  tableId="1b95d8b8-0bf6-48f5-808d-3aa528e46297"
                  hidePagination
                  :mobileConfig="mobileInvestmentTable.mobileConfig"
                  :headers="mobileInvestmentTable.headers"
                  :rows="mobileInvestmentTable.rows"
                />
              </div>
            </template>
            <NoData v-else content="Keine Anlagen vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/vermogensubersicht/depot">Investment anzeigen</router-link>
            </div>
          </div>
        </div>
      </template>
      <template #Versicherungen>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isInsurances">
              <div class="tbl tbl-full" v-if="!$isSmallScreen">
                <div class="tbl-row" v-if="!loading">
                  <div class="tbl-cell tbl-cell-max text-left text-small"></div>
                  <div class="tbl-cell text-right text-small">
                    <span class="hide-on-small-screen">ø Beiträge / Monat</span>
                    <span class="hide-on-large-screen">ø Beiträge / M</span>
                  </div>
                  <div class="tbl-cell text-right text-small">
                    <span class="hide-on-small-screen">Beiträge / Jahr</span>
                    <span class="hide-on-large-screen">Beiträge / J</span>
                  </div>
                </div>
                <template v-if="!loading">
                  <template v-for="(item, i) of insurances">
                    <div class="tbl-row" :key="'in1'+i">
                      <div class="tbl-cell text-left break-word">{{item.label}}</div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="(item.jahrBeitrag /12)"/></div>
                      <div class="tbl-cell text-right"><CurrencyLabel :value="(item.jahrBeitrag)"/></div>
                    </div>
                    <div class="tbl-row" v-if="item.rueckKaufsWert" :key="'in2'+i">
                      <div class="text-left text-small sub-category-spacer break-word" v-if="item.rueckKaufsWert">
                        <div class="pl-sm-3"><router-link to="/home/vermogensubersicht/versicherungsvermoegen">Rückkaufswerte</router-link></div>
                      </div>
                      <div class="tbl-cell text-right text-small sub-category-spacer"></div>
                      <div class="tbl-cell text-right text-small  sub-category-spacer">{{rueckkaufsWert}}</div>
                    </div>
                    <div class="tbl-row" v-if="item.fondsWert" :key="'in3'+i">
                      <div class="text-left text-small break-word" v-if="item.fondsWert">
                        <div class="pl-sm-3"><router-link to="/home/vermogensubersicht/versicherungsvermoegen">Fondswerte</router-link></div>
                      </div>
                      <div class="tbl-cell text-right text-small"></div>
                      <div class="tbl-cell text-right text-small">{{fondsWert}}</div>
                    </div>
                  </template>
                </template>
                <GhostLoading :style="{ width: '100%' }" width="100%" v-else-if="loading" type="table" />
                <NoData v-else />
              </div>
              <div v-else>
                <Table
                  tableId="56a905a8-ae66-4b14-97e4-fbc0154ff975"
                  hidePagination
                  :mobileConfig="mobileInsuranceTable.mobileConfig"
                  :headers="mobileInsuranceTable.headers"
                  :rows="mobileInsuranceTable.rows"
                  @click-rueckKaufsWert="navigateTo('/home/vermogensubersicht/versicherungsvermoegen')"
                  @click-fondsWert="navigateTo('/home/vermogensubersicht/versicherungsvermoegen')"
                />
              </div>
            </template>
            <NoData v-else content="Keine Versicherungen vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/versicherungen">Versicherungen anzeigen</router-link>
            </div>
          </div>
        </div>
      </template>
      <template #Beteiligungen>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isBeteiligungen">
              <div class="label-value--list">
                <div class="label-value--item">
                  <div class="label-value--item-label">Bestand</div>
                  <div class="label-value--item-value"><CurrencyLabel :value="info.closedFunds"/></div>
                </div>
              </div>
            </template>
            <NoData v-else content="Keine Anlagen vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/vermogensubersicht/beteiligungen">Beteiligungen anzeigen</router-link>
            </div>
          </div>
        </div>
      </template>
      <template #Finanzierungen>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isFinanzierungen">
              <div class="tbl tbl-full" v-if="!$isSmallScreen">
                <div class="tbl-row">
                  <div class="tbl-cell tbl-cell-max text-left text-small"></div>
                  <div class="tbl-cell text-right text-small">
                    <span class="hide-on-small-screen">Beiträge / Jahr</span>
                    <span class="hide-on-large-screen">Beiträge / J</span>
                  </div>
                  <div class="tbl-cell text-right text-small">Summe</div>
                </div>
                <template v-for="(item, i) of info.steckBreifZusatz.kredite">
                  <div class="tbl-row" :key="'fin'+i">
                    <div class="tbl-cell text-left break-word">{{item.kreditform}}</div>
                    <div class="tbl-cell text-right"><CurrencyLabel v-if="item.yearRate" :value="item.yearRate"/></div>
                    <div class="tbl-cell text-right"><CurrencyLabel :value="item.aktWert"/></div>
                  </div>
                </template>
              </div>
              <Table v-else
                tableId="8530795e-c312-4451-942e-83d08585c55e"
                hidePagination
                :mobileConfig="mobileFinanzierungenTable.mobileConfig"
                :headers="mobileFinanzierungenTable.headers"
                :rows="mobileFinanzierungenTable.rows"
              />
            </template>
            <NoData v-else content="Keine Finanzierungen vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/verbindlichkeiten/credit">Finanzierungen anzeigen</router-link>
            </div>
          </div>
        </div>
      </template>
      <template #WeitereVerbindlichkeiten>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isWeitereVerbindlichkeiten">
              <div class="tbl tbl-full" v-if="!$isSmallScreen">
                <div class="tbl-row">
                  <div class="tbl-cell tbl-cell-max text-left text-small"></div>
                  <div class="tbl-cell text-right text-small">Ende</div>
                  <div class="tbl-cell text-right text-small">Summe</div>
                </div>
                <template v-for="(item, i) of info.steckBreifZusatz.weitereVerbindlichkeitenOutput">
                  <div class="tbl-row" :key="'fin'+i">
                    <div class="tbl-cell text-left break-word">
                      <span>{{ item.produktart }} </span>
                      <span v-if="item.produktbez">- {{ item.produktbez }}</span>
                    </div>
                    <div class="tbl-cell text-right">{{ item.ende }}</div>
                    <div class="tbl-cell text-right"><CurrencyLabel :value="item.aktWert"/></div>
                  </div>
                </template>
              </div>
              <Table v-else
                tableId="7934bc38-d9f8-4909-b779-b9a93719e2aa"
                hidePagination
                :mobileConfig="mobileFinanzierungenTable.mobileConfig"
                :headers="mobileFinanzierungenTable.headers"
                :rows="mobileFinanzierungenTable.rows"
              />
            </template>
            <NoData v-else content="Keine Daten vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/verbindlichkeiten/weitere-verbindlichkeiten/search">Weitere Verbindlichkeiten</router-link>
            </div>
          </div>
        </div>
      </template>
      <template #WeiteresVermögen>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <template v-if="isWeiteresVermoegen">
              <div class="tbl tbl-full" v-if="!$isSmallScreen">
                <div class="tbl-row">
                  <div class="tbl-cell tbl-cell-max text-left text-small"></div>
                  <div class="tbl-cell tbl-cell-max text-left text-small">Gesellschaft</div>
                  <div class="tbl-cell text-right text-small">Aktueller Wert</div>
                </div>
                <template v-for="(item, i) of info.steckBreifZusatz.weitereVermoegenOutput">
                  <div class="tbl-row" :key="'fin'+i">
                    <div class="tbl-cell text-left break-word">
                      <span>{{ item.produktart }} </span>
                      <span v-if="item.produktbez">( {{ item.produktbez }} )</span>
                    </div>
                    <div class="tbl-cell text-left"> {{ item.company }}</div>
                    <div class="tbl-cell text-right"><CurrencyLabel :value="item.aktWert"/></div>
                  </div>
                </template>
              </div>
              <Table v-else
                tableId="5023e615-0515-4363-a3c4-06e857ecaf99"
                hidePagination
                :mobileConfig="mobileFinanzierungenTable.mobileConfig"
                :headers="mobileFinanzierungenTable.headers"
                :rows="mobileFinanzierungenTable.rows"
              />
            </template>
            <NoData v-else content="Keine Daten vorhanden" noIcon />
          </div>
          <div class="bottom-row" v-if="!$isSmallScreen">
            <div class="text-right">
              <router-link to="/home/vermogensubersicht/weitere-vermoegen/search">Weitere Vermögen</router-link>
            </div>
          </div>
        </div>
      </template>
    </DashboardPanel>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';

import { mapGetters } from 'vuex'
import validator from '@/mixins/validator';
import { mapNewTableStructure } from '@/store/depotpositions/utils';
import { PhCheck, PhWarning  } from 'phosphor-vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import NoData from '@/components/core/NoData.vue';
import { VIEW_ROLES, BROKER_STRUCTURE_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import Table from '@/components/table2/Table.vue'
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import { parse } from '@/helpers/number-formatter.js'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import MY_GOALS_TYPES from '@/store/myGoals/types';

export default {
  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    NoData,
    Table,
    FlexibleList,
    GhostLoading,
  },
  mixins: [validator],
  validators: {},
  data: function () {
    return {
      showAdditional: false,
      tableData: {
        headers: {},
        records: []
      },
      videoIndentTable: {
        headers: {},
        records: []
      },
      openSignaturesTable: {
        headers: {},
        records: []
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
      goals: MY_GOALS_TYPES.GETTERS.GOALS,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
    }),
    hasImportantBemerkungen() {
      return (this.info?.steckBreifZusatz?.importantBemerkungen || []).length > 0;
    },
    hasInfo() {
      return !!this.info?.customerID;
    },
    columnIndex() {
      if (!this.insuranceTable?.data?.tableHeaders) {
        return {}
      }

      const headers = this.insuranceTable.data.tableHeaders

      const kategorie = this.indexOfRow(headers, 'kategorie');
      const nummer = this.indexOfRow(headers, 'nummer');
      const gesellschaft = this.indexOfRow(headers, 'gesellschaft');
      const zw = this.indexOfRow(headers, 'zw');
      const praemie = this.indexOfRow(headers, 'praemie');
      const fondswert = this.indexOfRow(headers, 'Fondswert');
      const jahr = this.indexOfRow(headers, 'jahr');
      const rueckkaufswert = this.indexOfRow(headers, 'rueckkaufswert');

      return {
        kategorie,
        nummer,
        gesellschaft,
        zw,
        praemie,
        fondswert,
        jahr,
        rueckkaufswert,
      }
    },
    rueckkaufsWert() {
      const result = this.insuranceTable?.data?.records?.
        filter?.(record => parse(record[this.columnIndex.rueckkaufswert]) > 0 && !record[this.columnIndex.fondswert]).
        reduce((accumulator, currentObject) => { return parse(accumulator) + parse(currentObject[this.columnIndex.rueckkaufswert]); }, 0);

        const formatter = new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

      return formatter.format(result);

    },
    fondsWert() {
      const result = this.insuranceTable?.data?.records?.
        filter?.(record => record[this.columnIndex.fondswert]).
        reduce((accumulator, currentObject) => { return parse(accumulator) + parse(currentObject[this.columnIndex.fondswert]); }, 0);

        const formatter = new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

      return formatter.format(result);

    },    
    insurances() {
      const order = ['Gesundheit und Existenz', 'Betrieb', 'Besitz und Eigentum', 'Altersvorsorge'];
      const insurances = Object.values(this.info.steckBreifZusatz.insurances)
      .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
      
      insurances.forEach(insurance => {
        switch(insurance.label){
          case order[0]: insurance.label = 'Gesundheit'; break;
          case order[1]: insurance.label = 'Betrieblich'; break;
          case order[2]: insurance.label = 'Sachversicherungen'; break;
          case order[3]: insurance.label = 'Vorsorge'; break;
        }
        if(insurance.label.toLowerCase() === 'other') {
          insurance.label = 'Sonstige'
        }
      });
      return insurances;
    },
    isInvestment() {
      return this.info.steckBreifZusatz.sparplanJahrlich.Entnahmeplan || this.info.steckBreifZusatz.sparplanJahrlich.Sparplan || this.info.openFunds || this.info.steckBreifZusatz.freistellungAuftrag;
    },
    isInsurances() {
      return this.info.steckBreifZusatz.insurances && Object.values(this.info.steckBreifZusatz.insurances).length;
    },
    isBeteiligungen() {
      return this.info.closedFunds;
    },
    isFinanzierungen() {
      return this.info.steckBreifZusatz.kredite && this.info.steckBreifZusatz.kredite.length && this.info.steckBreifZusatz.kredite.some(v => v.aktWert);
    },
    isWeitereVerbindlichkeiten(){
      return this.info.steckBreifZusatz.weitereVerbindlichkeitenOutput && this.info.steckBreifZusatz.weitereVerbindlichkeitenOutput.length;
    },
    isWeiteresVermoegen(){
      return this.info.steckBreifZusatz.weitereVermoegenOutput && this.info.steckBreifZusatz.weitereVermoegenOutput.length;
    },
    dashboardData() {
      const data = {
        widgets: [
          {
            name: 'CustomerOverview',
            title: 'Kunde',
            component: () => import('@/components/steckbrief/cards/CustomerOverview.vue'),
            loading: () => !this.hasInfo,
          },
          {
            name: 'CustomerInfo',
            title: 'Kunden Details',
            component: () => import('@/components/steckbrief/cards/CustomerInfo.vue'),
            loading: () => !this.hasInfo,
          },
          {
            name: 'CustomerAdditionalPersons',
            title: 'Zusätzliche Personen',
            component: () => import('@/components/steckbrief/cards/CustomerAdditionalPersonsWidget.vue'),
            loading: () => !this.hasInfo,
          },
          {
            name: 'Investment',
            title: 'Investment',
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]),
            loading: () => !this.hasInfo,
          },
          {
            name: 'Versicherungen',
            title: 'Versicherungen',
            visible: () => this.hasAccess('/home/versicherungen'),
            loading: () => !this.hasInfo,
            overrideDefaultAction: true,
          },
          {
            name: 'Beteiligungen',
            title: 'Beteiligungen',
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS]),
            loading: () => !this.hasInfo,
          },
          {
            name: 'Finanzierungen',
            title: 'Finanzierungen',
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN]),
            loading: () => !this.hasInfo,
          },
          {
            name: 'WeitereVerbindlichkeiten',
            title: 'Weitere Verbindlichkeiten',
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN]),
            loading: () => !this.hasInfo,
          },
          {
            name: 'WeiteresVermögen',
            title: 'Weiteres Vermögen',
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN]),
            loading: () => !this.hasInfo,
          },
          {
            name: 'CustomerInterneBemerkung',
            title: 'Anmerkungen (für den Kunden nicht sichtbar)',
            component: () => import('@/components/steckbrief/cards/CustomerInterneBemerkung.vue'),
            loading: () => !this.hasInfo,
            isNavigable: false
          },
          {
            name: 'CustomerKontaktdaten',
            title: 'Kontaktdaten',
            component: () => import('@/components/steckbrief/cards/CustomerKontaktdaten.vue'),
            loading: () => !this.hasInfo,
          },
          {
            name: 'CustomerBankkonten',
            title: 'Bankkonten',
            overrideDefaultAction: true,
            component: () => import('@/components/steckbrief/cards/CustomerBankkonten.vue'),
            loading: () => !this.hasInfo,
          },
          {
            name: 'Courtageverdienst',
            title: 'Courtageverdienst der letzten 12 Monate',
            component: () => import('@/components/steckbrief/cards/Courtageverdienst.vue'),
            loading: () => !this.hasInfo,
            visible: () => this.hasRoles([
              [EMPLOYEE_ROLES.ZUGRIFF_COURTAGEHISTORIE, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
              [EMPLOYEE_ROLES.ZUGRIFF_COURTAGEHISTORIE, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
            ]),
            isNavigable: false
          },
          {
            name: 'DepotServicebuehrRabatt',
            title: 'Servicegebühr & Depotrabatt',
            component: () => import('@/components/steckbrief/cards/DepotServicegebuehrRabatt.vue'),
            visible: () => this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]),
            loading: () => !this.hasInfo,
            isNavigable: false
          },
        ],
      };
      if (this.myGoals?.length) {
        const goals = this.myGoals.map(goal => ({
          name: `goal${goal.id}`,
          title: `Mein Ziel: ${goal.title} ${goal.endDate && ('zum ' + goal.endDate) || ''}`,
          component: () => import('@/components/steckbrief/cards/MyGoalsCard.vue'),
          props: {
            idProp: goal.id,
          },
          isNavigable: false
          }));
        data.widgets.push(...goals);
      }
      return data;
    },
    mobileInvestmentFlexibleList() {
      return [
        {
          title: 'Bestand',
          value: this.info.openFunds,
          isClickable: false
        },
        {
          title: 'Freistellungsauftrag',
          value: {freistellungAuftrag: this.info.steckBreifZusatz.freistellungAuftrag, zinsen: this.info.steckBreifZusatz.zinsen},
          isClickable: true
        }
      ];
    },
    mobileInvestmentTable() {
      const headers = {
        center: [],
      };

      headers.center.push(TextColumn("kategorie", "Kategorie"))
      headers.center.push(CurrencyColumn("beitragMonat", "ø Beiträge / Monat"))
      headers.center.push(CurrencyColumn("beitrageJahr", "Beiträge / Jahr"))

      const mobileConfig = {
        title: 'kategorie',
        headers: ['beitragMonat', 'beitrageJahr'],
        disableClickRow: true,
      }

      const rows = [
        {
          kategorie: 'Sparpläne',
          beitragMonat: this.info.steckBreifZusatz.sparplanJahrlich.Sparplan/12,
          beitrageJahr: this.info.steckBreifZusatz.sparplanJahrlich.Sparplan
        },
        {
          kategorie: 'Entnahmepläne',
          beitragMonat: this.info.steckBreifZusatz.sparplanJahrlich.Entnahmeplan/12,
          beitrageJahr: this.info.steckBreifZusatz.sparplanJahrlich.Entnahmeplan
        }
      ];

      return {mobileConfig, headers, rows};
    },
    mobileInsuranceTable() {
      const headers = {
        center: [],
      };

      headers.center.push(TextColumn("kategorie", "Kategorie"))
      headers.center.push(CurrencyColumn("beitragMonat", "ø Beiträge / Monat"))
      headers.center.push(CurrencyColumn("beitrageJahr", "Beiträge / Jahr"))
      headers.center.push(CurrencyColumn("rueckKaufsWert", "Rückkaufswerte").makeConditionalLink('rueckKaufsWert'))
      headers.center.push(CurrencyColumn("fondsWert", "FondsWerte").makeConditionalLink('fondsWert'))

      const mobileConfig = {
        title: 'kategorie',
        headers: ['beitragMonat', 'beitrageJahr', 'rueckKaufsWert','fondsWert'],
      }

      const rows = this.insurances.map(insurance => {
        const row = {
          kategorie: insurance.label,
          beitragMonat: insurance.jahrBeitrag /12,
          beitrageJahr: insurance.jahrBeitrag,
          rueckKaufsWert: insurance.rueckKaufsWert,
          fondsWert: insurance.fondsWert,
        }

        return row
      });
      
      return {mobileConfig, headers, rows};
    },
    myGoals() {
      return this.goals || [];
    },
    mobileFinanzierungenTable() {
      const headers = {
        center: [],
      };

      headers.center.push(TextColumn("kreditform", 'Kreditform'))
      headers.center.push(CurrencyColumn("beitrageJahr", "Beiträge / Jahr"))
      headers.center.push(CurrencyColumn("summe", "Summe"))

      const mobileConfig = {
        title: 'kreditform',
        headers: ['beitrageJahr', 'summe'],
        disableClickRow: true,
      }

      const rows = this.info.steckBreifZusatz.kredite
        .filter(kredite => kredite.yearRate || kredite.aktWert)
        .map(kredite => {
          return {
            kreditform: kredite.kreditform,
            beitrageJahr: kredite.yearRate,
            summe: kredite.aktWert,
          }
        });
      
      return {mobileConfig, headers, rows};
    },
  },
	mounted() {
    this.loadSteckbriefData();
  },

  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zum Kunden-Überblick', 
      to,
      from,
    });

    next()
  },

  methods: {
    async loadSteckbriefData() {
      try {
        this.loading = true;

        await this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_STECKBRIEF_CUSTOMER_INFO);
        await this.getInsuranceTable();
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
      } catch (error) {
        // empty block
      } finally {
        this.loading = false;
      }
    },
    async getInsuranceTable() {
      try {
        if (this.insuranceTable?.data?.records?.length) {
          return;
        }
        this.loading = true;
        const id = 'ALLE_DEPOTS_ANZEIGEN';
        await this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE, {id});
      } finally {
        this.loading = false;
      }
    },  
    indexOfRow(items, name) {
      return items.findIndex(item => item.key === name);
    },
    getOpenSignaturesTable(data) {
      const tableData = {
        headers: {
          name: {
            label: 'Name',
            key: 'name',
            dataType: 'String',
            sortable: false,
            filterable: false,
            sum: false,
            visible: true,
            fixed: true,
          },
        },
        records: []
      }
      const records = [];
      data.offeneUnterschriften.map(offeneUnterschriften => {
        offeneUnterschriften.parameters.map(p => {
          tableData.headers[p.label] = {
            label: p.label,
            key: p.label,
            dataType: p.type === 'TEXT' ? 'String' : 'Image',
            sortable: false,
            filterable: false,
            sum: false,
            visible: true,
            fixed: true,
          };
        });
      });
      data.offeneUnterschriften.map(offeneUnterschriften => {
        const paramsByKey = offeneUnterschriften.parameters.map(p => ({[p.label]: p.value})).reduce((acc, curr) => ({...acc, ...curr}), {});
        records.push({
          name: offeneUnterschriften.name,
          ...Object.values(tableData.headers).map((h) => ({[h.label]: paramsByKey[h.key] || ''})).reduce((acc, curr) => ({...acc, ...curr}), {})
        });
      });
      return {
        ...tableData,
        records
      };
    },
    mapNewTableStructure(table) {
      return mapNewTableStructure(table);
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    handleAction(event) {
      if(!event) {
        return
      }
      if(event?.action?.action === 'BEARBEITEN_APP') {
        if(event?.action?.widget?.name === 'CustomerKontaktdaten') {
          this.navigateTo('/persoenlichedaten/customer-data/steps/kommunikationsdaten');
        }
        if(event?.action?.widget?.name === 'CustomerInfo') {
          this.navigateTo('/persoenlichedaten/customer-data');
        }
        if(event?.action?.widget?.name === 'Investment') {
          this.navigateTo('/home/vermogensubersicht/depot');
        }
        if(event?.action?.widget?.name === 'Versicherungen') {
          this.navigateTo('/home/versicherungen');
        }
        if(event?.action?.widget?.name === 'Beteiligungen') {
          this.navigateTo('/home/vermogensubersicht/beteiligungen');
        }
        if(event?.action?.widget?.name === 'Finanzierungen') {
          this.navigateTo('/home/verbindlichkeiten/credit');
        }
        if(event?.action?.widget?.name === 'CustomerBankkonten') {
          this.navigateTo('/home/account');
        }
      }
    },
    handleMobileInvestmentFlexibleListAction(event) {
      if(event && event.row?.title === 'Freistellungsauftrag') {
        this.navigateTo('/home/freistellungsauftrag');
        event?.event?.stopPropagation();
      }
    },
  },

  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' € ';
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // + '%';
    },
    percentage(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' % ';
    },
  },
}
</script>

<style src='./steckbrief.scss' lang='scss' scoped></style>
