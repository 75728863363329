<template>
    <div class="table-container">
        <template v-if="!$isSmallScreen || !mobileConfig">
            <SortableTable
                ref="sortableTable"
                :cardView="cardView"
                :headersSize="headersSize"
                :showWeiterLink="showWeiterLink"
                :tableId="tableId"
                :title="title"
                :headers="headerMap"
                :rows="rowsWithId"
                :enableToggleHorizontalScrollingConfig="enableToggleHorizontalScrollingConfig"
                :scrollHorizontally="scrollHorizontally"
                :pageSize="pageSize"
                :page="pageIndex"
                :exportConfig="exportConfig"
                :maxHeaderLines="maxHeaderLines"
                :headerActions="headerActions"
                :sortableRows="sortableRows"
                :showColumnsConfigEvenNoTitle="showColumnsConfigEvenNoTitle"
                :thHoverDisabled="thHoverDisabled"
                :noDataIcon="noDataIcon"
                :noDataContent="noDataContent"
                :valign="valign"
                @clickCell="handleClick"
                @action="handleAction"
                @files="handleFile"
                @headerAction="handleHeaderAction"
                @configColumn="columnConfigChanged"
                @orderChanged="$emit('orderChanged', $event)"
                @onNavigate="$emit('onNavigate', $event)"
            >

                <template v-slot:customTableTitle>
                        <slot name="customTableTitle"></slot>
                </template>

                <template v-slot:dropdown_menu v-if="menuRowsPerPage.length && !ignoreTablePageSize">
                    <SelectRowPerPage :pageSize="pageSize" :rowSteps="menuRowsPerPage" @tablePageSize="onPageSizeChange" />
                </template>
                <template v-slot:header___SELECT__>
                    <InputCheckBoxItem
                        :value="areAllSelected"
                        :indeterminate="areOnlySomeSelected"
                        :disabled="areAllHiddenDisabled"
                        :tid="_generateTidFromString(tableId + ':__SELECT__')"
                        @input="onSelectAll"
                        style="margin-left: 3px"
                    />
                </template>
                <template v-for="{key, rowElement} in headersFlat" v-slot:[key]="{row, id}">
                    <InputCheckBoxItem
                        v-if="!rowElement && key == '__SELECT__' && id != null && !row.selectedHidden"
                        :key="key"
                        :disabled="row.selectedDisabled"
                        :value="isSelected[id]"
                        :tid="_generateTidFromString(tableId + ':__SELECT__:' + id)"
                        @input="onSelect(id)"
                    />
                    <slot v-else-if="!rowElement" :name="key" v-bind="row"/>
                </template>
                <template v-for="header in headersFlatLabelNull" v-slot:[header.headerKey]>
                    <slot :name="header.headerKey" v-bind="header"/>
                </template>
                <template v-for="hAction in headerActionsSlot" v-slot:[hAction.actionKey]>
                    <slot :name="hAction.actionKey" v-bind="hAction"/>
                </template>
            </SortableTable>
            <div v-if="!hidePagination && displayedRowCount > 0" class="table-pagination">
                <div class="table-pagination--left">
                    <PageRowCount :displayedItems="displayedRowCount" :totalRows="rows.length"/>
                    <SelectRowPerPage v-if="!ignoreTablePageSize" short :pageSize="pageSize" :rowSteps="menuRowsPerPage" @tablePageSize="onPageSizeChange" />
                </div>
                <div v-if="displayedRowCount < rows.length" class="table-pagination--right">
                    <Pagination 
                        :totalPages="pageCount"
                        :currentPage="pageIndex"
                        @pagechanged="onPageChange"
                    />
                </div>
            </div>
        </template>

        <template v-else>
            <MobileTable
                ref="sortableTable"
                :tableId="tableId"
                :rowId="rowId"
                :title="title"
                :headers="headerMap"
                :rows="rowsWithId"
                :titleActions="headerActions"
                :config="mobileConfig"
                :selected="selected"
                :noDataIcon="noDataIcon"
                :noDataContent="noDataContent"
                v-on="$listeners['clickRow'] ? { clickRow: (event) => $emit('clickRow', event) } : {}"
                @selected="$emit('selected', $event)"
                @clickCell="handleClick"
                @action="handleAction"
                @files="handleFile"
                @headerAction="handleHeaderAction"
                @configColumn="columnConfigChanged"
                @orderChanged="$emit('orderChanged', $event)"
            >
                <template v-for="{key, rowElement} in headersFlat" #[key]="{row, mobileTableContext}">
                    <slot v-if="!rowElement && key !== '__SELECT__'" :name="key" v-bind="row" :mobileTableContext="mobileTableContext"/>
                </template>
                <template v-for="header in headersFlatLabelNull" #[header.headerKey]>
                    <slot :name="header.headerKey" v-bind="header"/>
                </template>
            </MobileTable>
        </template>
    </div>
</template>
<script>
import tableMixin from './table-mixin.js';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import MobileTable from "@/components/table2/MobileTable.vue";

import { mapGetters } from 'vuex';

export default {
    mixins: [tableMixin],
    components: {
      MobileTable,
    },
    model: {
        prop: 'selected',
        event: 'selected',
    },
    props: {
        tableId: {
            // tableId must be unique. This Id is being used to save the column config into backend
            // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
            type: String,
        },
        title: {
            type: String,
            default: "",
        },
        headers: { 
            type: Object,
            default: () => {},
            required: true,
        },
        rows: { 
            type: Array,
            default: () => [],
            required: true,
        },
        rowId: { 
            type: String,
            default: "id",
        },
        // how many rows should be displayed per page
        // if set to 0, all rows will be displayed on a single page and the pagination is hidden
        rowsPerPage: {
            type: Number,
            default: 0,
        },
        // if set to true, it's possible to drag & drop files into rows.
        // those files will be emitted as list with the "files" event (@files)
        dragnDropFilesOnRow: {
            type: Boolean,
            default: false,
        },
        hidePagination: {
            type: Boolean,
            default: false,
        },
        exportConfig: {
            type: Object,
            default: () => null,
        },
        // if set, checkboxes will be shown next to each row contained in the array.
        // when any checkbox is clicked, the new list of selected rows will be emitted
        // using the "selected" event. You can use v-model to model the list of selected rows.
        selected: {
            type: Array,
            default: () => null,
        },
        scrollHorizontally: {
            type: Boolean,
            default: false,
        },
        // don't set cardView and scrollHorizontally to true at the same time
        cardView: {
            type: Boolean,
            default: false,
        },
        // This property is only used when the "cardView" property is true
        // It will be ignored if headersSize <= 0
        headersSize: {
            type: Number,
            default: 0,
        },     
        showWeiterLink: {
            type: Boolean,
            default: false,
        },           
        maxHeaderLines: {
            type: Number,
            default: 1,
        },
        enableToggleHorizontalScrollingConfig: {
            type: Boolean,
            default: false,
        },
        headerActions: {
            type: Array,
            default: () => [],
        },
        tableRowsPerPage: {
            type: Array,
            default: null
        },
        ignoreTablePageSize: {
            type: Boolean,
            default: false,
        },
        sortableRows: {
            type: Boolean,
            default: false,
        },
        showColumnsConfigEvenNoTitle: {
            type: Boolean,
            default: false,
        },
        thHoverDisabled: {
            type: Boolean,
            default: false,
        },
        noDataIcon: {
            type: Boolean,
            default: false,
        },
        noDataContent: {
            type: String,
        },
        mobileConfig: {
            type: Object,
            default: () => null,
        },
        valign: {
            type: String,
            default: 'center'
        },
    },
    data() {
        return {
            tablePageSize: 0,
        };
    },
    watch: {
        'pageTableCount': {
            handler() {
                if ( this.pageTableCount === -1){
                    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE);
                } else if (this.pageTableCount) {
                    this.tablePageSize = this.pageTableCount;
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            pageTableCount: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
        }),
        pageSize() {
            if (this.tablePageSize > 0 && this.rowsPerPage && !this.hidePagination && !this.ignoreTablePageSize) {
                return this.tablePageSize;
            }
            return this.rowsPerPage;
        },
        pageCount() {
            if (this.pageSize <= 0)
                return 0;
            return Math.ceil(this.rows.length / this.pageSize);
        },
        displayedRowCount() {
            if (this.pageSize <= 0)
                return this.rows.length;
            if (this.pageIndex < this.pageCount - 1)
                return this.pageSize;
            else
                return this.rows.length - this.pageSize * this.pageIndex;
        },
        rowsWithId() {
            return this.rows.map(row => {
                return {
                    id: this.constructRowId(row),
                    row,
                    acceptsFile: this.dragnDropFilesOnRow,
                    customRowStyle: row.customRowStyle || {},
                };
            });
        },
        isSelected() {
            const result = {};
            if (this.selected) {
                this.selected.forEach(row => {
                    result[this.constructRowId(row)] = true;
                });
            }
            return result;
        },
        areAllSelected() {
            if (!this.selected) return false;
            return this.selected.length == this.rows.length;
        },
        areOnlySomeSelected() {
            return this.selected?.length > 0 && !this.areAllSelected;
        },
        areAllHiddenDisabled() {
            return !this.rows.some(s => !s.selectedDisabled && !s.selectedHidden)
        },
        menuRowsPerPage() {
            return this.hidePagination ? [] : (this.tableRowsPerPage ? this.tableRowsPerPage : [
                {value: 10, label: '10 Zeilen pro Seite'}, 
                {value: 25, label: '25 Zeilen pro Seite'}, 
                {value: 50, label: '50 Zeilen pro Seite'}, 
                {value: 100, label: '100 Zeilen pro Seite'}
            ])
        },
        headerActionsSlot() {
            return this.headerActions.filter(ha => this.$slots[ha.actionKey])
        },
    },
    methods: {
        onPageChange(index) {
            this.pageIndex = Math.max(0, Math.min(this.pageCount - 1, index));
        },
        onPageSizeChange(rowsPerPage) {
            this.pageIndex = 0;
            this.tablePageSize = rowsPerPage;
            this.$emit("onPageSizeChange", rowsPerPage)
        },
        onSelect(id) {
            let isSelected = {...this.isSelected};
            if (isSelected[id])
                delete isSelected[id];
            else
                isSelected[id] = true;
            this.emitSelected(isSelected);
        },
        onSelectAll(value) {
            let isSelected = {};
            if (value) {
                this.rows.filter(row => !row.selectedDisabled && !row.selectedHidden).forEach(row => {
                    isSelected[this.constructRowId(row)] = true;
                });
            }
            this.emitSelected(isSelected);
        },
        emitSelected(isSelected) {
            const result = this.rowsWithId
                .filter(idRow => isSelected[idRow.id])
                .map(idRow => idRow.row);
            this.$emit("selected", result);
        },
        openColumnsConfig() {
            this.$refs.sortableTable.openColumnsConfig()
        },
    },
}
</script>
<style src='./table.scss' lang='scss' scoped/>
