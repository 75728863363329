<template>
  <div>
    <div>
      <OptionMenu v-if="hasRoles([VIEW_ROLES.VIEW_BROKER])" :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
      <OptionMenu v-else :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

      <PageHeaderTitleNavigation 
        title="Depotpositionslimit"
        :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="optionsMenu"
        :actions="headerActions"
        @action-REMOVE="loschen()"
        @action-RESET="zuruckSetzen()"
      />

      <div class="box__container limits-box">
        <span class="box__title">{{fondsName}}</span>
        <br>
        <template v-if="limits">
          <template v-if="limits && limits.id !== null">
            <template
              v-if="limits && limits.data && !limits.isError">
              <div class="container-fluid">
                <div class="row" v-for="(l, i) of limits.data.labels" :key="'row-'+i">
                  <p class="col-12">{{l}}</p>
                </div>
              </div>

              <form>
                <div class="form-group container-fluid">
                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">Interne Depotpositionsnummer</div>
                    <div class="col-12 col-md-6 col-lg-10">{{begleitscheinNr}}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">ISIN</div>
                    <div class="col-12 col-md-6 col-lg-10">{{id}}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">Währung</div>
                    <div class="col-12 col-md-6 col-lg-10">{{limits.data.currency}}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">Aktueller Kurs</div>
                    <div class="col-12 col-md-6 col-lg-10">
                      {{limits.data.kurs | currency(2, limits.data.currency)}}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">Kursdatum</div>
                    <div class="col-12 col-md-6 col-lg-10">{{limits.data.kursDate}}</div>
                  </div>

                  <div class="row mb-2">
                    <InputField
                      label="Ausgangswert (Anteilspreis)"
                      v-model="price"
                      type="currency"
                      :foreign_currency="limits.data.currency">
                    </InputField>
                  </div>

                  <div class="row mb-2">
                    <InputField
                      label="Abweichung nach unten"
                      v-model="low"
                      type="number">
                    </InputField>
                    <ComboBox
                      label=" "
                      v-model="lowRelative"
                      :values="untenOptionComboOptions">
                    </ComboBox>
                  </div>

                  <div class="row mb-2">
                    <InputField
                      label="Abweichung nach oben"
                      v-model="high"
                      type="number">
                    </InputField>
                    <ComboBox
                      label=" "
                      v-model="highRelative"
                      :values="untenOptionComboOptions">
                    </ComboBox>
                  </div>
                  <div class="row mb-2">
                    <InputToggleSwitch
                      v-model="dynamic"
                      label="Dynamik"
                    />
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 col-md-6 col-lg-2">Ausgangswert für Dynamik</div>
                    <div class="col-12 col-md-6 col-lg-10">{{limits.data.dynamicValue | number}}</div>
                  </div>

                  <div class="row mb-2" v-if="limits.data.changeDate">
                    <div class="col-12 col-md-6 col-lg-2">Letzte Änderung</div>
                    <div class="col-12 col-md-6 col-lg-10">{{limits.data.changeDate}}</div>
                  </div>

                  <div class="row mb-2" v-if="limits.data.dateOutofLimit">
                    <div class="col-12 col-md-6 col-lg-2">Datum der Überschreitung</div>
                    <div class="col-12 col-md-6 col-lg-10">{{limits.data.dateOutofLimit}}</div>
                  </div>
                  <div class="row mb-2 w-394" v-if="isBroker || isByPass">
                    <div class="col-12">
                      <InputTextArea
                        label="Bemerkung"
                        v-model="comment">
                      </InputTextArea>
                    </div>
                  </div>

                </div>
              </form>
            </template>
          </template>
        </template>
      </div>
    </div>
    
    <BaseModal
      ref="modalRef"
      modalTitle="Limit löschen?"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="action(true)"
      @onCancelButton="action(false)">
    </BaseModal>

    <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmError" @resetError="resetError"/>

    <BaseModal
      ref="saveOrReset"
      labelButtonCancel="Verwerfen"
      labelButtonConfirm="Speichern"
      @onCancelButton="handleReset"
      @onConfirmButton="speichern">
      <template v-slot:modalTitle>
        Möchte Sie Ihre Änderungen speichern?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CORE_TYPES from '@/store/core/types';
import { removeFormatting } from './Utils';
import BaseModal from "@/components/core/BaseModal.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue' 
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue' 
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {
  },
  data () {
    return {
      params: {},
      breadcrumbs: [],
      id: '',
      isBreadcrumbsFinal: false,
      begleitscheinNr: '',
      fondsName: '',
      errorFromService: '',
      resetting: false,
      showModal: false,

      price: '',
      highRelative: 'PROZENTUAL',
      lowRelative: 'PROZENTUAL',
      dynamic: '',
      high: '',
      low: '',
      comment: '',
      isLeavingRoute: false,
      nextCallback: null,
      previousData: {},
    }
  },
  computed: {
    ...mapGetters({
      limits: DEPOTPOSITIONS_TYPES.GETTERS.LIMITS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    optionsMenu() {
      return [
        {
          path: `/home/vermogensubersicht/wertpapiere/limits/${this.id}/${this.begleitscheinNr}`,
          label: 'Depotpositionslimit',
          textBold: true,
        },
        {
          path: `/home/vermogensubersicht/wertpapiere/limits200/${this.id}/${this.begleitscheinNr}`,
          label: '200 Tage Limit',
        },
      ]
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('REMOVE', 'Löschen'),
        PageHeaderSimpleAction('RESET', 'Zurücksetzen'),
      ];
    },
    untenOptionComboOptions() {
      return [
        {
          value: 'ABSOLUT',
          label: 'absolut'
        },
        {
          value: 'PROZENTUAL',
          label: 'prozentual (relativ)'
        },
      ]
    },
    isChanged() {
      return this.previousData && JSON.stringify(this.previousData) !== JSON.stringify(this.formData())
    },    
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id;
    },
    '$route.params.begleitscheinNr': function (begleitscheinNr) {
      this.begleitscheinNr = begleitscheinNr;
    },
    high(value) {
      if (value < 0) {
        this.high = -value;
      }
    },
    low(value) {
      if (value < 0) {
        this.low = -value;
      }
    },
    limits(result, oldValue) {
        if (result.data.errorStr) {
          this.errorFromService = result.data.errorStr.replace(new RegExp('\n', 'g'), '<br/>');
          // Resets the state
          // this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
          this.$refs.modalError.open();
          return;
        }
        if (!this.errorFromService && this.isLeavingRoute) {
          this.isLeavingRoute();
        }
        if (result.action && result.action==LimitsActions.ActionTypes.setLimitsSuccess) {
          this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, { depotid: this.depotid });
          this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
          this.clearPreviousValues();
          this.navigateTo(`/home/vermogensubersicht/depot?backAction=true`);
        }
        if (result.data && result.data.input) {
          this.fondsName = result.data.fondsName;
          if (this.begleitscheinNr== result.data.input.bgsNr) {
              this.price = result.data.input.price,
              this.highRelative = result.data.input.highRelative,
              this.lowRelative = result.data.input.lowRelative,
              this.dynamic = result.data.input.dynamic,
              this.high = result.data.input.high,
              this.low = result.data.input.low,
              this.comment = result.data.input.comment
          }
        }
        if ((this.price == null || this.price == '') && result.data.kurs) {
          this.price = result.data.kurs;
        }
        this.resetting = false;
        this.previousData = this.formData();
    },
  },
  mounted: function() {
    this.errorFromService = null;
    this.isLeavingRoute = false;
    this.id = this.$route.params.id;
    this.begleitscheinNr = this.$route.params.begleitscheinNr;

    this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
  },
  methods: {
    confirmError() {
      if (this.isLeavingRoute) {
        this.isLeavingRoute();
      }
    },
    resetError() {
      this.errorFromService = null;
    },
    clearPreviousValues() {
      this.price = null;
      this.highRelative = null;
      this.lowRelative = null;
      this.dynamic = false;
      this.high = null;
      this.low = null;
      this.comment = null;
      this.errorFromService = null;
    },
    speichern() {
      const input = {
        price: Number(removeFormatting(this.price)),
        highRelative: this.highRelative,
        lowRelative: this.lowRelative,
        dynamic: this.dynamic,
        bgsNr: this.begleitscheinNr,
        high: Number(removeFormatting(this.high)),
        low: Number(removeFormatting(this.low)),
        comment: this.comment
      };
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.SET_LIMITS, input);
      if (this.nextCallback) {
          this.nextCallback();
      }
    },
    zuruckSetzen() {
      // this.resetting=true;
      // this.clearPreviousValues();
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RESET_LIMITS, { bgsNr: this.begleitscheinNr });
    },
    loschen() {
      this.$refs.modalRef.open();
    },
    confirmDelete() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.DELETE_LIMITS, { begleitscheinNr: this.begleitscheinNr });
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, { depotid: this.depotid, disableCache: true });
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
      this.navigateTo(`/home/vermogensubersicht?backAction=true`);
    },
    action(value) {
      this.hideModal();
      if (value) {
        this.confirmDelete();
      }
    },
    back() {
      this.navigateTo(`/home/vermogensubersicht/depot?backAction=true`);
    },
    backToForm() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
    },
    zuruck() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS, { begleitscheinNr: this.begleitscheinNr });
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    hideModal() {
      this.showModal = false;
    },
    formData() {
      return {
        price: Number(removeFormatting(this.price)),
        highRelative: this.highRelative,
        lowRelative: this.lowRelative,
        dynamic: this.dynamic,
        bgsNr: this.begleitscheinNr,
        high: Number(removeFormatting(this.high)),
        low: Number(removeFormatting(this.low)),
        comment: this.comment
      }
    },
    saveOrReset(action, callback) {
      if (this.isChanged) {
        this.nextCallback = callback;
        this.$refs.saveOrReset.open();
      } else {
          callback();
      }
    },
    handleReset() {
      this.$refs.saveOrReset.close();
      if (this.nextCallback) {
          this.nextCallback();
      }
    },
  },
  filters: {
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveOrReset('save', next);
  },
  mixins: [],
  components: {
    BaseButton,
    BaseModal,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputTextArea,
    InputField,
    ComboBox,
    InputToggleSwitch,
    LimitErrorModal,
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.modal-footer {
    min-width: 150px;
}
.antrag-action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.error-message {
  color: var(--color-danger);
}
</style>
<style>
.limits-box .input-forms__label-content {
  display: block;
}
.w-394 textarea {
  width: 440px;
  max-width: 100%;
}
</style>