var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "EditCommentModal",
    attrs: {
      size: "md",
      showCancelButton: true,
      labelButtonConfirm: "Speichern"
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.complete()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "option-menu-edit-modal--title-container" },
              [
                _c("div", { staticClass: "option-menu-edit-modal--title" }, [
                  _vm._v("Bemerkung bearbeiten")
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c("InputTextArea", {
                  attrs: { label: "Thema" },
                  model: {
                    value: _vm.bemerkungThema,
                    callback: function($$v) {
                      _vm.bemerkungThema = $$v
                    },
                    expression: "bemerkungThema"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("InputTextArea", {
                  attrs: { label: "Bemerkung", autoGrow: "" },
                  model: {
                    value: _vm.bemerkungText,
                    callback: function($$v) {
                      _vm.bemerkungText = $$v
                    },
                    expression: "bemerkungText"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }