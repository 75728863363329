import DEPOTPOSITIONENEDIT_TYPES from './types';

export default {
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.FILTER](state) {
    return state.filter;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.TABLE_DATA](state) {
    return state.tableData;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS](state) {
    return state.bgs;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.PARAM](state) {
    return state.param;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.HINWEIS](state) {
    return state.hinweise;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.PAGE_INDEX](state) {
    return state.pageIndex;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.TOTAL_ROWS](state) {
    return state.totalRows;
  },
  [DEPOTPOSITIONENEDIT_TYPES.GETTERS.TRANSAKTIONEN_UEBERTRAGEN_DATA](state) {
    return state.transaktUebertragenData;
  },
}