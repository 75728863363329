import DEPOTPOSITIONENEDIT_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';
import { Promise } from 'core-js';
import {filterStruktur} from '@/components/begleitscheine/filterDataDepotpos.js';

const config = {
  defaultSpinner: true,
};

export default {
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.FILTER]({ commit, state, rootState }) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?get_combo', {
        ask: 'get_combo',
        params: {}
      }, { defaultSpinner: true });
      const rights = rootState.core.loginData.rights;
      const schwerpunkt = response.data.schwerpunkt.map(item => ({label: item, value: item}));
      const region = response.data.region.map(item => ({label: item, value: item}));
      const metadata = filterStruktur(rights, schwerpunkt, region);
      commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.FILTER, {metadata});
      return state.filter;
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.FILTER', er);
    }
  },
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TABLE_DATA]({ commit, state, rootState }, payload = {}) {
      if (state.askPIN) {
        commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
          key: 'TABELLE_EDIT_DEPOTPOSITIONEN', 
          value: 0,
          action: DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TABLE_DATA,
          payload: JSON.stringify(payload)
        })
        state.askPIN = 0;
      }
      payload.pageIndex = state.pageIndex || 0;
      
      return axios.post(rootState.core.apiAddress + '/depotpositions/search?get_table_data', {
        ask: 'get_table_data',
        params: payload
      }, { defaultSpinner: true, cancelableRequest: {title: 'Depotpositionenfilter'} }).then(response => {
        if (typeof response.data.askPIN == 'number' && response.data.askPIN) {
          commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
            key: 'TABELLE_EDIT_DEPOTPOSITIONEN', 
            value: response.data.askPIN,
            action: DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TABLE_DATA,
            payload: JSON.stringify(payload)
          })
          state.askPIN = response.data.askPIN;
          commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TABLE_DATA, {records: []});
        } else {
          commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TABLE_DATA, response.data);
        }
    })
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB]({rootState, dispatch }, payload) {
    try {
      const ask = payload.ask || 'get_pdf';
      let response = await axios.post(rootState.core.apiAddress +  '/depotpositions/search?' + ask, {
        ask: ask,
        params: payload
      }, { defaultSpinner: true });
      if (payload._refreshBgs) {
        dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: payload._refreshBgs });
      }
      return Promise.resolve(response.data);
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('FONDSVERGLEICH_TYPES.ACTIONS.JOB', er);
    }
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.XLS]({rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?get_xls', {
        ask: 'get_xls',
        params: payload
      }, {defaultSpinner: true});
      return Promise.resolve(response.data);
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('FONDSVERGLEICH_TYPES.ACTIONS.XLS', er);
    }
  },
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GETINFO]({ rootState}, payload) {
    return axios.post(rootState.core.apiAddress + '/depotpositions/search?get_info', {
        ask: 'get_info',
        params: payload
      }, { defaultSpinner: true });
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_BGS]({ dispatch, commit, rootState}, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?del_bgs', {
        ask: 'del_bgs',
        params: payload
      }, { defaultSpinner: true });
      if (response.data?.message) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'danger'));
      } else {
        commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TABLE_DATA, response.data);
      }
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_BGS', er);
    }
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS]({ rootState}, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?get_courtage', {
        ask: 'get_courtage',
        params: payload
      }, { defaultSpinner: true });
      return response.data;
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS', er);
    }
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS]({commit, state, rootState}, payload) {
    try {
      if (payload?.bgsnr) {
        const isStammDaten = state.tableData?.length && Object.keys(state.tableData)?.some(key => state.tableData[key]?.find(depot => depot?.positionsnr === payload.bgsNr)?.stammDaten || false);
        let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?get_bgs', {
          ask: 'get_bgs',
          params: Object.assign(payload, {isStammDaten: isStammDaten})
        }, { defaultSpinner: true });
        if (response.data) {
          commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, response.data);
        }
      }
      return state.bgs;
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS', er);
    }
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_PF_KND]({ rootState}, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?del_pf', {
        ask: 'del_pf',
        params: payload
      }, { defaultSpinner: true });
      if (response.data.message !== undefined) {
        return response.data.message;
      } else if (response.data.combos) {
        return response.data.combos;
      }
      return '';
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_PF_KND', er);
    }
  },
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_VAL]({rootState }, payload) {
    try {
      return axios.post(rootState.core.apiAddress + '/depotpositions/search?get_val', {
        ask: 'get_val',
        params: payload
      }, {defaultSpinner: false});
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_VAL', er);
      Promise.resolve({});
    }
  },
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE]({dispatch, commit, state, rootState }, payload) {
    try {
      let callback = null;
      if ( payload.cb ) {
        callback = payload.cb;
        payload.cb = undefined;
      }
      let response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?' + payload.ask, {
        ask: payload.ask,
        params: payload
      }, { defaultSpinner: true });
      if ( payload.ask === 'save_bgs') {
        if (response.data && !response.data.message && response.data.values) {
          commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {values: {...state.bgs.values, ...response.data.values} });
        }
      } else {
        if (response.data ) {
          await dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: payload.bgsnr });
          if (callback) {
            callback();
          }
        }
      }
      return Promise.resolve(response.data);
    } catch (er) {
      if (process.env.NODE_ENV === 'development') console.log('DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE_COURTAGE', er);
      return Promise.resolve({});
    }
  },
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE_BGS]({dispatch, commit, state, rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/depotpositions/search?' + payload.ask, {
      ask: payload.ask,
      params: payload
    }, { defaultSpinner: true })
    .then((response) => {
      if (response.data && !response.data.message && response.data.values) {
        commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, response.data);
      }
      return response.data;
    });
  },
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.PATCH_BEMERKUNG]({ rootState, commit }, payload = {}) {
    const config = {
      defaultSpinner: true,
    };
    axios.put(rootState.core.apiAddress + '/depotpositions/updateBemerkung', {
      bgsNr: payload.begleitscheinNr,
      bemerkung: payload.bemerkung,
    }, config).then(response => {
      if (response.data) {
        response.data.pageIndex = payload.pageIndex;
        commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.PATCH_BEMERKUNG_SUCCESS, response?.data);
      }
    });
  },
 
  [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_TRANSAKTIONEN_UEBERTRAGEN_DATA]({ rootState, commit }, bgsNr) {
    if (bgsNr) {
      axios.get(rootState.core.apiAddress + `/depotpositions/get-transaktionen-uebertragen/${bgsNr}`, config).then(response => {
        if (response?.data) {
          commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TRANSAKTIONEN_UEBERTRAGEN_DATA_SUCCESS, response.data);
        }
      });
    }
  },
  
  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TRANSAKTIONEN_UEBERTRAGEN]({ rootState, dispatch }, bgsNr) {
    if (bgsNr) {
      await axios.post(rootState.core.apiAddress + `/depotpositions/transaktionen-uebertragen?bgsNr=${bgsNr}`, config).then(response => {
        if (response?.data?.errorMessage) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.errorMessage, 'danger'));
        } else if (response?.data?.message) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.message, 'info'));
        }
      });
    }
  },

  async [DEPOTPOSITIONENEDIT_TYPES.ACTIONS.CREATE_XLS_OR_PDF]({rootState, state, commit}, payload) {
    payload.pageIndex = state.pageIndex || 0;
    payload.limit = state.limit || 20;
    const response = await axios.post(rootState.core.apiAddress + '/depotpositions/search?create_xls_pdf', {
      ask: 'create_xls_pdf',
      params: payload
    }, { defaultSpinner: true, cancelableRequest: {title: 'Depotpositionenfilter'} });
    
    if (typeof response?.data?.askPIN == 'number') {
      commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
        key: 'TABELLE_EDIT_DEPOTPOSITIONEN', 
        value: response.data.askPIN,
        payload: JSON.stringify(payload)
      })
    }
    if (response?.data) {
      return response;
    } else {
      return null;
    }

  },

}