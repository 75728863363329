var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading && !_vm.scrollLoading
        ? _c("GhostLoading", { attrs: { type: "table", title: _vm.title } })
        : _c(
            "PaginatedTable",
            _vm._g(
              {
                attrs: {
                  tableId: "15b90920-ebdd-11ec-8ea0-0242ac120002",
                  title: _vm.title,
                  headers: _vm.headers,
                  page: _vm.selectedPage,
                  pages: _vm.pages,
                  pageCount: _vm.pageCount,
                  rowCount: _vm.rowCount,
                  sorted: _vm.searchSort,
                  pageSize: _vm.maxRowsPerPage,
                  menuRowsPerPage: _vm.menuRowsPerPage,
                  mobileConfig: {
                    title: "subject",
                    headers: ["typ", "d", "status"]
                  },
                  exportConfig: _vm.exportConfig
                },
                on: {
                  scrollLoading: function($event) {
                    _vm.scrollLoading = $event
                  },
                  "click-subject": _vm.clickBetreff,
                  "action-OPEN": _vm.handleActionOpen,
                  "action-BEMERKUNG": _vm.handleActionBemerkung,
                  "action-TRACE": _vm.handleActionTrace,
                  "action-OPENEMAIL": _vm.handleActionOpenMail,
                  "action-EDIT": _vm.handleActionEdit,
                  "action-CONFIRM": _vm.handleActionConfirm,
                  "action-TOERROR": _vm.handleActionToError,
                  "action-SEND": _vm.handleActionSend,
                  "action-COMM": _vm.clickBetreff
                },
                scopedSlots: _vm._u([
                  {
                    key: "subject",
                    fn: function(row) {
                      return [
                        row.canRead && !row.mobileTableContext
                          ? _c("DownloadLink", {
                              attrs: {
                                target: "_blank",
                                rel: "noopener noreferer",
                                title: row.subject,
                                downloadServicePath: "/dokumentEingangData",
                                queryParams: {
                                  id: row.id
                                }
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(row.subject))])
                      ]
                    }
                  }
                ])
              },
              _vm.$listeners
            )
          ),
      _c("Bemerkung", { ref: "Bemerkung" }),
      _c("Trace", { ref: "Trace" }),
      _c("Confirm", { ref: "Confirm" }),
      _c("ToError", { ref: "ToError" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }