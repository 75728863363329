<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Erfassung Position Papierantrag" />

    <FondsFinderSuchMaske v-if="searchMode" />

    <FondsFinderPositionsList  v-if="searchMode"
      :goBackEnabled="true"
      :singleSelectionProp="true"    
      @goBack="onselectionDone($event)">
    </FondsFinderPositionsList>

    <div class="row parameter-search__input-fields box__container" v-if="!searchMode">
      <div class="col" v-if="lastSelectedFund && lastSelectedFund.wertpapiername">
          {{lastSelectedFund.wertpapiername}}
      </div>
      <div class="col-auto" >
          <BaseButton @click="openFormsFinder">Wertpapierauswahl</BaseButton>
      </div>
      <div class="col-12" v-if="componentConfig && componentConfig.positionSpecFields && lastSelectedFund && lastSelectedFund.isin">
        <WertpapierAuswahlPositionFields 
          :config="componentConfig"
          :specSelectedFund="lastSelectedFund"
          :spec="true"
          :categoryId="categoryId"
          :antragId="antragId" />
      </div>     
    </div>   

    <BaseModal @onCancelButton ="cancelSaving()" ref="errorModal" modalTitle="Fehler Liste" labelButtonConfirm="Ok" labelButtonCancel="Zurück zur Beratungsmappe">
      <div v-if="response" >
        <div v-for="errorItem in response.errorTable" :key="errorItem.value">
            {{errorItem.label+": " + errorItem.value}}
        </div>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ANTRAG_TYPES from "@/store/antrag/types";
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex';
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
/* import validator from "@/mixins/validator"; */
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import { calculateAntragID } from '@/components/antrag/antrag-utils';


export default {
  mixins: [antragNavigationMixin, antragMixin/* , validator */],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputToggleSwitch,
    InputRadioBoxGroup,
    ComboBox,
    BaseButton,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    BaseModal,
    WertpapierAuswahlPositionFields
  },
  data: function() {
    return {
      component: {},
      antragId: '',  
      lagerstelle: '',  
      componentId: '',     
      categoryId: '',    
      antragsName: '',  
      antragId: '',  
      fondsArt: 'INVESTMENTFONDS_OHNE_ZERTIFIKAT',
      lastSelectedFund: {wertpapiername: 'Bitte tragen Sie hier eine Positionen ein', isin:''},
      selectionIsin: {},
      fondsArtValues:[
        {label:'Investmentfonds', value: 'INVESTMENTFONDS_OHNE_ZERTIFIKAT'}, 
        {label:'Beteiligungen', value: 'GESCHLOSSENEFONDS'},
      ],
      suppressFilterLagerstelle: true,
      searchMode: false,
      response: null,
      ignoreFormErrors: false,
      formSaved: false,
      mandatoryFields: [],        
    }
  },
  watch: {
    lastSavedAntragsPosition: function (newValue, oldValue) {
      this.response = newValue;
      if (this.response && this.response.errorTable && this.response.errorTable.length>0) {
        this.$refs.errorModal.open();
      }

      if (this.response && this.response.errorTable.length==0) {
        this.formSaved = true;
         this.$router.go(-1); 
      }
    }
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,    
    }),
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    componentConfig() {
      return this.component && this.component.config;
    },
  
    getFondsArt() {
      switch (this.fondsArt) {
        case "INVESTMENTFONDS_OHNE_ZERTIFIKAT":
          return "1";  
        case "GESCHLOSSENEFONDS":
          return "3";           
        default:
          return "1";
      }
    }
  },
  methods: {
   goBack() {
     this.navigateBackToCurrentStep();
    },
    cancelSaving() {
      this.ignoreFormErrors = true;
      this.$router.go(-1);
    },
    openFormsFinder() {
      this.searchMode = true;
    },  
    onselectionDone(data)  {
      this.searchMode = false;

      if (data[0] && data[0]._fondsData.isin) {
        this.selectionIsin = data[0]._fondsData.isin;
        this.lastSelectedFund = data[0];
      }
    },
    setFondsArt(whatType) {
      this.fondsArt = whatType.value;
    },
  },
  mounted() {    
    this.antragId = calculateAntragID({antragsName: this.antragsName, lagerstelle: this.lagerstelle});    
    this.lagerstelle = this.$route.params.lagerstelle || this.$route.query.lagerstelle;          
    this.categoryId = this.$route.params.categoryId;    
    this.componentId = this.categoryId;    
    this.antragsName = this.$route.params.antragsName;
    this.antragId = calculateAntragID({antragsName: this.antragsName, lagerstelle: this.lagerstelle});
    let components = this.antrag && this.antrag.components[this.$route.params.step];
    this.component = components && components.find(ct => ct.id === this.componentId);
  },
   
}
</script>

<style scoped>
.wi-type-liste  {
  display: flex;
}

.wi-type-liste span  {
  margin-right: 4px;
}

.wi-type-liste a  {
  margin-right: 4px;
}
</style>
