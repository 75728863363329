<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="MyDepot - Musterdepotverwaltung" 
      :defaultMenu="customOptionMenu"
      :actions="headerActions"
      @action-CREATE="handleAction({key: 'CREATE'})" 
      @action-FILES="onFileChange" 
    />

    <div class="box__container" :tid="_generateTidFromString('box__container')">
      <DragnDropArea hoverText="Hochladen" @files="onFileChange" v-if="rows.length">
        <Table 
          :headers="headers"
          :rows="rows"
          :headerActions="tableHeaderActions"
          :selected="selectedRowsByModus"
          sortableRows
          @selected="selectRow"
          @click-label="openMusterdepot"
          @action="handleAction"
          @action-DELETE="deleteMusterdepots([$event])"
          @headerAction-BULK_DELETE="deleteMusterdepots(allSelectedRows)"
          @orderChanged="orderChanged"
        >
          
            <template v-slot:struktur="row">
              <InputToggleSwitch 
                v-if="row.owner"
                :disabled="loading"
                :value="row.struktur"
                @input="setStrukturBeratung({row, key: 'STRUKTUR', value: $event, objKey: 'struktur'})"
                inLineLabel
              />
              <span v-else></span>
            </template>

            <template v-slot:beratung="row">
              <ComboBox
                :value="row.beratung"
                :values="beratungValues"
                :disabled="loading"
                @change="setStrukturBeratung({row, key: 'BERATUNG', value: $event, objKey: 'beratung'})"
              />
            </template>
        </Table>
      </DragnDropArea>
      <NoData v-else-if="!loading" />
      <GhostLoading v-else type="table" :config="{ table: { rows: 10, }, }"/>
    </div>

    <div class="box__container">
      <div >
        <p>Mit MyDepot können Sie Ihre eigenen Musterdepots (Einmalanlagen und Sparpläne separat)
						 mit verschiedenen Anlagebeträgen zusammenstellen.</p>
        <p>Diese Muster-Depots können beim Kunden unter Beratung/Anlageempfehlung aufgerufen
						und weiterverarbeitet werden.</p>
        <p>
          - Mit der Einstellung „Für Struktur freigeschaltet“ legen Sie fest, welche Ihrer MyDepots an Ihre Unterstruktur vererbt wird.
        </p>
        <p>
          - Mit der Einstellung „Für Beratung verwendet“ entscheiden Sie, welche der MyDepots Ihnen in der Anlageempfehlung angezeigt 
          werden. Bitte beachten Sie, dass wenn Sie kein Musterdepot aktivieren, Ihnen keines in den jeweiligen Tools angezeigt wird.
        </p>
      </div>
    </div>

    <BaseModal 
      ref="createOrEditMusterdepotModal"  
      :labelButtonConfirm="labelButtonConfirm"
      :modalTitle="modalTitle"
      @onConfirmButton="handleActionConfirmed()"
    >
      <InputField 
        label="Bezeichnung"
        v-model="createMusterdepotData.label"
      />
      <ComboBox
        label="Typ"
        v-model="createMusterdepotData.type" 
        :values="typeValues"
      />
    </BaseModal>

    <BaseModal 
      ref="errorModal"  
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      modalTitle="Hinweise und Fehler"
    >
      <div class="row" v-for="(error, index) of errorMessages" :key="index">
        <div :class="getStatusClass(error.status)" class="col-12">
          {{ getDescription(error) }}
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types.js'
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import axios from 'axios';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import {TextColumn, SlotColumn, ActionColumn, DateColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { mapGetters } from 'vuex';
import { makeQueryParam } from '@/helpers/utils-helper';
import { PageHeaderSimpleAction, 
  PageHeaderFileSelectAction,
  PageHeaderConfirmedAction, 
  PageHeaderDownloadLinkHrefAction } from '@/components/core/header-title-navigation/page-header-utils';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    PageHeaderTitleNavigation,
    Table,
    InputField,
    InputToggleSwitch,
    GhostLoading,
    BaseModal,
    NoData,
    OptionMenu,
    ComboBox,
    BaseFileSelect,
    DragnDropArea,
    DownloadLink,
  },
  data() {
    return {
      selectedMusterdepot: {},
      currentAction: '',
      selectedRowsEinmal: [],
      selectedRowsSpar: [],
      loading: false,
      createMusterdepotData: {
        label: '',
        type: null
      },
      modus: 'EINMALANLAGE',
      typeValues: [
        {
          label: 'Einmalanlage',
          value: 'EINMALANLAGE'
        },
        {
          label: 'Sparplan',
          value: 'SPARPLAN'
        }
      ],
      einmalanlageValues: [],
      sparplaeneValues: [],
      modalTitle: '',
      labelButtonConfirm: '',
      headers: {
        lockedLeft: [
          TextColumn("label", "Bezeichnung").makeLink(),
          SlotColumn("struktur", "Für Struktur freigeschaltet", 80, 1),
          SlotColumn("beratung", "Für Beratung verwendet", 80, 1),
          DateColumn("dateCreated", "Erstellt am", 80, 1),
          DateColumn("dateLastModified", "Zuletzt geändert am", 80, 1),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ]
      },
      errorMessages: [],
      beratungValues: [
        {
          label: 'Ja',
          value: 'JA',
        },
        {
          label: 'Ja (inkl. Struktur)',
          value: 'JA_STRUKTUR',
        },
        {
          label: 'Nein',
          value: 'NEIN',
        },
      ],
      orderHasChanged: false,
      orderedRows: [],
    }
  },
  computed: {  
    ...mapGetters({
      token: CORE_TYPES.GETTERS.GET_TOKEN,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE', 'Musterdepot erstellen').withDisabled(() => this.loading),
        PageHeaderFileSelectAction('FILES', 'Musterdepots importieren', '.csv, .xlsx').withDisabled(() => this.loading),
      ];
    },
    selectedRowsByModus() {
      return this.modus === 'SPARPLAN' ? this.selectedRowsSpar : this.selectedRowsEinmal
    },
    allSelectedRows() {
      return this.selectedRowsSpar.concat(this.selectedRowsEinmal) || []
    },
    rows() {
      const rows = this.modus === 'SPARPLAN' ? this.sparplaeneValues : this.einmalanlageValues
      if (!rows || !rows.length)
        return []

      let records = rows.map(row => {
        let rowWithData = {
            data: row,
            ...row,
        };
        let actions = this.makeActions(rowWithData);
        return {...rowWithData, actions};
      });

      return records;
    },
    tableHeaderActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withDisabled(() => this.loading || !this.allSelectedRows.some(c => c.owner)),
        PageHeaderDownloadLinkHrefAction('DOWNLOAD', 'Markierte exportieren', 'musterdepots', this.linkmaker())
        .withDisabled(() => this.loading || !this.allSelectedRows.length),
      ];
    },
    customOptionMenu() {
      return [
        {
          label: 'Einmalanlagen',
          textBold: () => this.modus === 'EINMALANLAGE',
          action: () => {
            this.saveOrder().finally(() => this.modus = 'EINMALANLAGE')
          }
        },
        {
          label: 'Sparpläne',
          textBold: () => this.modus === 'SPARPLAN',
          action: () => {
            this.saveOrder().finally(() => this.modus = 'SPARPLAN')
          }
        },
      ]
    }
  },
  mounted() {
    this.retrieveMusterdepotList();
  },
  methods: {
    getDescription(error) {
      if (error) {
        return this.getErrorMessage(error.title, error.message);
      }
      return '';
    },
    getErrorMessage(title, message) {
      return ((title ? `${title}: ` : '') + (message || ''));
    },
    getStatusClass(status) {
      switch(status) {
        case 'HINWEIS':
          return { 'color-text': true, };
        case 'WARNUNG':
          return { 'color-warning': true, };
        case 'FEHLER':
          return { 'color-danger': true, };
      }
    },
    selectRow(row) {
      if (this.modus === 'SPARPLAN') {
        this.selectedRowsSpar = row
      } else {
        this.selectedRowsEinmal = row
      }
    },
    onFileChange(files) {
      this.loading = true

      const reader = new FileReader();

      reader.onload = e => {
        let base64string = reader.result.split(",").pop();
        this.doImport({
          fileName: files[0].name,
          data: base64string
        })
      }
      reader.readAsDataURL(files[0]);

    },
    doImport(file) {
      axios.post(`${process.env.VUE_APP_API}/musterdepotService/import?modus=${this.modus}`, file, config)
        .then(response => {
          if (response && response.data && response.data.length) {
            this.errorMessages = response.data
            this.$refs.errorModal.open()
          }
          this.retrieveMusterdepotList()
        })
        .catch(() => this.loading = false)
    },
    createOrEditMusterdepot() {
      this.loading = true

      const payload = {
        ...this.createMusterdepotData,
        id: this.selectedMusterdepot.id,
      }

      axios.post(`${process.env.VUE_APP_API}/musterdepotService/createOrEdit`, payload, config)
        .then(() => this.retrieveMusterdepotList())
        .catch(() => this.loading = false)
    },
    copyMusterdepot() {
      this.loading = true

      const payload = {
        id: this.selectedMusterdepot.id,
        ...this.createMusterdepotData,
      }

      axios.post(`${process.env.VUE_APP_API}/musterdepotService/copy`, payload, config)
        .then(() => this.retrieveMusterdepotList())
        .catch(() => this.loading = false)
    },
    openMusterdepot(row) {
      this.$addBreadcrumb({
        label: 'zurück zu MyDepot', 
        fullPath: this.$route.fullPath,
      });
      this.$router.push(`/intern/mydepot/wertpapiere/${row.id}`)
    },
    handleAction(actionData) {
      this.selectedMusterdepot = actionData.row || {}
      this.currentAction = actionData.key

      switch(actionData.key) {
        case 'CREATE':
          this.modalTitle = "Musterdepot erstellen"
          this.labelButtonConfirm = "Erstellen"
          this.createMusterdepotData = {
            label: '',
            type: this.modus
          }
          this.$refs.createOrEditMusterdepotModal.open()
          break;
        case 'EDIT':
          this.modalTitle = "Musterdepot bearbeiten"
          this.labelButtonConfirm = "Speichern"
          this.createMusterdepotData = {
            label: this.selectedMusterdepot.label,
            type: this.selectedMusterdepot.type
          }
          this.$refs.createOrEditMusterdepotModal.open()
          break;
        case 'COPY':
          this.modalTitle = "Musterdepot kopieren"
          this.labelButtonConfirm = "Kopieren"
          this.createMusterdepotData = {
            label: this.selectedMusterdepot.label,
            type: this.selectedMusterdepot.type
          }
          this.$refs.createOrEditMusterdepotModal.open()
          break;
        default:
          break;
      }
    },
   handleActionConfirmed() {
      switch(this.currentAction) {
        case 'CREATE':
        case 'EDIT':
          this.createOrEditMusterdepot()
          break;
        case 'COPY':
          this.copyMusterdepot()
        default:
          break;
      }
    },
    linkmaker (){
      const token = this.token
      const musterdepotIdList = this.allSelectedRows.map((n) => `musterdepotIdList=${n.id}`).join('&')

      const params = makeQueryParam({ token })
      
      return `${process.env.VUE_APP_API}/musterdepotService/get_export_link?${musterdepotIdList}&${params}`;
    },
    deleteMusterdepots(rows) {
      const musterdepotIdList = rows.filter(c => c.owner).map(c => `musterdepotIdList=${c.id}`).join('&')

      if (musterdepotIdList && musterdepotIdList.length) {
        this.loading = true

        axios.delete(`${process.env.VUE_APP_API}/musterdepotService/delete?${musterdepotIdList}`, config)
        .then(() => {
            this.selectedRowsSpar = []
            this.selectedRowsEinmal = []
            this.retrieveMusterdepotList()
        }).catch(() => this.loading = false)
      }
    },
    setStrukturBeratung({row, key, value, objKey}) {
      this.loading = true;

      const currentList = this.modus === 'SPARPLAN' ? this.sparplaeneValues : this.einmalanlageValues

      const payload = {
        id: row.id,
        [objKey]: value
      }

      axios.post(`${process.env.VUE_APP_API}/musterdepotService/executeAction?action=${key}`, payload, config)
        .then(response => {
          const indx = currentList.findIndex(row => row.id === response.data.id)
          
          if (indx > -1) {
            this.$set(currentList, indx, response.data)
          }
        })
        .finally(() => this.loading = false)
    },
    makeActions(child) {
      const actions = [
        SimpleAction("COPY", "PhCopy", "Musterdepot kopieren")
      ];      

      if (child.owner) {
        actions.push(SimpleAction("EDIT", "PhPencilLine", "Musterdepot bearbeiten"))
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Musterdepot löschen", `Wollen Sie das Musterdepot "${child.label}" wirklich löschen?`, 'Musterdepot löschen', "Löschen"))
      }

      return actions;
    },
    retrieveMusterdepotList() {
      this.loading = true;

      axios.get(`${process.env.VUE_APP_API}/musterdepotService/list`, config).then(response => {
        if (response && response.data) {
          [this.einmalanlageValues, this.sparplaeneValues] = response.data.reduce(([p, f], item) => item.type === 'SPARPLAN' ? [p, [...f, item]] : [[...p, item], f], [[], []]);
        }
      })
      .finally(() => this.loading = false)
    },
    orderChanged(rows) {
      this.orderHasChanged = true
      this.orderedRows = rows

      if (this.modus === 'SPARPLAN') {
        this.sparplaeneValues = rows.map(row => ({...row.data}))
      } else {
        this.einmalanlageValues = rows.map(row => ({...row.data}))
      }
    },
    saveOrder(orderedRows = this.orderedRows) {
        if (!this.orderHasChanged) {
          return new Promise((resolve) => {
            resolve()
          })
        }

        this.loading = true;

        const params = makeQueryParam({musterdepotId: orderedRows.map(row => row.id)})

        return axios.post(`${process.env.VUE_APP_API}/musterdepotService/saveOrder?${params}`, undefined, config)
        .then(() => {
          this.orderHasChanged = false
          this.orderedRows = []
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveOrder()
    next();
  },
}
</script>
