<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Jahrescheckliste"
      :actions="headerActions"
      @action-excel="getExcellFile()"
    />

    <StepperForm
      stepType="tab"
      stepperName="jahrescheckliste"
      :stepperMediator="getStepperMediator()"
      :selectedStepKey="selectedStepKey"
      @next-step="nextStep"
      @previous-step="previousStep"
      @set-step="setStepByKey"
      @on-fertig="close"
    >
      <template #contentTemplate>
        <div v-if="selectedStepKey == 'punkt1'" class="box__container">
          <ComboBox
            v-model="form.INPUT_JAHR"
            :values="[
              { label: '2024', value: '2024' },
            ]"
            label="JAHRESCHECKLISTE für Jahr:"
            isComponentHalfSize
            @change="fieldChanged({ name: 'INPUT_JAHR', value: $event })"
          />
          <InputField
            :hidden="true"
            :disabled="true"
            v-model="form.PARAM_ID"
            isComponentHalfSize
          />
          <ChecklistePunkt1
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt2'">
          <ChecklistePunkt2
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt3'">
          <ChecklistePunkt3
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt4'">
          <ChecklistePunkt4
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt5'">
          <ChecklistePunkt5
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt6'">
          <ChecklistePunkt6
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt7'">
          <ChecklistePunkt7
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt8'">
          <ChecklistePunkt8
            :form="form"
            :paramId="form.PARAM_ID"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
        <div v-if="selectedStepKey == 'punkt9'">
          <ChecklistePunkt9
            :form="form"
            @fieldChanged="fieldChanged($event)"
          />
        </div>
      </template>
    </StepperForm>
    <BaseModal ref="komplett" :showConfirmButton="false" labelButtonCancel="Ok">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered" />
        Formular ist bereits signiert
      </template>
      <div>
        Das Dokument wurde bereits elektronisch signiert.
        <br />
        Eine Änderung ist daher nicht mehr möglich.
        <br />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import MAKLER_ANTRAG_TYPES from "@/store/maklerAntrag/types";
import Vue from "vue";
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from '../../helpers/log-message-helper';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import StepperForm from "@/components/stepper/StepperForm.vue";
import { makeQueryParam } from "@/helpers/utils-helper";
import ChecklistePunkt1 from "./ChecklistePunkt1.vue";
import ChecklistePunkt2 from "./ChecklistePunkt2.vue";
import ChecklistePunkt3 from "./ChecklistePunkt3.vue";
import ChecklistePunkt4 from "./ChecklistePunkt4.vue";
import ChecklistePunkt5 from "./ChecklistePunkt5.vue";
import ChecklistePunkt6 from "./ChecklistePunkt6.vue";
import ChecklistePunkt7 from "./ChecklistePunkt7.vue";
import ChecklistePunkt8 from "./ChecklistePunkt8.vue";
import ChecklistePunkt9 from "./ChecklistePunkt9.vue";

import BaseModal from "@/components/core/BaseModal.vue";
import {  PageHeaderLoadingAction, PageHeaderDownloadLinkHrefAction} from "@/components/core/header-title-navigation/page-header-utils";
import { openSignoViewDocument } from "@/components/core/download/SignoViewerLink.vue";
import { StepperMediator } from "@/components/stepper/utils";
import { PhWarning } from "phosphor-vue";
import { VIEW_ROLES } from '@/router/roles';
import router from "@/router";

import { downloadLinkMaker } from '@/helpers/utils-helper';

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'
export default {
  components: {
    StepperForm,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    DatePickerField,
    InputToggleSwitch,
    InputRadioBoxGroup,
    FormLabel,
    BaseButton,
    ChecklistePunkt1,
    ChecklistePunkt2,
    ChecklistePunkt3,
    ChecklistePunkt4,
    ChecklistePunkt5,
    ChecklistePunkt6,
    ChecklistePunkt7,
    ChecklistePunkt8,
    ChecklistePunkt9,
    BaseModal,
    PhWarning,
  },
  data() {
    return {
      selectedStepKey: "punkt1",
      someFieldHasChanged: false,
      signoFile: null,
      loading: false,
      form: {
        INPUT_JAHR: "",
        PARAM_ID: null,
        INPUT_HOMEPAGE: "",
        INPUT_HOMEPAGE_TEXT: "",
        INPUT_KEINE_HOMEPAGE_BUERO_TEXT: "",
        INPUT_KEINE_HOMEPAGE_TEXT: "",
        INPUT_SIGNATUR_BEST_1: "",
        INPUT_VISITENKARTE_BEST_1: "",
        INPUT_GEWERBE_BEST1: "",
        INPUT_GEWERBE_BEST2_TEXT: "",
        INPUT_GEWERBE_BEST3: "",
        INPUT_GEWERBE_STRASSE: "",
        INPUT_GEWERBE_PLZ: "",
        INPUT_GEWERBE_ORT: "",
        INPUT_WPV_BEST1: "",
        INPUT_WPV_TEXT1: "",
        INPUT_WPV_TEXT2: "",
        INPUT_WPV_DATUM1: "",
        INPUT_WPV_DATUM2: "",
        INPUT_FIRMENBUCHAUSZUG_BEST: false,
        INPUT_WAG_BEST: "",
        INPUT_FIRMENNAME_TEXT: "",
        INPUT_FIRMENFUNKTION_TEXT: "",
        INPUT_GEWERBEBERECHTIGUNG_TEXT: "",
        INPUT_FIRMENNAME_TEXT2: "",
        INPUT_FIRMENFUNKTION_TEXT2: "",
        INPUT_GEWERBEBERECHTIGUNG_TEXT2: "",
        INPUT_VORGELEGT_BEST: "",
        INPUT_MITARBEITER1: "",
        INPUT_MITARBEITER2: "",
        INPUT_MITARBEITER3: "",
        INPUT_MITARBEITER4: "",
        INPUT_MITARBEITER5: "",
        INPUT_WIRTSCHAFTSPRUEFUNG_BEST1: "",
        INPUT_SONST_BEST1: false,
        INPUT_VERMITTLUNG_LEBENSVERS: false,
        INPUT_VERMITTLUNG_LEBENSVERS_DATUM: "",
        INPUT_VERMITTLUNG_SACHVERS: false,
        INPUT_VERMITTLUNG_SACHVERS_DATUM: "",
        INPUT_VERMITTLUNG_FINANZ: false,
        INPUT_VERMITTLUNG_FINANZ_DATUM: "",
        INPUT_VERMITTLUNG_DARLEHNEN: false,
        INPUT_VERMITTLUNG_DARLEHNEN_DATUM: "",
        INPUT_VERMITTLUNG_CROWDFUNDING: false,
        INPUT_VERMITTLUNG_CROWDFUNDING_DATUM: "",
        INPUT_VERMITTLUNG_CRYPTO: false,
        INPUT_VERMITTLUNG_CRYPTO_DATUM: "",
        INPUT_VERMITTLUNG_BETEILIGUNG: false,
        INPUT_VERMITTLUNG_BETEILIGUNG_DATUM: "",
        INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG: false,
        INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DATUM: "",
        INPUT_NICHT_FDL: false,
        INPUT_NICHT_FDL_DATUM: "",
        INPUT_ERKLAERUNG_TEXT: "",
        INPUT_WEITERE_TAETIGKEIT: "",
        INPUT_WEITERE_TAETIGKEIT_TEXT: "",
        INPUT_UNTERLAGEN_BEST1: false,
        INPUT_VERSICHERT_BEI_TEXT: "",
        INPUT_POLIZZENNR_TEXT: "",
        INPUT_GUELTIG_BIS_TEXT: "",
        INPUT_STATUS_OK: false,
        INPUT_STATUS_OK_DATUM: "",
        INPUT_STATUS_FEHLER:false,
        INPUT_STATUS_FEHLER_TEXT:"",
        INPUT_HANDY_GESICHERT: '',
        INPUT_GERAETE_MIT_PASSWORT: '',
        INPUT_MITARBEITER_SICHERHEITSSTANDARDS: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      maklerAntrag: MAKLER_ANTRAG_TYPES.GETTERS.GET_MAKLER_ANTRAG,
      attachments: MAKLER_ANTRAG_TYPES.GETTERS.GET_JAHRESCHECKLISTE_DOCS,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    headerActions() {
      return [
        PageHeaderDownloadLinkHrefAction(
          "DOWNLOAD",
          "PDF anzeigen",
          "",
          this.showPDF()
        ).withDisabled(() => !this.hatPDF)
        .withVisible(()=> this.isBrokerBypass),
        PageHeaderLoadingAction("excel","EXCEL anzeigen",() => this.loading)
        .withVisible(()=> this.isBrokerBypass)
        .withDisabled(() => !this.hatPDF)
      ];
    },
    isBrokerBypass() {
        return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
    }, 
    hatPDF() {
      return (
        this.maklerAntrag &&
        this.maklerAntrag.PARAM_ID != "" &&
        this.maklerAntrag.PARAM_ID != null &&
        this.maklerAntrag.PARAM_ID != undefined &&
        this.someFieldHasChanged == false
      );
    },
    getAttachments() {
      return this.jahreschecklisteAttachments;
    },
    steps() {
      return [
        {
          stepKey: "punkt1",
          label: "Homepage",
          totalProgress: 1,
        },
        {
          label: "Gewerbeschein",
          stepKey: "punkt2",
          totalProgress: 1,
        },
        {
          label: "Vertraglich gebundener Vermittler (vgV) oder Wertpapiervermittler (WPV)",
          stepKey: "punkt3",
          totalProgress: 1,
        },
        {
          label: "Wirtschaftsprüfung 2024 – Klagen, Beschwerden",
          stepKey: "punkt4",
          totalProgress: 1,
        },
        {
          label: "Nebentätigkeiten und sonstige Funktionen",
          stepKey: "punkt5",
          totalProgress: 1,
        },
        {
          label: "Unterlagen der FinanzAdmin",
          stepKey: "punkt6",
          totalProgress: 1,
        },
        {
          label: "Vermögensschadenhaftpflicht",
          stepKey: "punkt7",
          totalProgress: 1,
        },
        {
          label: "Datensicherheit",
          stepKey: "punkt9",
          totalProgress: 1,
        },
        {
          label: "Status",
          stepKey: "punkt8",
          totalProgress: 1,
          hidden: !this.isBrokerBypass
        },
      ];
    },
  },
  mounted() {
    this.selectedStepKey = "punkt1";
    let paramId = this.$route.query.antragsdatenId;

    if (paramId != null) {
      this.doLoadJahresCheckliste(paramId);
    } else {
      this.form.INPUT_JAHR = "2024";
      this.doLoadJahresCheckliste();
    }
  },
  watch: {
    maklerAntrag(newVal, oldVal) {
      if(newVal['PARAM_ID']) {
        this.PARAM_ID = newVal['PARAM_ID'];
      }
    }
  },
  methods: {
    async doLoadJahresCheckliste(paramId) {
      const payload = {
        paramId: paramId,
        jahr: this.form.INPUT_JAHR,
      };
      await this.$store.dispatch(
        MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_MAKLERANTRAG,
        payload
      );
      if (this.maklerAntrag && this.maklerAntrag.PARAM_ID) {
        this.form = this.maklerAntrag;
        this.loadAttachments();
      }
    },
    loadAttachments() {
      this.$store.dispatch(
        MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CHECKLISTE_DOCS,
        this.maklerAntrag.PARAM_ID
      );
    },
    async generatePDF() {
      this.loading = true;
      await this.$store.dispatch(
        MAKLER_ANTRAG_TYPES.ACTIONS.SAVE_JAHRESCHECKLISTE,
        this.form
      );
      if (this.maklerAntrag && this.maklerAntrag.PARAM_ID) {
        this.someFieldHasChanged = false;
        this.loading = false;
        this.form.PARAM_ID = this.maklerAntrag.PARAM_ID;
      }
    },
    showPDF() {  
      const payload = {
        data: Object.assign(this.form, {
          servletName:
            "msc.mscrestservice.impl.general.antraege.jahrescheckliste.FAChecklisteServlet",
        }),
        action: "pdf",
        confirmed: true,
      };

      const queryParams = btoa(JSON.stringify(payload));
      const fileName = "Jahrescheckliste.pdf";
      const path = `${ROOT_DOWNLOAD_SERVICE_PATH}/generate_antrag_pdf`;
      return downloadLinkMaker(this.$store.getters, path, fileName, { input: queryParams });
    },

    getExcellFile(){
       this.$store
        .dispatch(MAKLER_ANTRAG_TYPES.ACTIONS.GET_EXCEL_DATA)
    },

    async setStepByKey(stepKey) {
      if (this.selectedStepKey === stepKey) {
        return;
      }
      if (this.someFieldHasChanged) {
        await this.generatePDF();
      }

      this.selectedStepKey = stepKey;
    },
    nextStep(params) {
      this.setStepByKey(params.stepKey);
    },
    previousStep(params) {
      this.setStepByKey(params.stepKey);
    },
    getStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    close() {
      if(this.isBroker) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Jahrescheckliste wurde abgeschlossen und zur Überprüfung vorgelegt.', 'info',
                 true, 5000));
      this.$router.push('/home')
      }
    },
    fieldChanged(payload) {
      if(payload.name !== "INPUT_JAHR"){
         this.someFieldHasChanged = true;
      }
      Vue.set(this.form, payload.name, payload.value);

      if (payload.name == "INPUT_JAHR") {
        this.doLoadJahresCheckliste().then(() => {
          if (!this.maklerAntrag || !this.maklerAntrag.PARAM_ID) {
            this.resetForm();
            this.form = {};
            this.form.INPUT_JAHR = payload.value;
          }
        });
      }
    },
    signMaklerAntrag() {
      this.$store
        .dispatch(MAKLER_ANTRAG_TYPES.ACTIONS.OPEN_PDF, this.form)
        .then((response) => {
          if (response && response.data) {
            this.signoFile = response.data;
            this.handleSigniture();
          }
        });
    },
    handleSigniture() {
      const signatures = this.signoFile.signatures;
      if (signatures.length === 1) {
        const signoItem = signatures[0];
        if (signoItem.signaturStatus === "SIGNED") {
          setTimeout(() => {
            this.$refs.komplett.open();
          }, 200);
        } else if (signoItem.url) {
          openSignoViewDocument(signoItem.url, signoItem.fileName);
        } else {
          this.errorStr = "Es liegen keine Dokumente vor.";
          this.$store.dispatch(
            LOG_TYPES.ACTIONS.ADD_MESSAGE,
            buildMessage(this.errorStr, "primary")
          );
        }
      }
    },
    resetForm() {
      this.$store.commit(MAKLER_ANTRAG_TYPES.MUTATIONS.RESET_FORM);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hatPDF || this.someFieldHasChanged) {
      this.generatePDF().then(() => {
        this.resetForm();
      });
    }
    this.$addBreadcrumb({
      label: `zurück zur Jahrescheckliste`,
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });
    next();
  },
};
</script>
