var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.filterType === "contact" ? "Kontaktsuche" : "Gesellschaft",
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-SELECTION-DONE": _vm.handleBackAction,
          "action-NEW-CONTACT": function($event) {
            return _vm.addNewContact()
          }
        }
      }),
      _vm.isLoaded
        ? _c("GenericPersonFilter", {
            attrs: {
              filterId: "fa0b41fe-41b0-4c49-bc6e-981c0744d8dd",
              saveKey: "contactSearchAdvanced",
              title: _vm.filterType === "contact" ? "Kontakte" : "Gesellschaft",
              metadata: _vm.searchMenu,
              isCustomerSearch: false,
              configFilter: _vm.configFilter,
              isCache: _vm.$route.meta.isCache
            },
            on: {
              search: function($event) {
                return _vm.handleSearch($event)
              }
            }
          })
        : _vm._e(),
      _vm.filtered
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "20250edd-3d2c-4ef5-8afc-56c34a2f5fcf" }
            },
            [
              _vm.loading && !_vm.scrollLoading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _vm.error
                ? _c("UnexpectedError")
                : _c("PaginatedTable", {
                    attrs: {
                      tableId: "3235ec89-a2c1-59e4-95b9-01331b92d3c0",
                      title:
                        _vm.filterType === "contact"
                          ? "Kontakte"
                          : "Gesellschaft",
                      headers: _vm.headers,
                      pages: _vm.pages,
                      pageCount: _vm.pageCount,
                      rowCount: _vm.rowCount,
                      page: _vm.pageId,
                      mobileConfig: {
                        title: "fullName",
                        headers: ["email", "art"],
                        selectionActive: _vm.isExternalSelect,
                        disableClickRow: true
                      },
                      selectionAsBlacklist: _vm.invertSelection,
                      sorted: _vm.contactSearchSort
                    },
                    on: {
                      page: function($event) {
                        _vm.pageId = $event
                      },
                      requestPage: _vm.loadPage,
                      selectionAsBlacklist: function($event) {
                        _vm.invertSelection = $event
                      },
                      sort: _vm.onContactSearchResultSort,
                      onScroll: _vm.onScroll,
                      scrollLoading: function($event) {
                        _vm.scrollLoading = $event
                      },
                      "click-fullName": _vm.handleEdit,
                      "action-DELETE": _vm.handleDelete,
                      "action-EMAIL": _vm.handleEmail
                    },
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }