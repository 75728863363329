<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" 
      title="Bestandsübertragung Versicherung" 
      :actions="headerActions"
      :defaultMenu="customOptionMenu"
      @action-MAKE="makeUebertragung()"
      @action-ADD="addVersicherung()"
    />

    <div class="box__container" v-if="isUebertragbarVersPage">
      <div>
        Zusätzlich wird der Makler beauftragt, die Betreuung auch der nachfolgend aufgeführten, nicht vom ihm vermittelten Versicherungsverträge 
        zu übernehmen. Für die angehakten Verträge wird mit der aktuell hinterlegten Vollmacht sofort eine Bestandsübertragung ausgelöst:
      </div>
      <GhostLoading v-if="loading" type="table"/>
      <Table
        v-else
        rowId="index"
        v-model="selectedRows"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
        :mobileConfig="{ selectionActive: true }"
        noDataContent="Es wurden keine übertragbaren Versicherungen gefunden"
        @clickRow="editVersicherung"
        @click-label="editVersicherung"
      >

        <template v-slot:status="row">
          <ph-x v-if="!hatVollmacht" :size="24" class="color-danger" v-fc-tooltip="'keine Vollmacht vorhanden'"/>
          <ph-check v-else-if="row.isUebertragMoeglich && row.inUmfang" :size="24" class="color-success"/>
          <Pill v-else-if="!row.versNr" label="keine Versicherungsnummer" type="danger"/>
          <Pill v-else :label="row.extraInfo" type="danger"/>
        </template>
      </Table>
    </div>

    <div class="box__container" v-else>
      <div>
        Die nachfolgenden Versicherungen können leider nicht übertragen werden, weil kein Vertrag mit den entsprechenden Gesellschaften 
        besteht oder eine Übertragung bereits stattfindet.
      </div>
      <GhostLoading v-if="loading" type="table"/>
      <Table
        v-else
        :headers="headersDisabled"
        :rows="rowsDisabled"
        :rowsPerPage="10"
        :mobileConfig="{}"
        @clickRow="editVersicherung"
        @click-label="editVersicherung"
      />
    </div>  
  </div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, SlotColumn} from "@/components/table2/table_util.js";
import axios from 'axios';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PhCheck, PhX } from "phosphor-vue";
import Pill from '@/components/core/Pill.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { buildMessage } from "@/helpers/log-message-helper";
import { TabMenu } from '@/menu/menu-utils';

const config = {
  defaultSpinner: true
};

export default {
  components: {
    Table,
    GhostLoading,
    PhCheck,
    PhX,
    Pill,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      selectedRows: null,
      loading: false,
      uebertragungenListDisabled: [],
      uebertragungenList: [],
      hatVollmacht: false,
    }
  },
  computed: {
    headers() {
      return {
          lockedLeft: [
              TextColumn("label", "Sparte").makeLink(),
              TextColumn("gesellschaft", "Gesellschaft"),
              TextColumn("versNr", "Vers. Nr"),
              SlotColumn("status", "Status").alignCenter()
          ],
          center: [],
          lockedRight: [],
      }
    },
    headersDisabled() {
        return {
            lockedLeft: [
                TextColumn("label", "Sparte").makeLink(),
                TextColumn("gesellschaft", "Gesellschaft"),
                TextColumn("versNr", "Vers. Nr"),
                PillColumn("status", "Vertrag"),
                PillColumn("uebertragStatus", "Übertragungsstatus"),
            ],
            center: [],
            lockedRight: [],
        };
    },
    rows() {
      return this.uebertragungenList
    },
    rowsDisabled() {
      return this.uebertragungenListDisabled.map((row, index) => {        
        let status = null;
        let uebertragStatus = null;

        if (row.hatVertrag) {
          status = {label: "vorhanden", type: "success"};
        } else {
          status = {label: "nicht vorhanden", type: "danger"};
        }

        if (row.hatUebertragung) {
          uebertragStatus = {label: "in Bearbeitung", type: "info"};
        } else {
          uebertragStatus = {label: "", type: "info"};
        }

        return {...row, status, index, uebertragStatus};
      });
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('MAKE', 'Bestandsübertragung an Versicherung senden')
          .withDisabled(() => this.loading || !this.selectedRows?.length || !this.hatVollmacht)
          .withVisible(() => this.isUebertragbarVersPage),
        PageHeaderSimpleAction('ADD', 'Neue Versicherung hinzufügen')
          .withDisabled(() => this.loading),
      ];
    },
    isUebertragbarVersPage() {
      return this.$route.params.typ === "list";
    },
    customOptionMenu() {
      return [
        TabMenu('/beratung/bestandsuebertragung/list', 'Übertragbare Versicherungen'),
        TabMenu('/beratung/bestandsuebertragung/listDisabled', 'Unübertragbare Versicherungen'),
      ];
    },
  },
  mounted() {
    this.initialize(true)
  },
  methods: {
    addVersicherung() {
      this.$router.push('/home/versicherungen/auswahl-vertragsart')
    },
    editVersicherung(row) {
      if (row.id) {
        this.$router.push(`/home/versicherungen/insurance-group/details/${row.id}`)
      }
    },
    makeUebertragung() {
      if (!this.selectedRows?.length) {
        return
      }

      this.$confirmModal({
        title: 'Bestandübertragung durchführen',
        message: 'Die ausgewählten Versicherungen übertragen?',
        labelButtonConfirm: 'Übertragen',
      }).then(() => {
        this.loading = true

        axios.post(`${process.env.VUE_APP_API}/bestandsuebertragung/transfer`, this.selectedRows, config).then(response => {
          if (response && response.status === 200) {
            this.initialize()
          }
        }).catch(() => this.loading = false)
      }).catch(() => {})


    },
    initialize(mount = false) {
      this.loading = true

      axios.get(`${process.env.VUE_APP_API}/bestandsuebertragung/list`, config).then(response => {
        if (response && response.data) {
          this.hatVollmacht = response.data.hatVollmacht
          
          const [pass, fail] = response.data.list.reduce(([p, f], v) => (v.uebertragMoeglich ? [[...p, v], f] : [p, [...f, v]]), [[], []]);
          this.uebertragungenList = pass.map((row, index) => {
            return {...row, index, selectedDisabled: !row.checkDefaultAktiv};
          }).sort((a,b) => (a.selectedDisabled) - (b.selectedDisabled))

          this.uebertragungenListDisabled = fail

          if (mount) {
            if (!this.hatVollmacht) {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es ist keine unterschriebene Maklervollmacht vorhanden, bitte erstellen sie diese zuerst.', 'danger'))
            } else {
              this.selectedRows = this.uebertragungenList.filter(item => !item.selectedDisabled)
            }
            
          } else {
            this.selectedRows = []
          }
        }
      }).finally(() => this.loading = false)
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zur Bestandsübertragung`, 
      to,
      from,
    });

    next()
  },
}
</script>

<style scoped>

</style>