<template>
  <div class="mb-4">
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <DashboardPanel
      id="3db09d10-ccf8-4181-8d8d-407f3d596a3a"
      :data="dashboardData"
      :canEdit="isEditable"
      ignoreUserLevelConfig
      :defaultOptionsMenu="customOptionsMenu"
      is3Columns
      :headerActions="headerActions"
      :noPrimaryAction="false"
      @executeAction="handleExecuteAction($event)"
    >
      <template #title>
        {{ kreditform || 'Kredite bearbeiten' }}
      </template>
      <template #subtitle>
        <template>
          <!-- <span class="subtitle-part">{{userIdUnterNr}}</span> -->
        </template>
      </template>
    </DashboardPanel>

    <BaseModal
      ref="editModal"
      :modalTitle="modalTitle"
      size="lg"
      :showCancelButton="false"
      @onCloseButton="saveChanges()"
      :showConfirmButton="false"
    >
        <Vertragsdaten v-if="editComponent=='vertragsdaten'"></Vertragsdaten>
        <Anpassungen  v-if="editComponent=='anpassungen'"></Anpassungen>
        <Abrechnung v-if="editComponent=='abrechnung'"></Abrechnung>
        <Aktivitaet v-if="editComponent=='aktivitaet'"></Aktivitaet>
        <Dokumente v-if="editComponent=='dokumente'"></Dokumente>
        <Prohyp v-if="editComponent=='prohyp'"></Prohyp>

    </BaseModal>
  </div>
</template>
  
  <script>
import CREDIT_TYPES from '@/store/credit/types';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhCheck, PhWarning } from "phosphor-vue";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import DashboardPanel from "@/components/dashboard/DashboardPanel.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Pill from "@/components/core/Pill.vue";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import Vertragsdaten from './steps/Vertragsdaten.vue';
import Anpassungen from './steps/Anpassungen.vue';
import Abrechnung from './steps/Abrechnung.vue';
import Aktivitaet from './steps/Aktivitaet.vue';
import Dokumente from './steps/Dokumente.vue';
import Prohyp from './steps/Prohyp.vue';

import { KREDITFORM_BAUFINANZIERUNG } from './credit-utils';

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  mixins: [InteractiveHelpCommonsMixin],
  validators: {},
  data: function () {
    return {
      editComponent: null,
      substepProperty: null,
      modalTitle: null,
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      creditEdit: CREDIT_TYPES.GETTERS.CREDIT_EDITED,
      rights: CREDIT_TYPES.GETTERS.RIGHTS,
      hints: CREDIT_TYPES.GETTERS.HINTS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    rights() {
      return this.loginData?.rights || {};
    },
    dashboardData() {
      const data = {
        widgets: [
          {
            name: "vertragsdaten",
            title: "Vertragsdaten",
            component: () =>
              import("@/views/home/credit/cards/Vertragsdaten.vue"),
            loading: () => false,
            actions: [
              {
                tableContainer: "vertragsdaten",
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
          {
            name: "anpassungen",
            title: "Anpassungen",
            component: () =>
              import("@/views/home/credit/cards/Anpassungen.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
        ],
      };

      if (this.rights?.allowReadVertragAbrechnung) {
        data.widgets.push(
          {
            name: "abrechnung",
            title: "Abrechnung",
            component: () =>
              import("@/views/home/credit/cards/Abrechnung.vue"),
            loading: () => false,
          },
        );
      }

      if (this.isByPass) {
        data.widgets.push(
          {
            name: "aktivitaet",
            title: "Aktivität",
            component: () =>
              import("@/views/home/credit/cards/Aktivitaet.vue"),
            loading: () => false,
          },
        );
      }      

      if (this.loginData?.rights?.showDocumentArchive || this.loginData?.rights?.updateDocumentArchive) {
        data.widgets.push(
          {
            name: "dokumente",
            title: "Dokumente",
            component: () =>
              import("@/views/home/credit/cards/Dokumente.vue"),
            loading: () => false,
          },   
        );
      }      
      
      if (this.rights?.prohypMenuItemVisible && this.kreditform === KREDITFORM_BAUFINANZIERUNG) {
        data.widgets.push(
          {
            name: "prohyp",
            title: "Prohyp",
            component: () =>
              import("@/views/home/credit/cards/Prohyp.vue"),
            loading: () => false,
          },   
        );
      }         


      return data;
    },
    customOptionsMenu() {
      return [];
    },
    isEditable() {
      return true;
    },
    headerActions() {
      return [];
    },
    kreditform() {
      return this.creditEdit?.kreditform || this.credit?.kreditform;
    },    
  },
  
  beforeCreate() {
    this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_CREDIT_STATE);
  },

  mounted: async function () {
    await this.init();
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_CREDIT_STATE);

    this.$addBreadcrumb({
      label: "zurück zum Überblick",
      to,
      from,
    });

    next();
  },

  methods: {
    saveChanges() {},
    init() {
      this.$store.commit(CREDIT_TYPES.MUTATIONS.SET_SELECTED_CREDIT_ID, this.$route.params?.creditId);

      this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_COMBOS);
      this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_CREDIT);
    },    
    handleExecuteAction(actionData) {
      if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
        if (actionData?.component?.$refs.table) {
          actionData?.component?.$refs.table.openColumnsConfig();
        } else if (actionData.action?.tableContainer) {
          actionData.component.$refs[
            actionData.action?.tableContainer
          ].$refs.table.openColumnsConfig();
        }
      } else {
        if (actionData.action?.action === "BEARBEITEN_APP") {
          this.editComponent =
            actionData?.widget?.mainStep || actionData?.widget?.name;

          if (actionData?.widget?.mainStep) {
            this.substepProperty = actionData?.widget?.name;
          } else {
            this.substepProperty = null;
          }

          this.modalTitle = actionData?.widget?.title;

          this.$refs.editModal.open();
        } else {
          this.editCredit()
        }
      }
    },
    editCredit() {
      const path = `/home/verbindlichkeiten/credit/edit/${this.creditId}`
      this.$router.push(path);
    },    
  },

  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " € ";
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // + '%';
    },
    percentage(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " % ";
    },
  },

  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    Pill,
    DragnDropArea,
    BaseModal,
    BaseButton,
    Vertragsdaten,
    Anpassungen,
    Dokumente,
    Abrechnung,
    Prohyp,
    Aktivitaet

  },
};
</script>
  
  <style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>
  
  