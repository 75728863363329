var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormLabel", {
        staticClass: "col-12",
        attrs: {
          config: { bold: true },
          label:
            "Ich/wir wünsche/n eine Anlage in folgenden Risikoklassen laut SRI: (SRI 1 = geringstes Risiko, SRI 7 = höchste Risiko)"
        }
      }),
      _c(
        "div",
        { staticClass: "col-12 my-2" },
        [
          _c("FormLabel", {
            attrs: {
              config: { small: true },
              label: _vm.isWealthKonzept
                ? _vm.labelSRIWealthKonzept
                : _vm.labelSRI
            }
          })
        ],
        1
      ),
      _c("InputCheckBoxGroup", {
        attrs: {
          disabled: _vm.isDisabled || _vm.isRefusedPage || _vm.isWealthKonzept,
          values: [
            {
              label:
                "<b>SRI 1:</b> Wertpapiere mit sehr geringer Schwankungsbreite (kleiner als 0,5% p.a.).<br> Empfohlener Anlagehorizont mindestens 1 Jahr und länger, sehr geringe Kapitalverluste.<br>&nbsp;",
              path: "sri1"
            },

            {
              label:
                "<b>SRI 2:</b> Wertpapiere mit geringer Schwankungsbreite (zwischen 0,5% und 5% p.a.).<br> Empfohlener Anlagehorizont mindestens 3 Jahre und länger, geringe Kapitalverluste.<br>&nbsp;",
              path: "sri2"
            },

            {
              label:
                "<b>SRI 3:</b> Wertpapiere mit geringer bis mittlerer Schwankungsbreite (zwischen 5% und 12% p.a.).<br> Empfohlener Anlagehorizont mindestens 5 Jahre und länger, Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;",
              path: "sri3"
            },

            {
              label:
                "<b>SRI 4:</b> Wertpapiere mit mittlerer Schwankungsbreite (zwischen 12% und 20% p.a.).<br> Empfohlener Anlagehorizont mindestens 5 Jahre und länger, Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;",
              path: "sri4"
            },

            {
              label:
                "<b>SRI 5:</b> Wertpapiere mit erhöhter Schwankungsbreite (zwischen 20% und 30% p.a.).<br> Empfohlener Anlagehorizont mindestens 7 Jahre und länger, höhere Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;",
              path: "sri5"
            },

            {
              label:
                "<b>SRI 6:</b> Wertpapiere mit hoher Schwankungsbreite (zwischen 30% und 80% p.a.).<br> Empfohlener Anlagehorizont mindestens 10 Jahre und länger, höhere Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;",
              path: "sri6"
            },

            {
              label:
                "<b>SRI 7:</b> Wertpapiere mit sehr hoher Schwankungsbreite (höher als 80% p.a.).<br> Empfohlener Anlagehorizont mindestens 10 Jahre und länger, sehr hohe Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen bis Totalverlust.<br>&nbsp;",
              path: "sri7"
            }
          ]
        },
        on: {
          checkBoxToggle: function($event) {
            return _vm.updateStoreCheckBoxGroup("riskRange", $event)
          }
        },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      !_vm.isWealthKonzept
        ? _c("InputField", {
            attrs: {
              label:
                "Beschreibung der Risikobereitschaft (Risikokennzahl) mit eigenen Worten falls obige Fallgruppen nicht ausreichend sind",
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.updateStore(
                  "riskRange",
                  "annotationRisikobereitschaft",
                  $event
                )
              }
            },
            model: {
              value: _vm.form.annotationRisikobereitschaft,
              callback: function($$v) {
                _vm.$set(_vm.form, "annotationRisikobereitschaft", $$v)
              },
              expression: "form.annotationRisikobereitschaft"
            }
          })
        : _vm._e(),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Bedarf an Liquidität bei bestimmten Anlagen",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          values: _vm.radioBtns
        },
        on: {
          input: function($event) {
            return _vm.updateStore("riskRange", "liquidity", $event)
          }
        },
        model: {
          value: _vm.form.liquidity,
          callback: function($$v) {
            _vm.$set(_vm.form, "liquidity", $$v)
          },
          expression: "form.liquidity"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }