<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Versicherungs Abschluss-Portale" />

    <div class="box__container" tid="4ad66987-07ad-46ae-892c-cac0d0379645">
      <Table 
        v-if="tableData && tableData.records && tableData.records.length"
        tableId="cbbf3d4b-eec7-469b-9504-2e197d4de462"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false">
        <template v-slot:itemLabel="props">
          <div v-if="props.data.row.disabled" :tid="_generateTidFromString('Abschluss-Portale-Table-disabled' + props.index)">
            {{props.data.row.itemLabel}}
          </div>
          <a v-else :tid="_generateTidFromString('Abschluss-Portale-Table' + props.index)" @click="askOptionsExterneZugang(props.data.row)">{{props.data.row.itemLabel}}</a>
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" />
    </div>
    <ExterneZugangAskOptions 
      :askOptions="askOptions"
      :title="externeZugang.companyLabel"
      v-if="askOptions"
      @close="askOptions = null"
      @onConfirmButton="askOptionsConfirmed($event)"/>
  </div>
</template>

<script>
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue'
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types'
import { mapGetters } from 'vuex'
import Table from '@/components/table/Table.vue'
import ExterneZugangAskOptions from '@/components/externeZugang/ExterneZugangAskOptions.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    PageHeaderActionCard,
    Table,
    ExterneZugangAskOptions,
    GhostLoading,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      askOptions: null,
      externeZugang: '',
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      externeZugangList: EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_ABSCHLUSS_LIST
    }),
    tableData() {
      if (!this.externeZugangList) {
        return {}
      }

      const headers = {
        companyLabel: {
          label: 'Gesellschaft',
          key: 'companyLabel',
          dataType: 'String',
          visible: true,
        },
        type: {
          label: 'Art',
          key: 'type',
          dataType: 'String',
          visible: true,
        },
        itemLabel: {
          label: 'Bezeichnung',
          key: 'itemLabel',
          dataType: 'Slot',
          visible: true,
        }    
      }
      const records = this.externeZugangList

      return {
        headers,
        records
      }
    }
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_ABSCHLUSS_LIST);
      } finally {
        this.loading = false;
      }
    },
    askOptionsExterneZugang(externeZugang) {
      if (externeZugang.askOptions) {
        this.externeZugang = externeZugang
        this.askOptions = externeZugang.askOptions
      } else {
        this.openExterneZugang(externeZugang.path)
      }
    },
    openExterneZugang(path) {
      this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path })
    },
    askOptionsConfirmed($event) {
      this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path: this.externeZugang?.path, options: $event })
      this.externeZugang = null
      this.askOptions = null
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>