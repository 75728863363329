<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Ordnerstruktur" />

    <div class="box__container private-dok-node-tree__container">
      <ContentWithSidebar :mobileContent="mobileContentActive" @mobileContentChange="handleMobileContentChange()">
        <template #sidebar>
        <Tree 
          v-if="folderStructure"
          :items="items" idKey="folderName" 
          :itemSelectedId="selectedNodeId" 
          @itemSelect="selectNode"
          uniqueId="cea83874-82eb-11ee-b962-0242ac120002"
          :defaultCollapsed="false"
          :showArrowRight="false"
          :isCollapsable="false"
          class="strucktur__tree_nodes">
          <template #default="{item}">
            <div class="strucktur__tree_node_content">
              <span class="strucktur__tree_node_label">{{ item.folderName }}</span>
              <div class="strucktur__tree_node_pill">
                <div v-for="(expl, index) of explanations(item)" :key="index">
                  <Pill :label="expl" type="info"></Pill>
                </div>
              </div>
            </div>
          </template>
        </Tree>
        <div v-else>Keine Daten</div>
        </template>
        <template #content>
          <div class="ml-4 mt-4" v-if="folderStructure && Object.keys(folderStructure).length">
            <EMailAccesOrdnerstrukturSettings ref="settings" :data="node"/>
          </div>
        </template>
      </ContentWithSidebar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import E_MAIL_ACCESS_TYPES from '@/store/eMailAccess/types';
import BaseButton from '@/components/core/BaseButton.vue';
import Tree from '@/components/core/Tree.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import AddPrivateDokNodeModal from '@/components/documents/privateDokNode/AddPrivateDokNodeModal.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { PhDotsThreeVertical, } from 'phosphor-vue';
import Pill from '@/components/core/Pill.vue';
import ContentWithSidebar from '@/components/core/ContentWithSidebar.vue';
import EMailAccesOrdnerstrukturSettings from '@/components/eMailAccess/EMailAccesOrdnerstrukturSettings.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    Tree,
    BaseDropdownMenu,
    AddPrivateDokNodeModal,
    PhDotsThreeVertical,
    Pill,
    ContentWithSidebar,
    EMailAccesOrdnerstrukturSettings,
  },
  data() {
    return {
      form: {},
      selectedNodeId: '',
      mobileContentActive: 'sidebar',
    };
  },
  mounted() {
    this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_FOLDER_STRUCTURE, this.serverId);
  },
  computed: {
    ...mapGetters({
      folderstructure: E_MAIL_ACCESS_TYPES.GETTERS.FOLDER_STRUCTURE,
    }),
    folderStructure() {
      if (!this.selectedNodeId) {
        this.selectedNodeId = this.folderstructure?.folderName || '';
      }
      return this.folderstructure || {};
    },
    serverId() {
      return this.$route.params.id;
    },
    items() {
      return [this.folderStructure];
    },
    node() {
      if(this.selectedNodeId && this.selectedNodeId == this.folderStructure?.folderName) {
        return this.folderStructure 
      } else {
        const node =  this.findSubfolder(this.folderStructure);
        return node;
      }
    },
  },

  methods: {
     findSubfolder(folder, result) {
      if (result) {
        return result;
      } 
      result = folder?.children?.find(child => child?.folderName === this.selectedNodeId);
      if (!result) {
        folder?.children?.forEach(child => {
          if (!result) {
            result = this.findSubfolder(child, result);
          }
        });
      } 
      return result;
    },
    selectNode(value) {
      if (value) {
        this.selectedNodeId = value.folderName;
        this.mobileContentActive = 'content';
      }
    },
    explanations(node = {}) {
      const arr = [];
      if (node.isEingang) {
        arr.push('Eingang');
      }
      if (node.isAusgang) {
        arr.push('Ausgang');
      }
      if (node.isMSCSent) {
        arr.push('Ablage Gesendet');
      }
      if (node.isMoveInbox) {
        arr.push('Ablage Eingang');
      }
      if (node.isMoveOutbox) {
        arr.push('Ablage Ausgang');
      }
      return arr;
    },
    handleMobileContentChange(content) {
      if (content !== this.mobileContentActive) {
        this.$refs?.settings?.save();
      }
      this.mobileContentActive = content || 'sidebar';
    },
  },

}
</script>
<style lang="scss" scoped>
.private-dok-node-tree__container {
  font-size: 0.9em;
}

.strucktur__tree_nodes {
  margin: 8px 0 8px;
}

.strucktur__tree_node_content {
  display: flex;
}

.strucktur__tree_node_label {
  flex: 1 1 auto;
  word-break: break-word;
}

.strucktur__tree_node_pill {
  flex: 0 0 auto;
  direction: column;
  align-self: flex-end;
}

.strucktur__tree_node_total {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}

</style>
