var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Jahresperformance Details",
          subtitle: _vm.depotTitle,
          actions: _vm.headerActions
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "ibox-body" }, [
          _c(
            "div",
            { staticClass: "w300" },
            [
              _c("ComboBox", {
                attrs: { label: "Auswahl Jahr", values: _vm.valuesYear },
                on: { change: _vm.retrieveDetails },
                model: {
                  value: _vm.year,
                  callback: function($$v) {
                    _vm.year = $$v
                  },
                  expression: "year"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.rows.length && !_vm.loading
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "Wertpapier",
                      rowsPerPage: 0,
                      exportConfig: _vm.exportConfig,
                      mobileConfig: {
                        title: "Wertpapier",
                        headers: ["g_v", "Rendite", "Aktueller_Bestand"]
                      }
                    },
                    on: { "action-TRANSAKTIONEN": _vm.navigateToTransactions }
                  })
                : _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("NoData")
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }