var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id:
            _vm.antragCustomNavigation.headerOptionMenuId ||
            _vm.$appNavigation.currentOptionMenuId,
          title: _vm.title,
          subtitle: _vm.subtitle,
          defaultMenu: _vm.antragCustomNavigation.headerOptionMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-PDF": _vm.getPdfList,
          "action-SAVE": function($event) {
            return _vm.webserviceSync()
          },
          "action-ACTIVATE_TAN": function($event) {
            return _vm.webserviceActivateTan()
          },
          "action-SIGN": function($event) {
            return _vm.doClick("sign")
          },
          "action-ONLINE_LEGI": function($event) {
            return _vm.doClick("online")
          },
          "action-EMAIL": function($event) {
            return _vm.doClick("email")
          },
          "action-FAX": function($event) {
            return _vm.doClick("fax")
          },
          "action-AUFTRAGSDOKUMENTE": function($event) {
            return _vm.doClick("auftragsdokumente")
          },
          "action-VOLLMACHT": function($event) {
            return _vm.doClickFFBButtons("vollmacht")
          },
          "action-COPY_SIGNED_ANTRAG": _vm.copySignedAntrag,
          "action-ADDITIONAL": _vm.doClickAdditional,
          "action-TOGGLE-E-SIGNATUR": _vm.sendFormToElectronicSignature
        },
        scopedSlots: _vm._u(
          [
            _vm.$slots.title
              ? {
                  key: "title",
                  fn: function() {
                    return [_vm._t("title")]
                  },
                  proxy: true
                }
              : null,
            _vm.$slots.subtitle
              ? {
                  key: "subtitle",
                  fn: function() {
                    return [_vm._t("subtitle")]
                  },
                  proxy: true
                }
              : null,
            {
              key: "action-TOGGLE-AGB",
              fn: function() {
                return [
                  _c("InputToggleSwitch", {
                    staticClass: "py-0",
                    attrs: {
                      disabled: _vm.disabled,
                      label: "inklusive AGBs / weitere Unterlagen",
                      inLineLabel: "",
                      inLineAlignment: "left"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateAgbProperty($event)
                      }
                    },
                    model: {
                      value: _vm.form.agb,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "agb", $$v)
                      },
                      expression: "form.agb"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "action-TOGGLE-RAHMEN-KUNDEN-SAVE",
              fn: function() {
                return [
                  _c("InputToggleSwitch", {
                    staticClass: "py-0",
                    attrs: {
                      disabled: _vm.disabled,
                      label: "Änderungen in Kundeneinstellungen speichern",
                      inLineLabel: "",
                      inLineAlignment: "left"
                    },
                    on: {
                      input: function($event) {
                        return _vm.sendFormRahmenKundenSave($event)
                      }
                    },
                    model: {
                      value: _vm.form.rahmenvereinbarungkundensave,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rahmenvereinbarungkundensave", $$v)
                      },
                      expression: "form.rahmenvereinbarungkundensave"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "action-TOGGLE-SHOW-PDF-LANGVERSION",
              fn: function() {
                return [
                  _c("InputToggleSwitch", {
                    staticClass: "py-0",
                    attrs: {
                      label: "PDF in Langversion",
                      inLineLabel: "",
                      inLineAlignment: "left",
                      disabled: _vm.disabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.setShowPDFLangversion($event)
                      }
                    },
                    model: {
                      value: _vm.form.showPDFLangversionSwitch,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "showPDFLangversionSwitch", $$v)
                      },
                      expression: "form.showPDFLangversionSwitch"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "BaseModal",
        {
          ref: "multisignable",
          attrs: { labelButtonCancel: "Zurück", showConfirmButton: false },
          on: {
            onCancelButton: _vm.closeMultisignModal,
            onCloseButton: _vm.closeMultisignModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Dokumente zur Signatur ")]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.signoList, function(signoElement, index) {
          return _c("div", { key: index, staticClass: "row" }, [
            signoElement.signaturStatus !== "KOMPLETT"
              ? _c(
                  "a",
                  {
                    attrs: { target: "_blank" },
                    on: {
                      click: function($event) {
                        return _vm.handleSingleSignature(
                          signoElement,
                          true,
                          _vm.$refs.multisignable &&
                            _vm.$refs.multisignable.toClose
                        )
                      }
                    }
                  },
                  [_vm._v(_vm._s(signoElement.fileName))]
                )
              : _c("span", { staticClass: "grayed-out" }, [
                  _vm._v(_vm._s(signoElement.fileName))
                ])
          ])
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "multi_pdf",
          attrs: { labelButtonCancel: "Zurück", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Dokumente ")]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.pdfList, function(file, index) {
          return _c(
            "div",
            { key: index, staticClass: "row" },
            [
              _c("DownloadLink", {
                attrs: {
                  title: _vm.getTitle(file),
                  href: _vm.getPDFLinkForMultiPdf(file)
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "hatFehler",
          attrs: {
            labelButtonConfirm: "Trotzdem anzeigen",
            labelButtonCancel: "Zurück",
            showConfirmButton: _vm.hatFehlerConfirm
          },
          on: {
            onConfirmButton: _vm.confirmFehlerModal,
            onCancelButton: _vm.closeFehlerModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehlerliste für Antrag ")
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.pdfWarnings, function(warning, index) {
          return _c("div", { key: index, staticClass: "row" }, [
            _c("div", {
              staticClass: "col mb-2",
              domProps: { innerHTML: _vm._s(_vm.sanitize(warning)) }
            })
          ])
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "hatInfo",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          on: { onCancelButton: _vm.closeInfoModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-info", {
                    staticStyle: { color: "var(--color-success)" }
                  }),
                  _vm._v(" Information ")
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.pdfInfos, function(info, index) {
          return _c("div", { key: index, staticClass: "row" }, [
            _c("div", {
              staticClass: "col mb-2",
              domProps: { innerHTML: _vm._s(_vm.sanitize(info)) }
            })
          ])
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "needsConfirmation",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.confirm(true)
            },
            onCancelButton: function($event) {
              return _vm.confirm(false)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" " + _vm._s(_vm.confirmTitle) + " ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v(" " + _vm._s(_vm.confirmText) + " ")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "berichtDok",
          attrs: { showDefaultButtons: false, labelButtonCancel: "Abbrechen" },
          on: { onCancelButton: _vm.closeBerichtListModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-file", { staticClass: "mr-2", attrs: { size: 24 } }),
                  _vm._v(" Bericht erstellen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Achtung: Die Dateigröße der Factsheets ist sehr umfangreich. Dies kann zu längeren Downloadzeiten führen. "
            )
          ]),
          _vm._l(_vm.berichtList, function(dok, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("InputToggleSwitch", {
                    attrs: { label: dok.label, inLineLabel: "" },
                    model: {
                      value: _vm.berichtDokForm[dok.name],
                      callback: function($$v) {
                        _vm.$set(_vm.berichtDokForm, dok.name, $$v)
                      },
                      expression: "berichtDokForm[dok.name]"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _c("DownloadLink", {
            attrs: {
              asButton: "",
              title: "Erzeugen",
              href: _vm.getBerichtErstellenLink
            }
          }),
          _vm.eMailVersandEnabled
            ? _c(
                "BaseButton",
                {
                  staticClass: "mr-2",
                  attrs: { isSecondary: "" },
                  on: {
                    click: function($event) {
                      return _vm.berichtVersenden()
                    }
                  }
                },
                [_vm._v("Bericht per E-Mail versenden")]
              )
            : _vm._e()
        ],
        2
      ),
      _c("PictureAttachmentsModal", {
        ref: "pictureAttachmentsModal",
        attrs: { selectedDoc: _vm.selectedDoc },
        on: {
          updateLoadingStatus: function($event) {
            return _vm.updateLoadingStatus($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "komplett",
          attrs: { showConfirmButton: false, labelButtonCancel: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Formular ist bereits abgeschlossen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Das Dokument wurde bereits elektronisch signiert und abgeschlossen. "
            ),
            _c("br"),
            _vm._v(" Eine Änderung ist daher nicht mehr möglich. "),
            _c("br")
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "teilweise",
          attrs: { showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Formular ist bereits teilweise signiert ")
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.pdfNeuErstellen()
                            }
                          }
                        },
                        [_vm._v("PDF neu erstellen ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.showSignoListFromTeilweise()
                            }
                          }
                        },
                        [_vm._v("PDF unverändert lassen")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c("BaseButton", { attrs: { isSecondary: "" } }, [
                        _vm._v("Abbrechen")
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Das Dokument wurde bereits teilweise elektronisch signiert. "
            ),
            _c("br"),
            _vm._v(
              " Möchten Sie eine neue PDF mit den geänderten Daten erstellen (alle bisherigen Unterschriften gehen verloren) oder die Daten nur speichern ohne sie in die PDF zu übernehmen? "
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "fehlerStatusModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Antrag enthält Fehler. ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "var(--color-danger) font-bold" }, [
            _c("hr"),
            _vm._v(
              " Alle Fehler müssen korrigiert werden, um das Dokument erzeugen zu können. "
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "var(--color-danger) font-bold" }, [
            _c("hr"),
            _vm._v(" " + _vm._s(_vm.serverError) + " ")
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "videoIdent",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.startVideoOnline()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Videolegitimation Vorgang starten ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", {}, [
            _vm._v(
              " Die Depoteröffnung wird nun an die FFB übermittelt und Ihr Kunde erhält ein ToDo zur Online Legitimation."
            ),
            _c("br"),
            _c("br"),
            _vm._v(" Danach kann der Antrag nicht mehr geändert werden."),
            _c("br"),
            _c("br"),
            _vm._v(" Möchten Sie diesen Vorgang fortsetzen? ")
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Bitte warten... "), _c("AnimatedSpinner")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c(
              "span",
              [
                _vm._v("Warte auf Antwort vom Webservice... "),
                _c("AnimatedSpinner")
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }