var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      key: _vm.menuItemKey,
      staticClass: "option-menu-edit-modal__item",
      class: {
        "is-permission-disabled": _vm.isPermissionDisabled,
        "has-remaining-actions": _vm.hasRemainingActions,
        "is-from-struktur": _vm.isFromStruktur,
        "sub-menu-view-active": _vm.isSubMenuViewActive
      },
      attrs: { "data-sortable-item": _vm.isSortable }
    },
    [
      _c("div", { staticClass: "option-menu-edit-modal__item--wrap" }, [
        _c("span", { staticClass: "option-menu-edit-modal__item--label" }, [
          !_vm.hideParentLabel
            ? _c("span", {
                staticClass: "option-menu-edit-modal__item--parent-label",
                domProps: { innerHTML: _vm._s(_vm.parentLabel) }
              })
            : _vm._e(),
          _c(
            "span",
            { staticClass: "option-menu-edit-modal__item--menu-label" },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.menu.label)) }
              }),
              _vm.hasSubMenu && _vm.isExpandedLayout
                ? _c(
                    "button",
                    {
                      staticClass:
                        "option-menu-edit-modal__item--toggle-sub-menu-view btn-clear clickable",
                      class: { active: _vm.isSubMenuViewActive },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.isSubMenuViewActive = !_vm.isSubMenuViewActive
                        }
                      }
                    },
                    [_c("PhCaretDown", { attrs: { size: 16 } })],
                    1
                  )
                : _vm._e()
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "option-menu-edit-modal__item--actions" },
          [
            _vm.isExpandedLayout
              ? [
                  !_vm.isConfiguredMenuRemoveable
                    ? _c("PhEye", {
                        staticClass:
                          "option-menu-edit-modal__item--non-removeable-info",
                        attrs: { weight: "fill" }
                      })
                    : _vm._e(),
                  _vm.isPermissionConfigurable &&
                  !_vm.menu.unmodifiablePermission &&
                  !_vm.menu.async
                    ? _c("OptionMenuPermissionToggleSwitch", {
                        attrs: {
                          menu: _vm.menu,
                          userLevel: _vm.userLevel,
                          configContext: _vm.configContext,
                          indeterminate: _vm.isIndeterminate
                        },
                        on: { input: _vm.onPermissionInput }
                      })
                    : _vm._e(),
                  _vm.isFromStruktur
                    ? [
                        _c("Pill", {
                          attrs: { label: "Standard", type: "default" }
                        })
                      ]
                    : _vm.isAvailable
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn-clear clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("add", _vm.menu)
                              }
                            }
                          },
                          [_c("PhPlus", { attrs: { size: 16 } })],
                          1
                        )
                      ]
                    : _vm.hasRemainingActions
                    ? _c(
                        "BaseContextMenu",
                        {
                          staticClass:
                            "option-menu-edit-modal__item--actions-context-menu"
                        },
                        [
                          _vm.isLockable && _vm.isLocked
                            ? [
                                _c(
                                  "ContextMenuItem",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("unlock", _vm.menu)
                                      }
                                    }
                                  },
                                  [
                                    _c("PhLock", {
                                      attrs: { size: 16, weight: "fill" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("fixiert")])
                                  ],
                                  1
                                )
                              ]
                            : !_vm.isConfiguredMenuRemoveable
                            ? [
                                _c(
                                  "ContextMenuItem",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "toggleRemoveable",
                                          _vm.menu
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("PhEye", {
                                      attrs: { size: 16, weight: "fill" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("nicht entfernbar")])
                                  ],
                                  1
                                )
                              ]
                            : [
                                _vm.isToggleRemoveable
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "toggleRemoveable",
                                              _vm.menu
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("PhEye", { attrs: { size: 16 } }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("nicht entfernbar")])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isLockable
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("lock", _vm.menu)
                                          }
                                        }
                                      },
                                      [
                                        _c("PhLock", { attrs: { size: 16 } }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("fixieren")])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isRemoveable
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("remove", _vm.menu)
                                          }
                                        }
                                      },
                                      [
                                        _c("PhTrashSimple", {
                                          attrs: { size: 16 }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("ausblenden")])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isSortable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-clear",
                          attrs: {
                            type: "button",
                            "data-sortable-item-handler": ""
                          }
                        },
                        [_c("PhList", { attrs: { size: 16, weight: "bold" } })],
                        1
                      )
                    : _vm._e()
                ]
              : [
                  _vm.isFromStruktur
                    ? [
                        _c("Pill", {
                          attrs: { label: "Standard", type: "default" }
                        })
                      ]
                    : _vm.isAvailable
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn-clear clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("add", _vm.menu)
                              }
                            }
                          },
                          [_c("PhPlus", { attrs: { size: 16 } })],
                          1
                        )
                      ]
                    : _vm.isLockable && _vm.isLocked
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn-clear clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("unlock", _vm.menu)
                              }
                            }
                          },
                          [
                            _c("PhLock", {
                              attrs: { size: 16, weight: "fill" }
                            })
                          ],
                          1
                        )
                      ]
                    : !_vm.isConfiguredMenuRemoveable
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn-clear clickable",
                            attrs: {
                              type: "button",
                              disabled: !_vm.isConfiguredMenuRemoveableChangeable
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("toggleRemoveable", _vm.menu)
                              }
                            }
                          },
                          [
                            _c("PhEye", { attrs: { size: 16, weight: "fill" } })
                          ],
                          1
                        )
                      ]
                    : [
                        _vm.isToggleRemoveable
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-clear clickable",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "toggleRemoveable",
                                      _vm.menu
                                    )
                                  }
                                }
                              },
                              [_c("PhEye", { attrs: { size: 16 } })],
                              1
                            )
                          : _vm._e(),
                        _vm.isLockable
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-clear clickable",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("lock", _vm.menu)
                                  }
                                }
                              },
                              [_c("PhLock", { attrs: { size: 16 } })],
                              1
                            )
                          : _vm._e(),
                        _vm.isRemoveable
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-clear clickable",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("remove", _vm.menu)
                                  }
                                }
                              },
                              [_c("PhTrashSimple", { attrs: { size: 16 } })],
                              1
                            )
                          : _vm._e()
                      ],
                  _vm.isSortable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-clear",
                          attrs: {
                            type: "button",
                            "data-sortable-item-handler": ""
                          }
                        },
                        [_c("PhList", { attrs: { size: 16, weight: "bold" } })],
                        1
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      ]),
      _vm.hasSubMenu && _vm.isExpandedLayout
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSubMenuViewActive,
                  expression: "isSubMenuViewActive"
                }
              ],
              staticClass: "option-menu-edit-modal__item--sub-menu"
            },
            [
              _c("OptionMenuConfigList", {
                attrs: {
                  configId: _vm.configId,
                  menus: _vm.menu.subMenu,
                  userLevel: _vm.userLevel,
                  configContext: _vm.configContext,
                  hideParentLabel: "",
                  isPermissionConfigurable: _vm.isPermissionConfigurable,
                  isExpandedLayout: _vm.isExpandedLayout
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }