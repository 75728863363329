<template>
  <div>
    <div v-if="getPositions && getPositions.length">
      <div class="small" v-if="config && config.comment">{{config.comment}}</div>
      <FormLabel v-if="isEditDisabled" class="mb-2"
        label='Diese Daten sind bei der Löschung nicht notwendig und werden daher nicht angedruckt.'
      />

      <Table
        v-if="rows && rows.length"
        :headers="tableHeaders"
        :rows="rows"
        rowId="index"
        hidePagination
        valign="top"
        @action-DELETE="openModalDeleteFond"
      >
      <template v-slot:betrag="row">
          <InputField :type="betragType" :value="row.betrag" :disabled="isEditDisabled" :placeholder="config && config.betrag && config.betrag.placeholder" 
            :id="'betrag' + row.index" 
            @input="onChangeInputField($event, row, getBetragId)"/>
        </template>
        <template v-slot:aa="row">
          <InputField type="percent" :value="row.aa" :id="'aa' + row.index" 
            :disabled="disabled"
            :placeholder="row.defaultAA"
            @input="onChangeInputField($event, row, 'aa')"/>
        </template>

         <template v-slot:modus="row">
          <ComboBox  :value="row.modus" :id="'modus' + row.index" 
            :values="values('modus', row)"
            :firstEmpty="true"
            :disabled="isDisabled('modus', row)"
            @input="onChangeInputField($event, row, 'modus')"/>
        </template>
        <template v-slot:lagerstelle="row">
          <ComboBox  :value="row.lagerstelle" :id="'lagerstelle' + row.index" 
            :values="values('lagerstelle', row)"
            :firstEmpty="true"
            :disabled="isDisabled('lagerstelle', row)"
            @input="onChangeInputField($event, row, 'lagerstelle')"/>
        </template>
        <template v-slot:depotnummer="row">
          <InputField :value="row.depotnummer" :id="'depotnummer' + row.index" 
            :disabled="isDisabled('depotnummer', row)"
            @input="onChangeInputField($event, row, 'depotnummer')"/>
        </template>
        <template v-slot:frequenz="row">
          <ComboBox  :value="row.frequenz" :id="'frequenz' + row.index" 
            :values="values('frequenz', row)"
            :disabled="isDisabled('frequenz', row)"
            :firstEmpty="true"
            @input="onChangeInputField($event, row, 'frequenz')"/>
        </template>
         <template v-slot:dynamik="row">
          <InputField type="percent" :value="row.dynamik" :id="'dynamik' + row.index" 
            :disabled="isDisabled('dynamik', row)"
            @input="onChangeInputField($event, row, 'dynamik')"/>
        </template>
        <template v-slot:zielsumme="row">
          <InputField type="currency" :precision="2" :value="row.zielsumme" :id="'zielsumme' + row.index" 
            :disabled="isDisabled('zielsumme', row)"
            @input="onChangeInputField($event, row, 'zielsumme')"/>
        </template>
        <template v-slot:laufzeit="row">
          <InputField type="number" :value="row.laufzeit" :id="'laufzeit' + row.index" 
            :disabled="isDisabled('laufzeit', row)"
            @input="onChangeInputField($event, row, 'laufzeit')"/>
        </template>
        <template v-slot:bemerkung="row">
          <InputField  :value="row.bemerkung" :id="'bemerkung' + row.index" 
            :disabled="isDisabled('bemerkung', row)"
            @input="onChangeInputField($event, row, 'bemerkung')"/>
        </template>
         <template v-slot:provfrei="row">
          <input-toggle-switch  :value="row.provfrei" :id="'provfrei' + row.index" 
            :disabled="isDisabled('provfrei', row)"
            @input="onChangeInputField($event, row, 'provfrei')"/>
        </template>
         <template v-slot:depotuebertrag="row">
          <input-toggle-switch  :value="row.depotuebertrag" :id="'depotuebertrag' + row.index" 
            :disabled="isDisabled('depotuebertrag', row)"
            @input="onChangeInputField($event, row, 'depotuebertrag')"/>
        </template>
        <template v-slot:keineIndexierung="row">
          <InputToggleSwitch  :value="row.keineIndexierung" :id="'keineIndexierung' + row.index" 
            :disabled="isDisabled('keineIndexierung', row)"
            @input="onChangeInputField(!$event, row, 'indexierung')"/>
        </template>
      </Table>
      
      <NoData v-else/>
    </div>

    <DeletePositionModal ref="deleteModal" 
      :position="positionToDelete"
      @delete="doDeletePosition()"
    ></DeletePositionModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Table from "@/components/table2/Table.vue";
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import Summenbildung from '@/components/antrag/Summenbildung.vue';
import { TextColumn, ActionColumn, SlotColumn, IconColumn, SimpleAction, } from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  components: {
    InputField,
    ComboBox,
    WertpapierAuswahlPositionsButtons,
    DeletePositionModal,
    Table,
    InputToggleSwitch,
    FormLabel,
    Summenbildung,
    NoData,
  },
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      positionToDelete : {
      },
    }
  },
  mounted() {
    this.updateWarnings();
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA
    }),
    tableHeaders() {
      const headers = { 
        lockedLeft: [
          TextColumn('isin', 'ISIN'),
          TextColumn('fondsname', 'Fondsname'),
        ],
        center: [
        ],
        lockedRight: [ActionColumn('actions', '')],
      };
      if (!this.config.noBetrag) {
        const betragColumn = SlotColumn('betrag', this.betragHeader, 100);
        if (this.config?.hasSumme && this.betragType !== 'text') {
          betragColumn.withSumFooter(this.betragType).withConstantFooter(() =>
               `<span class="color-danger text-bold"></span>`)
        }
        headers.center.push(betragColumn)
      }
      if (this.config?.individualAA) {
        headers.center.push(SlotColumn('aa', this.config?.individualAA && typeof(this.config?.individualAA) === 'string' ? this.config?.individualAA : 'Ausgabeaufschlag', 100))
      }
      if (this.config?.modus) {
        headers.center.push(SlotColumn('modus', this.config?.modus?.label || '', 100))
      }
      if (this.config?.depotnummer) {
        headers.center.push(SlotColumn('depotnummer', this.config?.depotnummer?.label || '', 100))
      }
      if (this.config?.provfrei) {
        headers.center.push(SlotColumn('provfrei', this.config?.provfrei?.label || '', 100))
      }
      if (this.config?.depotuebertrag) {
        headers.center.push(SlotColumn('depotuebertrag', this.config?.depotuebertrag?.label || '', 100))
      }
      if (this.config?.dynamik) {
        headers.center.push(SlotColumn('dynamik', this.config?.dynamik?.label || '', 100))
      }
      if (this.config?.laufzeit) {
        headers.center.push(SlotColumn('laufzeit', this.config?.laufzeit?.label || '', 100))
      }
      if (this.config?.zielsumme) {
        headers.center.push(SlotColumn('zielsumme', this.config?.zielsumme?.label || '', 100))
      }
      if (this.config?.lagerstelle) {
        headers.center.push(SlotColumn('lagerstelle', this.config?.lagerstelle?.label || '', 100))
      }
      if (this.config?.frequenz) {
        headers.center.push(SlotColumn('frequenz', this.config?.frequenz?.label || '', 100))
      }
      if (this.config?.isPositionZielfondsSparplan) {
        headers.center.push(IconColumn('isPositionZielfondsSparplan', '', 100))
      }
      if (this.config?.bemerkung) {
        headers.center.push(SlotColumn('bemerkung', 'Bemerkung', 100))
      }
      if (this.config?.hasIndexierung) {
        headers.center.push(SlotColumn('keineIndexierung', 'Keine Indexiereung', 100))
      }
      return headers;
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return this.getPositions?.map((pos, index) => {
          const row = Object.assign(pos, { 
            fondsname: this.getFondsName(pos, this.positionInfo),
            betrag: pos[this.getBetragId] || 0,
            index: index,
            aa: this.config?.aaVariableName ? pos[this.config.aaVariableName] : pos.aa,
            defaultAA: this.positionInfo[pos.isin]?.defaultAusgabeaufschlag != null ? "" + this.positionInfo[pos.isin].defaultAusgabeaufschlag : "",
            isPositionZielfondsSparplan: this.config.isPositionZielfondsSparplan,
            actions: actions
          });
          if (this.config?.hasIndexierung) {
            if (!Object.keys(pos).includes('indexierung')) {
              row.indexierung = pos.art === 'SPARPLAN' ? true : false;
            }
            row.keineIndexierung = !row.indexierung;
          }
          return row;
        }) || [];
    },
    betragHeader() {
      return this.config && this.config.betrag && this.config.betrag.label || 'Betrag';
    },
    betragType() {
      let type = 'currency';
      if (this.config && this.config.betrag && this.config.betrag.type) {
        switch(this.config.betrag.type) {
          case 'TEXT_PROZENT': 
            type = 'percent';
            break;
          case 'TEXT': 
            type = 'text'
            break;
          case 'TEXT_NUMBER':
            type = 'number';
            break;
        }
      }
      return type;
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    getPositions() {
      if (this.positions && this.positions.length) {
        this.positions.filter(pos => !pos[this.getBetragId] && pos[this.getBetragId] !== 0).forEach(pos => 
        this.onChangeInputField(0, pos, this.getBetragId));
      }
      return this.positions;
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        this.updateWarnings(this.antrag.warnings && this.antrag.warnings.positionWarnings);
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null
    },
    dynamicValidators() {
      if (this.config && this.config.betrag && this.config.betrag.validators) {
        const dynamicValidators = this.getPositions
          .map(pos => ({
            componentId: 'betrag' + pos.index,
            validators: this.config.betrag.validators.map(componentValidatorToRule)
          })
        )

        return dynamicValidators
      }
    },
    getBetragId() {
      return this.config && this.config.betrag && this.config.betrag.id
    },
    isEditDisabled() {
      return this.disabled || this.antragData?.["FFB-splittsparplan"]?.["disableEdit"];
    },
  },
  methods: {
    values(id, prop) {
      return id && this.config?.[id]?.values || [];
    },
    isDisabled(id, row) {
      return this.disabled || (id && this.config?.[id]?.disabled && isHiddenDisabled(this.config?.[id]?.disabled, row));
    },
    updateWarnings() {
      if (this.antrag?.warnings?.positionWarnings) {
        for (const warn of this.antrag?.warnings?.positionWarnings) {
          if (warn.posGrpId) {
            if (!warn.id || warn.id.includes(this.categoryId)) {
              const index = this.getPositions.findIndex(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId);
              if (index >= 0) {
                this.$pushErrors('betrag' + index, warn.message || '');
              }
            }
          }
        }
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      if (field == 'aa' && this.config?.aaVariableName) {
        field = this.config.aaVariableName;
      }
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    openModalDeleteFond(fond) {
      if (!this.disabled) {
        this.positionToDelete = fond;
        this.$refs.deleteModal.open()
      }
    },
    doDeletePosition() {
      if (this.positionToDelete) {
        this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
        this.positionToDelete = null;
      }
    }
  },
}
</script>

<style scoped>
  .small {
    font-size: small;
    color: var(--color-danger);
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
</style>