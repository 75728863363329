var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Vermittlerstruktur"
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "4b819bbd-d86c-4a2f-8b79-9f5ef8b7f205" }
        },
        [
          _vm.struckturData &&
          _vm.struckturData.nodes &&
          _vm.struckturData.nodes.length
            ? _c("Tree", {
                staticClass: "strucktur__tree_nodes",
                attrs: {
                  items: [_vm.struckturData],
                  childrenKey: "nodes",
                  idKey: "nodeId",
                  itemSelectedId: _vm.selectedNodeId,
                  uniqueId: "c557f5e8-82eb-11ee-b962-0242ac120002"
                },
                on: {
                  itemSelect: function($event) {
                    return _vm.selectNode($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "strucktur__tree_node_content" },
                            [
                              _vm.maklernr !== item.nodeId
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "strucktur__tree_node_label",
                                      on: {
                                        click: function($event) {
                                          return _vm.openBroker(item.nodeId)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                : _vm._e(),
                              _vm.maklernr == item.nodeId
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "strucktur__tree_node_label"
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                : _vm._e(),
                              item.icon
                                ? _c("Pill", {
                                    staticClass: "strucktur__tree_node_pill",
                                    attrs: { label: item.icon, type: "info" }
                                  })
                                : _vm._e(),
                              item.numberOfRecords
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "strucktur__tree_node_total"
                                    },
                                    [_vm._v(_vm._s(item.numberOfRecords))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  60042733
                )
              })
            : _vm.loadingMitarbeiterstruktur
            ? _c("GhostLoading", {
                staticClass: "mt-2",
                attrs: {
                  type: "block",
                  config: { block: { height: 24 } },
                  repeat: 10
                }
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }