import { PrimaryMenu, MenuItem, TabMenu, } from './../menu-utils';

import { PhIdentificationCard, } from 'phosphor-vue';

import UndrawPeopleSearch from '@/components/icons/undraw/UndrawPeopleSearch.vue'
import UndrawWarningCyit from '@/components/icons/undraw/UndrawWarningCyit.vue'
import UndrawOnlineTransactions from '@/components/icons/undraw/UndrawOnlineTransactions.vue'
import UndrawDataPoints from '@/components/icons/undraw/UndrawDataPoints.vue'
import UndrawOnlineFriends from '@/components/icons/undraw/UndrawOnlineFriends.vue'
import UndrawGoals from '@/components/icons/undraw/UndrawGoals.vue'
import UndrawSweetHome from '@/components/icons/undraw/UndrawSweetHome.vue';

const CUSTOMER_SEARCH_PATH = '/customer/customer-search';

export default PrimaryMenu('/customer', 'Kundenverwaltung', PhIdentificationCard, [
  MenuItem('/customer/customer-search', 'Kundensuche', UndrawPeopleSearch),
  MenuItem('/customer/maklersuche', 'Vermittlersuche', UndrawPeopleSearch),
  MenuItem('/customer/immobilien', 'Immobilien Suche', UndrawSweetHome),
  MenuItem('/customer/transaktionen', 'Transaktionen', UndrawOnlineTransactions),
  MenuItem('/customer/verbindlichkeiten/credit', 'Verbindlichkeiten'),
  MenuItem('/customer/weitere-verbindlichkeiten', 'Weitere Verbindlichkeiten Suche', null),
  MenuItem('/customer/weitere-vermoegen', 'Weiteres Vermögen Suche', null),
  MenuItem('/customer/stornos', '<nobr>Wichtige Warnungen</nobr>, Stornos, Informationen', UndrawWarningCyit),
  MenuItem('/customer/eingegangene-transaktionen', 'Eingegangene Transaktionen', UndrawDataPoints),
  MenuItem('/customer/customer-category', 'Kundenkategorie'),
  MenuItem('/customer/vermittlerwechsel', 'Vermittlerwechsel Status', UndrawOnlineFriends),
  MenuItem('/customer/merge-people', 'Personen zusammenführen', UndrawGoals),
  MenuItem('/customer/limit', 'Limits', null, [
    TabMenu('/customer/limit/global-wertpapierlimits', 'Globale Wertpapierlimits'),
    TabMenu('/customer/limit/depotpositionlimits', 'Depotpositionslimits'),
    TabMenu('/customer/limit/automatic-depotpositionlimits', 'Automatische Depotpositionslimits'),
    TabMenu('/customer/limit/depotlimits', 'Depotlimits'),
    TabMenu('/customer/limit/risikoklassenlimits', 'Risikoklassenlimits'),
    TabMenu('/customer/limit/global-gd200-limit', 'Globale <nobr>GD200-Limits</nobr>'),
    TabMenu('/customer/limit/depotpositionen-gd200-limit', 'Depotpositionen <nobr>GD200-Limits</nobr>'),
    TabMenu('/customer/limit/wertpapierkategorie-limits', 'Wertpapierkategorie Limits'),
  ]),
]).withAutoSelectTo(CUSTOMER_SEARCH_PATH);
