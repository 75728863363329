var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container" },
    [
      _vm.scrollHorizontally
        ? _c(
            "PrimitiveScrollTable",
            {
              attrs: {
                headers: _vm.topHeaders,
                rows: _vm.visibleRows,
                maxCollapsedWidth: _vm.containerWidth - 18,
                scrollButtonWidth: _vm.scrollButtonWidth
              },
              on: { files: _vm.handleFile },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.headersFlat, function(ref) {
                    var key = ref.key
                    return {
                      key: key,
                      fn: function(data) {
                        return [_vm._t(key, null, null, data)]
                      }
                    }
                  })
                ],
                null,
                true
              )
            },
            [
              _vm._l(_vm.headersFlat, function(ref) {
                var key = ref.key
                return _c(
                  "template",
                  { slot: "header_" + key },
                  [_vm._t("header_" + key)],
                  2
                )
              })
            ],
            2
          )
        : _c(
            "PrimitiveTable",
            {
              attrs: {
                topHeaders: _vm.shownHeaders,
                collapsedHeaders: _vm.collapsedHeaders,
                rows: _vm.visibleRows,
                maxCollapsedWidth: _vm.containerWidth - 18,
                sortableRows: _vm.sortableRows,
                valign: _vm.valign
              },
              on: {
                files: _vm.handleFile,
                closeRowModal: _vm.closeRowModal,
                orderChanged: function($event) {
                  return _vm.$emit("orderChanged", $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "_v",
                    fn: function(ref) {
                      var id = ref.id
                      var index = ref.index
                      var open = ref.open
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickable _v hide-on-small-screen",
                            on: {
                              click: function($event) {
                                return _vm.toggleRow(id, index)
                              }
                            }
                          },
                          [
                            open
                              ? _c("PhCaretDown", { attrs: { size: 16 } })
                              : _c("PhCaretRight", { attrs: { size: 16 } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "clickable _v hide-on-large-screen",
                            on: {
                              click: function($event) {
                                return _vm.openRowModal(id, index)
                              }
                            }
                          },
                          [_c("PhCaretRight", { attrs: { size: 16 } })],
                          1
                        )
                      ]
                    }
                  },
                  _vm._l(_vm.headersFlat, function(ref) {
                    var key = ref.key
                    return {
                      key: "header_" + key,
                      fn: function(data) {
                        return [_vm._t("header_" + key, null, null, data)]
                      }
                    }
                  }),
                  _vm._l(_vm.headersFlat, function(ref) {
                    var key = ref.key
                    return {
                      key: key,
                      fn: function(data) {
                        return [_vm._t(key, null, null, data)]
                      }
                    }
                  }),
                  {
                    key: "bottom__table",
                    fn: function() {
                      return [_vm._t("bottom__table")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _c("template", { slot: "_sortable_handler" }, [
                _c(
                  "div",
                  {
                    staticClass: "_sortable-handler",
                    attrs: { "data-sortable-item-handler": "" }
                  },
                  [_c("PhList", { attrs: { size: 16 } })],
                  1
                )
              ])
            ],
            2
          ),
      _c(
        "PrimitiveFlexTable",
        {
          staticStyle: { position: "fixed", top: "-999%", left: "-999%" },
          attrs: { headers: _vm.allHeaders, rows: _vm.rows },
          on: { resize: _vm.onAllResize },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.headersFlat, function(ref) {
                var key = ref.key
                return {
                  key: key,
                  fn: function(data) {
                    return [_vm._t(key, null, null, data)]
                  }
                }
              }),
              {
                key: "bottom__table",
                fn: function() {
                  return [_vm._t("bottom__table")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c("template", { slot: "_sortable_handler" }, [
            _c(
              "div",
              { attrs: { "data-sortable-item-handler": "" } },
              [_c("PhList", { attrs: { size: 16 } })],
              1
            )
          ]),
          _c("template", { slot: "_v" }, [
            _c(
              "div",
              { staticClass: "clickable _v" },
              [_c("PhCaretRight", { attrs: { size: 16 } })],
              1
            )
          ]),
          _c(
            "template",
            { slot: "header__l" },
            [
              _c(
                "BaseButton",
                { staticStyle: { margin: "0" } },
                [_c("PhCaretRight", { attrs: { size: 24 } })],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "header__r" },
            [
              _c(
                "BaseButton",
                { staticStyle: { margin: "0" } },
                [_c("PhCaretRight", { attrs: { size: 24 } })],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.headersFlat, function(ref) {
            var key = ref.key
            return _c(
              "template",
              { slot: "header_" + key },
              [_vm._t("header_" + key)],
              2
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }