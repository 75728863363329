<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="200-Tage-Linie(GD200) Limit"
            :id="$appNavigation.currentOptionMenuId"
            :actions="actions"
            @action-RESET="reset()">
        </PageHeaderTitleNavigation>

        <div v-if="!isFondsfinder">
            <div class="box__container">
                <div  class="box__title">200-Tage-Linie(GD200) Limit</div>
                <div>
                    Zum Setzen des Limits kann eine prozentuale Abweichung basierend auf dem gleitenden Durchschnitt der letzten 200 Tage (GD200) gesetzt werden. 
                    Soll ein Limit (oberes oder unteres) nicht beachtet werden so ist dieser Wert leer zu 
                    lassen bzw. auf 0 zu setzen.
                </div>
                <div class="mt-2">
                    Beispiel: Unteres Limit 5% bedeutet, dass die Limitwarnung ausgelöst wird, wenn der Wertpapierpreis unter den 200 Tage Durchschnittswert - 5% fällt.
                </div>
            </div>

            <div class="box__container">
                <WertpapierAuswahlUnique label="Wertpapier" 
                    :data="limit" 
                    id="isin" 
                    :config="{isEmbedded: true}" 
                    :hasDelete="false"
                    :disabled="wertpapierDisabled"
                    @openFondsfinder="isFondsfinder=true" 
                />
                <div class="info-block">
                    <div v-if="modus === 'LIMIT_GD200_BGS'">Kunde: {{limit.kundenName}}</div>
                    <div>Währung: {{limit.currency}}</div>
                    <div>Aktueller Kurs: {{formatCurrency(limit.aktuellKurs)}}</div>
                    <div>Kursdatum: {{limit.kursdatum}}</div>
                    <div>Aktueller Wert GD200: {{formatCurrency(limit.gd200wert)}}</div>
                </div>
                <InputField
                    label="Abweichung nach unten (relativ)"
                    v-model="form.unteresLimitAbweichung"
                    type="percent"
                    :precision="0"
                    isComponentHalfSize />
                <InputField
                    label="Abweichung nach oben (relativ)"
                    v-model="form.oberesLimitAbweichung"
                    :precision="0"
                    type="percent"
                    isComponentHalfSize />

                <div>Letzte Änderung: {{limit.lastChangeDate}}</div>
                <div>Datum der Überschreitung: {{limit.informedDate}}</div>

                <InputTextArea
                    label="Bemerkung"
                    v-model="form.bemerkung" 
                    isComponentHalfSize />
            </div>
        </div>
        <div v-else>
            <FondsFinderSuchMaske/>

            <FondsFinderPositionsList 
                ref="fondsfinderResult"
                :goBackEnabled="true"
                :singleSelectionProp="true"
                :hasDefaultButton="true"
                @goBack="isFondsfinder = false"
                @getSelectedOutcome="setIsin($event)"
            />
        </div>

        <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmNextRoute" />

    </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import {toCurrencyString} from '@/filters.js';
import mixin from '@/mixins/limit/limit-mixin.js';
import WertpapierAuswahlUnique from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

export default {
    mixins: [mixin],
    components: {
        BaseButton,
        InputField,
        InputTextArea,
        WertpapierAuswahlUnique,
        FondsFinderSuchMaske,
        FondsFinderPositionsList,
        PageHeaderTitleNavigation,
        LimitErrorModal,
    },
    computed: {
        ...mapGetters({
            gd200limit: LIMIT_TYPES.GETTERS.GD200_LIMIT,
        }),
        limit() {
            return this.gd200limit || {};
        },
        title() {
            return `Depotlimit ${this.limit?.notExist ? '(nicht gesetzt)' : ''}`;
        },
        wertpapierDisabled() {
            return this.id ? true : false;
        },
        actions() {
            return [
                PageHeaderSimpleAction('RESET', 'Zurücksetzen').withDisabled(() => !this.id || this.limit?.notExist),
            ]
        }
    },
    data() {
        return {
            form: {},
            id: '',
            modus: '',
            isin: '',
            isFondsfinder: false,
            errorFromService: null,
            nextRoute: false,
        }
    },
    watch: {
        limit(value) {
            if (Object.keys(value).length) {
                this.form = value;
                if (value.id) {
                    this.id = value.id
                }
                this.isin = value.isin;
            }
        }
    },
    mounted() {
        this.id = this.$route.params.id && this.$route.params.id !== 'neu' ? this.$route.params.id : '';
        this.modus = this.$route.params.modus || 'GD200_LIMIT';
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_GD200_LIMIT, {id: this.id || '', isin: this.isin || '', modus: this.modus});
        this.form = {
            ausgangskurs: '',
            oberesLimitAbweichung: '',
            unteresLimitAbweichung: '',
            dynamic: '0',
            bemerkung: '',
            radioUnteresAbweichung: '',
            radioOberesAbweichung: '',
        }
    },
    methods: {
        formatCurrency(value) {
            return toCurrencyString(value);
        },
        save() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_GD200_LIMIT, Object.assign(this.form, { modus: this.modus })).then(errorStr => {
                if (errorStr) {
                    this.errorFromService = errorStr;
                    this.$refs.modalError.open();
                } else {
                    this.confirmNextRoute();
                }
            });
        },
        reset() {
            if (this.id) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.RESET_GD200_LIMIT, { id: this.id, modus: this.modus });
            }
        },
        setIsin(data) {
            if (data?.records?.length && data.records[0]?.isin) {
               this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_GD200_LIMIT, {isin: data.records[0]?.isin, modus: this.modus});
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isin) {
            this.nextRoute = next;
            this.save();
        } else {
            next();
        }
    },
}
</script>

<style scoped>
.info-block {
    display: flex;
    flex-direction: column; 
    flex-grow: 2;
    justify-content: space-between;
}
</style>