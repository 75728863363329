import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import MENU_TYPES from '@/store/menu/types';

import menuManager from '@/menu/menu-manager';

function trackRecentMenuOpened(to, from) {
  if(!store.getters[CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE]) {
    store.dispatch(MENU_TYPES.ACTIONS.TRACK_RECENT_MENU_OPENED, { path: from?.path, });
  }
}

function configureMenuManager(to) {
  if (menuManager.started) {
    if (!store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN] || store.getters[CORE_TYPES.GETTERS.HAS_MANDATORY_PENDING_ACTION_ACTIVE]) {
      menuManager.close();
    }

    return;
  }

  menuManager.start(to?.path);
}

const menuManagerHistory = {};
function handleMenuManagerHistory(to) {
  if (!menuManager.started || to?.path?.includes('/noop')) return;

  requestAnimationFrame(() => { // TODO check if there is some key / id for the state on the router stack instead of use the history API directly
    const key = history.state?.key || to.path;

    if (to?.query?.backAction) {
      const savedTracks = menuManagerHistory?.[key];
      if (savedTracks?.length > 0) {
        menuManager.recoverTracks(savedTracks);
      }
    }

    if (!(key in menuManagerHistory)) {
      menuManagerHistory[key] = menuManager.copyTracks();
    }
  });
}

export function registerMenuManagerGuards(router) {
  if (!router) {
    console.error('a router instance must be passed to "registerMenuGuards"');
    return;
  }

  router.afterEach(trackRecentMenuOpened);
  router.afterEach(configureMenuManager);
  router.afterEach(handleMenuManagerHistory);
}
