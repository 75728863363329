<template>
<div>
    <PageHeaderTitleNavigation 
      id="4d5f8286-e61f-4110-a06e-c8f2752042e1" :title="dynamicTitle"
      :actions="headerActions" 
      :noPrimaryAction="false"
      @action-RELOAD-DATA="reloadData()">
    </PageHeaderTitleNavigation>    


    <div class="masonry__container" v-if="!loading">
        <div class="item">
            <div class="card-header">
                <div class="card-title">
                    Details
                </div>
            </div>

            <div class="card-row" v-if="category">
                <div class="card-row-label">Kategorie</div>
                <div class="card-row-value">{{category}}</div>
            </div>

            <div class="card-row" v-if="fileName">
                <div class="card-row-label">Dateiname</div>
                <div class="card-row-value"><a @click="fetchPDF">{{fileName}}</a></div>
            </div>  

            <div class="card-row" v-if="cause">
                <div class="card-row-label">Hinweis</div>
                <div class="card-row-value">{{cause}}</div>
            </div>

            <div class="card-row" v-if="foreignName || foreignVorname">
                <div class="card-row-label">Kunde</div>
                <div class="card-row-value">{{foreignName}} {{foreignVorname}}</div>
            </div> 

            <div class="card-row" v-if="foreignBirthName">
                <div class="card-row-label">Kundengeburtsname</div>
                <div class="card-row-value">{{foreignBirthName}}</div>
            </div>

            <div class="card-row" v-if="foreignAddress">
                <div class="card-row-label">Adresse</div>
                <div class="card-row-value">{{foreignAddress}}</div>
            </div>

            <div class="card-row" v-if="foreignBirthDate">
                <div class="card-row-label">Kundengeburtsdatum</div>
                <div class="card-row-value">{{foreignBirthDate}}</div>
            </div>

            <div class="card-row" v-if="foreignPartnernummer">
                <div class="card-row-label">Fremdnummer</div>
                <div class="card-row-value">{{foreignPartnernummer}}</div>
            </div>

            <div class="card-row" v-if="insuranceId">
                <div class="card-row-label">VSNR</div>
                <div class="card-row-value"><InputField v-model="insuranceId" ref="insuranceIdInputField" validateUntouched/></div>
            </div>

            <div class="card-row" v-if="damageNumber">
                <div class="card-row-label">Schaden-Nr.</div>
                <div class="card-row-value">{{damageNumber}}</div>
            </div>

            <div class="card-row" v-if="licensePlateNumber">
                <div class="card-row-label">KFZ</div>
                <div class="card-row-value">{{licensePlateNumber}}</div>
            </div>

            <div class="card-row" v-if="referenceNumber">
                <div class="card-row-label">Referenz</div>
                <div class="card-row-value">{{referenceNumber}}</div>
            </div>                                                                                                 

        </div>

        <div class="item">
            <div class="card-header">
                <div class="card-title">
                    Vertragsdokumente
                </div>
            </div>            

            <div class="card-row">
                <div class="card-row-label">Kategorie</div>
                <div class="card-row-value"><ComboBox v-model="comboCategory" :values="categories" /></div>
            </div>

            <div class="card-row">
                <div class="card-row-label">Interne Nummer&nbsp;<span :title="interneNummerInfo"><PhInfo :size="24" class="ml-1" /></span></div>
                <div class="card-row-value"><InputField id="mscInternalId" v-model="mscInternalId" ref="mscInternalIdInputField" validateUntouched/> </div>
            </div> 

            <div class="card-row">
                <div class="card-row-label"><BaseButton @click="advancedSearch" isPrimary>Erweiterte Suche</BaseButton><span :title="erweiterteSucheInfo"><PhInfo :size="24" class="ml-1" /></span></div>
                <div class="card-row-value"><ComboBox v-model="comboGesellschaft" :values="gesellschaftOptionsMitDefault" /></div>
            </div>

            <div class="card-row">
                <div class="card-row-value">
                    <InputToggleSwitch :label="prfx + '-Versicherungsnummer übernehmen'" v-model="transferInsuranceNumber" inLineLabel/> 
                </div>
            </div>

            <div class="card-row">
                <div class="card-row-value">            
                    <BaseButton @click="searchInsurances" isSecondary isSmall>
                        Versicherungssuche
                    </BaseButton>

                    <BaseButton @click="buttonVersicherungsdokumentZuordnen" isPrimary :disabled="!mscInternalId"  isSmall>
                        Versicherungsdokument zuordnen
                    </BaseButton>

                    <BaseButton @click="buttonNewCustomer(false)" isPrimary  isSmall>
                        Neukunden anlegen
                    </BaseButton>  


                </div>
            </div>                 


        </div>   

        <div class="item" v-if="showBiproCard">
            <div class="card-header">
                <div class="card-title">
                    BiPRO Daten
                </div>
            </div>            

            <div class="card-row" v-if="biproFileName">
                <div class="card-row-label">Dateiname</div>
                <div class="card-row-value">{{biproFileName}}</div>
            </div>

            <div class="card-row" v-if="biproForeignName">
                <div class="card-row-label">Kundenname</div>
                <div class="card-row-value">{{biproForeignName}}</div>
            </div>

            <div class="card-row" v-if="biproForeingVorname">
                <div class="card-row-label">Kundenvorname</div>
                <div class="card-row-value">{{biproForeingVorname}}</div>
            </div>

            <div class="card-row" v-if="biproForeignGeburtsname">
                <div class="card-row-label">Kundengeburtsname</div>
                <div class="card-row-value">{{biproForeignGeburtsname}}</div>
            </div>

            <div class="card-row" v-if="biproForeignAnshrift">
                <div class="card-row-label"> Kundenanschrift</div>
                <div class="card-row-value">{{biproForeignAnshrift}}</div>
            </div>

            <div class="card-row" v-if="biproForeignGeburtsdatum">
                <div class="card-row-label">Kundengeburtsdatum</div>
                <div class="card-row-value">{{biproForeignGeburtsdatum}}</div>
            </div> 

            <div class="card-row" v-if="biproMSCCategory">
                <div class="card-row-label"> MSC Kategorie</div>
                <div class="card-row-value">{{biproMSCCategory}}</div>
            </div> 

            <div class="card-row" v-if="biproVersicherungsnummer">
                <div class="card-row-label">Versicherungsnummer</div>
                <div class="card-row-value">{{biproVersicherungsnummer}}</div>
            </div>                                                                                   

        </div>

    </div>

    <div v-if="!loading" class="box__container">
        <BigTable v-if="rows.length"
            selectable
            title="Vorschläge"
            tableId="7020d022-7380-4209-9afd-2c0ac46e0ce2"
            :headers="insuranceHeaders"
            :rows="rows"
            :page="page"
            :sortingState="sortingState"
            :headerActions="tableActions"
            :initialPageSize="minNumRows"
            @onClickHeader="onClickHeader"
            @selectedRow="onRowSelection"
            @selectedRows="onAllRowsSelection"
            @click-link-kundennr="openCustomerNewTab"
            @click-icon-ICON="actionAssign"
            @click-icon-SCHAEDEN="actionContract($event, 'schadensmeldungen')"
            @click-icon-KUNDEN_BEARBEITEN_OEFFNEN="openCustomerNewTab"
            @action-CONTRACT="actionContract"
            @action-NEW_CONTRACT="openCustomerNewInsurance"
            @action-TICKET_NEED_VERTRAG="sendMaklerTicket"
            @headerAction-UNITE="actionUnite" 
        >
            <template v-slot:insuranceId="row">
                <!-- doesn't exist for customer rows -->
                <div v-if="row.insuranceId" :class="{'green': row.insuranceId.green}">
                    {{row.insuranceId.text}}
                </div>
            </template>
            <template v-slot:name="row">
                <div :class="{'green': row.name.green}">
                    {{row.name.text}}
                </div>
            </template>
            <template v-slot:vorname="row">
                <div :class="{'green': row.vorname.green}">
                    {{row.vorname.text}}
                </div>
            </template>
            <template v-slot:street="row">
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
            <template v-slot:gesellnr="row">
                <div :class="{'green': row.gesellnr.green}">
                    {{row.gesellnr.text}}
                </div>
            </template>
        </BigTable>
        <NoData v-else
            title="Vorschläge"
            noIcon
        />

    </div>

    <div class="box__container" v-if="loading">
        <GhostLoading type="table" />
    </div>
    

    <UniteContracts v-if="uniteInsurance1 && uniteInsurance2" :insurance1="uniteInsurance1" :insurance2="uniteInsurance2" @close="uniteInsurance1=null; uniteInsurance2=null"/>

    <PreviewPDF :saveLastPosition="false" startPosition="BOTTOM_RIGHT" :initialHeight="75" :initialWidth="35" sizeIsPercentage ref="resizablePopup" :pdfUrl="pdfUrl" id="c5ad9400-c09d-4258-ac12-6eaa8af8ae27"></PreviewPDF>

</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import BIPRO_TYPES from '@/store/bipro/types'
import LOG_TYPES from '@/store/log/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import UniteContracts from '@/components/bipro/UniteContracts.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import {SimpleAction, Icon} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex'
import axios from 'axios';
import {PhPencilLine, PhFilePlus, PhInfo} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { required, regex } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';
import PreviewPDF from '@/components/core/PreviewPDF.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, SLOT_COLUMN, ICON_COLUMN, DATE_COLUMN, LINK_COLUMN, NUMBER_COLUMN} from '@/components/bigTable/BigTable.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';


const config = {
    defaultSpinner: true,
};

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

export default {
    mixins: [validator],
    components: {
        BigTable,
        BaseButton,
        InputToggleSwitch,
        InputField,
        ComboBox,
        UniteContracts,
        GhostLoading,
        NoData,
        PreviewPDF,
        PageHeaderTitleNavigation,
        PhInfo,
    },
    props: {
        type: {
            type: String,
        },
        versicherungId: {
            type: String,
        },
        id: {
            type: String,
        },
        isMrMoney: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            insurances: null,
            customers: null,

            title: null,

            fileName: null,
            foreignName: null,
            foreignVorname: null,
            foreignBirthName: null,
            foreignAddress: null,
            foreignStrasse: null,
            foreignOrt: null,
            foreignPLZ: null,
            foreignBirthDate: null,
            foreignPartnernummer: null,
            cause: null,
            category: null,
            insuranceId: null,
            gesellschaftId: null,

            transferInsuranceNumber: false,
            customerNameSearch: "",
            comboCategory: null,
            categories: [],
            mscInternalId: null,
            comboGesellschaft: null,

            uniteInsurance1: null,
            uniteInsurance2: null,
            showGesellschaftPanel: false,
            validationMetadata: null,
            isValid: true,
            licensePlateNumber: null,
            referenceNumber: null,

            biproFileName: null,
            biproForeignName: null,
            biproForeingVorname: null,
            biproForeignGeburtsname: null,
            biproForeignAnshrift: null,
            biproForeignGeburtsdatum: null,
            biproMSCCategory: null,
            biproVersicherungsnummer: null,

            damageNumber: null,
            pdfUrl: '',
            selected: [],
            insuranceHeaders: [
                { label: "", key: "ICON", width: 40, type: ICON_COLUMN, position: FIXED_LEFT },
                ...(this.isMrMoney ? [{ label: "", key: "SCHAEDEN", width: 40, type: ICON_COLUMN, position: FIXED_LEFT }] : []),
                { label: "Diesen Kunden zum Bearbeiten öffnen", key: "KUNDEN_BEARBEITEN_OEFFNEN", width: 40, type: ICON_COLUMN, position: FIXED_LEFT, hideLabel: true },
                { label: "Interne Nr", key: "id", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Gesellschaft", key: "gesellschaft", width: 100, type: STRING_COLUMN, visible: true },
                { label: "VSNR", key: "insuranceId", width: 150, type: SLOT_COLUMN, visible: true, },
                { label: "Sparte", key: "sparte", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Beginn", key: "begin", width: 100, type: DATE_COLUMN, visible: true },
                { label: "Beitrag", key: "beitrag", width: 100, type: NUMBER_COLUMN, visible: true },
                { label: "Status", key: "status", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Kundenname", key: "name", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Kundenvorname", key: "vorname", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Kundennr", key: "kundennr", width: 100, type: LINK_COLUMN, visible: true },
                { label: "Straße u. Hausnr.", key: "street", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "PLZ", key: "plz", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Ort", key: "ort", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Geburtsdatum", key: "birthdate", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Maklernr", key: "maklernr", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Maklername", key: "makler", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Weitere Personen/Gegenstand", key: "weitere", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Kunden GesellNr.", key: "gesellnr", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: this.isFA ? "OMDS aktualisiert" : "GDV aktualisiert", key: "current", width: 100, type: SLOT_COLUMN, visible: true, },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ], 
            sortingState: { key: "insuranceId", direction: "desc" },
            minNumRows: 100,
            page: 0,
            rows: [],
            erweiterteSucheInfo: "Die erweiterte Suche ist ein mächtiges Tool des smartMSC, das Nutzern ermöglicht präzisere Suchergebnisse zu erhalten.",
            interneNummerInfo: "Für die Zuordnung ist eine interne Nr. erforderlich",

        }
    },
    watch: {
        paramReturnVersVertrag: {
            handler(newValue) {
                this.mscInternalId = newValue;
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS,
            isInternOriginally: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            paramReturnVersVertrag: BIPRO_TYPES.GETTERS.PARAM_RETURN_VERS_VERTRAG,
        }),
        showBiproCard() {
                return this.biproFileName || this.biproForeignName || this.biproForeingVorname || this.biproForeignGeburtsname 
                    || this.biproForeignAnshrift || this.biproForeignGeburtsdatum || this.biproMSCCategory || this.biproVersicherungsnummer;
        },
        tableActions() {
            return [
                PageHeaderSimpleAction('UNITE', 'Vertrag vereinigen')
                .withDisabled(() => this.numberSelected !== 2),
            ];
        },
        numberSelected() {
            return this.selected.length;
        },        
        headerActions() {
            const actions = [];
            actions.push(PageHeaderSimpleAction('RELOAD-DATA', 'Aktualisieren'));
            return actions;
        },
        dynamicTitle() {
            let result = `Festlegung der Versicherung für das ${this.prfx} Dokument`;

            if (this.title) {
                result = `${this.title} | ${result}`;
            }

            return result;
        },          
        prfx() {
            return this.type == "pdfBox" ? "PDF-Box" : "BiPRO";
        },
        fieldsValidators() {

            let validators = {
                mscInternalId : [required(this.interneNummerInfo)],
            };

            if (this.validationMetadata?.insuranceId?.regex !== undefined) {
                const insuranceRegex = "^(?:" + this.validationMetadata.insuranceId.regex + ")$";
                const insuranceExample =  this.validationMetadata.insuranceId.beispielKontonrEffektiv;
                
                validators.insuranceId = [
                    regex(new RegExp(insuranceRegex), `Die Pol.Nr. muss im Format ${insuranceExample} eingegeben werden.`, {emptyAllowed: true})
                ];
            }

            return validators;
        },
        gesellschaftOptionsMitDefault() {
            const defaultOption = [{ "value": null, "label": "Versicherungsgesellschaft", "style": null, "disabled": null }];
            return [...defaultOption, ...this.gesellschaftOptions || []];
        }
    },
    async mounted() {
        if (this.versicherungId)
            this.mscInternalId = this.versicherungId || null;
        
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
        
        await this.loadData(null, false);

        if (this.isMrMoney) {
            this.showGesellschaftPanel = true;
        }
    },
    methods: {
        async reloadData() {
            await this.loadData(null, false);
        },
        processSelection(selection) {
            this.selected = selection;
            console.log(this.selected.map(sr => {return {id: sr.id}}));
        },
        fetchPDF() {
            const {fileName} = this;

            if (this.isMrMoney) {
                const queryParams = {
                    mrMoneyId: this.id,
                };

                const downloadServicePath="/mrmoneypdf";

                const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
                this.pdfUrl = url;
            } else {
                const { automatischZugeordnet, } = this;
    
                const queryParams = {
                        id: this.id,
                    };
    
                let downloadServicePath="/bipro_dokument_nicht_zugeordnet";
    
                if (automatischZugeordnet) {
                    downloadServicePath =  "/bipro_dokument_zugeordnet";
                }
    
                const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
                this.pdfUrl = url;
            }
            
            if (this.$refs.resizablePopup) {
                this.$refs.resizablePopup.showPopup();
            }

        },    
        async loadData(name, advancedSearch) {
            this.loading = true;
            let nameParam = "";
            if (name !== null)
                nameParam = `&name=${name}`;
            let advancedSearchParam = "";
            if (advancedSearch)
                advancedSearchParam = `&advancedSearch=true`;

            let serviceUrl = process.env.VUE_APP_API;

            // switch (this.$route.query.prfx) {
            switch (this.type) {
                case "pdfBox":
                    serviceUrl = `${serviceUrl}/mrMoney/zuweisen`
                    break;
            
                default:
                    serviceUrl = `${serviceUrl}/bipro_documents/zuweisen`
                    break;
            }
            axios.get(`${serviceUrl}?id=${this.id}` + nameParam + advancedSearchParam + `&changedPolNr=${this.insuranceId}`)
            .then(response => {

                this.validationMetadata = response?.data?.validationMetadata;

                this.$configureValidators(this.fieldsValidators);
                
                this.title = `${response?.data?.title}, Id: ${this.id}`;

                // table rows
                this.insurances = response?.data?.insurances;


                // Populates the "rows" property of "Vorschläge" table
                const insuranceRows = (this.insurances || []).map(row => {
                    const actions = [
                        SimpleAction("CONTRACT", PhPencilLine, "Versicherungsvertrag öffnen"),
                    ];
                    return {
                        ...row,
                        ICON: Icon("PhArrowFatRight", "Interne Nr. übernehmen und Versicherungsdokument direkt zuordnen"),
                        ...(this.isMrMoney && row.hasSchaeden ? { SCHAEDEN: Icon("PhFirstAidKit", "Schäden des Vertrags anzeigen") } : {}),
                        KUNDEN_BEARBEITEN_OEFFNEN: Icon("PhPencil", "Diesen Kunden zum Bearbeiten öffnen"),
                        actions,
                    }
                });

                this.customers = response?.data?.customers;

                const customerRows =  (this.customers || []).map(row => {
                    const actions = [
                        SimpleAction("KUNDEN_BEARBEITEN_OEFFNEN", PhFilePlus, "Diesen Kunden zum Bearbeiten öffnen"),
                        SimpleAction("NEW_CONTRACT", PhFilePlus, "Neuen Vers. Vertrag anlegen"),
                        SimpleAction("TICKET_NEED_VERTRAG", PhFilePlus, "Ticket an Makler schicken"),
                    ];
                    return {
                        ...row,
                        actions,
                    }
                });

                this.rows = [...insuranceRows, ...customerRows];

                // information displayed above the tables
                this.fileName = response.data.fileName;
                this.foreignName = response.data.foreignName;
                this.foreignVorname = response.data.foreignVorname;
                this.foreignBirthName = response.data.foreignBirthName;
                this.foreignAddress = response.data.foreignAddress;
                this.foreignPLZ = response.data.foreignPLZ;
                this.foreignOrt = response.data.foreignOrt;
                this.foreignStrasse = response.data.foreignStrasse;
                this.foreignBirthDate = response.data.foreignBirthDate;
                this.foreignPartnernummer = response.data.foreignPartnernummer;
                this.cause = response.data.cause;
                this.category = response.data.category;
                this.insuranceId = this.insuranceId || response.data.insuranceId;
                this.gesellschaftId = response.data.gesellschaftId;
                this.damageNumber = response?.data?.damageNumber;
                this.licensePlateNumber = response?.data?.licensePlateNumber;
                this.referenceNumber = response?.data?.referenceNumber;

                this.comboCategory = response.data.comboCategory;
                this.categories = response.data.categories;
                this.categories.map(option => {
                    if (option.label.startsWith("[c=blue]")) {
                        option.label = option.label.substring(8, option.label.length - 4);
                        option.style = "color: blue";
                    }
                });

                //FixMe: for some reason, the validation in the "Interne Nummer" only happens when the focus is focused
                this.$nextTick(() => {
                    this.$refs.mscInternalIdInputField && this.$refs.mscInternalIdInputField.focus();
                    this.$refs.insuranceIdInputField && this.$refs.insuranceIdInputField.focus();
                    
                })

                if (response?.data?.biproFileName) {
                    this.biproFileName = response?.data?.biproFileName;
                    this.biproForeignName = response?.data?.biproForeignName;
                    this.biproForeingVorname = response?.data?.biproForeingVorname;
                    this.biproForeignGeburtsname = response?.data?.biproForeignGeburtsname;
                    this.biproForeignAnshrift = response?.data?.biproForeignAnshrift;
                    this.biproForeignGeburtsdatum = response?.data?.biproForeignGeburtsdatum;
                    this.biproMSCCategory = response?.data?.biproMSCCategory;
                    this.biproVersicherungsnummer = response?.data?.biproVersicherungsnummer;
                }

                if (!this.pdfUrl) {
                    this.fetchPDF();
                }


                this.loading = false;

            });
        },
        actionContract(row, step) {

            let path = `/home/versicherungen/insurance-group/details/${row.id}?isCalledFromMrMoneyPdfbox=true&mrMoneyId=${this.id}&VSNR=${this.insuranceId}`;

            if (step) {
                path = `${path}&stepKey=${step}`
            }


            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                customerId: row.kundennr,
                insurances: true,
                path
            });
        },
        actionUnite() {
            this.uniteInsurance1 = this.selected[0]?.id;
            this.uniteInsurance2 = this.selected[1]?.id;
        },
        async actionAssign(row) {
            // const isMrMoney = !!this.$route?.query?.isMrMoney;

            let params = {
                mscInternalId: row.id,
                gesellName: row.gesellschaft,
                transferInsuranceNumber: this.transferInsuranceNumber,
                insuranceId: this.insuranceId,
                mscCategory: this.comboCategory,
            }

            if (this.isMrMoney) {
                // params.mrMoneyId = this.$route?.query?.id;
                params.mrMoneyId = this.id;
            } else {
                // params.docId = this.$route?.query?.id;
                params.docId = this.id;
            }

            const url = this.isMrMoney ? "/mrMoney/accept_insurance_proposal" : "/bipro_documents/accept_insurance_proposal";

            let response = await axios.post(`${process.env.VUE_APP_API}${url}`, params, config);

            if (response.data.error) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.error, 'danger'));
            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'success', true, 5000));

                if (this.isMrMoney) {
                    this.$router.push({path: "/intern/mrmoney/list"});
                } else {
                    this.$router.push({path: "/intern/bipro/documents"});
                }
                
            }
        },
        async searchInsurances() {
            
            // let allPossibleGesellschaftIds = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/selected_gesellschaften?id=${this.$route.query.id}`);
            let allPossibleGesellschaftIds = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/selected_gesellschaften?id=${this.id}`);

            const defaultOptions = {
                lastName: {
                    value: this.foreignName,
                },
                firstName: {
                    value: this.foreignVorname,
                },
            };

            if (allPossibleGesellschaftIds?.data?.length) {
                defaultOptions["vertragGesellschaft"] = allPossibleGesellschaftIds.data[0];

            }

            if (this.insuranceId != null) {
                defaultOptions.versNummer = {
                    value: this.insuranceId,
                };
            }
            if (this.isInternOriginally && this.isBroker) {
                defaultOptions.maklernr = {
                    value: this.userId,
                };
            }

            this.$router.push({
                path: "/home/versicherungsfilter/insurance-group",
                query: {
                    // default options for the filter
                    defaultOptions,
                    mitZuordnenAktion: true,
                }
            });
        },
        buttonVersicherungsdokumentZuordnen() {
            const params = {
                // docId: this.$route.query.id,
                docId: this.id,
                mscInternalId: this.mscInternalId,
                insuranceId: this.insuranceId,
                setPoliceNr: this.transferInsuranceNumber,
                // mrMoney: this.$route.query.isMrMoney,
                mrMoney: this.isMrMoney,
            };

            if (this.isMrMoney) {
                axios.post(`${process.env.VUE_APP_API}/mrMoney/assign_insurance`, params, config)
                .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: "/intern/mrmoney/list"});
                }).catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
                })
            } else {
                axios.post(`${process.env.VUE_APP_API}/bipro_documents/assign_insurance`, params, config)
                .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: "/intern/bipro/documents"});
                }).catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
                })

            }
        },
        buttonNewCustomer(sameBroker) {
            this.$addBreadcrumb({
              label: `zurück`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Datensatz zuordnen',
            });
            let query = '';
            if (this.foreignName) {
                query += '&lastName=' + this.foreignName;
            }
            if (this.foreignVorname) {
                query += '&firstName=' + this.foreignVorname;
            }
            if (this.foreignBirthDate) {
                query += '&dayOfBirth=' + this.foreignBirthDate;
            }
            if (this.foreignPLZ) {
                query += '&zip=' + this.foreignPLZ;
            }
            if (this.foreignOrt) {
                query += '&city=' + this.foreignOrt;
            }
            if (this.foreignStrasse) {
                query += '&street=' + this.foreignStrasse;
            }
            if (query) {
                query = '?' + query.substring(1);
            }
            if (sameBroker) {
                this.$router.push({
                    path: "/customer/kunde-anlegen" + query
                });
                // in neuer tab
                // this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
                //     brokerId: this.userId,
                //     path: `customer/kunde-anlegen${encodeURIComponent(query)}`
                // });
            } else {
                this.$router.push({
                    path: "/customer/maklersuche",
                    query: {
                        bipro: true,
                        nextUrl: `customer/kunde-anlegen${query}`
                    }
                });
            }
        },
        advancedSearch() {
            this.loadData(this.comboGesellschaft, true);
        },
        openCustomerNewTab(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr })
        },
        openCustomerNewInsurance(row) {
            let path = '/home/versicherungen/auswahl-vertragsart';
            if (this.gesellschaftId || this.insuranceId) {
                let query = '';
                if (this.gesellschaftId) {
                    query='?gesellid=' + this.gesellschaftId;
                }
                if (this.insuranceId) {
                    query += (query ? '&' : '?') + 'vesichnr=' + this.insuranceId;
                }
                path += encodeURIComponent(query);
            }
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr, path })
        },
       sendMaklerTicket(row){
           console.log(`maklernr: ${row.maklernr} kundennr: ${row.kundennr} id: ${this.id} user: ${this.userId} type: ${this.type}`);

           const url = this.isMrMoney ? "/mrMoney/send_ticket_new_vertrag" : "/bipro_documents/send_ticket_new_vertrag";
           const nextUrl = this.isMrMoney ? "/intern/mrMoney/list" : "/intern/bipro/documents";
           let params = {
                //maklernr: row.maklernr,
                kundennr: row.kundennr,
                id: this.id
                //user: this.userId
            }

          axios.post(`${process.env.VUE_APP_API}${url}`, params, config)
          .then(response => {
                    if (response?.data)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                    this.$router.push({path: nextUrl});
                })  
          .catch(error => {
                    let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
                    if(error?.response?.status == 400 || error?.response?.status == 403 ){
                        msg = error?.response?.data
                    }                
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
            });
        },
        onRowSelection(payload) {
            if (payload?.action == "added") {
                // Check if the row is not already selected before adding
                if (!this.selected.some(item => item === payload.row)) {
                    this.selected.push(payload.row);
                }
            } else if (payload?.action == "removed") {
                // Find the index of the row in selected array
                const index = this.selected.findIndex(item => item === payload.row);
                // If found, remove it
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
        },
        onAllRowsSelection(payload)  {
            this.selected = payload;
        },  
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            // TODO: save the sorting state
        }, 

    },         
    validators: {},
}
</script>


<style lang="scss" scoped>
.green {
    background-color: var(--color-success)
}

.masonry__container {
    -moz-column-gap: 8px;
    column-gap: 14px;
    row-gap: 14px;
    transition: border .2s,padding .2s;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.box__shadow {
  box-shadow: 0px 4px 16px rgba(85, 85, 85, 0.1);
}

.item {
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--color-box);
    border: 1px solid var(--color-box);
    padding: 8px;
    @extend .box__shadow;
    max-width: 590px;
}

.card-row {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
}

.card-row-label {
    min-width: 160px;
    
}

.card-row-value {
    margin-left: 8px;
    flex: 1 1 auto;
    display: block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    
}

.card-title {
    padding: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    flex: 1 1 auto;
    display: flex;
}

.card-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;

}

@media (max-width:1291px) {
  .item {
    max-width: 100%;
  }
}


</style>
