var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions
        },
        on: {
          "action-BACK": _vm.close,
          "action-NEW_SPARPLAN": _vm.newSparplan,
          "action-action_neue_transaktion": _vm.newTransaktion,
          "action-actiontransaktionenpdf": _vm.transaktionenpdf,
          "action-button_transaktionen2xls": _vm.transaktionen2xls,
          "action-button_transaktionenanlegen": _vm.transaktionenanlegen,
          "action-button_courtagezubringer_neu": _vm.newCourtage,
          "action-button_add_wiedervorlage": _vm.newAktivitaet,
          "action-button_dokument_neu": _vm.newDocument
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { class: { "fc-form-danger": _vm.blinkHinweis } }, [
                  _vm._v("Depotposition")
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "box__container d-lg-none col-12" },
        [
          _c("Stepper2", {
            ref: "refStepper2",
            attrs: {
              stepType: "step",
              visible: true,
              stepperName: "BegleitscheinData",
              selectedStepKey: _vm.selectedStepKey,
              stepperService: _vm.steps
            },
            on: {
              setStep: function($event) {
                return _vm.setStep($event)
              }
            }
          })
        ],
        1
      ),
      _vm.hinweise.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "box__title",
                              class: { "fc-form-danger": _vm.blinkHinweis }
                            },
                            [_vm._v(_vm._s(_vm.hinweisTitle()))]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _vm.hinweise.length
                            ? _c(
                                "div",
                                { staticClass: "d-block" },
                                _vm._l(_vm.hinweise, function(text, idx) {
                                  return _c("div", { key: idx }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.sanitize(text))
                                      }
                                    })
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3526799794
                ),
                model: {
                  value: _vm.showHinweis,
                  callback: function($$v) {
                    _vm.showHinweis = $$v
                  },
                  expression: "showHinweis"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c(
                  "BoxContainer",
                  [
                    _c("VerticalStepper", {
                      attrs: {
                        stepType: "tab",
                        visible: true,
                        stepperName: "BegleitscheinData",
                        selectedStepKey: _vm.selectedStepKey,
                        stepperService: _vm.steps,
                        showCollapse: true,
                        showSaveSpinner: _vm.showSaveSpinner
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.setStep($event)
                        },
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("BegleitscheinData", {
                  ref: "refBegleitscheinData",
                  staticClass: "box__container",
                  attrs: { currentStepKey: _vm.selectedStepKey },
                  on: {
                    setStep: function($event) {
                      return _vm.setStep($event)
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "box__container antrag-bottom-buttons-container row"
                  },
                  [
                    _vm.selectedStepKey !== "POSITION"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isSecondary: "", isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.onButtonPrevious()
                                  }
                                }
                              },
                              [_vm._v("Zurück ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedStepKey !== "DOKUMENT"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.onButtonNext()
                                  }
                                }
                              },
                              [_vm._v("Weiter ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedStepKey === "DOKUMENT"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.close()
                                  }
                                }
                              },
                              [_vm._v("Abschließen ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.selectedStepKey === "DOKUMENT"
        ? _c("DocumentsUploadModal", {
            ref: "uploadForm",
            attrs: {
              files: _vm.uploadFiles,
              additionalInputs: _vm.uploadFields,
              uploadType: 3,
              multipleFileUpload: true,
              openOnMount: false,
              customUpload: _vm.dokUpload
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }