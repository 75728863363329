<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <div v-if="dokumenteAbholenResult">
      {{dokumenteAbholenResult.value}}
    </div>
  </div>
</template>

<script>
import BERATUNGSMAPPE_TYPES from "@/store/beratungVersicherung/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  data() {
    return {
      dokumenteAbholenResult: {},
    }
  },
  methods: {
    dokumenteAbholen() {
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC).then(
        response => {
          this.dokumenteAbholenResult = response;
        }
      );
    },
  },
  mounted() {
    this.dokumenteAbholen();
  },
  components: {
    OptionMenu,
  },
}
</script>
