<template>
  <div class="d-none">
  </div>
</template>

<script>
import store from '@/store';
import CORE_TYPES from '@/store/core/types'

export default {
  beforeRouteEnter(to, from, next) {
    const username = store.getters[CORE_TYPES.GETTERS.GET_USER_FULL_NAME];
    const link = `https://www.fundsware.de/grundlagenwissen/download?partner_id=e0a43498539d9e04d0efd414c4f3a49f&type=gwmd&filename=grundlagenwissen.pdf&name=${username}`;
    window.open(link)
    next(false);
  },
}
</script>

<style scoped>

</style>