<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" 
      :title="depotTitle" 
      subtitle="Bankkonten" 
      :actions="headerActions" 
    />

    <div id="account-positive" key="account-positive">
      <Account title="Konten mit positivem Saldo" showAccounts="positive" :isLoading="loading" />
    </div>

    <div id="account-negative" key="account-negative">
      <Account title="Konten mit negativem Saldo" showAccounts="negative" :isLoading="loading" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import ACCOUNT_TYPES from '@/store/account/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import Account from './Account.vue';

import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [],
  components: {
    Account,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
    }),
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
  },
  watch: {
    depotid(id) {
      this.id = id;
      this.findAccounts();
    },
  },
  methods: {
    async findAccounts() {
      this.loading = true;
      try {
        await this.$store.dispatch(ACCOUNT_TYPES.ACTIONS.GET_ACCOUNTS, {id: this.id || this.depotid});
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.findAccounts();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
}
</script>

<style scoped>
</style>
