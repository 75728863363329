import CORE_TYPES from '@/store/core/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function getInitialState() {
  return {
    filter: {},
    tableData: {},
    bgs: {bgsnr: -1},
    param: {},
    hinweise: {texte: [], show: false},
    pageIndex: 0,
    totalRows: 0,
    transaktUebertragenData: {},
    askPIN: 0,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    [CORE_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  }
}