var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle: "Terminkalender",
          actions: _vm.headerActions
        },
        on: { "action-DISCARD": _vm.resetDataEdited }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.stepperMediator,
          selectedStepKey: _vm.selectedStepKey,
          hasFertig: false
        },
        on: { "set-step": _vm.setStepByKey },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("GhostLoading", { attrs: { type: "title" } }),
                        _c("GhostLoading", { attrs: { type: "block" } }),
                        _c("GhostLoading", { attrs: { type: "title" } }),
                        _c("GhostLoading", { attrs: { type: "title" } }),
                        _c("GhostLoading", { attrs: { type: "title" } }),
                        _c("GhostLoading", { attrs: { type: "title" } })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "kalender"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Kalender")
                        ]),
                        _c("InputRadioBoxGroup", {
                          attrs: {
                            title:
                              "Voreinstellung der Uhrzeit in Tages- / Wochenansicht",
                            values: _vm.tagesansichtUhrzeitValues
                          },
                          on: { input: _vm.setDataChanged },
                          scopedSlots: _vm._u(
                            [
                              _vm.formData.tagesansichtUhrzeitModus === "FESTE"
                                ? {
                                    key: "after-FESTE",
                                    fn: function() {
                                      return [
                                        _c("TimePicker", {
                                          attrs: { isComponentHalfSize: "" },
                                          on: { change: _vm.setDataChanged },
                                          model: {
                                            value:
                                              _vm.formData.tagesansichtUhrzeit,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "tagesansichtUhrzeit",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.tagesansichtUhrzeit"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.formData.tagesansichtUhrzeitModus,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "tagesansichtUhrzeitModus",
                                $$v
                              )
                            },
                            expression: "formData.tagesansichtUhrzeitModus"
                          }
                        }),
                        _c(
                          "div",
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v("Geburtstage anzeige")
                            ]),
                            _vm.isMakler
                              ? [
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      label: "Geburtstage der Kunden anzeigen"
                                    },
                                    on: { input: _vm.setDataChanged },
                                    model: {
                                      value: _vm.formData.zeigeGebtagKunden,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "zeigeGebtagKunden",
                                          $$v
                                        )
                                      },
                                      expression: "formData.zeigeGebtagKunden"
                                    }
                                  }),
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      label:
                                        "Geburtstage der zusätzlichen Depotinhaber anzeigen"
                                    },
                                    on: { input: _vm.setDataChanged },
                                    model: {
                                      value: _vm.formData.zeigeGebtagKundenZDI,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "zeigeGebtagKundenZDI",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.zeigeGebtagKundenZDI"
                                    }
                                  }),
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      label:
                                        "Geburtstage der Bevollmächtigten anzeigen"
                                    },
                                    on: { input: _vm.setDataChanged },
                                    model: {
                                      value: _vm.formData.zeigeGebtagKundenVM,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "zeigeGebtagKundenVM",
                                          $$v
                                        )
                                      },
                                      expression: "formData.zeigeGebtagKundenVM"
                                    }
                                  })
                                ]
                              : _vm.isIntern
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "mb-3" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Geburtstage der Makler der folgenden Kategorien anzeigen"
                                        )
                                      ]),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "A+" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerAPlus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerAPlus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerAPlus"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "A" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerA,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerA",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerA"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "B" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerB,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerB",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerB"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "C" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerC,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerC",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerC"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "D" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerD,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerD",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerD"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "E" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerE,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerE",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerE"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: { label: "F" },
                                        on: { input: _vm.setDataChanged },
                                        model: {
                                          value:
                                            _vm.formData.zeigeGebtagMaklerF,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "zeigeGebtagMaklerF",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.zeigeGebtagMaklerF"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      label:
                                        "Geburtstage der Ansprechpartner Gesellschaften anzeigen"
                                    },
                                    on: { input: _vm.setDataChanged },
                                    model: {
                                      value: _vm.formData.zeigeGebtagGesell,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "zeigeGebtagGesell",
                                          $$v
                                        )
                                      },
                                      expression: "formData.zeigeGebtagGesell"
                                    }
                                  }),
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      label: "Geburtstage der Kontakte anzeigen"
                                    },
                                    on: { input: _vm.setDataChanged },
                                    model: {
                                      value: _vm.formData.zeigeGebtagKontakt,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "zeigeGebtagKontakt",
                                          $$v
                                        )
                                      },
                                      expression: "formData.zeigeGebtagKontakt"
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "div",
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v("Benachrichtigung")
                            ]),
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("InputToggleSwitch", {
                                  staticClass: "p-0",
                                  attrs: {
                                    label: "Benachrichtigung per Popup"
                                  },
                                  model: {
                                    value: _vm.formData.popupAlarmAktiv,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "popupAlarmAktiv",
                                        $$v
                                      )
                                    },
                                    expression: "formData.popupAlarmAktiv"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "forms__input--half-size" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row mt-1 mb-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-4 col-sm-2 col-lg-3"
                                          },
                                          [
                                            _c("InputField", {
                                              staticClass: "p-0",
                                              attrs: {
                                                type: "number",
                                                precision: 0,
                                                disabled: !_vm.formData
                                                  .popupAlarmAktiv
                                              },
                                              on: {
                                                change: _vm.setDataChanged
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.popupAlarmWert,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "popupAlarmWert",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.popupAlarmWert"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-5 col-sm-3 col-lg-4"
                                          },
                                          [
                                            _c("ComboBox", {
                                              staticClass: "p-0",
                                              attrs: {
                                                values:
                                                  _vm.comboData.alarmModusPopup,
                                                disabled: !_vm.formData
                                                  .popupAlarmAktiv
                                              },
                                              on: { input: _vm.setDataChanged },
                                              model: {
                                                value:
                                                  _vm.formData.popupAlarmModus,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "popupAlarmModus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.popupAlarmModus"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex align-items-center"
                                          },
                                          [_vm._v("vorher")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("InputToggleSwitch", {
                                  staticClass: "p-0",
                                  attrs: {
                                    label: "Benachrichtigung per E-Mail"
                                  },
                                  on: { input: _vm.setDataChanged },
                                  model: {
                                    value: _vm.formData.mailAlarmAktiv,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "mailAlarmAktiv",
                                        $$v
                                      )
                                    },
                                    expression: "formData.mailAlarmAktiv"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "forms__input--half-size" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row mt-1 mb-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-4 col-sm-2 col-lg-3"
                                          },
                                          [
                                            _c("InputField", {
                                              staticClass: "p-0",
                                              attrs: {
                                                type: "number",
                                                precision: 0,
                                                disabled: !_vm.formData
                                                  .mailAlarmAktiv
                                              },
                                              on: {
                                                change: _vm.setDataChanged
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.mailAlarmWert,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "mailAlarmWert",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.mailAlarmWert"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-5 col-sm-3 col-lg-4"
                                          },
                                          [
                                            _c("ComboBox", {
                                              staticClass: "p-0",
                                              attrs: {
                                                values:
                                                  _vm.comboData.alarmModusEmail,
                                                disabled: !_vm.formData
                                                  .mailAlarmAktiv
                                              },
                                              on: { input: _vm.setDataChanged },
                                              model: {
                                                value:
                                                  _vm.formData.mailAlarmModus,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "mailAlarmModus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.mailAlarmModus"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex align-items-center"
                                          },
                                          [_vm._v("vorher")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("InputToggleSwitch", {
                              attrs: {
                                label:
                                  "Termine des heutigen Tages in der E-Mail aufführen"
                              },
                              on: { input: _vm.setDataChanged },
                              model: {
                                value: _vm.formData.mailAlarmTagestermine,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "mailAlarmTagestermine",
                                    $$v
                                  )
                                },
                                expression: "formData.mailAlarmTagestermine"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm.isIntern && _vm.selectedStepKey === "terminfreigabe"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("TerminFreigaben", {
                          on: { input: _vm.setDataChanged },
                          model: {
                            value: _vm.formData.freigaben,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "freigaben", $$v)
                            },
                            expression: "formData.freigaben"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "feiertagseinstellungen"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Feiertagseinstellungen")
                        ]),
                        _c("InputChips", {
                          attrs: {
                            label: "Deutschland",
                            availableOptions: _vm.comboData.feiertagGruppeDE,
                            showToggle: "",
                            uniqueSelection: ""
                          },
                          on: { input: _vm.setDataChanged },
                          model: {
                            value: _vm.formData.feiertagGruppeDE,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "feiertagGruppeDE", $$v)
                            },
                            expression: "formData.feiertagGruppeDE"
                          }
                        }),
                        _c("InputChips", {
                          attrs: {
                            label: "Österreich",
                            availableOptions: _vm.comboData.feiertagGruppeAT,
                            showToggle: "",
                            uniqueSelection: ""
                          },
                          on: { input: _vm.setDataChanged },
                          model: {
                            value: _vm.formData.feiertagGruppeAT,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "feiertagGruppeAT", $$v)
                            },
                            expression: "formData.feiertagGruppeAT"
                          }
                        }),
                        _c("FeiertageGruppeSpezial", {
                          on: { input: _vm.setDataChanged },
                          model: {
                            value: _vm.formData.eigeneFeiertage,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "eigeneFeiertage", $$v)
                            },
                            expression: "formData.eigeneFeiertage"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }