<template>
  <div class="workspaces-menu__container">
    <template v-if="isLoading">
      <GhostLoading class="workspaces-menu__ghost-loading hide-on-small-screen m-0" :width="100" :repeat="4" inline>
        <Block :width="24" :height="24" />
        <Block />
      </GhostLoading>
      <GhostLoading class="workspaces-menu__ghost-loading hide-on-large-screen m-0" :width="24" :repeat="4" inline>
        <Block :width="24" :height="24" :opacity="0.5" />
      </GhostLoading>
    </template>
    <template v-else v-for="menu in menuOptionsWithClass">
      <span class="workspaces-menu__item workspaces-menu__icons--spaced" :key="menu.path" v-if="!menu.disabled">
        <a class="workspaces-menu__item" :class="[menu.class]" :tid="getTid(menu)" @click="navigateTo(menu)">
          <span class="workspaces-menu__item--icon">
            <component :is="menu.component" :size="24" :class="[menu.iconClass]" :weight="menu.iconWeight"/>
            <BaseBadgeCounter :count="menu.count" class="workspaces-menu__item--counter" />
          </span>
          <span class="workspaces-menu__item--label">{{menu.label}}</span>
        </a>
      </span>
      <span v-else-if="menu.disabled" class="workspaces-menu__item workspaces-menu__icons--spaced workspaces-menu__item-disabled" 
        :class="[menu.class]" v-bind:key="menu.path">
        <span class="workspaces-menu__item--icon">
          <component :is="menu.component" :size="24" :class="[menu.iconClass]" :weight="menu.iconWeight" :tid="getTid(menu)"/>
        </span>
        <span class="workspaces-menu__item--label">{{menu.label}}</span>
      </span>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MENU_TYPES from '@/store/menu/types'
import { v5 as uuidv5 } from 'uuid';
import BaseBadgeCounter from '@/components/core/BaseBadgeCounter.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

import menuManager from '@/menu/menu-manager';

export default {
  components: {
    BaseBadgeCounter,
    GhostLoading,
    Block,
  },
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isLoadingToken: CORE_TYPES.GETTERS.IS_LOADING_TOKEN,
      isManagingLoginResponse: CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE,
      isConfiguringMenu: MENU_TYPES.GETTERS.IS_CONFIGURING_MENU,
      isLoggingIn: CORE_TYPES.GETTERS.IS_LOGGING_IN,
      isLoggingOut: CORE_TYPES.GETTERS.IS_LOGGING_OUT,
      parentsMenuBadgeCount: MENU_TYPES.GETTERS.PARENTS_MENU_BADGE_COUNT,
      primaryMenu: MENU_TYPES.GETTERS.PRIMARY_MENU,
    }),
    menu() {
      return [ ...this.primaryMenu, ];
    },
    isLoading() {
      return this.isLoadingToken || this.isManagingLoginResponse || this.isConfiguringMenu || this.isLoggingIn || this.isLoggingOut;
    },
    menuOptions() {
      return [ ...this.menu, ];
    },
    menuOptionsWithClass() {
      return this.menuOptions && this.menuOptions
        .map(menu => ({
          ...menu, 
          class: menuManager.isActive(menu) ? 'active-menu' : '',
          iconClass: 'workspaces-menu__icon-' + this.normalizePathToClassname(menu.path),
          iconWeight: this.iconWeight(menu),
          count: this.parentsMenuBadgeCount[menu.path],
        }));
    },
  },
  data: function () {
    return {

    }
  },
  methods: {
    iconWeight(menu) {
      return menuManager.isActive(menu) ? 'fill' : 'regular';
    },
    normalizePathToClassname(path) {
      return path && path.replace(/[^\w\s]/g,'');
    },
    getTid(item) {
      const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

      return uuidv5(item.path, MY_NAMESPACE);
    },
    async configureMenuBadges() {
      await this.$store.dispatch(MENU_TYPES.ACTIONS.CONFIG_PARENTS_MENU_BADGE);
      await this.$store.dispatch(MENU_TYPES.ACTIONS.LOAD_PARENTS_MENU_BADGE);
    },
    navigateTo(menu) {
      menuManager.openMenu(menu);
    },
  },
  watch: {
    isLoading: {
      handler() {
        if(this.isLoading === false) {
          this.configureMenuBadges();
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.workspaces-menu__ghost-loading {
  align-items: center;
  justify-content: space-around;
  flex: 1 0 auto;

  /deep/ .ghost-loading {
    margin-left: 12px;
    margin-right: 12px;
  }

  /deep/ .ghost-loading__block {
    margin-left: auto;
    margin-right: auto;
  }
}

.workspaces-menu__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.workspaces-menu__item.active-menu {
  text-shadow: 0 0 1px var(--color-text);
}
.workspaces-menu__item-disabled {
  color: #cccccc;
}

.workspaces-menu__item .workspaces-menu__item--icon {
  display: flex;
  position: relative;
  max-width: 24px;
}

.workspaces-menu__item .workspaces-menu__item--counter {
  position: absolute;
  top: -8px;
  right: -8px;

  &.more-than-99 {
    right: -12px;
  }
}

.workspaces-menu__item .workspaces-menu__item--label {
  white-space: nowrap;
}

.workspaces-menu__container a {
  color: var(--color-workspaces-nav-text);
  text-decoration: none;
}

.workspaces-menu__container a.router-link-active {
  font-weight: bold;
}

.workspaces-menu__container a.router-link-exact-active {
  color: var(--color-workspaces-nav-active);
}

.workspaces-menu__container {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.router-link-active {
  font-weight: 500;
}
.workspaces-menu__icons--spaced {
  margin-left: 12px;
  margin-right: 12px;
}

.workspaces-menu__icon-intern {
  transform: rotate(180deg);
}

@media (max-width: 940px) {
  .workspaces-menu__icons--spaced {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 780px) {
  .workspaces-menu__icons--spaced {
    margin-left: 6px;
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .workspaces-menu__item .workspaces-menu__item--label {
    display: none
  }

  .workspaces-menu__container a {
    color: var(--color-top-nav-text);
  }

}

</style>