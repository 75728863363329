<template>
  <div class="formulare-list__container">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="`Wertpapiere für ${selectedFavorit && selectedFavorit.labelShort || ''}`" 
      :actions="headerActions" 
      @action-ADD="addFond()" 
      @action-FONDSVERGLEICH="openFondsvergleich()" 
      @action-IMPORT="$refs.importWertpapiereModal.open()" 
    />

    <div class="box__container">
      <InputTextArea 
        label="Bemerkung" :disabled="!isEditBem" v-model="bemerkung" :rows="4"
        @change="editBemerkung"
      />
    </div>

    <div class="box__container" v-if="!loading || (rows && rows.length)">
      <InputField 
        :disabled="loading"
        isComponentHalfSize
        placeholder="Suchen nach Wertpapier / Gesellschaft / Kategorie / ISIN"
        v-model="descriptionSearch"
      />
    </div>

    <div class="box__container">
      <WertpapierTable
        v-model="selectedRows"
        :tableId="'cf2fd13a-37ba-40f2-aea0-5937f3aeae4a'"
        :rows="rows"
        :headers="wpHeaders"
        :headerActions="tableHeaderActions"
        :loading="loading"
        :exportConfig="exportConfig"
        @action-DELETE="deleteWertpapier([$event])"
        @headerAction-BULK_DELETE="deleteWertpapier(selectedRows)"
      />
    </div>

    <BaseModal ref="importWertpapiereModal" labelButtonConfirm="Importieren" modalTitle="Wertpapiere importieren"
       @onConfirmButton="importWertpapiere()" @close="resetImportData()">

      <DragnDropArea hoverText="Hochladen" @files="onFileChange">
        ISIN-Liste:<br>
        <BaseFileSelect @files="onFileChange" accept='.txt' :class="{'mb-3': !importData.file}">
          Textdatei hochladen
        </BaseFileSelect>
        <div>
          <div class="input-chip" v-if="importData.file">
            <span>{{importData.file && importData.file.fileName}}</span>
            <ph-x-circle class="clickable ml-1" :size="16" @click.stop="importData.file = null" />
          </div>
        </div>
        <div class="mb-3">
          <b>Dateiformat:</b> Textdatei (.txt)<br>
          <b>Dateiaufbau:</b><br>
          <b>Beispiel 1:</b><br>
          ISIN 1<br>
          ISIN 2<br>
          ISIN ...<br>
          <b>Beispiel 2:</b> <br>
          ISIN 1, ISIN 2, ISIN ...<br>
        </div>
        <InputToggleSwitch 
          v-if="!(selectedFavorit.fondsshop && !options.zugriffUneingeschraenkt)"
          class="mt-2" 
          style="padding-bottom:0px" 
          v-model="importData.replace"
          label="<b>Alle</b> Wertpapiere ersetzen"
          inLineLabel
        />
      </DragnDropArea>
    </BaseModal>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import axios from 'axios';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import { PhXCircle } from "phosphor-vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam } from '@/helpers/utils-helper';
import WertpapierTable from '@/views/brokerData/WertpapierTable.vue'
import { mapGetters } from 'vuex';
import FONDSFAVORITEN_TYPES from '@/store/fondsfavoriten/types';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputToggleSwitch,
    InputTextArea,
    BaseModal,
    PhXCircle,
    BaseFileSelect,
    DragnDropArea,
    WertpapierTable,
  },
  data() {
    return {
      options: {},
      descriptionSearch: '',
      bemerkung: '',
      isEditBem: true,
      loading: false,
      selectedFavorit: {},
      selectedRows: [],
      importData: {
        file: null,
        replace: false,
      },
      wpRows: [],
      wpHeaders: [],
    }
  },

  computed: {
    ...mapGetters({
      favoritenlists: FONDSFAVORITEN_TYPES.GETTERS.FAVORITENLISTS,
      optionsList: FONDSFAVORITEN_TYPES.GETTERS.OPTIONS,
    }),
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('BULK_DELETE', 'Markierte entfernen')
          .withVisible(() => this.isSomeRowDeletable)
          .withDisabled(() => this.loading || !this.selectedRows.some(row => row.actions.some(action => action.actionKey === 'DELETE'))),
      ];
    },
    isSomeRowDeletable() {
      return this.wpRows.some(row => row.actions.some(action => action.key === 'DELETE'))
    },
    headerActions() {
      const { selectedFavorit, options, } = this;
      const isVisible = selectedFavorit 
        && (selectedFavorit.owner || (selectedFavorit.primaryFondsshop && options.zugriffWertpapiere)) 
        && options.favoritenRestricted === false;

      return [
        PageHeaderSimpleAction('ADD', 'Wertpapier hinzufügen').withVisible(() => isVisible).withDisabled(() => this.loading),
        PageHeaderSimpleAction('FONDSVERGLEICH', 'Fondsvergleich').withDisabled(() => this.loading || !this.selectedRows.length),
        PageHeaderSimpleAction('IMPORT', 'Wertpapiere importieren').withVisible(() => isVisible).withDisabled(() => this.loading),
      ];
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        name: this.selectedFavorit.labelShort, 
        title: this.selectedFavorit.labelShort,
        printMaklerLogo: '1',
      };
    },  
    rows() {
      let records = this.wpRows

      if (this.descriptionSearch.length) {
        const regex = new RegExp(this.descriptionSearch, "i");
        records = records.filter(r => regex.test(r.fondsname) || regex.test(r.gesellschaft) || regex.test(r.kategorie) || regex.test(r.isin));
      }

      return records;
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    deleteWertpapier(rows) {
      const rowsToDelete = rows.filter(row => row.actions.some(action => action.actionKey === 'DELETE'))

      if (!rowsToDelete.length) {
        return
      }

      this.$confirmModal({
        title: rowsToDelete.length === 1 ? 'Wertpapier' : 'Wertpapiere' + ' entfernen',
        message: rowsToDelete.length === 1 ? `Wollen Sie das Wertpapier \"${rowsToDelete[0].fondsname}\" wirklich aus der Favoritenliste entfernen?` 
          : 'Wollen Sie die ausgewählten Wertpapiere wirklich aus der Favoritenliste entfernen?',
        labelButtonConfirm: 'Entfernen',
      }).then(() => {
        this.loading = true

        const isinList = rowsToDelete.map(row => row.isin)
        const params = makeQueryParam({isinList, favoritenListId: this.selectedFavorit.id})

        axios.delete(`${process.env.VUE_APP_API}/favoritenlisteService/remove_wps?${params}`, config)
        .then(() => {
          isinList.forEach(isin => {
            const index = this.wpRows.findIndex(row => row.isin === isin);
            if (index >= 0) {
              this.wpRows.splice(index, 1);
            }
          })
          this.selectedRows = []
        }).finally(() => this.loading = false)
      }).catch(() => {})
    },
    async initialize() {
      this.loading = true;

      const starterRow = this.favoritenlists.find( f => f.id == this.$route.params.favoritenlisteId);
      this.bemerkung = '';
      if (starterRow) {
        this.selectedFavorit = starterRow;
        this.options = this.optionsList;
      } else {
        const params = makeQueryParam({favoritenListId: this.$route.params.favoritenlisteId})

        await axios.get(`${process.env.VUE_APP_API}/favoritenlisteService/loadfavorit?${params}`, config).then(response => {
            if (response && response.data) {
              this.selectedFavorit = response.data.favorit
              this.options = response.data.options
            }}
        ).finally(() => this.loading = false)
      }

      if (this.options && this.selectedFavorit) {
        this.bemerkung = this.selectedFavorit.bemerkung ;
        this.isEditBem = this.selectedFavorit.owner;
        this.requestWpList()
      }
    },
    resetImportData() {
      this.importData = {
        file: null,
        replace: false,
      }
    },
    onFileChange(files) {
      const reader = new FileReader();
      reader.onload = e => {
        let base64string = reader.result.split(",").pop();
        this.importData.file = {
          fileName: files[0].name,
          data: base64string
        }
      }
      reader.readAsDataURL(files[0]);
    },
    importWertpapiere() {
      if (!this.importData?.file) {
        return
      }
      this.loading = true
      const payload = {
        ...this.importData,
        favoritenliste: {id: this.selectedFavorit.id},
      }

      axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/import`, payload, config)
        .then(response => {
          if (response && response.data) {
            this.$route.params.favoritenlisteId = response.data
            this.$router.push(`/intern/fondsfavoritenliste/wertpapiere/${this.$route.params.favoritenlisteId}`)
            this.initialize()
          }
        }).catch(() => {
          this.loading = false
        });
    },
    addFond() {
      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
      });

      this.$router.push(`/intern/fondsfavoritenliste/wertpapiere/search/${this.selectedFavorit.id}?fondsshop=${this.selectedFavorit.fondsshop}`)
    },
    openFondsvergleich() {
      if (this.selectedRows.length){
        let isinList = this.selectedRows.reduce( (result, row) => result + ',' +row.isin, '').slice(1);
        this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPNEW, isinList ).then((response) => {
          if (response.data?.fv_id) {
            this.$addBreadcrumb({
              fullPath: this.$route.fullPath,
            });
            this.$router.push('/beratung/fondsvergleich/' + response.data?.fv_id);
          }
        });
      }
    },
    requestWpList() {
      this.loading = true;

      const cancelableRequestConfig = {
        defaultSpinner: true,
        cancelableRequest: {
          title: 'Favoritenliste Wertpapiere'
        }
      };

      const params = makeQueryParam({favoritenListId: this.$route.params.favoritenlisteId})

      axios.get(`${process.env.VUE_APP_API}/favoritenlisteService/wp_list?${params}`, cancelableRequestConfig).then(response => {
        if (response && response.data) {
          this.wpRows = response.data.rows
          this.wpHeaders = response.data.headers
        }      
      }).finally(() => {
        this.loading = false;
      });
    },
    editBemerkung() {
      if (this.bemerkung != this.selectedFavorit.bemerkung && this.selectedFavorit.owner) {
        const payload = {
          id: this.selectedFavorit.id,
          bemerkung: this.bemerkung
        }
        axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/executeAction?action=BEMERKUNG`, payload)
          .then(() => {
            this.selectedFavorit.bemerkung = this.bemerkung;
          });
      }
      
    },
  },
}
</script>

<style scoped>
.input-chip {
  border-radius: 4px;
  border: 1px solid var(--color-text);
  padding: 0 4px;
  display: inline-block;
  margin: 10px 0px 4px 0px;
}
</style>
