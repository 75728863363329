var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.title,
          subtitle: _vm.depotTitle,
          actions: _vm.headerActions
        }
      }),
      _vm.getCards.length
        ? _c("div", [
            !_vm.$isSmallScreen
              ? _c(
                  "div",
                  { staticClass: "depotpositions-categories__container" },
                  _vm._l(_vm.getCards, function(item, index) {
                    return _c("div", { key: "sum" + index }, [
                      _c(
                        "div",
                        {
                          staticClass: "box__container",
                          attrs: {
                            tid: _vm._generateTidFromString(
                              "box__container" + "sum" + index
                            )
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-small text-center mp-0" },
                            [_vm._v(_vm._s(item.label))]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center mp-0" },
                            [
                              _c("CurrencyLabel", {
                                attrs: { value: item.value }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _vm._l(_vm.getCards, function(item, index) {
                      return [
                        _c("MobileInfoRow", {
                          key: "sum" + index,
                          attrs: { label: item.label },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "value",
                                fn: function() {
                                  return [
                                    _c("CurrencyLabel", {
                                      attrs: { value: item.value }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    })
                  ],
                  2
                )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "93ed63c9-5c64-4ae0-be49-a1a5e1008f3b" }
        },
        [
          !_vm.refresh
            ? _c(
                "div",
                { staticClass: "table-wrap" },
                [
                  _vm.sparplaeneRows.length
                    ? _c("Table", {
                        attrs: {
                          title: "Sparpläne",
                          tableId: "e4638090-f381-4da4-b8e0-7d3c7de233c7",
                          headers: _vm.headers,
                          rows: _vm.sparplaeneRows,
                          exportConfig: _vm.exportConfigSpar,
                          rowId: "index",
                          hidePagination: "",
                          mobileConfig: {}
                        },
                        on: {
                          "click-FONDSNAME": _vm.navigateToFondsinfo,
                          "action-COURTAGE_BGS": _vm.openCourtagehistorie,
                          "action-LIMITS": _vm.openLimits,
                          "action-TRANSAKTIONEN": _vm.openTransactions,
                          "action-NEW_APPOIMENT": _vm.openAddNewActivityModal,
                          "action-FACTSHEET": function($event) {
                            return _vm.openFactsheetFile($event, "FACTSHEET")
                          },
                          "action-KAG": function($event) {
                            return _vm.openFactsheetFile($event, "MR")
                          },
                          "action-PRIIP-BIB": function($event) {
                            return _vm.openFactsheetFile($event, "KIID")
                          }
                        }
                      })
                    : _c("NoData", { attrs: { title: "Sparpläne" } })
                ],
                1
              )
            : _c("GhostLoading", { attrs: { type: "table" } })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "92363e45-aee1-49be-8322-99525e8c8f9e" }
        },
        [
          !_vm.refresh
            ? _c(
                "div",
                { staticClass: "table-wrap" },
                [
                  _vm.entnahmeplaeneRows.length
                    ? _c("Table", {
                        attrs: {
                          title: "Entnahmepläne",
                          tableId: "e4638090-f381-4da4-b8e0-7d3c7de233c7",
                          headers: _vm.headers,
                          rows: _vm.entnahmeplaeneRows,
                          exportConfig: _vm.exportConfigEntnahme,
                          rowId: "BGS_NR",
                          hidePagination: "",
                          mobileConfig: {}
                        },
                        on: {
                          "click-FONDSNAME": _vm.navigateToFondsinfo,
                          "action-COURTAGE_BGS": _vm.openCourtagehistorie,
                          "action-LIMITS": _vm.openLimits,
                          "action-TRANSAKTIONEN": _vm.openTransactions,
                          "action-NEW_APPOIMENT": _vm.openAddNewActivityModal,
                          "action-FACTSHEET": function($event) {
                            return _vm.openFactsheetFile($event, "FACTSHEET")
                          },
                          "action-KAG": function($event) {
                            return _vm.openFactsheetFile($event, "MR")
                          },
                          "action-PRIIP-BIB": function($event) {
                            return _vm.openFactsheetFile($event, "KIID")
                          }
                        }
                      })
                    : _c("NoData", { attrs: { title: "Entnahmepläne" } })
                ],
                1
              )
            : _c("GhostLoading", { attrs: { type: "table" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }