var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Ändern/Neue Zuordnungen für Data Control"
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2" },
            [
              _c("ComboBox", {
                attrs: { label: "Sparte MSC", values: _vm.getSparten },
                model: {
                  value: _vm.form.sparteMSC,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sparteMSC", $$v)
                  },
                  expression: "form.sparteMSC"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2" },
            [
              _c("InputField", {
                attrs: { isComponentHalfSize: "" },
                model: {
                  value: _vm.form.sparteMSCText,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sparteMSCText", $$v)
                  },
                  expression: "form.sparteMSCText"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2" },
            [
              _c("ComboBox", {
                attrs: {
                  label: "Gesellschaftsname MSC",
                  values: _vm.getGesellschaften
                },
                model: {
                  value: _vm.form.gesellschaftsnameMSC,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gesellschaftsnameMSC", $$v)
                  },
                  expression: "form.gesellschaftsnameMSC"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2" },
            [
              _c("InputField", {
                model: {
                  value: _vm.form.gesellschaftsnameMSCText,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gesellschaftsnameMSCText", $$v)
                  },
                  expression: "form.gesellschaftsnameMSCText"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2" },
            [
              _c("InputField", {
                attrs: { label: "Produkt DC" },
                model: {
                  value: _vm.form.productDC,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productDC", $$v)
                  },
                  expression: "form.productDC"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2 mb-3" },
            [
              _c("InputField", {
                attrs: { label: "Gesellschaft DC" },
                model: {
                  value: _vm.form.gesellschaftsnameDC,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gesellschaftsnameDC", $$v)
                  },
                  expression: "form.gesellschaftsnameDC"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 mt-2 mb-3" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.saveProduct()
                    }
                  }
                },
                [_c("span", [_vm._v("Speichern")])]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }