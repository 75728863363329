<template>
  <div>
    <Table
      title="Wertpapiere"
      tableId="579b1932-470b-4522-8482-1266152082a3"
      :headers="newHeaders"
      :rows="records"
      :rowsPerPage="records.length"
      :id="_generateTidFromString('WERTPAPIER_NAME' + 'ANTEILE' + 'GESELLSCHAFT')"
      hidePagination
    >

      <template #WERT_FW="row">
        <CurrencyLabel :value="row.WERT_FW" :symb="currencyTypeToLocaleIdentifier(row)"/>
      </template>

      <template #GUV="row">
        <CurrencyLabel :value="row.WERT_FW.procentPA || row.WERT_FW.procent" symb="%"/>
      </template>      
    </Table>    
  </div>
</template>

<script>
import Table from '@/components/table2/Table.vue';
import {TextColumn, CurrencyColumn, NumberColumn, PercentageColumn, DateColumn, SlotColumn} from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    depotPositions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    newHeaders() {
      const headers = this.headers;
      const result = {
        center: []
      };

      Object.values(headers).forEach(({ key, label, dataType }) => {
        if (key === "WERT_FW" || key === "GUV") {
          result.center.push(SlotColumn(key, label));
        } else {
          switch (dataType) {
            case "String":
              result.center.push(TextColumn(key, label));
              break;
            case "Currency":
              result.center.push(CurrencyColumn(key, label));
              break;
            case "Double":
              result.center.push(NumberColumn(key, label));
              break;
            case "Procent":
              result.center.push(PercentageColumn(key, label));
              break;
            case "Date":
              result.center.push(DateColumn(key, label));
              break;
            default:
              result.center.push(TextColumn(key, label));
              break;
          }
        }
      });

      return result;
    },
    headers() {
      const headers = this.depotPositions?.headers || [];
      return headers.reduce((result, header) => {
        header.visible = true;
        header.sortable = true;

        result[header.key] = header;
        return result;
      }, {});
    },
    records() {
      const headers = this.depotPositions?.headers || [];
      const records = this.depotPositions?.records || [];
      return records.map((record) => {
        const values = record?.values || [];
        return values.reduce((result, value, index) => {
          result[headers[index].key] = value;
          return result;
        }, {});
      });
    },
  },
  methods: {		
    currencyTypeToLocaleIdentifier(row) {
			let result = '€';

			if (row.WAEHRUNG === 'USD') {
				result = '$'
			}

			return result;
		},	

  },
  components: {
    Table,
    CurrencyLabel,
  },
}
</script>
