var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Dokumente hinzufügen",
          isSticky: "",
          actions: _vm.headerActions
        },
        on: {
          "action-SELECT-FILE": _vm.onFileChange,
          "action-RESET": function($event) {
            return _vm.resetState()
          },
          "action-SEND": function($event) {
            return _vm.sendDocuments()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(" PDF Datei mit Barcode hochladen und verarbeiten ")
          ]),
          _c(
            "DragnDropArea",
            {
              attrs: {
                disabled: _vm.sendRunning || _vm.isEingang,
                hoverText: "PDF Datei mit Barcode hochladen und verarbeiten"
              },
              on: { files: _vm.onFileChange }
            },
            [
              _vm.uploadStatus
                ? _c(
                    "div",
                    { staticClass: "font-bold mb-3" },
                    [
                      _vm._v(" " + _vm._s(_vm.uploadStatus) + " "),
                      _c("AnimatedSpinner")
                    ],
                    1
                  )
                : _vm._e(),
              _c("InputToggleSwitch", {
                attrs: {
                  inLineLabel: "",
                  suppressValidationMessage: "",
                  disabled:
                    _vm.sendRunning || _vm.filesWithoutError.length != 1,
                  label: "Folgedokumente ohne Barcode anhängen"
                },
                on: {
                  input: function($event) {
                    return _vm.updateNextBarcodes(
                      _vm.filesWithoutError[0].id,
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.isUpdateNextBarcodes,
                  callback: function($$v) {
                    _vm.isUpdateNextBarcodes = $$v
                  },
                  expression: "isUpdateNextBarcodes"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  inLineLabel: "",
                  suppressValidationMessage: "",
                  disabled: _vm.sendRunning || this.uploadedFiles.length != 1,
                  label: "Nur Barcodes vom Deckblatt verwenden"
                },
                on: {
                  input: function($event) {
                    return _vm.updateDeckblatt(
                      _vm.filesWithoutError[0].id,
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.isUpdateDeckblatt,
                  callback: function($$v) {
                    _vm.isUpdateDeckblatt = $$v
                  },
                  expression: "isUpdateDeckblatt"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "nur fehlerhafte Daten anzeigen",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                on: { input: _vm.toggledFehlerhafte },
                model: {
                  value: _vm.nurFehlerhafte,
                  callback: function($$v) {
                    _vm.nurFehlerhafte = $$v
                  },
                  expression: "nurFehlerhafte"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "gelöschte Daten anzeigen",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.showDeleted,
                  callback: function($$v) {
                    _vm.showDeleted = $$v
                  },
                  expression: "showDeleted"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Dokumente nur archivieren, keine Weiterleitung",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.nurArchivieren,
                  callback: function($$v) {
                    _vm.nurArchivieren = $$v
                  },
                  expression: "nurArchivieren"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      this.filesWithoutError.length && !_vm.canSend && _vm.showBarcodeControls
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", [
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errorMessage))
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "DragnDropArea",
        {
          staticClass: "card-container",
          attrs: {
            disabled: _vm.isEingang,
            hoverText: "PDF Datei mit Barcode hochladen und verarbeiten"
          },
          on: { files: _vm.onFileChange }
        },
        _vm._l(_vm.rows, function(row) {
          return _c(
            "div",
            { key: row.index, staticClass: "col-12 col-md-6 col-xl-4" },
            [
              _c(
                "div",
                { staticClass: "box__container card" },
                [
                  !row.page.sendet
                    ? _c(
                        "a",
                        {
                          staticClass: "card-action",
                          on: {
                            click: function($event) {
                              return _vm.togglePage(
                                row.page.eingangId,
                                row.page.rownum,
                                !row.page.active
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " Seite " +
                              _vm._s(
                                row.page.active ? "Löschen" : "Wiederherstellen"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    [
                      _c("DownloadLink", {
                        attrs: {
                          target: "_blank",
                          rel: "noopener noreferer",
                          title:
                            row.Bezeichnung && row.Bezeichnung.trim().length > 0
                              ? row.Bezeichnung
                              : "Seite nicht anerkannt",
                          downloadServicePath: "/page_download",
                          queryParams: {
                            eingangId: row.page.eingangId,
                            pageNo: row.page.rownum
                          }
                        }
                      }),
                      _vm._v(
                        " | " +
                          _vm._s(_vm._f("titleCasing")(row.Dateityp)) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-left": "30px",
                        "margin-top": "16px"
                      }
                    },
                    [
                      _c("span", { staticClass: "card-left" }, [
                        _vm._v("Status:")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "card-content",
                          class: [
                            row.page.error ? "error-message" : "",
                            row.page.active ? "" : "page-inactive"
                          ],
                          staticStyle: { "margin-bottom": "5px" }
                        },
                        [_vm._v(_vm._s(row.Status))]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "card-left" }, [
                        _vm._v("Kundenname:")
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "card-content",
                          on: {
                            click: function($event) {
                              return _vm.openCustomerNewTab(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.Kundenname))]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "card-left" }, [
                        _vm._v("Gesellschaft:")
                      ]),
                      _c("span", { staticClass: "card-content" }, [
                        _vm._v(_vm._s(row.Gesellschaft))
                      ]),
                      _c("br")
                    ]
                  ),
                  _c("InputField", {
                    staticStyle: { maxwidth: "250px" },
                    attrs: {
                      value: _vm.unsavedBarcodes[_vm.rowKey(row)]
                        ? _vm.unsavedBarcodes[_vm.rowKey(row)].barcode
                        : row.page.barcode,
                      label: "Barcode",
                      labelClass: "small-text",
                      disabled: _vm.sendRunning || row.page.sendet
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeBarcode(row, $event)
                      }
                    }
                  }),
                  _c("InputField", {
                    staticStyle: { maxwidth: "250px" },
                    attrs: {
                      value: _vm.unsavedBarcodes[_vm.rowKey(row)]
                        ? _vm.unsavedBarcodes[_vm.rowKey(row)].pageNo
                        : row.page.pageNo,
                      label: "Seite",
                      labelClass: "small-text",
                      disabled: _vm.sendRunning || row.page.sendet,
                      type: "number"
                    },
                    on: {
                      change: function($event) {
                        return _vm.changePageIndex(row, $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      this.collatedFiles.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Table", {
                attrs: {
                  title: "Erfolgreich zugeordnete Dateien",
                  headers: _vm.collatedHeaders,
                  rows: _vm.collatedFiles,
                  rowId: "barcode"
                },
                on: { "click-kundenname": _vm.openCustomerNewTab }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }