import Vue from 'vue';
import store from '@/store';
import LOG_TYPES from '@/store/log/types';

import { isUserHasRequiredRole, isOptionMenuPermissionVisible, isRolesIncludes, } from '@/router/guards';
import { isOptionMenuPermissionConfigVisible } from '@/menu/menu-config-guards';
import ROLES from '@/router/roles';
import { 
  validatesPrimaryMenuAsRoot, 
  validatesPrimaryMenuAsSubMenu,
  validatesPrimaryMenuWithTabMenu, 
  validatesGroupMenuWithTabMenu, 
  validatesMenuItemWithGroupMenu, 
  validatesMenuItemWithMenuItem,
} from '@/menu/menu-utils';

import homeMenus from './features-menus/home';
import customerMenus from './features-menus/customer';
import communicationMenus from './features-menus/communication';
import persoenlicheDatenMenus from './features-menus/persoenlicheDaten';
import internMenus from './features-menus/intern';
import beratungMenus from './features-menus/beratung';
import services from './features-menus/services';
import dbm from './features-menus/dbm';

/**
 * Menu
 * - All menus are presented by checking the access/roles linked to it
 */
const rootMenu = [
  homeMenus,
  customerMenus,
  communicationMenus,
  persoenlicheDatenMenus,
  beratungMenus,
  services,
  dbm,
  internMenus,
];
rootMenu.forEach(validatesPrimaryMenuAsRoot);

/**
 * App Menu object
 * @param {*} options
 * @returns 
 */
function AppMenu({ flatMenu }) {
  return {
    flatMenu, 
  };
}

/**
 * Load async menus
 */
async function _resolveAsyncMenu(router, flatMenu, configContext) {
  // find menus to resolve
  const menusToResolve = flatMenu
    .filter(menu => menu.async);

  // resolve menus
  await Promise.all(menusToResolve.map(async asyncMenu => {
    try {
      const subMenuResolved = await store.dispatch(asyncMenu.path) || [];
      const checkedSubMenu = subMenuResolved
        .reduce((menuList, menu) => _checkMenu(router, menuList, menu, configContext), [])
        .map(m => ({
          ...m,
          resolvedFrom: asyncMenu,
        }));
      Vue.set(asyncMenu, '__resolved__', checkedSubMenu);
    } catch(e) {
      store.dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "resolve async menu error", e });
      Vue.delete(asyncMenu, '__resolved__');
    }
  }));

  // new flat menu with resolved menus
  return _prepareFlatMenuWithResolvedMenus(router, flatMenu);
}

function _prepareFlatMenuWithResolvedMenus(router, flatMenu) {
  const resolvedMenuConfig = flatMenu
    .filter(menu => '__resolved__' in menu)
    .reduce((acc, menu) => ({ 
      ...acc, 
      [menu.path]: (acc[menu.path] ?? []).concat(menu.__resolved__), 
    }), {});

  const resolvedMenuMappedByPath = Object.keys(resolvedMenuConfig).reduce((acc, key) => {
    const asyncMenu = flatMenu.find(m => m.path === key);
    return {
      ...acc,
      [key]: resolvedMenuConfig[key].map(m => mapMenu(router, m, asyncMenu.parent)),
    };
  }, {});

  const mapResolvedMenu = (menu) => {
    if (menu.async) {
      // replace async by resolved menus
      return resolvedMenuMappedByPath?.[menu?.path] ?? [];
    }

    const mappedMenu = {
      ...menu,
      subMenu: menu?.subMenu?.flatMap(mapResolvedMenu),
    };
    return [mappedMenu];
  };

  const flatMenuResolved = Object.values(resolvedMenuMappedByPath)
    .flatMap(v => v)
    // adds resolved menu item subMenu - it goes only one level deep
    .flatMap(menu => [ menu, ...(menu.subMenu ?? []) ]);

  return [ ...flatMenu.filter(menu => !menu.async), ...flatMenuResolved, ]
    .flatMap(mapResolvedMenu);
}

function menuLabel(menu, __CONTEXT_ROLES__) {
  if(Array.isArray(menu.label)) {
    const labels = [ ...menu.label, ];
    for(const label of labels) {
      if(!label.roles?.length || isRolesIncludes(label.roles, __CONTEXT_ROLES__)) {
        return label.label;
      }
    }
    return '';
  }

  return menu.label;
}

function _hasTestUserRole(router, path) {
  const matchRoute = router.matcher.match(path);
  return matchRoute.matched.some(route => {
    const allowed = route?.meta?.roles?.allowed || [];
    return allowed.some(a => Array.isArray(a) ? a.includes(ROLES.TEST_USER) : a === ROLES.TEST_USER);
  })
}

/**
 *
 * @param {import('vue-router').VueRouter} router
 * @param {*} menuList - menu result list where the menu is added if allowed
 * @param {*} menu - menu to be checked
 * @param {*} configContext
 */
function _checkIfItsAllowedAndAddToList(router, menuList, menu, configContext) {
  const { __CONTEXT_ROLES__, } = router.options;

  const to = router.matcher.match(menu.path);

  // if the customer has the required role, then the redirect variable from the callback is undefined. 
  // in other words, if redirect is undefined, the current user has the required role
  isUserHasRequiredRole(to, null, (redirect) => {
    if (redirect) {
      return;
    }

    // check the option menu configuration
    if (configContext) {
      isOptionMenuPermissionConfigVisible(configContext, to, null, (redirect) => {
        if(!redirect) {
          menuList.push(menu);
        }
      });
    } else {
      isOptionMenuPermissionVisible(to, null, (redirect) => {
        if(!redirect) {
          menuList.push(menu);
        }
      });
    }
  }, false, __CONTEXT_ROLES__);
}

/**
 * Map menu initial properties
 * 
 * @param {*} menu 
 * @param {*} parent 
 * @returns 
 */
const mapMenu = (router, menu, parent = null) => {
  const mappedMenu = {
    ...menu,
    parent: (parent ? { ...parent, subMenu: undefined, } : undefined),
    label: menuLabel(menu, router.options.__CONTEXT_ROLES__), // TODO check this call
    hasSubMenu: menu.group || menu.subMenu?.length > 0,
    hasResolve: 'resolve' in menu,
    hasTabMenu: menu.subMenu?.some?.(m => m.tab === true),
    hasTestUserRole: _hasTestUserRole(router, menu.path),
    resolvedFrom: menu.resolvedFrom, // it has a valid value only when a menu is resolved from an async menu
  };

  return {
    ...mappedMenu,
    subMenu: (menu.subMenu || []).map(sm => mapMenu(router, sm, mappedMenu)),
  };
};

/**
   * Check if the current user has the required roles to access the menu
   *
   * @param {import('vue-router').VueRouter} router
   * @param {*} menuList
   * @param {*} menu 
   * @param {*} parent
   * @param {*} configContext
   * @returns 
   */
function _checkMenu(router, menuList, menu, configContext) {
  const menuToCheck = { ...menu, };
  _checkIfItsAllowedAndAddToList(router, menuList, menuToCheck, configContext);
  if(menuList[menuList.length - 1]?.path === menuToCheck.path && menuToCheck?.subMenu?.length > 0) {
    menuToCheck.subMenu = menu.subMenu.reduce((menuList, menu) => _checkMenu(router, menuList, menu, configContext), []);
  }

  return menuList;
}

// make flat menu
const makeFlatMenu = (menuList, menu) => {
  return [
    ...menuList,
    menu,
    ...menu.subMenu.reduce(makeFlatMenu, []),
  ];
};

// parents label
function findParentsLabel(labels, menu) {
  if(!menu.parent) return labels;
  return [...[menu.parent].reduce(findParentsLabel, labels), menu.parent.label];
}

function mapParentsLabel(menu) {
  const parentsLabel = [menu].reduce(findParentsLabel, []);
  return {
    ...menu,
    subMenu: [ ...menu.subMenu || [], ].map(mapParentsLabel),
    parentsLabel: parentsLabel?.length ? parentsLabel : undefined,
  };
}

function removeEmptyGroups(menuList, menu) {
  const menuCopy = {
    ...menu, 
    subMenu: menu.subMenu ? menu.subMenu.reduce(removeEmptyGroups, []) : menu.subMenu, 
  };

  if ((!menuCopy.parent || menuCopy.group) && !menuCopy.subMenu?.length) {
    return menuList;
  }

  return [ 
    ...menuList, 
    menuCopy, 
  ];
}

/**
 * Create a new menu instance
 * 
 * @param {import('vue-router').VueRouter} router
 * @param {*} [configContext]
 * @returns 
 */
export async function createAppMenu(router, { 
  configContext = null, 
  disableResolve = false, 
  withEmptyGroups = false, 
} = {}) {
  if(!router) throw 'A "router" instance must be passed to "createAppMenu"';

  const menus = [ ...rootMenu, ];
  const mappedMenu = menus.map(menu => mapMenu(router, menu));
  const checkedMenu = mappedMenu
    .reduce((menuList, menu) => _checkMenu(router, menuList, menu, configContext), []);
  const flatCheckedMenu = checkedMenu.reduce(makeFlatMenu, []);
  const flatMenuResolved = !disableResolve ? await _resolveAsyncMenu(router, flatCheckedMenu, configContext) : flatCheckedMenu;
  const flatMenu = withEmptyGroups ? flatMenuResolved : flatMenuResolved.reduce(removeEmptyGroups, []);

  // validates
  flatMenu.forEach(menu => {
    validatesPrimaryMenuAsSubMenu(menu);
    validatesPrimaryMenuWithTabMenu(menu);
    validatesGroupMenuWithTabMenu(menu);
    validatesMenuItemWithGroupMenu(menu);
    validatesMenuItemWithMenuItem(menu);
  });

  return new AppMenu({ 
    flatMenu: flatMenu.map(mapParentsLabel), 
  });
}
