var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$isSmallScreen,
              expression: "!$isSmallScreen"
            }
          ]
        },
        [
          _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          }),
          _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: "Postfach",
              subtitle: "E-Mail",
              actions: _vm.headerActions
            },
            on: {
              "action-REPLY": _vm.onReplyChat,
              "action-FORWARD": _vm.onReply,
              "action-WEITERLEITEN_USER": _vm.handleActionWeiterleitenUser,
              "action-WEITERLEITEN_COMPANY": _vm.handleActionWeiterleitenCompany
            }
          })
        ],
        1
      ),
      _c(
        "BasePageContent",
        {
          attrs: {
            modalTitle: "Postfach - Email",
            defaultLastAction: "/communication/postfach/emails",
            modalActions: _vm.modalHeaderActions
          },
          on: {
            "action-REPLY": _vm.onReplyChat,
            "action-FORWARD": _vm.onReply,
            "action-WEITERLEITEN_USER": _vm.handleActionWeiterleitenUser,
            "action-WEITERLEITEN_COMPANY": _vm.handleActionWeiterleitenCompany
          }
        },
        [
          _vm.email
            ? _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "email-title--data" }, [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(_vm._s(_vm.email.subject))
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.email.dateSend))])
                ]),
                _vm.email.mailInfo && _vm.email.mailInfo.trim().length > 0
                  ? _c("div", { staticClass: "email-header--data" }, [
                      _c("span", [_vm._v(_vm._s(_vm.email.mailInfo))])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "email-header--data" },
                  [
                    _c("span", [_vm._v("Von")]),
                    _c("span", [_vm._v(_vm._s(_vm.email.fromAddress))]),
                    _c("span", [_vm._v("An")]),
                    _c("span", [_vm._v(_vm._s(_vm.email.toAddress))]),
                    _vm.email.ccAddress
                      ? [
                          _c("span", [_vm._v("CC")]),
                          _c("span", [_vm._v(_vm._s(_vm.email.ccAddress))])
                        ]
                      : _vm._e(),
                    _vm.isBrokerOrBypass
                      ? [
                          _c("span", [_vm._v("Status")]),
                          _c("span", {
                            class: "color-" + _vm.email.status,
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sanitize(_vm.email.statusText)
                              )
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.email && _vm.email.attachments && _vm.email.attachments.length
            ? _c("div", { staticClass: "box__container" }, [
                _vm._v(" Anhang "),
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "d-flex attachments-lines" },
                  _vm._l(_vm.email.attachments, function(attachment) {
                    return _c(
                      "div",
                      { key: attachment.id, staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "div",
                          [
                            attachment.contentType == "image/png" ||
                            attachment.contentType == "image/jpeg"
                              ? _c("PhImage", { attrs: { size: 16 } })
                              : attachment.contentType == "audio/mpeg"
                              ? _c("PhMusicNotes", { attrs: { size: 16 } })
                              : attachment.contentType == "video/mp4"
                              ? _c("PhMonitorPlay", { attrs: { size: 16 } })
                              : _c("PhFileText", { attrs: { size: 16 } })
                          ],
                          1
                        ),
                        _c("DownloadLink", {
                          attrs: {
                            title: attachment.fileName,
                            filename: attachment.fileName,
                            downloadServicePath: "/get_email_attachment",
                            queryParams: {
                              attId: attachment.id,
                              tempFileId: attachment.tempFileId
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "block", useBoxContainer: "" }
              })
            : _vm._e(),
          _vm.email
            ? _c("div", { staticClass: "box__container" }, [
                _c("div", {
                  staticClass: "email-body",
                  domProps: { innerHTML: _vm._s(_vm.sanitizedText) }
                })
              ])
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: {
                  type: "block",
                  config: { block: { height: 500 } },
                  useBoxContainer: ""
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.mailUsers },
            on: { selectedRows: _vm.selectRowsContacts }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }