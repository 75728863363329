const FC_CONFIG = {
  TABLE_COLUMN_CONFIG: 'TABLE_COLUMN_CONFIG',
  OPTION_MENU_CONFIG: 'OPTION_MENU_CONFIG',
  OPTION_MENU_PERMISSION_CONFIG: 'OPTION_MENU_PERMISSION_CONFIG',
  DASHBOARD_PANEL_CONFIG: 'DASHBOARD_PANEL_CONFIG',
  BROKER_DASHBOARD_CONFIG: 'BROKER_DASHBOARD_CONFIG',
  ADD_PARAMETER: 'ADD_PARAMETER',
  ADVANCED_PARAMETER: 'ADVANCED_PARAMETER',
  RISIKOKLASSEN_DEFINITION_2018: 'RISIKOKLASSEN_DEFINITION_2018',
  LAST_VISUALIZED_EVENT_DATE: 'LAST_VISUALIZED_EVENT_DATE',

  STORED_SEARCH_PARAMETER: 'STORED_SEARCH_PARAMETER',

  FORMULARE_MENU_PERMISSION_CONFIG: 'FORMULARE_MENU_PERMISSION_CONFIG',

  ALLOWED_CONFIG_DEFINITION_GROUP: 'ALLOWED_CONFIG_DEFINITION_GROUP',
  DENIED_CONFIG_DEFINITION_GROUP: 'DENIED_CONFIG_DEFINITION_GROUP',

  ALLOWED_VISUALIZE_MAKLERVERTRAG: 'ALLOWED_VISUALIZE_MAKLERVERTRAG',
  DENIED_VISUALIZE_MAKLERVERTRAG: 'DENIED_VISUALIZE_MAKLERVERTRAG',
	
  ALLOWED_EDIT_MAKLERVERTRAG: 'ALLOWED_EDIT_MAKLERVERTRAG',
  DENIED_EDIT_MAKLERVERTRAG: 'DENIED_EDIT_MAKLERVERTRAG',

  EXTERNE_ZUGANG_BANK_CONFIG: 'EXTERNE_ZUGANG_BANK_CONFIG',

  COLLAPSED_TREE_CONFIG: 'COLLAPSED_TREE_CONFIG',

  CARD_OVERVIEW_CONFIG: 'CARD_OVERVIEW_CONFIG',

  RESIZABLE_MODAL_CONFIG: 'RESIZABLE_MODAL_CONFIG',
}
Object.freeze(FC_CONFIG)

export default FC_CONFIG

export const FC_CONFIG_USER_LEVEL = {
  KUNDE: 'KUNDE',
  MAKLER: 'MAKLER',
  MAKLER_STRUKTUR: 'MAKLER_STRUKTUR',
  MAKLER_MASTER_STRUKTUR: 'MAKLER_MASTER_STRUKTUR',
  MAKLER_PERSONEN: 'MAKLER_PERSONEN',
  MAKLER_MASTER_PERSONEN: 'MAKLER_MASTER_PERSONEN',
  INTERN: 'INTERN',
  GLOBAL: 'GLOBAL',
}
Object.freeze(FC_CONFIG_USER_LEVEL)

export const FC_CONFIG_CONTEXT = {
  INTERN_INTERN: 'INTERN:INTERN', // Intern
  INTERN_MAKLER: 'INTERN:MAKLER', // Makler from an Intern
  INTERN_KUNDE: 'INTERN:KUNDE', // Kunden from an Intern
  MAKLER_MAKLER: 'MAKLER:MAKLER', // Makler / Makler from a Makler / Mitarbeiter
  MAKLER_KUNDE: 'MAKLER:KUNDE', // Kunde from a Makler
  KUNDE_KUNDE: 'KUNDE:KUNDE', // Kunde
}
Object.freeze(FC_CONFIG_CONTEXT)
