<template>
  <div>

    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-BERATUNG_2018="createBeratungsmappe('BERATUNG_2018')">
    </PageHeaderTitleNavigation>

    <BaseFilter 
      filterId="d6d497d4-7dca-4658-8914-928b81f4c0d8"
      title="Beratungsmappen filtern" 
      :metadata="filterMetadata" 
      @onFilter="filterForm($event)">
    </BaseFilter>
    
    <div class="box__container" tid="5d455d91-7c38-429c-8f35-64a522145a20">
      <Table v-if="rows.length"
          tableId="036f03f6-8bcc-4cf5-8b33-f48039d63a32"
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          @click-art="editBeratungsmappe($event.data)"
          @action-copy="copyBeratungsmappe($event.data)"
          @action-delete="confirmDeleteBeratungsmappe($event.data)"
      />

      <GhostLoading v-else-if="loadingBeratungsmappeList" type="table" :title="TABLE_TITLE" />
      <NoData v-else-if="rows.length === 0" :title="TABLE_TITLE" />
    </div>

    <BaseModal @onConfirmButton="deleteBeratungsmappe()" ref="deleteBeratungsmappeConfirmation" modalTitle="Beratungsmappe löschen" labelButtonConfirm="Löschen">
      {{ deleteBeratungsmappeMessage }}
    </BaseModal>    
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import BERATUNGSMAPPE_TYPES from "@/store/beratungVersicherung/types";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import BaseFilter from "@/components/core/BaseFilter.vue";
import dayjs from "dayjs";
import {
  PhCopy,
  PhTrash,
} from "phosphor-vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import {TextColumn, DateTimeColumn, NumberColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

const TABLE_TITLE = 'Beratungsmappen Versicherung Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    BaseFilter,
    OptionMenu,
    GhostLoading,
    NoData,
    BaseModal,
    PageHeaderTitleNavigation,
  },
  data: function () {
    return {
      TABLE_TITLE,
      showModal: false,
      resultCount: null,
      isTest: false,
      filterMetadata: [
          {
            type: "group",
            key: "abDatum",
            label: "Ab Datum",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "letzte 3 Monate",
                key: "LETZTEN_3_MONATEN",
              },
              {
                type: "dateRange",
                label: "Zeitraum",
                key: "ZEITRAUM",
              },
              {
                type: "integer",
                label: "Jahr",
                key: "jahr",
              },

            ],
          },
          {
            type: "group",
            key: "hinterlegtenOrderdokumenten",
            /* label: "Nur Beratungsmappen mit hinterlegten Orderdokumenten anzeigen", */
            label: "Beratungsmappen anzeigen",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "Nur mit hinterlegten Orderdokumenten",
                key: "NUR_HINTERLEGTEN_ORDERDOKUMENTEN",
              },

            ],
          },         
          {
            type: "group",
            key: "abDatumIst",
            label: "Ab Datum Ist",
            menuItems: [
              {
                type: "default",
                label: "Datum geschlossen",
                key: "VALUE_DATE_GESPERRT",
              },
              {
                type: "default",
                label: "Datum erstellt",
                key: "VALUE_DATE_CREATED",
              },


            ],
          },
          {
            type: "group",
            key: "abgeschlossen",
            label: "Abgeschlossen",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "nur offene",
                key: "VALUE_STATUS_OFFEN",
              },
              {
                type: "default",
                label: "nur gesicherte",
                key: "VALUE_STATUS_GESICHERT",
              },
              {
                type: "default",
                label: "nur abgeschlossene",
                key: "VALUE_STATUS_GESCHLOSSEN",
              },            

            ],
          },          
          {
            type: "group",
            key: "fehlerstatus",
            label: "Fehlerstatus",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "nur fehlerfreie",
                key: "VALUE_FEHLER_OK",
              },
              {
                type: "default",
                label: "nur fehlerhafte",
                key: "VALUE_FEHLER_ROT",
              },

            ],
          },          
          {
            type: "group",
            key: "ort",
            label: "Beratungsort",
            menuItems: [
              {
                type: "default",
                label: "Adresse Kunde",
                key: "KUNDE",
              },
              {
                type: "default",
                label: "Standort Versicherungsagent",
                key: "MAKLER",
              },
              {
                type: "default",
                label: "Telefonisch",
                key: "TELEFON",
              },
              {
                type: "default",
                label: "Mail",
                key: "MAIL",
              },
              {
                type: "default",
                label: "Sonstiges",
                key: "SONSTIGES",
              },
            ],
          },         
        ],    
      selectedArt: null,
      filtered: false,
      loadingBeratungsmappeList: false,
      headers: {
          lockedLeft: [
              TextColumn("art", "Art").makeLink(),
              TextColumn("beschreibung", "Beschreibung"),
          ],
          center: [
              DateTimeColumn("erstellt", "erstellt"),
              DateTimeColumn("geschlossen", "geschlossen"),
              NumberColumn("positionen", "Positionen"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],
      },
      selectedBeratungsmappe: null,
      deleteBeratungsmappeMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      beratungVersicherungenList: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_LIST_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      getCoreUserID: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    rows() {
        if (!this.beratungVersicherungenList?.rows)
            return [];
        return this.beratungVersicherungenList.rows.map(item => {
            const actions = [SimpleAction("copy", PhCopy, "Kopieren")];
            if (item.closed_status && item.closed_status !== "IstGespert")
                actions.push(SimpleAction("delete", PhTrash, "Löschen"))
            return {
                id: item.id,
                art: item.art || 'Versicherung',
                beschreibung: item.beschreibung,
                erstellt: item.datum_erstellt,
                geschlossen: item.datum_geschlossen,
                positionen: item.pos,
                data: item,
                actions,
            };
        });
    },
    actions() {
      return [
        PageHeaderSimpleAction('BERATUNG_2018', 'Neue Beratungsmappe'),
      ]
    },
  },
  watch: {
    $route() {
      this.init();
    }
  },
  mounted: function () {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RESET_DATA);

    const pathForCreating = ['create_beratung']
    if (pathForCreating.some(p => to.path.includes(p))) {
      next();
      return
    }

    this.$addBreadcrumb({
      label: 'zurück zu den Beratungsmappen', 
      to,
      from,
      breadcrumb: /* this.selectedArt */'Beratungsmappen Versicherungen'
    });

    next();
  },
  methods: {
    init() {
      this.investementAdviceReferrer = false;
      const action = this.$route.query?.action ?? this.$route.meta?.action
      if (this.$route.query && action && !this.$route.query.backAction) {
        this.createBeratungsmappe(action);   
        return;  
      } 
      if (!this.filtered) {
        this.retrieveBeratungsmappeList();
      }
    },
    getBeratungArtByType(whatType) {
      switch (whatType) {
        case 'BERATUNG_2018':
          return 'Beratungsdokumentation';
        case 'VERMITTLUNG_2018':
          return 'Vermittlungsdokumentation';  
        case  'WEALTH_KONZEPT_2018':
          return 'WealthKonzept VV';
        default:
          return 'Beratungsdokumentation';

      }

    },
    async retrieveBeratungsmappeList(filters) {
      try {
        this.loadingBeratungsmappeList = true;
        await this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST, filters || {});
      } finally {
        this.loadingBeratungsmappeList = false;
      }
    },
    searchDocuments() {

    },
    closeModal() {
      this.showModal = false;
    },

    editBeratungsmappe(beratungsMappeData) {
      this.selectedArt = beratungsMappeData.art;
      this.$router.push({path: '/beratung/beratungsmappe_versicherungen/bearbeiten', query: {
        id: beratungsMappeData.id, art: beratungsMappeData.art, 
        datum: beratungsMappeData.datum_erstellt, 
        investementAdviceReferrer: this.investementAdviceReferrer && !this.$route.query.backAction}});
    },

    deleteBeratungsmappe(beratungsMappeData) {
      if (confirm(`Soll die Beratungsmappe ${beratungsMappeData.beschreibung ? '"' + beratungsMappeData.beschreibung + '"' : ''} wirklich gelöscht werden?`)) {
        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_BERATUNG_VERSICHERUNG,
          {
            beratungsMappeID : beratungsMappeData.id,
          }
        );      

      }
    },

    confirmDeleteBeratungsmappe(beratungsMappeData) {
      this.selectedBeratungsmappe = beratungsMappeData;
      this.deleteBeratungsmappeMessage = `Soll die Beratungsmappe ${beratungsMappeData.beschreibung ? '"' + beratungsMappeData.beschreibung + '"' : ''} wirklich gelöscht werden?`;
      this.$refs.deleteBeratungsmappeConfirmation.open();
    },

    copyBeratungsmappe(beratungsMappeData) {
      if (confirm(`Möchten Sie die Beratungsmappe ${beratungsMappeData.beschreibung ? '"' + beratungsMappeData.beschreibung + '"' : ''} kopieren?`)) {
        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNG_VERSICHERUNG,
          {
            beratungsMappeID : beratungsMappeData.id,
          }
        );      

      }
    },    

    createBeratungsmappe(beratungsmappeTyp) {
      const payload = {
        parameterOpenMappeDjeManaged : null,
        beratungsmappeTyp
      }

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNG_VERSICHERUNG, payload).then(data => {
        this.editBeratungsmappe({ id: data.beratungsMappeId, datum: data.datum})
      });      
    },

    makeActions(item) {
      const actions = [];
      if (item && item.closed_status && item.closed_status !== "IstGespert") {
        actions.push({
          legend: {
            icon: PhTrash,
            index: 1,
            key: "delete",
            label: "Löschen",
            color: "color-text",
          },
        });
      }
      actions.push({
        legend: {
          icon: PhCopy,
          index: 1,
          key: "copy",
          label: "Kopieren",
        },
      });
      return actions;
    },

    executeAction(actionData) {
      switch (actionData.action.legend.key) {
        case 'copy':
          this.copyBeratungsmappe(actionData.value?.data)
          break;
        case 'delete':
          this.deleteBeratungsmappe(actionData.value?.data)
          break;
      }
    },

    filterForm(filterConfig) {
      // console.log(filterConfig);

      this.filtered = true;

      const abDatum = filterConfig.find(fc => fc.group=="abDatum");

      let fromDate = null;
      let toDate = null;

      if (abDatum && abDatum.key =='ZEITRAUM') {
        fromDate = abDatum.value.find(v => v.key == 'min').value;
        toDate = abDatum.value.find(v => v.key == 'max').value;
      }

      if (abDatum && abDatum.key === "LETZTEN_3_MONATEN") {
        fromDate = dayjs().subtract(3, "month").format("DD.MM.YYYY");
      }

      let filterDateJahr = null;

      if (abDatum && abDatum.key === "jahr") {
        filterDateJahr = abDatum.value;
      }      

      const filterModusDatum = filterConfig.find(fc => fc.group=="abDatumIst");
      const filterFehler = filterConfig.find(fc => fc.group=="fehlerstatus");
      let filterStatus = filterConfig.find(fc => fc.group=="abgeschlossen");
      const filterMitScan = filterConfig.find(fc => fc.group=="hinterlegtenOrderdokumenten");
      const ort = filterConfig.find(fc => fc.group=="ort");
      
      if (this.$route.query && this.$route.query.investementAdviceReferrer) {
        filterStatus = 'VALUE_STATUS_OFFEN';
      }

      this.retrieveBeratungsmappeList({
        filterDateVon: fromDate,
        filterDateBis: toDate,
        filterModusDatum: filterModusDatum && filterModusDatum.key,
        filterWithScan: filterMitScan && filterMitScan.value,
        filterFehler: filterFehler && filterFehler.key,
        filterStatus: filterStatus && filterStatus.key,
        filterDateJahr: filterDateJahr,
        filterOrt: ort && ort.key,
      });
    },    
  },
};
</script>

<style scoped>

</style>