<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="$appNavigation.currentMenu.label" />

    <div class="box__container">
      <div class="box__title">PDF- und Excel-Dateien herunterladen mit PIN</div>

      <InputToggleSwitch v-if="isBroker && pinExcel"
        label="PIN-Abfrage bei Mitarbeitern beim Download von PDF- und Excel-Listen"
        inLineLabel
        v-model="pinExcel"
      />
      <template v-if="loading">
        <GhostLoading >
          <Block height="300" />
        </GhostLoading>
      </template>
      <template v-else-if="pinExcel=='Ja' || isIntern">
        <template v-if="rows.length">
          <Table 
              :headers="headers" :rows="rows" rowId="pin" :rowsPerPage="10"
          >
            <template #pin="{pin}">
              <a @click="viewFile(pin)">{{pin[0]}}</a>
            </template>
          </Table>
        </template>
        <NoData noIcon v-else />
      </template>
    </div>

    <div class="box__container">
      <div class="box__title">Tabellen anzeigen mit PIN</div>

      <InputToggleSwitch v-if="isBroker && pinTable"
        label="PIN-Abfrage bei Mitarbeitern beim Anzeigen von Tabellen"
        inLineLabel
        v-model="pinTable"
      />
      <template>
        <ComboBox  v-if="isBroker && sizeTable" style="max-width: 500px;"
          :label="labelSizeTable"
          v-model="sizeTable" 
          :values="sizeTableOptionen"
          @input="onChangeSizeTable"
        />
      </template>
      <template v-if="loading">
        <GhostLoading >
          <Block height="300" />
        </GhostLoading>
      </template>
      <template v-else-if="pinTable=='Ja' || isIntern ">
        <template v-if="rechte.length">
          <Table 
              :headers="headers2" :rows="rechte" rowId="pin" :rowsPerPage="10"
              @action-UNLOCK="unlock"
          >
          </Table>
        </template>
        <NoData noIcon v-else />
      </template>
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BROKERDATA_TYPES from "../../store/brokerData/types";
import CORE_TYPES from "../../store/core/types";
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, DateTimeColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import { PhLockKeyOpen } from "phosphor-vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from "dayjs";

export default {
  components: {
      OptionMenu, PageHeaderTitleNavigation, GhostLoading, Block, Table, NoData, InputToggleSwitch, ComboBox,
  },
  data() {
    return {
      loading: true,
      headers: {
        lockedLeft: [
          SlotColumn("pin", "PIN", 80, 0).makeSortable().makeAlwaysVisible(),
        ],
        center: [
          TextColumn("user", "Benutzer"),
          TextColumn("name", "Name"),
          TextColumn("created", "Erzeugt am"),
        ],
      },
      headers2: {
        lockedLeft: [
          TextColumn("pin", "PIN", 80, 0).makeAlwaysVisible(),
        ],
        center: [
          TextColumn("user", "Benutzer"),
          TextColumn("name", "Aktion / Menü"),
          TextColumn("status", "Status"),
          DateTimeColumn("created", "Erzeugt am"),
        ],
        lockedRight: [
            ActionColumn("actions", ""),
        ],
      },
      statusName: ['bereitgestellt', 'erster falscher Versuch', 'zweiter falscher Versuche', 'gesperrt', 'verwendet'],
      rows: [],
      rechte: [],
      pin_excel: '',
      pinExcel: '',
      pin_table: '',
      pinTable: '',
      size_table: '',
      sizeTable: '',
      sizeTableOptionen: [
        { label: 'So wie im smartMSC eingestellt wurde', value: '0' },
        { label: 'Immer 25 Zeilen', value: '25' },
        { label: 'Immer 50 Zeilen', value: '50' },
        { label: 'Immer 100 Zeilen', value: '100' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
    }),
    labelSizeTable() {
      return 'Anzahl Zeilen pro Tabellenseite'
    },
  },
  mounted() {
    this.loadPinList();
  },
  beforeDestroy() {
    const params = {};
    if (this.pin_excel != this.pinExcel) {
      params.pin_excel = this.pinExcel;
    }
    if (this.pin_table != this.pinTable) {
      params.pin_table = this.pinTable;
    }
    if (this.size_table != this.sizeTable) {
      params.size_table = this.sizeTable;
    }
    if ( Object.keys(params).length) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.UNLOCK_PIN, params );
    }
  },
  methods: {
    async loadPinList() {
      const result = await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_PIN_LIST );
      if (result.data?.rows) {
        const rows = [];
        result.data.rows.forEach(row => {
          rows.push({pin: [row[0],row[4],row[2]], user: row[1], name: row[2], created: row[3]});
        });
        try {
          rows.sort((first, second) => dayjs(second.created, "DD.MM.YY HH:mm") - dayjs(first.created, "DD.MM.YY HH:mm"));
        } catch (e) {
          // empty block
        }
        this.rows = rows;
      }
      if (result.data?.rechte) {
        const rechte = [];
        let actions;
        result.data.rechte.forEach(row => {
          actions = [SimpleAction("UNLOCK", PhLockKeyOpen, "Freischalten")];
          rechte.push({pin: row[0], user: row[1], name: row[2], created: row[3], status: this.statusName[row[5]], id: row[4],
          actions});
        });
        try {
          rechte.sort((first, second) => second.created - first.created);
        } catch (e) {
          // empty block
        }
        this.rechte = rechte;
      }
      if (result.data?.pin_excel) {
        this.pin_excel = result.data.pin_excel;
        this.pinExcel = result.data.pin_excel;
      }
      if (result.data?.pin_table) {
        this.pin_table = result.data.pin_table;
        this.pinTable = result.data.pin_table;
      }
      if (result.data?.size_table) {
        this.size_table = result.data.size_table;
        this.sizeTable = result.data.size_table;
      }
      this.loading = false;
    },
    viewFile(pin) {
      let type = '';
      let content = '';
      if (-1 !== pin[2].indexOf('.xls') ) {
        type = 'xlsx';
        content =  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      if (-1 !== pin[2].indexOf('.pdf') ){
        type = 'pdf';
        content = 'application/pdf';
      }
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_TEMP_FILE, {
          id: pin[1],
          fileName: pin[2],
          type: type,
          contenttype: content,
      });
    },
    async unlock(row) {
      const result = await this.$store.dispatch(CORE_TYPES.ACTIONS.UNLOCK_PIN, {id: row.id} );
      if (result.data === true) {
        this.rechte = this.rechte.map( r => {
          if (r.id === row.id) {
            r.status=this.statusName[0];
            r.actions = [];
          }
          return r;
        })
      }
    },
    onChangeSizeTable(value) {
      this.$store.commit(BROKERDATA_TYPES.MUTATIONS.PUT_BROKER_PAGE_TABLE_SIZE, value);
    }
  }
}
</script>

<style>

</style>