<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="title" 
      :subtitle="subtitle" 
      :actions="headerActions" 
      @action-DISCARD="discardChanges()"
      @action-COPY-ADDRESS="copyAddress"
      @action-CONVERT="convertPersonToCustomer()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { PageHeaderSimpleAction, PageHeaderLoadingAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {
    title: {
      type: String,
      default: 'Person',
    },
    subtitle: {
      type: String,
    },
  },
  data() {
    return {
      copying: false,
      converting: false,
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isNewPerson: CUSTOMERDATA_TYPES.GETTERS.IS_NEW_PERSON,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    hasDataEdited() {
      return Object.keys(this.customerDataEdited).length > 0;
    },
    headerActions() {
      const { hasDataEdited, isNewPerson, copying, converting, isMainPerson, isEditable, isBrokerOrByPass, } = this;
      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataEdited || isNewPerson),
        PageHeaderLoadingAction('COPY-ADDRESS', 'Adresse kopieren', () => copying)
          .withVisible(() => !isMainPerson && isEditable),
        PageHeaderLoadingAction('CONVERT', 'Person zu Kunden umwandeln', () => converting)
          .withVisible(() => isBrokerOrByPass && !isMainPerson && isEditable && !isNewPerson),
      ];
    },
  },
  methods: {
    discardChanges() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
    },
    async copyAddress() {
      this.navigateToAdresse(async () => {
        this.copying = true;
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_ADDRESS);
        this.copying = false;
      });
    },
    navigateToAdresse(onComplete) {
      const personIdParam = this.$route?.params?.personId;
      const currentSubstep = this.$route?.query?.substep;
      if(currentSubstep !== 'adresse') {
        this.$router.push(`/persoenlichedaten/person-data/${personIdParam}/stammdaten?substep=adresse`, () => setTimeout(onComplete));
      } else {
        onComplete();
      }
    },
    async convertPersonToCustomer() {
      try {
        await this.$confirmModal({
          title: 'Person in Kunden umwandeln',
          message: 'Wollen Sie wirklich diese Person in einen Kunden umwandeln? Diese Aktion lässt sich nicht mehr rückgängig machen!',
          labelButtonConfirm: 'Ja',
          labelButtonCancel: 'Nein',
        });

        this.converting = true;

        // save changes
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);

        // convert
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.CONVERT_PERSON_TO_CUSTOMER, {
          personId: this.personId,
        });
      } catch (e) {
        // empty block
      } finally {
        this.converting = false;
      }
    },
  },
}
</script>
