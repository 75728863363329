var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Data Control Zuordnungen",
          actions: _vm.headerActions
        },
        on: { "action-IMPORT": _vm.importData }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-3 mt-4" },
            [
              _c("BaseButton", { on: { click: _vm.neueZuordnung } }, [
                _vm._v("Neues Produkt DC")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("FormLabel", { attrs: { label: "Sparte MSC" } }),
              _c("InputField", {
                on: {
                  input: function($event) {
                    return _vm.setFilterParam("filterSparte", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("FormLabel", { attrs: { label: "Gesellschaft DC" } }),
              _c("InputField", {
                on: {
                  input: function($event) {
                    return _vm.setFilterParam("filterGesell", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-3 mt-4" },
            [
              _c("BaseButton", { on: { click: _vm.filter } }, [
                _vm._v("Suchen")
              ])
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm.rowCount == 0
            ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            : _vm.rowCount
            ? [
                _c("Table", {
                  attrs: {
                    tableId: "99aaf2c1-69y9-42f3-9618-978k8ea2f459",
                    title: _vm.TABLE_TITLE,
                    headers: _vm.headers,
                    rows: _vm.rows,
                    exportConfig: _vm.exportConfig,
                    rowsPerPage: 25,
                    rowId: "id"
                  },
                  on: {
                    "click-productDC": _vm.editZuordnung,
                    "action-DELETE": _vm.deleteZuordnung
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "importModal",
          attrs: {
            modalTitle: "Data Control Zuordnungsliste einlesen",
            showConfirmButton: true,
            labelButtonConfirm: "Importieren",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.saveImportedData }
        },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              values: [
                {
                  label: "Zuordnungsliste als csv-Datei(Textdatei)",
                  value: ".csv"
                },
                {
                  label: "Zuordnungsliste als xlsx-Datei(Excel)",
                  value: ".xlsx"
                }
              ]
            },
            on: {
              input: function($event) {
                return _vm.setFileType($event)
              }
            },
            model: {
              value: _vm.fileType,
              callback: function($$v) {
                _vm.fileType = $$v
              },
              expression: "fileType"
            }
          }),
          _c("span", [_vm._v(_vm._s(_vm.filename))]),
          _c(
            "BaseFileSelect",
            {
              staticClass: "ml-3",
              attrs: {
                accept: _vm.acceptedFileType,
                multiple: false,
                isSecondary: ""
              },
              on: { files: _vm.importFile }
            },
            [_vm._v(" Durchsuchen... ")]
          )
        ],
        1
      ),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhInfo", {
                  staticStyle: { color: "var(--color-success)" }
                }),
                _vm._v(" " + _vm._s(_vm.infoText) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "errorModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhWarning", {
                  staticStyle: { color: "var(--color-danger)" }
                }),
                _vm._v(
                  " Es konnte keine Zeilen eingelesen werden, da die Zeilen schon vorhanden sind oder die " +
                    _vm._s(_vm.fileType) +
                    "-Datei konnte nicht gelesen werden! "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }