import store from '@/store';
import router from '@/router';
import CORE_TYPES from "@/store/core/types";
import VueRouter from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export const BACK_BUTTON_PARAM = 'backAction=true';
export const BACK_ACTION_PATTERN = new RegExp(`(\\?|&)*${BACK_BUTTON_PARAM}`, 'gi');

/**
 * Lock the $addBreadcrumb mixn method
 */
export function lockAddBreadcrumb() {
  store.commit(CORE_TYPES.MUTATIONS.LOCK_ADD_BREADCRUMB, true);
}

/**
 * Unlock the $addBreadcrumb mixin method
 */
export function unlockAddBreadcrumb() {
  store.commit(CORE_TYPES.MUTATIONS.LOCK_ADD_BREADCRUMB, false);
}

/**
 * Reset current breadcrumb stack
 */
export function resetBreadcrumb() {
  const { currentRoute, } = router;
  const payload = { 
    resetPreviousState: true,
  };
  store.dispatch(CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE, payload);
  trackBreadcrumbToCurrentRouteIfNeeded(currentRoute);
}

/**
 * Navigate to a new page and reset breadcrumb stack
 * 
 * @param {import('node_modules/vue-router/types/router').RawLocation} path 
 * @param {Object} opts
 * @param {Boolean} opts.ignoreReset - ignores breadcrumb reset when a user navigates to a path
 * @param {Boolean} opts.force - forces open a route even if it already on it
 * @param {Boolean} opts.replace - replaces current path on history
 * }
 */
export async function navigateResettingBreadcrumb(route, { ignoreReset=false, force=false, replace=false } = {}) {
  const { currentRoute, } = router;
  const targetRoute = router.matcher.match(route);

  if(currentRoute?.path === targetRoute?.path && !force) return;

  if (!ignoreReset) {
    window.dispatchEvent(new Event('navigate:beforeResetBreadcrumb'));
  }

  lockAddBreadcrumb();
  if (force) {
    try {
      const method = replace ? 'replace' : 'push';
      await router[method]('/noop');
    } catch(e) {
      // empty block
    }
  }

  const method = force || replace ? 'replace' : 'push';
  await router[method](route)
    .catch(() => {})
    .finally(() => {
      if (!ignoreReset) {
        resetBreadcrumb();
      }
      unlockAddBreadcrumb();
    });
}

/**
 * Track the current breadcrumb stack to the route path
 * 
 * @param {Route} to 
 */
export function trackBreadcrumbToCurrentRouteIfNeeded(to) {
  if(!to?.query?.backAction) {
    store.dispatch(CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE_TRACK, { 
      currentRouteLink: to?.path,
    });
  }

}

/**
 * Recovery the breadcrumb stack to the route path
 * 
 * @param {Route} to 
 */
function recoveryBreadcrumbStateIfNeeded(to) {
  if(to?.query?.backAction) {
    const currentRouteLink = to?.path.replace(BACK_ACTION_PATTERN, '');
    const backToPreviousPageTrack = store.getters[CORE_TYPES.GETTERS.GET_BACK_TO_PREVIOUS_PAGE_TRACK][currentRouteLink] || [];
    store.commit(CORE_TYPES.MUTATIONS.REPLACE_BACK_TO_PREVIOUS_PAGE, [...backToPreviousPageTrack]);
  }
}

/**
 * Resolve the track and recovery for the current route while navigating
 * 
 * @param {Route} to
 * @param {Route} from
 */
export function breadcrumbRouterResolver(to, from) {
  trackBreadcrumbToCurrentRouteIfNeeded(to);
  recoveryBreadcrumbStateIfNeeded(to);
}

/**
 * Remove the query parameter 'backAction' from the query object
 * 
 * @param {Dictionary} query 
 * @returns 
 */
export function removeBackActionFromQuery(query) {
  if (query) {
    delete query.backAction
  }

  return query
}

function normalizeRoute(route) {
  if (!route) return null;

  if (typeof route === 'string') {
    return router.resolve(route).route;
  }

  return route;
}

export function isSamePath(route1, route2) {
  if (!route1 || !route2) {
    return false;
  }

  const route1Normalized = normalizeRoute(route1);
  const route2Normalized = normalizeRoute(route2);
  if (!route1Normalized?.path || !route2Normalized?.path) {
    return false;
  }

  const route1WithBackAction = router.resolve({
    path: route1Normalized.path,
    query: {
      ...(route1Normalized.query || {}),
      backAction: true,
    },
  }).route;
  const route2WithBackAction = router.resolve({
    path: route2Normalized.path,
    query: {
      ...(route2Normalized.query || {}),
      backAction: true,
    },
  }).route;

  return route1Normalized.fullPath === route2Normalized.fullPath || route1WithBackAction.fullPath === route2WithBackAction.fullPath;
}

export function keepBreadcrumbNavigationIntegrity(to) {
  const previousPageData = store.getters[CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE];
  if (!previousPageData?.length) {
    return;
  }

  const currentPreviousPage = store.getters[CORE_TYPES.GETTERS.CURRENT_BACK_TO_PREVIOUS_PAGE];
  if (isSamePath(to, currentPreviousPage?.fullPath)) { // removes if the previous/back route is the same as the current route
    store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
  }
}

export function onNavigationFailure(failure) {
  if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
    store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
  }
}
