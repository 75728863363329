const eventBus = {
  events: {},

  $on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
    return () => {
      this.$off(event, callback);
    };
  },

  $off(event, callback) {
    if (!this.events[event]) {
      return;
    }
    if (!callback) {
      this.events[event] = null;
    } else {
      this.events[event] = this.events[event].filter(subscriber => subscriber !== callback);
    }
  },

  $emit(event, data) {
    if (this.events[event]) {
      this.events[event].forEach(callback => {
        callback(data);
      });
    }
  }
}

export const ANTRAG_SIGN_ACTION = 'ANTRAG-SIGN-ACTION';
export const ANTRAG_E_SIGNATUR = 'ANTRAG-E-SIGNATUR';
export const ANTRAG_CLOSE = 'ANTRAG_CLOSE';

export const CHAT_EDIT_BEARBEITER = 'CHAT_EDIT_BEARBEITER';
export const CHAT_CHANGE_STATUS = 'CHAT_CHANGE_STATUS';
export const CHAT_CHANGE_THEMA = 'CHAT_CHANGE_THEMA';
export const CHAT_CLOSE = 'CHAT_CLOSE';
export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';
export const CHAT_ADD_DOCUMENT = 'CHAT_ADD_DOCUMENT';
export const CHAT_SAVE_BEWERTUNG = 'CHAT_SAVE_BEWERTUNG';

// begin: Legitimationsurkunde actions
export const GESPEICHERTE_AUSWEISFOTOS_LOESCHEN = 'GESPEICHERTE_AUSWEISFOTOS_LOESCHEN';
export const SAVE_LEGITIMATION = 'SAVE_LEGITIMATION';

// end: Legitimationsurkunde actions

// begin: VermittlerDaten actions
export const TERMINE_NEUE_AUFGABE = 'TERMINE_NEUE_AUFGABE';

// end: VermittlerDaten actions

// begin: FondsVergleich actions
export const FONDSVERGLEICH_WEITER = 'FONDSVERGLEICH_WEITER';
export const FONDSVERGLEICH_RESET_VERGLEICH = 'FONDSVERGLEICH_RESET_VERGLEICH';
export const FONDSVERGLEICH_GET_VERGLEICH = 'FONDSVERGLEICH_GET_VERGLEICH';
export const FONDSVERGLEICH_DO_ACTION = 'FONDSVERGLEICH_DO_ACTION';
// end: FondsVergleich actions

// begin: Anpassungen actions
export const ANPASSUNGEN_NEUE_ANPASSUNG = 'ANPASSUNGEN_NEUE_ANPASSUNG';
// end: Anpassungen actions

// Email
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_BRIEF = 'SEND_BRIEF';

// Configuration
export const RECALCULATE_MENU_USER_LEVEL = 'RECALCULATE_MENU_USER_LEVEL';

// Refresh 
export const REFRESH_INSURANCE_TABLE = 'REFRESH_INSURANCE_TABLE';

/**
 * This object may be used as a global event bus.
 * 
 * Usage example
 *   - EVENT_BUS.$on('eventName', () => console.log('code here')); // An event listener can be added in any component
 *   - EVENT_BUS.$emit('eventName'); // An emit can be fired from any component
 *  
 */
export default eventBus;

