export const MUTATION_PREFIX = 'VERSICHERUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERSICHERUNG_ACTIONS_';
export const GETTERS_PREFIX = 'VERSICHERUNG_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_INSURANCES_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_INSURANCES_SUCCESS',
    RETRIEVE_INSURANCES_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_INSURANCES_FAILTURE',
    RETRIEVE_INSURANCE_DETAILS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_INSURANCE_DETAILS_SUCCESS',
    RETRIEVE_INSURANCE_DETAILS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_INSURANCE_DETAILS_FAILTURE',
    RETRIEVE_INSURANCE_GESELLSCHAFT: MUTATION_PREFIX + 'RETRIEVE_INSURANCE_GESELLSCHAFT',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    FILTERED_CATEGORY: MUTATION_PREFIX + 'FILTERED_CATEGORY',
    RETRIEVE_CATEGORIES_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_CATEGORIES_SUCCESS',
    RETRIEVE_ALL_CATEGORIES_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_ALL_CATEGORIES_SUCCESS',
    RETRIEVE_CATEGORIES_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_CATEGORIES_FAILTURE',
    EDITING_DETAILS_START: MUTATION_PREFIX + 'EDITING_DETAILS_START',
    EDITING_DETAILS_STOP: MUTATION_PREFIX + 'EDITING_DETAILS_STOP',
    FILTER_CATEGORY_STOP: MUTATION_PREFIX + 'FILTER_CATEGORY_STOP',
    INSURANCE_DETAILS_COMBINED_SUCCESS: MUTATION_PREFIX + 'INSURANCE_DETAILS_COMBINED_SUCCESS',
    CLEAR_INSURANCE_DETAILS: MUTATION_PREFIX + 'CLEAR_INSURANCE_DETAILS',
    GET_SPARTEN_LIST_SUCCESS: MUTATION_PREFIX + 'GET_SPARTEN_LIST_SUCCESS',
    GET_ZAHLRHYTHMUS_LIST_SUCCESS: MUTATION_PREFIX + 'GET_ZAHLRHYTHMUS_LIST_SUCCESS',
    GET_DEPOT_POSITIONS_SUCCESS: MUTATION_PREFIX + 'GET_DEPOT_POSITIONS_SUCCESS',
    CLEAR_DEPOT_POSITIONS: MUTATION_PREFIX + 'CLEAR_DEPOT_POSITIONS',
    GET_INSURANCE_FILTERS_DATA_SUCCESS: MUTATION_PREFIX + 'GET_INSURANCE_FILTERS_DATA_SUCCESS',
    GET_INSURANCES_FILTERED_SUCCESS: MUTATION_PREFIX + 'GET_INSURANCES_FILTERED_SUCCESS',
    CLEAR_INSURANCES_FILTERED: MUTATION_PREFIX + 'CLEAR_INSURANCES_FILTERED',
    DELETE_SCHADENSMELDUNG_SUCCESS: MUTATION_PREFIX + 'DELETE_SCHADENSMELDUNG_SUCCESS',
    DELETE_SCHADENSMELDUNG_FAILURE: MUTATION_PREFIX + 'DELETE_SCHADENSMELDUNG_FAILURE',
    DELETE_VERTRAG: MUTATION_PREFIX + 'DELETE_VERTRAG',
    SET_TEMP_FILES: MUTATION_PREFIX + 'SET_TEMP_FILES',
    CLEAR_TEMP_FILES: MUTATION_PREFIX + 'CLEAR_TEMP_FILES',
    INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION_SUCCESS: MUTATION_PREFIX + 'INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION_SUCCESS',
    CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION: MUTATION_PREFIX + 'CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION',
    INSURANCE_AUSWAHL_VERTRAGSART_SELECTED: MUTATION_PREFIX + 'INSURANCE_AUSWAHL_VERTRAGSART_SELECTED',
    INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES_SUCCESS: MUTATION_PREFIX + 'INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES_SUCCESS',
    APPEND_VERSICHERUNGEN_DETAILS_EDITED: MUTATION_PREFIX + 'APPEND_VERSICHERUNGEN_DETAILS_EDITED',
    INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION_SUCCESS: MUTATION_PREFIX + 'INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION_SUCCESS',
    UPDATE_VERSICHERUNGEN_FORM_VALIDATION: MUTATION_PREFIX + 'UPDATE_VERSICHERUNGEN_FORM_VALIDATION',
    CLEAR_VERSICHERUNG_FORM_VALIDATION: MUTATION_PREFIX + 'CLEAR_VERSICHERUNG_FORM_VALIDATION',
    GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS: MUTATION_PREFIX + 'GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS',
    SET_PRUEFSTATUS_CHANGE: MUTATION_PREFIX + 'SET_PRUEFSTATUS_CHANGE',
    ADD_ATTACHMENT_SUCCESS: MUTATION_PREFIX + 'ADD_ATTACHMENT_SUCCESS',
    UPDATE_VERTRAG_SUCCESS: MUTATION_PREFIX + 'UPDATE_VERTRAG_SUCCESS',
    UPDATE_VERTRAG_SPARTEN_SUCCESS: MUTATION_PREFIX + 'UPDATE_VERTRAG_SPARTEN_SUCCESS',
    UPDATE_STORE_SPARTEN_DATA: MUTATION_PREFIX + 'UPDATE_STORE_SPARTEN_DATA',
    UPDATE_STORE_SELECTED_SPARTEN: MUTATION_PREFIX + 'UPDATE_STORE_SELECTED_SPARTEN',
    INSURANCE_ANMERKUNG_GET_SUCCESS: MUTATION_PREFIX + 'INSURANCE_ANMERKUNG_GET_SUCCESS',
    INSURANCE_ANMERKUNG_SAVE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_ANMERKUNG_UDPATE_SUCCESS',
    INSURANCE_ANMERKUNG_DELETE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_ANMERKUNG_DELETE_SUCCESS',
    SET_CAN_EDIT_ANMERKUNG: MUTATION_PREFIX + 'SET_CAN_EDIT_ANMERKUNG',
    RETRIEVE_INSURANCES_BY_CUSTOMER_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_INSURANCES_BY_CUSTOMER_SUCCESS',
    INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES_SUCCESS: MUTATION_PREFIX + 'INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES_SUCCESS',
    FIND_ALL_INSURANCE_COURTAGE_SUCCESS: MUTATION_PREFIX + 'FIND_ALL_INSURANCE_COURTAGE_SUCCESS',
    INSURANCE_COURTAGE_DELETE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_COURTAGE_DELETE_SUCCESS',
    INSURANCE_COURTAGE_SAVE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_COURTAGE_SAVE_SUCCESS',
    INSURANCE_COURTAGE_UPDATE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_COURTAGE_UPDATE_SUCCESS',
    INSURANCE_COURTAGE_CONFIG_SUCCESS: MUTATION_PREFIX + 'INSURANCE_COURTAGE_CONFIG_SUCCESS',
    FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS: MUTATION_PREFIX + 'FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS',
    INSURANCE_BUCHUNG_DELETE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_BUCHUNG_DELETE_SUCCESS',
    COPY_INSURANCE_SUCCESS: MUTATION_PREFIX + 'COPY_INSURANCE_SUCCESS',
    SAVE_RUECKKAUFSWERTE_SUCCESS: MUTATION_PREFIX + 'SAVE_RUECKKAUFSWERTE_SUCCESS',
    UPDATE_RUECKKAUFSWERTE_SUCCESS: MUTATION_PREFIX + 'UPDATE_RUECKKAUFSWERTE_SUCCESS',
    DELETE_RUECKKAUFSWERTE_SUCCESS: MUTATION_PREFIX + 'DELETE_RUECKKAUFSWERTE_SUCCESS',
    DELETE_EINGEBUNDENE_WERTPAPIERE_SUCCESS: MUTATION_PREFIX + 'DELETE_EINGEBUNDENE_WERTPAPIERE_SUCCESS',
    RETRIEVE_VERTRAGSPERSONENDATA_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERTRAGSPERSONENDATA_SUCCESS',
    SAVE_VERTRAG_PERSONEN_DATA_SUCCESS: MUTATION_PREFIX + 'SAVE_VERTRAG_PERSONEN_DATA_SUCCESS',
    RETRIEVE_VERTRAGPERSONEN_LIST_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERTRAGPERSONEN_LIST_SUCCESS',
    DELETE_VERTRAG_PERSON_SUCCESS: MUTATION_PREFIX + 'DELETE_VERTRAG_PERSON_SUCCESS',
    GET_FAHRZEUGDATEN_LIST_SUCCESS: MUTATION_PREFIX + 'GET_FAHRZEUGDATEN_LIST_SUCCESS',
    SET_VERTRAGS_VERSCHIEBUNGEN_DATA: MUTATION_PREFIX + 'SET_VERTRAGS_VERSCHIEBUNGEN_DATA',
    UPDATE_FAHRZEUGDATEN_SUCCESS: MUTATION_PREFIX + 'UPDATE_FAHRZEUGDATEN_SUCCESS',
    CREATE_FAHRZEUGDATEN_SUCCESS: MUTATION_PREFIX + 'CREATE_FAHRZEUGDATEN_SUCCESS',
    NODE_ID: MUTATION_PREFIX + 'NODE_ID',
    GET_SPARTEN_SUCCESS: MUTATION_PREFIX + 'GET_SPARTEN_SUCCESS',
    SEARCH_CUSTOMER_SUCCESS: MUTATION_PREFIX + 'SEARCH_CUSTOMER_SUCCESS',
    RETRIEVE_VERSICHERUNGSSUMMEN_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERSICHERUNGSSUMMEN_SUCCESS',
    SET_EXTERNAL_URL: MUTATION_PREFIX + 'SET_EXTERNAL_URL',
    UPDATE_PRUEFSTATUS_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS: MUTATION_PREFIX + 'UPDATE_PRUEFSTATUS_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS',
    GET_MAKLER_VOLLMACHT_SUCCESS: MUTATION_PREFIX + 'GET_MAKLER_VOLLMACHT_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_INSURANCES: ACTIONS_PREFIX + 'RETRIEVE_INSURANCES',
    RETRIEVE_INSURANCE_DETAILS: ACTIONS_PREFIX + 'RETRIEVE_INSURANCE_DETAILS',
    RETRIEVE_CATEGORIES: ACTIONS_PREFIX + 'RETRIEVE_CATEGORIES',
    RETRIEVE_ALL_CATEGORIES: ACTIONS_PREFIX + 'RETRIEVE_ALL_CATEGORIES',
    RETRIEVE_INSURANCE_GESELLSCHAFT: ACTIONS_PREFIX + 'RETRIEVE_INSURANCE_GESELLSCHAFT',
    GET_INSURANCE_DETAILS_COMBINED: ACTIONS_PREFIX + 'GET_INSURANCE_DETAILS_COMBINED',
    GET_SPARTEN_LIST: ACTIONS_PREFIX + 'GET_SPARTEN_LIST',
    GET_ZAHLRHYTHMUS_LIST: ACTIONS_PREFIX + 'GET_ZAHLRHYTHMUS_LIST',
    SAVE_VERTRAG: ACTIONS_PREFIX + 'SAVE_VERTRAG',
    UPDATE_VERTRAG : ACTIONS_PREFIX + 'UPDATE_VERTRAG',
    UPDATE_VERTRAG_SPARTEN : ACTIONS_PREFIX + 'UPDATE_VERTRAG_SPARTEN',
    DELETE_VERTRAG : ACTIONS_PREFIX + 'DELETE_VERTRAG',
    CAN_DELETE_VERTRAG : ACTIONS_PREFIX + 'CAN_DELETE_VERTRAG',
    GET_DEPOT_POSITIONS: ACTIONS_PREFIX + 'GET_DEPOT_POSITIONS',
    GET_INSURANCE_UPLOAD_INFO: ACTIONS_PREFIX + 'GET_INSURANCE_UPLOAD_INFO',
    INSURANCE_UPLOAD: ACTIONS_PREFIX + 'INSURANCE_UPLOAD',
    GET_INSURANCE_FILTERS_DATA: ACTIONS_PREFIX + 'GET_INSURANCE_FILTERS_DATA',
    GET_INSURANCES_FILTERED: ACTIONS_PREFIX + 'GET_INSURANCES_FILTERED',
    DELETE_SCHADENSMELDUNG: ACTIONS_PREFIX + 'DELETE_SCHADENSMELDUNG',
    INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION',
    INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES',
    INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION',
    GET_ANTRAG_CHECK_VERSICHERUNG_LIST: ACTIONS_PREFIX + 'GET_ANTRAG_CHECK_VERSICHERUNG_LIST',
    GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS: ACTIONS_PREFIX + 'GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS',
    GET_ANTRAG_CHECK_VERSICHERUNG_DOCUMENT: ACTIONS_PREFIX + 'GET_ANTRAG_CHECK_VERSICHERUNG_DOCUMENT',
    CAN_CHANGE_PRUEFSTATUS: ACTIONS_PREFIX + 'CAN_CHANGE_PRUEFSTATUS',
    SAVE_PRUEFSTATUS: ACTIONS_PREFIX + 'SAVE_PRUEFSTATUS',
    ADD_ATTACHMENT: ACTIONS_PREFIX + 'ADD_ATTACHMENT',
    VERSICHERUNG_VEREINIGEN: ACTIONS_PREFIX + 'VERSICHERUNG_VEREINIGEN',
    INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_MESSAGE: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_MESSAGE',
    INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION',
    INSURANCE_ANMERKUNG_GET: ACTIONS_PREFIX + 'INSURANCE_ANMERKUNG_GET',
    INSURANCE_ANMERKUNG_SAVE: ACTIONS_PREFIX + 'INSURANCE_ANMERKUNG_SAVE',
    INSURANCE_ANMERKUNG_DELETE: ACTIONS_PREFIX + 'INSURANCE_ANMERKUNG_DELETE',
    RETRIEVE_INSURANCES_BY_CUSTOMER: ACTIONS_PREFIX + 'RETRIEVE_INSURANCES_BY_CUSTOMER',
    INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES: ACTIONS_PREFIX + 'INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES',
    FIND_ALL_INSURANCE_COURTAGE: ACTIONS_PREFIX + 'FIND_ALL_INSURANCE_COURTAGE',
    INSURANCE_COURTAGE_DELETE: ACTIONS_PREFIX + 'INSURANCE_COURTAGE_DELETE',
    INSURANCE_COURTAGE_SAVE: ACTIONS_PREFIX + 'INSURANCE_COURTAGE_SAVE',
    INSURANCE_COURTAGE_UPDATE: ACTIONS_PREFIX + 'INSURANCE_COURTAGE_UPDATE',
    INSURANCE_COURTAGE_CONFIG: ACTIONS_PREFIX + 'INSURANCE_COURTAGE_CONFIG',
    FIND_ALL_INSURANCE_ABRECHNUNGEN: ACTIONS_PREFIX + 'FIND_ALL_INSURANCE_ABRECHNUNGEN',
    INSURANCE_BUCHUNG_DELETE: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_DELETE',
    INSURANCE_BUCHUNG_COPY: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_COPY',
    INSURANCE_BUCHUNG_STORNO: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_STORNO',
    COPY_INSURANCE: ACTIONS_PREFIX + 'COPY_INSURANCE',
    SAVE_RUECKKAUFSWERTE: ACTIONS_PREFIX + 'SAVE_RUECKKAUFSWERTE',
    UPDATE_RUECKKAUFSWERTE: ACTIONS_PREFIX + 'UPDATE_RUECKKAUFSWERTE',
    DELETE_RUECKKAUFSWERTE: ACTIONS_PREFIX + 'DELETE_RUECKKAUFSWERTE',
    DELETE_EINGEBUNDENE_WERTPAPIERE: ACTIONS_PREFIX + 'DELETE_EINGEBUNDENE_WERTPAPIERE',
    RETRIEVE_VERTRAGSPERSONENDATA: ACTIONS_PREFIX + 'RETRIEVE_VERTRAGSPERSONENDATA',
    SAVE_VERTRAG_PERSONEN_DATA: ACTIONS_PREFIX + 'SAVE_VERTRAG_PERSONEN_DATA',
    RETRIEVE_VERTRAGPERSONEN_LIST: ACTIONS_PREFIX + 'RETRIEVE_VERTRAGPERSONEN_LIST',
    DELETE_VERTRAG_PERSON: ACTIONS_PREFIX + 'DELETE_VERTRAG_PERSON',
    GET_FAHRZEUGDATEN_LIST: ACTIONS_PREFIX + 'GET_FAHRZEUGDATEN_LIST',
    EXPORT_INSURANCES: ACTIONS_PREFIX + 'EXPORT_INSURANCES',
    KUNDENNR_AENDERN: ACTIONS_PREFIX + 'KUNDENNR_AENDERN',
    GET_SPARTEN: ACTIONS_PREFIX + 'GET_SPARTEN',
    ADD_SPARTE: ACTIONS_PREFIX + 'ADD_SPARTE',
    SEARCH_CUSTOMER: ACTIONS_PREFIX + 'SEARCH_CUSTOMER',
    DELETE_VERSICHERUNG_SUMME: ACTIONS_PREFIX + 'DELETE_VERSICHERUNG_SUMME',
    SAVE_OR_UPDATE_VERSICHERUNG_SUMME: ACTIONS_PREFIX + 'SAVE_OR_UPDATE_VERSICHERUNG_SUMME',
    RETRIEVE_VERSICHERUNGSSUMMEN: ACTIONS_PREFIX + 'RETRIEVE_VERSICHERUNGSSUMMEN',
    RESET_EXTERNAL_URL: ACTIONS_PREFIX + 'RESET_EXTERNAL_URL',
    VALIDATE_CUSTOMER: ACTIONS_PREFIX + 'VALIDATE_CUSTOMER',
    GET_ANTRAG_CHECK_PROTOKOLL: ACTIONS_PREFIX + 'GET_ANTRAG_CHECK_PROTOKOLL',
    GET_MAKLER_VOLLMACHT: ACTIONS_PREFIX + 'GET_MAKLER_VOLLMACHT',
  },
  GETTERS: {
    INSURANCE_LIST: GETTERS_PREFIX + 'INSURANCE_LIST',
    INSURANCE_DETAILS: GETTERS_PREFIX + 'INSURANCE_DETAILS',
    FILTERED_CATEGORY: GETTERS_PREFIX + 'FILTERED_CATEGORY',
    CATEGORIES_LIST: GETTERS_PREFIX + 'CATEGORIES_LIST',
    ALL_CATEGORIES_LIST: GETTERS_PREFIX + 'ALL_CATEGORIES_LIST',
    EDITING_DETAILS: GETTERS_PREFIX + 'EDITING_DETAILS',
    INSURANCE_DETAILS_COMBINED: GETTERS_PREFIX + 'INSURANCE_DETAILS_COMBINED',
    SPARTEN_LIST: GETTERS_PREFIX + 'SPARTEN_LIST',
    GESELLSCHAFT_LIST: GETTERS_PREFIX + 'GESELLSCHAFT_LIST',
    ZAHLRHYTHMUS_LIST: GETTERS_PREFIX + 'ZAHLRHYTHMUS_LIST',
    LAST_DELETED_SCHADENSMELDUNG: GETTERS_PREFIX + 'LAST_DELETED_SCHADENSMELDUNG',
    DEPOT_POSITIONS: GETTERS_PREFIX + 'DEPOT_POSITIONS',
    INSURANCE_FILTERS_DATA: GETTERS_PREFIX + 'INSURANCE_FILTERS_DATA',
    INSURANCES_FILTERED: GETTERS_PREFIX + 'INSURANCES_FILTERED',
    TEMP_FILES: GETTERS_PREFIX + 'TEMP_FILES',
    INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION: GETTERS_PREFIX + 'INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION',
    INSURANCE_AUSWAHL_VERTRAGSART_SELECTED: GETTERS_PREFIX + 'INSURANCE_AUSWAHL_VERTRAGSART_SELECTED',
    INSURANCE_VERTRAGSDATEN_GESELLSCHAFT_MAKLERGESELLNR: GETTERS_PREFIX + 'INSURANCE_VERTRAGSDATEN_GESELLSCHAFT_MAKLERGESELLNR',
    RETRIEVE_VERSICHERUNG_DETAILS_EDITED: GETTERS_PREFIX + 'RETRIEVE_VERSICHERUNG_DETAILS_EDITED',
    VERSICHERUNG_FORM_VALIDATION: GETTERS_PREFIX + 'VERSICHERUNG_FORM_VALIDATION',
    ANTRAG_CHECK_VERS_DATA: GETTERS_PREFIX + 'ANTRAG_CHECK_VERS_DATA',
    PRUEFSTATUS_CHANGE: GETTERS_PREFIX + 'PRUEFSTATUS:_CHANGE',
    INSURANCE_ANMERKUNG_GET: GETTERS_PREFIX + 'INSURANCE_ANMERKUNG_GET',
    INSURANCES_BY_CUSTOMER: GETTERS_PREFIX + 'INSURANCES_BY_CUSTOMER',
    INSURANCE_COURTAGE: GETTERS_PREFIX + 'INSURANCE_COURTAGE',
    INSURANCE_COURTAGE_CONFIG: GETTERS_PREFIX + 'INSURANCE_COURTAGE_CONFIG',
    INSURANCE_ABRECHNUNGEN: GETTERS_PREFIX + 'INSURANCE_ABRECHNUNGEN',
    CAN_EDIT_ANMERKUNG: GETTERS_PREFIX + 'CAN_EDIT_ANMERKUNG',
    INSURANCE_COPY: GETTERS_PREFIX + 'INSURANCE_COPY',
    RUCKKAUFSWERTE_LIST: GETTERS_PREFIX + 'RUCKKAUFSWERTE_LIST',
    VERTRAGSPERSONENDATA: GETTERS_PREFIX + 'VERTRAGSPERSONENDATA',
    VERTRAGPERSONEN_LIST: GETTERS_PREFIX + 'VERTRAGPERSONEN_LIST',
    VERTRAGS_VERSCHIEBUNGEN_DATA: GETTERS_PREFIX + 'VERTRAGS_VERSCHIEBUNGEN_DATA',
    FAHRZEUGDATEN_LIST: GETTERS_PREFIX + 'FAHRZEUGDATEN_LIST',
    NODE_ID: GETTERS_PREFIX + 'NODE_ID',
    GET_SPARTEN: GETTERS_PREFIX + 'GET_SPARTEN',
    FOUND_CUSTOMER: GETTERS_PREFIX + 'FOUND_CUSTOMER',
    SELECTED_VERSICHERUNG_SUMME: GETTERS_PREFIX + 'SELECTED_VERSICHERUNG_SUMME',
    VERSICHERUNGSSUMMEN: GETTERS_PREFIX + 'SELECTED_VERSICHERUNG_SUMME',
    EXTERNAL_URL: GETTERS_PREFIX + 'EXTERNAL_URL',
    MAKLER_VOLLMACHT: GETTERS_PREFIX + 'MAKLER_VOLLMACHT',
  }
}