<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
          >
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>

        <div class="col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3">
          <BaseButton
            @click="openFondsfinder('SPARPLAN')"
            isBlock
            :disabled="disabled"
          >
            {{config.buttonText}}
          </BaseButton>
        </div>
      </div>
      <div>
        <template v-for="(pos, index) in positions">
          <FormMorgenfundSparplanPositionCards
            :key="index"
            :position="pos"
            :antragId="antragId"
            :disabled="disabled"
            :config="config"
            :categoryId="categoryId"
          />
        </template>
      </div>
    </div>
    <div
      v-if="config.mitSummenbildung && this.positions && this.positions.length"
    >
      <Summenbildung v-if="categoryId == 'SPARPLAN'"
        :antragId="antragId"
        :summeSpar="summe"
        :mitSparplan="true"
        :ohneEinmal="true"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import FormMorgenfundSparplanPositionCards from "@/components/core/forms/antraege/wertpapierAuswahl/FormMorgenfundSparplanPositionCards.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import Summenbildung from "@/components/antrag/Summenbildung.vue";
import { PhBank } from "phosphor-vue";
export default {
  mixins: [antragNavigationMixin],
  props: {
    title: {},
    antragId: {},
    antragTyp: {},
    categoryId: {},
    config: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      summe: 0,
    };
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      let positions =
        (this.positionsAll &&
          this.antragId &&
          this.positionsAll[this.antragId] &&
          this.positionsAll[this.antragId][this.categoryId]) ||
        [];
      
      if (positions) {
        positions = positions.map((pos, index) =>
          Object.assign(pos, {
            fondsname: this.getFondsName(pos),
            sri: this.getSRI(pos),
            art: this.categoryId,
          })
        );
      }

      return positions;
    },
  },
  watch: {
    positions: {
      handler() {
        let sumSparplan = 0;
        if (this.positions && this.positions.length) {
          this.positions.forEach((r) => {
            if (r.sparplanBetrag) {
              sumSparplan += +r.sparplanBetrag;
            }
          });
        }
        this.summe = sumSparplan;
      },
      immediate: true,
    },
  },
  components: {
    Summenbildung,
    BaseButton,
    FormMorgenfundSparplanPositionCards,
    PhBank,
  },
  methods: {
    openFondsfinder(posTyp) {
      this.goToFormsFinder(
        posTyp,
        this.config,
        this.suppressFilterLagerstelle
      );
    },
    getFondsName(pos) {
      return (
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getSRI(pos) {
      return (
        this.positionInfo &&
        this.positionInfo[pos.isin] &&
        this.positionInfo[pos.isin].sri
      );
    },
  },
};
</script>
