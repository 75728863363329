var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.title,
          subtitle: _vm.subtitle,
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-PDF": function($event) {
            return _vm.navigateTo("/persoenlichedaten/pdf-kundendaten")
          },
          "action-VCARD": function($event) {
            return _vm.navigateTo("/persoenlichedaten/vcard-kundendaten")
          },
          "action-COPY": function($event) {
            return _vm.openCopyCustomerModal()
          },
          "action-COPY-GROUP": function($event) {
            return _vm.openCopyCustomerGroupModal()
          },
          "action-BRIEF": function($event) {
            return _vm.navigateTo("/communication/mailcomposer-brief")
          },
          "action-EMAIL": function($event) {
            return _vm.navigateTo("/communication/mailcomposer")
          },
          "action-TICKET": function($event) {
            return _vm.navigateTo("/communication/tickets/new-ticket")
          },
          "action-ACTIVITY": function($event) {
            return _vm.navigateTo("/communication/postfach/termine")
          }
        }
      }),
      _c("BaseModal", {
        ref: "copyCustomerModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Kunde kopieren")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomer()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "copyCustomerGroupModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Gruppenoberhaupt erzeugen")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe nochmal angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerGroupModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomerGroup()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }