var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Anlegerprofil",
          subtitle: _vm.getSubtitle,
          actions: _vm.headerActions,
          defaultMenu: _vm.personenOptionMenu
        },
        on: {
          "action-PDF": _vm.openPDFErzeugen,
          "action-SIGN": _vm.openPDFSignieren,
          "action-CONFIRM": _vm.confirmAnlegerprofil,
          "action-REFUSE": _vm.refuseButtonClicked,
          "action-ANLAGEZIELE_PLANEN": _vm.anlagezielePlanen,
          "action-COPY_FINANCE": _vm.copyFinance,
          "action-TOGGLE-E-SIGN": _vm.doSendToElectronicSignature
        }
      }),
      !_vm.isMobileNativeContext
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "font-bold mr-3" }, [
                _vm._v("Risikokennzahl: " + _vm._s(_vm.risikoKennzahl))
              ]),
              _c("div", { staticClass: "font-bold" }, [
                _vm._v("GSRI: " + _vm._s(_vm.gsri))
              ])
            ])
          ])
        : _vm._e(),
      _vm.stepper && _vm.isSmallOrMediumScreen
        ? _c("div", { staticClass: "row my-0" }, [
            _c("div", { staticClass: "d-lg-none col-12" }, [
              _c(
                "div",
                { staticClass: "antrag-step__container" },
                [
                  _c("Stepper3", {
                    ref: "pieStepper",
                    attrs: {
                      stepType: "step",
                      visible: true,
                      selectedStepKey: _vm.getStepKey,
                      stepperService: _vm.stepper
                    },
                    on: {
                      setStep: _vm.setStepByKey,
                      setSubstep: _vm.setSubstepByKey
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      !_vm.isSmallOrMediumScreen
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("AnlegerprofilSidetext", {
                  key: _vm.getStepKey,
                  attrs: {
                    hasFinanceSummary: _vm.hasFinanceSummary,
                    showExpanded: _vm.getStepKey === "aktionen"
                  },
                  on: {
                    goToWarning: function($event) {
                      return _vm.goToWarning($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.isSmallOrMediumScreen
        ? _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u(
              [
                _vm.stepper && _vm.getStepKey !== "aktionen"
                  ? {
                      key: "stepper",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _c("VerticalStepper", {
                                ref: "verticalStepper",
                                staticClass: "responsive-stepper",
                                attrs: {
                                  stepType: "step",
                                  visible: true,
                                  selectedStepKey: _vm.getStepKey,
                                  selectedSubstepKey: _vm.getSubstepKey,
                                  stepperService: _vm.stepper,
                                  showCollapse: true
                                },
                                on: {
                                  setStep: _vm.setStepByKey,
                                  setSubstep: _vm.setSubstepByKey,
                                  collapsed: function($event) {
                                    _vm.stepperCollapsed = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "box__container" }, [
                        _vm.getStepKey !== "aktionen"
                          ? _c(
                              "div",
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v(_vm._s(_vm.stepTitle))
                                ]),
                                _c("hr"),
                                _c("router-view"),
                                _c("hr", {
                                  staticClass: "hide-on-small-screen"
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "antrag-bottom-buttons-container" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 hide-on-small-screen mb-3"
                                },
                                [
                                  _vm.hasConfirmMoney
                                    ? _c("InputToggleSwitch", {
                                        attrs: {
                                          value: _vm.hasEnoughMoney,
                                          label:
                                            "Ich bestätige die nötigen finanziellen Mittel für die Anlage zu besitzen. *",
                                          inLineLabel: "",
                                          suppressValidationMessage: "",
                                          disabled: _vm.isDisabled
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateStore(
                                              "finance",
                                              "confirmHasMoney",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.hasPreviousStep
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 my-1 col-sm-auto hide-on-small-screen"
                                    },
                                    [
                                      _c(
                                        "BaseButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isSaving,
                                            isBlock: "",
                                            isSecondary: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.prevStep()
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [_vm._v("Zurück")]),
                                          _vm.showPreviousSpinner
                                            ? _c("AnimatedSpinner")
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.hasNextStep
                                ? _c(
                                    "div",
                                    { staticClass: "col-12 my-1 col-sm-auto" },
                                    [
                                      _c(
                                        "BaseButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isSaving,
                                            isBlock: "",
                                            isPrimary: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.nextStep()
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [_vm._v("Weiter")]),
                                          _vm.showNextSpinner
                                            ? _c("AnimatedSpinner")
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.getStepKey === "aktionen" || !_vm.hasNextStep
                                ? _c(
                                    "div",
                                    { staticClass: "col-12 my-1 col-sm-auto" },
                                    [
                                      _c("BaseButton", {
                                        attrs: { label: "Schließen" },
                                        on: { click: _vm.goToLastAction }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "warnings",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          on: {
            onConfirmButton: function($event) {
              return _vm.$refs.warnings.close()
            },
            onCloseButton: function($event) {
              return _vm.$refs.warnings.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Information ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "col mb-2" }, [
            _vm._v(
              "Fehlerhafte Anlegerprofile können nicht zur e-Signatur vorgelegt werden"
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "copySuccess",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Information ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "col mb-2" }, [
            _vm._v(
              "Finanzielle Verhältnisse wurden von persönlichen Daten erfolgreich kopiert."
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.editModalTitle,
            size: "lg",
            showCancelButton: false,
            actions: _vm.baseModalActions,
            showConfirmButton: false,
            autoCloseOnRouteNavigation: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.onCloseEditModal()
            },
            "action-WEITER": function($event) {
              return _vm.nextStep()
            },
            "action-ZURUCK": function($event) {
              return _vm.prevStep()
            },
            "action-REFUSE": _vm.refuseButtonClicked,
            "action-ANLAGEZIELE_PLANEN": _vm.anlagezielePlanen,
            "action-COPY_FINANCE": _vm.copyFinance
          }
        },
        [
          !_vm.loadingStep
            ? _c("div", { staticClass: "modal__container" }, [
                _vm.getStepKey !== "aktionen"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v(_vm._s(_vm.stepTitle))
                        ]),
                        _c("hr"),
                        _c("router-view")
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("AnlegerprofilSidetext", {
                          key: _vm.getStepKey,
                          attrs: {
                            hasFinanceSummary: _vm.hasFinanceSummary,
                            showExpanded: _vm.getStepKey === "aktionen"
                          },
                          on: {
                            goToWarning: function($event) {
                              return _vm.goToWarning($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mb-3" },
                    [
                      _vm.hasConfirmMoney
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              value: _vm.hasEnoughMoney,
                              label:
                                "Ich bestätige die nötigen finanziellen Mittel für die Anlage zu besitzen. *",
                              inLineLabel: "",
                              suppressValidationMessage: "",
                              disabled: _vm.isDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateStore(
                                  "finance",
                                  "confirmHasMoney",
                                  $event
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _c("GhostLoading", { attrs: { type: "table" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }