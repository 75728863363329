var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "ExPost Berechnung" }
      }),
      _vm.isFA
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Hinweise")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "font-strong" }, [
                            _vm._v("Serienbrief")
                          ]),
                          _c("div", [
                            _vm._v(
                              "Erstellt die Datei wie beim Serienbriefversand. Aktueller Unterschied: Extraseite Mailadresse."
                            )
                          ]),
                          _c("div", { staticClass: "font-strong mt-2" }, [
                            _vm._v("Detailierter Auszug")
                          ]),
                          _c("div", [
                            _vm._v(
                              "Teilt den Auszug auf die einzelnen Depots auf."
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1187658531
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title mb-4" }, [
          _vm._v("ExPost Berechnung")
        ]),
        _c("div", { staticClass: "box__container-wrap" }, [
          _c("div", { staticClass: "box__container-rows" }, [
            _c("div", { staticClass: "label-value--list" }, [
              _c("div", { staticClass: "label-value--item" }, [
                _c("div", { class: !_vm.isFA && "label-value--item-label" }, [
                  _vm._v("Berechnung für Jahr:")
                ]),
                _c(
                  "div",
                  { staticClass: "label-value--item-value font-strong" },
                  [
                    _vm._v(_vm._s(_vm.jahr) + " "),
                    _c("PhCheck", {
                      style: { opacity: 0 },
                      attrs: { size: 16 }
                    })
                  ],
                  1
                )
              ]),
              _vm.isInternLogin
                ? _c("div", [
                    _vm.isFA
                      ? _c(
                          "div",
                          [
                            _c("InputToggleSwitch", {
                              attrs: {
                                label: "Serienbrief",
                                inLineLabel: "",
                                suppressValidationMessage: ""
                              },
                              model: {
                                value: _vm.checkSerienbrief,
                                callback: function($$v) {
                                  _vm.checkSerienbrief = $$v
                                },
                                expression: "checkSerienbrief"
                              }
                            }),
                            _c("InputToggleSwitch", {
                              attrs: {
                                label: "detailierter Auszug",
                                inLineLabel: "",
                                suppressValidationMessage: ""
                              },
                              model: {
                                value: _vm.checkDetail,
                                callback: function($$v) {
                                  _vm.checkDetail = $$v
                                },
                                expression: "checkDetail"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          _vm._l(_vm.entries, function(entry, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "label-value--item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "label-value--item-label" },
                                  [_vm._v(_vm._s(entry.label))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "label-value--item-value" },
                                  [
                                    _vm._v(_vm._s(entry.value) + " "),
                                    _c("PhCheck", {
                                      staticClass: "color-success",
                                      style: {
                                        opacity: entry.value === "Ja" ? 1 : 0
                                      },
                                      attrs: { size: 16 }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "bottom-row" }, [
            _c(
              "div",
              [
                _c("DownloadLink", {
                  attrs: {
                    asLink: "",
                    title: "Makler PDF anzeigen / Vorschau",
                    downloadServicePath: "/ex_post_berechnung",
                    filename: "ExPostKosteninformation.pdf",
                    queryParams: {
                      checkSerienbrief: _vm.checkSerienbrief,
                      checkDetail: _vm.checkDetail
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.isInternLogin && !_vm.isFA
                  ? _c("DownloadLink", {
                      attrs: {
                        asLink: "",
                        title: "WealthKonzept PDF anzeigen / Vorschau",
                        downloadServicePath: "/ex_post_berechnung",
                        filename: "ExPostKosteninformation.pdf",
                        queryParams: { type: "wk" }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }