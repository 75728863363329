<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Postfach" subtitle="SMS" />
    
    <div class="box__container" v-if="sms">
      <span class="box__title" style="margin-right: 60px">
        <span class="text-right" style="width:5rem">Betreff</span>
        {{sms.subject}} 
      </span>
      <span>{{sms.dateSend}}</span><br>
      <div class="header">
        <div>
          <div>
            <span class="text-right" style="width:5rem">Von</span>
            {{sms.fromAddress}}
          </div>
          <div>
            <span class="text-right" style="width:5rem">An</span>
            {{sms.toAddress}}
          </div>
        </div>
      </div>
    </div>
    <div class="box__container" v-if="sms">
      <div class="sms-body">
        {{sms.text}}
      </div>
    </div>
  </div>
</template>

<script>
import COMMUNICATION_TYPES from '@/store/communication/types';
import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      smss: COMMUNICATION_TYPES.GETTERS.SMSS,
    }),
    sms() {
      return this.smss[this.commId];
    },
  },
  data() {
    return {
      commId: this.$route.params.commId,
    }
  },
  mounted() {
    if (!this.sms)
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_SMS, this.commId);
  },
  methods: {
  },

}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
}
.header >div {
  width: 50%;
}
.text-right{
  display: inline-block;
  padding-right: 15px;
  text-align: right;
}
.sms-body img {
  max-width: unset;
}
.footer {
  justify-content: space-around;
}
</style>