<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Eingegangene Transaktionen" />

    <BaseFilter
      title="Transaktionen suchen"
      filterId="9cd1544e-0e6f-4813-baec-71aa89728bc4"
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :showSaveButton="false"
      @onFilter="setFilters($event)"
    />

    <div class="box__container">
      <Transactions :filters="filters" title="Transaktionen" />
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Transactions from '@/components/transaktionVerlauf/Transactions.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';

const VALUE_MODUS_MONAT = '';
const VALUE_MODUS_ALLES = 'VALUE_MODUS_ALLES';
const VALUE_MODUS_TRANSDAT = 'VALUE_MODUS_TRANSDAT';
const VALUE_MODUS_VON_BIS = 'VALUE_MODUS_VON_BIS';

const MAP_FILTER_KEY_TO_MODUS = {
  vergangenerMonat: VALUE_MODUS_MONAT,
  alleTransaktionen: VALUE_MODUS_ALLES,
  nachTransaktionsdatum: VALUE_MODUS_TRANSDAT,
  ausZeitraum: VALUE_MODUS_VON_BIS,
}


export default {
  data() {
    return {
      filters: {
        modus: VALUE_MODUS_MONAT,
      },
      configFilter: {
        hideFirstColumn: true,
        singleFilter: true,
      },
      searchMetadata: [
        {
          type: 'group',
          key: 'Allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'default',
              label: 'Vergangener Monat',
              key: 'vergangenerMonat',
              emptyDenied: true,
            },
            {
              type: 'default',
              label: 'Alle Transaktionen',
              key: 'alleTransaktionen',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Nach Transaktionsdatum',
              key: 'nachTransaktionsdatum',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Aus Zeitraum',
              key: 'ausZeitraum',
              emptyDenied: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    setFilters(filters) {
      const filter = filters[0]; // Only use the first line of filters
      const modus = MAP_FILTER_KEY_TO_MODUS[filter.key];
      let from = null;
      let to = null;
      switch(modus) {
        case VALUE_MODUS_TRANSDAT:
        case VALUE_MODUS_VON_BIS:
          const rangeValue = filter?.value || [];
          const minValue = rangeValue[0] || {};
          const maxValue = rangeValue[1] || {};
          from = minValue?.value;
          to = maxValue?.value;
          break;
      }

      this.filters = {
        modus,
        to,
        from,
      };
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseFilter,
    Transactions,
  },
}
</script>
