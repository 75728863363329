<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.parentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      title="Datenschutzerklärung (extern) eintragen"
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      @action-BACK="back"
      @action-CANCEL="abbrechen"
    >
    </PageHeaderTitleNavigation>

    <div class="box__container" tid="61f5c7e1-d399-4079-8615-ef2be90c4fb7">

      <p>Bitte erfassen Sie außerhalb des Systems vorliegene Kontaktwünsche des Kunden hier.
				Widersprüche / Widerrufe des Kunden (auch formlose) können ebenfalls hier eingetragen werden.
				Die gemachten Angaben sind dauerhaft gespeichert, können aber als "ungültig" markiert werden oder durch
				aktualisierte bzw. korrigierte Angaben ersetzt werden.</p>

      <InputField label="Beschreibung / Herkunft / Dokument der Erklärung" 
        v-model="form.beschreibung" 
        validateUntouched
        isComponentHalfSize
      />

      <p>Einwilligung zur Kundenansprache</p>

      <!-- <InputToggleSwitch v-model="form.akzeptiert" :label="anspracheErlaubtSwitcherlabel"/> -->
      <InputRadioBoxGroup v-model="form.akzeptiert"  :values="anspracheErlaubtOptions" />

      <InputCheckBoxGroup
        v-model="form"
        :title="istDialog ? 'Sie wünschen den Kontakt' : 'Ich/wir wünsche/n den Kontakt'"
        :values="[ 
            { label: 'per Telefon', path: 'einwilligungTel' },
            { label: 'per Fax', path: 'einwilligungFax' },
            { label: 'per E-Mail', path: 'einwilligungEmail' },
            { label: 'per Post', path: 'einwilligungPost' },
            { label: 'per Onlinekundenzugang / App:', path: 'einwilligungApp' },
        ]"
      />
      <p>Falls die Kontaktaufnahme über E-Mail oder Fax verweigert wird ist ein E-Mail oder Faxversand aus dem System nicht möglich.</p>     
      <p v-if="istDialog">Bitte beachten Sie, dass in diesem Fall Ihnen zusätzliche wichtige Dienstleistungen (z. B. Angebote zu aktuellen Produkten, Informationen zu Marktentwicklungen, die telefonische Beratung) nicht zur Verfügung stehen.</p>     

      <InputToggleSwitch 
        v-if="!istDialog" 
        v-model="form.einwilligungVerkauf" 
        label="Im Zuge eines Unternehmensverkaufs stimme/n ich/wir der Weitergabe unserer Daten an das weiterführende Unternehmen zu. Im Vorfeld werden wir über die Weiterverwendung unserer Daten durch das weiterführende Unternehmen rechtzeitig und in einem gesonderten Anschreiben informiert."
      />

    </div>    

  </div>
    
</template>

<script>
import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from "@/store/datenschutzkundenaenderungen/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [validator],
  validators: {
    form: {
      beschreibung: [required('Beschreibung ist erforderlich!')]
    }
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputToggleSwitch,
    InputRadioBoxGroup,
    InputCheckBoxGroup,
  },
  data: function () {
      return {
        form: {
          beschreibung: '',
          akzeptiert: false,
          einwilligungVerkauf: true,
          einwilligungEmail: false,
          einwilligungApp: false,
          einwilligungTel: false,
          einwilligungPost: false,
          einwilligungFax: false,
        },
        cancel: false,
      }
  },
  computed: {
    textModus() {
      return this.$route.params.textModus;
    },
    istDialog() {
      return this.textModus == 'DIALOG_34F' || this.textModus == 'DIALOG_HD';
    },
    anspracheErlaubtOptions() {
      const result =  [
                {value: true, label: this.anspracheErlaubtSwitcherlabel},
                {value: false, label: this.istDialog ? "Sie möchten nicht durch Ihren Vermittler/Berater in telefonischer-, schriftlicher-, elektronischer oder anderer Form kontaktiert werden."
				: "Ich/wir möchte/n nicht telefonisch und/oder mit elektronischer Post kontaktiert werden."}
            ];

      return result;
    },
    anspracheErlaubtSwitcherlabel() {
      let label = "";

      switch (this.textModus) {
        case "FA":
          label = "Ich/wir bin/sind damit einverstanden, durch den Vermittler/Berater zukünftig zur Betreuung oder Vermittlung von bestimmten Finanzprodukten "
						+ "(z.B. Investmentfonds) oder zur Information über aktuelle Themen z.B. durch Infoletter, Einladungen zu Kundeninformationsveranstaltungen "
						+ "oder Produktneuigkeiten angerufen bzw. per E-Mail, Fax oder Postsendung kontaktiert zu werden. "
						+ "Zu diesem Zweck darf der Vermittler/Berater personenbezogene Daten, die er im Rahmen der Anlageberatung oder der vermittelten "
						+ "Vermögensverwaltung zum Zwecke der Vertragsdurchführung und Betreuung erhoben, verarbeitet und genutzt hat (z.B. "
						+ "Daten zum Geschäftsabschluss, Konto-/Depotdaten, Angaben unter diesem Formular und einem Anlageberatungsprotokoll) "
						+ "auch zu den genannten Werbezwecken nutzen. Diese Einwilligung ist freiwillig. Sie kann jederzeit ohne Einfluss auf das bestehende "
						+ "Vertragsverhältnis mit Wirkung für die Zukunft widerrufen werden.";
          break;
        case "FK":
          label = "Ich/wir bin/sind damit einverstanden, durch den Vermittler/Berater zukünftig zur Betreuung oder Vermittlung von bestimmten "
						+ "Finanzprodukten (z.B. Investmentfonds, geschlossenen Beteiligungen und Versicherungen) oder zur Information über aktuelle Themen "
						+ "z.B. durch Infoletter, Einladungen zu Kundeninformationsveranstaltungen oder Produktneuigkeiten angerufen bzw. per E-Mail, Fax "
						+ "oder Postsendung kontaktiert zu werden. Zu diesem Zweck darf der Vermittler/Berater personenbezogene Daten, die er im Rahmen "
						+ "der Anlageberatung oder der Vermögensverwaltung zum Zwecke der Vertragsdurchführung und Betreuung erhoben, verarbeitet "
						+ "und genutzt hat (z.B. Daten zum Geschäftsabschluss, Konto-/Depotdaten, Angaben unter diesem Formular und einem Anlageberatungsprotokoll) "
						+ "auch zu den genannten Werbezwecken nutzen. Diese Einwilligung ist freiwillig. Sie kann jederzeit ohne Einfluss auf "
						+ "das bestehende Vertragsverhältnis mit Wirkung für die Zukunft widerrufen werden.";
          break;    
        case "DIALOG_34F":
          label = "Sie sind damit einverstanden, dass Ihr Vermittler/Berater zukünftig zur Betreuung oder Vermittlung von bestimmten Finanzprodukten (z.B. Investmentfonds, "
						+ "geschlossene Beteiligungen) oder zur Information über aktuelle Themen (z. B. durch Infoletter, Einladungen zu Kundeninformationsveranstaltungen oder Produktneuheiten) anruft bzw. per "
						+ "E-Mail, Fax oder Postsendung kontaktiert. Zu diesem Zweck darf der Vermittler/Berater personenbezogene Daten, die er im Rahmen der Anlageberatung oder der Vermögensverwaltung zum Zwecke "
						+ "der Vertragsdurchführung und Betreuung erhoben, verarbeitet und genutzt hat (z. B. Daten zum Geschäftsabschluss, Konto-/Depotdaten, Angaben in einem Beratungsprotokoll) auch zu den "
						+ "genannten Zwecken nutzen. Diese Einwilligung ist freiwillig. Sie kann jederzeit ohne Einfluss auf das bestehende Vertragsverhältnis mit Wirkung für die Zukunft widerrufen werden.";
          break;      
        case "DIALOG_HD":
          label = "Sie sind damit einverstanden, dass Ihr Vermittler/Berater zukünftig zur Betreuung oder Vermittlung von bestimmten Finanzprodukten (z.B. Investmentfonds, "
						+ "geschlossene Beteiligungen) oder zur Information über aktuelle Themen (z. B. durch Infoletter, Einladungen zu Kundeninformationsveranstaltungen oder Produktneuheiten) anruft bzw. per "
						+ "E-Mail, Fax oder Postsendung kontaktiert. Zu diesem Zweck darf der Vermittler/Berater personenbezogene Daten, die er im Rahmen der Anlageberatung oder der Vermögensverwaltung zum Zwecke "
						+ "der Vertragsdurchführung und Betreuung erhoben, verarbeitet und genutzt hat (z. B. Daten zum Geschäftsabschluss, Konto-/Depotdaten, Angaben in einem Beratungsprotokoll) auch zu den "
						+ "genannten Zwecken nutzen. Diese Einwilligung ist freiwillig. Sie kann jederzeit ohne Einfluss auf das bestehende Vertragsverhältnis mit Wirkung für die Zukunft widerrufen werden.";
          break;     
      }

      return label;
    },
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction('BACK', 'Zurück').withDisabled(() => this.validation?.invalid));
      actions.push(PageHeaderSimpleAction('CANCEL', 'Abbrechen'));
      return actions;
    },
  },
  methods: {
    async makeEntryManuell() {
       await this.$store.dispatch(DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.MAKE_ENTRY_MANUELL, this.form);
    },
    abbrechen() {
      this.cancel = true;
      this.back();
    },
    back() {
      this.$router.push({ path: '/service/datenschutzkundenaenderungen/list', query: {backAction: true}});
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.cancel) {
      next();
    } else {
      if (this.validation?.invalid) {
        this.$confirmModal({
          title: 'Fehler', 
          message: 'Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche.', 
          labelButtonCancel: 'Ok',
          showConfirmButton: false,
        })
      } else {
        await this.makeEntryManuell();
        next();
      }
    } 
  },

}
</script>