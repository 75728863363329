var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isBroker
        ? _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: "Vermittler Dashboard",
              noPrimaryAction: "",
              actions: _vm.headerActions
            },
            on: {
              "action-CONFIG": function($event) {
                return _vm.$refs.brokerDashboardConfigModal.open()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "action-TOGGLE-STRUKTUR",
                  fn: function() {
                    return [
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "mit Struktur",
                          inLineLabel: "",
                          inLineAlignment: "left",
                          value: _vm.isStruktur,
                          disabled: !_vm.isLoaded
                        },
                        on: {
                          input: function($event) {
                            return _vm.toggleChangeStruktur()
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              363756056
            )
          })
        : _vm._e(),
      _c(
        "div",
        [
          _vm._l(_vm.sections, function(section) {
            return [
              _c(
                "div",
                { key: section.section },
                [
                  _c("DashboardPanel", {
                    attrs: {
                      id: _vm.MAP_SECTION_TO_DASHBOARD_ID[section.section],
                      title: section.title,
                      data: _vm.mapSectionToDashboardData[section.section]
                    },
                    on: {
                      executeAction: function($event) {
                        return _vm.executeAction($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(section.cards, function(card, index) {
                          return {
                            key: card.widgetName,
                            fn: function() {
                              return [
                                _c("CardFactory", {
                                  key: index,
                                  ref: card.widgetName,
                                  refInFor: true,
                                  attrs: {
                                    index: "" + card.chartIndex,
                                    currentCard: card,
                                    currentCardOption: card.option,
                                    additionalData: _vm.chartsData,
                                    title: card.title,
                                    labels: card.labels,
                                    info: card.info,
                                    isError: card.isError,
                                    infoLabel:
                                      section.title + " - " + card.option,
                                    customColors: card.customColors,
                                    showTitle: false,
                                    showToolbar: false,
                                    listView:
                                      _vm.mapWidgetNameToIsListView[
                                        card.widgetName
                                      ]
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        })
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c("BrokerDashboardConfigModal", {
        ref: "brokerDashboardConfigModal",
        attrs: { sections: _vm.sections }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }