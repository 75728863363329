<template id="explorer">
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <DocumentsNavigation :nodeId="selectedNodeId" :versicherungId="$route.params.id" 
      :navigationArray="navigationArray"
      :additionalActions="additionalActions"
      :maxFileSizeMB="getMaxFileSizeAllowed"
      @headerAction="handleHeaderActions"
      @selectNavigationNode="selectNavigationNode($event)"
    />

    <div class="explorer_container">
      <DocumentsUploadModal 
        v-if="files && currentAdditionalInputs" 
        :files="files" 
        @close="files=null" 
        :additionalInputs="currentAdditionalInputs" 
        :parentId="selectedNode.nodeId"
        :maxFileSizeMB="getMaxFileSizeAllowed"
      />
      <div class="explorer__container">
        <!-- HEADER WITH ICONS AND FILTER -->
        <div class="explorer__filter"><DocumentsFilter @onFilter="retrieveDokumentenarchivTree('???')"/></div>

        <!-- BREADCRUMB NAVIGATION -->
        <div v-if="hasAdditionalData" class="explorer__navigation box__container">
          <div>
            <DocumentsHeaderInputs :inputs="selectedNode.additionalData"/>
          </div>
        </div>

        <div
          class="explorer__body search__mode__off box__container" :tid="_generateTidFromString('explorer__body search__mode__off box__container')"
          v-if="explorerData"
        >

          <!-- CONTENT -->
          <DragnDropArea @files="dropFile" hoverText="Datei hier ablegen" :disabled="!selectedExplorerNode || !selectedExplorerNode.uploadable" class="explorer__content" :maxFileSizeMB="getMaxFileSizeAllowed">

            <ContentWithSidebar>
              <template #sidebar>
                <Tree 
                  v-if="treeNodes.length"
                  :items="treeNodes" childrenKey="nodes" idKey="nodeId" 
                  :itemSelectedId="selectedNodeId" @itemSelect="selectNode($event)"
                  :defaultCollapsed="false"
                  uniqueId='36f10422-475a-4457-84b2-ea2ae2fb6dd0'
                  class="explorer__tree_nodes"
                  @dropped="onDropAction"
                >
                  <template #default="{item}">
                    <div class="explorer__tree_node_content">
                      <span class="explorer__tree_node_label">{{ item.label }}</span>
                      <span v-if="item.numberOfRecords" class="explorer__tree_node_total">{{ item.numberOfRecords }}</span>
                    </div>
                  </template>
                </Tree>
                <GhostLoading v-else-if="dokTreeLoading" class="mt-2" type="block" :config="{ block: { height: 24, }, }" :repeat="10" />
                <div v-else>Keine Daten</div>
              </template>
              <template #content v-if="!$isSmallScreen">
                <PrivateDokNodeTree v-if="selectedNodeViewType === VIEW_TYPE_NODES && selectedNodeSubfolderAllowed" :nodeId="selectedNodeId" />
                <DocumentsTable v-show="selectedNodeViewType !== VIEW_TYPE_NODES" :nodeId="selectedNodeId" :rowsPerPage="rowsPerPage" :maxFileSizeMB="getMaxFileSizeAllowed" linkVertrag loadAntraege enableDraggable noHeaderActions />
              </template>
            </ContentWithSidebar>

          </DragnDropArea>
        </div>
      </div>
    </div>

    <DocumentsTableModal
      ref="documentsModal"
      :selectedNodeViewType="selectedNodeViewType"
      :selectedNodeSubfolderAllowed="selectedNodeSubfolderAllowed"
      :nodeId="selectedNodeId"
      :title="documentsTableModalTitle"
      :additionalActions="additionalActions"
      :rowsPerPage="rowsPerPage"
      :maxFileSizeMB="getMaxFileSizeAllowed"
      linkVertrag
      loadAntraege
      enableDraggable
      @close="selectedNodeId = null"
      @headerAction="handleHeaderActions"
    />
  </div>
</template>

<script>
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import DocumentsUploadModal from '../fileUpload/DocumentsUploadModal';
import ContentWithSidebar from '@/components/core/ContentWithSidebar.vue';
import Tree from '@/components/core/Tree.vue';
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import DocumentsTableModal from '@/components/documents/DocumentsTableModal.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { mapGetters } from 'vuex';
import DocumentsHeaderInputs from '@/components/documents/DocumentsHeaderInputs.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DocumentsNavigation from '@/components/documents/DocumentsNavigation.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import PrivateDokNodeTree from '@/components/documents/privateDokNode/PrivateDokNodeTree.vue';
import DocumentsFilter from '@/components/documents/DocumentsFilter.vue';
import { Droppable, } from '@/directives/v-draggable-directive';

const ROWS_PER_PAGE_DEFAULT = 20;

const VIEW_TYPE_NODES = 'NODES';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  name: "BaseExplorer",
  components: {
    DragnDropArea,
    DocumentsUploadModal,
    ContentWithSidebar,
    Tree,
    DocumentsTable,
    DocumentsTableModal,
    DocumentsHeaderInputs,
    OptionMenu,
    DocumentsNavigation,
    GhostLoading,
    InputField,
    PrivateDokNodeTree,
    DocumentsFilter,
  },
  props: {
    // expected to be BaseModalSimpleActions
    additionalActions: {
      type: Array,
      default: () => []
    },
  },  
  data() {
    return {
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      selectedNodeId: null,
      navigationArray: [{ label: "Dokumentenarchiv" }],
      files: null,
      mobileContentActive: null,
      selectedExplorerNode: null,
      selectedNodeViewType: null,
      VIEW_TYPE_NODES,
    };
  },
  mounted() {
    this.retrieveDokumentenarchivTree().then(response => {
      this.selectInitialNode(response)
    })
    document.addEventListener('counterUpdate', this.refreshDocuments)
  },
  destroyed() {
    document.removeEventListener('counterUpdate', this.refreshDocuments)
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
      breadcrumb: 'Dokumente',
    });

    next();
  },
  watch: {
    selectedNode(newValue, oldValue) {
      if (newValue.nodeId == null || !this.explorerData)
        return null
      let result = null
      const selectedNodeId = newValue.nodeId
      const findNode = function (node) {
        if (node.nodeId == selectedNodeId) {
          result = node
          return true
        }
        else {
          return node.nodes && node.nodes.some(findNode)
        }
      };
      findNode(this.explorerData)
      this.selectedExplorerNode = result
      if (this.selectedNode?.documents?.['0']?.length || this.selectedNode?.viewType === VIEW_TYPE_NODES && this.selectedNodeSubfolderAllowed) {
        this.mobileContentActive = 'content';
      }
    },
  }, 
  computed: {
    ...mapGetters({
      selectedNode: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS,
      explorerData: DOKUMENTENARCHIV_TYPES.GETTERS.DOKUMENTENARCHIV_DATA,
      additionalUploadInputs: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS,
      dokTreeLoading: DOKUMENTENARCHIV_TYPES.GETTERS.TREE_LOADING,
    }),
    navigationParams() {
      return this.$route.params?.navigationParams
    },
    treeNodes() {
      const { selectedNodeId, } = this;

      const mapDroppable = node => ({
        ...node,
        nodes: node?.nodes?.map(mapDroppable),
        $droppable: node?.nodeId != selectedNodeId && node?.droppable ? Droppable(node) : null,
      });
      return this.explorerData?.nodes?.map(mapDroppable) || [];
    },
    hasAdditionalData() {
      return this.selectedNode?.additionalData?.length > 0;
    },
    selectedNodeSubfolderAllowed() {
      return this.selectedNode?.subfolderAllowed;
    },
    currentAdditionalInputs() {
      return this.additionalUploadInputs?.additionalUploadInputs || null;
    },
    originalPath() {
      let originalPath = this.$route.path
      
      if (this.navigationParams) {
        originalPath = this.$route.path.replace(`${encodeURIComponent(this.navigationParams)}`, '')
      }

      return originalPath.endsWith('/') ? originalPath.slice(0, -1) : originalPath
    },
    documentsTableModalTitle() {
      const whatNode = this.navigationArray?.at(-1);

      if (whatNode) {
        return whatNode.label
      } else {
        return 'Dokumente'
      }
    },
    getMaxFileSizeAllowed() {
      if(this.navigationParams && this.navigationParams.includes("Makleraufträge")){
        return 10 // megabytes
      }
      return 0; // any size allowed
    },
  },
  methods: {  
    selectInitialNode(tree) {
      if (tree && tree.nodes) {
        let nextNode = null

        // when navigating back, using a deep link or a bookmark we want to select that specific folder
        if (this.navigationParams) {
          let pathArray = this.navigationParams.split('/');
          let parent = null

          pathArray.forEach(path => {
            nextNode = (nextNode || tree).nodes.find(n => n.label === path);

            if (parent && nextNode) {
              nextNode.parent = parent
            }

            parent = nextNode
          })
          
          // if we have naviagtionparams but did not find a node for it we clear the navigationparams
          if (!nextNode) {
            this.$router.push({
              path: this.originalPath,
              query: this.$route.query,
            });
          }
        }
        
        // navigate to the default folder if we haven't found a nextNode already
        if (!nextNode && !this.$isSmallScreen && tree.defaultNodeId) {
          nextNode = this.findNestedNode(tree.nodes, tree.defaultNodeId)
        }

        // if we found a nextNode then select it
        if (nextNode) {
          this.selectNode(nextNode);
        }
      }
    },
    findNestedNode(nodes, nodeId) {
      for (const node of nodes) {
        if (node.nodeId === nodeId) return node;
        if (node.nodes?.length) {
          const innerResult = this.findNestedNode(node.nodes, nodeId);
          if (innerResult) return innerResult;
        }
      }
    },
    retrieveDokumentenarchivTree() {
      return this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOKUMENTENARCHIV)
    },
    handleMobileContentChange(content) {
      this.mobileContentActive = content;
    },
    selectNode(whatNode) {
      this.selectedNodeId = whatNode.nodeId;
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_DOCUMENTS_SUCCESS, {node: null})

      this.navigationArray = [];
      this.addItemToBreadcrumb(whatNode);
      this.navigationArray.push(whatNode);

      const params = Array.prototype.map.call(this.navigationArray.slice(1), function(item) { return item.label }).join("/")

      if (this.$isSmallScreen) {
        this.$nextTick(() => {
          this.$refs.documentsModal.open()
        })
      }

      if (params !== this.navigationParams) {
        this.$router.push({
          path: `${this.originalPath}/${encodeURIComponent(params)}`, 
          query: this.$route.query,
        });
      }


      this.selectedNodeViewType = whatNode?.viewType;
      this.getNextPageDocuments(whatNode.nodeId, 0, this.rowsPerPage);
    },
    refreshDocuments(event) {
      if (event.detail?.badgeName === 'TODO_LIST') {
        const whatNode = this.navigationArray?.at(-1);

        if (whatNode) {
          this.getNextPageDocuments(whatNode.nodeId, 0, this.rowsPerPage, null);
        }
      }
    },
          
    getNextPageDocuments(nodeId, offset, limit) {
      this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS,
        {
          nodeId: nodeId,
          offset: offset,
          limit: limit,
          linkVertrag: true,
        }
      );
    },
    addItemToBreadcrumb(whatNode) {
      if (whatNode.parent) {
        this.navigationArray.unshift(whatNode.parent);
        this.addItemToBreadcrumb(whatNode.parent)
      } else {
        this.navigationArray.unshift({ label: "Dokumentenarchiv" });
      }
    },
    selectNavigationNode(whatNode) {
      if (whatNode.label === "Dokumentenarchiv") {
        this.selectedNodeId = null;
        this.navigationArray = [{ label: "Dokumentenarchiv" }];
      } else {
        this.selectedNodeId = whatNode.nodeId;
      }
    },
    async dropFile(files) {
      if (this.selectedNode?.headerActions?.some(action => action.key === 'DATEI_HINZUFUGEN')) {
        // wenn headeractions includes datei hinzufügen -> files in den store packen -> zu den additional documents routen
        this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TEMP_UPLOAD_FILES, files)

        this.$nextTick(() => {
          this.$router.push({
            path: `/communication/documents/zusaetzlichedokumente`, 
            query: { 
              hasFilesUploaded: true,
              nodeId: this.selectedNodeId, 
            },
          })
        })
      } else {
        await this.$store.dispatch(
          DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS,
          {
            nodeId: this.selectedNode.nodeId,
          }
        );
        this.files = files
      }
    },
    async onDropAction(event) {
      const sourceFile = event.detail?.source;
      const targetNode = event.detail?.target;

      if(!sourceFile || !targetNode) return;

      await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.DROP_ACTION, {
        sourceNodeId: sourceFile?.nodeId,
        targetNodeId: targetNode?.nodeId,
        fileId: sourceFile?.id,
      });

      this.selectNode(targetNode);
    },
    handleHeaderActions({ key, value, }) {
      this.$emit('headerAction', { key, value, });
      this.$emit(`headerAction-${key}`, value);
    },
  },
};
</script>

<style scoped>
.header_buttons > div {
  margin: 0 0 12px;
}

.header_buttons > div:last-child {
  margin-bottom: 0;
}

.explorer__container {
  display: flex;
  flex-direction: column;
}

.explorer__body {
  width: 100%;
}

.explorer__header {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  padding: 0.8em;
}

.explorer__header * {
  float: right;
}

.explorer__header i {
  font-size: 1.8em;
  cursor: pointer;
  float: right;
}

.explorer__header .explorer__header__search {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.explorer__header .explorer__header__search input {
  flex-grow: 1;
}

.explorer__header .explorer__schadensmeldung_checkbox {
  margin-right: 4px;
  margin-left: 4px;
}

.explorer__header .explorer__header__search i {
  position: absolute;
  left: 8px;
  font-size: 1em;
}

.explorer__header .explorer__header__search input {
  text-indent: 26px;
}

.explorer__sidebar {
  white-space: nowrap;
  padding: 4px;
  flex-grow: 0;
}

.explorer__sidebar div {
  cursor: pointer;
}

.explorer__content {
  position: relative;
  background-color: unset;
  flex-grow: 1;
  max-width: 100%;
}

.content__item {
  display: flex;
  max-width: 98px;
  min-width: 98px;
  min-height: 98px;
  text-align: center;
  /* background-color: aqua; */
  margin: 2px;
  flex-direction: column;
  word-wrap: break-word;
  padding: 2px;
}

.content__item i:nth-child(2) {
  font-size: 1.2em;
}

.content__detailed {
  display: flex;
  flex-wrap: nowrap;
  /* border: 1px black solid; */
}

.content__detailed:nth-child(1) div {
  font-weight: bold;
  border-left: 1px #e5e5e5 solid;
  cursor: pointer;
}

.content__detailed:nth-child(1) div i {
  font-size: 1em;
}

.content__detailed:nth-child(1) div:nth-child(1) {
  border-left: none;
}

.content__item__expanded__column {
  word-wrap: break-word;
  text-align: left;
  width: 120px;
}

.content__item__expanded__column__icon {
  display: flex;
  /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.content__item__expanded__column:nth-child(1) {
  width: 32px;
}

.content__item__expanded__column:nth-child(2) {
  width: 32px;
}

.content__item__expanded__column:nth-child(3) {
  width: 32px;
}

.content__item__expanded__column:nth-child(4) {
  width: 32px;
}

.content__item__expanded__column:nth-child(6) {
  width: 80px;
}

.content__item__expanded__column:nth-child(8) {
  width: 180px;
}

.explorer__navigation__item {
  display: inline-block;
  cursor: pointer;
  flex: 1 1 0px;
  margin-bottom: 4px;
  font-size: 1.1em;
}

.explorer__content__node {
  display: flex;
  flex-wrap: wrap;
}

.explorer__content__node i {
  cursor: pointer;
}

.explorer__content__node a {
  color: var(--color-link)
}

.explorer__content i {
  font-size: 3em;
}

.content__detailed i {
  font-size: 2em;
}

.explorer__content__smaller {
  display: none;
}

.explorer__search__string {
  font-weight: bold;
  font-style: italic;
}

.break-text {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
}

.vertrag__ordner.tooltip {
  position: relative;
  display: inline-block;
}

.vertrag__ordner.tooltip .tooltiptext {
  visibility: hidden;
  background: rgb(204, 204, 204);
  background: rgba(204, 204, 204, 0.5);
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  display: inline-block;
  z-index: 1;
  min-width: 600px;
}

.vertrag__ordner.tooltip:hover .tooltiptext {
  visibility: visible;
}

.no__vertrag__ordner {
  display: none;
}

.selected__node {
  font-weight: bold;
}

.unselected__node {
  font-weight: unset;
}

.color-text {
  color: var(--color-primary);
}

.color-primary {
  color: var(--color-primary);
}

.color-success {
  color: var(--color-success);
}

.color-warning {
  color: var(--color-warning);
}

.color-info {
  color: var(--color-info);
}

.color-danger {
  color: var(--color-danger);
}

.td-icon i {
  font-size: 1em;
  padding: 2px;
}

th,
tr,
td {
  vertical-align: middle;
}

.icons-inline {
  display: inline;
  font-size: 1.8em;
}

.explorer__tree_nodes {
  margin: 8px 0 8px;
}

.explorer__tree_node_content {
  display: flex;
}

.explorer__tree_node_label {
  flex: 1 1 auto;
  word-break: break-word;
}

.explorer__tree_node_total {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}


.box__title {
  margin-bottom: 8px;
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-box);
}

.success {
  background-color: var(--color-success);
  color: var(--color-box);
}

.warning {
  background-color: var(--color-warning);
  color: var(--color-box);
}

.info {
  background-color: var(--color-info);
  color: var(--color-box);
}

.danger {
  background-color: var(--color-danger);
  color: var(--color-box);
}

.explorer__navigation {
   width: 100%;
}

.explorer__navigation--breadcrumb {
  flex: 1 1 auto;
}

.header__button {
  margin-right: 16px;
}

.name-filter {
  width: 300px;
  max-width: 100%;
}

/* Large desktops and laptops */
@media (min-width: 1861px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }

  .content__item__expanded__column:nth-child(5) {
    width: 320px;
  }
  .content__item__expanded__column:nth-child(6) {
    width: 80px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 200px;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 1021px) and (max-width: 1860px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 250px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 1020px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }
  .content__item__expanded__column .vertrag__ordner:nth-child(4) {
    width: 180px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .explorer__sidebar {
    display: none;
  }
  .explorer__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 180px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .explorer__content {
    border-left: none;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}

/* Portrait phones and smaller */
@media (max-width: 767px) {
  ::v-deep .header_buttons .header__section--buttons {
    display: none;
  }
}

@media (max-width: 640px) {
  .explorer__sidebar {
    display: none;
  }
  .explorer__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }

  /* Search mode off */
  .search__mode__off .content__item__expanded__column:nth-child(5) {
    min-width: 150px;
  }
  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .explorer__navigation__item {
    font-size: 1em;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}
</style>
