var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Bemerkungen",
          actions: _vm.headerActions
        },
        on: { "action-NEW_BEMERKUNG": _vm.createBemerkung }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Bemerkungen Filter",
              filterId: "PostfachBemerkungFilter",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c("BemerkungenList", {
        attrs: {
          records: _vm.records,
          recordCount: _vm.recordCount,
          loading: _vm.loading,
          typ: _vm.VALUE_TYP,
          useInvertSelection: ""
        },
        on: {
          onScroll: _vm.onScroll,
          delete: _vm.deleteRow,
          deleteSelected: _vm.deleteSelected
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }