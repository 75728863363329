<template>
  <div>
    <PageHeaderActionCard :showHeaderCard="false"></PageHeaderActionCard>

    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <div class="row space-between">
      <div class="flex-item" v-for="(sumItem, index) of resultSums" :key="index" >
        <BoxContainer class="text-center" :tid="_generateTidFromString('sum' + index)">
          <span class="text-small mp-0">{{sumItem.label}}</span><br>
          <span v-if="sumItem.type.includes('currency')"><CurrencyLabel :value="sumItem.value"/></span>
          <span v-else>{{sumItem.value}}</span>
        </BoxContainer>
      </div>
    </div>

    <BalanceSheetInsuranceTable/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { parse } from '@/helpers/number-formatter.js'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import BalanceSheetInsuranceTable from '@/components/versicherungen/BalanceSheetInsuranceTable.vue'
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  computed: {
    ...mapGetters({
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
    }),

    vorsorgeRecords() {
      return this.insuranceTable?.data?.records?.filter?.(record => record[1] === 'VORSORGE')
    },
    
    totalAmount() {
      return this.vorsorgeRecords?.map(record => record[17] + parse(record[10]))
        .reduce((accu, curr) => accu + curr, 0)
    }, 
    resultSums() {
      return [{
        type: 'currency',
        label: 'Wert',
        value: this.totalAmount
      }]
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from, breadcrumb: this.selectedFund});
    this.selectedFund = null;
    next();
  },
  components: {
    BalanceSheetInsuranceTable,
    OptionMenu,
    PageHeaderActionCard,
    CurrencyLabel,
    BoxContainer,
  }

}
</script>

<style scoped>
.insurance__table--company {
  display: flex;
  align-items: center;
}
.insurance__table--company__logo {
  margin: 0 8px 0 0;
  max-width: 40px;
  height: auto;
}

.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>
