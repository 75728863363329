<template>
  <div>
  </div>
</template>

<script>
export default {
  mixins: [],
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Kommunikationsübersicht',
      to,
      from
    });

    next()
  },
}
</script>

<style scoped>

</style>