<template>
  <div>
    <BaseModal 
      ref="addVersicherungModal"
      modalTitle="Versicherung hinzufügen"
      :confirmDisabled="isFormInvalid"
      :autoClose = "false"
      @onConfirmButton="saveNewVersicherung()"
      @close="close()">


      <div v-if="vertragsdatenFieldsDefinition">

        <ComboBox
          id="gesellschaftId"
          label="Gesellschaft"
          :customizable="true"
          :firstEmpty="true"
          v-model="versicherung.gesellschaftId"
          :values="gesellschaftList"
          :disabled="vertragsdatenFieldsDefinition.gesellschaft.disabled"
          :validateUntouched="true"
          @blur="dataChanged('gesellschaft', versicherung.gesellschaftId)"
        />

        <InputToggleSwitch
          label="Zeige Fremdgesellschaften"
          v-model="versicherung.displayFremdges"
          :disabled="vertragsdatenFieldsDefinition.displayFremdges.disabled"
          @input="updateGesellschaften($event)"
        />

        <ComboBox
          label="Anbindung"
          v-model="versicherung.anbindung"
          :values="vertragsdatenFieldsDefinition.anbindung.value"
          :disabled="!versicherung.gesellschaftId || 
            vertragsdatenFieldsDefinition.anbindung.disabled || 
            !vertragsdatenFieldsDefinition.anbindung.value || 
            !vertragsdatenFieldsDefinition.anbindung.value.length || 
            isLoading"
          v-if="vertragsdatenFieldsDefinition.anbindung.visible"
          @change="dataChanged('anbindung', $event)"
        />

        <ComboBox
          label="Betreuungsstufe"
          v-model="versicherung.betreuungsstufe"
          :values="betreungsstufeComputedValues"
          :disabled="!versicherung.anbindung || versicherung.anbindung !== 'FPPLUS_GEWERBE'"
          v-if="vertragsdatenFieldsDefinition.anbindung.visible"
          @change="dataChanged('betreuungsstufe', $event)"
        />

        <InputField
          id="nummer"
          v-model="versicherung.nummer"
          :label="vertragsdatenFieldsDefinition.polNr.value"
          :disabled="!versicherung.gesellschaftId"
          @input="dataChanged('nummer', $event)"
          :validateUntouched="true"
        />

        <DatePickerField
          id="beginn"
          v-model="versicherung.beginn"
          label="Vertragsbeginn"
          :disabled="vertragsdatenFieldsDefinition.vertragsbeginn.disabled"
          :validateUntouched="isFPPlus"
          @input="laufzeitChanged('beginn', $event)"
        />

        <div v-if="isFPPlus" class="d-flex">
          <InputRadioBoxGroup
            class="align-end"
            style="flex-grow: 0;"
            v-model="versicherung.anzeigeModusDauer"
            :values="[{ value: 'ENDE', label: '' }]"
            @input="versicherung.laufzeit=''"
            :disabled="!versicherung.beginn"
          />
          <DatePickerField
            v-model="versicherung.ende"
            label="Vertragsende"
            :disabled="!versicherung.beginn || versicherung.anzeigeModusDauer === 'LAUFZEIT'"
            @input="laufzeitChanged()"
          />
        </div>

        <div v-if="isFPPlus" class="d-flex">
          <InputRadioBoxGroup
            class="align-end"
            style="flex-grow: 0;"
            v-model="versicherung.anzeigeModusDauer"
            :values="[{ value: 'LAUFZEIT', label: '' }]"
            @input="updateEndDate()"
            :disabled="!versicherung.beginn"
          />
          <InputField
            v-model="versicherung.laufzeit"
            label="Laufzeit (Jahre)"
            type="number"
            isInteger
            :disabled="!versicherung.beginn || versicherung.anzeigeModusDauer === 'ENDE'"
            @input="dataChanged('laufzeit', $event)"
            :validateUntouched="isFPPlus"
          />
        </div>

      </div>
      <GhostLoading v-else>
        <Block height="40" />
        <Block height="40" />
        <Block height="40" />
        <Block height="40" />
      </GhostLoading>
      
    </BaseModal>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import validator from '@/mixins/validator'
import { required, regex } from '@/mixins/validator/rules';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import dayjs from 'dayjs';
import CORE_TYPES from "@/store/core/types";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import { debounce, } from '@/helpers/commonfunctions.js';

const FPPLUS_GEWERBE = 'FPPLUS_GEWERBE';

export default {
  mixins: [validator],
  validators: {},
  components: {
    BaseModal,
    InputField,
    ComboBox,
    InputToggleSwitch,
    DatePickerField,
    InputRadioBoxGroup,
    GhostLoading,
    Block,
  },
  watch: {
    displayFremdges() {
      this.loadGesellschaftList(this.categorySelected?.group, this.displayFremdges)
    },
  },
  data() {
    return {
      versicherung: {
        vertragssparteId: null,
        gesellschaftId: null,
        nummer: null,
        displayFremdges: false,
        beginn: null,
        anzeigeModusDauer: null,
        betreuungsstufe: null,
        isLoading: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
      categorySelected: VERSICHERUNG_TYPES.GETTERS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED,
      gesellschaftList: VERSICHERUNG_TYPES.GETTERS.GESELLSCHAFT_LIST,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    isFormInvalid() {
      return this.validation.invalid;
    },
    fieldsValidators() {
      
      let validators = {
        gesellschaftId : [required('Gesellschaft ist erforderlich!')],
      }

      if(this.isFPPlus) {
        validators.beginn = [required('Vertragsbeginn ist erforderlich!')];
        validators.laufzeit = [required('Laufzeit ist erforderlich!')];
      }

      return {versicherung: validators};
    },
    betreungsstufeComputedValues(){
      if(FPPLUS_GEWERBE === this.versicherung.anbindung){
        //removing 'Ohne' value for FP+ Gewerbe GmbH
        return this.vertragsdatenFieldsDefinition.betreuungsstufe.value?.filter(item => item.value !== 'Ohne');
      }
      return this.vertragsdatenFieldsDefinition.betreuungsstufe.value
    },
  },
  methods: {
    loadVertragsDaten(groupId) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION, { groupId });
    },
    loadGesellschaftList(groupId, displayFremdges) {
      if (groupId) {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_GESELLSCHAFT, {groupId, displayFremdges})
      }
    },
    close() {
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION);
      this.$emit('close')
    },
    updateGesellschaften(payload){
      this.loadGesellschaftList(this.categorySelected.group,payload); 
    },
    saveNewVersicherung() {
      const payload = {
        description: {
          id: this.categorySelected.group,
        },
        gesellschaft: {
          id: this.versicherung.gesellschaftId
        },
        nummer: this.versicherung.nummer,
        displayFremdges: this.versicherung.displayFremdges,
        einlesen_updaten: true,
        beginn: this.formatDate(this.versicherung.beginn),
        fremdanlage: this.isBroker && !this.isIntern,
        isEigenerVertrag: this.isFA,
      }

      if(this.isFPPlus){
        payload.anbindung = this.versicherung.anbindung;
        payload.betreuungsstufe = this.versicherung.betreuungsstufe;
        payload.ende = this.formatDate(this.versicherung.ende);
        payload.anzeigeModusDauer = this.versicherung.anzeigeModusDauer;
        payload.laufzeit = this.versicherung.laufzeit;
      }

      if(this.validation.valid){
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_VERTRAG, payload)
        .then(vertragId => {
          this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION);
          this.$refs.addVersicherungModal.close();
          this.$router.push(`/home/versicherungen/insurance-group/details/${vertragId}?newVertrag=true`);
        })
        .catch((error) => {
          const message = error?.response?.data;
          if(message){
            this.validateNummer(message);
          }
        })
      }
    },
    async validateNummer(serverError) {
      const nummerPath = 'versicherung.nummer';

      this.validation.reset(nummerPath);
      if(!this.versicherung.gesellschaftId) {
        return;
      }

      if(serverError) {
        this.$pushErrors(nummerPath, serverError?.message);
        return;
      }
      const response = await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION, 
        { gesellschaftId: this.versicherung.gesellschaftId, polNummer: this.versicherung.nummer });
      if(this.versicherung.nummer && !response?.valid){
        this.$pushErrors(nummerPath, response?.validationMessage || '');
      } else {
        this.validation.reset(nummerPath);
      }

    },
    getPoliceNummerValidation(value){
      if(value) {
        const payload = {gesellschaftId: value}
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION, payload)
        .then(() => {
          this.validateNummer();
        })

      } else {
        this.versicherung.nummer = '';
      }
    },
    laufzeitChanged(field, value) {

      const date1 = this.versicherung.beginn;
      const date2 = this.versicherung.ende;

      if (date1 && date2) {
        const d1 = dayjs(date1);
        const d2 = dayjs(date2);
        this.versicherung.laufzeit = Math.round(d2.diff(d1, 'year', true));
      }

      this.dataChanged(field, value);

    },
    dataChanged: debounce(function changedData(field, value) {
      if(field === 'gesellschaft'){
        this.getPoliceNummerValidation(value);
        if(this.isFPPlus) {
          this.getAnbindungComboboxValues(value)
        }
      }
      if(field === 'nummer') {
        this.validateNummer();
      }
      if(field === 'laufzeit') {
        if(this.versicherung.beginn && value){
          let beginnDate = dayjs(this.versicherung.beginn);
          this.versicherung.ende = beginnDate.add(parseInt(value), 'year').format('DD.MM.YYYY');
        }
      }
      if(field === 'beginn') {
        this.versicherung.anzeigeModusDauer = 'LAUFZEIT';
        if(!value){
          this.versicherung.laufzeit = null;
        }
      }

      if(field === 'anbindung') {
        this.versicherung[field] = value;
        this.updateBetreuungsstufeValue(value);
      }
    }, 600),
    formatDate(date) {
      if(date && !dayjs(date, 'DD.MM.YYYY').isValid()){
        return dayjs(date).format('DD.MM.YYYY');
      }
      return date;
    },
    getAnbindungComboboxValues(value) {
      if(value) {
        const payload = {gesellschaftId: value, groupId: this.categorySelected.group}
        this.isLoading = true;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES, payload).then(result => {
          if(result?.anbindung?.length){
            const firstAnbindungValue = result.anbindung[0].value;
            if('FPPLUS_GBR' !== firstAnbindungValue){
              this.$set(this.versicherung, 'anbindung', firstAnbindungValue)
            }
            this.updateBetreuungsstufeValue(firstAnbindungValue);
          }
          this.isLoading = false;
        })
      } else {
        this.versicherung.nummer = '';
      }
    },
    updateBetreuungsstufeValue(value){
      const betreungsstufeKFZ = this.vertragsdatenFieldsDefinition.betreuungsstufe?.value?.filter(item => item.value === 'KFZ');
      const betreungsstufePLATIN = this.vertragsdatenFieldsDefinition.betreuungsstufe?.value?.filter(item => item.value === 'PLATIN');
      if(value === FPPLUS_GEWERBE && this.categorySelected?.groupLabel?.includes('KFZ') ) {;
        if(betreungsstufeKFZ?.length){
          this.$set(this.versicherung, 'betreuungsstufe', betreungsstufeKFZ[0]?.value);
        } else {
          this.$set(this.versicherung, 'betreuungsstufe', betreungsstufePLATIN[0]?.value);
        }
      } else if(value == FPPLUS_GEWERBE) {
        this.$set(this.versicherung, 'betreuungsstufe', betreungsstufePLATIN[0]?.value);
      } else {
        this.$set(this.versicherung, 'betreuungsstufe', 'Ohne')
      }
    },
    updateEndDate(){
      if(this.versicherung.anzeigeModusDauer === 'LAUFZEIT' && !this.versicherung.laufzeit) {
        this.versicherung.ende = '';
      }
    }
  },
  mounted() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        {
          groupId: this.categorySelected?.group,
        }
      );

    if (this.categorySelected?.group) {
      this.loadVertragsDaten(this.categorySelected.group)
      this.loadGesellschaftList(this.categorySelected.group, this.versicherung.displayFremdges)
      this.$configureValidators(this.fieldsValidators)
    }
    if (this.categorySelected?.versicherung) {
      Object.assign(this.versicherung, this.categorySelected?.versicherung);
    }
    this.$refs.addVersicherungModal.open()
  }
}
</script>
<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>
