var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Gesellschaftsinfos",
          subtitle: _vm.gesellschaftName,
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.actions
        }
      }),
      _vm.gesellschaftsInfos
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "PLZ", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.plz,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "plz", $$v)
                      },
                      expression: "gesellschaftsInfos.plz"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Ort", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.org,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "org", $$v)
                      },
                      expression: "gesellschaftsInfos.org"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Strasse", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.strasse,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "strasse", $$v)
                      },
                      expression: "gesellschaftsInfos.strasse"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Strasse", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.strasse,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "strasse", $$v)
                      },
                      expression: "gesellschaftsInfos.strasse"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Land", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.land,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "land", $$v)
                      },
                      expression: "gesellschaftsInfos.land"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Postfach", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.postfach,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "postfach", $$v)
                      },
                      expression: "gesellschaftsInfos.postfach"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "PLZ für Postfach", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.plzPostfach,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "plzPostfach", $$v)
                      },
                      expression: "gesellschaftsInfos.plzPostfach"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Beschreibung", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.beschreibung,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "beschreibung", $$v)
                      },
                      expression: "gesellschaftsInfos.beschreibung"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Vermittlernummer", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.vermittlerNummer,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.gesellschaftsInfos,
                          "vermittlerNummer",
                          $$v
                        )
                      },
                      expression: "gesellschaftsInfos.vermittlerNummer"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Vermittlernr. AK", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.vermittlerNummerAK,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.gesellschaftsInfos,
                          "vermittlerNummerAK",
                          $$v
                        )
                      },
                      expression: "gesellschaftsInfos.vermittlerNummerAK"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { label: "Hauptnummer", disabled: "" },
                    model: {
                      value: _vm.gesellschaftsInfos.hauptNummer,
                      callback: function($$v) {
                        _vm.$set(_vm.gesellschaftsInfos, "hauptNummer", $$v)
                      },
                      expression: "gesellschaftsInfos.hauptNummer"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "3ff275df-3245-4ea1-872f-c91731ab6962",
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "telefon",
                      fn: function(row) {
                        return [
                          _vm.isTelefon
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.telefon)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tapiCall(row.telefon)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.telefon))]
                              )
                            : _c(
                                "label",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.telefon)
                                  }
                                },
                                [_vm._v(_vm._s(row.telefon))]
                              )
                        ]
                      }
                    },
                    {
                      key: "mobil",
                      fn: function(row) {
                        return [
                          _vm.isTelefon
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.mobil)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tapiCall(row.mobil)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.mobil))]
                              )
                            : _c(
                                "label",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.mobil)
                                  }
                                },
                                [_vm._v(_vm._s(row.mobil))]
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3942056681
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  content: "Keine Ansprechpartner hinterlegt"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }