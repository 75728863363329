<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Wertpapiersuche" :defaultMenu="customOptionMenu"/>
    
    <div class="box__container">
      <div class="box__title">Umtausch von:</div>
      <ComboBox
        v-model="bgsNr" 
        :values="depotComboboxValues"
        @change="bgsNrSourceChanged"
      />
      <div class="row">
        <div class="col-12 d-flex my-2 anteile" v-if="anteileInfo">
          <span class="mr-3">Anteile: {{anteileInfo['anteile']}}</span>
          <span class="mr-3">Anteilspreis: {{anteileInfo['anteilspreis']}}<ph-currency-eur :size="16" /></span>
          <span>Gesamter Wert: {{anteileInfo['gesamterWert']}}<ph-currency-eur :size="16" /></span>
        </div>
      </div>
    </div>

    <div class="box__container" v-if="modus === 'depotpossuche'">
      <div class="box__title">Umtausch nach:</div>
      <ComboBox
        v-model="bgsNrTarget" 
        :values="bgsNrTargetValues"
      />
    </div>
    
    <template v-if="modus === 'wpsuche'">
      <FondsFinderSuchMaske/>

      <FondsFinderPositionsList 
        ref="fondsfinderResult"
        :lagerstelle="lagerstelle"
        :goBackEnabled="false"
        :hasDefaultButton="false"
        showMobileSelectionAlways
      />
    </template>

    <div class="box__container">
      <BaseButton @click="addTauschPositions()">
        Umtauschen
      </BaseButton>
    </div>

    <BaseModal
      ref="confirmAddPositions"
      modalTitle="Wertpapier hinzugefügt!"
      labelButtonConfirm="Ok"
      :showCancelButton="false">
      <label>Wertpapier hinzugefügt!</label>
    </BaseModal>

    <BaseModal
      ref="emptySelection"
      modalTitle="Kein Auswahl"
      labelButtonConfirm="Ok"
      :showCancelButton="false">
      <label>Bitte wählen Sie eine Position aus.</label>
    </BaseModal>
  </div>
</template>

<script>
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import BaseModal from "@/components/core/BaseModal.vue";
import mixin from '@/mixins/wertpapierorder/mixin.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import {formatNumber} from '@/helpers/number-formatter';
import { PhCurrencyEur } from 'phosphor-vue';

export default {
    mixins: [mixin],
    components: {
        FondsFinderSuchMaske,
        FondsFinderPositionsList,
        ComboBox,
        BaseModal,
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseButton,
        PhCurrencyEur,
    },
    data() {
        return {
            bgsNr: {},
            bgsNrTarget: '',
            modus: 'wpsuche',
        };
    },
    watch: {
      depotNr(value) {
        if (value && value !== 'freie Eingabe:' && this.lagerstelle && this.depots?.length) {
          this.bgsNr = this.getDepot(value)?.bgsNr || ''; 
        } else {
          this.bgsNr = '';
        }
      },
    },
    computed: {
        depotNr() {
            return this.data && this.data['depotNr'];
        },
        anteileInfo() {
          const info = {};
          if (this.bgsNr && this.depots) {
            const depot = this.getDepot(this.depotNr, this.bgsNr) || {};
            info.anteile = formatNumber(depot['anteile'], 6);
            info.anteilspreis = formatNumber(depot['anteilspreis'], 2);
            info.gesamterWert = formatNumber(depot['gesamterWert'], 2);
          }
          return info;
        },
        bgsNrTargetValues() {
          let selection = this.depotComboboxValues.filter(value => value.bgsNr !== this.bgsNr)
          selection.unshift({label: 'Keine Auswahl', value: ''})
          return selection
        },
        customOptionMenu() {
          return [
            {
              label: 'Wertpapierauswahl',
              textBold: () => this.modus === 'wpsuche',
              action: () => {
                this.modus = 'wpsuche'
              }
            },
            {
              label: 'Depotpositionsauswahl',
              textBold: () => this.modus === 'depotpossuche',
              action: () => {
                this.modus = 'depotpossuche'
              }
            },
          ]
        },
    },
    mounted() {
        if (this.lagerstelle && this.depots?.length) {
         this.bgsNr = Number(this.$route.query?.bgs);
        }
    },
    methods: {
        bgsNrSourceChanged(bgsNrSource) {
          if (bgsNrSource === this.bgsNrTarget) {
            this.bgsNrTarget = ''
          }
        },
        goBack() {
          this.$goToLastPage();
        },
        addPositions(data) {
            const depot = this.depots?.find(depot => depot.bgsNr ===  this.bgsNr);
            if (data.isin && depot) {
                const position = Object.assign({}, depot);
                position.betragModus = this.lagerstelle === 'FODB' ? 'VALUE_BETRAG_MODUS_ANTEILE' : 'VALUE_BETRAG_MODUS_ALLE_ANTEILE'
                position.positionTyp = 'ALT_TAUSCH';
                position.tauschInISIN = data.isin;
                position.lagerstelle = this.lagerstelle;
                position.tauschInWertpapiername = this.getWertpapiernameStr({ isin: data.isin, wertpapiername: data.wertpapiername, wkn: data.wkn, sri: data.sri })
                position.tauschInBgsNr = data.bgsNrTarget

                // this action already saves
                this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.ADD_POSITIONS, { positions: [position] });
                this.goBack()
            }
        },
        addTauschPositions() {
          let data = null;

          if (this.modus === 'wpsuche') {
            const fondTarget = this.$refs.fondsfinderResult?.getSelected();

            if (fondTarget) {
              const fondTargetIsin = fondTarget.checkPositions?.isins && Object.keys(fondTarget.checkPositions.isins).length && Object.keys(fondTarget.checkPositions.isins)[0]
              
              if (fondTargetIsin) {
                const info = fondTarget.positionInfo?.find(elem => elem.isin == fondTargetIsin) || {};
                data = {
                  isin: fondTarget.checkPositions?.isins && Object.keys(fondTarget.checkPositions.isins).length && Object.keys(fondTarget.checkPositions.isins)[0],
                  wertpapiername: info.wertpapiername,
                  wkn: info.wkn,
                  sri: info.sri,
                }
              }

            }

          } else if (this.modus === 'depotpossuche') {
            if (this.bgsNrTarget) {
              const depotPosTarget = this.depots?.find(depot => depot.bgsNr ===  this.bgsNrTarget);

              if (depotPosTarget) {
                data = {
                  bgsNrTarget: depotPosTarget.bgsNr,
                  isin: depotPosTarget.isin,
                  wertpapiername: depotPosTarget.wertpapiername,
                  wkn: depotPosTarget.wkn,
                  sri: depotPosTarget.sri,
                }
              }
            }
          }

          if (data) {
            this.addPositions(data);
          } else {
            this.$refs.emptySelection.open();
          }
        }
    },
}
</script>

<style>
    .anteile {
        font-weight: bold;
        font-size: 18px;
    }
</style>