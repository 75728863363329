var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Dokumente für den Antrag",
          subtitle: _vm.formLabel,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.createDocumentGroup()
          }
        }
      }),
      _c(
        "SortableList",
        {
          attrs: { items: _vm.documentGroups },
          on: {
            orderChanged: function($event) {
              return _vm.onOrderChanged($event)
            }
          }
        },
        [
          _c(
            "ul",
            {
              staticClass: "list-bordered",
              attrs: { "data-sortable-container": "" }
            },
            _vm._l(_vm.documentGroups, function(documentGroup) {
              return _c(
                "li",
                { key: documentGroup.id, attrs: { "data-sortable-item": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c(
                        "div",
                        { staticClass: "group__bar" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mr-3",
                              on: {
                                click: function($event) {
                                  documentGroup.visible = !documentGroup.visible
                                }
                              }
                            },
                            [
                              documentGroup.visible
                                ? _c("PhCaretDown", {
                                    attrs: { size: "20", weight: "bold" }
                                  })
                                : _c("PhCaretUp", {
                                    attrs: { size: "20", weight: "bold" }
                                  })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "group__title ellipsis" }, [
                            _vm._v(
                              _vm._s(documentGroup.bezeichnung) +
                                _vm._s(_vm.getDocumentTypeLabel(documentGroup))
                            )
                          ]),
                          _c("Pill", {
                            attrs: {
                              label: _vm.getPillLabelForGroup(documentGroup),
                              type: _vm.getPillTypeForGroup(documentGroup)
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "group__bar__right" },
                            [
                              _c(
                                "BaseDropdownMenu",
                                {
                                  staticClass: "mr-2",
                                  attrs: { placement: "bottom-right" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "hook-target",
                                        fn: function() {
                                          return [
                                            _c("ph-dots-three-vertical", {
                                              staticClass: "clickable",
                                              attrs: {
                                                size: "24",
                                                weight: "bold"
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "action-texts" },
                                    [
                                      _vm._l(
                                        _vm.getGroupActions(documentGroup),
                                        function(action) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: action.key,
                                                staticClass:
                                                  "btn btn-clean clickable",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleGroupAction(
                                                      action,
                                                      documentGroup
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(action.icon, {
                                                  tag: "component",
                                                  staticClass: "mr-1",
                                                  attrs: { size: 24 }
                                                }),
                                                _vm._v(_vm._s(action.label))
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { attrs: { "data-sortable-item-handler": "" } },
                                [
                                  _c("ph-list", {
                                    attrs: { size: 28, weight: "bold" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.rows(documentGroup).length && documentGroup.visible
                        ? _c("Table", {
                            attrs: {
                              hidePagination: "",
                              headers: _vm.headers,
                              rows: _vm.rows(documentGroup),
                              mobileConfig: {
                                title: null,
                                headers: ["version", "status"]
                              }
                            },
                            on: {
                              action: function($event) {
                                return _vm.handleTableAction(
                                  $event,
                                  documentGroup
                                )
                              }
                            }
                          })
                        : documentGroup.visible
                        ? _c("NoData", {
                            attrs: { content: "Keine Versionen vorhanden" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "releaseModal",
          attrs: {
            labelButtonConfirm: "Veröffentlichen",
            confirmDisabled: _vm.isDateBeforeNow(
              _vm.createDocumentForm.releaseDate
            )
          },
          on: { onConfirmButton: _vm.onConfirmReleaseModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Dokument veröffentlichen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              value: _vm.selectedGroup && _vm.selectedGroup.bezeichnung,
              isComponentHalfSize: "",
              disabled: ""
            }
          }),
          _c("InputField", {
            attrs: { label: "Version", isComponentHalfSize: "", disabled: "" },
            model: {
              value: _vm.createDocumentForm.version,
              callback: function($$v) {
                _vm.$set(_vm.createDocumentForm, "version", $$v)
              },
              expression: "createDocumentForm.version"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.createDocumentForm.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.createDocumentForm, "bemerkung", $$v)
              },
              expression: "createDocumentForm.bemerkung"
            }
          }),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Veröffentlichungsdatum")
          ]),
          _c("InputToggleSwitch", {
            attrs: { label: "sofort", isValueAsString: "" },
            model: {
              value: _vm.createDocumentForm.releaseNow,
              callback: function($$v) {
                _vm.$set(_vm.createDocumentForm, "releaseNow", $$v)
              },
              expression: "createDocumentForm.releaseNow"
            }
          }),
          !_vm.createDocumentForm.releaseNow
            ? _c("DatePickerField", {
                attrs: { label: "oder am", isValueAsString: "" },
                model: {
                  value: _vm.createDocumentForm.releaseDate,
                  callback: function($$v) {
                    _vm.$set(_vm.createDocumentForm, "releaseDate", $$v)
                  },
                  expression: "createDocumentForm.releaseDate"
                }
              })
            : _vm._e(),
          _vm.isDateBeforeNow(_vm.createDocumentForm.releaseDate)
            ? _c("div", { staticClass: "fc-form-danger" }, [
                _vm._v("Datum darf nicht in der Vergangenheit sein")
              ])
            : _c("div", { staticClass: "fc-form-danger" }, [
                _vm._v(
                  "Nach der Veröffentlichung eines Dokuments sind keine Änderungen mehr möglich"
                )
              ])
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createDocumentGroupModal",
          attrs: {
            labelButtonConfirm: _vm.createDocumentGroupData.id
              ? "Speichern"
              : "Erstellen",
            modalTitle: _vm.createDocumentGroupData.id
              ? "Dokument umbenennen"
              : "Dokument erstellen",
            confirmDisabled: !_vm.createDocumentGroupData.bezeichnung
          },
          on: { onConfirmButton: _vm.onConfirmCreateDocumentGroupModal }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              renderDanger: !_vm.createDocumentGroupData.bezeichnung
            },
            model: {
              value: _vm.createDocumentGroupData.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.createDocumentGroupData, "bezeichnung", $$v)
              },
              expression: "createDocumentGroupData.bezeichnung"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Dokumenttyp", values: _vm.documentTypeValues },
            model: {
              value: _vm.createDocumentGroupData.documentType,
              callback: function($$v) {
                _vm.$set(_vm.createDocumentGroupData, "documentType", $$v)
              },
              expression: "createDocumentGroupData.documentType"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "disclaimerModal",
          attrs: {
            labelButtonCancel: "Abbrechen",
            labelButtonConfirm: "Akzeptieren"
          },
          on: { onConfirmButton: _vm.confirmDisclaimer },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Wichtiger Hinweis ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.selectedGroup &&
                  _vm.sanitize(_vm.selectedGroup.disclaimerText)
              )
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }