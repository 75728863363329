var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { id: _vm.$appNavigation.currentOptionMenuId, title: _vm.title }
      }),
      _c("BaseFilter", {
        attrs: {
          title: _vm.title,
          filterId: _vm.title,
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                staticStyle: { clear: "both" },
                attrs: {
                  selectable: "",
                  tableId: "38b4386f-c591-4165-b3d9-1c1644592596",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  headerActions: _vm.headerActions,
                  initialPageSize: _vm.minNumRows
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  selectedRow: _vm.onRowSelection,
                  selectedRows: _vm.onAllRowsSelection,
                  "action-READ": _vm.actionRead,
                  "action-RESULT": _vm.actionResult,
                  "headerAction-MARKIERTE_POSITIONEN_LOESCHEN":
                    _vm.deleteSelected
                },
                scopedSlots: _vm._u([
                  {
                    key: "downloadFile",
                    fn: function(row) {
                      return [
                        _c(
                          "DownloadLink",
                          {
                            attrs: {
                              title: _vm.looksLikePDFFile(row)
                                ? "Datei in neuem Tab öffnen"
                                : "Herunterladen",
                              downloadServicePath: "/bipro_courtage_file",
                              filename: row.fileName,
                              queryParams: {
                                id: row.id
                              }
                            }
                          },
                          [
                            _vm.looksLikePDFFile(row)
                              ? _c("PhFilePdf", { attrs: { size: 16 } })
                              : _c("PhDownloadSimple", { attrs: { size: 16 } })
                          ],
                          1
                        ),
                        _vm._v("  "),
                        _vm.looksLikePDFFile(row)
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.fetchObject(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.fileName))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.fileName))])
                      ]
                    }
                  },
                  {
                    key: "zusatz",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: { value: row.zusatz },
                          on: {
                            change: function($event) {
                              return _vm.saveZusatz(row, $event)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            : _c("NoData")
        ],
        1
      ),
      _c("PreviewObject", {
        ref: "resizablePopup",
        attrs: {
          startPosition: "TOP_RIGHT",
          fullClientHeight: true,
          modalTitle: _vm.modalTitle,
          objectUrl: _vm.objectUrl,
          id: "e3fe3d48-88a8-48f3-9c14-caaefce1a233"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }