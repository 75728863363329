<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation title="Kredite"/>
    <Credit hideNavigation />
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Credit from '@/views/home/Credit.vue';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Credit,
  },
}
</script>
