<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      :subtitle="isFA ? 'Dienstleistungsvertrag' : 'Honorar / Serviceleistung'" 
      :actions="headerActions" 
      @action-DISCARD="discardChanges()" 
      @action-DELETE="deleteFeeProperties()" 
      @action-COPY="copyFeeProperties()" 
    />

    <template v-if="isSmallOrMediumScreen">
      <GhostLoading v-if="loading" type="block" :config="{ block: { height: '120px', }, }" useBoxContainer />
      <ConfigStepper3 
        v-else
        ref="stepper"
        :configStep="configStep"
        :selectedStep="selectedStep"
        :configData="configData"
        @setStep="setSelectedStep"
      />
    </template>
    <ContentWithStepper v-else :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigVerticalStep
          ref="stepper"
          :configStep="configStep"
          :configData="configData"
          :selectedStep="selectedStep"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
        />
      </template>

      <template #content>
        <div>
          <Vertragsdaten v-if="selectedStep === 'vertragsdaten'" />
          <GebuehrenFinanzservicevertrag v-else-if="selectedStep === 'gebuehren_finanzservicevertrag'" />
          <Gebuehren v-else-if="selectedStep === 'gebuehren'" />
          <Provisionserstattung v-else-if="selectedStep === 'provisionserstattung'" />
          <Dienstleistungsvertrag v-else-if="selectedStep === 'dienstleistungsvertrag'" />
          <Bankdaten v-else-if="selectedStep === 'bankdaten'" />
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal
      ref="editModal"
      :modalTitle="editModalTitle"
      size="lg"
      :showDefaultButtons="false"
      :actions="modalActions"
      @action-DISCARD="discardChanges()"
      @close="saveChanges"
    >
      <template #default>
        <div>
          <Vertragsdaten v-if="selectedStep === 'vertragsdaten'" />
          <GebuehrenFinanzservicevertrag v-else-if="selectedStep === 'gebuehren_finanzservicevertrag'" />
          <Gebuehren v-else-if="selectedStep === 'gebuehren'" />
          <Provisionserstattung v-else-if="selectedStep === 'provisionserstattung'" />
          <Dienstleistungsvertrag v-else-if="selectedStep === 'dienstleistungsvertrag'" />
          <Bankdaten v-else-if="selectedStep === 'bankdaten'" />
        </div>
      </template>
    </BaseModal>

    <BaseModal
      ref="confirmationModal"
      modalTitle="Bestätigung"
      labelButtonConfirm="Ok"
      :showCancelButton="true"
      @onConfirmButton="onConfirmModal"
    >
      <label>Das Formular ist fehlerhaft und kann nicht gespeichert werden, bevor Sie es korrigiert haben. Wollen Sie wirklich gehen?</label>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import FEE_TYPES from '@/store/fee/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigVerticalStep from '@/components/core/configs/ConfigVerticalStep.vue';
import ConfigStepper3 from '@/components/core/configs/ConfigStepper3.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import Vertragsdaten from './steps/Vertragsdaten.vue';
import Gebuehren from './steps/Gebuehren.vue';
import Provisionserstattung from './steps/Provisionserstattung.vue';
import Dienstleistungsvertrag from './steps/Dienstleistungsvertrag.vue';
import Bankdaten from './steps/Bankdaten.vue';
import GebuehrenFinanzservicevertrag from './steps/GebuehrenFinanzservicevertrag.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseModal from '@/components/core/BaseModal.vue';
import { BaseModalSimpleAction, } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  data() {
    return {
      selectedStep: null,
      stepperCollapsed: false,
      loading: false,
      next: null,
    };
  },
  computed: {
    ...mapGetters({
      feeProperties: FEE_TYPES.GETTERS.FEE_PROPERTIES,
      feePropertiesEdited: FEE_TYPES.GETTERS.FEE_PROPERTIES_EDITED,
      feePropertiesCombos: FEE_TYPES.GETTERS.FEE_PROPERTIES_COMBOS,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      fehlerhaft: FEE_TYPES.GETTERS.FEHLERHAFT,
      
    }),
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    editModalTitle() {
      const { configStep, selectedStep } = this;
      return configStep?.find(step => step.stepKey === selectedStep)?.label || 'Einstellung';
    },
    istMargitLang() {
      return !!this.feePropertiesCombos?.istMargitLang;
    },
    hasFeeProperties() {
      return !!this.feeProperties?.exists;
    },
    headerActions() {
      if (this.isCustomer) {
        const { loading, hasDataChanged, hasFeeProperties, isSmallOrMediumScreen } = this;
        return [
          PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged).withVisible(() => !isSmallOrMediumScreen),
          PageHeaderSimpleAction('DELETE', 'Löschen').withDisabled(() => loading || !hasFeeProperties),
          PageHeaderSimpleAction('COPY', 'Von Standard kopieren').withDisabled(() => loading),
        ];
      }

      return [];
    },
    modalActions() {
      if (this.isCustomer) {
        const { hasDataChanged, } = this;
        return [
          BaseModalSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged),
        ];
      }

      return [];
    },
    configStep() {
      let index = 0;
      return [
        {
          label: 'Vertragsdaten',
          stepKey: 'vertragsdaten',
          index: index++,
        },
        {
          label: 'Gebühren Finanzservicevertrag',
          stepKey: 'gebuehren_finanzservicevertrag',
          index: index++,
          hidden: true,
        },
        {
          label: 'Gebühren',
          stepKey: 'gebuehren',
          index: index++,
          hidden: true,
        },
        {
          label: 'Provisionserstattung',
          stepKey: 'provisionserstattung',
          index: index++,
          hidden: true,
        },
        {
          label: 'Dienstleistungsvertrag',
          stepKey: 'dienstleistungsvertrag',
          index: index++,
          hidden: true,
        },
        {
          label: 'Bankdaten',
          stepKey: 'bankdaten',
          index: index++,
        },
      ];
    },
    configData() {
      return {};
    },
    hasDataChanged() {
      return Object.keys(this.feePropertiesEdited || {}).length > 0;
    },
  },
  watch: {
    feePropertiesCombos: {
      handler() {
        if(Object.keys(this.feePropertiesCombos).length > 0) {
          this.configureSteps();
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      if (!this.isSmallOrMediumScreen) {
        this.setSelectedStep(this.configStep[0].stepKey);
      }

      this.$store.dispatch(FEE_TYPES.ACTIONS.FIND_FEE_PROPERTIES_COMBOS);
      this.$store.dispatch(FEE_TYPES.ACTIONS.GET_FEE_PROPERTIES);
    },
    configureSteps() {
      this.showStep('gebuehren_finanzservicevertrag', this.istMargitLang);
      this.showStep('gebuehren', !this.isFA);
      this.showStep('provisionserstattung', !this.istMargitLang && !this.isFA);
      this.showStep('dienstleistungsvertrag', this.isFA);
    },
    showStep(stepKey, show = false) {
      const step = this.configStep.find((s) => s.stepKey === stepKey);
      step.hidden = !show;
      this.$refs.stepper.setStepHidden(stepKey, !show);
    },
    discardChanges() {
      this.$store.commit(FEE_TYPES.MUTATIONS.RESET_FEE_PROPERTIES_EDITED);
    },
    async saveChanges() {
      await this.$store.dispatch(FEE_TYPES.ACTIONS.SAVE_FEE_PROPERTIES);
    },
    async setSelectedStep(step) {
      try {
        await this.saveChanges();
      } catch (error) {
        this.$store.commit(FEE_TYPES.MUTATIONS.SET_FEHLERHAFT, true);
      } finally {
        // Always let the user changing the step
        this.selectedStep = step;
      }

      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal?.open();
      }
    },
    deleteFeeProperties() {
      this.$confirmModal({
        message: `Möchten Sie wirklich die gesamten Einstellungen zu Honorar- und Serviceleistungen löschen?`,
        title: 'Einstellungen löschen',
        labelButtonConfirm: 'Löschen'
      })
      .then(async () => {
        try {
          this.loading = true;
          await this.$store.dispatch(FEE_TYPES.ACTIONS.DELETE_FEE_PROPERTIES);
        } finally {
          this.loading = false;
        }
      });
    },
    async copyFeeProperties() {
      try {
        this.loading = true;
        await this.$store.dispatch(FEE_TYPES.ACTIONS.COPY_FEE_PROPERTIES);
      } finally {
        this.loading = false;
      }
    },
    onConfirmModal() {
      this.next();
    }
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {

    try {
      await this.saveChanges();
    } catch (error) {

      this.$store.commit(FEE_TYPES.MUTATIONS.SET_FEHLERHAFT, true);
      this.next = next;
      this.$refs.confirmationModal.open();
      
    } 

    if (!this.fehlerhaft) {
      this.$store.commit(FEE_TYPES.MUTATIONS.RESET_STATE);
      next();
    }

  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ContentWithStepper,
    ConfigVerticalStep,
    ConfigStepper3,
    GhostLoading,
    Vertragsdaten,
    Gebuehren,
    Provisionserstattung,
    Dienstleistungsvertrag,
    Bankdaten,
    GebuehrenFinanzservicevertrag,
    BaseModal
  },
}
</script>
