<template>
    <div v-if="loginName">
        <div class="box__container">
            <div class="box__title">
                Zugangsinformation
            </div>
            <div class="info"><div class="infoLabel">Login Name:</div><a @click="changeInfo = 'loginName'">{{loginName}} <PhPencil :size="16"/></a></div>
            <div class="info"><div class="infoLabel">Telefonnummer:</div><a @click="changeInfo = 'phoneNumber'">{{phoneNumber}} <PhPencil :size="16"/></a></div>
            <div class="info"><div class="infoLabel">E-Mail Adresse:</div><a @click="changeInfo = 'email'">{{email}} <PhPencil :size="16"/></a></div>
            <div class="info"><div class="infoLabel"></div><a @click="changeInfo = 'password'">Passwort ändern <PhPencil :size="16"/></a></div>
            <ChangeLoginNameModal v-if="changeInfo == 'loginName'" @close="changeInfo = null"/>
            <ChangePhoneNumberModal v-if="changeInfo == 'phoneNumber'" @close="changeInfo = null"/>
            <ChangeEmailModal v-if="changeInfo == 'email'" @close="changeInfo = null"/>
            <ChangePasswordModal v-if="changeInfo == 'password'" @close="changeInfo = null"/>
        </div>

        <div class="box__container" v-if="!activated">
            <div class="box__title">Single-Sign-On Zugang aktivieren</div>
            <form v-on:submit.prevent="activateSsoAccount">
                <InputField label="E-Mail Code" v-model="emailToken"/>
                <InputField label="SMS Code" v-model="smsToken"/>
                <BaseButton @click="requestEmailCode" :disabled="tokenButtonsDisabled" isSecondary>E-Mail Code erneut senden</BaseButton>
                <BaseButton @click="requestSMSCode" :disabled="tokenButtonsDisabled" isSecondary>SMS Code erneut senden</BaseButton>
                <br><br>
                <BaseButton type="submit" :disabled="!emailToken || !smsToken" isPrimary>Aktivieren</BaseButton>
            </form>
        </div> 

        <div class="box__container">
            <div class="box__title">
                Ihre Zugänge
            </div>
            <Table v-if="fkAccounts && fkAccounts.length"
                tableId="fc5b97e6-e605-4281-9be0-25d5dc9c7dfe"
                :headers="fkHeaders"
                :rows="fkRows"
                hidePagination
                @click-displayedName="loginFk($event.id)"
                @action-DELETE="fkDelete"
            >
                <template v-slot:isDefault="row">
                    <input type="radio" :checked="row.isDefault || (fkAccounts && fkAccounts.length === 1)" @click="changeDefaultFkAccess(row)"/>
                </template>
            </Table>
            <div v-else>
                Keine Zugänge.
            </div>
        </div>

        <div class="box__container">
            <div class="box__title">An Sie gerichtete Einladungen</div>
            <Table  v-if="ssoInvitations && ssoInvitations.length"
                tableId="fc5b97e6-e605-4281-9be0-25d5dc9c7df2"
                :headers="invitationHeaders"
                :rows="invitationRows"
                @action-DELETE="deleteInvitation"
                hidePagination
            >
                <template v-slot:accept="row">
                    <BaseButton @click="acceptInvitation(row)" isLink v-fc-tooltip="'Einladung akzeptieren'" >
                        <PhCheck :size="16" class="color-success mr-1" /> 
                        <span class="color-success">akzeptieren</span>
                    </BaseButton>
                </template>
            </Table>
            <div v-else>
                Keine Einladungen.
            </div>

            <SsoInvitationModal v-if="ssoInvitation" :invitation="ssoInvitation" @close="ssoInvitation = null"/>
        </div>

        <BaseModal ref="errorModal" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeErrorModal">
            <template v-slot:modalTitle>
                <PhInfo style="color: orange" />
                Fehler
            </template>
            <div>
                {{errorStr}}
            </div>
        </BaseModal>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';

import { mapGetters } from 'vuex';
import { PhTrash, PhPencil, PhCheck } from "phosphor-vue";
import {TextColumn, ActionColumn, DateColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";

import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import Table from "@/components/table2/Table.vue";
import SsoInvitationModal from '@/components/core/sso/SsoInvitationModal.vue'
import ChangeLoginNameModal from '@/components/core/sso/ChangeLoginNameModal.vue'
import ChangePhoneNumberModal from '@/components/core/sso/ChangePhoneNumberModal.vue'
import ChangeEmailModal from '@/components/core/sso/ChangeEmailModal.vue'
import ChangePasswordModal from '@/components/core/sso/ChangePasswordModal.vue'
import mixin from '@/mixins/sso/sso-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    mixins: [mixin],
    components: {
        PhPencil,
        PhCheck,
        InputField,
        BaseButton,
        Table,
        SsoInvitationModal,
        ChangeLoginNameModal,
        ChangePhoneNumberModal,
        ChangeEmailModal,
        ChangePasswordModal,
        BaseModal,
    },
    data() {
        return {
            emailToken: "",
            smsToken: "",
            tokenButtonsDisabled: false,
            changeInfo: null,
            errorStr: '',
            fkHeaders: {
                lockedLeft: [
                    SlotColumn("isDefault", "Standard", 100, 0).makeAlwaysVisible(),
                    TextColumn("displayedName", "Zugangsnummer").makeConditionalLink("notCurrently").makeAlwaysVisible(),
                    TextColumn("userName", "Name"),
                    TextColumn("permission", "Berechtigung"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            invitationHeaders: {
                lockedLeft: [
                    TextColumn("loginName", "Einladung").makeAlwaysVisible(),
                    SlotColumn("accept", "Akzeptieren", 150, 1).makeAlwaysVisible(),
                    TextColumn("permission", "Berechtigung"),
                ],
                center: [
                    DateColumn("validUntil", "Gültig bis"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            ssoInvitation: null,
        };
    },
    computed: {
        ...mapGetters({
            loginName: SSO_TYPES.GETTERS.LOGIN_NAME,
            phoneNumber: SSO_TYPES.GETTERS.PHONE_NUMBER,
            email: SSO_TYPES.GETTERS.EMAIL,
            activated: SSO_TYPES.GETTERS.IS_ACTIVATED,
            fkAccounts: SSO_TYPES.GETTERS.FK_ACCOUNTS,
            ssoInvitations: SSO_TYPES.GETTERS.SSO_INVITATIONS,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
        }),
        fkRows() {
            if (!this.fkAccounts)
                return [];
            const actions = [
                SimpleAction("DELETE", PhTrash, "Löschen"),
            ];
            return this.fkAccounts.map(record => {
                return {
                    ...record,
                    permission: record.permissionDescription && this.comboBerechtigung.find(item => item.value === record.permissionDescription)?.label || '',
                    notCurrently: this.userId != record.displayedName,
                    actions,
                };
            });
        },
        invitationRows() {
            if (!this.ssoInvitations)
                return [];
            const actions = [
                SimpleAction("DELETE", PhTrash, "Löschen"),
            ];
            return this.ssoInvitations.map(row => {
                return {
                    ...row,
                    actions,
                }
            })
        },
    },
    mounted() {
        this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
    },
    methods: {
        loginFk(accessId) {
            this.$store.dispatch(SSO_TYPES.ACTIONS.LOGIN_FK, accessId);
        },
        requestEmailCode() {
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE, null);
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        requestSMSCode() {
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_SMS_CODE, null);
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        activateSsoAccount() {
            if (!this.emailToken || !this.smsToken)
                return;
            this.$store.dispatch(SSO_TYPES.ACTIONS.ACTIVATE_ACCOUNT, {
                emailToken: this.emailToken,
                smsToken: this.smsToken,
            });
            this.emailToken = "";
            this.smsToken = "";
        },
        changeDefaultFkAccess(account) {
            this.$store.dispatch(SSO_TYPES.ACTIONS.SET_DEFAULT, account.isDefault ? null : account.id);
        },
        fkDelete(row) {
            this.$store.dispatch(SSO_TYPES.ACTIONS.DELETE_ACCESS_SSO, row.id);
        },
        deleteInvitation(row) {
            this.$store.dispatch(SSO_TYPES.ACTIONS.DELETE_INVITE_SSO, row);
        },
        acceptInvitation(row) {
            this.ssoInvitation = row;
        },
        closeErrorModal() {
            this.errorStr = null;
        },
    },
}
</script>

<style scoped>
.box__container {
    width: 100%;
}
.info {
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
}
.infoLabel {
    width: 130px;
}

@media screen and (max-width: 450px) {
    .info {
        display: flex;
        flex-direction: column;
    }
    .infoLabel {
        width: 100%;
    }
}

</style>