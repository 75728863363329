var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _vm.hasHeader
        ? [
            _c("OptionMenu", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                defaultMenu: _vm.$appNavigation.currentOptionMenu
              }
            }),
            _c("PageHeaderTitleNavigation", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                title: _vm.$route.query.lagerstelle
              }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("box__container") }
        },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(_vm._s(_vm.$route.query.lagerstelle))
          ]),
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              placeholder: "Suchen nach Bezeichnung"
            },
            model: {
              value: _vm.descriptionSearch,
              callback: function($$v) {
                _vm.descriptionSearch = $$v
              },
              expression: "descriptionSearch"
            }
          })
        ],
        1
      ),
      _vm.filteredLinks && _vm.filteredLinks.length
        ? _c(
            "div",
            { staticClass: "cards__container layout__negative-margin--8" },
            _vm._l(_vm.filteredLinks, function(linkGroup) {
              return _c(
                "div",
                {
                  key: linkGroup.groupLabel,
                  staticClass: "box__container cards__item",
                  attrs: {
                    tid: _vm._generateTidFromString(
                      "box__container cards__item" + linkGroup.groupLabel
                    )
                  }
                },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(_vm._s(linkGroup.groupLabel))
                  ]),
                  _vm._l(linkGroup.forms, function(linkItem) {
                    return _c("div", { key: linkItem.link }, [
                      _c(
                        "a",
                        { attrs: { target: "_blank", href: linkItem.url } },
                        [_vm._v(_vm._s(linkItem.label))]
                      )
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              useBoxContainer: "",
              type: "block",
              config: { block: { height: 280 } }
            }
          })
        : _c(
            "div",
            { staticClass: "box__container" },
            [_c("NoData", { attrs: { content: "Keine Formulare vorhanden" } })],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }