<template>
	<div>
		<OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

		<PageHeaderTitleNavigation 
			:id="$appNavigation.currentOptionMenuId" 
			title="Vertrag Wirtschaftsprüfung" 
			:actions="headerActions" 
            @action-TOGGLE-SIGNATURE="sendFormToElectronicSignature"
		/>

		<HinweiseUndFehler 
			ref="hinweiseUndFehler"
			v-if="hinweiseMessages.length || errors.length" 
			@set-step="setStep($event)"
			:hints="hinweiseMessages" 
			:errors="errors" />

		<ContentWithStepper :stepperCollapsed="stepperCollapsed">
			<template #stepper>
				<div class="box__container">
					<VerticalStepper
						stepType="tab"
						stepperName="VertragWP"
						:selectedStepKey="selectedStepKey"
						:stepperService="steps"
						:showCollapse="true"
						@setStep="setStep($event)"
						@collapsed="stepperCollapsed = $event">
					</VerticalStepper>
				</div>
			</template>
			<template #content>
				<div>
					<ContractData1 
						:formData="form"
						@fieldChanged="fieldChanged($event)"
						v-if="selectedStepKey === 'contractData1'"/>
					<ContractData2 
						:formData="form"
						@fieldChanged="fieldChanged($event)"
						@changeVtgArt34Modus="changeVtgArt34Modus($event)"
						v-if="selectedStepKey === 'contractData2'"/>
					<ContractData3 
						:formData="form"
						@fieldChanged="fieldChanged($event)"
						v-if="selectedStepKey === 'contractData3'"/>
					<ContractData4 
						:formData="form"
						@fieldChanged="fieldChanged($event)"
						v-if="selectedStepKey === 'contractData4'"/>

					<Summary 
						@fieldChanged="fieldChanged($event)"
						v-if="selectedStepKey === 'summary'"/>
				</div>

				<div class="box__container">
					<div>
						<div class="layout__negative-margin--8 d-flex">
							<BaseButton 
								v-if="selectedStepKey !== 'contractData1'"
								isSecondary 
								@click="onButtonPrevious()">
									Zurück 
							</BaseButton>

							<BaseButton 
								v-if="selectedStepKey !== 'summary'"
								@click="onButtonNext()">
									Weiter
							</BaseButton>

							<BaseBackButtonBreadcrumb 
								showAsButton 
								fixedLabel="Schließen"
								v-if="selectedStepKey === 'summary'"/>
						</div>
					</div>
				</div>
			</template>
		</ContentWithStepper>

	</div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'
import { mapGetters } from 'vuex';
import { sanitize } from '@/helpers/string-helper.js';
import { StepperMediator } from '@/components/stepper/utils';
import ContractData1 from '@/components/vertragWP/ContractData1.vue'
import ContractData2 from '@/components/vertragWP/ContractData2.vue'
import ContractData3 from '@/components/vertragWP/ContractData3.vue'
import ContractData4 from '@/components/vertragWP/ContractData4.vue'
import Summary from '@/components/vertragWP/Summary.vue'
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'
import { PageHeaderDownloadLinkHrefAction, PageHeaderSignoViewerLinkAction, PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {

	data() {
		return {
			
			form: {
				// isVorgelegt: false
			},

			stepperCollapsed: false,
			selectedStepKey: 'contractData1',

			someFieldHasChanged: false,
		}
	},

	watch: {
		formData(newValue) {
			this.$set(this, 'form', Object.assign({}, newValue, this.form))
		}
	},

	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			errorList: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
			getPDFLinkGenerator: VERTRAG_WP_TYPES.GETTERS.GET_PDF_LINK_GENERATOR,
			getSignoViewerLinkGenerator: VERTRAG_WP_TYPES.GETTERS.GET_SIGNO_VIEWER_LINK_GENERATOR,
			formData: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_FORM_DATA,
		}),
		errors() {
			return this.errorList;
		},
		headerActions() {
			return [
				PageHeaderDownloadLinkHrefAction('DOWNLOAD', 'PDF', 'Vertrag_Scan.pdf', this.getPDFLink).withDisabled(() => this.disableActionButtons),
				PageHeaderSignoViewerLinkAction('SIGN', 'PDF signieren', this.getSignoViewerLink).withDisabled(() => this.disableActionButtons),
				PageHeaderSimpleAction('TOGGLE-SIGNATURE', !this.form?.isVorgelegt ? 'zu e-Signatur vorlegen' : 'e-Signatur zurückziehen').withDisabled(() => this.disableActionButtons),
			];
		},
		hinweiseMessages() {
			if (!this.hinweise) {
				return []
			}
			return this.hinweise.map(hint => ({message: hint, title: ''}))
		},
		getPDFLink() {
			return this.formData?.id && this.getPDFLinkGenerator(this.formData.id, 'Vertrag_Scan.pdf')
		},

		getSignoViewerLink() {
			return this.formData?.id && this.getSignoViewerLinkGenerator(this.formData.id)
		},

		disableActionButtons() {
			return !this.formData?.id || this.selectedStepKey !== 'summary';
		},
		loading() {
			return this.duplicatedPeopleStatus.loading || this.unifiablePeopleStatus.loading
		},
		steps() {
			const result = [];

			for (let index = 1; index < 5; index++) {
				result.push({
					stepKey: 'contractData' + index, 
					label:'Vertragsdaten ' + index, 
					current: this.selectedStepKey === 'contractData' + index, 
					totalProgress: index});
			}

			result.push({
					stepKey: 'summary', 
					label:'Zusammenfassung', 
					current: this.selectedStepKey === 'summary', 
					totalProgress: result.length + 1});

			return new StepperMediator(result)
		},
	},

	methods: {
		signPDF() {

		},
		async sendFormToElectronicSignature() {
			await this.saveForm();

			if (this.formData?.id) {
				const response = await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN);
				this.form.isVorgelegt = response?.isVorgelegt;
			}
		},
		onButtonNext() {
			this.setStep(this.steps?.getNextStep(this.selectedStepKey))
		},
		onButtonPrevious() {
			this.setStep(this.steps?.getPreviousStep(this.selectedStepKey))
		},
		async loadHinweise() {
			try {
				this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP_HINWEIS)
			} catch (error) {
				//empty block
			} 
		},
		async loadVertragWP() {
			try {
				await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP)
			} catch (error) {
				//empty block
			} 
		},
		async changeVtgArt34Modus($event) {
			try {
				const param = { vtgArt34Modus: $event}
				await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP, param)
				this.fieldChanged(param)

			} catch (error) {
				//empty block
			} 
		},
		async loadVertragWPValues() {
			try {
				await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP_VALUES)
			} catch (error) {
				//empty block
			} 
		},
		
		sanitize(htmlString) {
			return sanitize(htmlString);
		},

		async saveForm() {
			if (this.someFieldHasChanged) {
				const formToSave = { ...this.form}
				this.$set(this, 'form', {})

				await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.SAVE_FORM, formToSave)

				if (!this.errors?.length) {
					this.someFieldHasChanged = false;
				}
			}
		},

		async setStep(step) {
			await this.saveForm()
			if (step === 'summary') {
				this.$refs.hinweiseUndFehler?.expand()
			} else {
				this.$refs.hinweiseUndFehler?.collapse()
			}

			this.selectedStepKey = step;
		},

		fieldChanged(form) {
			this.someFieldHasChanged = true
			this.$set(this, 'form', Object.assign({}, this.form, form))
		},
	},

	mounted() {
		this.loadHinweise();
		this.loadVertragWP();
		this.loadVertragWPValues();
	},

	async beforeRouteLeave(to, from, next) {
		await this.saveForm()

		if (!this.errors?.length) {
			this.$store.commit(VERTRAG_WP_TYPES.MUTATIONS.RESET_STATE)
			next()
		} else {
			this.$confirmModal({
        message: `Aufgrund von Fehlern konnte der Vertrag nicht gespeichert werden! Möchten Sie die Seite mirklich verlassen?`,
        title: 'Vertrag Wirtschaftsprüfung',
        labelButtonConfirm: 'Leave'
      })
        .then(async () => {
          next()
        });
		}
	},

	components: {
		OptionMenu,
		PageHeaderTitleNavigation,
		InputToggleSwitch,
		DownloadLink,
		SignoViewerLink,
		ContentWithStepper,
		VerticalStepper,
		ContractData1,
		ContractData2,
		ContractData3,
		ContractData4,
		Summary,
		HinweiseUndFehler,
		BaseButton,
		BaseBackButtonBreadcrumb,
	}
}
</script>

<style scoped>

.button__container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

</style>