<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="accessTitle" 
      :actions="headerActions" 
      @action-BACK="goBack()" 
      @action-MICROSOFT="getMicrosoftLink()" 
    />
   
    <div class="box__container">
      <ComboBox
        label="Servertyp"
        :values="types"
        v-model="form.type"
        @change="typeChanged($event)"
        :firstEmpty="false"
        isComponentHalfSize
      />
      <InputField label="Server" v-model="form.server" :disabled="isMicrosoft" isComponentHalfSize />
      <InputField label="Port" v-model="form.port" :disabled="isMicrosoft" isComponentHalfSize />
      <InputField label="Benutzer" v-model="form.name" id="form.name" isComponentHalfSize validateUntouched @input="updateMicrosoftConfirmed()"/>
      <InputField
        label="Passwort"
        v-model="form.passwd"
        :type="passwordType"
        :disabled="isMicrosoft"
        isComponentHalfSize
        @focus="handlePswField(true)"
      />
      <InputToggleSwitch
        label="SSL benutzen"
        :disabled="isMicrosoft"
        v-model="form.ssl"
      />
      <InputToggleSwitch inLineLabel
        label="Die E-Mails sind für alle Mitarbeiter sichtbar."
        v-model="form.freigeschaltet"
      />
      <InputToggleSwitch inLineLabel label="aktiv" v-model="form.aktiv" />
    </div>

    <BaseModal ref="errorModal" 
      labelButtonCancel="Abbrechen"
      labelButtonConfirm="Zurück ohne speichern"
      :showConfirmButton="true"
      @onCancelButton="$refs.errorModal.close()"
      @onCloseButton="$refs.errorModal.close()"
      @onConfirmButton="canLeavePage =true; goBack()">
      
      <template v-slot:accessTitle>
        Fehler
      </template>
      <div>
        Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche.
      </div>
    </BaseModal>
  </div>
</template>

<script>
import E_MAIL_ACCESS_TYPES from "@/store/eMailAccess/types";

import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import validator from "@/mixins/validator";
import emailAccessMixin from "@/mixins/email-access-mixin.js";
import { required } from "@/mixins/validator/rules";
import BaseModal from '@/components/core/BaseModal.vue';
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from '@/helpers/log-message-helper';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { trackBreadcrumbToCurrentRouteIfNeeded } from '@/router/breadcrumb/index';

export default {
  mixins: [validator, emailAccessMixin],
  validators: {
    form: {
      name: [required('Benutzername ist erforderlich!')]
    }
  },
  data() {
    return {
      types: [{label: "imap", value: 'imap'}, { label: 'pop3', value: "pop3"}, { label: "microsoft", value: 'msgraph'}],
      canLeavePage: false,
    };
  },
  computed: {
    headerActions() {
      const showZurueck = !this.isMicrosoft || this.microsoftConfirmed;
      return [
        PageHeaderSimpleAction('BACK', 'Zurück').withVisible(() => showZurueck),
        PageHeaderSimpleAction('MICROSOFT', '(Microsoft) Berechtigungen erteilen').withVisible(() => !showZurueck),
      ];
    },
    serverId() {
      return this.msGraphData?.id || this.$route.params.id;
    },
  },
  mounted() {
    this.$store.commit(E_MAIL_ACCESS_TYPES.MUTATIONS.RESET_MS_GRAPH);
    if (this.$route.query.result) {
      trackBreadcrumbToCurrentRouteIfNeeded({ fullPath: '/intern/ubersicht'});
      this.$addBreadcrumb({
        label: 'zurück zur Übersicht', 
        fullPath: '/intern/ubersicht',
        breadcrumb: 'E-Mail Zugang',
      });
      trackBreadcrumbToCurrentRouteIfNeeded({ fullPath: '/intern/email-access/eingang' });
      this.$addBreadcrumb({
        label: 'zurück zu E-Mail Zugänge', 
        fullPath: '/intern/email-access/eingang',
        breadcrumb: 'E-Mail Eingangsserver bearbeiten',
      });
      this.$store.commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, {
        type: 'msgraph',
        server: this.$route.query.INPUT_EINGANG_SERVER,
        name: this.$route.query.INPUT_EINGANG_USER,
        aktiv: "true" === this.$route.query.ACTIVE_CHECKBOX ? true : false,
        freigeschaltet: "true" === this.$route.query.SHARE_CHECKBOX ? true : false,
        passwd: '',
        port: '',
        ssl: true,
        microsoftConfirmed: this.$route.query.errorMsg ? false : true,
        id: this.$route.params.id,
      })
      if (this.$route.query.errorMsg) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.$route.query.errorMsg, 'danger', false));
      } else {
        setTimeout(() => this.doSave(), 500);
      }
      
    } else {
      if (this.serverId !== '0') {
        this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_MS_GRAPH_EMAIL_DATA, this.serverId || '');
      }
    }
  },
  methods: {
    getMicrosoftLink() {
      this.doSave();
    },
    async save() {
      if (this.validation.invalid){
        this.$refs.errorModal.open();
      } else if (this.isChanged()) {
        await this.doSave();
        this.canLeavePage = true;
      } else {
        this.canLeavePage = true;
      }
    },
    async doSave() {
      const data = {};
      Object.keys(this.form).forEach(key => data[key] = (typeof this.form[key]) === 'string' ? this.form[key]?.trim() : this.form[key]);
      await this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SET_E_MAIL_SERVER, data);
    },
    goBack() {
      this.$router.push('/intern/email-access/eingang?backAction=true');
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.save();
    if (this.canLeavePage) {
      next();
    }
  },
  components: {
    InputField,
    ComboBox,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputToggleSwitch,
    BaseModal,
  },
};
</script>
