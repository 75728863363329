<template>
  <div>

    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="$appNavigation.currentMenu.label" 
      :actions="headerActions" 
      @action-EDIT-ROWS="$refs.externeZugangBankConfigModal.open()" 
    />

    <div class="box__container">
        <Table v-if="!isLoading && rows.length"
            tableId="6deee8ba-8180-4eae-abeb-dab018b261ef"
            :title="TABLE_TITLE"
            :headers="headers"
            :rows="rows"
            rowId="label"
            :rowsPerPage="10"
            :exportConfig="{roottext: 'Externe Zugaenge'}"
            @click-label="openExterneZugangBank"
        />
        <GhostLoading v-else-if="isLoading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
    </div>

    <ExterneZugangBankConfigModal ref="externeZugangBankConfigModal" :defaultList="list" @onSave="getConfig(true)" @onRestoreDefault="getConfig(true)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EXTERNE_ZUGANG_BANK_TYPES from '@/store/externeZugangBank/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import Table from '@/components/table2/Table.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ExterneZugangBankConfigModal from '@/components/externeZugangBank/ExterneZugangBankConfigModal.vue';

import { TABLE_HEADERS, configMixin, } from './externeZugangBankUtils';

const TABLE_TITLE = 'Externe Zugänge Liste';

export default {
  mixins: [configMixin],
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      headers: TABLE_HEADERS,
    };
  },
  computed: {
    ...mapGetters({
      list: EXTERNE_ZUGANG_BANK_TYPES.GETTERS.LIST,
      fcConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG,
    }),
    isLoading() {
      return this.loading || this.loadingConfig;
    },
    rows() {
      return this.filterVisibleItems(this.list, this.configuredList);
    },
  },
  methods: {
    async init() {
      this.getList();
      this.getConfig(true);
    },
    async getList() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.GET_LIST);
      } finally {
        this.loading = false;
      }
    },
    openExterneZugangBank(externeZugangBank) {
      this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.OPEN_URL, externeZugangBank);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Table,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    OptionMenu,
    ExterneZugangBankConfigModal,
  },
}
</script>
