var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle:
            (_vm.selectedGesellschaft && _vm.selectedGesellschaft.name) || "",
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _vm.formList && _vm.formList.length
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "box__container",
                attrs: { tid: _vm._generateTidFromString("box__container") }
              },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(_vm._s(_vm.selectedGesellschaft.name))
                ]),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: "",
                    placeholder: "Suchen nach Bezeichnung"
                  },
                  model: {
                    value: _vm.descriptionSearch,
                    callback: function($$v) {
                      _vm.descriptionSearch = $$v
                    },
                    expression: "descriptionSearch"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cards__container layout__negative-margin--8" },
              [
                _c(
                  "div",
                  {
                    staticClass: "box__container cards__item",
                    attrs: {
                      tid: _vm._generateTidFromString(
                        "cards__container layout__negative-margin--8"
                      )
                    }
                  },
                  [
                    _c("Table", {
                      staticClass: "formulare-list__table",
                      attrs: {
                        tableId: "0eca709b-022c-4078-871f-633007348bae",
                        tableData: _vm.tableData,
                        cardViewEnabled: false,
                        exportEnabled: false,
                        rowsPerPage: 20,
                        paginationEnabled: true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function(ref) {
                              var data = ref.data
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        "formulare-table" + data.index
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectFormsByGesellschaftId(
                                          data.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(data.row.name) + " ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1583196783
                      )
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm.loadingGesellschaftsFormulare
        ? _c(
            "div",
            [
              _c(
                "GhostLoading",
                { attrs: { useBoxContainer: "", isHalfSize: "" } },
                [
                  _c("Block", { attrs: { type: "title" } }),
                  _c("Block", { attrs: { type: "form-input" } })
                ],
                1
              ),
              _c("GhostLoading", {
                attrs: { type: "table", useBoxContainer: "" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }