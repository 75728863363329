var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.title,
          subtitle: _vm.subtitle,
          actions: _vm.headerActions
        },
        on: {
          "action-SAVE": function($event) {
            return _vm.saveDocument()
          },
          "action-DISCARD": function($event) {
            return _vm.resetChanges()
          },
          "action-PREVIEW": function($event) {
            return _vm.getDocumentPreview()
          },
          "action-CONFIG": function($event) {
            return _vm.openPageLayoutModal()
          }
        }
      }),
      _c("DynamicPageLayoutModal", {
        ref: "openPageLayoutModal",
        attrs: { document: _vm.document }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }