<template>
    <div class="container" ref="container">
        
        <PrimitiveScrollTable v-if="scrollHorizontally"
            :headers="topHeaders"
            :rows="visibleRows"
            :maxCollapsedWidth="containerWidth - 18"
            :scrollButtonWidth="scrollButtonWidth"
            @files="handleFile"
        >
            <template v-for="{key} in headersFlat" :slot="'header_' + key">
                <slot :name="'header_' + key"/>
            </template>
            <template v-for="{key} in headersFlat" v-slot:[key]="data">
                <slot :name="key" v-bind="data"/>
            </template>
        </PrimitiveScrollTable>

        <PrimitiveTable v-else
            :topHeaders="shownHeaders"
            :collapsedHeaders="collapsedHeaders"
            :rows="visibleRows"
            :maxCollapsedWidth="containerWidth - 18"
            :sortableRows="sortableRows"
            :valign="valign"
            @files="handleFile"
            @closeRowModal="closeRowModal"
            @orderChanged="$emit('orderChanged', $event)"
        >
            <template slot="_sortable_handler">
                <div class="_sortable-handler" data-sortable-item-handler>
                    <PhList :size="16"/>
                </div>
            </template>
            <template v-slot:_v="{id, index, open}">
                <div @click="toggleRow(id, index)" class="clickable _v hide-on-small-screen">
                    <PhCaretDown :size="16" v-if="open"/>
                    <PhCaretRight :size="16" v-else/>
                </div>
                <div @click="openRowModal(id, index)" class="clickable _v hide-on-large-screen">
                    <PhCaretRight :size="16" />
                </div>
            </template>
            <template v-for="{key} in headersFlat" v-slot:[`header_${key}`]="data">
                <slot :name="'header_' + key" v-bind="data"/>
            </template>
            <template v-for="{key} in headersFlat" v-slot:[key]="data">
                <slot :name="key" v-bind="data"/>
            </template>
            <template v-slot:bottom__table>
                <slot name="bottom__table"></slot>
            </template>               
        </PrimitiveTable>
        <!--
            This pseudo table measures the space that each of the columns want to take 
            This information is then used to shrink or even hide columns if not all of them fit in the available space
            It should never be visible
        -->
        <PrimitiveFlexTable
            :headers="allHeaders"
            :rows="rows"
            @resize="onAllResize"
            style="position: fixed; top: -999%; left: -999%;"
        >
            <template slot="_sortable_handler">
                <div data-sortable-item-handler>
                    <PhList :size="16"/>
                </div>
            </template>
            <template slot="_v">
                <div class="clickable _v">
                    <PhCaretRight :size="16"/>
                </div>
            </template>
            <template slot="header__l">
                <BaseButton style="margin: 0">
                    <PhCaretRight :size="24"/>
                </BaseButton>
            </template>
            <template slot="header__r">
                <BaseButton style="margin: 0">
                    <PhCaretRight :size="24"/>
                </BaseButton>
            </template>
            <template v-for="{key} in headersFlat" :slot="'header_' + key">
                <slot :name="'header_' + key"/>
            </template>
            <template v-for="{key} in headersFlat" v-slot:[key]="data">
                <slot :name="key" v-bind="data"/>
            </template>
            <template v-slot:bottom__table>
                <slot name="bottom__table"></slot>
            </template>            
        </PrimitiveFlexTable>
    </div>
</template>
<script>

import Vue from 'vue';

import PrimitiveScrollTable from './PrimitiveScrollTable.vue';
import PrimitiveTable from './PrimitiveTable.vue';
import PrimitiveFlexTable from './PrimitiveFlexTable.vue';
import { PhCaretRight, PhCaretUp, PhCaretDown, PhList, } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue';
import { SMALL_SCREEN_WIDTH } from '@/helpers/responsive-helper';

const COLLAPSE_BUTTON_WIDTH = 32;
const SCROLL_BUTTON_WIDTH = 64;
const MIN_CENTER_WIDTH_TO_ENABLE_SCROLL = 198;

const expandedId = (id, index) => `${id}-${index}`;

export default {
    components: {
        PrimitiveScrollTable,
        PrimitiveTable,
        PrimitiveFlexTable,
        PhCaretRight,
        PhCaretUp,
        PhCaretDown,
        PhList,
        BaseButton,
    },
    props: {
        headers: { 
            type: Object,
        },
        rows: { 
            type: Array,
            default: () => [],
        },
        scrollHorizontally: {
            type: Boolean,
            default: false,
        },
        loadingCollapsed: { 
            type: Object,
            default: () => {},
        },
        sortableRows: {
            type: Boolean,
            default: false,
        },
        valign: {
            type: String,
            default: 'center'
        },
    },
    data() {
        return {
            resizeObserver: null,
            containerWidth: 0,
            theoreticalWidth: 0,
            theoreticalColumnWidths: {},
            expandedRows: {},
            openedRowsModal: {},
        };
    },
    computed: {
        headersFlat() {
            const headerLists = [
                this.headers.lockedLeft,
                this.headers.center,
                this.headers.lockedRight,
            ];
            return headerLists.flat().map(header => {
                return {
                    ...header,
                    style: {
                        flexBasis: "auto",
                        flexGrow: header.grow != null ? header.grow : 1,
                    },
                };
            });
        },
        allHeaders() {
            const result = [...this.headersFlat];

            if(this.sortableRows) {
                result.push({
                    key: "_sortable_handler",
                    label: "",
                    minWidth: COLLAPSE_BUTTON_WIDTH,
                    grow: 0,
                    classes: 'sortable-handler--cell',
                    align: 'center',
                    style: {
                        width: COLLAPSE_BUTTON_WIDTH,
                    },
                });
            }

            if (this.scrollHorizontally) {
                // these are inserted for the flex table, so widths can be calculated correctly
                result.splice(this.headers.lockedLeft.length, 0, {
                    key: "_l",
                    label: "",
                    minWidth: SCROLL_BUTTON_WIDTH,
                    grow: 0,
                    style: {
                        width: SCROLL_BUTTON_WIDTH,
                    },
                });
                result.splice(this.headers.lockedLeft.length + 1 + this.headers.center.length, 0, {
                    key: "_r",
                    label: "",
                    minWidth: SCROLL_BUTTON_WIDTH,
                    grow: 0,
                    style: {
                        width: SCROLL_BUTTON_WIDTH,
                    },
                });
            } else {
                const arrAddMethod = this.containerWidth && window.innerWidth > SMALL_SCREEN_WIDTH ? 'unshift' : 'push';
                result[arrAddMethod]({
                    key: "_v",
                    label: "",
                    minWidth: COLLAPSE_BUTTON_WIDTH,
                    grow: 0,
                    classes: 'collapse-toggle--cell',
                    style: {
                        width: COLLAPSE_BUTTON_WIDTH,
                    },
                });
            }

            this.$emit('allHeaders', result);
            return result;
        },
        topHeadersFlat() {
            // first try shrinking all headers to their minimum width requirement
            let shrunkColumnsBy = {};
            let remainingWidth = this.theoreticalWidth;
            let minWidths = {};
            this.allHeaders.forEach(header => {
                if (header.minWidth < this.theoreticalColumnWidths[header.key]) {
                    shrunkColumnsBy[header.key] = this.theoreticalColumnWidths[header.key] - header.minWidth;
                    remainingWidth -= shrunkColumnsBy[header.key];
                }
                minWidths[header.key] = Math.min(header.minWidth, this.theoreticalColumnWidths[header.key]);
            });
            let shownHeaders = null;
            if (this.scrollHorizontally) {
                shownHeaders = this.allHeaders.map(header => {
                    return {
                        ...header,
                        width: this.theoreticalColumnWidths[header.key] - (shrunkColumnsBy[header.key] || 0)
                    }
                });
            } else {
                let hiddenColumns = [];
                // if even shrunk not all headers fit, hide columns that aren't locked
                if (this.containerWidth < remainingWidth - COLLAPSE_BUTTON_WIDTH)
                    remainingWidth = this.hideColumns(remainingWidth, h => !h.locked && !h.neverHide, hiddenColumns, minWidths);
                // if there are still too many columns, start hiding locked columns unless they have 'neverHide' set
                if (this.containerWidth < remainingWidth - (hiddenColumns.length ? 0 : COLLAPSE_BUTTON_WIDTH))
                    remainingWidth = this.hideColumns(remainingWidth, h => h.locked && !h.neverHide, hiddenColumns, minWidths);
                if (hiddenColumns.length == 0) {
                    hiddenColumns.push("_v");
                    remainingWidth -= minWidths["_v"];
                }
                hiddenColumns.forEach(key => delete shrunkColumnsBy[key]);
                // some space may be left over, share it among the shrunk columns
                let freeWidth = this.containerWidth - remainingWidth;
                if (freeWidth > 0) {
                    let toBeShrunk = Object.entries(shrunkColumnsBy).map(([key, removedWidth]) => {
                        return {
                            key,
                            removedWidth,
                        };
                    }).sort((c1, c2) => c2.removedWidth - c1.removedWidth);
                    while (toBeShrunk.length > 0 && toBeShrunk[toBeShrunk.length - 1].removedWidth <= freeWidth / toBeShrunk.length) {
                        freeWidth -= toBeShrunk.pop().removedWidth;
                    }
                    // now all keys still left in 'toBeShrunk' actually have to be shrunk
                    shrunkColumnsBy = {};
                    toBeShrunk.forEach(({key, removedWidth}) => {
                        shrunkColumnsBy[key] = removedWidth - freeWidth / toBeShrunk.length;
                    });
                }

                shownHeaders = this.allHeaders.filter(header => {
                    return hiddenColumns.indexOf(header.key) < 0;
                }).map(header => {
                    return {
                        ...header,
                        width: this.theoreticalColumnWidths[header.key] - (shrunkColumnsBy[header.key] || 0)
                    }
                });
            }
            let freeWidth = this.containerWidth - shownHeaders.reduce((totalWidth, header) => totalWidth + header.width, 0);
            if (freeWidth > 0) {
                // there's free space left over that can be grown into
                let remainingGrow = shownHeaders.reduce((totalGrow, header) => totalGrow + (header.grow || 0), 0);
                if (remainingGrow == 0) {
                    shownHeaders.forEach(header => {
                        header.width += remainingGrow / shownHeaders.length;
                    });
                } else {
                    shownHeaders.forEach(header => {
                        if (header.grow > 0) {
                            const additionalWidth = freeWidth * header.grow / remainingGrow;
                            freeWidth -= additionalWidth;
                            remainingGrow -= header.grow;
                            header.width += additionalWidth;
                        }
                    });
                }
            }
            return shownHeaders;
        },
        topHeaders() {
            return {
                lockedLeft: this.topHeadersFlat.filter(h => h.key == "_v" || this.headers.lockedLeft.some(h2 => h2.key == h.key)),
                center: this.topHeadersFlat.filter(h => this.headers.center.some(h2 => h2.key == h.key)),
                lockedRight: this.topHeadersFlat.filter(h => h.key == "_sortable_handler" || this.headers.lockedRight.some(h2 => h2.key == h.key)),
            };
        },
        shownHeaders() {
            const shownHeaders = this.topHeadersFlat.map(header => {
                return {
                    ...header,
                    width: header.width,
                };
            });
            this.$emit('shownHeaders', shownHeaders);
            return shownHeaders
        },
        collapsedHeaders() {
            return this.allHeaders.filter(header => {
                return header.key != "_v" && !this.topHeadersFlat.find(h => h.key == header.key);
            });
        },
        visibleRows() {
            const result = this.rows.map((row, index) => {
                return {
                    ...row,
                    loadingCollapsed: this.loadingCollapsed && this.loadingCollapsed[row.id],
                    open: !!this.expandedRows[expandedId(row.id, index)],
                    modalOpen: !!this.openedRowsModal[expandedId(row.id, index)],
                };
            });
            return result;
        },
        rowsExpandedIdsKey() { // this key detects when a row position changes and there is a watch that clears the expandedRows to prevent misbehavior
            return this.rows
                .map((row, index) => expandedId(row.id, index))
                .join(':');
        },
        scrollButtonWidth() {
            // should be the same for left and right button
            return this.theoreticalColumnWidths._l || SCROLL_BUTTON_WIDTH;
        },
        lockedLeftWidth() {
            return this.topHeaders.lockedLeft.reduce((sum, header) => sum + header.width, 0);
        },
        lockedRightWidth() {
            return this.topHeaders.lockedRight.reduce((sum, header) => sum + header.width, 0);
        },
    },
    watch: {
        rowsExpandedIdsKey: {
            handler() {
                this.clearExpandedRows();
            },
            immediate: true,
        },
        $isSmallScreen() {
            this.clearExpandedRows();
        },
    },
    mounted() {
        this.observeResize();
    },
    updated() {
        this.observeResize();
    },
    methods: {
        observeResize() {
            this.resizeObserver?.disconnect();
            this.resizeObserver = null;
            if (this.$refs.container) {
                this.resizeObserver = new ResizeObserver(this.updateContainerSize);
                this.resizeObserver.observe(this.$refs.container);
                this.updateContainerSize();
            } else {
                //console.log("observeResize without container");
            }
        },
        updateContainerSize(secondTry = false) {
            if (this.$refs.container) {
                this.containerWidth = this.$refs.container.clientWidth;
                this.canEnableHorizontalScroll();
            } else if (!secondTry) {
                this.$nextTick(() => this.updateContainerSize(true));
            }
        },
        canEnableHorizontalScroll() {
            const { containerWidth, lockedLeftWidth, lockedRightWidth, } = this;
            const remainingCenterWidth = containerWidth - (lockedLeftWidth + lockedRightWidth);
            this.$emit('enableHorizontalScroll', remainingCenterWidth >= MIN_CENTER_WIDTH_TO_ENABLE_SCROLL);
        },
        onAllResize({totalWidth, widths}) {
            Vue.set(this, "theoreticalColumnWidths", {...widths});
            this.theoreticalWidth = totalWidth;
        },
        hideColumns(remainingWidth, filter, hiddenColumns, columnWidths) {
            const hidable = Object.values(this.headers)
                .flat()
                .filter(filter)
                .map(h => h.key);
            while (this.containerWidth < remainingWidth && hidable.length > 0) {
                const key = hidable.pop();
                remainingWidth -= columnWidths[key];
                hiddenColumns.push(key);
            }
            return remainingWidth
        },
        toggleRow(id, index) {
            Vue.set(this.expandedRows, expandedId(id, index), !this.expandedRows[expandedId(id, index)]);
            this.$emit('toggleRow', {changeId: id})
        },
        openRowModal(id, index) {
            Vue.set(this.openedRowsModal, expandedId(id, index), true);
            this.$emit('toggleRow', {changeId: id})
        },
        closeRowModal(id, index) {
            Vue.set(this.openedRowsModal, expandedId(id, index), false);
            this.$emit('toggleRow', {changeId: id})
        },
        clearExpandedRows() {
            this.$set(this, 'expandedRows', {});
            this.$set(this, 'openedRowsModal', {});
        },
        handleFile({row, files}) {
            this.$emit('files', {
                row,
                files,
            });
        },
    },
    beforeDestroy() {
        this.resizeObserver?.disconnect();
    },
}
</script>
<style scoped>
.container {
    width: 100%;
    position: relative;
}

::v-deep .header-cell {
    font-weight: 500;
    padding: 1px 1px;
}
::v-deep .table-cell {
    box-sizing: border-box;
    padding: 8px 4px;
    border-top: 1px solid #C4C4C4;
    overflow: hidden;
}

._v,
._sortable-handler {
    width: 24px;
}
</style>