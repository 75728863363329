<template>
  <div>
    <BaseFilter
      v-if="showFilters"
      title="Offene Unterschriften Filter"
      :filterId="baseFilterSaveKey"
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :showSaveButton="true"
      immidiateSearch
      :defaultOptions="defaultOptions"
      @onFilter="search($event)"
      @onFilterZurucksetzen="search(null)"
    />
    <div class="d-flex box__container" >
      <span class="col box__title">Liste der offenen Unterschriften</span>
      <BaseContextMenu >
        <template v-if="!isLoading">
          <ContextMenuItem @click="exportFile('pdf')" >PDF</ContextMenuItem>
          <ContextMenuItem @click="exportFile('xlsx')" >Excel</ContextMenuItem>
        </template>
      </BaseContextMenu>
    </div>
    <div class="cards__container layout__negative-margin--8">

      <template v-if="initialLoad">
        <div class="box__container cards__item" v-if="initialLoad">
          <GhostLoading>
            <Block type="title" width="50%" />
            <Block type="title" width="75%" />
            <Block type="title" width="75%" />
            <Block type="title" width="100%" />
          </GhostLoading>
        </div>
        <div class="box__container cards__item" v-if="initialLoad">
          <GhostLoading>
            <Block type="title" width="50%" />
            <Block type="title" width="75%" />
            <Block type="title" width="75%" />
            <Block type="title" width="100%" />
          </GhostLoading>
        </div>
      </template>

      <template v-else-if="offeneUnterschriften.length">
        <ScrollLoading
          :hasScroll="shouldLoadNextPage"
          loadingDisabled
          @scroll="onScroll"
        >
          <div class="cards__container layout__negative-margin--8">
            <div class="box__container cards__item" v-for="(elem, index) in offeneUnterschriften" :key="elem.dokId + index">
              <div class="cards__item-header">
                <div class="box__title breakable-words">{{getTitle(index, elem)}}</div>
                <BaseContextMenu v-if="isActionsMenuVisible(elem) && canGenerateSignoViewerLink(elem)">
                  <template v-if="isBroker || isIntern">
                    <ContextMenuItem @click="openOffeneUnterschriftenDisableWarnung(elem)">
                      Warnung Deaktivieren (inkl. Kommentar)
                    </ContextMenuItem>
                  </template>
                  <template v-if="shouldShowMoreDetails(elem)">
                    <ContextMenuItem @click="selectOpenSignatureDocumentDetail(elem)">
                      weitere Details
                    </ContextMenuItem>
                  </template>

                  <template v-if="elem.zusDokumente">
                    <ContextMenuItem @click="selectAdditionalDocuments(elem)">
                      zusätzliche Dokumente anzeigen
                      <AnimatedSpinner class="ml-2" v-if="loading[elem.zusDokumente.nodeId]"/>
                    </ContextMenuItem>
                  </template>

                  <template v-if="isBroker || isIntern">
                    <ContextMenuItem @click="openVorlageModal(elem)">
                      Vorlage des Dokuments zurückziehen
                    </ContextMenuItem>
                  </template>
                </BaseContextMenu>
              </div>
              <div>
                <div class="mb-2">
                  <span class="font-bold" :class="getStatusClass(elem)">{{getStatusStr(elem)}}</span>
                  <span v-if="elem.missingSignatureDescription"> | {{ elem.missingSignatureDescription }}</span>
                </div>
              </div>
              <div class="parameters-list">
                <div>
                  <div class="col-12" v-if="!isCustomer">
                    <div v-if="elem.dokSignaturStornosBestaetigt && elem.dokSignaturStornosBestaetigt.disableStornosBestaetigt">
                      <span class="color-danger font-bold">Warnung wurde deaktiviert </span>
                      <span v-if="elem.dokSignaturStornosBestaetigt.disableComment">({{ elem.dokSignaturStornosBestaetigt.disableComment }})</span>
                    </div>
                    <div>
                      Kunde: <a @click="openCustomerNewTab(elem)">{{elem.lastName}}, {{elem.firstName }}</a>
                    </div>
                  </div>
                  <div v-for="(param, index4) in getParameterList(elem)" :key="index4">
                    <div v-if="param.type === 'IMAGE'">
                      {{param.label}}
                    </div>
                    <div v-if="param.type !== 'IMAGE'">
                      <span v-if="param.label">{{param.label}}:&nbsp;</span><span class="breakable-words">{{param.value}}</span>
                    </div>
                  </div>
                </div>

                <div v-if="canGenerateSignoViewerLink(elem)" class="action-button-list mt-3">
                  <SignoViewerLink
                    :disabled="readOnly"
                    title="Elektronisch unterschreiben"
                    requestURL
                    extraParams="noRedirect=true"
                    :href="signoLinkMaker(elem.viewFileId, elem.nodeId)" 
                  />
                  <BaseButton
                    v-if="showButtonDokumentAbschliessen && elem.showSubmitButton"
                    :disabled="readOnly || elem.status !== 'KOMPLETT'"
                    @click="submitDocument(elem)"
                    class="mr-0"
                    isLink>
                    Dokument abschließen
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </ScrollLoading>
      </template>
      <div v-else class="box__container cards__item"><NoData /></div>
    </div>

    <RemoveVorlageModal :selectedElement="selectedElement" :isBroker="isBroker || isIntern" ref="removeVorlageModal"
      @error="errorStr = $event; $refs.fehlerVorlage.open()"/>

    <OffeneUnterschriftenDisableWarnung
      v-if="showOffeneUnterschriftenDisableWarnung"
      :selectedElement="selectedElement"
      @close="closeShowOffeneUnterschriftenDisableWarnung" />

    <BaseModal ref="fehlerVorlage" :showConfirmButton="false" labelButtonCancel="Ok">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        {{errorStr}}
      </template>
    </BaseModal>

    <BaseModal 
        ref="modalDownloadPin"
        modalTitle="Download PIN geschützte Datei"
        :showConfirmButton="true"
        labelButtonConfirm="Herunterladen"
        labelButtonCancel="Abbrechen"
        :autoClose="false"
        @onConfirmButton="downloadPinFile"
        @close="onCloseModal" 
    > 
        <form @submit.prevent="downloadPinFile()">
            <InputField 
            label="Bitte geben Sie die PIN ein"
            placeholder="Pin" 
            v-model="tempFilePin" />
        </form>
        <div class="fc-form-danger">
            {{ errorPinFile }}
        </div>
    </BaseModal>

    <OpenSignatureDocumentDetails 
      v-if="selectedOpenSignatureDocumentDetail" 
      :selectedOpenSignatureDocumentDetail="selectedOpenSignatureDocumentDetail" 
      @close="selectedOpenSignatureDocumentDetail=null">

      <template #title>
        <div class="box__title">{{getTitle(null, selectedOpenSignatureDocumentDetail)}}</div>
        <div>
          <span class="font-bold" :class="getStatusClass(selectedOpenSignatureDocumentDetail)">
            {{getStatusStr(selectedOpenSignatureDocumentDetail)}}
          </span>
          <span v-if="selectedOpenSignatureDocumentDetail.missingSignatureDescription">
            | {{ selectedOpenSignatureDocumentDetail.missingSignatureDescription }}
          </span>
        </div>
      </template>
    </OpenSignatureDocumentDetails>

    <OpenSignatureAdditionalDocuments 
      v-if="selectedAdditionalDocuments" 
      :selectedAdditionalDocuments="selectedAdditionalDocuments" 
      @close="selectedAdditionalDocuments=null">
      <template #title>
        <div class="box__title">{{getTitle(null, selectedOpenSignatureForAdditionalDocuments)}}</div>
        <div>
          <span class="font-bold" :class="getStatusClass(selectedOpenSignatureForAdditionalDocuments)">
            {{getStatusStr(selectedOpenSignatureForAdditionalDocuments)}}
          </span>
          <span v-if="selectedOpenSignatureForAdditionalDocuments.missingSignatureDescription">
            | {{ selectedOpenSignatureForAdditionalDocuments.missingSignatureDescription }}
          </span>
        </div>
      </template>
    </OpenSignatureAdditionalDocuments>
  </div>
</template>

<script>
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'
import COMMUNICATION_TYPES from '@/store/communication/types'
import CORE_TYPES from "@/store/core/types";
import { PhWarning } from 'phosphor-vue'
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import { mapGetters } from 'vuex'
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'
import BaseModal from '@/components/core/BaseModal.vue';
import RemoveVorlageModal from '@/components/openSigns/RemoveVorlageModal.vue';
import OffeneUnterschriftenDisableWarnung from '@/components/communication/OffeneUnterschriftenDisableWarnung.vue'
import OpenSignatureDocumentDetails from '@/components/communication/OpenSignatureDocumentDetails.vue'
import OpenSignatureAdditionalDocuments from '@/components/communication/OpenSignatureAdditionalDocuments.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import NoData from '@/components/core/NoData.vue';
import { getSearchMetadata, prepareFilters } from './offeneUnterschriftenConstants';
import BaseFilter from '@/components/core/BaseFilter.vue';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import BRIDGE_TYPES from '@/store/bridge/types';
import ScrollLoading from '@/views/communication/ScrollLoading.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

export default {
  props: {
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseFilterSaveKey: 'OffeneUnterschriftenFilter',
      configFilter: {
        placeholderForDefSearchInput: 'Kundennr',
        checkDefaultSearchInput: false,
      },
      defaultOptions: {
        kundennr: { value: '' },
        includeDisabled: { value: false},
      },
      filterOptions: {
        kundennr: '',
        includeDisabled: false,
      },
      expanded: {},
      zusDokumente: {},
      selectedOpenSignatureDocumentDetail: null,
      selectedAdditionalDocuments: null,
      selectedOpenSignatureForAdditionalDocuments: null,
      loading: {},
      selectedElement: null,
      errorStr: null,
      currentPageIndex: 0,
      rowsPerPage: 20,
      visibleParameters: 3,
      initialLoad: false,
      shouldLoadNextPage: true,
      tempFileId: '', 
      tempFileEnding: '',
      tempFilePin: '', 
      errorPinFile: '', 
      isLoading: false,
      showOffeneUnterschriftenDisableWarnung: false,
    }
  },
  mounted() {
    document.addEventListener('counterUpdate', this.updateDocumentList);
    window.addEventListener('visibilitychange', this.handleUpdateListVisibilityChange);

    if (!this.showFilters) {
      this.doInitialLoad()
    }
  },
  destroyed() {
    document.removeEventListener('counterUpdate', this.updateDocumentList);
    document.removeEventListener('visibilitychange', this.handleUpdateListVisibilityChange);
  },
  computed: {
    ...mapGetters({
      signoLinkMaker: OPEN_SIGNS_TYPES.GETTERS.GET_SIGNO_VIEWER_LINK_MAKER,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      openSigns: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS,
      readOnly: CORE_TYPES.GETTERS.IS_OPEN_SIGNATURES_READ_ONLY,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    offeneUnterschriften() {
      return this.openSigns.offeneUnterschriften || [];
    },
    count() {
      return this.openSigns?.count;
    },
    searchMetadata() {
      return getSearchMetadata()
    },
    showFilters() {
      return this.$hasRoles([VIEW_ROLES.VIEW_BROKER])
    },
    showButtonDokumentAbschliessen() {
      return this.$hasRoles([ROLES.SHOW_BUTTON_DOKUMENT_ABSCHLIESSEN]);
    }
  },
  methods: {
    closeShowOffeneUnterschriftenDisableWarnung() {
      this.showOffeneUnterschriftenDisableWarnung = false;
      this.doInitialLoad();
    },
    async submitDocument(elem) {
      try {
        const metadata = await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.METADATA_SUBMIT_DOCUMENT, {
          viewFileId: elem.viewFileId,
          nodeId: elem.nodeId,
        });

        let title = 'Dokument endgültig abschließen';
        let message = 'Das Dokument wird gespeichert und endgültig abgeschlossen.<br/>Anschließend sind keine Änderungen mehr möglich.';

        if (metadata.submitConfirmationText) {
          message = metadata.submitConfirmationText;
        }
        if (metadata.submitConfirmationTitle) {
          title = metadata.submitConfirmationTitle;
        }

        await this.$confirmModal({
          title,
          message,
          labelButtonCancel: 'Abbrechen',
          labelButtonConfirm: 'Abschließen',
        });

        await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.SUBMIT_DOCUMENT, { 
          viewFileId: elem.viewFileId,
          nodeId: elem.nodeId,
          dokId: elem.dokId,
        });

        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Dokument wurde abgeschlossen/eingereicht', 'success'));

        this.doInitialLoad();
      } catch(e) {
        // empty block
      }
    },
    async onScroll(onScrollEnd) {
      const currenCount = this.offeneUnterschriften.length;
      const nextPageIndex =  this.currentPageIndex + 1
        
      try {
        await this.loadOpenSigns(nextPageIndex, true)
      } finally {
        onScrollEnd()
      }

      // weird implementation: we don't know how many more offeneUnterschriften there are to load
      // so if the count does not change after the last load we say that we are done
      if (currenCount === this.offeneUnterschriften.length) {
        this.shouldLoadNextPage = false;
        return
      }

      this.currentPageIndex = nextPageIndex;
    },
    handleUpdateListVisibilityChange() {
      if (document.visibilityState === 'visible' && this.isMobileNativeContext) {
        this.doInitialLoad();
      }
    },
    search(filters) {
      this.filterOptions = prepareFilters(filters);

      this.doInitialLoad();
    },
    async doInitialLoad() {
      if (this.isLoading) {
        return;
      }
      this.initialLoad = true;
      this.currentPageIndex = 0;
      this.shouldLoadNextPage = true;

      try {
        await this.loadOpenSigns()
      } finally {
        if(!this.offeneUnterschriften.length){
          this.$emit('noOpenSigns')
      }
        this.initialLoad = false;
      }
    },
    async loadOpenSigns(page = this.currentPageIndex, keepMeta = false) {
      this.isLoading = true;

      return this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS, {
        rowsPerPage: this.rowsPerPage, 
        page,
        keepMeta,
        ...this.filterOptions
      }).finally(() => {
        this.isLoading = false;
      });
    },
    updateDocumentList(event) {
      if (event.detail?.badgeName === 'TODO_LIST') {
        this.doInitialLoad();
      }
    },
    async selectAdditionalDocuments(element) {
      const folder = element.zusDokumente
      await this.getZusaetzlicheDok(folder)
      this.selectedOpenSignatureForAdditionalDocuments = element
      this.selectedAdditionalDocuments = this.zusDokumente[folder.nodeId]
    },
    selectOpenSignatureDocumentDetail(element) {
      this.selectedOpenSignatureDocumentDetail = element
    },
    shouldShowMoreDetails(element) {
      return element?.parameters?.length > this.visibleParameters 
    },
    getParameterList(element) {
      const parameters = (element?.parameters || []).filter(param => param.label !== 'Name');
      return parameters.length > this.visibleParameters ? parameters.slice(0, this.visibleParameters) : parameters
    },
    canGenerateSignoViewerLink(element) {
      return !!(element.nodeId && element.viewFileId)
    },
    async getZusaetzlicheDok(folder) {
      if (folder && folder.nodeId) {
        if (!this.zusDokumente[folder.nodeId]) {
          this.$set(this.loading, folder.nodeId, true)

          try {
            const params = { nodeId: folder.nodeId }
            const response = await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.GET_ZUSAETZLICHE_DOCUMENT_OFFENE_UNTERSCHRIFTEN, params)

            if (response.data?.offeneUnterschriften) {
              this.zusDokumente[folder.nodeId] = response && response.data.offeneUnterschriften;
              this.$set(this.expanded, folder.nodeId, true)
            }
          } catch (error) {
            // empty block
          } finally {
            this.$set(this.loading, folder.nodeId, false)
          }
        } else {
          this.$set(this.expanded, folder.nodeId, !this.expanded[folder.nodeId])
        }
      }
    },
    openVorlageModal(element) {
      this.selectedElement = element;
      this.$refs.removeVorlageModal.openVorlageModal();
    },
    openOffeneUnterschriftenDisableWarnung(element) {
      this.selectedElement = element;
      this.showOffeneUnterschriftenDisableWarnung = true;
    },
    openCustomerNewTab(sign) {
      if (sign?.kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: sign.kundennr });
      }
    },
    getTitle(index, elem = {}) {
      const date = elem.vorlegeDatum; //this.getDatumParameterValue(elem)
      const dateDescription = date ? `${date} | ` : ''
      const indexStr = typeof index === 'number' ? `${index + 1}.` : ''

      return `${indexStr} ${dateDescription} ${elem.name || 'Dokument'} `.trim();
    },
    getStatusClass(elem) {
      return getStatusClass(elem);
    },
    getStatusStr(elem) {
      return getStatusStr(elem);
    },
    isActionsMenuVisible(elem) {
      return this.shouldShowMoreDetails(elem) || elem?.zusDokumente || (this.isBroker || this.isIntern);
    },
    exportFile(type) {
      if (type === 'pdf' || type == 'xlsx') {
        this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.EXPORT_OPEN_SIGNS, {...this.filterOptions, type})
        .then(response => {
          if (response?.data?.bytes) {
            viewDocument({
                data: response.data.bytes,
                filename: response.data.fileName,
                contentType: (type === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
            });
          } else if (response?.data?.tempFileId) {
            this.tempFileId = response.data.tempFileId;
            this.tempFileEnding = type;
            this.$refs.modalDownloadPin.open();
            
          }
        })
      }
    },
    async downloadPinFile() {
      const content = (this.tempFileEnding == 'xlsx') ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
      if ( /\d\d\d/.test(this.tempFilePin) ) {
          const error = await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PIN_DATA, {
              id: this.tempFileId,
              pin: parseInt(this.tempFilePin),
              type: this.tempFileEnding,
              contenttype: content,
          });
          if (!error) {
              this.$refs.modalDownloadPin.close();
              this.onCloseModal();
          }
      } else {
          this.errorPinFile = 'Bitte eine 3-stellige Nummer als Pin eingeben';
      }
    },
    onCloseModal() {
      this.tempFileId = null;
      this.tempFileEnding = null;
      this.tempFilePin = '';
      this.errorPinFile = ''
    },
  },
  components: {
    OpenSignatureDocumentDetails,
    AnimatedSpinner,
    SignoViewerLink,
    OpenSignatureAdditionalDocuments,
    BaseModal,
    RemoveVorlageModal,
    PhWarning,
    GhostLoading,
    Block,
    BaseContextMenu,
    ContextMenuItem,
    NoData,
    BaseFilter,
    ScrollLoading,
    InputField,
    BaseButton,
    OffeneUnterschriftenDisableWarnung,
  }
}

export function getStatusStr(elem = {}) {
  let statusStr = 'nicht unterschrieben';
  if (elem.status) {
    switch(elem.status) {
      case 'TEILWEISE':
        statusStr = 'teilweise unterschrieben';
        break;
      case 'KOMPLETT':
        statusStr = 'komplett unterschrieben, aber noch nicht abgeschlossen';
        break;
      default: break;
    }
    return statusStr;
  }
}

export function getStatusClass(elem = {}, hasColor = true) {
  let statusStr = hasColor && 'color-danger' || 'danger';
  if (elem.status) {
    switch(elem.status) {
      case 'TEILWEISE':
      case 'teilweise unterschrieben':
        statusStr = hasColor && 'color-warning' || 'warning';
        break;
      case 'KOMPLETT':
      case 'komplett unterschrieben, aber noch nicht abgeschlossen':
        statusStr = hasColor && 'color-success' || 'success';
        break;
      default: break;
    }
    return statusStr;
  }
}

</script>

<style scoped>
.breakable-words {
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.action-button-list {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

.parameters-list {
  display: flex;
  flex-direction: column; 
  flex-grow: 2;
  justify-content: space-between;
}

.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__container > * {
  flex: 1 1 auto;
}

.cards__item { 
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.cards__item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .cards__item { 
    flex: 1 1 calc(50% - 16px);
  }
}

</style>