var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Bemerkungen",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.createBemerkung()
          }
        }
      }),
      _vm.isBemerkungSichtbar || !_vm.isCustomerOnly
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "col box__title" }, [
                _vm._v("Allgemeine Bemerkung")
              ]),
              _c("CustomerBemerkung")
            ],
            1
          )
        : _vm._e(),
      _c("BemerkungenList", {
        attrs: {
          records: _vm.bemerkungen,
          loading: _vm.loading,
          typ: _vm.VALUE_TYP
        },
        on: { delete: _vm.deleteRow, deleteSelected: _vm.deleteSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }