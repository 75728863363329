var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Wertpapiere"
        }
      }),
      _c("FondsFinderSuchMaske", {
        attrs: { emitFilters: "" },
        on: { onSearch: _vm.search }
      }),
      _c("FondsFinderPositionsList", {
        attrs: {
          zurueckButtonText: "Zurück zur Vermögensverwaltung",
          hasDefaultButton: false,
          isBuyPositions: false,
          isSelectable: false
        },
        on: {
          goBack: _vm.navigateToEditor,
          handleTableAction: function($event) {
            return _vm.handleTableAction($event, "zurück zur Wertpapiersuche")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }