<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellung" 
      :subtitle="configDefinitionForm.label" 
      :actions="headerActions" 
      @action-EDIT="openDetailsModal()" 
      @action-PERMISSION="openPermissionModal()" 
    />

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigEditVerticalStep
          ref="stepper"
          :configStep="configDefinitionForm.steps"
          :configData="emptyData"
          :selectedStep="selectedStep"
          :disabled="isLoading || isNewConfigDefinition"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
          @setSubstep="setSelectedSubstep"
          @stepEdited="stepEdited($event)"
          @removeStep="removeStep($event)"
          @addStep="addStep()"
          @orderChanged="onStepOrderChanged($event)"
        />
      </template>

      <template #content>
        <div v-if="loading || selectedStep" class="box__container">
          <GhostLoading v-if="loading" type="block" :config="{ block: { height: '120px', }, }" />
          <SortableList v-else-if="selectedStepForm.configComponents.length" class="configs-components__sortable" :key="selectedStepForm.configComponents.length" 
            :items="selectedStepForm.configComponents" @orderChanged="onOrderChanged($event)"
          >
            <div class="configs-components" data-sortable-container>
                <div v-for="(component, index) in selectedStepForm.configComponents" :key="component._uuid || component.id" 
                  class="configs-component--item" 
                  :class="{ 'active': editEnabled[component._uuid || component.id], }"
                  data-sortable-item
                >
                  <template v-if="editEnabled[component._uuid || component.id]">
                    <ConfigEditParamLabel :param="component" :disabled="isLoading" 
                      @confirm="onParamLabelEdited(index, $event);setParamEditEnabled(index, false);" 
                      @cancel="setParamEditEnabled(index, false)" 
                    />
                  </template>
                  <template v-else>
                    <div class="configs-component--item-input">
                      <AntragComponent 
                        :component="component"
                        :data="emptyData"
                        disabled
                        isComponentHalfSizeEnabled />

                      <div v-if="isLabelEdited(component)" class="configs-component--item-original-label text-small">
                        <span class="configs-component--item-original-label--icon"><PhNotePencil :size="16" /></span>
                        <span class="configs-component--item-original-label--text"><b>Originaltitel:</b> <span v-html="sanitize(component.originalLabel)" /></span>
                      </div>
                    </div>
                  </template>

                  <div class="configs-component--item-actions">
                    <div class="configs-component--item-action--sortable">
                      <button type="button" class="btn-clear" :disabled="isLoading" data-sortable-item-handler>
                        <PhList :size="20" />
                      </button>
                    </div>

                    <BaseContextMenu class="configs-component--item-action--context-menu" :disabled="isLoading" placement="bottom-right">
                      <template #default>
                        <ContextMenuGroup v-if="isLabelEditVisible(component)">
                          <ContextMenuItem :disabled="isLoading" @click="setParamEditEnabled(index, true)">Titel bearbeiten</ContextMenuItem>
                          <ContextMenuItem v-if="isLabelEdited(component)" :disabled="isLoading" @click="resetEditedLabel(index);">Titel zurücksetzen</ContextMenuItem>
                        </ContextMenuGroup>
                        <ContextMenuGroup>
                          <ContextMenuItem :disabled="isLoading" @click="openAddParamModal(index, 'before')">Neue Einstellung davor einfügen</ContextMenuItem>
                          <ContextMenuItem :disabled="isLoading" @click="openAddParamModal(index, 'after')">Neue Einstellung danach einfügen</ContextMenuItem>
                        </ContextMenuGroup>
                        <ContextMenuGroup>
                          <ContextMenuItem :disabled="isLoading" @click="addLabel(index, 'before')">Titel davor einfügen</ContextMenuItem>
                          <ContextMenuItem :disabled="isLoading" @click="addLabel(index, 'after')">Titel danach einfügen</ContextMenuItem>
                        </ContextMenuGroup>
                        <ContextMenuGroup>
                          <ContextMenuItem :disabled="isLoading" @click="addDivider(index, 'before')">Trenn-Linie davor einfügen</ContextMenuItem>
                          <ContextMenuItem :disabled="isLoading" @click="addDivider(index, 'after')">Trenn-Linie danach einfügen</ContextMenuItem>
                        </ContextMenuGroup>
                        <ContextMenuGroup>
                          <ContextMenuItem :disabled="isLoading" @click="removeComponent(index)">Löschen</ContextMenuItem>
                        </ContextMenuGroup>
                      </template>
                    </BaseContextMenu>
                  </div>
                </div>
            </div>
          </SortableList>

          <div v-if="selectedStep" class="configs-component--actions">
            <button type="button" class="btn-clear clickable" @click="openAddParamModal()" :disabled="isLoading">
              <PhPlus :size="16" /> neuer Parameter
            </button>
            <button type="button" class="btn-clear clickable" @click="addLabelAtLastIndex()" :disabled="isLoading">
              <PhPlus :size="16" /> neuer Titel
            </button>
          </div>
        </div>
      </template>
    </ContentWithStepper>

    <ConfigDetailsModal ref="configDetailsModal" @changed="onDetailsChanged($event)" />

    <ConfigAddParamModal ref="configAddParamModal" :alreadySelected="selectedComponents" @add="onAdd($event)" />

    <ConfigPermissionModal ref="configPermissionModal" 
      @changed="onPermissionChanged($event)" 
      :deniedFCConfigType="deniedFCConfigType"
      :allowedFCConfigType="allowedFCConfigType" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

import { v4 as uuidv4 } from 'uuid';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigEditVerticalStep from '@/components/core/configs/ConfigEditVerticalStep.vue';
import { PhList, PhPlus, PhNotePencil, } from 'phosphor-vue';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import SortableList from '@/components/core/SortableList.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import ConfigAddParamModal from './ConfigAddParamModal.vue';
import ConfigDetailsModal from './ConfigDetailsModal.vue';
import ConfigPermissionModal from './configPermission/ConfigPermissionModal.vue';
import ConfigEditParamLabel, { LABEL_UNTITLED, } from './ConfigEditParamLabel.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { replaceUmlaute, } from '@/helpers/commonfunctions';
import { sanitize } from '@/helpers/string-helper.js';
import FC_CONFIG from '@/configs/fcConfig';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { stripTags, } from '@/helpers/commonfunctions';
import { findSubsteps, } from './configs-utils';

const NEW_ID = 'new';
const DEFAULT_STEP_LABEL = 'Gruppe';

const DIVIDER_TYPE = 'DIVIDER';
const LABEL_TYPE = 'LABEL';
const DIVIDER_ID = DIVIDER_TYPE.toLowerCase();
const LABEL_ID = LABEL_TYPE.toLowerCase();

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ContentWithStepper,
    ConfigEditVerticalStep,
    AntragComponent,
    PhList,
    PhPlus,
    PhNotePencil,
    SortableList,
    BaseContextMenu,
    ContextMenuGroup,
    ContextMenuItem,
    ConfigAddParamModal,
    ConfigDetailsModal,
    ConfigPermissionModal,
    ConfigEditParamLabel,
    GhostLoading,
  },
  data() {
    return {
      configDefinitionForm: {
        label: '',
        steps: [],
      },

      configPermissionListForm: null,

      addParam: {
        index: null,
        onto: '',
      },

      stepperCollapsed: null,
      selectedStep: null,
      selectedStepForm: {
        configComponents: [],
      },

      loading: false,
      saving: false,

      deniedFCConfigType: FC_CONFIG.DENIED_CONFIG_DEFINITION_GROUP,
      allowedFCConfigType: FC_CONFIG.ALLOWED_CONFIG_DEFINITION_GROUP,

      editEnabled: {},
    };
  },
  computed: {
    ...mapGetters({
      configDefinitionSelected: CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_SELECTED,
      configPermissionList: CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_PERMISSION_LIST,
    }),
    isLoading() {
      return this.loading || this.saving;
    },
    isValid() {
      return !!this.configDefinitionForm?.label;
    },
    hasSteps() {
      return !!this.configDefinitionForm?.steps?.length;
    },
    configDefinitionId() {
      return this.configDefinitionSelected?.id || this.$route.params.id;
    },
    isNewConfigDefinition() {
      return this.configDefinitionId === NEW_ID;
    },
    selectedComponents() {
      return this.configDefinitionForm.steps
        .flatMap(step => step.configComponents)
        .filter(component => !!component.id)
        .map(component => component.id) || [];
    },
    allConfigs() {
      const rootConfigs = this.configDefinitionForm.steps
        .flatMap(step => step.configComponents)
        .filter(config => config.configId && config.configType);

      const subConfigs = this.configDefinitionForm.steps
        .flatMap(step => step.configComponents)
        .filter(config => config?.config?.componentFields)
        .flatMap(config => config.config.componentFields)
        .filter(config => config.configId && config.configType);

      return [...rootConfigs, ...subConfigs];
    },
    emptyData() {
      return this.allConfigs.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: '',
      }), {});
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('EDIT', 'bearbeiten'),
        PageHeaderSimpleAction('PERMISSION', 'Zugriff'),
      ];
    },
  },
  watch: {
    configDefinitionSelected: {
      handler() {
        this.setConfigDefinitionForm();
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.reset();
      this.findAllData();
      this.initNewConfigDefinition();
    },
    reset() {
      this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_SELECTED);
      this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_PERMISSION_LIST);
    },
    sanitize(html) {
      return sanitize(html);
    },
    isLabelEditVisible(component) {
      return component?.type !== DIVIDER_TYPE;
    },
    isLabelEdited(component) {
      return component.type !== LABEL_TYPE && component?.originalLabel && component?.label !== component?.originalLabel;
    },
    async findAllData() {
      if(this.isNewConfigDefinition) return;

      try {
        this.loading = true;
        await this.findConfigDefinition();
        await this.findConfigPermission();
      } finally {
        this.loading = false;
      }
    },
    async findConfigDefinition() {
      if(this.isNewConfigDefinition) return;

      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.GET_CONFIG_DEFINITION, { 
        id: this.configDefinitionId,
        editMode: true,
      });
    },
    async findConfigPermission() {
      if(this.isNewConfigDefinition) return;

      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.FIND_CONFIG_PERMISSION, { 
        configDefinitionId: this.configDefinitionId,
      });
    },
    initNewConfigDefinition() {
      if(!this.isNewConfigDefinition) return;

      // open details modal
      this.$nextTick(() => this.openDetailsModal());
    },
    setSelectedStep(stepKey) {
      this.selectedStep = stepKey;
      this.selectedStepForm = {
        ...this.configDefinitionForm.steps.find(step => step.stepKey === stepKey) || { configComponents: [], },
      };
    },
    setSelectedStepByIndex(index) {
      this.setSelectedStep(this.configDefinitionForm.steps?.[index]?.stepKey || '');
    },
    setSelectedSubstep(event) {
      const { stepKey, } = event;
      this.setSelectedStep(stepKey);
    },
    stepEdited({ stepKey, label }) {
      const { steps } = this.configDefinitionForm;
      const stepIndex = steps.findIndex(step => step.stepKey === stepKey);
      const step = steps[stepIndex];

      const stepChecked = this.checkStep(label || DEFAULT_STEP_LABEL, step.stepKey);

      this.$set(steps, stepIndex, {
        ...step,
        stepKey: stepChecked.stepKey,
        label: stepChecked.label,
      });

      this.setSelectedStep(stepChecked.stepKey);

      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    removeStep(stepKey) {
      const { steps } = this.configDefinitionForm;
      const stepIndex = steps.findIndex(step => step.stepKey === stepKey);
      steps.splice(stepIndex, 1);

      if(this.selectedStep === stepKey) {
        this.setSelectedStepByIndex(0);
      }

      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    async addStep() {
      const { stepKey, label, } = this.checkStep(DEFAULT_STEP_LABEL);

      this.configDefinitionForm.steps.push({
        stepKey,
        label,
        configComponents: [],
      });

      // set step
      this.setSelectedStepByIndex(this.configDefinitionForm.steps.length - 1);

      this.setConfigDefinitionEdited();
      await this.autoSave();

      if(!this.stepperCollapsed) {
        this.$nextTick(() => this.$refs.stepper?.enablesEditStep(stepKey));
      }
    },
    checkStep(label, currentStepKey = null) {
      const labelSanitized = label.trim();

      const stepKey = replaceUmlaute(labelSanitized)
        .replace(/\s/gi, '_')
        .replace(/[^\w]/gi, '')
        .toUpperCase();
      const labelEscaped = labelSanitized.replace(/[()]/g, '\\$&');

      const steps = this.configDefinitionForm.steps.filter(step => !currentStepKey || step.stepKey !== currentStepKey);
      const stepKeyFound = steps.filter(step => new RegExp(`^${stepKey}(?=$|_\\d+$)`).test(step.stepKey));
      const labelFound = steps.filter(step => new RegExp(`^${labelEscaped}(?=$|\\s\\(\\d+\\)$)`).test(step.label));

      const findCheckedStepKey = (stepKey) => {
        let index = 0;
        let stepKey2 = `${stepKey}`;
        while(steps.some(step => step.stepKey === stepKey2)) {
          index++;
          stepKey2 = `${stepKey}_${index}`;
        }
        return stepKey2;
      };

      const findCheckedLabel = (label) => {
        let index = 0;
        let label2 = `${label}`;
        while(steps.some(step => step.label === label2)) {
          index++;
          label2 = `${label} (${index})`;
        }
        return label2;
      };

      const checkedStepKey = stepKeyFound.length ? findCheckedStepKey(stepKey) : stepKey;
      const checkedLabel = labelFound.length ? findCheckedLabel(labelSanitized) : labelSanitized;

      return {
        stepKey: checkedStepKey,
        label: checkedLabel,
      };
    },
    onStepOrderChanged(orderedList) {
      const { steps, } = this.configDefinitionForm;
      const orderedSteps = [ ...orderedList.map(item => steps.find(step => step.stepKey === item.stepKey)) ];
      this.$set(this.configDefinitionForm, 'steps', orderedSteps);

      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    openDetailsModal() {
      this.$refs.configDetailsModal.open({ ...this.configDefinitionForm, });
    },
    onDetailsChanged(configDefinitionDetails) {
      this.configDefinitionForm = {
        ...this.configDefinitionForm,
        ...configDefinitionDetails,
      };

      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    setConfigDefinitionForm() {
      // label
      this.configDefinitionForm.label = this.configDefinitionSelected?.label;

      // steps
      const steps = (this.configDefinitionSelected?.steps || [])
        .map(step => ({
          ...step,
          configComponents: step?.configComponents?.map(component => ({
            ...component,
            ...this.mapComponent(component),
          })),
        }));

      this.$set(this.configDefinitionForm, 'steps', steps.map(step => {
        const substeps = findSubsteps(step);
        return {
          ...step,
          substeps: substeps?.length > 1 ? substeps : [],
        };
      }));

      // set step
      if(!this.selectedStep) {
        this.setSelectedStepByIndex(0);
      } else {
        this.setSelectedStep(this.selectedStep);
      }
    },
    mapComponent(component) {
      switch(component.type) {
        case DIVIDER_TYPE:
          return { _uuid: `${DIVIDER_TYPE}_${uuidv4()}`, };
        case LABEL_TYPE:
          return { 
            _uuid: `${LABEL_TYPE}_${uuidv4()}`,
            ...(component.id === LABEL_ID ? {
              config: {
                ...component?.config || {},
                html: true,
              },
            } : {}),
          };
        default:
          return {};
      }
    },
    openAddParamModal(index, onto) {
      this.addParam = {
        index,
        onto,
      };
      this.$refs.configAddParamModal.open();
    },
    setConfigDefinitionEdited() {
      this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED, {
        id: this.isNewConfigDefinition ? null : this.configDefinitionId,
        ...this.configDefinitionForm,
        steps: this.configDefinitionForm.steps?.map(step => {
          const currentStep = step.stepKey === this.selectedStep ? this.selectedStepForm : step;
          return {
            ...currentStep,
            ...step,
            configComponents: currentStep.configComponents?.map(component => ({
              ...component,
              _uuid: undefined,
            })),
          };
        }),
      });
    },
    addComponentAtIndex(component, index, onto) {
      const checkedIndex = onto === 'before' ? index : index+1;

      this.selectedStepForm.configComponents.splice(checkedIndex, 0, { ...component, });

      this.setConfigDefinitionEdited();

      return checkedIndex;
    },
    onAdd(components) {
      const { index, onto, } = this.addParam;
      const checkedIndex = index >= 0 ? index : this.selectedStepForm.configComponents.length;
      components
        ?.map(component => ({ ...component, originalLabel: component.label, }))
        ?.forEach((component, i) => this.addComponentAtIndex(component, checkedIndex + i, onto));

      this.addParam = {};

      this.autoSave();
    },
    addDivider(index, onto) {
      const divider = { 
        _uuid: `${DIVIDER_TYPE}_${uuidv4()}`,
        id: DIVIDER_ID,
        type: DIVIDER_TYPE,
      };
      this.addComponentAtIndex(divider, index, onto);
      this.autoSave();
    },
    async addLabel(index, onto) {
      const label = { 
        _uuid: `${LABEL_TYPE}_${uuidv4()}`,
        id: LABEL_ID,
        type: LABEL_TYPE,
        label: LABEL_UNTITLED,
      };
      const addedIndex = this.addComponentAtIndex(label, index, onto);
      await this.autoSave();
      this.$nextTick(() => this.setParamEditEnabled(addedIndex, true));
    },
    addLabelAtLastIndex() {
      this.addLabel(this.selectedStepForm.configComponents.length, 'before');
    },
    setParamEditEnabled(index, value) {
      const configComponent = this.selectedStepForm.configComponents[index];
      this.$set(this.editEnabled, configComponent._uuid || configComponent.id, value);
    },
    onParamLabelEdited(index, editedLabel) {
      const configComponent = this.selectedStepForm.configComponents[index];
      this.$set(configComponent, 'label', editedLabel);
      if(configComponent.type === LABEL_TYPE) {
        this.$set(configComponent, 'id', LABEL_ID);
        this.$set(configComponent, 'configType', null);
        this.$set(configComponent, 'configId', null);
        this.$set(configComponent, 'config', {
          ...configComponent?.config || {},
          html: true,
        });
      }
      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    resetEditedLabel(index) {
      const configComponent = this.selectedStepForm.configComponents[index];
      this.$confirmModal({
        title: 'Titel zurücksetzen',
        message: `Möchten Sie dem Titel <b>"${stripTags(configComponent.label)}"</b> wirklich zurücksetzen?`,
        labelButtonConfirm: 'Zurücksetzen'
      }).then(() => {
        this.setParamEditEnabled(index, false); // cancel edit if it is enabled

        this.$set(configComponent, 'label', configComponent.originalLabel);
        this.setConfigDefinitionEdited();
        this.autoSave();
      })
    },
    removeComponent(index) {
      const configComponent = this.selectedStepForm.configComponents[index];
      const title = configComponent.type === DIVIDER_TYPE 
        ? 'Trenn-Linie löschen'
        : 'Parameter löschen';
      const message = configComponent.type === DIVIDER_TYPE 
        ? `Möchten Sie den <b>"Trenn-Linie"</b> wirklich löschen?`
        : `Möchten Sie den Parameter <b>"${stripTags(configComponent.label)}"</b> wirklich löschen?`;
      this.$confirmModal({
        title,
        message,
        labelButtonConfirm: 'Löschen'
      })
        .then(() => {
          this.selectedStepForm.configComponents.splice(index, 1);
          this.setConfigDefinitionEdited();
          this.autoSave();
        });
    },
    onOrderChanged(components) {
      this.selectedStepForm.configComponents = [ ...components, ];

      this.setConfigDefinitionEdited();
      this.autoSave();
    },
    openPermissionModal() {
      this.$refs.configPermissionModal.open([ ...this.configPermissionListForm || this.configPermissionList, ]);
    },
    onPermissionChanged(configPermissionList) {
      this.configPermissionListForm = [ ...configPermissionList, ];

      this.autoSave();
    },
    async saveConfigDefinition() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CONFIG_DEFINITION);
    },
    async deleteConfigPermission() {
      if(!this.configPermissionListForm || !this.configDefinitionSelected?.id) return;

      const configPermissionListToDelete = this.configPermissionList.filter(configPermission => !this.configPermissionListForm.find(configPermissionForm => {
        return configPermission.configContext == configPermissionForm.configContext &&
          configPermission.configType == configPermissionForm.configType &&
          configPermission.userLevel == configPermissionForm.userLevel &&
          configPermission.maklernr == configPermissionForm.maklernr &&
          configPermission.kundennr == configPermissionForm.kundennr;
      }));

      const configDefinitionId = this.configDefinitionSelected.id;
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.DELETE_CONFIG_PERMISSION, { 
        configDefinitionId,
        configPermissionList: configPermissionListToDelete,
      });
    },
    async saveConfigPermission() {
      if(!this.configPermissionListForm || !this.configDefinitionSelected?.id) return;
      const configDefinitionId = this.configDefinitionSelected.id;
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CONFIG_PERMISSION, { 
        configDefinitionId,
        configPermissionList: this.configPermissionListForm,
      });
    },
    async saveConfigPermissionData() {
      if(!this.configPermissionListForm || !this.configDefinitionSelected?.id) return;

      await this.deleteConfigPermission();
      await this.saveConfigPermission();

      this.configPermissionListForm = null;
      this.findConfigPermission();
    },
    async autoSave() {
      if(this.isValid) {
        await this.saveData();
      }
    },
    async saveData() {
      try {
        this.saving = true;
        await this.saveConfigDefinition();
        await this.saveConfigPermissionData();
      } finally {
        this.saving = false;
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if(this.isValid) {
      await this.saveData();
    } else if(this.hasSteps || !this.isNewConfigDefinition && !this.isLoading) {
      await this.$confirmModal({
        message: 'Titel field is required to save.<br>Are you sure you want to leave without save?',
        labelButtonCancel: 'Nein',
        labelButtonConfirm: 'Ja',
      });
    }

    this.reset();

    next();
  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.configs-component--item {
  display: flex;
  min-height: 32px;
  margin: 0 0 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.active {
    background-color: #f5f5f5;
  }

  .configs-component--item-editor {
    width: 100%;
  }

  .configs-component--item-input {
    flex: 1 1 auto;
    padding: 4px;
  }

  .configs-component--item-original-label {
    color: #999;
    display: flex;
    align-items: center;
    margin: 0;

    .configs-component--item-original-label--icon {
      display: flex;
      flex: 0 0 auto;
    }

    .configs-component--item-original-label--text {
      margin: 0 0 0 4px;
    }
  }

  .configs-component--item-actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 0 0 12px;

    > * {
      margin: 0 4px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.configs-component--actions {
  display: flex;
  justify-content: center;
}

.configs-component--actions button {
  margin: 0 8px;
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.configs-component--item-input {
  em {
    color: inherit;
  }

  .input-forms__label-content {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
