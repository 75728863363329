var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.form.name,
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _c(
        "div",
        [
          _c("HinweiseUndFehler", {
            attrs: { errors: _vm.warnings, hints: [] },
            on: {
              "set-step": function($event) {
                return _vm.navigateToPath($event)
              },
              "set-substep": function($event) {
                return _vm.navigateToSubstepPath($event)
              }
            }
          }),
          _c("StepperForm", {
            attrs: {
              stepType: "tab",
              stepperName: "retirement-plan",
              stepperMediator: _vm.tabsStepperMediator,
              hasFertig: false,
              selectedStepKey: _vm.selectedStepKey
            },
            on: {
              "next-step": _vm.nextStep,
              "previous-step": _vm.previousStep,
              "set-step": _vm.setStepByKey
            },
            scopedSlots: _vm._u([
              {
                key: "contentTemplate",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { class: { hidden: _vm.selectedStepKey !== "settings" } },
                      [
                        _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col col-md-6" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col col-md-6" },
                                  [
                                    _c("InputField", {
                                      attrs: { label: "Name", type: "text" },
                                      model: {
                                        value: _vm.form.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "name", $$v)
                                        },
                                        expression: "form.name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col col-md-6" },
                                  [
                                    _c("InputRadioBoxGroup", {
                                      attrs: {
                                        title: "Betrachtungszeitraum",
                                        values: _vm.getComboValues(
                                          _vm.periodTypes
                                        )
                                      },
                                      on: { input: _vm.retirementUpdate },
                                      model: {
                                        value: _vm.form.period,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "period", $$v)
                                        },
                                        expression: "form.period"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col col-md-6" },
                                  [
                                    _c("InputField", {
                                      attrs: {
                                        label: "Zinssatz Rentenphase",
                                        type: "percent"
                                      },
                                      on: { input: _vm.retirementUpdate },
                                      model: {
                                        value: _vm.form.interestAfterRetirement,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "interestAfterRetirement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.interestAfterRetirement"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col col-md-6" },
                                  [
                                    _c("InputField", {
                                      attrs: {
                                        label: "Inflation",
                                        type: "percent"
                                      },
                                      on: { input: _vm.retirementUpdate },
                                      model: {
                                        value: _vm.form.inflation,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "inflation", $$v)
                                        },
                                        expression: "form.inflation"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col col-md-6" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-forms__label-content"
                                    },
                                    [_vm._v("Geburtsdatum (TT.MM.JJJJ)")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("DatePickerField", {
                                        attrs: { validateUntouched: "" },
                                        on: { input: _vm.retirementUpdate },
                                        model: {
                                          value: _vm.form.dateOfBirth,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "dateOfBirth",
                                              $$v
                                            )
                                          },
                                          expression: "form.dateOfBirth"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("InputTextArea", {
                                  attrs: { label: "Notizen" },
                                  model: {
                                    value: _vm.form.notizen,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "notizen", $$v)
                                    },
                                    expression: "form.notizen"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "box__container" }, [
                          _c(
                            "div",
                            { staticClass: "row pl-3" },
                            [
                              _c(
                                "label",
                                { staticClass: "input-forms__label-content" },
                                [_vm._v("Gesamtvermögen")]
                              ),
                              _c("CurrencyLabel", {
                                attrs: { value: _vm.form.presentValue }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col sm-table" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "input-forms__label-content" },
                                  [_vm._v("Vermögen")]
                                ),
                                _vm.tableDataBalanceAssets &&
                                _vm.tableDataBalanceAssets.records
                                  ? _c("Table", {
                                      attrs: {
                                        tableId:
                                          "f0a4b0c9-c682-4e6f-8f42-9ac420b54da1",
                                        headers: _vm.headerTableDataBalance,
                                        rows:
                                          _vm.tableDataBalanceAssets.records,
                                        rowId: "index",
                                        hidePagination: "",
                                        selected:
                                          _vm.selectedBalanceAssetsAdapted
                                      },
                                      on: {
                                        selected:
                                          _vm.onSelectedAssetsRowsAdapted
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "interest",
                                            fn: function(row) {
                                              return [
                                                _c("InputField", {
                                                  attrs: { type: "percent" },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeInterest(
                                                        row.name,
                                                        $event,
                                                        +1
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.assetsInterest[
                                                        row.name.toLowerCase()
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.assetsInterest,
                                                        row.name.toLowerCase(),
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "assetsInterest[row.name.toLowerCase()]"
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "original",
                                            fn: function(row) {
                                              return [
                                                row.isAdjustable
                                                  ? _c("InputField", {
                                                      attrs: {
                                                        type: "currency"
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.changeOriginalValues
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.assetsIndividual,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.assetsIndividual = $$v
                                                        },
                                                        expression:
                                                          "assetsIndividual"
                                                      }
                                                    })
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("CurrencyLabel", {
                                                          attrs: {
                                                            value: row.original
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3402350226
                                      )
                                    })
                                  : _c("GhostLoading", {
                                      attrs: { type: "table" }
                                    })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col sm-table" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "input-forms__label-content" },
                                  [_vm._v("Verbindlichkeiten")]
                                ),
                                _vm.tableDataBalanceLiabilities &&
                                _vm.tableDataBalanceLiabilities.records
                                  ? _c("Table", {
                                      attrs: {
                                        tableId:
                                          "ce7e545e-a241-45ea-9b78-9c698bc36088",
                                        headers: _vm.headerTableDataBalance,
                                        rows:
                                          _vm.tableDataBalanceLiabilities
                                            .records,
                                        rowId: "index",
                                        hidePagination: "",
                                        selected:
                                          _vm.selectedBalanceLiabilitiesAdapted
                                      },
                                      on: {
                                        selected:
                                          _vm.onSelectedLiabilitiesRowsAdapted
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "interest",
                                            fn: function(row) {
                                              return [
                                                _c("InputField", {
                                                  attrs: { type: "percent" },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeInterest(
                                                        row.name,
                                                        $event,
                                                        -1
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.liabilitiesInterest[
                                                        row.name.toLowerCase()
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.liabilitiesInterest,
                                                        row.name.toLowerCase(),
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "liabilitiesInterest[row.name.toLowerCase()]"
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "original",
                                            fn: function(row) {
                                              return [
                                                row.isAdjustable
                                                  ? _c("InputField", {
                                                      attrs: {
                                                        type: "currency"
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.changeOriginalValues
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.liabilitiesIndividual,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.liabilitiesIndividual = $$v
                                                        },
                                                        expression:
                                                          "liabilitiesIndividual"
                                                      }
                                                    })
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("CurrencyLabel", {
                                                          attrs: {
                                                            value: row.original
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3162919380
                                      )
                                    })
                                  : _c("GhostLoading", {
                                      attrs: { type: "table" }
                                    })
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { class: { hidden: _vm.selectedStepKey !== "preview" } },
                      [
                        _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "row" }, [
                            _vm.retirementYearSet
                              ? _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("InputField", {
                                      attrs: {
                                        label: "Ruhestand ab",
                                        placeholder: "Ruhestand ab",
                                        type: "number"
                                      },
                                      on: { input: _vm.retirementUpdate },
                                      model: {
                                        value: _vm.form.retirementAge,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "retirementAge",
                                            $$v
                                          )
                                        },
                                        expression: "form.retirementAge"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("InputField", {
                                  attrs: {
                                    label: "Vermögen am Ende der Rentenphase",
                                    type: "currency"
                                  },
                                  on: { input: _vm.retirementUpdate },
                                  model: {
                                    value: _vm.form.moneyNeededAtTheEnd,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "moneyNeededAtTheEnd",
                                        $$v
                                      )
                                    },
                                    expression: "form.moneyNeededAtTheEnd"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("InputField", {
                                  attrs: {
                                    label: "Vermögen heute",
                                    placeholder: "Vermögen heute",
                                    type: "currency",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.form.presentValue,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "presentValue", $$v)
                                    },
                                    expression: "form.presentValue"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "mt-3 mb-3" },
                                [
                                  _vm.isLoading
                                    ? [_c("AnimatedSpinner")]
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "hidden-chart":
                                          _vm.isLoading ||
                                          !_vm.allBalance ||
                                          !_vm.allGoals
                                      }
                                    },
                                    [
                                      _c("RetirementChart", {
                                        attrs: {
                                          data: {
                                            data: _vm.apexSeries,
                                            series: _vm.apexSeries,
                                            xaxis: _vm.apexXaxis,
                                            annotations: _vm.apexAnnotations,
                                            markers: _vm.apexMarkers,
                                            dataLabels: { enabled: false },
                                            stroke: { curve: "smooth" },
                                            autoUpdateSeries: true
                                          }
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: {
                                          label:
                                            "Aktueller Wert/Urspürnglicher Wert",
                                          inLineLabel: ""
                                        },
                                        on: { input: _vm.retirementUpdate },
                                        model: {
                                          value: _vm.showOriginalWert,
                                          callback: function($$v) {
                                            _vm.showOriginalWert = $$v
                                          },
                                          expression: "showOriginalWert"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "box__container" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "layout__negative-margin--8 pb-3"
                                },
                                [
                                  _c(
                                    "BaseButton",
                                    {
                                      attrs: { isSecondary: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNewGoal(
                                            "addGoalTemplate"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Ausgabe hinzufügen ")]
                                  ),
                                  _c(
                                    "BaseButton",
                                    {
                                      attrs: { isSecondary: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNewRevenue(
                                            "addGoalTemplate"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Einnahme hinzufügen ")]
                                  )
                                ],
                                1
                              ),
                              _vm.isLoaded && _vm.tableData.records.length
                                ? _c("Table", {
                                    attrs: {
                                      tableId:
                                        "63a11a79-1c5d-42e7-912b-3381c5bb077e",
                                      headers: _vm.headerTableData,
                                      rows: _vm.tableDataRows,
                                      rowId: "index",
                                      hidePagination: "",
                                      selected: _vm.selectedRowsAdapted
                                    },
                                    on: {
                                      "action-EDIT": function($event) {
                                        return _vm.editAction($event)
                                      },
                                      "action-DELETE": function($event) {
                                        return _vm.deleteAction($event)
                                      },
                                      selected: _vm.onSelectedRowsAdapted
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "type",
                                          fn: function(row) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "type-col" },
                                                [
                                                  !_vm.isSpecialEventType(
                                                    row.type
                                                  )
                                                    ? _c("PhCircle", {
                                                        attrs: {
                                                          size: 16,
                                                          weight: "fill",
                                                          color: row.color
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        badge: true,
                                                        "badge-red": !row.isIncome,
                                                        "badge-green":
                                                          row.isIncome
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.typeName ||
                                                              row.type
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "startDate",
                                          fn: function(row) {
                                            return [
                                              row.startDate
                                                ? [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            row.startDate
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "endDate",
                                          fn: function(row) {
                                            return [
                                              row.endDate
                                                ? [
                                                    row.endDate
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                row.endDate
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    row.period
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                row.period
                                                              ) +
                                                              ")"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4007718830
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { class: { hidden: _vm.selectedStepKey !== "view" } },
                      [
                        _c("div", { staticClass: "box__container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "mt-3 mb-3" },
                                [
                                  _vm.isLoading
                                    ? [_c("AnimatedSpinner")]
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "hidden-chart":
                                          _vm.isLoading ||
                                          !_vm.allBalance ||
                                          !_vm.allGoals
                                      }
                                    },
                                    [
                                      _c("RetirementChart", {
                                        attrs: {
                                          data: {
                                            data: _vm.apexSeries,
                                            series: _vm.apexSeries,
                                            xaxis: _vm.apexXaxis,
                                            annotations: _vm.apexAnnotations,
                                            markers: _vm.apexMarkers,
                                            dataLabels: { enabled: false },
                                            stroke: { curve: "smooth" },
                                            autoUpdateSeries: true
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ])
                          ])
                        ]),
                        !_vm.isLoading && _vm.moneyNeededForRetirement !== 0
                          ? _c("div", { staticClass: "box__container" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _vm._v(
                                    " Zusätzlich benötigtes Vermögen bis zum Ruhestand: "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.moneyNeededForRetirement
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _vm._v(
                                    " Das bedeutet ein Investment in Höhe von "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.moneyNeededForRetirementAdditionalStartDate
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    " heute, oder eine monatliche Sparrate von "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.moneyNeededForRetirementAdditionalMonthly
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                !_vm.isBrokerOrBypass
                                  ? _c(
                                      "div",
                                      { staticClass: "col-12 pt-2" },
                                      [
                                        _c(
                                          "BaseButton",
                                          {
                                            attrs: { isPrimary: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  name: "new-ticket",
                                                  params: { textInput: "" }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("Nachricht senden")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "addGoalTemplate",
          attrs: {
            modalTitle:
              _vm.currentRowType === "goal"
                ? "AUSGABE ERFASSEN"
                : "EINNAHMEN ERFASSEN",
            showDefaultButtons: false
          }
        },
        [
          _c("EditForm", {
            attrs: {
              currentRowType: _vm.currentRowType,
              currentRowIndex: _vm.currentRowIndex,
              transactionsArray: _vm.transactionsArray,
              emptyItem: _vm.createZiele({})
            },
            on: {
              close: function($event) {
                return _vm.$refs.addGoalTemplate.close()
              },
              handleOk: _vm.saveEvent
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "configBalance",
          attrs: { showDefaultButtons: false, modalTitle: "" }
        },
        [
          _c("BalanceSheetView", {
            attrs: {
              isSelectableView: true,
              selectedItems: _vm.selectedBalance
            },
            on: {
              setBalance: _vm.setBalance,
              init: _vm.setBalance,
              selectedItems: _vm.onbalanceselectedItems
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "hidden" },
        [
          _c("BalanceSheetView", {
            attrs: {
              isSelectableView: true,
              selectedItems: _vm.selectedBalance
            },
            on: {
              setBalanceItems: _vm.setBalanceItems,
              setBalance: _vm.setBalance,
              init: _vm.setBalance,
              selectedItems: _vm.onbalanceselectedItems,
              balanceSum: _vm.setAllBalance
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "noDoB",
          attrs: { showDefaultButtons: false, modalTitle: "Kein Geburtsdatum" }
        },
        [
          _c("div", [
            _vm._v(
              " Bitte geben Sie Ihr Geburtsdatum in die persönlichen Daten ein "
            )
          ])
        ]
      ),
      _c("BaseModal", {
        ref: "errorMessage",
        attrs: {
          modalTitle:
            "Bei der Aktualisierung Ihrer Daten gab es ein Problem. Bitte laden Sie die Seite neu!"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }