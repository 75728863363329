<template>
  <div class="config-edit-param-label__container">
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      title="Marketing Kampagne bearbeiten"
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      @action-NEW="newMarketing"
    />
    <div class="box__container">
      <div class="input-forms__container">
        <InputField
          label="Titel"
          isComponentHalfSize
          v-model="campaign.title"
          @change="setMarketingCampaignAsChanged(true)">
        </InputField>

        <DatePickerField
          isRangePicker
          isComponentHalfSize
          label='Von / Bis'
          :value="{
            from: campaign.dateFrom,
            to: campaign.dateUntil
          }"
          @onSelectRangeFrom="setFromDate($event)"
          @onSelectRangeTo="setUntilDate($event)"  
        />

        <InputToggleSwitch
          v-model="campaign.active"
          label="Ist Aktiv"
          @change="setMarketingCampaignAsChanged(true)"
          inLineLabel
        />
        
        <HtmlEditor
          isFontParams
          v-model="campaign.body"
          @change="setMarketingCampaignAsChanged(true)"
        />

        <ParticipantInput
          label="Makler Blacklist"
          :value="targetListExcluded"
          :maxItemsVisible="maxItemsVisible"
          :multipleReceivers="multipleReceivers"
          :isUserSelect="isUserSelect"
          :isMaklerOnly = true
          :availableUsers="availableUsers"
          @addItem="clickReceiveUsers($event, 'EXCLUDED')"
          @deleteReceiveUsers="deleteReceiveUsers($event)"
          @deleteAllReceiveUsers="deleteAllReceiveUsers($event)"
          @handleAddMakler="handleAddMakler('MAKLER', 'EXCLUDED')"
          @userPreview="isUserPreviewActivated = $event"/>

        <ParticipantInput
          label="Makler Whitelist"
          :value="targetListIncluded"
          :maxItemsVisible="maxItemsVisible"
          :multipleReceivers="multipleReceivers"
          :isUserSelect="isUserSelect"
          :isMaklerOnly = true
          :availableUsers="availableUsers"
          @addItem="clickReceiveUsers($event, 'INCLUDED')"
          @deleteReceiveUsers="deleteReceiveUsers($event)"
          @deleteAllReceiveUsers="deleteAllReceiveUsers($event)"
          @handleAddMakler="handleAddMakler('MAKLER', 'INCLUDED')"
          @userPreview="isUserPreviewActivated = $event"/>
      </div>
    </div>

  </div>
</template>

<script>
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import InputField from '@/components/core/forms/InputField.vue';
import MSC_MARKETING_TYPES from '@/store/mscmarketing/types'
import Table from "@/components/table2/Table.vue";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ParticipantInput from '@/components/mailcomposer/ParticipantInput';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import { getNewMarketingCampaign } from '@/store/mscmarketing/index';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import dayjs from 'dayjs';

export const MAX_ITEMS_VISIBLE = 15;
export const KUNDE_TYPE = 'KUNDE';
export const BROKER_TYPE = 'MAKLER';

export default {
  props: {
    param: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reciverDisable:{
      type: Boolean,
      default: false,
    }, 
    multipleReceivers: {
      type: Boolean,
      default: true
    },
    isUserSelect: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HtmlEditor,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputField,
    Table,
    DatePickerField,
    ParticipantInput,
    InputToggleSwitch,
  },
  watch: {
    editingMarketingCampaign: {
      immediate: true,
      handler(campaign) {
        if (campaign) {
          this.campaign = {
            ...campaign
          }
        }
      }
    }
  },
  data() {
    return {
      campaign: {},

      userType: null,
      targetType: null,

      maxItemsVisible: MAX_ITEMS_VISIBLE,
      availableUsers: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedCustomersExternal: CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
      marketingCampaigns: MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS,

      targetListExcluded: MSC_MARKETING_TYPES.GETTERS.TARGET_LIST_EXCLUDED,
      targetListIncluded: MSC_MARKETING_TYPES.GETTERS.TARGET_LIST_INCLUDED,

      editingMarketingCampaign: MSC_MARKETING_TYPES.GETTERS.EDITING_MARKETING_CAMPAIGN,
    }),
    disabledConfirm() {
      return !this.campaign.title;
    },
    headerActions() {
      return [PageHeaderSimpleAction('NEW', 'Neue Marketing Kampagne')]
    },
  },
  methods: {
    setMarketingCampaignAsChanged(changed) {
      this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.MARKETING_CAMPAIGN_CHANGED, changed);
    },
    setFromDate(value) {
      this.campaign.dateFrom = dayjs(value).startOf('date').toDate();
      this.setMarketingCampaignAsChanged(true);
    },
    setUntilDate(value) {
      this.campaign.dateUntil = dayjs(value).endOf('date').toDate();
      this.setMarketingCampaignAsChanged(true);
    },
    newMarketing() {
      this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.SET_EDITING_MARKETING_CAMPAIGN, getNewMarketingCampaign());
      this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.RESET_USER_INTO_TARGET_LIST);
      this.setMarketingCampaignAsChanged(true);
    },
    async saveMarketing() {
      const campaign = await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.SAVE_MARKETING_CAMPAIGN, this.campaign);

      if (campaign) {
        this.campaign = {
          ...campaign
        };
      }
    },
    inputReceiver(value) {
      this.receiver = [...value]
      this.isSendDisabled = !this.isAnyEmail;
    },
    async handleAddMakler(userType, targetType) {
      this.userType = userType;
      this.targetType = targetType;
      await this.saveMarketing();
      this.navigateTo({ path: '/communication/mailcomposer-customer-search/broker/broker', query: { 
        back: `/intern/marketing-campaign/marketing-campaign-edit/${this.campaign.id}`, 
        targetType: this.targetType,
      }})
    },
    navigateTo(event) {
      this.$router.push({
        path: event?.path ? event.path : event,
        query: {
          singleUser: this.$route.query?.singleUser,
          ...(event?.query ? event.query : {})
        },
      });
    },

    async loadMarketingForEdit() {
      if (this.$route.params.id === 'new') {
        this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.RESET_USER_INTO_TARGET_LIST);
        return;
      }

      if (!this.$route.params.id || this.$route.params.id === "undefined") {
        return;
      }

      const found = await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN, { 
        id: this.$route.params.id 
      });

      if (found) {
        this.campaign = {
          ...found
        }

        if (!this.$route.query?.usersselected) {
          await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN_TARGET_LIST, { id: found.id });
        }
      }
    },
    async clickReceiveUsers(selected, targetType) {
      if (/^\d{5}$/.test(selected?.value)) {
        await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.LOAD_USER_ID_AS_TARGET, { userId: selected.value, targetType });
      }
    },
    deleteReceiveUsers(value) {
      this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.REMOVE_USER_FROM_TARGET_LIST, value);
    },
    deleteAllReceiveUsers(value) {
      value.forEach((chip) => {
        this.$store.commit(MSC_MARKETING_TYPES.MUTATIONS.REMOVE_USER_FROM_TARGET_LIST, chip);
      });
    },
  },
  mounted() {
    this.loadMarketingForEdit();
  },
  async beforeRouteLeave(to, from, next) {
    try {
      await this.saveMarketing();

      this.$addBreadcrumb({
        label: 'zurück zu Marketing', 
        to,
        from,
      });
      next();
    } catch (e) {
      // this.$confirmModal({
      //   title: '',
      //   message: ``,
      // }).then(() => {
      //   next();
      // })
    }
  },
}
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>
