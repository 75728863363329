var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "BiPRO Lieferungen"
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "BiPRO Lieferungen",
          filterId: "BiPRO Lieferungen",
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                attrs: {
                  tableId: "dcee9bf4-af71-494f-b86d-1688368d345e",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  initialPageSize: _vm.minNumRows
                },
                on: { onClickHeader: _vm.onClickHeader }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }