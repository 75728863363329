<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="Wertpapierlimit"
            :id="$appNavigation.currentOptionMenuId">
        </PageHeaderTitleNavigation>

        <div v-if="!isFondsfinder">
            <div class="box__container">
                <div  class="box__title">Wertpapierlimit</div>
                <div>
                    Zum Setzen der Limits kann ein absoluter Betrag oder eine prozentuale Abweichung basierend auf 
                    dem Ausgangswert eingegeben werden. Um den Ausgangswert an den aktuellen Anteilspreis anzupassen 
                    oder um bei einer Benachrichtigung das Limit neu zu setzen ist der 'Zurücksetzen' Button zu 
                    drücken. Soll ein Limit (oberes oder unteres) nicht beachtet werden so ist dieser Wert leer zu 
                    lassen bzw. auf 0 zu setzen.
                </div>
                <div class="mt-2">
                    Beispiel 1: Prozentuales unteres Limit 5% bedeutet, dass die Limitwarnung bei einer Abweichung des 
                    Wertpapierpreises um 5% nach unten ausgelöst wird.
                </div>
                <div>
                    Beispiel 2: Absolutes oberes Limit 70 bedeutet, dass die Limitwarnung bei einem Anstieg des Wertpapierpreises 
                    in Wertpapierwährung auf 70 ausgelöst wird.
                </div>
                <div>
                    Wenn Sie Dynamik auswählen und ein unteres Limit gesetzt ist, so steigt bei steigenden Wertpapierkursen 
                    das untere Limit im gleichen Verhältnis an. Bei sinkenden Kursen bleiben die Limits unverändert.
                </div>
                <div v-if="!isCustomerOrigin">
                    Möchten Sie ihrem Kunden bei Erreichen des Limits automatisch ein Kauf- und Verkaufsformular 
                    zuschicken, bitten wir Sie dies unter "Kundendaten/Einstellungen" auszuwählen.
                </div>
                <div class="layout__negative-margin--8 antrag-action-button__container">
                    <BaseButton isSecondary @click="reset()" :disabled="!isin || (limit && limit.notExist)">Zurücksetzen</BaseButton>
                    <BaseButton isPrimary @click="$refs.confirmDelete.open()" :disabled="!isin || (limit && limit.notExist)">Löschen</BaseButton>
                </div>
            </div>

            <div class="box__container">
                <WertpapierAuswahlUnique label="Wertpapier" :data="limit" id="isin" :config="{isEmbedded: true}" 
                    @openFondsfinder="isFondsfinder=true" :hasDelete="false" :disabled="wertpapierDisabled"/>
                <div class="info-block">
                    <div>Währung: {{limit.currency}}</div>
                    <div>Aktueller Kurs: {{formatCurrency(limit.aktuellKurs)}}</div>
                    <div>Kursdatum: {{limit.kursdatum}}</div>
                </div>
                <InputField
                    label="Ausgangswert (Anteilspreis)"
                    v-model="form.ausgangskurs"
                    type="percent"
                    :precision="2"
                    isComponentHalfSize />
                <InputField
                    label="Abweichung nach unten"
                    v-model="form.unteresLimitAbweichung"
                    type="percent"
                    :precision="2"
                    isComponentHalfSize />
                <InputRadioBoxGroup 
                    v-model="form.radioUnteresAbweichung" 
                    :values="radioValues" />
                <InputField
                    label="Abweichung nach oben"
                    v-model="form.oberesLimitAbweichung"
                    :precision="2"
                    type="percent"
                    isComponentHalfSize />
                <InputRadioBoxGroup 
                    v-model="form.radioOberesAbweichung"
                    :values="radioValues"  />
                <InputToggleSwitch
                    v-model="form.dynamic" 
                    label="Dynamik" />

                <div>Ausgangswert für Dynamik: {{formatCurrency(limit.dynamicValue)}}</div>
                <div>Letzte Änderung: {{limit.lastChangeDate}}</div>
                <div>Datum der Überschreitung: {{limit.informedDate}}</div>

                <InputTextArea
                    label="Bemerkung"
                    v-model="form.bemerkung" 
                    isComponentHalfSize />
            </div>
        </div>
        <div v-else>
            <FondsFinderSuchMaske/>

            <FondsFinderPositionsList 
                ref="fondsfinderResult"
                :goBackEnabled="true"
                :singleSelectionProp="true"
                :hasDefaultButton="true"
                @goBack="isFondsfinder = false"
                @getSelectedOutcome="setIsin($event)"
            />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="remove()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Limit löschen?</div>
        </BaseModal>

        <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmNextRoute" />
    </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue' 
import {toCurrencyString} from '@/filters.js';
import mixin from '@/mixins/limit/limit-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import WertpapierAuswahlUnique from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { PhWarning } from "phosphor-vue";
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue' 
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export default {
    mixins: [mixin],
    components: {
        BaseButton,
        InputField,
        InputTextArea,
        InputToggleSwitch,
        InputRadioBoxGroup,
        WertpapierAuswahlUnique,
        FondsFinderSuchMaske,
        FondsFinderPositionsList,
        BaseModal,
        PhWarning,
        LimitErrorModal,
        PageHeaderTitleNavigation,
    },
    computed: {
        ...mapGetters({
            wertpapierlimit: LIMIT_TYPES.GETTERS.WERTPAPIER_LIMIT,
        }),
        limit() {
            return this.wertpapierlimit || {};
        },
        title() {
            return `Depotlimit ${this.limit?.notExist ? '(nicht gesetzt)' : ''}`;
        },
        wertpapierDisabled() {
            return this.isin ? true : false;
        }
    },
    data() {
        return {
            form: {},
            radioValues: [{label: 'absolut', value: 'ABSOLUT'}, {label: 'prozentual (relativ)', value: 'PROZENTUAL'}],
            isin: '',
            isFondsfinder: false,
            errorFromService: null,
            nextRoute: false,
        }
    },
    watch: {
        limit(value) {
            if (Object.keys(value).length) {
                this.form = value;
                if (value.isin) {
                    this.isin = value.isin
                }
            }
        }
    },
    mounted() {
        this.isin = this.$route.params.isin && this.$route.params.isin !== 'neu' ? this.$route.params.isin : '';
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_WERTPAPIER_LIMIT, {isin: this.isin});
        this.form = {
            ausgangskurs: '',
            oberesLimitAbweichung: '',
            unteresLimitAbweichung: '',
            dynamic: '0',
            bemerkung: '',
            radioUnteresAbweichung: '',
            radioOberesAbweichung: '',
        }
    },
    methods: {
        formatCurrency(value) {
            return toCurrencyString(value);
        },
        save() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_WERTPAPIER_LIMIT, Object.assign(this.form, this.isin)).then(errorStr => {
                if (errorStr) {
                    this.errorFromService = errorStr;
                    this.$refs.modalError.open();
                } else {
                    this.confirmNextRoute();
                }
            });
        },
        reset() {
            if (this.isin) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.RESET_WERTPAPIER_LIMIT, this.isin);
            }
        },
        remove() {
            if (this.isin) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_FONDS_LIMIT, {id: this.isin, modus: 'LIMIT_KURS' });
                this.isin = '';
            }
        },
        setIsin(data) {
            if (data?.records?.length && data.records[0]?.isin) {
               this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_WERTPAPIER_LIMIT, {isin: data.records[0]?.isin});
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isin) {
            this.nextRoute = next;
            this.save();
        } else {
            next();
        }
    },
}
</script>

<style scoped>
.info-block {
    display: flex;
    flex-direction: column; 
    flex-grow: 2;
    justify-content: space-between;
}
</style>