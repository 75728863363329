var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.useParentOptionMenu
        ? _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.parentOptionMenuId,
              defaultMenu: _vm.$appNavigation.parentOptionMenu
            }
          })
        : _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.pageMetadata.title,
          subtitle: _vm.pageMetadata.subtitle,
          actions: _vm.headerActions
        },
        on: { "action-ADD-AKTIVITAET": _vm.newAppointment }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "other-product",
          stepperMediator: _vm.stepperMediator,
          hasFertig: _vm.isTestBroker || _vm.isTestCustomer,
          selectedStepKey: _vm.selectedStepKey,
          additionalActionsForMobile: _vm.baseModalActions
        },
        on: {
          handleOtherActions: _vm.handleOtherActions,
          "set-step": function($event) {
            return _vm.setStep($event)
          },
          "on-fertig": _vm.onDone
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.selectedStepKey === "produktdaten"
                  ? _c("Produktdaten", { ref: "produktdaten" })
                  : _vm.selectedStepKey === "anpassungen"
                  ? _c("Anpassungen")
                  : _vm.selectedStepKey === "dokumente"
                  ? _c(
                      "div",
                      [
                        _vm.type === "Immobilie"
                          ? _c(
                              "DragnDropArea",
                              {
                                attrs: { hoverText: "Datei hier ablegen" },
                                on: { files: _vm.dropFile }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "box__container" },
                                  [
                                    _c("DocumentsTable", {
                                      ref: "documentsTableEl",
                                      attrs: {
                                        title: "Dokumente",
                                        nodeId: _vm.combos.nodeId
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _c("Dokumente")
                      ],
                      1
                    )
                  : _vm.selectedStepKey === "abrechnung"
                  ? _c("Abrechnung")
                  : _vm.selectedStepKey === "aenderungslog"
                  ? _c("Aenderungslog")
                  : _vm.selectedStepKey === "manuelle-struktur"
                  ? _c("ManuelleStruktur")
                  : _vm.selectedStepKey === "aktivitaet"
                  ? _c("Aktivitaet")
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }