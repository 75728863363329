<template>
	<div>
		<OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

		<PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Informationen zum Prüfbericht" />
		<div class="box__container">
			<GhostLoading v-if="loading.init" type="table" />
			<Table rowId="id" :headers="headers" :rows="rows" :rowsPerPage="10">
				<template v-slot:bezeichnung="row">
					<DownloadLink :title="row.bezeichnung" 
						:filename="row.filename"
						:queryParams="{
                      dateCreated: row.dateCreated
						}"
					downloadServicePath="/get_allFiles_by_name" />
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { mapGetters } from 'vuex';
import { sanitize } from '@/helpers/string-helper.js';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'

import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import axios from 'axios';
import { SlotColumn, DateColumn } from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue'

const config = {
  defaultSpinner: true
};

export default {

	components: {
		OptionMenu,
		PageHeaderTitleNavigation,
		InputToggleSwitch,
		BaseButton,
		BaseBackButtonBreadcrumb,
		Table,
		GhostLoading,
		DownloadLink,
	},

	data() {
		return {
			loading: {
				init: false,
			},
			fileListe: [],
		}
	},

	watch: {},

	computed: {
		...mapGetters({}),
		headerActions() {
			return [];
		},
		headers() {
			return {
				lockedLeft: [
					SlotColumn("bezeichnung", "Name", 250, 1).makeAlwaysVisible(),
				],
				center: [],
				lockedRight: [
				//SlotColumn("dateCreated", "Erstellungsdatum", 250, 1).makeAlwaysVisible(),
				],
			}
		},
		rows() {
			let rows = this.fileListe || []
			return rows
		},
	},

	methods: {
		sanitize(htmlString) {
			return sanitize(htmlString);
		},
		load() {
			this.loading = true
			axios.get(`${process.env.VUE_APP_API}/informationWPService/documents`, config).then(response => {
				if (response && response.status === 200) {
					this.fileListe = response.data
				}
			}).finally(() => this.loading = false)
		},
	},

	mounted() {
		this.loading.init = true
		this.load();
	},
}
</script>

<style scoped>
.button__container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
</style>