<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
      title="FondsVergleich"
      subtitle="Einstellungen"
      :id="$appNavigation.currentOptionMenuId">
    </PageHeaderTitleNavigation>

    <div class="box__container" v-if="initialize">
      <InputRadioBoxGroup title="Berechnungsbasis" :values="settings.berechnungsbasisoptions" v-model="settings.berechnungsbasis"/>
      <InputRadioBoxItem name="steuerart" :checked="settings.steuerart===settings.steuerartoptions[0].value"
          :value="settings.steuerartoptions[0].value"  :label="settings.steuerartoptions[0].label"
          @change="settings.steuerart=settings.steuerartoptions[0].value" class="mb-0" />
      <InputField type="percent" v-model="settings.steuerindividuell" class="ml-4" isComponentHalfSize />

      <InputRadioBoxItem name="steuerart" :checked="settings.steuerart===settings.steuerartoptions[1].value"
          :value="settings.steuerartoptions[1].value"  :label="settings.steuerartoptions[1].label"
          @change="settings.steuerart=settings.steuerartoptions[1].value" />
      <div v-if="settings.steuersoli != undefined">
        <InputCheckBoxItem label="Solidaritätszuschlag" v-model="settings.steuersoli" />
        <InputCheckBoxItem label="Kirchensteuer" v-model="settings.steuerkirche" />
        <InputRadioBoxGroup class="ml-4 mt-2" :values="settings.steuerkircheoptions" v-model="settings.steuerkirchevalue"/>
      </div>
    </div>
    <div class="box__container" v-if="initialize">
      <InputCheckBoxItem label="Stammdaten" v-model="settings.showDaten" />
      <InputRadioBoxGroup title="PDF-Version" :values="settings.pdfversionoptions" v-model="settings.pdfversion"/>
      <ComboBox
        v-if="settings.hauptkriteriumoptions && settings.hauptkriteriumoptions.length"
        label="Hauptkriterium Optionen"
        v-model="settings.hauptkriterium"
        :values="settings.hauptkriteriumoptions"
        isComponentHalfSize />
      <InputRadioBoxGroup title="Layout" :values="settings.layoutoptions" v-model="settings.layout"/>
      <div>
        <div class="layout__negative-margin--8">
          <BaseButton isSecondary @click="$refs.standardConfig.open();">Zusammensetzung</BaseButton>
          <BaseButton isSecondary @click="$refs.einfachConfig.open();">Zusammensetzung Vereinfacht</BaseButton>
        </div>
      </div>
    </div>
    <div class="box__container" v-if="initialize">
      <InputCheckBoxItem label="Rendite-Risiko-Chart" v-model="settings.showRisiko" />
      <InputRadioBoxGroup title="Zeitraum" :values="settings.RisikoJahreOptions" v-model="settings.RisikoJahreValue"/>
      <InputField label="Tendenz - Anzahl Wochen" v-model="settings.risikoRenditeTendenz" isComponentHalfSize />
    </div>
    <div class="box__container" v-if="initialize">
      <InputCheckBoxItem label="Wertentwicklung-Chart" v-model="settings.showWertEntw" />
      <ComboBox label="Zeitraum - die lezten Jahre"  v-model="settings.WertEntwJahreValue" :values="settings.WertEntwJahreOptions" isComponentHalfSize />
      <ComboBox label="Reihenfolge der Fonds"  v-model="settings.WertEntwSort" :values="settings.WertEntwSortOptions" isComponentHalfSize />
    </div>

    <ModalColumnConfig
      ref="standardConfig"
      title="Sichtbarkeit und Reihenfolge"
      :tableHeaders="standardHdrs"
      @orderChanged="(hdrs) => setHeaders(hdrs, true)"
      @onRestoreDefault="restoreDefault(true)"/>
    <ModalColumnConfig
      ref="einfachConfig"
      title="Sichtbarkeit"
      :ordered="false"
      :tableHeaders="einfachHdrs"
      @orderChanged="(hdrs) => setHeaders(hdrs, false)"
      @onRestoreDefault="restoreDefault(false)"/>

    <div class="box__container">
      <BaseBackButtonBreadcrumb :showAsSecondaryButton="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import BaseButton from '@/components/core/BaseButton.vue';
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import ComboBox from '@/components/core/forms/ComboBox';
import InputField from '@/components/core/forms/InputField';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import ModalColumnConfig from '@/components/table/ModalColumnConfig.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'

export default {
  components: {
    BaseBackButtonBreadcrumb,
    ComboBox,
    InputCheckBoxItem,
    InputField,
    InputRadioBoxGroup,
    InputRadioBoxItem,
    BaseButton,
    ModalColumnConfig,
    PageHeaderTitleNavigation,
    OptionMenu,
  },
  data: function() {
    return {
      initialize: false,
      save: null,
      hdrs: null,
      items: null,
      standardHdrs: null,
      standardVisible: null,
      einfachHdrs: null,
      einfachVisible: null,
    }
  },
  computed: {
    ...mapGetters({
      settings: FONDSVERGLEICH_TYPES.GETTERS.SETTINGS,
    }),
  },
  watch: {
    settings: 'onSettings'
  },
  mounted() {
    if (this.settings) {
      this.onSettings();
    }
  },
  beforeDestroy() {
    const arg = this.getParams();
    let changes = false;
    const updates = {};
    Object.keys(arg).forEach( key => {
     if (arg[key] !== this.save[key]) {
        changes = true;
        updates[key] = arg[key];
      }
    })
    if (changes) {
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.EINSTELL, updates);
    }
  },
  methods: {
    onSettings() {
      this.hdrs = {};
      this.items = [];
      this.settings.headers.forEach( hdr => {
        this.hdrs[hdr.key] = hdr;
        this.items.push(hdr.key);
      });
      let item;
      this.standardHdrs = [];
      this.standardVisible = {};
      this.einfachHdrs = [];
      this.einfachVisible = {};
      this.settings.spalten[0].forEach( (key, k) => {
        item = JSON.parse(JSON.stringify(this.hdrs[key]));
        item.visible = this.settings.spalten[1][k] === 'Ja';
        this.standardHdrs.push(item);
        if (item.visible) {
          this.standardVisible[key] = true;
          if (this.settings.pdfConfig.find( r => r === key)) {
            this.einfachVisible[key] = true;
          }
        }
      });
      this.items.forEach( key => {
        if ( this.standardVisible[key] ) {
          item = JSON.parse(JSON.stringify(this.hdrs[key]));
          item.visible = !!this.einfachVisible[key];
          this.einfachHdrs.push(item);
        }
      });
      this.save = JSON.parse(JSON.stringify(this.getParams()));
      this.initialize = true;
    },
    setHeaders(hdrs, standard) {
      if ( standard ) {
        this.standardVisible = {};
        this.standardHdrs=hdrs;
        this.einfachHdrs = [];
        const einfachVisible = {};
        hdrs.forEach( item => {
          if (item.visible) {
            this.standardVisible[item.key] = true;
            einfachVisible[item.key] = this.einfachVisible[item.key];
          }
        });
        this.einfachVisible = einfachVisible;
        let item;
        this.items.forEach( key => {
          if ( this.standardVisible[key] ) {
            item = JSON.parse(JSON.stringify(this.hdrs[key]));
            item.visible = !!this.einfachVisible[key];
            this.einfachHdrs.push(item);
          }
        });
      } else {
        this.einfachHdrs=hdrs;
        hdrs.forEach( item => {
          this.einfachVisible[item.key] = item.visible;
        });
      }
    },
    restoreDefault(standard) {
      if ( standard) {
        this.standardVisible = {};
        this.standardHdrs = [];
        this.einfachHdrs = [];
        this.einfachVisible = {};
        let item;
        this.settings.headers.forEach( hdr => {
          item = JSON.parse(JSON.stringify(hdr));
          item.visible = true;
          this.standardHdrs.push(item);
          this.standardVisible[item.key] = true;
          item = JSON.parse(JSON.stringify(hdr));
          item.visible = true;
          this.einfachHdrs.push(item);
          this.einfachVisible[item.key] = true;
        });
      } else {
        this.einfachHdrs.forEach( item => {
          item.visible = true;
          this.einfachVisible[item.key] = true;
        })
      }
    },
    getParams() {
      return {
        spalten: this.standardHdrs.map( item => encodeURIComponent(item.key + (item.visible?'=Ja':'=Nein'))).join('!§!'),
        pdfConfig: this.items.map( v => encodeURIComponent(this.einfachVisible[v] ? v+'=Ja' : v + '=Nein')).join('!§!'),
        berechnungsbasis: this.settings.berechnungsbasis,
        hauptkriterium: this.settings.hauptkriterium,
        showDaten: this.settings.showDaten,
        RisikoJahreValue: this.settings.RisikoJahreValue,
        showRisiko: this.settings.showRisiko,
        steuerkirchevalue: this.settings.steuerkirchevalue,
        steuerkirche: this.settings.steuerkirche,
        steuerindividuell: this.settings.steuerindividuell,
        WertEntwJahreValue: this.settings.WertEntwJahreValue,
        steuerart: this.settings.steuerart,
        risikoRenditeTendenz: this.settings.risikoRenditeTendenz,
        WertEntwSort: this.settings.WertEntwSort,
        pdfversion: this.settings.pdfversion,
        layout: this.settings.layout,
        steuersoli: this.settings.steuersoli,
        showWertEntw: this.settings.showWertEntw,
      };
    },
  }

}
</script>

<style scoped>
.btn-clean {
  color: var(--color-text);
}
</style>
