<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Versicherungsdokumente hochladen/zuordnen (nur ZIP-Dateien)"
      :actions="headerActions" @action-SELECT-FILE="onFileChange" en/>
    <ComboBox :values="this.COMBO" :firstEmpty="false" v-model="company" label="Versicherungsanbieter">

    </ComboBox>
    <div style="color: var(--color-danger)" v-if="RESULT && RESULT.warning"> {{ RESULT.warning }}</div>
    <div>
      <BaseButton v-if="this.FILE && this.FILE.id" @click="upload()" :disabled="this.worked">Hochladen: {{ this.FILE.name }}</BaseButton>
    </div>
    <BoxContainer>


      <InputTextArea v-if="RESULT && RESULT.result" :rows="12" :hideScrollBar="true" :autoGrow="true"
        v-model="RESULT.result" />

    </BoxContainer>

  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import TYPES from "@/store/inszipfile/types";
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue"
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { uploadFileChunked } from "@/helpers/upload-helper";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import {
  TextColumn,
  ActionColumn,
  SimpleAction,

} from "@/components/table2/table_util.js";

import { PageHeaderSimpleAction, PageHeaderFileSelectAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Sipgate Liste';

export default {
  components: {

    OptionMenu,
    InputField,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    BaseModal,
    GhostLoading,
    NoData,
    ComboBox,
    BaseButton,
    InputTextArea,

    BoxContainer

  },
  data() {
    return {
      company: 'ALLGEMEIN',
      FILE: null,
      worked: false,
    };
  },
  computed: {
    ...mapGetters({
      COMBO: TYPES.GETTERS.COMBO,
      RESULT: TYPES.GETTERS.RESULT
    }),



    headerActions() {
      return [


        PageHeaderFileSelectAction(
          "SELECT-FILE",
          "Dokument auswählen",
          ".zip").withDisabled(() => this.worked)

      ];
    },
  },

  mounted() {
    this.$store.dispatch(TYPES.ACTIONS.COMBO);
  },

  methods: {
    async onFileChange(files) {
      try {
        this.worked = true;
        this.FILE = null;
        this.$store.commit(TYPES.MUTATIONS.RESULT, null);
        let file = files[0];

        let idd = await uploadFileChunked(file);
        this.FILE = { id: idd, name: file.name }
      } finally {
        this.worked = false;
      }

    },
    async upload() {
      try {
        this.worked = true;
        let confirm = this.RESULT != null && this.RESULT.warning != null
        let input = { confirmed: confirm, gesellschaft: this.company, tempfileId: this.FILE.id };
        await this.$store.dispatch(TYPES.ACTIONS.UPLOAD, input);
      } finally {
        this.worked = false;
      }
    }
  }
}


</script>
<style scoped>
.positionDivider {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>