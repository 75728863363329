<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="customOptionMenu"
    />

    <KeepAliveOnBackNavigation>
      <WertpapierSearch
        :name="searchKey"
        :type="wi_type"
        :isSelectable="wi_type=='I'"
        :tableHeaderActions="tableHeaderActions"
        :canAddToFavoritenliste="isBrokerOrBypass"
        :canAddToMyDepot="isBrokerOrBypass"
        :canAddToFondsvergleich="isBrokerOrBypass"
        @selected="onSelect"
        @headerAction="headerAction"
      />
    </KeepAliveOnBackNavigation>

    <AddFondsToListModal ref="addFondsToListModal" />
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import { mapGetters } from 'vuex';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import KeepAliveOnBackNavigation from '@/components/core/KeepAliveOnBackNavigation.vue';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from "@/router/roles";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import AddFondsToListModal from '@/components/fondsinfo/AddFondsToListModal.vue';

const ID_INVESTMENTFONDS = '1';
const ID_ZERTIFIKATE = '2';
const ID_BETEILIGUNGEN = '3';

const TYPE_INVESTMENTFONDS = 'I';
const TYPE_ZERTIFIKATE = 'Z';
const TYPE_BETEILIGUNGEN = 'B';

export default {
  components: {
    WertpapierSearch,
    PageHeaderTitleNavigation,
    OptionMenu,
    KeepAliveOnBackNavigation,
    AddFondsToListModal,
  },
  data: function() {
    return {
      wi_type: TYPE_INVESTMENTFONDS,
      selectedRows: [],
    }
  },
  created() {
    if ( this.phase && this.phase.wp_type ) {
      if (this.phase.wp_type === ID_ZERTIFIKATE) {
        this.wi_type = TYPE_ZERTIFIKATE;
      } else if (this.phase.wp_type === ID_BETEILIGUNGEN) {
        this.wi_type = TYPE_BETEILIGUNGEN;
      }
    }
  },
  computed: {
    ...mapGetters({
      getWPIAct: WERTPAPIERINFO_TYPES.GETTERS.ACT,
      getWPIFilter: WERTPAPIERINFO_TYPES.GETTERS.FILTER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    phase() {
      const result = this.getWPIAct('searchMenu..');
      return result && result.phase;
    },
    config() {
      return this.getWPIFilter;
    },
    showZertifikate() {
      return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_ZERTIFIKATE, BROKER_LEVEL_ROLES.ZUGRIFF_ZERTIFIKATE]])
    },
    customOptionMenu() {
      const { config } = this;
      const menus = config?.[0].filter(item => (this.showZertifikate || (!this.showZertifikate && item !== ID_ZERTIFIKATE))) || [];
      return menus.map?.((item) => ({
        label: config[item],
        textBold: this.wi_type == config[item].charAt(0),
        action: () => {
          this.setWIType(config[item].charAt(0))
        },
      }));
    },
    searchKey() {
      return this.$route.meta?.filterKey || 'searchMenu'
    },
    tableHeaderActions() {
      if (this.wi_type == TYPE_INVESTMENTFONDS) {
        return [
          PageHeaderSimpleAction('ADD_FAVORITENLISTE', 'Markierung zur Favoritenliste hinzufügen')
            .withDisabled(() => !this.selectedRows.length),
          PageHeaderSimpleAction('ADD_MYDEPOT', 'Markierung zum Musterdepot hinzufügen')
            .withDisabled(() => !this.selectedRows.length),
          PageHeaderSimpleAction('ADD_FONDSVERGLEICH', 'Markierung zum Fondsvergleich hinzufügen')
            .withDisabled(() => !this.selectedRows.length),

        ];

      }
      return [];
    }
  },
  methods: {
    setWIType(type) {
      this.wi_type = type;
    },
    onSelect(rows) {
      this.selectedRows = rows
    },
    headerAction({key}) {
      let liste = '';
      if (key === 'ADD_FAVORITENLISTE') {
        liste = 'Favoritenlisten'
      } else if (key === 'ADD_MYDEPOT') {
        liste = 'MyDepot'
      } else if (key == 'ADD_FONDSVERGLEICH') {
        liste = 'Fondsvergleich'
      }
      if (liste) {
        this.$refs.addFondsToListModal.open(this.selectedRows, liste)
      }
    }
  }
}
</script>
