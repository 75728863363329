import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    versicherungen: [],
    versicherungenDetails: {
      insurance: {
        action: null,
        barChart: null,
        beginn: null,
        bezeichnung: null,
        dateUpdatetdGDV: null,
        description: {id:null},
        doks: null,
        einlesen_updaten: null,
        ende: null,
        externeKennung: null,
        fremdanlage: null,
        fehler: [],
        gesellschaft: {id:null},
        groupId: null,
        id: null,
        isEigenerVertrag: null,
        isFremdvertrag: null,
        kategorie: null,
        kategorieName: null,
        kuendiegungsMailPojo: null,
        kundennr: null,
        maklerGesellNr: null,
        nummer: null,
        praemiebto_zahlweise: null,
        praemiento_zahlweise: null,
        produktbez: null,
        rueckkaufswert: null,
        sparten: null,
        status: null,
        statusAktuell: null,
        tarif: null,
        versObject: null,
        vtgGruppe: null,
        weitereSparten: null,
        zahlrhythm: null,
        zahlungsart: null,
      }
    },
    versicherungDetailsEdited: {},
    filteredCategory: null,
    categories: [],
    allCategories: [],
    editingDetails: false,
    insuranceDetails: {},
    spartenList: [],
    gesellschaftList: [],
    zahlrhythmusList: [],
    depotPositions: {},
    insuranceFiltersData: {},
    insurancesFiltered: {},
    tempFiles: [],
    deletedSchadensmeldungId:null,
    vertragsdatenFieldsDefinition: null,
    categorySelected: {category: null, group: null},
    gesellschaftMaklerGesellNr: {},
    versicherungFormValidation: {},
    antragCheckVersData: null,
    pruefstatusChange: null,
    anmerkungen: [],
    insurancesByCustomer: {},
    courtage: [],
    abrechnungen: [],
    insuranceCopy: null,
    eigenbundeneWertpapiereEdit: null,
    vertragsPersonenData: {},
    vertragPersonenList: [],
    fahrzeugdatenList: [],
    vertragsverschiebungenData: {},
    nodeId: null,
    sparten: {},
    canEditAnmerkung: false,
    foundCustomer: {},
    versicherungsSummen: [],
    insuranceCourtageConfig: {},
    maklerVollmacht: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}