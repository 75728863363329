<template>
  <div class="dashboard__container">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <DashboardCustomer v-if="isCustomer"/>
    <DashboardBroker v-if="isBrokerOrByPass"/>
  </div>
</template>

<script>
import DashboardCustomer from '@/components/home/DashboardCustomer.vue'
import DashboardBroker from '@/components/home/DashboardBroker.vue'
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import { VIEW_ROLES } from '@/router/roles';
import DASHBOARD_TYPES from '@/components/dashboard/store/types'
import GRAPHICAL_COURSE_TYPES from '@/store/graphicalCourse/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    })
  },
  mounted() {
    const hasRoles = this.$store.getters[CORE_TYPES.GETTERS.HAS_ROLES]

    if (this.isIntern && !this.isBrokerOrByPass) {
      if (this.$route.path !== '/customer/maklersuche') {
        this.$router.push({ path: '/customer/maklersuche' }).catch(e => {});
      }
    }
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CONFIGURATION);
    if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
      this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, {site: 'page', depotid: -1});
    }
  },
  components: {
    DashboardCustomer,
    DashboardBroker,
    OptionMenu,
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Übersicht', 
      to,
      from,
      breadcrumb: "Dashboard"
    });

    next();
  },   
}
</script>
