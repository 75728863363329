var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Daten einlesen"
        }
      }),
      _c(
        "BaseFilter",
        {
          attrs: {
            title: "Termine für geplante Interfaceläufe",
            filterId: "Termine für geplante Interfaceläufe",
            configFilter: _vm.filterConfig,
            metadata: _vm.metadata,
            defaultOptions: _vm.defaultOptions,
            showSaveButton: "",
            immidiateSearch: true
          },
          on: { onFilter: _vm.onFilter }
        },
        [
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: { click: _vm.newInterfaceSetting }
            },
            [_vm._v(" Anlegen ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading || !_vm.rows.length
            ? _c("div", [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" Termine für geplante Interfaceläufe ")
                ]),
                _c("br")
              ])
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "fd693e33-ef47-4c06-953e-1aea4b49b8b4",
                  title: "Termine für geplante Interfaceläufe",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "action-EDIT": _vm.showLineDetails,
                  "action-DELETE": _vm.deleteRow
                }
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            modalTitle: "Termineingabe für Interfacelauf",
            labelButtonConfirm: "Speichern",
            labelButtonCancel: "Abbrechen",
            autoClose: false
          },
          on: { onConfirmButton: _vm.saveInterface }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Interface Id",
              value: _vm.modalData.interfaceId,
              disabled: ""
            }
          }),
          _c("InputTextArea", {
            attrs: {
              label: "Beschreibung",
              value: _vm.modalData.description,
              disabled: ""
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Gesellschaft", values: _vm.gesellschaftOptions },
            on: {
              input: function($event) {
                _vm.reloadComboboxes()
                _vm.updateInterface()
              }
            },
            model: {
              value: _vm.modalData.gesellschaft,
              callback: function($$v) {
                _vm.$set(_vm.modalData, "gesellschaft", $$v)
              },
              expression: "modalData.gesellschaft"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Interface", values: _vm.interfaceOptions },
            on: { input: _vm.updateInterface },
            model: {
              value: _vm.modalData.interfaceName,
              callback: function($$v) {
                _vm.$set(_vm.modalData, "interfaceName", $$v)
              },
              expression: "modalData.interfaceName"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Mit nicht aktiven Interfaces", inLineLabel: "" },
            on: { input: _vm.reloadComboboxes },
            model: {
              value: _vm.modalData.withInactiveInterfaces,
              callback: function($$v) {
                _vm.$set(_vm.modalData, "withInactiveInterfaces", $$v)
              },
              expression: "modalData.withInactiveInterfaces"
            }
          }),
          _c("InputCheckBoxGroup", {
            attrs: {
              values: [
                { label: "Montag", path: 0 },
                { label: "Dienstag", path: 1 },
                { label: "Mittwoch", path: 2 },
                { label: "Donnerstag", path: 3 },
                { label: "Freitag", path: 4 },
                { label: "Samstag", path: 5 },
                { label: "Sonntag", path: 6 }
              ]
            },
            model: {
              value: _vm.modalData.days,
              callback: function($$v) {
                _vm.$set(_vm.modalData, "days", $$v)
              },
              expression: "modalData.days"
            }
          }),
          _c("TimePicker", {
            attrs: { label: "Bis Uhrzeit" },
            model: {
              value: _vm.modalData.time,
              callback: function($$v) {
                _vm.$set(_vm.modalData, "time", $$v)
              },
              expression: "modalData.time"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }