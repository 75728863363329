var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: [],
          actions: _vm.pageActions
        },
        on: { "action-ADD": _vm.gotoWertpapierinfo }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "1010ff5d-7c8f-4753-a432-46b33878b057",
                  title: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  headerActions: _vm.tableActions,
                  rowId: "isin",
                  rowsPerPage: 25,
                  scrollHorizontally: true,
                  exportConfig: {
                    roottext: _vm.$appNavigation.currentMenu.label
                  }
                },
                on: {
                  "click-fondsname": function($event) {
                    return _vm.gotoFondsinfo($event && $event.isin)
                  },
                  "action-EDIT": _vm.edit,
                  "action-DELETE": function($event) {
                    return _vm.deleteRows($event)
                  },
                  "headerAction-EDIT": _vm.edit,
                  "headerAction-DELETE": _vm.deleteRows
                },
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "bemerkModal",
          attrs: { modalTitle: "Bemerkung" },
          on: { onConfirmButton: _vm.saveBem }
        },
        [
          _c(
            "form",
            [
              _c("InputTextArea", {
                attrs: { label: "" },
                model: {
                  value: _vm.comment,
                  callback: function($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }