var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: "",
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: [],
          actions: _vm.actions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.setFVP(null)
          },
          "action-SETTINGS": _vm.showSettings
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Fondsvergleichsfilter",
          filterId: "a3c7c0c6-31cd-40b8-8d1d-0440bae1e485",
          metadata: _vm.filterMetadata,
          configFilter: _vm.configFilter,
          showSaveButton: true,
          defaultOptions: _vm.filter
        },
        on: {
          onFilter: function($event) {
            return _vm.search($event)
          },
          onFilterZurucksetzen: function($event) {
            return _vm.search(null)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.paramList
            ? _c("Table", {
                attrs: {
                  tableId: "da1ef4a1-2271-4732-9133-288a7d980dff",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.paramList,
                  rowId: "id",
                  rowsPerPage: 20,
                  exportConfig: { roottext: "Fondsvergleichsliste" },
                  sortableRows: "",
                  headerActions: _vm.tableActions,
                  mobileConfig: {
                    title: "description",
                    headers: [
                      "dateCreated",
                      "lastUpdate",
                      "allowedToSee",
                      "kundenname"
                    ]
                  }
                },
                on: {
                  clickRow: _vm.setFVP,
                  "click-description": _vm.setFVP,
                  "action-DELETE_PARAMETER": _vm.deleteFVP,
                  "action-COPY_PARAMETER": _vm.copyFVP,
                  "action-SHOW_TO_CUSTOMER": _vm.toggleShowToCustomer,
                  orderChanged: _vm.orderChanged,
                  "headerAction-DELETE": _vm.deleteIds
                },
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "messageRef",
          attrs: {
            modalTitle: _vm.configMess.text,
            showConfirmButton: _vm.configMess.showConfirmButton,
            labelButtonCancel: _vm.configMess.labelButtonCancel,
            labelButtonConfirm: "Ja"
          },
          on: { onConfirmButton: _vm.confitmDeleteFVP }
        },
        [
          _c("div", [
            !_vm.paramList
              ? _c("div", [_vm._v("Keine Parameter wurden gespeichert.")])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "copiedName",
          attrs: {
            modalTitle: "",
            showConfirmButton: true,
            labelButtonCancel: "Abbrechen",
            labelButtonConfirm: "Speichern"
          },
          on: { onConfirmButton: _vm.sendCopyFVP }
        },
        [
          _c(
            "div",
            [
              _c("InputField", {
                attrs: { label: "Speichern unter:", placeholder: "Unbennant" },
                model: {
                  value: _vm.saveAs,
                  callback: function($$v) {
                    _vm.saveAs = $$v
                  },
                  expression: "saveAs"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }