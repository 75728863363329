var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Haftungserklärung",
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions
        }
      }),
      _c("HaftungserklaerungsUntervermittler", {
        on: { close: _vm.close, confirmed: _vm.close }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }