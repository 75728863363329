<template>
    <div>
        <PageHeaderTitleNavigation 
            title="Haftungserklärung"
            :id="$appNavigation.currentOptionMenuId"
            :actions="headerActions"
        />
        <HaftungserklaerungsUntervermittler @close="close" @confirmed="close"/>
    </div>
</template>

<script>

import HaftungserklaerungsUntervermittler from './HaftungserklaerungsUntervermittler.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { addParamToURL } from '@/helpers/utils-helper';

export default {
    components: {
        HaftungserklaerungsUntervermittler,
        PageHeaderTitleNavigation,
    },
    computed: {
        headerActions() {
            return [];
        },
    },
    methods: {
        close() {
            this.$router.push(addParamToURL('/home'));
        }
    }
}
</script>

<style>

</style>