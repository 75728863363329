var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.actions
        },
        on: {
          action: function($event) {
            return _vm.$emit("action-" + $event.key, $event.value)
          },
          "action-BRIEF_VORLAGE": _vm.briefVorlage,
          "action-PRODUKTE": _vm.openProdukte,
          "action-BUCHUNG_GESELLSCHAFT": function($event) {
            return _vm.doBuchung("buchungGesellschaft")
          },
          "action-BUCHUNG_LAGERSTELLE": function($event) {
            return _vm.doBuchung("buchungLagerstelle")
          },
          "action-BUCHUNG_BANKKONTO": function($event) {
            return _vm.doBuchung("buchungBankkonto")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }