var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showFilters
        ? _c("BaseFilter", {
            attrs: {
              title: "Offene Unterschriften Filter",
              filterId: _vm.baseFilterSaveKey,
              metadata: _vm.searchMetadata,
              configFilter: _vm.configFilter,
              showSaveButton: true,
              immidiateSearch: "",
              defaultOptions: _vm.defaultOptions
            },
            on: {
              onFilter: function($event) {
                return _vm.search($event)
              },
              onFilterZurucksetzen: function($event) {
                return _vm.search(null)
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex box__container" },
        [
          _c("span", { staticClass: "col box__title" }, [
            _vm._v("Liste der offenen Unterschriften")
          ]),
          _c(
            "BaseContextMenu",
            [
              !_vm.isLoading
                ? [
                    _c(
                      "ContextMenuItem",
                      {
                        on: {
                          click: function($event) {
                            return _vm.exportFile("pdf")
                          }
                        }
                      },
                      [_vm._v("PDF")]
                    ),
                    _c(
                      "ContextMenuItem",
                      {
                        on: {
                          click: function($event) {
                            return _vm.exportFile("xlsx")
                          }
                        }
                      },
                      [_vm._v("Excel")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cards__container layout__negative-margin--8" },
        [
          _vm.initialLoad
            ? [
                _vm.initialLoad
                  ? _c(
                      "div",
                      { staticClass: "box__container cards__item" },
                      [
                        _c(
                          "GhostLoading",
                          [
                            _c("Block", {
                              attrs: { type: "title", width: "50%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "75%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "75%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.initialLoad
                  ? _c(
                      "div",
                      { staticClass: "box__container cards__item" },
                      [
                        _c(
                          "GhostLoading",
                          [
                            _c("Block", {
                              attrs: { type: "title", width: "50%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "75%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "75%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm.offeneUnterschriften.length
            ? [
                _c(
                  "ScrollLoading",
                  {
                    attrs: {
                      hasScroll: _vm.shouldLoadNextPage,
                      loadingDisabled: ""
                    },
                    on: { scroll: _vm.onScroll }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cards__container layout__negative-margin--8"
                      },
                      _vm._l(_vm.offeneUnterschriften, function(elem, index) {
                        return _c(
                          "div",
                          {
                            key: elem.dokId + index,
                            staticClass: "box__container cards__item"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "cards__item-header" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "box__title breakable-words" },
                                  [_vm._v(_vm._s(_vm.getTitle(index, elem)))]
                                ),
                                _vm.isActionsMenuVisible(elem) &&
                                _vm.canGenerateSignoViewerLink(elem)
                                  ? _c(
                                      "BaseContextMenu",
                                      [
                                        _vm.isBroker || _vm.isIntern
                                          ? [
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openOffeneUnterschriftenDisableWarnung(
                                                        elem
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Warnung Deaktivieren (inkl. Kommentar) "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.shouldShowMoreDetails(elem)
                                          ? [
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectOpenSignatureDocumentDetail(
                                                        elem
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" weitere Details ")]
                                              )
                                            ]
                                          : _vm._e(),
                                        elem.zusDokumente
                                          ? [
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectAdditionalDocuments(
                                                        elem
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " zusätzliche Dokumente anzeigen "
                                                  ),
                                                  _vm.loading[
                                                    elem.zusDokumente.nodeId
                                                  ]
                                                    ? _c("AnimatedSpinner", {
                                                        staticClass: "ml-2"
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.isBroker || _vm.isIntern
                                          ? [
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openVorlageModal(
                                                        elem
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Vorlage des Dokuments zurückziehen "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("div", [
                              _c("div", { staticClass: "mb-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "font-bold",
                                    class: _vm.getStatusClass(elem)
                                  },
                                  [_vm._v(_vm._s(_vm.getStatusStr(elem)))]
                                ),
                                elem.missingSignatureDescription
                                  ? _c("span", [
                                      _vm._v(
                                        " | " +
                                          _vm._s(
                                            elem.missingSignatureDescription
                                          )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]),
                            _c("div", { staticClass: "parameters-list" }, [
                              _c(
                                "div",
                                [
                                  !_vm.isCustomer
                                    ? _c("div", { staticClass: "col-12" }, [
                                        elem.dokSignaturStornosBestaetigt &&
                                        elem.dokSignaturStornosBestaetigt
                                          .disableStornosBestaetigt
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "color-danger font-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    "Warnung wurde deaktiviert "
                                                  )
                                                ]
                                              ),
                                              elem.dokSignaturStornosBestaetigt
                                                .disableComment
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          elem
                                                            .dokSignaturStornosBestaetigt
                                                            .disableComment
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _c("div", [
                                          _vm._v(" Kunde: "),
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openCustomerNewTab(
                                                    elem
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(elem.lastName) +
                                                  ", " +
                                                  _vm._s(elem.firstName)
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._l(_vm.getParameterList(elem), function(
                                    param,
                                    index4
                                  ) {
                                    return _c("div", { key: index4 }, [
                                      param.type === "IMAGE"
                                        ? _c("div", [
                                            _vm._v(
                                              " " + _vm._s(param.label) + " "
                                            )
                                          ])
                                        : _vm._e(),
                                      param.type !== "IMAGE"
                                        ? _c("div", [
                                            param.label
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(param.label) + ": "
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "breakable-words"
                                              },
                                              [_vm._v(_vm._s(param.value))]
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  })
                                ],
                                2
                              ),
                              _vm.canGenerateSignoViewerLink(elem)
                                ? _c(
                                    "div",
                                    { staticClass: "action-button-list mt-3" },
                                    [
                                      _c("SignoViewerLink", {
                                        attrs: {
                                          disabled: _vm.readOnly,
                                          title: "Elektronisch unterschreiben",
                                          requestURL: "",
                                          extraParams: "noRedirect=true",
                                          href: _vm.signoLinkMaker(
                                            elem.viewFileId,
                                            elem.nodeId
                                          )
                                        }
                                      }),
                                      _vm.showButtonDokumentAbschliessen &&
                                      elem.showSubmitButton
                                        ? _c(
                                            "BaseButton",
                                            {
                                              staticClass: "mr-0",
                                              attrs: {
                                                disabled:
                                                  _vm.readOnly ||
                                                  elem.status !== "KOMPLETT",
                                                isLink: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.submitDocument(
                                                    elem
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Dokument abschließen ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            : _c(
                "div",
                { staticClass: "box__container cards__item" },
                [_c("NoData")],
                1
              )
        ],
        2
      ),
      _c("RemoveVorlageModal", {
        ref: "removeVorlageModal",
        attrs: {
          selectedElement: _vm.selectedElement,
          isBroker: _vm.isBroker || _vm.isIntern
        },
        on: {
          error: function($event) {
            _vm.errorStr = $event
            _vm.$refs.fehlerVorlage.open()
          }
        }
      }),
      _vm.showOffeneUnterschriftenDisableWarnung
        ? _c("OffeneUnterschriftenDisableWarnung", {
            attrs: { selectedElement: _vm.selectedElement },
            on: { close: _vm.closeShowOffeneUnterschriftenDisableWarnung }
          })
        : _vm._e(),
      _c("BaseModal", {
        ref: "fehlerVorlage",
        attrs: { showConfirmButton: false, labelButtonCancel: "Ok" },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("ph-warning", {
                  staticClass: "mr-2",
                  staticStyle: { color: "orangered" },
                  attrs: { size: 16 }
                }),
                _vm._v(" " + _vm._s(_vm.errorStr) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "modalDownloadPin",
          attrs: {
            modalTitle: "Download PIN geschützte Datei",
            showConfirmButton: true,
            labelButtonConfirm: "Herunterladen",
            labelButtonCancel: "Abbrechen",
            autoClose: false
          },
          on: { onConfirmButton: _vm.downloadPinFile, close: _vm.onCloseModal }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.downloadPinFile()
                }
              }
            },
            [
              _c("InputField", {
                attrs: {
                  label: "Bitte geben Sie die PIN ein",
                  placeholder: "Pin"
                },
                model: {
                  value: _vm.tempFilePin,
                  callback: function($$v) {
                    _vm.tempFilePin = $$v
                  },
                  expression: "tempFilePin"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "fc-form-danger" }, [
            _vm._v(" " + _vm._s(_vm.errorPinFile) + " ")
          ])
        ]
      ),
      _vm.selectedOpenSignatureDocumentDetail
        ? _c("OpenSignatureDocumentDetails", {
            attrs: {
              selectedOpenSignatureDocumentDetail:
                _vm.selectedOpenSignatureDocumentDetail
            },
            on: {
              close: function($event) {
                _vm.selectedOpenSignatureDocumentDetail = null
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getTitle(
                              null,
                              _vm.selectedOpenSignatureDocumentDetail
                            )
                          )
                        )
                      ]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "font-bold",
                            class: _vm.getStatusClass(
                              _vm.selectedOpenSignatureDocumentDetail
                            )
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getStatusStr(
                                    _vm.selectedOpenSignatureDocumentDetail
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.selectedOpenSignatureDocumentDetail
                          .missingSignatureDescription
                          ? _c("span", [
                              _vm._v(
                                " | " +
                                  _vm._s(
                                    _vm.selectedOpenSignatureDocumentDetail
                                      .missingSignatureDescription
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              729998361
            )
          })
        : _vm._e(),
      _vm.selectedAdditionalDocuments
        ? _c("OpenSignatureAdditionalDocuments", {
            attrs: {
              selectedAdditionalDocuments: _vm.selectedAdditionalDocuments
            },
            on: {
              close: function($event) {
                _vm.selectedAdditionalDocuments = null
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getTitle(
                              null,
                              _vm.selectedOpenSignatureForAdditionalDocuments
                            )
                          )
                        )
                      ]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "font-bold",
                            class: _vm.getStatusClass(
                              _vm.selectedOpenSignatureForAdditionalDocuments
                            )
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getStatusStr(
                                    _vm.selectedOpenSignatureForAdditionalDocuments
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.selectedOpenSignatureForAdditionalDocuments
                          .missingSignatureDescription
                          ? _c("span", [
                              _vm._v(
                                " | " +
                                  _vm._s(
                                    _vm
                                      .selectedOpenSignatureForAdditionalDocuments
                                      .missingSignatureDescription
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1232458873
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }