var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: "Änderungshistorie Datenschutzerklärung",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "bbdab63b-0e5b-4bdb-935b-34c55fb1a47f" }
        },
        [
          _vm.textModus
            ? _c("BaseButton", {
                staticClass: "mb-2",
                attrs: { label: "Externe Erklärung eintragen" },
                on: {
                  click: function($event) {
                    return _vm.navigateTo(
                      "externeErklaerungEintragen/" + _vm.textModus
                    )
                  }
                }
              })
            : _vm._e(),
          !_vm.loading && _vm.tableRows.length
            ? _c("p", [
                _vm._v(
                  "Hier können Sie die vom Historie der vom Kunden abgegebenen Datenschutz- / Kundenanspracheerklärungen sowie Änderungen in seinen Kommunikationseinstellungen einsehen"
                )
              ])
            : _vm._e(),
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "609f1611-cd3b-4c50-94e8-5580df622ec5",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "description",
                      fn: function(row) {
                        return [
                          row.akzeptiertDesc.status.length
                            ? _c("Pill", {
                                attrs: {
                                  label: row.akzeptiertDesc.status,
                                  type: row.akzeptiertDesc.color
                                }
                              })
                            : _vm._e(),
                          _vm.canSeeSpecialColumns
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.navigateTo("details/" + row.id)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.akzeptiertDesc.description))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.akzeptiertDesc.description))
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1381872781
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }