var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "eFonds24",
          subtitle: "Stammdaten",
          actions: _vm.headerActions
        },
        on: {
          "action-UPLOAD": function($event) {
            return _vm.uploadData()
          },
          "action-OPEN-E-FONDS": function($event) {
            return _vm.openEFonds()
          }
        }
      }),
      _c("div", { staticClass: "row my-0" }, [
        _c("div", { staticClass: "col-12" }, [_c("AntragSidetext")], 1)
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.accessRights
            ? [
                _c("EntitySelector", {
                  attrs: {
                    isComponentHalfSize: "",
                    isPersonPicker: "",
                    values: _vm.personsCombo,
                    hideNewButton: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", $event)
                    },
                    change: function($event) {
                      return _vm.getStammdaten($event)
                    },
                    edit: function($event) {
                      return _vm.editPerson(_vm.personId)
                    }
                  },
                  model: {
                    value: _vm.personId,
                    callback: function($$v) {
                      _vm.personId = $$v
                    },
                    expression: "personId"
                  }
                }),
                _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.rows,
                    hidePagination: ""
                  }
                })
              ]
            : _vm.loadingList
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("div", [_vm._v("Fehlende Berechtigungen für diesen Bereich")])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }