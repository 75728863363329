<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Freistellungsauftrag bearbeiten" />

    <div class="box__container" tid="a2e7b485-27fa-46e2-93b3-e1445b1f331c" v-if="formData">
      <ComboBox
          label="Gesellschaft"
          v-model="formData.gesellschaft" 
          :values="gesellschaften"
          :disabled="formData.gesellschaft.disabled"
          :sortComboboxValues="false"
          :firstEmpty="false"
          @change="formData.isFreieEingabe = ($event === 'FREIE_EINGABE')"
      />    

      <InputField
        label="freie Eingabe"
        v-if="formData.isFreieEingabe"
        v-model="formData.freieEingabe">
      </InputField>    

      <DatePickerField isValueAsString label=" Gültigkeit von" v-model="formData.gueltigAb"/>    
      <DatePickerField isValueAsString label=" Gültigkeit bis" v-model="formData.gueltigBis"/> 

       <InputField
        label="Betrag"
        type="currency"
        v-model="formData.betrag">
      </InputField>        

      <DatePickerField isValueAsString label="Aktuelles Datum des Verfügungsbetrags" v-model="formData.aktuellesJahr"/>        

      <InputField
        label="Verfügungsbetrag"
        type="currency"
        v-model="formData.verfuegbar">
      </InputField> 

      <InputField
        label="Verbrauchter Freibetrag"
        type="currency"
        v-model="formData.zinsen">
      </InputField>

      <div class="row mb-0">
        <span class="col-auto">Bemerkung</span>
        <InputToggleSwitch  class="col" v-if="!isCustomerOnly"
          label="Für Kunde sichtbar" :inLineLabel="true" v-model="formData.bemSichtbarKunde"
        />
      </div>
      <InputTextArea 
        label="" :rows="4" v-model="bemerkung" :disabled="isCustomerOnly"
      />
      
    </div>    


  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import FREISTELLUNGSANTRAG_TYPES from "@/store/freistellungsauftrag/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, PillColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import {
  PhPencilLine, PhPencil, PhCheck, PhTrash, PhEnvelope} from "phosphor-vue";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import { addParamToURL } from '@/helpers/utils-helper';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    InputField,
    ComboBox,
    DatePickerField,
    InputToggleSwitch,
    InputTextArea,
    InputRadioBoxGroup,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data: function () {
      return {
        resultCount: null,
        filtered: false,
        loading: false,
        formData : null,
        initialData: '',
      }
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      selectedItem: FREISTELLUNGSANTRAG_TYPES.GETTERS.SELECTED_ITEM,
      gesellschaften: FREISTELLUNGSANTRAG_TYPES.GETTERS.FREISTELLUNGSANTRAG_GESELLSCHAFTEN,
    }),
    bemerkung: {
      get() {
        return this.isCustomerOnly && this.formData.bemSichtbarKunde || !this.isCustomerOnly ? this.formData.bemerkung :  ''
      },
      set(value) {
        if (!this.isCustomerOnly) {
          this.formData.bemerkung = value;
        }
      }
    }
  },
  mounted() {
    this.formData = {...this.selectedItem, actions: undefined};
    this.initialData = JSON.stringify(this.formData);
  },
  methods: {
    // executeAction(actionData) {
    //   console.log(actionData.key);
    //   switch (actionData.key) {
    //     case 'EDIT_ITEM':
    //       console.log(actionData);
    //       break;
    //     case 'REMOVE_ITEM':
    //       console.log(actionData);
    //       this.$store.dispatch(FREISTELLUNGSANTRAG_TYPES.ACTIONS.REMOVE_ROW, {itemNummer: actionData?.row?.id}).then((response) => {
    //         console.log(response);
    //         this.retrieveData();  
    //       }); 
    //       break;     
    //     case 'SEND_EMAIL':
    //       console.log(actionData);
    //       break;                  
    //   }
    // },   
    async saveBeforeLeave() {
      if (this.initialData != JSON.stringify(this.formData)) {
        this.$store.dispatch(
                FREISTELLUNGSANTRAG_TYPES.ACTIONS.PERSIST_ROW, this.formData
              );
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Freistellungsaufträge Bearbeiten', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    await this.saveBeforeLeave();

    next();
  },


    
}
</script>