<template>
  <div class="formulare-list__container">
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <template v-if="!isLagerstelleToRedirect && !isLagerstelleAsGroupMenu">
      <PageHeaderTitleNavigation 
        :id="$appNavigation.currentOptionMenuId" 
        title="Formulare" 
        :actions="headerActions" 
        :subtitle="subtitle"
        @action-EDIT-ROWS="showConfigModal = true" />

      <GhostLoading v-if="loading" type="table" useBoxContainer />
      <div v-else-if="selectedLagerstelle && selectedLagerstelle.path">
        <div class="box__container" :tid="_generateTidFromString('box__container')">
          <div class="box__title">Formularsuche</div>
          <InputField 
            isComponentHalfSize
            placeholder="Suchen nach Bezeichnung"
            v-model="descriptionSearch"/>
        </div>

        <div class="cards__container layout__negative-margin--8" v-if="!loadingConfig">
          <div class="box__container cards__item" :tid="_generateTidFromString('box__container cards__item' + propName)" v-for="(value, propName) in groupedForms" :key="propName">
              <Table
                  tableId="0eca709b-022c-4078-871f-633007348bae"
                  :headers="header(propName)"
                  :rows="value"
                  rowId="label"
                  hidePagination
                  @click-label="handleLabelClick"
              />
          </div>
        </div>
        <div class="cards__container layout__negative-margin--8" v-else>
          <div class="box__container cards__item">
            <GhostLoading type="table" />
          </div>
          <div class="box__container cards__item">
            <GhostLoading type="table" />
          </div>
        </div>
      </div>
    </template>

    <FormulareListConfigModal
      v-if="showConfigModal"
      :defaultList="forms"
      :selectedLagerstelle="selectedLagerstelle"
      @close="closeFormulareListConfig()"/>
  </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ANTRAG_TYPES from "../../store/antrag/types";
import InputField from '@/components/core/forms/InputField.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import FormUtil from '@/components/beratung/formsMenu/forms-menu';
import FormulareListConfigModal from './FormulareListConfigModal.vue';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig';
import menuManager from '@/menu/menu-manager';

export const DEFAULT_OTHER_CATEGORY = 'Sonstiges'

const LAGERSTELLE_TO_REDIRECT = ['WERTPAPIERORDER'];
const LAGERSTELLE_AS_GROUP_MENU = ['VERSICHERUNG'];

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data: function() {
    return {
      descriptionSearch: '',
      loading: false,
      error: false,
      showConfigModal: false,
      loadingConfig: false,
    }
  },
  computed: {
    ...mapGetters({
      antragListFormulare: ANTRAG_TYPES.GETTERS.ANTRAG_LIST_FORMULARE,
    }),
    subtitle() {
      return this.selectedLagerstelle?.label || '';
    },
    lagerstelleList() {
      return this.antragListFormulare?.formGroups || [];
    },
    selectedLagerstelle() {
      return this.lagerstelleList.find(lagerstelle => lagerstelle.path === this.$route.params.lagerstelle);
    },
    isLagerstelleToRedirect() {
      const { selectedLagerstelle, } = this;
      return LAGERSTELLE_TO_REDIRECT.includes(selectedLagerstelle?.id);
    },
    isLagerstelleAsGroupMenu() {
      const { selectedLagerstelle, } = this;
      return LAGERSTELLE_AS_GROUP_MENU.includes(selectedLagerstelle?.path);
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('EDIT-ROWS', 'Formulare anpassen')
          .withDisabled(() => !this.selectedLagerstelle?.path)
      ];
    },
    forms() {
      return [ ...FormUtil.getForms(this.selectedLagerstelle), ];
    },
    filteredForms() {
      let forms = this.filterVisibleItems(this.forms);

      if (!forms || !this.descriptionSearch) {
        return forms
      }

      return forms.filter(f => f?.friendlyName?.toLowerCase?.().concat(f?.label?.toLowerCase?.()).includes(this.descriptionSearch.toLowerCase()))
    },
    groupedForms() {
      if (!this.filteredForms) {
        return this.filteredForms
      }

      const groupedForm = this.filteredForms.reduce((accu, curr) => {
        const childGroup = curr?.childGroup || DEFAULT_OTHER_CATEGORY

        if (!accu[childGroup]) {
          accu[childGroup] = []
        }
        accu[childGroup].push(curr)
        return accu
      }, {})

      return groupedForm
    },
    showOptionenMenu() {
      // hide Optionen menu if flag only Formulare Versicherung is true
      return !this.$route?.query?.onlyFV
    },
  },
  watch: {
    '$route.params.lagerstelle': {
      handler() {
        this.getConfig();
        this.retrieveAntragList();
        this.redirectToFormIfNeeded();
      },
      immediate: true,
    },
  },
  methods: {
    header(category) {
        return {
            lockedLeft: [
                TextColumn("label", category).makeAlwaysVisible().makeLink(),
            ],
        };
    },
    handleLabelClick(row) {
      const { selectedLagerstelle } = this;
      FormUtil.openForm(selectedLagerstelle, row);
    },
    async retrieveAntragList() {
      try {
        if(this.selectedLagerstelle?.path) return;

        this.loading = true;
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FORMULARE);

      } catch (error) {
        this.error = true
      } finally {
        this.loading = false;
      }
    },
    redirectToFormIfNeeded() {
      const { isLagerstelleToRedirect, } = this;
      if(!isLagerstelleToRedirect) return;

      if(this.$route.query?.backAction) {
        menuManager.openRoute('/beratung/formulare');
      } else {
        const { selectedLagerstelle, } = this;
        const formItem = FormUtil.getForms(selectedLagerstelle)[0];
        this.handleLabelClick(formItem);
      }
    },

    async getConfig(forceReload = false) {
      if(!this.selectedLagerstelle?.path) return;

      try {
        const payload = {
          configId: this.selectedLagerstelle?.path,
          configType: FC_CONFIG.FORMULARE_MENU_PERMISSION_CONFIG,
          forceReload,
        }

        this.loadingConfig = true;
  
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      } finally {
        this.loadingConfig = false;
      }
    },
    filterVisibleItems(forms) {
      if (this.loadingConfig) {
        return []
      }

      const isFormVisible = FormUtil.createIsFormVisibleCheck(this.selectedLagerstelle.path);
      return [ ...forms || [], ].filter(form => isFormVisible(form));
    },
    async closeFormulareListConfig() {
      this.showConfigModal = false;
      await this.getConfig(true);
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
    });

    next()
  },
  components: {
    PageHeaderTitleNavigation,
    Table,
    OptionMenu,
    InputField,
    GhostLoading,
    FormulareListConfigModal,
  },
}
</script>

<style scoped>
.formulare-list__item-link {
  color: var(--color-text)
}
.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__item {
    width: 100%;
    flex: 1 1 100%;
}

@media (min-width: 576px) {
  .cards__item {
    width: 45%;
    flex: 1 1 calc(50% - 16px);
  }
}

@media (min-width: 992px) {  
  .cards__item {
    width: 30%;
    flex: 1 1 calc(33.3333% - 16px);
  }
}
</style>
