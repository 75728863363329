var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Personen suchen",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addSelected()
          }
        }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Personen suchen",
              filterId: "aacc74a0-6db1-4e9b-b2b1-3e762867f5f9",
              metadata: _vm.searchMetadata,
              hasSmartSearch: "",
              configFilter: _vm.configFilter,
              showSaveButton: false,
              defaultOptions: _vm.defaultOptions
            },
            on: {
              onFilter: function($event) {
                return _vm.search($event)
              }
            }
          })
        : _vm._e(),
      _vm.searched
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Personen")]),
              _vm.searching
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "22f54d86-454a-4d12-bb48-327d98527541",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 10,
                      rowId: "personId"
                    },
                    model: {
                      value: _vm.selectedPeople,
                      callback: function($$v) {
                        _vm.selectedPeople = $$v
                      },
                      expression: "selectedPeople"
                    }
                  })
                : _c("div", [_vm._v("Keine Daten")])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }