<template>
  <div>
    <CustomerSearch
      v-if="type === 'customer'"
      :customOptionMenu="customPageHeaderMenu"
      :singleSelection="isSingleSelection"
      :isExternalSelect="isExternalSelect"
      :filterType="filterType"
      @handleBackAction="handleBackAction"
    />

    <BrokerSearch
      v-else-if="type === 'broker'"
      showWithPerson
      :customOptionMenu="customPageHeaderMenu"
      :isExternalSelect="isExternalSelect"
      @handleBackAction="handleBackAction"
    />

    <ContactSearch
      v-else
      :customOptionMenu="customPageHeaderMenu"
      :isExternalSelect="isExternalSelect"
      :filterType="type"
      @handleBackAction="handleBackAction"
    />

  </div>
</template>

<script>
import CustomerSearch from "./CustomerSearch.vue";
import BrokerSearch from "@/components/brokerSearch/BrokerSearch.vue";
import ContactSearch from "@/components/contactSearch/ContactSearch.vue";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import MSC_MARKETING_TYPES from "@/store/mscmarketing/types";
import { TabMenu } from '@/menu/menu-utils';
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  components: {
    CustomerSearch,
    BrokerSearch,
    ContactSearch,
  },
  props: {
    filterTypeProp: {
      type: String,
      default: null,
    },
    isExternalSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      optionMenu: [
        {
          label: 'Kunden',
          path: 'customer',
        },
        {
          label: 'Vermittler',
          path: 'broker',
        },
        {
          label: 'Kontakte',
          path: 'contact',
        },
        {
          label: 'Gesellschaften',
          path: 'company',
        },
      ],
    };
  },
  computed: {
    filterType() {
      return (
        this.filterTypeProp ||
        (this.$route.params.type !== "mailcomposer-fax"
          ? "MailComposer"
          : "SendFax")
      );
    },
    type() {
      return this.$route.params.folder
    },
    basePath() {
      return this.$route.path.substring(0, this.$route.path.lastIndexOf('/'));//.replace(`/${this.$route.params.folder}`, '')
    },
    isSingleSelection() {
      return this.$route?.query?.singleSelection ==='true' || false;
    },
    customPageHeaderMenu() {    
      return this.optionMenu.map(item => TabMenu(`${this.basePath}/${item.path}?${makeQueryParam(this.$route.query)}`, item.label));
    },
  },
  methods: {
    handleBackAction(values) {
      if (this.$route.query?.targetType) {
        this.$store.commit(
          MSC_MARKETING_TYPES.MUTATIONS.ADD_USER_INTO_TARGET_LIST,
          {
            values,
            targetType: this.$route.query?.targetType,
          }
        );

        this.$router.push({
          path: this.$route.query.back,
          query: {
            backAction: true,
            usersselected: true,
          },
        });
        return;
      }

      if (this.$route.query && this.$route.query.back) {
        // General back / new appointment back
        this.$store.commit(
          CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS,
          values
        );
        const urlBack = this.$route.query.back;
        if (this.$route.query.insurance) {
          // Geteilt mit Kunde
          this.$router.push({
            path: `${urlBack}${
              urlBack.includes("?") ? "&" : "?"
            }insurance=true&backAction=true`,
          });
        } else {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS,
            values
          );
          this.$router.push({
            path: `${urlBack}?usersselected=true&backAction=true`,
          });
        }
      } else {
        // Mail Composer back
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS,
          values
        );
        this.$router.push({
          path: "/communication/" + this.$route.params.type,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
