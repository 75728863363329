<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="Anlegen eines Nafi Users" 
            :actions="headerActions" 
            @action-ADD="nafiLogin()" 
        />

        <div class="row my-0">
            <div class="d-lg-none col-12">
                <div class="antrag-step__container box__container">
                    <Stepper2
                        :selectedStepKey="stepKey"
                        :stepperService="stepper"
                        @setStep="setStepByKey"
                        ref="pieStepper"
                    ></Stepper2> 
                </div>
            </div>
        </div>

        <ContentWithStepper>
            <template #stepper>
                <div class="box__container">
                <VerticalStepper
                    :selectedStepKey="stepKey"
                    :stepperService="stepper"
                    stepperName="nafiStepper"
                    @setStep="setStepByKey"
                    ref="stepper"
                ></VerticalStepper>
                </div>
            </template>
            <template #content>
                <div class="box__container">
                    <router-view></router-view>
                    <div class="mt-4">
                        <BaseButton isSecondary v-if="stepKey !== 'maklerdaten'" @click="prev()">Zurück</BaseButton>
                        <BaseButton v-if="stepKey !== 'zugangsdaten'" @click="next()">Weiter</BaseButton>
                    </div>
                </div>
            </template>
        </ContentWithStepper>
    
    </div>
</template>

<script>
import Stepper2 from '@/components/stepper/Stepper2.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import NAFI_TYPES from '@/store/nafi/types';
import mixin from '@/mixins/nafi/nafi-mixin.js';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [mixin],
    components: {
        ContentWithStepper,
        OptionMenu,
        PageHeaderTitleNavigation,
        Stepper2,
        VerticalStepper,
        BaseButton,
    },
    mounted() {
        this.$store.dispatch(NAFI_TYPES.ACTIONS.GET_NAFI_ZUGANGSDATEN);
    },
    computed: {
        stepKey() {
            return this.$route.path?.substring(this.$route.path.lastIndexOf('/') + 1);
        },
        headerActions() {
            return [
                PageHeaderSimpleAction('ADD', 'Makler anlegen bei Nafi'),
            ];
        },
    },
    methods: {
        setStepByKey(stepKey) {
            if (stepKey && stepKey !== this.stepKey) {
                this.$router.push(`/intern/nafi-zugang/${stepKey}`)
            }
        },
        prev() {
            if (this.stepKey === 'anwenderdaten') {
                this.setStepByKey('maklerdaten');
            } else if (this.stepKey === 'zugangsdaten') {
                this.setStepByKey('anwenderdaten');
            }
        },
        next() {
            if (this.stepKey === 'maklerdaten') {
                this.setStepByKey('anwenderdaten');
            } else if (this.stepKey === 'anwenderdaten') {
                this.setStepByKey('zugangsdaten');
            }
        },
        nafiLogin() {
            if (this.zugang?.anwenderKennwort) {
                this.$store.dispatch(NAFI_TYPES.ACTIONS.NAFI_LOGIN);
            } else {
                this.$confirmModal({
                    title: 'Kennwort ist erforderlich', 
                    message: 'Das Kennwort darf nicht leer sein.', 
                    labelButtonConfirm: 'Ok',
                    showCancelButton: false,
                })
            }
        },
    }
}

</script>

<style>

</style>