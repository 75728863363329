var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: {
            hoverText: "Datei hier ablegen",
            disabled: !_vm.allowVersUpload
          },
          on: {
            files: function($event) {
              return _vm.openAddVersicherungPage($event)
            }
          }
        },
        [
          _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          }),
          _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: "Versicherungen",
              actions: _vm.headerActions
            },
            on: {
              "action-KUENDIGUNG": function($event) {
                return _vm.handleMailComposer()
              },
              "action-MAIL-GESELLSCHAFTEN": function($event) {
                return _vm.handleMailComposerCompany()
              },
              "action-ADD": function($event) {
                return _vm.openAddVersicherungPage()
              }
            }
          }),
          _vm.isInsuranceCompany
            ? _c("BaseFilter", {
                attrs: {
                  title: "Versicherungsfilter",
                  saveKey: "versicherungsfilterInsuranceCompany",
                  metadata: _vm.insuranceFilterMetadata,
                  hasSmartSearch: "",
                  configFilter: _vm.configFilter
                },
                on: {
                  onFilter: function($event) {
                    return _vm.handleSearch($event)
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "insurance-group__categories" },
            _vm._l(_vm.categories, function(category, i) {
              return _c(
                "div",
                { key: i, staticClass: "box__container" },
                [
                  _c("Table", {
                    attrs: {
                      title: category.friendlyName,
                      tableId:
                        "6d94bde2-2085-4af3-8745-92ef38ac247c-" +
                        category.filter,
                      headers: _vm.headers(category),
                      rows: _vm.rows_by_category[category.filter],
                      dragnDropFilesOnRow: _vm.allowVersUpload,
                      isLoading: _vm.isLoading,
                      selected:
                        _vm.isByPass || _vm.isBroker ? _vm.selectedRows : null,
                      mobileConfig: _vm.mobileConfig
                    },
                    on: {
                      selected: _vm.onSelectedRows,
                      files: function($event) {
                        return _vm.handleRowFiles($event)
                      },
                      "click-bezeichnung": function($event) {
                        return _vm.openItemSelectedOverview($event)
                      },
                      action: function($event) {
                        return _vm.handleInsuranceAction($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "gesellschaftText",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "insurance__table--company" },
                                [
                                  row.gesellschaft.logoUrl
                                    ? _c("BaseImage", {
                                        staticClass:
                                          "insurance__table--company__logo",
                                        attrs: {
                                          src:
                                            _vm.APP_API +
                                            "/" +
                                            row.gesellschaft.logoUrl,
                                          placeholder: _vm.imagePlaceholder
                                        }
                                      })
                                    : _vm._e(),
                                  _c("span", { staticClass: "ellipsis" }, [
                                    _vm._v(
                                      _vm._s(
                                        row.gesellschaft.zweigstelleName ||
                                          row.gesellschaft.shortName
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c("DeleteInsuranceModal", {
        ref: "deleteModal",
        attrs: {
          insurance: _vm.insuranceToDelete,
          insuranceLabel: _vm.getInsuranceLabel(_vm.insuranceToDelete)
        },
        on: {
          delete: function($event) {
            return _vm.removeInsurance()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }