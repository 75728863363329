var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions,
          title: "Anlageziele Depotpositionen planen"
        },
        on: {
          "action-PLANUNG_ABSCHLIESSEN": function($event) {
            return _vm.goToAnlegerprofil()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Anlageziele Depotpositionen planen")
          ]),
          _c("span", [
            _vm._v(
              "  (Insgesamt " +
                _vm._s((_vm.positions && _vm.positions.length) || 0) +
                " Position" +
                _vm._s(_vm.positions && _vm.positions.length > 1 ? "en" : "") +
                ", davon " +
                _vm._s(
                  (_vm.positionsWithoutZeile &&
                    _vm.positionsWithoutZeile.length) ||
                    0
                ) +
                " ungeordnet)"
            )
          ])
        ]),
        _vm._m(0)
      ]),
      _vm.positions && _vm.positions.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "row justify-content-between" },
                            [
                              _c(
                                "div",
                                { staticClass: "col color-danger box__title" },
                                [
                                  _vm._v(
                                    "Positionen ohne Zuordnung - bitte einer Zeile zuweisen"
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "stock__row" }, [
                            _c("div", { staticClass: "stock__row__title" }, [
                              _vm._v(" Bestand: ")
                            ]),
                            _c(
                              "div",
                              { staticClass: "stock__row__amount" },
                              [
                                _c("NumberCell", {
                                  attrs: {
                                    row: {
                                      bestand: _vm.positionsWithoutZeileSum
                                    },
                                    column: "bestand",
                                    symbol: "€"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "mt-8px" }, [
                            _c("hr"),
                            _vm.positionsWithoutZeile &&
                            _vm.positionsWithoutZeile.length
                              ? _c(
                                  "div",
                                  [
                                    _vm._l(_vm.positionsWithoutZeile, function(
                                      pos,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c("APAnlagezielePlanenZeile", {
                                                attrs: { position: pos },
                                                on: {
                                                  zeileChanged: function(
                                                    $event
                                                  ) {
                                                    return _vm.positionZeileChanged(
                                                      pos,
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _c("div", [
                                  _vm._v(
                                    " Keine ungeordnete Position. Die Positionen sind augeteilt auf folgenden Zeilen: " +
                                      _vm._s(_vm.getZeilen) +
                                      ". "
                                  )
                                ])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2626502665
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.zeilen, function(zeile, count) {
        return _c("div", { key: count }, [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "row justify-content-between" },
                            [
                              _c("div", { staticClass: "col box__title" }, [
                                _vm._v("Zeile " + _vm._s(count + 1))
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "stock__row" }, [
                            _c("div", { staticClass: "stock__row__title" }, [
                              _vm._v(" Bestand Zielsumme ")
                            ]),
                            _c(
                              "div",
                              { staticClass: "stock__row__amount" },
                              [
                                _c("NumberCell", {
                                  attrs: {
                                    row: {
                                      bestand: _vm.bestandTotal(
                                        zeile,
                                        count + 1
                                      )
                                    },
                                    column: "bestand",
                                    symbol: "€"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "mt-8px" },
                            [
                              _c("Table", {
                                attrs: {
                                  mobileConfig: {
                                    title: "AnlagezielePlanen",
                                    headers: [
                                      "bestand",
                                      "einmalig",
                                      "ratierlich",
                                      "zeithorizont",
                                      "anlageziel"
                                    ],
                                    disableClickRow: false
                                  },
                                  headers: _vm.headers,
                                  rows: [_vm.rows[zeile.zeile]],
                                  rowId: "apanlagezieleplanen1",
                                  hidePagination: ""
                                },
                                on: {
                                  clickRow: function($event) {
                                    return _vm.openRowEditModal(
                                      zeile,
                                      count,
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "einmalig",
                                      fn: function(row) {
                                        return [
                                          _c("InputField", {
                                            attrs: {
                                              value:
                                                row[
                                                  _vm.EINMALIG_STR + zeile.zeile
                                                ],
                                              type: "currency"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.updateStore(
                                                  zeile.zeile,
                                                  _vm.EINMALIG_STR,
                                                  _vm.formatNumber($event)
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "ratierlich",
                                      fn: function(row) {
                                        return [
                                          _c("InputField", {
                                            attrs: {
                                              value:
                                                row[
                                                  _vm.RATIERLICH_STR +
                                                    zeile.zeile
                                                ],
                                              type: "currency"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.updateStore(
                                                  zeile.zeile,
                                                  _vm.RATIERLICH_STR,
                                                  _vm.formatNumber($event)
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "zeithorizont",
                                      fn: function(row) {
                                        return [
                                          _c("ComboBox", {
                                            attrs: {
                                              value:
                                                row[
                                                  _vm.HORIZONT_STR + zeile.zeile
                                                ],
                                              values: _vm.comboboxValues(
                                                count + 1,
                                                "horizont"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateStore(
                                                  zeile.zeile,
                                                  _vm.HORIZONT_STR,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "anlageziel",
                                      fn: function(row) {
                                        return [
                                          _c("ComboBox", {
                                            attrs: {
                                              value:
                                                row[_vm.ZIEL_STR + zeile.zeile],
                                              values: _vm.comboboxValues(
                                                count + 1,
                                                "anlageziel"
                                              ),
                                              firstEmpty: true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateStore(
                                                  zeile.zeile,
                                                  _vm.ZIEL_STR,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "verlust",
                                      fn: function(row) {
                                        return [
                                          _c("ComboBox", {
                                            attrs: {
                                              value:
                                                row[
                                                  _vm.VERLUST_STR + zeile.zeile
                                                ],
                                              values: _vm.comboboxValues(
                                                count + 1,
                                                "verlust"
                                              ),
                                              firstEmpty: true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateStore(
                                                  zeile.zeile,
                                                  _vm.VERLUST_STR,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "div",
                                _vm._l(
                                  _vm.positionsProZeile(count + 1),
                                  function(pos, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "row" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c("APAnlagezielePlanenZeile", {
                                              attrs: { position: pos },
                                              on: {
                                                zeileChanged: function($event) {
                                                  return _vm.positionZeileChanged(
                                                    pos,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index <
                                        _vm.positionsProZeile(count + 1)
                                          .length -
                                          1
                                          ? _c("hr", {
                                              staticClass: "positionDivider"
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ])
      }),
      _vm.rows && _vm.selectedZeile && _vm.selectedZeile.zeile
        ? _c(
            "BaseModal",
            {
              ref: "zeileBearbeitenModal",
              attrs: {
                modalTitle: "Zeile bearbeiten",
                showConfirmButton: false
              }
            },
            [
              _c("InputField", {
                attrs: {
                  label: "Einmalig",
                  value:
                    _vm.rows[_vm.selectedZeile.zeile][
                      _vm.EINMALIG_STR + _vm.selectedZeile.zeile
                    ],
                  type: "currency"
                },
                on: {
                  change: function($event) {
                    _vm.updateStore(
                      _vm.selectedZeile.zeile,
                      _vm.EINMALIG_STR,
                      _vm.formatNumber($event)
                    )
                  }
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Ratierlich",
                  value:
                    _vm.rows[_vm.selectedZeile.zeile][
                      _vm.RATIERLICH_STR + _vm.selectedZeile.zeile
                    ],
                  type: "currency"
                },
                on: {
                  change: function($event) {
                    _vm.updateStore(
                      _vm.selectedZeile.zeile,
                      _vm.RATIERLICH_STR,
                      _vm.formatNumber($event)
                    )
                  }
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Zeithorizont",
                  value:
                    _vm.rows[_vm.selectedZeile.zeile][
                      _vm.HORIZONT_STR + _vm.selectedZeile.zeile
                    ],
                  values: _vm.comboboxValues(_vm.position + 1, "horizont")
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      _vm.selectedZeile.zeile,
                      _vm.HORIZONT_STR,
                      $event
                    )
                  }
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Anlageziel",
                  value:
                    _vm.rows[_vm.selectedZeile.zeile][
                      _vm.ZIEL_STR + _vm.selectedZeile.zeile
                    ],
                  values: _vm.comboboxValues(_vm.position + 1, "anlageziel"),
                  firstEmpty: true
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      _vm.selectedZeile.zeile,
                      _vm.ZIEL_STR,
                      $event
                    )
                  }
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Bereitschaft Verluste zu tragen",
                  value:
                    _vm.rows[_vm.selectedZeile.zeile][
                      _vm.VERLUST_STR + _vm.selectedZeile.zeile
                    ],
                  values: _vm.comboboxValues(_vm.position + 1, "verlust"),
                  firstEmpty: true
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      _vm.selectedZeile.zeile,
                      _vm.VERLUST_STR,
                      $event
                    )
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("span", [
        _vm._v(
          "Bitte weisen Sie die Depotpositionen den Zeilen zu, so wie die Aufteilung der Anlageziele vom Kunden gewünscht ist. Anschließend können Sie die Neuanlage(n) eintragen und die gewünschten Zielmarktkriterien auswählen."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }