<template>
<div>
  <InsuranceOptionMenu title="Buchungsdaten" />

  <div class="box__container">
  <div class="row">
    <div class="col-6">
      <InputField
        id="buchungsnr"
        label="Buchungsnr"
        v-model="form.buchungsnr"
        disabled
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ComboBox
        id="sparte"
        label="Sparte"
        v-model="form.spartenID"
        :values="buchungFieldsDefinition.sparte"
        validateUntouched
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <DatePickerField
        id="zeichnungsdatum"
        label="Zeichnungsdatum"
        v-model="form.zeichnungsdatum"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <DatePickerField
        id="courtageeingang"
        label="courtageeingang"
        v-model="form.courtageeingDatum"
        validateUntouched
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ComboBox
        id="courtageart"
        label="Courtageart"
        v-model="form.courtageart"
        :values="buchungFieldsDefinition.courtageart"
        validateUntouched
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="courtagesumme"
        label="Courtagesumme"
        type="currency"
        :precision="2"
        v-model="form.courtagesumme"
        validateUntouched
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ComboBox
        id="courtagewaehrung"
        label="Courtagewährung"
        v-model="form.courtagewaehrung"
        :values="buchungFieldsDefinition.courtagewaehrung"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="bewertungssumme"
        label="Bewertungssumme"
        type="currency"
        :precision="2"
        v-model="form.bewertungssumme"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="monatsbeitrag"
        label="Monatsbeitrag"
        type="currency"
        :precision="2"
        v-model="form.monatsbeitrag"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputRadioBoxGroup
        v-model="form.defaultStornoreserveSatz"
        :values="buchungFieldsDefinition.stornoreserve"
        title="Stornoreserve"
        @input="updateStornoreserve()"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row" v-if="isManualStornoreserve">
    <div class="col-6">
      <InputField
        id="stornoreserve"
        type="currency"
        :precision="2"
        v-model="form.stornoreserve"
        :validateUntouched="isManualStornoreserve"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputCheckBoxItem
        label="Stornoreserve bei Gesellschaft"
        v-model="form.stornoreserveBeiGesellschaft"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row" v-if="isFK">
    <div class="col-6">
      <InputRadioBoxGroup
        v-model="form.defaultVersicherungseinbehaltSatz"
        title="Einbehalt Versicherung"
        :values="buchungFieldsDefinition.versicherungseinbehalt"
        @input="updateVersicherungseinbehalt()"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row" v-if="isFK && isManualVersicherungseinbehalt">
    <div class="col-6">
      <InputField
        id="versicherungseinbehalt"
        type="currency"
        :precision="2"
        v-model="form.versicherungseinbehalt"
        :validateUntouched="isManualVersicherungseinbehalt"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputCheckBoxItem
        label="Overhead Provision"
        v-model="form.istOverhead"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ComboBox
        id="status"
        label="Status"
        v-model="form.status"
        :values="buchungFieldsDefinition.status"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="Wechselkurs"
        label="Wechselkurs"
        type="currency"
        :precision="4"
        v-model="form.wechselkurs"
        validateUntouched
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="bemerkung"
        label="Bemerkung"
        v-model="form.bemerkung"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <InputField
        id="externeBuchungsnr"
        label="Externe Buchungsnr"
        v-model="form.externeBuchungsnr"
        :disabled="!form.editable"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ComboBox
        id="mwstSatz"
        label="MwSt Satz"
        v-model="form.mwstSatz"
        :values="buchungFieldsDefinition.mwstsatz"
        :disabled="!form.editable"
      />
    </div>
  </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import dayjs from 'dayjs';
import { required } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';

export default {
  mixins: [validator],
  components: {
    ComboBox,
    InputField,
    DatePickerField,
    InputCheckBoxItem,
    InputRadioBoxGroup,
    InsuranceOptionMenu,
  },
  data() {
    return {
      form: {
        buchungsnr : null,
        spartenID : null,
        zeichnungsdatum : null,
        courtageeingDatum : dayjs().format('DD.MM.YYYY'),
        courtageart : null,
        courtagesumme : '',
        courtagewaehrung : 'EUR',
        bewertungssumme : null,
        monatsbeitrag : null,
        defaultStornoreserveSatz : this.isFA ? '10' : '-1',
        stornoreserve : '0,00',
        stornoreserveBeiGesellschaft : null,
        defaultVersicherungseinbehaltSatz : '-1',
        versicherungseinbehalt : '0,00',
        istOverhead : false,
        status : '1',
        wechselkurs : '1,0000',
        bemerkung : null,
        externeBuchungsnr : null,
        mwstSatz : '0',
        editable: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      buchungFieldsDefinition: INSURANCE_BUCHUNG_TYPES.GETTERS.BUCHUNG_FIELDS_DEFINITIONS,
      buchungversicherung: INSURANCE_BUCHUNG_TYPES.GETTERS.BUCHUNG,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isFpPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      IsBavaria: CORE_TYPES.GETTERS.IS_BAVARIA,
    }),
    isManualStornoreserve() {
      return this.form.defaultStornoreserveSatz === '-1';
    },
    isManualVersicherungseinbehalt() {
      return this.form.defaultVersicherungseinbehaltSatz === '-1';
    },
    fieldsValidators() {
      const validators = {
        courtageart: [required('Courtageart ist erforderlich!')],
        courtageeingDatum: [required('Kaufdatum ist erforderlich!')],
        courtagesumme: [required('Courtagesumme ist erforderlich!')],
        stornoreserve: this.isManualStornoreserve ? [required('Stornoreserve ist erforderlich!')] : [],
        versicherungseinbehalt: this.isManualVersicherungseinbehalt ? [required('Einbehalt Versicherung ist erforderlich!')] : [],
        wechselkurs: [required('Wechselkurs ist erforderlich!')],
        spartenID: [required('Sparte ist erforderlich!')],
      }

      return {form: validators};
    },
  },
  methods: {
    formatDate(date) {
      if(date && !dayjs(date, 'DD.MM.YYYY').isValid()){
        return dayjs(date).format('DD.MM.YYYY');
      }
      return date;
    },
    updateStornoreserve(){
      if(!this.isManualStornoreserve) {
        this.form.stornoreserve='';
      }
    },
    updateVersicherungseinbehalt(){
      if(!this.isManualVersicherungseinbehalt) {
        this.form.versicherungseinbehalt='';
      }
    },
    saveBeforeLeave(){
      if (!this.form.editable) {
        return
      }

      this.form.zeichnungsdatum = this.form.zeichnungsdatum ? this.formatDate(this.form.zeichnungsdatum) : null;
      if(this.form.buchungsnr){
        return this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.UPDATE_BUCHUNG, {...this.form, vertragId: this.$route.params.vertragId})
      } else {
        return this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.SAVE_BUCHUNG, {...this.form, vertragId: this.$route.params.vertragId});
      }
    }
  },
  mounted(){
    this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.BUCHUNG_FIELDS_DEFINITIONS, 
    {vertragId: this.$route.params.vertragId, buchungId: this.$route.params.buchungId});
    
    if (this.$route.params.buchungId === 'neuesBuchung') {
      this.form.editable = true;
    } else if (this.$route.params.buchungId) {    
      this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.BUCHUNG_BY_ID, {buchungId: this.$route.params.buchungId})
      .then(response => {
        if(response){
          this.form = {...response}
        }
      })
    }

    this.$configureValidators(this.fieldsValidators);
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details', 
      to,
      from,
    });

    if(this.validation.form.valid){
      await this.saveBeforeLeave();
    }

    next()
  },
  validators: {},
}
</script>

<style>

</style>