<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label">
    </PageHeaderTitleNavigation>

    <HinweiseUndFehler :hints="hints" />
    
    <GhostLoading v-if="loading" useBoxContainer>
      <Block height="200" />
    </GhostLoading>
    <template v-else-if="maklerDok.length">
      <template>
        <div v-if="paraUnvoll" class="box__container">
          <div class="box__title">{{ paraUnvoll.label }}</div>
          <InputRadioBoxGroup :class="{'radio-danger':!paraOption}"
            :validateUntouched="true"
            v-model="paraOption"
            :values="paraUnvoll.values"
            :config="{validateUntouched: true}"
          >
            <template v-slot:after-F>
              <InputField v-if="paraOption=='F'" :validateUntouched="true" v-model="reg_nr_F"/>
            </template>
            <template v-slot:after-H>
              <InputField v-if="paraOption=='H'" :validateUntouched="true" v-model="reg_nr_H"/>
            </template>
            <template v-slot:after-KEINE>
              <div v-if="paraOption=='KEINE'">Sie werden in diesem Fall als Passivmakler hinterlegt und haben keinen Zugriff mehr auf Formulare im Investmentbereich.</div>
            </template>
          </InputRadioBoxGroup>
        </div>

        <div class="document-container">
          <div class="box__title">Dokumente des Vertrags</div>
          <div class="row">
            <div v-for="mdok in maklerDok" :key="mdok.key" class="makler-document col-12 col-sm-6 col-lg-4 col-xl-3">
              <div class="box__container">
                <div class="makler-document--header">
                  <div class="box__title">{{mdok.name}}</div>
                  <BaseContextMenu class="makler-document--context-menu">
                    <ContextMenuItem @click="uploadDok(mdok)">Neues Dokument hochladen</ContextMenuItem>
                  </BaseContextMenu>
                </div>
                <a v-if="mdok.file_id" :href="showDok(mdok)" target="_blank" rel="noopener noreferer" class="btn-clean clickable">{{mdok.value}}</a>
                <div v-else>Es ist kein Dokument hinterlegt.</div>
                <BaseButton v-if="mdok.error" isLink @click="showError(mdok)"><PhInfo class="text-danger"/>{{mdok.status}}</BaseButton>
                <div v-else-if="mdok.status" ><PhInfo class="text-success"/>{{mdok.status}}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="personen.length">
        <div v-for="pers in personen" :key="pers.key">
          <hr />

          <div v-if="!pers.items.length" class="box__container">
            <div class="box__title">{{pers.name}}</div>
            <a href="/intern/vermittlerdaten/vermittlerdaten" >Vermittlerdaten</a>
          </div>

          <div class="document-container">
            <div v-if="pers.items.length" class="box__title">{{pers.name}}</div>
            <div class="row">
              <div v-for="mdok in pers.items" :key="mdok.key" class="makler-document col-12 col-sm-6 col-lg-4 col-xl-3">
                <div class="box__container">
                  <div class="makler-document--header">
                    <div class="box__title">{{mdok.name}}</div>
                    <BaseContextMenu class="makler-document--context-menu">
                      <ContextMenuItem @click="uploadDok(mdok)">Neues Dokument hochladen</ContextMenuItem>
                    </BaseContextMenu>
                  </div>
                  <a v-if="mdok.file_id" :href="showDok(mdok)" target="_blank" rel="noopener noreferer" class="btn-clean clickable">{{mdok.value}}</a>
                  <div v-else>Es ist kein Dokument hinterlegt.</div>
                  <BaseButton v-if="mdok.error" isLink @click="showError(mdok)"><PhInfo class="text-danger"/>{{mdok.status}}</BaseButton>
                  <div v-else-if="mdok.status" ><PhInfo class="text-success"/>{{mdok.status}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <div v-else class="box__container"><NoData /></div>
    
    <BaseModal
      ref="errBemerk"
      :modalTitle="errorTitle"
      :showConfirmButton="false"
      labelButtonCancel="Ok"
     >
        <template v-slot>
          <div>{{errorText}}</div>
        </template>
    </BaseModal>
    
    <DocumentsUploadModal
      ref='uploadForm'
      :files='uploadFiles' 
      :additionalInputs="uploadInputs" 
      :uploadType="3" 
      :multipleFileUpload="false"
      :openOnMount="false"
      :customUpload="paraDocUpload"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import DOCUMENT_TYPES from '@/store/documents/types';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from '@/components/core/forms/InputField';
import { PhInfo } from 'phosphor-vue';
import validator from "@/mixins/validator";
import { required, regex } from "@/mixins/validator/rules";
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '../../helpers/log-message-helper';

export default {
  name: 'NotwendigeUnterlagenByMakler',
  components: {
    HinweiseUndFehler, NoData, GhostLoading, Block, BaseButton,
    InputRadioBoxGroup, InputField, BaseModal, PhInfo, DocumentsUploadModal, OptionMenu,
    PageHeaderTitleNavigation, BaseContextMenu, ContextMenuItem,
  },
  mixins: [
    validator
  ],
  // props: {
  // },
  data: function() {
    return {
      loading: true,
      describer: null,
      hints: [],
      maklerDok: [],
      personen: [],
      paraUnvoll: null,
      errorTitle: '',
      errorText: '',
      paraOption: '',
      reg_nr_F: '',
      reg_nr_H: '',
      uploadFiles: [],
      uploadKey: '',
      uploadInputs: [],
    }
  },
  validators: {
    paraOption: [required('Bitte eine Option auswählen', {forceTouch: true})],
    reg_nr_F: [regex(/^D-F-.{3}-.{4}-..$/, 'Bitte Registernummer eingeben. Format D-F-xxx-xxxx-xx', {forceTouch: true})],
    reg_nr_H: [required('Bitte Registernummer eingeben', {forceTouch: true})],
  },
  mounted() {
    this.initialise({ask: 'initialise', params: {}});
  },
  computed: {
    ...mapGetters({
      simpleFileLinkmaker: DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER,
    }),
  },
  beforeDestroy() {
    if (this.paraUnvoll) {
      let params = {para_option: this.paraOption};
      if (this.paraOption == 'F' && /^D-F-.{3}-.{4}-..$/.test(this.reg_nr_F)) {
        params.reg_nr = this.reg_nr_F;
      } else if (this.paraOption == 'H' && this.reg_nr_H ) {
        params.reg_nr = this.reg_nr_H;
      } else if ( this.paraOption !== 'KEINE') {
        params = null;
      }
      if (params) {
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.NOTWENDIGE_DOKUMENTE, {ask: 'para_option', params});
      }
    }
  },
  // watch: {
  // },
  methods: {
    async initialise(param) {
      const result = await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.NOTWENDIGE_DOKUMENTE, param);
      this.describer = result.data;
      if(result.data?.error){
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(result.data?.error, 'danger'))
        return;
      }
      this.hints = this.describer.hinweise;
      if (this.describer.paraUnvoll?.length) {
        this.paraUnvoll = {
          ...this.describer.paraUnvoll[0],
          values: this.describer.paraUnvoll[0].values //.map(v => ({value: v.value, label: v.label.replace('; Registernummer:', '')}))
        };
      }
      if (this.describer.maklerDok?.length) {
        this.maklerDok = this.describer.maklerDok.filter( md => md.dataType === 'dokument' ).map( md => {
          return this.getItemDok(md);
        });
      }
      if (this.describer.personen?.length) {
        this.personen = this.describer.personen.map( pers => {
          const data = pers.shift();
          const person = {
            key: data.key,
            name: data.label,
            checkOnline: data.checkOnline,
            items: pers.filter( md => md.dataType === 'dokument' ).map( md => {
              return this.getItemDok(md);
            })
          }
          return person;
        });
      }
      this.loading = false;
    },
    getItemDok(md) {
      const {prefix, values, valuedComponent} = this.describer;
      const pruefText = this.describer.values[prefix.pruef + md.key];
      const result = {
        key: md.key,
        name: md.label,
        value: values[prefix.name + md.key],
        id: values[prefix.mpdok + md.key],
        file_id: md.action?.param_file_id,
        status: '',
        error: '',
      };
      if ( pruefText == 'Die Prüfung ist fehlgeschlagen.' ) {
        result.status = pruefText;
        result.error = valuedComponent[prefix.bemerk + md.key];
      } else if (result.id) {
        result.status = 'zuletzt hochgeladen am ' + values[prefix.stand + md.key];
      }
      return result;
    },
    showDok(mdok) {
      return this.simpleFileLinkmaker(mdok.name, mdok.file_id);
    },
    showError(mdok) {
      this.errorTitle = mdok.name;
      this.errorText = mdok.error;
      this.$refs.errBemerk.open();
    },
    uploadDok(mdok) {
      this.uploadKey = mdok.key;
      this.uploadInputs = [];
      if (mdok.key.indexOf('legitimation') !== -1){
        this.uploadInputs.push({key:'legi_ablauf', label:'Legitimationsurkunde gültig bis', type:'DATE',value: ''});
      } else if (mdok.key.indexOf('fuehrungszeugnis') !== -1){
        this.uploadInputs.push({key:'datum_ausstellung', label:'Ausstellungsdatum des Führungszeugnisses', type:'DATE',value: ''});
      }
      this.$refs.uploadForm.open();
    },
    paraDocUpload(data) {
      const params = {file_id: '' + data.id}
      if ( this.uploadKey.indexOf('-') == -1) {
        params.doc_key = this.uploadKey;
        params.pers_id = '';
      } else {
        const items = this.uploadKey.split('-');
        params.pers_id = items[0];
        params.doc_key = items[1];
        if (data.formData.legi_ablauf) {
          params.legi_ablauf = data.formData.legi_ablauf;
        }
        if (data.formData.datum_ausstellung) {
          params.datum_ausstellung = data.formData.datum_ausstellung;
        }
      }
      this.initialise({ask: 'upload_dok', params});
    },
    
  },
}
</script>

<style scoped>
.document-container > .box__title {
  margin-bottom: 12px;
}
.makler-document {
  display: flex;
  margin-bottom: 16px;
  min-height: 120px;
}
.makler-document > div {
  flex: 1;
  margin: 0;
}
.makler-document--header {
  display: flex;
  justify-content: space-between;
}
.makler-document--header > .box__title {
  flex: 1 1 auto;
}
.makler-document--context-menu {
  flex: 0 0 auto;
}
.radio-danger {
  color: var(--color-danger);
}
.text-danger {
  background-color: var(--color-danger-background);
  color: var(--color-danger);
  display: inline-block;
  margin-right: 5px !important;
}
.text-success {
  background-color: var(--color-box);
  color: var(--color-success);
  display: inline-block;
  margin-right: 5px !important;
}
.box-title {
  margin-bottom: 8px;
  font-weight: 700;
}
</style>