<template>
    <div>
        <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="Manuelle Transaktionen einfügen" 
            :actions="headerActions"
            @action-CANCEL="abbrechen"
        />

        <div class="box__container">
            <div class="d-block">
                <Fields ref="refFields"
                    :fields="fields"
                    :combos="combos" 
                    :tableData="{}"
                    :valuesProp="values"
                    :isNotReactive="true"
                    :validators="validators"
                >
                </Fields>
            </div>
        </div>
    </div>
</template>

<script>
import Fields from '@/components/begleitscheine/Fields.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import { required } from "@/mixins/validator/rules";
import CORE_TYPES from '@/store/core/types';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    components: {
        Fields,
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    computed: {
        ...mapGetters({
            bgs: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
        }),
        fields() {
            return this.bgs?.steps?.TRANSAKT?.form || [];
        },
        isValid() {
            return this.values['input_anfang_datum'] && this.values['input_betrag'];
        },
        headerActions() {
            const actions = [];
            actions.push(PageHeaderSimpleAction('CANCEL', 'Änderungen verwerfen'));
            return actions;
        },
    },
    data() {
        return {
            values: {},
            combos: {},
            cancel: false,
            validators: {
                input_anfang_datum: [required('Bitte Datum erfassen')],
                input_betrag: [required('Bitte Betrag erfassen')],
            },
            initial: {},
        }
    },
    mounted() {
        this.combos = this.bgs?.combos || {};
        this.values = this.bgs?.values?.values_transaktionenanlegen || {};
        this.initial = JSON.parse(JSON.stringify(this.values))
    },
    methods: {
        abbrechen() {
            this.cancel = true;
            this.back();
        },
        back() {
            this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.$route.params.bgsnr}/TRANSAKT?backAction=true`);
        },
    },
    beforeRouteLeave(to, from, next) {
        const anyChanges = Object.keys(this.values).some(key => this.initial[key] != this.values[key]);
        if (!this.cancel && anyChanges) {
            if (!this.isValid) {
                this.$confirmModal({
                    title: 'Fehler', 
                    message: 'Bitte alle Pflichtfelder ausfüllen.', 
                    labelButtonConfirm: 'Ok',
                    showCancelButton: false,
                    })
            } else {
                this.values.bgsnr = this.$route.params.bgsnr;
                this.values.ask = 'save_man_tr';
                this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE, this.values);
                this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
                next();
            }
        } else {
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
            next();
        }
    }

}
</script>

<style>

</style>