<template>
  <div>
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="pageTitle" />

    <div class="box__container">
      <div class="box__title">Grundsätze über den Umgang mit Interessenskonflikten</div>
      <div class="row mt-2">
        <div class="col-auto">
          Wir sind seit 1.8.2020 gesetzlich verpflichtet Ihnen unsere <router-link to="/conflict-of-interests/principles">Grundsätze zum Interessenkonflikt.</router-link> zur Verfügung zu stellen.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-auto">
          Wir bitten Sie den Empfang unten zu bestätigen.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-auto">
          Vielen Dank für Ihr Verständnis.
        </div>
      </div>
      <InputCheckBoxItem 
        class="mb-0"
        :label="acceptCheckboxText"
        v-model="form.acceptCheck"
        @click="openLink"
      />
    </div>
    
    <div class="box__container">
      <div>
        <div class="layout__negative-margin--8">
          <BaseButton 
            @click="submit()"
            :disabled="validation.isInvalid('form')" >
            Bestätigen
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseButton from '@/components/core/BaseButton.vue'
import validator from '@/mixins/validator';
import { requiredTrue } from '@/mixins/validator/rules';
import CORE_TYPES from '@/store/core/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

const DEFAULT_TITLE = "Interessenskonflikt"

export default {
  mixins: [validator],
  data() {
    return {
      form: {
        acceptCheck: false
      }
    }
  },
  computed: {
    acceptCheckboxText() {
      return 'Ja, ich habe die <a id="goToPrinciplesLink">Grundsätze zum Interessenkonflikt.</a> gelesen.'
    },
    pageTitle() {
      return this.$appNavigation?.currentMenu?.label || DEFAULT_TITLE
    }
  },
  methods: {
    submit() {
      if (this.form.acceptCheck) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.SUBMIT_KUNDEINTERESSENKONFLIKT).then(() => {
          this.$router.push('/home')
        })
      }
    },
    openLink($event) {
      if ($event.target.id === 'goToPrinciplesLink') {
        this.goToPrinciples();
        return false;
      }
    },
    goToPrinciples() {
      this.$router.push('/conflict-of-interests/principles');
    }
  },
  beforeRouteLeave(to, from, next) {

    this.$addBreadcrumb({
      label: 'zurück zum Interessenkonflikt', 
      to,
      from,
    });

    next();
  },
  validators: {
    form: {
      acceptCheck: [requiredTrue()],
    }
  },
  components: {
    BaseButton,
    InputCheckBoxItem,
    PageHeaderTitleNavigation,
  }

}
</script>

<style scoped>

</style>