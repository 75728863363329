<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <router-view></router-view>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  mixins: [],
  components: {
    OptionMenu
  },
  beforeRouteLeave(to, from, next) {

    this.$addBreadcrumb({
      label: 'zurück zum Profil', 
      to,
      from,
    });

    next();
  },
}
</script>

<style scoped>

</style>