<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation :title="title" subtitle="Einstellungen" />

    <div class="box__container d-lg-none col-12">
      <Stepper2
        stepType="step"
        :visible="true"
        :selectedStepKey="stepKey"
        :stepperService="stepper"
        @setStep="setStepByKey"
        @setSubstep="setSubstepByKey"
        ref="pieStepper"
      ></Stepper2>
    </div>

    <AntragSidetext
      :warnings="warnings"
      :hinweiseProps="hinweise"
      :steps="stepper.getCurrentStepList()"
      showMessageErrorMustBeFixed
      :showExpanded="showExpanded"
      :highestStepVisited="4"
      @setStep="setStepByKey($event)"
      @setSubstep="setSubstepByKey($event)"
    />

    <ContentWithStepper>
      <template #stepper v-if="stepper">
        <div class="box__container">
          <VerticalStepper
            stepType="step"
            :visible="true"
            :selectedStepKey="stepKey"
            :selectedSubstepKey="substepKey"
            :stepperService="stepper"
            :showCollapse="true"
            @setStep="setStepByKey"
            @setSubstep="setSubstepByKey"
            ref="verticalStepper"
            class="responsive-stepper"
          />
        </div>
      </template>
      <template #content>
        <div><router-view></router-view></div>
        <div class="box__container">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1">
              <BaseButton
                v-if="hasPrevBtn"
                @click="prevStep()"
                isBlock
                isSecondary
              >
                <span>Zurück</span>
              </BaseButton>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1"
              v-if="hasNextBtn"
            >
              <BaseButton @click="nextStep()" isBlock isPrimary>
                <span>Weiter</span>
              </BaseButton>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1" v-else>
              <BaseButton @click="goToStrategie()" isBlock isPrimary>
                <span>Schließen</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </ContentWithStepper>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Stepper2 from "@/components/stepper/Stepper2.vue";
import VerticalStepper from "@/components/vertical-stepper/VerticalStepper.vue";
import ContentWithStepper from "@/components/core/ContentWithStepper.vue";
import AntragSidetext from "@/components/antrag/AntragSidetext.vue";

export default {
  mixins: [mixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    Stepper2,
    VerticalStepper,
    ContentWithStepper,
    AntragSidetext,
  },
  data() {
    return { showExpanded: false };
  },
  watch: {},
  mounted() {},
  computed: {
    title() {
      return this.schemaData?.title || "";
    },
    hasPrevBtn() {
      return (
        this.stepper.getCurrentSubStepIndex(this.stepKey, this.substepKey) > 0
      );
    },
    hasNextBtn() {
      return this.stepKey !== "settings" || this.substepKey !== "zielmarkt";
    },
  },
  methods: {
    nextStep() {
      const next = this.stepper?.getNextSubstep(this.stepKey, this.substepKey);
      if (next?.stepKey) {
        const query =
          next.substepKey && next.substepKey !== "0"
            ? { substepKey: next.substepKey }
            : {};
        this.navigateTo(next.stepKey, query);
      }
    },
    prevStep() {
      const next = this.stepper?.getPreviousSubstep(
        this.stepKey,
        this.substepKey
      );
      if (next?.stepKey) {
        const query =
          next.substepKey && next.substepKey !== "0"
            ? { substepKey: next.substepKey }
            : {};
        this.navigateTo(next.stepKey, query);
      }
    },
    updateStepperWithWarnings() {
      this.stepper.getCurrentStepList().forEach((step) => {
        if (step.substeps?.length) {
          // nix
        } else {
          if (this.warnings.some((warn) => warn.stepKey === step.stepKey)) {
            this.stepper.markStepAsWarning(step.stepKey);
          } else {
            this.stepper.markStepAsSuccess(step.stepKey);
          }
        }
      });
    },
    goToStrategie() {
      this.$router.push(`/beratung/vv/vv-schema-editor/${this.schemaId}`);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store
      .dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA, {
        schemaId: this.schemaId,
        stepKey: this.stepKey === "documents" ? this.stepKey : this.substepKey,
      })
      .finally(() => next());
  },
};
</script>

<style>
</style>