<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Persönliche Geschäfte" />

        <BaseFilter filterId="searchVVStrategien" 
            title="Hier finden Sie die Vermögensverwaltungen, die Ihnen zur Verfügung stehen" 
            :metadata="searchMenu" 
            :configFilter="configFilter"
            :predefinedFilter="null"
            :defaultOptions="defaultOptions" 
            :extraButton="{buttonText: 'Markierte Einträge bestätigen', isExtraButtonPrimary: true}"
            @onFilter="handleSearch($event)"
            @onFilterZurucksetzen="$emit('onFilterZurucksetzen')"
            @extraButtonClick="cofirmSelection"
        >
        </BaseFilter>


        <div class="box__container">
            <div class="box__title">Liste Fragenbogen</div>
            <div v-if="pages && Object.keys(pages).length && pages[pageIndex] && pages[pageIndex].length">

                <PaginatedTable v-if="!isLoading && pageCount > 0"
                    :headers="headers"
                    :pages="pages"
                    :pageCount="pageCount"
                    :rowCount="totalRows"
                    :page="pageIndex"
                    @page="setPageIndex"
                    @requestPage="loadPage"
                    v-model="selectedRows"
                    @click-maklernrText="openMakler"
                    @click-depotnr="openFragebogen"
                    @action-OPEN="openFragebogen"
                    @action-TICKET="createTicket"
                >
                    <template v-slot:geprueftBemerkung="row">
                        <InputField :value="row.geprueftBemerkung" v-if="row && row.id" @change="setChanges($event, row)"/>
                    </template>
                    <template v-slot:depotauszugFileName="row"> 
                        <DownloadLink target="_blank" rel="noopener noreferer" v-if="row.depotauszugFileId"
                            :title="row.depotauszugFileName"
                            :filename="row.depotauszugFileName"
                            downloadServicePath="/get_simple_file"
                            :queryParams="{
                                fileId: row.depotauszugFileId,
                                tempFileId: row.depotauszugFileId,
                            }"
                        >
                            <PhFileText :size="20"/>
                        </DownloadLink>
                    </template>
                    <template v-slot:translisteFileName="row"> 
                        <DownloadLink target="_blank" rel="noopener noreferer" v-if="row.translisteFileId"
                            :title="row.translisteFileName"
                            :filename="row.translisteFileName"
                            downloadServicePath="/get_simple_file"
                            :queryParams="{
                                fileId: row.translisteFileId,
                                tempFileId: row.translisteFileId,
                            }"
                        >
                            <PhFileText :size="20"/>
                        </DownloadLink>
                    </template>
                </PaginatedTable>
            </div>
            <NoData v-else/>
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PERS_GESCHAEFT_TYPES from '@/store/persoenlichesGeschaeft/types';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, ActionColumn, DateColumn, SlotColumn, SimpleAction, IconColumn, Icon} from "@/components/table2/table_util.js";
import {DEPOT_STATUS_VALUES} from './Fragebogen.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import InputField from '@/components/core/forms/InputField.vue';
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import {
  PhFile,
  PhFileText,
} from "phosphor-vue";

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseButton,
        NoData,
        Table,
        BaseFilter,
        InputField,
        PaginatedTable,
        Icon, 
        PhFile,
        PhFileText,
        DownloadLink,
    },
    data() {
        return {
            configFilter: { 
                noResetOnDefaultSearchInputExit: true,
            },
            initialFilters: {
                jahr: '2022',
            },
            selectedRows: [],
            changedRows: [],
            ROWS_PER_PAGE_DEFAULT: 20,
        }
    },
    mounted() {
        this.$store.commit(PERS_GESCHAEFT_TYPES.MUTATIONS.SET_OVERVIEW_FILTERS, this.initialFilters);
        this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_OVERVIEW_LIST, { pageIndex: 0, limit: this.ROWS_PER_PAGE_DEFAULT});
    },
    computed: {
        ...mapGetters({
            overviewList: PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST,
            savedFilters: PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_FILTERS,
            pageIndex: PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_PAGE_INDEX,
            totalRows: PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_TOTAL_ROWS,
            isLoading: PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_IS_LOADING,
        }),
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems:  [
                        {
                            type: 'combobox',
                            label: 'Jahr',
                            key: 'jahr',
                            comboOptions: [{ label: '2022', value: '2022'}]
                        },
                        {
                            type: 'combobox',
                            label: 'Prüfungsstatus',
                            key: 'geprueft_status',
                            comboOptions: [{ label: 'Alle', value: 'alle'}, { label: 'Geprüft', value: 'geprueft'}] // { label: 'Fehler', value: 'fehler'}, { label: 'Abgegeben', value: 'abgegeben'}, 
                        },
                        {
                            type: 'combobox',
                            label: 'Frgebogen augefüllt',
                            key: 'filled_in_status',
                            comboOptions: [{ label: 'Alle', value: 'alle'}, { label: 'Ausgefüllt', value: 'filledIn'}, { label: 'Nich ausgefüllt', value: 'notFilledIn'}]
                        },
                        {
                            type: 'text',
                            label: 'Maklernr',
                            key: 'maklernr',
                        },
                    ],
                }
            ]
        },
        headers() {
            return {
                lockedLeft: [
                    TextColumn("maklernrText", "Makler or Intern").makeAlwaysVisible().makeLink(),
                    PillColumn("geprueftStatus", "Prüfungsstatus"),
                    DateColumn("geprueftDate", "Prügungsdatum"),
                    SlotColumn("geprueftBemerkung", "Bemerkung zur Prüfung"),
                    TextColumn("jahr", "Jahr"),
                    TextColumn("depotnr", "Depotnummer").makeLink(),
                    TextColumn("lagerstelle", "Lagerstelle"),
                ],
                center: [
                    TextColumn("depotStatusText", "Depotstatus"),
                    PillColumn("confirmedText", "Angaben bestätigt"),
                    TextColumn("bemerkung", "Maklerbemerkung"),
                    SlotColumn("depotauszugFileName", "Depotauszug"),
                    SlotColumn("translisteFileName", "Transaktionsliste"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            }
        },
        pageCount() {
            return Math.ceil(this.totalRows / this.ROWS_PER_PAGE_DEFAULT);
        },
        pages() {
            const result = {};
            const entries = Object.entries(this.overviewList);
            entries.forEach(([key, page]) => {
                result[key] = this.buildPage(page);
            })
            return result;
        },
        defaultOptions() {
            return this.filters;
        },
        filters() {
            return this.savedFilters || {};
        }, 
    },
    methods: {
        buildPage(rows = []) {
            return rows.map((row, index) => {
                const actions = [ SimpleAction("OPEN", 'PhArrowSquareOut', "Öffnen") ];
                if (row.maklernr) {
                    actions.push(SimpleAction("TICKET", 'PhFileText', "Ticket/Nachticht an Makler"));
                }
                return !row ? {} : {
                    ...row,
                    index: index,
                    maklernrText: `${row.internId || ''}${row.maklernr || ''}${row.unternr ? ('-' + row.unternr) : ''}`,
                    depotStatusText: row.depotStatus && DEPOT_STATUS_VALUES.find(elem => elem.value === row.depotStatus)?.status || '',
                    confirmedText: row.id && (row.confirmed ? {label: 'ja', type: 'info'} : {label: 'nein', type: 'danger'}) || '',
                    selectedHidden: row.id && !row.geprueft && row.geprueftStatus?.type !== 'danger' ? false : true,
                    depotauszugIcon: row.depotauszugFileId && [Icon(PhFileText, "Depotauszug Datei", 16, "bold", "")],
                    translisteIcon: row.translisteFileId && [Icon(PhFileText, "Transaktionsliste Datei Datei", 16, "bold", "")],
                    actions: row.id && actions,
                }
            });
        },
        loadPage(index = 0) {
            this.saveData();
            this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_OVERVIEW_LIST, { pageIndex: index, limit: this.ROWS_PER_PAGE_DEFAULT});
        },
        handleSearch(filters) {
            const filtersObj = this.normalizeFilters(filters);
            this.$store.commit(PERS_GESCHAEFT_TYPES.MUTATIONS.SET_OVERVIEW_FILTERS, filtersObj);
            this.loadPage(this.pageIndex);
        },
        normalizeFilters(filtersArr = []) {
            const filters = {};
            filtersArr.forEach(filter => {
                filters[filter.key] = filter.value
        });
            return filters;
        },
        openFragebogen(data) {
            if (data?.id) {
                this.$addBreadcrumb({
                    label: `zurück zur Liste der persönlichen Geschäften`, 
                    fullPath: `/intern/aufsichtsrechtliches/persoenliches-geschaeft-overview`,
                    breadcrumb: 'Persönliches Geschäft Jahr 2022',
                });
                this.$router.push(`/intern/aufsichtsrechtliches/persoenliches-geschaeft-fragegoben/${data.id}`)
            }
        },
        openMakler(data = {}) {
            const params = {brokerId: data.maklernr};
            if (data?.unternr) {
                params.path = '/intern/ansprechpartner';
            }
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, params);
        },
        cofirmSelection() {
            if (this.selectedRows?.length) {
                this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.CONFIRM_SELECTION, this.selectedRows.map(row => { return { id: row.id, bemerkung: row.geprueftBemerkung}}));
            }
        },
        setChanges(value, row) {
            if (row && value != row.geprueftBemerkung) {
                row.geprueftBemerkung = value;
                const index = this.changedRows.findIndex(changedRow => row.id === changedRow.id);
                if (index >= 0) {
                    this.changedRows.splice(index, 1)
                } 
                this.changedRows.push(row);
            }
        },
        saveData() {
            if (this.changedRows.length) {
                this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.SAVE_BEMERKUNGEN, this.changedRows.map(row => { return { id: row.id, bemerkung: row.geprueftBemerkung}}));
            }
        },
        setPageIndex(index) {
            this.$store.commit(PERS_GESCHAEFT_TYPES.MUTATIONS.UPDATE_OVERVIEW_LIST_PAGE_INDEX, index);
        },
        createTicket(data) {
            if (data?.maklernr) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { 
                    brokerId: data.maklernr,
                    path: '/communication/ticket',
                })
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.saveData();
        next();
    }
}
</script>

<style>

</style>