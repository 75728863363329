var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Kundensuche",
          actions: _vm.headerActions,
          defaultMenu: _vm.customOptionMenu
        },
        on: {
          "action-SELECTION-DONE": _vm.handleBackAction,
          "action-NEW-CUSTOMER": function($event) {
            return _vm.addNewCustomer()
          },
          "action-NEW-CUSTOMER-GRUPPE": function($event) {
            return _vm.addNewCustomer("GRUPPE")
          },
          "action-NEW-CUSTOMER-AUSWEIS": function($event) {
            return _vm.addNewCustomer("AUSWEIS")
          }
        }
      }),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "search-filter" },
            [
              _c("GenericPersonFilter", {
                attrs: {
                  filterId: "76512a36-7861-4ff4-b939-88e0625eb8e9",
                  title: "Kundenfilter",
                  saveKey: "customerSearchAdvanced",
                  metadata: _vm.searchMenu,
                  predefinedFilter: _vm.predefinedFilter,
                  showButtons: !_vm.isExternalSelect,
                  defaultOptions: _vm.defaultOptionsData,
                  configFilter: _vm.configFilter,
                  immidiateSearch: _vm.immediateSearch,
                  isCache: _vm.$route.meta.isCache
                },
                on: {
                  search: function($event) {
                    return _vm.handleSearch($event)
                  },
                  comboboxChange: _vm.onComboboxChange
                }
              }),
              _vm.filtered
                ? _c(
                    "div",
                    {
                      staticClass: "box__container",
                      attrs: { tid: "f55d8574-902f-4663-9b34-1cbfb47c03e4" }
                    },
                    [
                      _vm.loading && !_vm.scrollLoading
                        ? _c("GhostLoading", {
                            staticClass: "m-0",
                            attrs: { type: "table", title: _vm.TABLE_TITLE }
                          })
                        : _vm.hasNoTableData
                        ? _c("NoData", {
                            attrs: {
                              title: _vm.TABLE_TITLE,
                              content: "Es wurden keine Einträge gefunden"
                            }
                          })
                        : _vm.customerFilterSetup &&
                          _vm.customerFilterSetup.data &&
                          _vm.customersData &&
                          _vm.customersData.customers
                        ? [
                            _c("PaginatedTable", {
                              staticClass: "m-0",
                              attrs: {
                                tableId: _vm.tableId,
                                title: _vm.TABLE_TITLE,
                                headers: _vm.headers,
                                pages: _vm.pages,
                                pageCount: _vm.pageCount,
                                rowCount: _vm.customersData.count || 0,
                                dragnDropFilesOnRow: _vm.isBroker && _vm.isTest,
                                selectionAsBlacklist: _vm.invertSelection,
                                sorted: _vm.customerSearchSort,
                                page: _vm.page,
                                pageSize: _vm.maxRowsPerPage,
                                menuRowsPerPage: _vm.menuRowsPerPage,
                                exportConfig: _vm.exportConfig,
                                thHoverDisabled: true,
                                headerActions: _vm.tableHeaderActions,
                                mobileConfig: {
                                  title: "name",
                                  headers: ["active", "customerID"],
                                  selectionActive: _vm.isExternalSelect
                                }
                              },
                              on: {
                                rowsPerPage: _vm.onPageSizeChange,
                                page: function($event) {
                                  _vm.page = $event
                                },
                                selectionAsBlacklist: function($event) {
                                  _vm.invertSelection = $event
                                },
                                files: function($event) {
                                  return _vm.handleRowFiles($event)
                                },
                                requestPage: _vm.loadPage,
                                sort: _vm.onCustomerSearchResultSort,
                                localSort: function($event) {
                                  _vm.customerSearchSort = $event
                                },
                                "click-name": _vm.openCustomerNewTab,
                                "click-openFunds": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/vermogensubersicht/depot"
                                  )
                                },
                                "click-insurances": _vm.openInsurancesNewTab,
                                "click-closedFunds": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/vermogensubersicht/beteiligungen"
                                  )
                                },
                                "click-kredite": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/verbindlichkeiten/credit"
                                  )
                                },
                                "click-immobilie": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/immobilien/search"
                                  )
                                },
                                "click-weitereVerbindlichkeiten": function(
                                  row
                                ) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/verbindlichkeiten/weitere-verbindlichkeiten/search"
                                  )
                                },
                                "click-weitereVermoegen": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/vermogensubersicht/weitere-vermoegen/search"
                                  )
                                },
                                "click-bankAccounts": function(row) {
                                  return _vm.openPathNewTab(
                                    row,
                                    "/home/account"
                                  )
                                },
                                "click-mobil": function(row) {
                                  return _vm.callTapi(row, row.mobil)
                                },
                                "click-telefon": function(row) {
                                  return _vm.callTapi(row, row.telefon)
                                },
                                "click-maklernr": _vm.openMaklerNewTab,
                                selected: _vm.validateRowSelection,
                                onScroll: _vm.onScroll,
                                scrollLoading: function($event) {
                                  _vm.scrollLoading = $event
                                },
                                "headerAction-KUNDEN-BEREINIGEN": function(
                                  $event
                                ) {
                                  return _vm.openKundenBereinigenModal()
                                },
                                "headerAction-VERMOEGENSUEBERSICHTEN-BESTELLEN": function(
                                  $event
                                ) {
                                  return _vm.vermoegensuebersichtenBestellen()
                                },
                                "headerAction-QUARTALSBERICHTE-BESTELLEN": function(
                                  $event
                                ) {
                                  return _vm.quartalsBerichteBestellen()
                                },
                                "headerAction-ZUSAETZLICHE-INFORMATIONEN-EINTRAGEN": function(
                                  $event
                                ) {
                                  return _vm.zusaetzlicheInformationenEintragen()
                                },
                                "headerAction-KUNDEN-AKTIV-SETZEN":
                                  _vm.setKundenInaktiv,
                                "headerAction-KUNDEN-INAKTIV-SETZEN": function(
                                  $event
                                ) {
                                  return _vm.setKundenInaktiv(true)
                                },
                                "headerAction-KUNDEN-LOESCHEN": function(
                                  $event
                                ) {
                                  return _vm.KundenLoeschenConfirmation()
                                },
                                "headerAction-KUNDEN-DECKBLAETER":
                                  _vm.callKundenDeckblaeter,
                                "headerAction-SEND-MESSAGE-TO-SELECTED":
                                  _vm.handleMailAll
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "name",
                                    fn: function(row) {
                                      return [
                                        row.customerID &&
                                        !row.mobileTableContext
                                          ? _c(
                                              "a",
                                              {
                                                class: {
                                                  deceased: row.personDeceased
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openCustomerNewTab(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(row.name))]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                class: {
                                                  deceased: row.personDeceased
                                                }
                                              },
                                              [_vm._v(_vm._s(row.name))]
                                            )
                                      ]
                                    }
                                  },
                                  {
                                    key: "customerID",
                                    fn: function(row) {
                                      return [
                                        _vm._v(" " + _vm._s(row.customerID)),
                                        row.data.externMaklerStatus
                                          ? _c(
                                              "span",
                                              { staticClass: "color-text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.data.externMaklerStatus
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  },
                                  {
                                    key: "fcAccess",
                                    fn: function(row) {
                                      return [
                                        _c("CustomerFCUsage", {
                                          attrs: {
                                            isAPP: row.data.app,
                                            isKSC: row.data.ksc,
                                            dateAppNutzung:
                                              row.data.dateAppNutzung,
                                            dateKSCNutzung:
                                              row.data.dateKSCNutzung
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "possibleContacts",
                                    fn: function(row) {
                                      return [
                                        _c("CustomerApproach", {
                                          attrs: {
                                            kundenansprache:
                                              row.data.kundenansprache
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                708625445
                              ),
                              model: {
                                value: _vm.selectedRows,
                                callback: function($$v) {
                                  _vm.selectedRows = $$v
                                },
                                expression: "selectedRows"
                              }
                            })
                          ]
                        : _vm._e(),
                      _c("HandleTablePin", {
                        attrs: { keyOfPIN: "TABELLE_KUNDENLISTE" }
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("CustomerDocumentUploadModal", { ref: "customerDocumentUploadModal" }),
      _c("KundenBereinigenModal", {
        ref: "kundenBereinigenModal",
        attrs: { customerList: _vm.selectedLoadedRows },
        on: {
          save: function($event) {
            return _vm.purgeCustomers($event)
          }
        }
      }),
      _c("BestellungenModal", {
        ref: "bestellungenModal",
        on: {
          save: function($event) {
            return _vm.executeOrders($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "executeOrdersResultModal",
          attrs: {
            modalTitle: "Info",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_vm._v(" " + _vm._s(_vm.executeOrdersResult.value) + " ")]
      ),
      _c("ZusaetzlichenModal", {
        ref: "zusaetzlichenModal",
        on: {
          save: function($event) {
            return _vm.executeOrders($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "validationModal",
          attrs: {
            modalTitle: "Überprüfung",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          },
          on: { close: _vm.onCloseValidationModal }
        },
        [_vm._v(" " + _vm._s(_vm.validationMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }