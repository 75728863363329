<template>
<div>
  <OptionMenu
    :id="$appNavigation.currentOptionMenuId"
    :defaultMenu="$appNavigation.currentOptionMenu" >
  </OptionMenu>

  <PageHeaderTitleNavigation
    :title="$appNavigation.currentMenu.label"
    :id="$appNavigation.currentOptionMenuId"
    :actions="actions"
    @action-NEW="navigateTo('new')">
  </PageHeaderTitleNavigation>

    <div>

      <div v-if="!scenarios || !scenarios.allRetirementScenarios || !isBalanceLoaded || !retirementYearSet">
        <div class="row">
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
        </div>
      </div>

      <div class="row" v-else-if="rows.length">
        <div class="col col-md-6" v-for="(scenario, i) of scenariosList" :key="i">
          <div class="box__container">
            <div class="box__title">
              <a @click="goToScenario(scenario.id)">
                {{scenario.name}} | {{scenario.updatedDate1}}
              </a>
            </div>
            <div>
              <RetirementChart
                v-if="chartsData[i] && chartsData[i].apexSeries"
                :data="{
                  data: chartsData[i].apexSeries,
                  series: chartsData[i].apexSeries,
                  xaxis: chartsData[i].apexXaxis,
                  annotations: chartsData[i].apexAnnotations,
                  markers: chartsData[i].apexMarkers,
                  dataLabels: {enabled: false},
                  stroke: {curve: 'smooth'},
                  autoUpdateSeries: true,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <NoData v-else />
    </div>
    <div class="hidden">
      <BalanceSheetView @setBalanceItems="setBalanceItems" @setBalance="setBalance" @init="setBalance" :isSelectableView="true" :selectedItems="selectedBalance"  @selectedItems="onbalanceselectedItems" @balanceSum="setAllBalance" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RETIREMENT_SCENARIO_TYPES from '@/store/retirementScenario/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";

import dayjs from 'dayjs';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PhTrash } from 'phosphor-vue'
import RetirementChart from './RetirementChart.vue';
import {calculateChart, getAgeTo, calculateDataPoints, mapGoalAPIToName} from './retirementChartCalculations.js'
import { manLifeYears, womanLifeYears } from './financialCalculator.js';
import BalanceSheetView from '@/components/balance-sheet/BalanceSheetView.vue';
import { SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT, SPECIAL_TYPE_REVENUE_PENSION_INCOME, SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME, SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL } from './types';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    Table,
    NoData,
    GhostLoading,
    RetirementChart,
    BalanceSheetView,
    PageHeaderTitleNavigation,
    OptionMenu,
  },
  props: {
  },
  data() {
    return {
        headers: {
            lockedLeft: [
                TextColumn("name", "Name").makeLink(),
                DateColumn("updatedDate1", "Datum"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
        chartsData: [],

        gender: 'm',
        form: {
          moneyNeededAtTheEnd: 0,

          presentValue: 0,
          interestBeforeRetirement: 2,
          interestAfterRetirement: 2,

          transactionsArray: [],

          period: 'pro Monat',
          name: '',
          dateOfBirth: '',
          retirementAge: 67,
          notizen: '',
          inflation: 0,
        },
        lifeLength: 70,
        retirementAge: 67,
        retirementYear: new Date().getFullYear() + this.retirementAge,
        retirementYearSet: false,
        isBalanceLoaded: false,
        
        allGoals: null,
        transactionsArray: [],
        now: new Date(),
        currentYear: new Date().getFullYear(),
        balance: null,
        
        selectedBalance: {},
        balanceselectedItems: {},
        allBalance: [],
        liabilitiesInterest: {},
        assetsInterest: {}
    }
  },
  computed: {
    ...mapGetters({
      scenarios: RETIREMENT_SCENARIO_TYPES.GETTERS.SCENARIOS,
      personalData: RETIREMENT_SCENARIO_TYPES.GETTERS.PERSONAL_DATA,
      goals: MY_GOALS_TYPES.GETTERS.GOALS,
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", PhTrash, "Löschen"),
        ];
        const result = this.scenarios?.allRetirementScenarios || [];
        return result.map(row => ({
            ...row,
            actions,
        }));
    },
    scenariosList() {
      return this.scenarios?.allRetirementScenarios || [];
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neue Ruhestandsplanung')
      ]
    }
  },
  watch: {
    scenarios: function(value) {
      if (this.scenarios?.allRetirementScenarios) {
        this.calculateAll(this.scenarios?.allRetirementScenarios);
      }
    },
    personalData(customer, oldValue) {
      if (customer && customer.personalDataBirth && customer.personalDataAddress) {
        if (!customer.personalDataBirth.dayOfBirth) {
          console.error('Bitte geben Sie Ihr Geburtsdatum in die persönlichen Daten ein')
          this.retirementYearSet = true;
        } else {
          this.gender = customer.personalDataAddress.title === 'Frau' ? 'f' : 'm';
          const dob = dayjs(customer.personalDataBirth.dayOfBirth, 'DD.MM.YYYY').toDate();
          this.retirementYear = +dob.getFullYear() + this.retirementAge + 1;
          this.form = {
            ...this.form,
            dateOfBirth: `${customer.personalDataBirth.dayOfBirth}`,
            retirementYear: `${this.retirementYear}`,
            retirementAge: `${this.retirementYear - +dob.getFullYear()}`,
          };
          this.retirementYearSet = true;
        }
      }
      this.calculateAll(this.scenarios?.allRetirementScenarios);
    },
    goals(myGoals, oldValue) {
      if (myGoals && myGoals.length) {
        this.allGoals = myGoals;
        this.transactionsArray = [];
        // this.presetSpecialEvents();

        this.allGoals.map(g => {
          if (g.myGoal) {
            const dateVal1 = g.myGoal.startDate && Object.prototype.toString.call(g.myGoal.startDate) === '[object Date]' ? dayjs(g.myGoal.startDate).format('DD.MM.YYYY') : g.myGoal.startDate.split('.');
            const dateVal2 = this.currentYear + Math.round(g.numberOfPayments / g.paymentPeriod);
            const deathDate = this.currentYear + 90;
            
            if (dateVal1[2] && dateVal1[2] > deathDate || dateVal2 > deathDate) {
              return;
            }
            const startDateVal = g.myGoal.startDate && Object.prototype.toString.call(g.myGoal.startDate) === '[object Date]' ?
              dayjs(g.myGoal.startDate).format('DD.MM.YYYY') : g.myGoal.startDate.split('.');
            const repeat = !!g.myGoal.endDate && g.myGoal.startDate && g.myGoal.endDate.split('.')[2] !== g.myGoal.startDate.split('.')[2];
            const goalDate = new Date(dateVal1[2], dateVal1[1] - 1, dateVal1[0]);
            const num = Math.round(g.numberOfPayments * 12 / g.paymentPeriod);
            goalDate.setMonth(DatePickerUtils.safeDate(goalDate).getMonth() + num);
            const goalAndRevenueData = {
              isIncome: g.myGoal.isIncome,
              type: mapGoalAPIToName(g.myGoal.type, g.myGoal.isIncome),
              repeat: repeat ? 'Wiederholung' : 'Keine Wiederholung',
              age: repeat ?
                `${startDateVal[0]}.${startDateVal[1]}.` + Math.round(+startDateVal[2])
                : dayjs(goalDate).format('DD.MM.YYYY'),
              endDate: g.myGoal.endDate || 0,
              priority: 0,
              amount: +g.futureValue,
              period: g.paymentPeriod === 1 ? 'pro Jahr' : 'pro Monat',
              id: g.id,
              myGoalId: g.myGoal?.id,
              data: g,
              title: g.myGoal.title,
              typeName: `${mapGoalAPIToName(g.myGoal.type, g.myGoal.isIncome)}`
            };
            this.transactionsArray.push(this.createZiele(goalAndRevenueData));
          }
        });
        
        // this.fillTransactionsTableData();
        
        this.isLoaded = true;//!!this.prevRetirementScenario;
        
        // this.isCalcBalance = true;
        // this.selectedRows = {...this.selectedRows}
      } else {
        this.isLoaded = true;
        this.allGoals = [];
        this.selectedRows = {};
      }
      this.calculateAll(this.scenarios?.allRetirementScenarios);
    },
  },
  mounted: function() {
    this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS);
    this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
    this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_PERSONAL_DATA);
    if (this.scenarios?.allRetirementScenarios) {
      this.calculateAll(this.scenarios?.allRetirementScenarios);
    }
  },
  methods: {
    goToScenario(id) {
      this.$router.push({ path: `view/${id}` });
    },
    deleteScenario(id) {
      this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.DELETE_SCENARIO, id);
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    getDateString(date) {
      return date && !isNaN(date) && dayjs(date).format('DD.MM.YYYY') || '';
    },
    calculateChart(...args) {
      return calculateChart(...args)
    },
    calculateAll(retirements) {
      if (!this.isBalanceLoaded || !this.retirementYearSet || !retirements)
        return;
      let transactionsArray = this.transactionsArray
      const dateOfBirth = this.form.dateOfBirth
      const retirementAge = this.form.retirementAge
      const retirementYear = this.form.retirementYear
      const beingDestroyed = false
      const mapLifeYears = this.gender === 'm' ? manLifeYears.map(v => Math.round(v)) : womanLifeYears.map(v => Math.round(v));
      const lifeLength = mapLifeYears[getAgeTo(new Date().getFullYear(), dateOfBirth)] + getAgeTo(new Date().getFullYear(), dateOfBirth) || 70;

      retirements.map(scenario => {
        const period = scenario.period;
        const interestAfterRetirement = scenario.interestAfterRetirement;
        const moneyNeededAtTheEnd = scenario.moneyNeededAtTheEnd;
        const {selectedTransactionsSpecial, transactionsArraySpecial} = this.presetSpecialEvents(scenario)
        transactionsArray = this.transactionsArray.concat(transactionsArraySpecial)
        const selectedBalanceAssets = scenario.selectedBalance
          .filter(v => v.key === 'assets').length ?
          scenario.selectedBalance
            .filter(v => v.key === 'assets')[0]?.selectedBalanceItems
            .map(v => v.itemValue) : [];
        const selectedBalanceLiabilities = scenario.selectedBalance
          .filter(v => v.key === 'loss').length ? 
          scenario.selectedBalance
            .filter(v => v.key === 'loss')[0]?.selectedBalanceItems
            .map(v => v.itemValue) : [];
        const assetIndexByName = {};
        this.balance.assetData.map((v, i) => {
          assetIndexByName[v.string.toLowerCase()] = `${i}`
        })
        const lossIndexByName = {};
        this.balance.liabilityData.map((v, i) => {
          lossIndexByName[v.string.toLowerCase()] = `${i}`
        })
        const presentValues = {
          assets: this.balance.assetsCurrencyData.filter(v => selectedBalanceAssets.includes(assetIndexByName[v.string.toLowerCase()])).map(v => ({key: v.string, value: v.number})),
          loss: this.balance.liabilityCurrencyData.filter(v => selectedBalanceLiabilities.includes(lossIndexByName[v.string.toLowerCase()])).map(v => ({key: v.string, value: v.number}))
        }
        const selectedTransactions = (scenario.selectedGoals.map(v => +v.selectedValue) || [])
          .concat(scenario.selectedIncomes.map(v => +v.selectedValue) || [])

        const assetsInterest = {}
        const liabilitiesInterest = {}
        this.balance.assetData.map(v => assetsInterest[v.string.toLowerCase()] = 0)
        this.balance.liabilityData.map(v => liabilitiesInterest[v.string.toLowerCase()] = 0)
        
        // Object.values(this.assetsInterestStored).map(asset => {
        //   if (asset.type > 0) {
        //     this.assetsInterest[asset.name.toLowerCase()] = asset.procent;
        //     this.assetsOriginalValues[asset.name.toLowerCase()] = asset.value;
        //   } else {
        //     this.liabilitiesInterest[asset.name.toLowerCase()] = asset.procent;
        //     this.liabilitiesOriginalValues[asset.name.toLowerCase()] = asset.value;
        //   }
        // })
        if (this.balance.assetsCurrencyData) {
          // this.tableDataBalanceAssets = {
          //   ...this.tableDataBalance,
          //   records: this.balance.assetsCurrencyData.map((v, i) => {
          //     return {
          //       name: v.string,
          //       number: v.number,
          //       interest: assetsInterest[v.string.toLowerCase()] || this.form.interestBeforeRetirement,
          //       index: i,
          //     }
          //   }).concat([{
          //     name: 'Individuell',
          //     number: 0, //this.assetsIndividual,
          //     interest: this.form.interestBeforeRetirement,
          //     index: balance.assetsCurrencyData.length,
          //     isAdjustable: true,
          //   }])
          // }
          if (!assetsInterest || !Object.keys(assetsInterest).length) {
            this.balance.assetsCurrencyData.map((v, i) => {
              assetsInterest[v.string.toLowerCase()] = 0 || this.form.interestBeforeRetirement;
            })
            assetsInterest['individuell'] = 0 || this.form.interestBeforeRetirement;
          }
        }
        if (this.balance.liabilityCurrencyData) {
          // this.tableDataBalanceLiabilities = {
          //   ...this.tableDataBalance,
          //   records: this.balance.liabilityCurrencyData.map((v, i) => {return {
          //       name: v.string,
          //       number: v.number,
          //       interest: liabilitiesInterest[v.string.toLowerCase()] || this.form.interestBeforeRetirement,
          //       index: i,
          //     }
          //   }).concat([{
          //     name: 'Individuell',
          //     number: 0,//this.liabilitiesIndividual,
          //     interest: this.form.interestBeforeRetirement,
          //     index: this.balance.liabilityCurrencyData.length,
          //     original: 0,
          //     isAdjustable: true,
          //   }])
          // }
          if (!liabilitiesInterest || !Object.keys(liabilitiesInterest).length) {
            this.balance.liabilityCurrencyData.map((v, i) => {
              liabilitiesInterest[v.string.toLowerCase()] = 0 || this.form.interestBeforeRetirement;
            })
            liabilitiesInterest['individuell'] = 0 || this.form.interestBeforeRetirement;
          }
        }

        const {
          dataPointsPerYear,
          dataLinesBalance,
          maxY,
          minY,
        } = calculateDataPoints({
          transactionsArray,
          selectedTransactions,
          selectedTransactionsSpecial,
          dateOfBirth,
          retirementAge,
          beingDestroyed,
          period,
          lifeLength,
          presentValues,
          interestAfterRetirement,
          assetsInterest,
          liabilitiesInterest,
        })
        this.chartsData.push(this.calculateChart({
          transactionsArray,
          selectedTransactions,
          dataPointsPerYear,
          dateOfBirth,
          maxY,
          minY,
          lifeLength,
          moneyNeededAtTheEnd,
          retirementYear,
        }))
      })
    },
    
    presetSpecialEvents(scenario) {
      if (!scenario) {
        return {selectedTransactionsSpecial: [], transactionsArraySpecial: []};
      }
      const selectedTransactionsSpecial = []
      const transactionsArray = []
      if (scenario.pensionIncome) {
        scenario.pensionIncome.map(v => {
          const index = transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_PENSION_INCOME,
            amount: +v.selectedValue,
            id: index,
          })
          transactionsArray.push(goal);
          selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (scenario.beforeRetirementIncome) {
        scenario.beforeRetirementIncome.map(v => {
          const index = transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
            amount: +v.selectedValue,
            id: index,
          });
          transactionsArray.push(goal);
          selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (scenario.presentValue) {
        scenario.presentValue.map(v => {
          const index = transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
            amount: +v.selectedValue,
            id: index,
          });
          transactionsArray.push(goal);
          selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (scenario.costOfLivingFromRetirement) {
        scenario.costOfLivingFromRetirement.map(v => {
          const index = transactionsArray.length;
          const goal = this.createZiele({
            isIncome: false,
            type: SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
            amount: +v.selectedValue,
            id: index,
          });
          transactionsArray.push(goal);
          selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      return {selectedTransactionsSpecial, transactionsArraySpecial: transactionsArray};
    },
    
    createZiele(val) {
      return {
        type: val.type || '',
        typeName: val.typeName || '',
        title: val.title || '',
        age: val.age || '01.01.' + this.currentYear,
        repeat: val.repeat || 'Keine Wiederholung',
        endDate: val.endDate || null,
        priority: val.priority || 0,
        amount: val.amount || 0,
        period: val.period || 'pro Monat',
        id: val.id || undefined,
        myGoalId: val.myGoalId || undefined,
        data: val.data || undefined,
        checked: val.checked || false,
        isIncome: val.isIncome || false,
      };
    },

    isSpecialEventType(type) {
      return [
        SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
        SPECIAL_TYPE_REVENUE_PENSION_INCOME,
        SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
        SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
      ].includes(type);
    },
    setBalance(val) {
    },
    onbalanceselectedItems(values) {
      this.selectedBalance = values;
      this.balanceselectedItems = values;
    },
    setBalanceItems(val) {
      this.form.presentValues = val
      if (this.selectedBalance && this.selectedBalance.assets) {
        if (Object.values(this.selectedBalance.assets).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValues.assets.push({
            key: 'Individuell',
            value: this.assetsIndividual
          });
        }
        if (Object.values(this.selectedBalance.loss).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValues.loss.push({
            key: 'Individuell',
            value: -this.liabilitiesIndividual
          });
        }
      }
    },
    setAllBalance(balance) {
      this.balance = balance;
      this.isBalanceLoaded = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Ruhestandsplanung', 
      to,
      from,
    });

    next();
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.hidden {
  display: none;
}
</style>