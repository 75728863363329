export const MUTATION_PREFIX = 'SCHADENSMELDUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'SCHADENSMELDUNG_ACTIONS_';
export const GETTERS_PREFIX = 'SCHADENSMELDUNG_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_SCHADENSMELDUNGEN_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_SCHADENSMELDUNGEN_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_SCHADENSMELDUNGEN: ACTIONS_PREFIX + 'RETRIEVE_SCHADENSMELDUNGEN',
  },
  GETTERS: {
    LIST_SCHADEN: GETTERS_PREFIX + 'LIST_SCHADEN',
  }
}