<template>
  <div>
    <div>
      <OptionMenu v-if="hasRoles([VIEW_ROLES.VIEW_BROKER])" :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
      <OptionMenu v-else :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

      <PageHeaderTitleNavigation
        title="200 Tage Limit"
        :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="optionsMenu"
        :actions="headerActions"
        @action-REMOVE="loschen()"
        @action-RESET="zuruckSetzen()"
      />

      <div class="box__container">
        <span class="box__title">{{fondsName}}</span>
        <br>
        <template v-if="limits200">
          <template v-if="limits200 && limits200.id!==null">
            <template v-if="limits200 && limits200.data && !limits200.isError">
              <div class="row" v-for="(l, index) of limits200.data.labels" :key="'row' + index">
                <p class="col-12">{{l}}</p>
              </div>

              <form>
                <div class="form-group">
                  <div class="row  mb-2">
                    <div class="col-12 col-md-6 col-lg-3">ISIN</div>
                    <div class="col-12 col-md-6 col-lg-9">{{id}}</div>
                  </div>

                  <div class="row  mb-2">
                    <div class="col-12 col-md-6 col-lg-3">Währung</div>
                    <div class="col-12 col-md-6 col-lg-9">{{limits200.data.currency}}</div>
                  </div>

                  <div class="row  mb-2">
                    <div class="col-12 col-md-6 col-lg-3">Aktueller Kurs</div>
                    <div class="col-12 col-md-6 col-lg-9">
                      {{limits200.data.kurs | currency}}</div>
                  </div>

                  <div class="row  mb-2">
                    <div class="col-12 col-md-6 col-lg-3">Kursdatum</div>
                    <div class="col-12 col-md-6 col-lg-9">{{limits200.data.kursDate}}</div>
                  </div>

                  <div class="row  mb-2">
                    <div class="col-12 col-md-6 col-lg-3">Aktueller Wert GD200</div>
                    <div class="col-12 col-md-6 col-lg-9">
                      {{limits200.data.value200 | currency}}</div>
                  </div>

                  <div class="row  mb-2">
                    <InputField
                      label="Abweichung nach unten - prozentual (relativ)"
                      v-model="low"
                      type="percent">
                    </InputField>
                  </div>

                  <div class="row mb-2">
                    <InputField
                      label="Abweichung nach oben - prozentual (relativ)"
                      v-model="high"
                      type="percent">
                    </InputField>

                  </div>

                  <div class="row  mb-2" v-if="limits200.data.changeDate">
                    <div class="col-12 col-md-6 col-lg-3">Letzte Änderung</div>
                    <div class="col-12 col-md-6 col-lg-9">{{limits200.data.changeDate}}</div>
                  </div>

                  <div class="row  mb-2" v-if="limits200.data.dateOutofLimit">
                    <div class="col-12 col-md-6 col-lg-3">Datum der Überschreitung</div>
                    <div class="col-12 col-md-6 col-lg-9">{{limits200.data.dateOutofLimit}}</div>
                  </div>
                  
                  <div class="row  mb-2 w-311" v-if="isBroker || isByPass">
                      <InputTextArea
                        label="Bemerkung"
                        v-model="comment">
                      </InputTextArea>
                  </div>
                </div>
              </form>
            </template>
          </template>
        </template>
      </div>
    </div>
    
    <BaseModal
      ref="modalRef"
      modalTitle="Limit löschen?"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="action(true)"
      @onCancelButton="action(false)">
    </BaseModal>
    
    <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmError" @resetError="resetError"/>

    <BaseModal
      ref="saveOrReset"
      labelButtonCancel="Verwerfen"
      labelButtonConfirm="Speichern"
      @onCancelButton="handleReset"
      @onConfirmButton="speichern">
      <template v-slot:modalTitle>
        Möchte Sie Ihre Änderungen speichern?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CORE_TYPES from '@/store/core/types';
import { removeFormatting } from './Utils';
import BaseModal from "@/components/core/BaseModal.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import {sanitize} from '@/helpers/string-helper.js';
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue' 
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {
  },
  data () {
    return {
      breadcrumbs: [],
      id: '',
      isBreadcrumbsFinal: false,
      begleitscheinNr: '',
      fondsName: '',
      errorFromService: '',
      showModal: false,

      high: '',
      low: '',
      comment: '',

      isLeavingRoute: false,
      nextCallback: null,
      previousData: {},
    }
  },
  computed: {
    ...mapGetters({
      limits200: DEPOTPOSITIONS_TYPES.GETTERS.LIMITS_200,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    optionsMenu() {
      return [
        {
          path: `/home/vermogensubersicht/wertpapiere/limits/${this.id}/${this.begleitscheinNr}`,
          label: 'Depotpositionslimit',
        },
        {
          path: `/home/vermogensubersicht/wertpapiere/limits200/${this.id}/${this.begleitscheinNr}`,
          label: '200 Tage Limit',
          textBold: true,
        },
      ]
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('REMOVE', 'Löschen'),
        PageHeaderSimpleAction('RESET', 'Zurücksetzen'),
      ];
    },
    isChanged() {
      return this.previousData && JSON.stringify(this.previousData) !== JSON.stringify(this.formData())
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id;
    },
    '$route.params.begleitscheinNr': function (begleitscheinNr) {
      this.begleitscheinNr = begleitscheinNr;
    },
    limits200(result, oldValue) {
      if (result.data.errorStr) {
        this.errorFromService = result.data.errorStr.replace(new RegExp('\n', 'g'), '<br/>');
        this.$refs.modalError.open();
        return;
      }
      if (!this.errorFromService && this.isLeavingRoute) {
        this.isLeavingRoute();
      }
      if (result.action && result.action == Limits200Actions.ActionTypes.setLimits200Success) {
        AuthenticationService.setCache(false);
        this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, { depotid: this.depotid });
        this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
        this.navigateTo(`/home/vermogensubersicht/depot?backAction=true`);
      }
      if (result.data && result.data.input) {
        this.fondsName = result.data.fondsName;
        if (this.begleitscheinNr == result.data.input.bgsNr) {
            this.high = result.data.input.high,
            this.low = result.data.input.low,
            this.comment = result.data.input.comment
        }
      }
      this.previousData = this.formData();
    },
  },
  mounted: function() {
    this.errorFromService = null;
    this.isLeavingRoute = false;
    this.id = this.$route.params.id;
    this.begleitscheinNr = this.$route.params.begleitscheinNr;
    this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    confirmError() {
      if (this.isLeavingRoute) {
        this.isLeavingRoute();
      }
    },
    resetError() {
      this.errorFromService = null;
    },
    zuruck() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
    },
    speichern() {
      const input = {
        bgsNr: this.begleitscheinNr,
        high: Number(removeFormatting(this.high)),
        low: Number(removeFormatting(this.low)),
        comment: this.comment
      };
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.SET_LIMITS_200, input);
      if (this.nextCallback) {
        this.nextCallback();
      }
    },
    clearPreviousValues() {
      this.high = null;
      this.low = null;
      this.comment = null;
      this.errorFromService = null;
    },
    zuruckSetzen() {
      this.clearPreviousValues();
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
    },
    loschen() {
      this.$refs.modalRef.open();
    },
    confirmDelete() {
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.DELETE_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, { depotid: this.depotid });
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200, { begleitscheinNr: this.begleitscheinNr });
      this.navigateTo(`/home/vermogensubersicht/depot?backAction=true`);
    },
    action(value) {
      this.hideModal();
      if (value) {
        this.confirmDelete();
      }
    },
    back() {
      this.navigateTo(`/home/vermogensubersicht/depot?backAction=true`);
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    hideModal() {
      this.showModal = false;
    },
    formData() {
      return {
        bgsNr: this.begleitscheinNr,
        high: Number(removeFormatting(this.high)),
        low: Number(removeFormatting(this.low)),
        comment: this.comment
      }
    },
    saveOrReset(action, callback) {
      if (this.isChanged) {
        this.nextCallback = callback;
        this.$refs.saveOrReset.open();
      } else {
          callback();
      }
    },
    handleReset() {
      this.$refs.saveOrReset.close();
      if (this.nextCallback) {
          this.nextCallback();
      }
    },
  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveOrReset('save', next);
  },
  mixins: [],
  components: {
    BaseModal,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputTextArea,
    InputField,
    LimitErrorModal,
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.modal-footer {
    min-width: 150px;
}
.antrag-action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.error-message {
  color: var(--color-danger);
}
</style>
<style>
.w-311 textarea {
  width: 311px;
}
</style>