var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "Wertpapierlimit",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      !_vm.isFondsfinder
        ? _c("div", [
            _c("div", { staticClass: "box__container" }, [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Wertpapierlimit")
              ]),
              _c("div", [
                _vm._v(
                  " Zum Setzen der Limits kann ein absoluter Betrag oder eine prozentuale Abweichung basierend auf dem Ausgangswert eingegeben werden. Um den Ausgangswert an den aktuellen Anteilspreis anzupassen oder um bei einer Benachrichtigung das Limit neu zu setzen ist der 'Zurücksetzen' Button zu drücken. Soll ein Limit (oberes oder unteres) nicht beachtet werden so ist dieser Wert leer zu lassen bzw. auf 0 zu setzen. "
                )
              ]),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(
                  " Beispiel 1: Prozentuales unteres Limit 5% bedeutet, dass die Limitwarnung bei einer Abweichung des Wertpapierpreises um 5% nach unten ausgelöst wird. "
                )
              ]),
              _c("div", [
                _vm._v(
                  " Beispiel 2: Absolutes oberes Limit 70 bedeutet, dass die Limitwarnung bei einem Anstieg des Wertpapierpreises in Wertpapierwährung auf 70 ausgelöst wird. "
                )
              ]),
              _c("div", [
                _vm._v(
                  " Wenn Sie Dynamik auswählen und ein unteres Limit gesetzt ist, so steigt bei steigenden Wertpapierkursen das untere Limit im gleichen Verhältnis an. Bei sinkenden Kursen bleiben die Limits unverändert. "
                )
              ]),
              !_vm.isCustomerOrigin
                ? _c("div", [
                    _vm._v(
                      ' Möchten Sie ihrem Kunden bei Erreichen des Limits automatisch ein Kauf- und Verkaufsformular zuschicken, bitten wir Sie dies unter "Kundendaten/Einstellungen" auszuwählen. '
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "layout__negative-margin--8 antrag-action-button__container"
                },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isSecondary: "",
                        disabled: !_vm.isin || (_vm.limit && _vm.limit.notExist)
                      },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("Zurücksetzen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isPrimary: "",
                        disabled: !_vm.isin || (_vm.limit && _vm.limit.notExist)
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.confirmDelete.open()
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("WertpapierAuswahlUnique", {
                  attrs: {
                    label: "Wertpapier",
                    data: _vm.limit,
                    id: "isin",
                    config: { isEmbedded: true },
                    hasDelete: false,
                    disabled: _vm.wertpapierDisabled
                  },
                  on: {
                    openFondsfinder: function($event) {
                      _vm.isFondsfinder = true
                    }
                  }
                }),
                _c("div", { staticClass: "info-block" }, [
                  _c("div", [_vm._v("Währung: " + _vm._s(_vm.limit.currency))]),
                  _c("div", [
                    _vm._v(
                      "Aktueller Kurs: " +
                        _vm._s(_vm.formatCurrency(_vm.limit.aktuellKurs))
                    )
                  ]),
                  _c("div", [
                    _vm._v("Kursdatum: " + _vm._s(_vm.limit.kursdatum))
                  ])
                ]),
                _c("InputField", {
                  attrs: {
                    label: "Ausgangswert (Anteilspreis)",
                    type: "percent",
                    precision: 2,
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.ausgangskurs,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "ausgangskurs", $$v)
                    },
                    expression: "form.ausgangskurs"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Abweichung nach unten",
                    type: "percent",
                    precision: 2,
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.unteresLimitAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "unteresLimitAbweichung", $$v)
                    },
                    expression: "form.unteresLimitAbweichung"
                  }
                }),
                _c("InputRadioBoxGroup", {
                  attrs: { values: _vm.radioValues },
                  model: {
                    value: _vm.form.radioUnteresAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "radioUnteresAbweichung", $$v)
                    },
                    expression: "form.radioUnteresAbweichung"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Abweichung nach oben",
                    precision: 2,
                    type: "percent",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.oberesLimitAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "oberesLimitAbweichung", $$v)
                    },
                    expression: "form.oberesLimitAbweichung"
                  }
                }),
                _c("InputRadioBoxGroup", {
                  attrs: { values: _vm.radioValues },
                  model: {
                    value: _vm.form.radioOberesAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "radioOberesAbweichung", $$v)
                    },
                    expression: "form.radioOberesAbweichung"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Dynamik" },
                  model: {
                    value: _vm.form.dynamic,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "dynamic", $$v)
                    },
                    expression: "form.dynamic"
                  }
                }),
                _c("div", [
                  _vm._v(
                    "Ausgangswert für Dynamik: " +
                      _vm._s(_vm.formatCurrency(_vm.limit.dynamicValue))
                  )
                ]),
                _c("div", [
                  _vm._v("Letzte Änderung: " + _vm._s(_vm.limit.lastChangeDate))
                ]),
                _c("div", [
                  _vm._v(
                    "Datum der Überschreitung: " +
                      _vm._s(_vm.limit.informedDate)
                  )
                ]),
                _c("InputTextArea", {
                  attrs: { label: "Bemerkung", isComponentHalfSize: "" },
                  model: {
                    value: _vm.form.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bemerkung", $$v)
                    },
                    expression: "form.bemerkung"
                  }
                })
              ],
              1
            )
          ])
        : _c(
            "div",
            [
              _c("FondsFinderSuchMaske"),
              _c("FondsFinderPositionsList", {
                ref: "fondsfinderResult",
                attrs: {
                  goBackEnabled: true,
                  singleSelectionProp: true,
                  hasDefaultButton: true
                },
                on: {
                  goBack: function($event) {
                    _vm.isFondsfinder = false
                  },
                  getSelectedOutcome: function($event) {
                    return _vm.setIsin($event)
                  }
                }
              })
            ],
            1
          ),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.remove()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Wollen Sie wirklich das Limit löschen?")])]
      ),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmNextRoute }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }