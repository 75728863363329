<template>
  <div>
    <ComboBox
      label="Depotauswahl" 
      :values="comboboxSelections[id]" 
      :value="antragData[id]"
      @change="onChangeBankdepot($event)"/>
    <hr>
    <div class="row">
      <InputField class="col-12 col-lg-6 px-3"
        label="Bankdepot-Nr."
        :isEmbedded="true" 
        :value="antragData['INPUT_AUSL_FE_DEPOTNR']"
        @change="onChange('INPUT_AUSL_FE_DEPOTNR', $event)"/>
      <InputField class="col-12 col-lg-6 px-3"
        label="Name der Bank"
        :isEmbedded="true" 
        :value="antragData['INPUT_AUSL_FE_BANK']"
        @change="onChange('INPUT_AUSL_FE_BANK', $event)"/>
      <InputField class="col-12 col-lg-6 px-3"
        label="IBAN"
        :isEmbedded="true" 
        :value="antragData['INPUT_AUSL_FE_BLZ']"
        @change="onChange('INPUT_AUSL_FE_BLZ', $event)"/>
      <InputField class="col-12 col-lg-6 px-3"
        label="BIC"
        :isEmbedded="true" 
        :value="antragData['INPUT_AUSL_FE_BIC']"
        @change="onChange('INPUT_AUSL_FE_BIC', $event)"/>
    </div>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';

export default {
  props: {
    id: {
      type: String,
    },
    config: {
    },
    antragId: {
    },
    antragData: {
    },
    antragTyp: {
    },
    comboboxSelections: {
    },
    warnings: {
    }
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
    onChangeBankdepot(value) {
      if (value) {
        const data = this.comboboxSelections[this.id].find(item => item.value === value) || {};
         this.$emit('change', { 
          [this.id]: value,
          INPUT_AUSL_FE_DEPOTNR: data.INPUT_AUSL_FE_DEPOTNR || '',
          INPUT_AUSL_FE_BANK: data.INPUT_AUSL_FE_BANK || '',
        });
      }
    },
    onChange(componentId, value) {
      if (componentId) {
        this.$emit('change', { [componentId]: value });
      }
    },
  },
  components: {
    ComboBox,
    InputField,
  }
}
</script>

<style scoped>
</style>
