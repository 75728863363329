var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Versicherungs Abschluss-Portale"
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "4ad66987-07ad-46ae-892c-cac0d0379645" }
        },
        [
          _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
            ? _c("Table", {
                attrs: {
                  tableId: "cbbf3d4b-eec7-469b-9504-2e197d4de462",
                  tableData: _vm.tableData,
                  cardViewEnabled: false,
                  filterEnabled: false,
                  exportEnabled: false,
                  paginationEnabled: false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "itemLabel",
                      fn: function(props) {
                        return [
                          props.data.row.disabled
                            ? _c(
                                "div",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "Abschluss-Portale-Table-disabled" +
                                        props.index
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.data.row.itemLabel) + " "
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "Abschluss-Portale-Table" + props.index
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.askOptionsExterneZugang(
                                        props.data.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(props.data.row.itemLabel))]
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2115056422
                )
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm._e()
        ],
        1
      ),
      _vm.askOptions
        ? _c("ExterneZugangAskOptions", {
            attrs: {
              askOptions: _vm.askOptions,
              title: _vm.externeZugang.companyLabel
            },
            on: {
              close: function($event) {
                _vm.askOptions = null
              },
              onConfirmButton: function($event) {
                return _vm.askOptionsConfirmed($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }