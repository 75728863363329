import Vue from 'vue';
import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import MENU_TYPES from '@/store/menu/types';
import router from '@/router';
import { navigateResettingBreadcrumb } from '@/router/breadcrumb';

const appNavigationByMenuPathFn = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH];
const appNavigationByRoutePathFn = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH];

const TrackMenu = (groupMenu) => {
  const createTrackMenu = (groupMenu, subMenu) => ({
    groupMenu,
    subMenu,
    setSubMenu(menu) {
      this.subMenu = menu;
    },
    isActive(menu) {
      if (!menu?.path) return false;
      return [this.groupMenu?.path, this.subMenu?.path].indexOf(menu.path) >= 0;
    },
    copy() {
      return createTrackMenu(this.groupMenu, this.subMenu);
    },
  });

  return createTrackMenu(groupMenu, null);
};

const FALLBACK_TIMEOUT = 1000 * 60;
let stopLoadingFallbackId = null;
const startLoading = () => {
  stopLoading();

  store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_START);
  stopLoadingFallbackId = setTimeout(stopLoading, FALLBACK_TIMEOUT); // prevents showing loading forever
}

const stopLoading = () => {
  clearTimeout(stopLoadingFallbackId);
  store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
}

const forceOpen = menu => !menu.parent || menu.group;

const isMenuVisible = (groupMenu, subMenu) => {
  const { currentOptionMenu } = appNavigationByMenuPathFn(groupMenu.path);
  return currentOptionMenu.findIndex(om => om.path === subMenu.path) >= 0;
}

const _navigateOnly = async (route, force = false, replace = false) => {
  startLoading();
  await navigateResettingBreadcrumb(route, { force, replace });
  stopLoading();
}

const VIEW_CHANGED_EVENT_NAME = 'viewChanged';

// TODO replace it to use Composition API
const menuManager = new Vue({
  router,
  data() {
    return {
      started: false, 
      tracks: [], 
      index: -1, 
    };
  },
  computed: {
    _currentTracks() {
      const { tracks, index } = this;
      if (index >= 0) {
        return tracks.slice(0, index);
      }
      return [ ...tracks ];
    },
    _currentHead() {
      return this._currentTracks?.[0] || {};
    },
    _currentTail() {
      return this._currentTracks?.[this._currentTracks.length - 1] || {};
    },
    isBackingActive() {
      return this.index >= 0;
    },
    showBackButton() {
      return this._currentTracks.length > 1;
    },
    currentId() {
      return this._currentTail?.groupMenu?.path;
    },
    asGrid() {
      const { _currentTail } = this;
      const menu = _currentTail?.subMenu ?? _currentTail?.groupMenu;
      if (!menu) return true;

      const { $route, isBackingActive } = this;
      const menuRoute = router.match(menu.path);
      const isGroup = !menu.parent || menu.group;
      return isGroup && (isBackingActive || menuRoute.path === $route.path);
    },
  },
  watch: {
    asGrid: {
      handler() {
        this.$emit(VIEW_CHANGED_EVENT_NAME);
      },
      immediate: true,
    },
    '_currentTail.subMenu'() {
      this.$emit(VIEW_CHANGED_EVENT_NAME);
    },
  },
  methods: {
    _reset() {
      this.tracks = [];
      this.index = -1;
    },
    _resetToCurrentTracks() {
      this.tracks = [ ...this._currentTracks ];
      this.index = -1;
    },
    start(path) {
      if (!path) {
        console.error('menu-manager.js >>> a path must be passed to "menuManager.start(<path>)"');
        return;
      }

      if (this.started) return;

      this.started = true;
      const appNavigation = appNavigationByRoutePathFn(path);
      const currentMenu = appNavigation?.currentMenu?.$isTabMenu 
        ? appNavigation?.currentMenu?.parent 
        : appNavigation?.currentMenu;

      if (this.configure(currentMenu))  {
        this._autoSelectIfNeeded();
      } else {
        this.close(); // revert when starting has not succeed
      }
    },
    configure(menu) {
      this._reset();
      const menus = findMenusFromPath(menu);
      if (menus?.length > 0) {
        menus.forEach(m => this.trackMenu(m));
      }

      return this.tracks.length > 0;
    },
    close() {
      this.started = false;
      this._reset();
    },
    copyTracks() {
      return [ ...this.tracks || [] ]
        .map(item => item.copy());
    },
    recoverTracks(tracks) {
      const listCopy = tracks.map(item => item.copy());
      this.$set(menuManager, 'tracks', listCopy);
    },
    trackMenu(menu) {
      if (!menu) return;

      if (!menu.parent) {
        this._reset();
      } else {
        this._resetToCurrentTracks();
      }

      if (menu.path === this._currentTail.groupMenu?.path) {
        this._currentTail.setSubMenu(null);
      } else if (!menu.parent) {
        this.tracks.push(TrackMenu(menu));
      } else if (isMenuVisible(this._currentTail.groupMenu, menu)) {
        this._currentTail.setSubMenu(menu);
  
        // when it is a group menu start a new track menu item
        if (menu.group) {
          this.tracks.push(TrackMenu(menu));
        }
      }
    },
    prev() {
      const { index } = this;
      if (index < 0) {
        this.index = this.tracks.length - 1;
      } else {
        this.index = index - 1;
      }
    },
    next() {
      const { index } = this;
      this.index = index + 1;
    },
    isActive(menu) {
      // when it is a primary menu it checks the head of the tracks
      const menuTrack = !menu.parent ? this._currentHead : this._currentTail;
      return menuTrack?.isActive?.(menu);
    },
    async _openRoute(route, menu, replace = false, ignoreAutoSelect = false) {
      await _navigateOnly(route, forceOpen(menu), replace);

      const { currentRoute } = router;
      const menuRoute = router.matcher.match(menu.path);
      const completed = currentRoute?.path?.replace(/\/$/, '') === menuRoute?.path?.replace(/\/$/, '');
      if (completed) {
        this.trackMenu(menu);
        if (!ignoreAutoSelect) {
          this._autoSelectIfNeeded();
        }
      }
    },
    async openRoute(route) {
      route = router.matcher.match(route);
      const { currentMenu } = appNavigationByRoutePathFn(route.redirectedFrom ?? route.path);
      await this._openRoute(route, currentMenu);
    },
    async openRouteRestarting(route) {
      route = router.matcher.match(route);
      const { currentMenu } = appNavigationByRoutePathFn(route.redirectedFrom ?? route.path);
      this.configure(currentMenu);
      await this._openRoute(route, currentMenu);
    },
    async openMenu(menu) {
      this._openRoute(menu.path, menu);
    },
    async openMenuRestarting(menu) {
      this.configure(menu);
      await this.openMenu(menu);
    },
    _autoSelectIfNeeded() {
      const { groupMenu, subMenu } = this._currentTail;
      if (subMenu) return;

      let menu = null;
      if (groupMenu.autoSelectTo?.length > 0) {
        const menus = groupMenu.autoSelectTo.map(path => appNavigationByMenuPathFn(path)?.currentMenu);
        menu = menus.find(m => isMenuVisible(groupMenu, m));
      }

      if (menu) {
        this._openRoute(menu.path, menu, true);
      }
    },
    checkMenuVisibility() {
      const { groupMenu, subMenu } = this._currentTail;
      if (subMenu && !isMenuVisible(groupMenu, subMenu)) {
        this._openRoute(groupMenu.path, groupMenu, true, true);
      }
    },
  },
});

function findMenusFromPath(menu) {
  if (!menu?.path) return [];

  const findParents = (m) => {
    if (m?.parent?.path) {
      return [
        ...findParents(m.parent),
        m.parent,
      ];
    }
    return [];
  };

  const generateMenus = m => ([
    ...findParents(m), 
    m, 
  ]);

  const configuredOptionMenu = store.getters[MENU_TYPES.GETTERS.CONFIGURED_OPTION_MENU];

  let configuredMenu = configuredOptionMenu
    .find(om => om.path === menu.path && om.parent?.path === menu.parent?.path);
  if (!configuredMenu) {
    configuredMenu = configuredOptionMenu.find(om => om.path === menu.path);
  }

  if (configuredMenu) {
    // configured structure
    return generateMenus(configuredMenu);
  }

  // default structure
  return generateMenus(menu);
}

if (process.env.NODE_ENV === 'development') {
  window.__MENU_MANAGER__ = menuManager;
}

export default menuManager;
