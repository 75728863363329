var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "broker-overview__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "3f6a2d8a-406b-4d4a-85f9-7204579b310a",
          data: _vm.dashboardData,
          externalConfig: _vm.externalWidgetsConfig,
          isExternalLoading: _vm.externalLoading
        },
        on: {
          saved: function($event) {
            return _vm.saveBrokerOverviewConfiguration($event)
          },
          restored: function($event) {
            return _vm.saveBrokerOverviewConfiguration($event)
          },
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          }
        },
        scopedSlots: _vm._u(
          [
            Object.keys(_vm.brokerParagraphsConfiguration).length
              ? {
                  key: "topBox",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "paragraphs-container" },
                        _vm._l(
                          Object.keys(_vm.brokerParagraphsConfiguration),
                          function(paragraph, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "paragraphs--item" },
                              [
                                _vm.brokerParagraphsConfiguration[paragraph]
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "paragraphs--item--status color-success"
                                      },
                                      [_c("PhCheck", { attrs: { size: 24 } })],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.brokerParagraphsConfiguration[paragraph]
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "paragraphs--item--status color-danger"
                                      },
                                      [
                                        _c("PhWarning", { attrs: { size: 24 } })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  { staticClass: "paragraphs--item--text" },
                                  [_vm._v(_vm._s(paragraph))]
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "GeburtstageVermittler",
              fn: function() {
                return [
                  _c("GeburtstageVermittler", { ref: "geburtstageVermittler" })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "BaseModal",
        {
          ref: "fkEventsModal",
          attrs: { labelButtonCancel: "Schließen", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.fkEventsTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_c("EventsFkOverview")], 1)]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }