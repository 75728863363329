var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", { attrs: { showHeaderCard: false } }),
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "div",
        { staticClass: "row space-between" },
        _vm._l(_vm.resultSums, function(sumItem, index) {
          return _c(
            "div",
            { key: index, staticClass: "flex-item" },
            [
              _c(
                "BoxContainer",
                {
                  staticClass: "text-center",
                  attrs: { tid: _vm._generateTidFromString("sum" + index) }
                },
                [
                  _c("span", { staticClass: "text-small mp-0" }, [
                    _vm._v(_vm._s(sumItem.label))
                  ]),
                  _c("br"),
                  sumItem.type.includes("currency")
                    ? _c(
                        "span",
                        [
                          _c("CurrencyLabel", {
                            attrs: { value: sumItem.value }
                          })
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(sumItem.value))])
                ]
              )
            ],
            1
          )
        }),
        0
      ),
      _c("BalanceSheetInsuranceTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }