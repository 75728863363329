var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Konfiguration des Re-Balancing für den FFB-Fondsshop",
          actions: _vm.headerActions
        },
        on: {
          "action-CONFIRM": function($event) {
            return _vm.confirm()
          },
          "action-LATER": function($event) {
            return _vm.spaterAnzeigen()
          },
          "action-DECLINE": function($event) {
            return _vm.decline()
          },
          "action-EMAIL-EDIT": function($event) {
            return _vm.editEmail()
          }
        }
      }),
      _vm.simpleActionFondsshopData && _vm.simpleActionFondsshopData.settings
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("p", [
                _vm._v(
                  "Leider kam es bei einigen Depoteröffnungen über Ihren Fondsshop zu einem Fehler in der Formulargenerierung."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Entgegen Ihrer Erwartung wurden bei den in der Anlage aufgeführten Kunden nicht das automatische Rebalancing der Depots beauftragt, konfiguriert (vierteljährlich/halbjährlich/jährlich) und ausgeführt. Aufgrund eines technischen Fehlers fehlte die entsprechende Beauftragung in den Depoteröffnungsunterlagen."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Mit dieser Aktion werden automatisch alle Ihre betroffenen Kunden per E-Mail (und ggf. App-Push-Nachricht) kontaktiert. Dem Kunden wird ein Formular zur Verfügung gestellt, dass er (ggf. auch digital per App) unterschreiben und Ihnen bzw. der FFB zuschicken kann. Sie können aus folgenden Formularvarianten wählen. Bitte beachten Sie: Der Kunde kann nur eine dieser Varianten zugeschickt bekommen und unterschreiben"
                )
              ]),
              _c("InputRadioBoxGroup", {
                staticStyle: { width: "100%" },
                attrs: {
                  values: [
                    {
                      value: "MIT_REBALANCING",
                      label: "Formular mit sofortigem Rebalancing des Bestands"
                    },
                    {
                      value: "OHNE_REBALANCING",
                      label: "Formular ohne sofortigem Rebalancing des Bestands"
                    },
                    {
                      value: "AUSWAHL_REBALANCING",
                      label:
                        "Formular mit Kundenauswahl für sofortiges Rebalancing des Bestands"
                    }
                  ],
                  value: _vm.settings,
                  isComponentHalfSize: ""
                },
                on: {
                  input: function($event) {
                    return _vm.onRadioInput($event)
                  }
                }
              }),
              _c("p", [_vm._v("Liste der betroffenen Kunden:")]),
              _c("p", [
                _vm._v(
                  "Sie können ebenfalls die PDF-Dokumente für die Kunden herunterladen, um diese per Post zu verschicken"
                )
              ]),
              _c("p", [
                _vm._v(
                  "Der E-Mail-Versand findet statt, falls der Kunde eine E-Mail-Adresse besitzt und in der Liste angekreuzt ist."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Ein Kunde kann auch mehrere betroffene Depots haben und daher mehrere Formulare benötigen."
                )
              ]),
              _vm.tableKundenRows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "4298d323-c283-4bd5-a7b2-a67a5f3d692a",
                      headers: _vm.tableKundenHeaders,
                      rows: _vm.tableKundenRows,
                      rowsPerPage: 5
                    },
                    on: { "click-kundennr": _vm.openCustomerNewTab },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "settings",
                          fn: function(row) {
                            return [
                              _c("DownloadLink", {
                                attrs: {
                                  asLink: "",
                                  title: row.settings,
                                  downloadServicePath:
                                    "/simpleActionFondshopPdf",
                                  filename:
                                    "Fondsshop_Depot_" +
                                    _vm.settings +
                                    "_Rebalancing.pdf",
                                  queryParams: {
                                    simpleActionKundeId: row.id,
                                    settings: _vm.settings
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2625411651
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                : _vm.rows.length === 0
                ? _c("NoData")
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }