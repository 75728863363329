var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Aufgaben",
          actions: _vm.headerActions
        },
        on: { "action-NEW_ACTIVITY": _vm.newActivity }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Aufgaben Filter",
              filterId: "PostfachActivityFilter",
              isCache: true,
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading && !_vm.scrollLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("PaginatedTable", {
                attrs: {
                  title: "Aufgaben",
                  tableId: "015eb9cf-6e4f-4dca-b9e3-52f0b49736bd",
                  rowId: "rowid",
                  headers: _vm.headers,
                  page: _vm.selectedPage,
                  pages: _vm.pages,
                  pageCount: _vm.pageCount,
                  rowCount: _vm.rowCount,
                  selectionAsBlacklist: _vm.invertSelection,
                  sorted: _vm.searchSort,
                  headerActions: _vm.tableHeaderActions,
                  exportConfig: _vm.exportConfig,
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  selectionAsBlacklist: function($event) {
                    _vm.invertSelection = $event
                  },
                  page: function($event) {
                    _vm.selectedPage = $event
                  },
                  requestPage: _vm.loadPage,
                  sort: _vm.onSearchSort,
                  onScroll: _vm.onScroll,
                  scrollLoading: function($event) {
                    _vm.scrollLoading = $event
                  },
                  "click-subject": _vm.clickBetreff,
                  "click-bereich": _vm.clickBereich,
                  "action-DELETE": _vm.deleteRow,
                  "action-DONE": _vm.doneRow,
                  "headerAction-BULK_DELETE": _vm.deleteSelected,
                  "headerAction-BULK_POSTPONE": _vm.openPostponeModal,
                  "headerAction-BULK_DONE": _vm.doneSelected
                },
                scopedSlots: _vm._u([
                  {
                    key: "participants",
                    fn: function(row) {
                      return [
                        _c(
                          "ul",
                          { staticStyle: { padding: "0", margin: "0" } },
                          _vm._l(row.participants, function(
                            participant,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: participant.id,
                                staticStyle: { display: "inline-block" }
                              },
                              [
                                participant.isKunde &&
                                _vm.canOpenCustomer(participant)
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.openCustomerNewTab(
                                              participant.nummer
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(participant.name))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(participant.name))
                                    ]),
                                index < row.participants.length - 1
                                  ? _c("span", [_vm._v("; ")])
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "postponeModal",
          attrs: {
            modalTitle: "Markierte Aufgaben verschieben auf",
            labelButtonConfirm: "Verschieben",
            confirmDisabled: !_vm.postponeDate
          },
          on: { onConfirmButton: _vm.postponeActivities }
        },
        [
          _c("DatePickerField", {
            attrs: {
              isComponentHalfSize: "",
              isValueAsString: "",
              renderDanger: !_vm.postponeDate
            },
            model: {
              value: _vm.postponeDate,
              callback: function($$v) {
                _vm.postponeDate = $$v
              },
              expression: "postponeDate"
            }
          }),
          _vm.isDateBeforeNow(_vm.postponeDate)
            ? _c("div", { staticClass: "fc-form-danger" }, [
                _vm._v(
                  "Die Aufgaben liegen in der Vergangenheit und werden nicht versendet"
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }