var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "FondsVergleich",
          subtitle: "Einstellungen",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _vm.initialize
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputRadioBoxGroup", {
                attrs: {
                  title: "Berechnungsbasis",
                  values: _vm.settings.berechnungsbasisoptions
                },
                model: {
                  value: _vm.settings.berechnungsbasis,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "berechnungsbasis", $$v)
                  },
                  expression: "settings.berechnungsbasis"
                }
              }),
              _c("InputRadioBoxItem", {
                staticClass: "mb-0",
                attrs: {
                  name: "steuerart",
                  checked:
                    _vm.settings.steuerart ===
                    _vm.settings.steuerartoptions[0].value,
                  value: _vm.settings.steuerartoptions[0].value,
                  label: _vm.settings.steuerartoptions[0].label
                },
                on: {
                  change: function($event) {
                    _vm.settings.steuerart =
                      _vm.settings.steuerartoptions[0].value
                  }
                }
              }),
              _c("InputField", {
                staticClass: "ml-4",
                attrs: { type: "percent", isComponentHalfSize: "" },
                model: {
                  value: _vm.settings.steuerindividuell,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "steuerindividuell", $$v)
                  },
                  expression: "settings.steuerindividuell"
                }
              }),
              _c("InputRadioBoxItem", {
                attrs: {
                  name: "steuerart",
                  checked:
                    _vm.settings.steuerart ===
                    _vm.settings.steuerartoptions[1].value,
                  value: _vm.settings.steuerartoptions[1].value,
                  label: _vm.settings.steuerartoptions[1].label
                },
                on: {
                  change: function($event) {
                    _vm.settings.steuerart =
                      _vm.settings.steuerartoptions[1].value
                  }
                }
              }),
              _vm.settings.steuersoli != undefined
                ? _c(
                    "div",
                    [
                      _c("InputCheckBoxItem", {
                        attrs: { label: "Solidaritätszuschlag" },
                        model: {
                          value: _vm.settings.steuersoli,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "steuersoli", $$v)
                          },
                          expression: "settings.steuersoli"
                        }
                      }),
                      _c("InputCheckBoxItem", {
                        attrs: { label: "Kirchensteuer" },
                        model: {
                          value: _vm.settings.steuerkirche,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "steuerkirche", $$v)
                          },
                          expression: "settings.steuerkirche"
                        }
                      }),
                      _c("InputRadioBoxGroup", {
                        staticClass: "ml-4 mt-2",
                        attrs: { values: _vm.settings.steuerkircheoptions },
                        model: {
                          value: _vm.settings.steuerkirchevalue,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "steuerkirchevalue", $$v)
                          },
                          expression: "settings.steuerkirchevalue"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.initialize
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputCheckBoxItem", {
                attrs: { label: "Stammdaten" },
                model: {
                  value: _vm.settings.showDaten,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "showDaten", $$v)
                  },
                  expression: "settings.showDaten"
                }
              }),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title: "PDF-Version",
                  values: _vm.settings.pdfversionoptions
                },
                model: {
                  value: _vm.settings.pdfversion,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "pdfversion", $$v)
                  },
                  expression: "settings.pdfversion"
                }
              }),
              _vm.settings.hauptkriteriumoptions &&
              _vm.settings.hauptkriteriumoptions.length
                ? _c("ComboBox", {
                    attrs: {
                      label: "Hauptkriterium Optionen",
                      values: _vm.settings.hauptkriteriumoptions,
                      isComponentHalfSize: ""
                    },
                    model: {
                      value: _vm.settings.hauptkriterium,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "hauptkriterium", $$v)
                      },
                      expression: "settings.hauptkriterium"
                    }
                  })
                : _vm._e(),
              _c("InputRadioBoxGroup", {
                attrs: { title: "Layout", values: _vm.settings.layoutoptions },
                model: {
                  value: _vm.settings.layout,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "layout", $$v)
                  },
                  expression: "settings.layout"
                }
              }),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "layout__negative-margin--8" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.standardConfig.open()
                          }
                        }
                      },
                      [_vm._v("Zusammensetzung")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.einfachConfig.open()
                          }
                        }
                      },
                      [_vm._v("Zusammensetzung Vereinfacht")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.initialize
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputCheckBoxItem", {
                attrs: { label: "Rendite-Risiko-Chart" },
                model: {
                  value: _vm.settings.showRisiko,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "showRisiko", $$v)
                  },
                  expression: "settings.showRisiko"
                }
              }),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title: "Zeitraum",
                  values: _vm.settings.RisikoJahreOptions
                },
                model: {
                  value: _vm.settings.RisikoJahreValue,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "RisikoJahreValue", $$v)
                  },
                  expression: "settings.RisikoJahreValue"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Tendenz - Anzahl Wochen",
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.settings.risikoRenditeTendenz,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "risikoRenditeTendenz", $$v)
                  },
                  expression: "settings.risikoRenditeTendenz"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.initialize
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputCheckBoxItem", {
                attrs: { label: "Wertentwicklung-Chart" },
                model: {
                  value: _vm.settings.showWertEntw,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "showWertEntw", $$v)
                  },
                  expression: "settings.showWertEntw"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Zeitraum - die lezten Jahre",
                  values: _vm.settings.WertEntwJahreOptions,
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.settings.WertEntwJahreValue,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "WertEntwJahreValue", $$v)
                  },
                  expression: "settings.WertEntwJahreValue"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Reihenfolge der Fonds",
                  values: _vm.settings.WertEntwSortOptions,
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.settings.WertEntwSort,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "WertEntwSort", $$v)
                  },
                  expression: "settings.WertEntwSort"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ModalColumnConfig", {
        ref: "standardConfig",
        attrs: {
          title: "Sichtbarkeit und Reihenfolge",
          tableHeaders: _vm.standardHdrs
        },
        on: {
          orderChanged: function(hdrs) {
            return _vm.setHeaders(hdrs, true)
          },
          onRestoreDefault: function($event) {
            return _vm.restoreDefault(true)
          }
        }
      }),
      _c("ModalColumnConfig", {
        ref: "einfachConfig",
        attrs: {
          title: "Sichtbarkeit",
          ordered: false,
          tableHeaders: _vm.einfachHdrs
        },
        on: {
          orderChanged: function(hdrs) {
            return _vm.setHeaders(hdrs, false)
          },
          onRestoreDefault: function($event) {
            return _vm.restoreDefault(false)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("BaseBackButtonBreadcrumb", {
            attrs: { showAsSecondaryButton: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }