var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.pageTitle
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.confirmationInfo && _vm.confirmationInfo.pruefnummer
            ? _c("div", [
                _c("p", [_vm._v(_vm._s(_vm.confirmationInfo.anrede))]),
                _vm.confirmationInfo.alreadyConfirmed
                  ? _c("div", [
                      _c(
                        "p",
                        [
                          _vm._v(" Ihr "),
                          _c("DownloadLink", {
                            attrs: {
                              target: "_blank",
                              rel: "noopener noreferer",
                              title: _vm.apTitle,
                              disabled: _vm.isSomeButtonLoading,
                              href: _vm.linkmaker()
                            }
                          }),
                          _vm._v(" ist bereits bestätigt. ")
                        ],
                        1
                      ),
                      _c("p", [_vm._v("Vielen Dank!")])
                    ])
                  : _c(
                      "div",
                      [
                        _c("p", [
                          _vm._v(
                            " die WealthKonzept AG ist als Ihr Vermögensverwalter gesetzlich dazu verpflichtet in regelmäßigen Abständen die Aktualität Ihrer persönlichen Verhältnisse, wie sie im Anlegerprofil dokumentiert sind, zu überprüfen. Hat sich Ihr Anlegerprofil in dieser Hinsicht geändert? "
                          )
                        ]),
                        _c(
                          "p",
                          [
                            _c("DownloadLink", {
                              attrs: {
                                target: "_blank",
                                rel: "noopener noreferer",
                                title: _vm.apTitle,
                                disabled: _vm.isSomeButtonLoading,
                                href: _vm.linkmaker()
                              }
                            })
                          ],
                          1
                        ),
                        _c("InputRadioBoxGroup", {
                          attrs: {
                            disabled: _vm.isSomeButtonLoading,
                            values: _vm.values,
                            labelClass: "font-bold"
                          },
                          model: {
                            value: _vm.apHasChanged,
                            callback: function($$v) {
                              _vm.apHasChanged = $$v
                            },
                            expression: "apHasChanged"
                          }
                        }),
                        _c(
                          "BaseButton",
                          {
                            attrs: {
                              disabled:
                                _vm.apHasChanged === null ||
                                _vm.isSomeButtonLoading
                            },
                            on: { click: _vm.confirm }
                          },
                          [
                            _vm._v(" Bestätigen "),
                            _vm.loading.confirm
                              ? _c("AnimatedSpinner")
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            : _vm.loading.init
            ? _c("GhostLoading", {
                attrs: { type: "block", config: { block: { height: 250 } } }
              })
            : _c("NoData", { attrs: { content: "Keine Daten" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }