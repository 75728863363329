<template>
  <!-- <div v-if="!isHidden"> -->
  
  <div class="top-component">
    <div v-if="showComponentAsDisabled" class="disable-component-arrea"></div>
    <component 
      :is="dynamicTemplate" 
      v-bind="dynamicBindings.props"
      v-on="dynamicBindings.events"
      v-if="dynamicTemplate"
      :class="dynamicBindings.class || ''" 
      ref="antragComponent"
    />
  </div>
 
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils.js';
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import expressionEvaluator from '@/mixins/expression/mixin.js';

const COMPONENT_SHOW_HALF_SCREEN = ['TEXT', 'TEXT_CURRENCY', 'TEXT_FOREIGN_CURRENCY', 'TEXT_PROZENT', 'TEXT_NUMBER', 'TEXT_NUMBER_INTEGER', 'PERSON_PICKER', 
  'BANKVERBINDUNG_PICKER', 'COMBOBOX', 'DATE_PICKER', 'DEPOTNUMMER_ANFORDERN_BUTTON', 'TEXT_FORM_PERCENT', 
  'KONTOFUEHRUNGSGEBUEHREN', 'SERVICEGEBUEHREN', 'TEXT_IBAN', 'TEXT_BIC', 'TEXT_PASSWORD']

export default {
  mixins: [expressionEvaluator],
  props: {
    component: {
    },
    components: {
      type: Array,
      default: null,
    },
    comboboxSelection: {
    },
    multiSelectionsTabledata: {
    },
    antragId: {
    },
    // componentValue: {
    // },
    data: {
    },
    isComponentHalfSizeEnabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    foreignCurrenciesProp: {
      type: Object,
      default: () => {},
    },
    validateUntouched: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentData: null,
      caretPosition: null,
    }
  },
  mounted() {
    this.loadAntragDataComponent()
  },
  watch: {
    originalData: function (newData, oldData) {
      this.loadAntragDataComponent();
    },
  },
  methods: {
    getRelationship(personType) {
      const relationship = {
        relation: {}
      };

      switch(personType){
        case 'DEPOTINHABER_PRIMAER':
        case 'DEPOTINHABER_PRIMAER_MIT_ANSCHRIFT':
        case 'DEPOTINHABER_ZUSATZ':
          relationship['isExtraDepotOwner'] = true
          break;
        case 'EHEPARTNER':
          relationship.relation['value'] = 'EHE'
          break;
        case 'ARBEITGEBER':
          relationship.relation['value'] = 'ARBEITGEBER'
          break;
        case 'GESV':
          relationship['isLegalAgent'] = true
          break;
        case 'KIND':
          relationship.relation['value'] = 'KIND'
          break;
        case 'VOLLMACHT':
          relationship['isMandatory'] = true
          break;
        default: 
          return null
      }
      return relationship
    },
    loadAntragDataComponent() {
      this.componentData = this.originalData
    },
    commitUpdateAntragData() {
      if (this.componentData !== this.originalData && !this.isDisabled) {
        const valueToSave = this.convertValueToSave(this.componentData)

        const payload = {
          componentType: this.component.type,
          id: this.antragId,
          data: {
            [this.component.id]: valueToSave,
          },
        }

        if('TEXT_AREA' === this.component.type) {
          this.caretPosition = this.$refs?.antragComponent?.$refs?.innerTextarea?.selectionStart;
          if(this.caretPosition || this.caretPosition === 0) {
            payload.data.caretPosition = this.caretPosition;
          }
        }

        if (this.component.config && this.component.config.placeholder && this.comboboxSelection && this.comboboxSelection.length) {
          payload.data[this.component.config.placeholder] = (this.comboboxSelection.find(item => item.value === this.componentData) || {}).label || '';
        }
        if (this.data && this.component.config && this.component.config.linkedInputs) {
          this.updateLinkedInputs(this.data, payload.data, this.component.id);
        }

        this.$emit('updateStore', payload);
        this.$emit('change', valueToSave);
        this.$emit('dataChange', { ...payload.data, })
      }
    },
    updateLinkedInputs(searchData, updates, key) {
      let keys = [key];
      let nextKeys = [];
      while (keys.length > 0) {
        searchData = {
          ...searchData,
          ...updates
        }
        keys.forEach(currentKey => {
          const component = currentKey == this.component.id ? this.component : this.components.find(component => component.id == currentKey);
          if (!component || !component.config || !component.config.linkedInputs)
            return;
          Object.keys(component.config.linkedInputs).forEach(key => {
            if (updates[key] !== undefined)
              return; // the value for this key has already been updated, no need to evaluate again
            if (this.components && !nextKeys.includes(key))
              nextKeys.push(key);
            updates[key] = this.evaluateExpression(searchData, component.config.linkedInputs[key]);
          });
        });
        keys = nextKeys;
        nextKeys = [];
      }
    },
    commitUpdateAntragDataDeckblattEvent($event) {
      let e = {...$event, 
        button: this.component.config.type,
      }
      this.commitUpdateAntragDataFromEvent(e);
    },

    commitUpdateAntragDataControlEvent($event) {
      let submitDataComponentId = this.component.config.control_component;
      let e = {...$event, 
        button: this.component.config.type,
        submitData: this.antragData[submitDataComponentId] ? this.antragData[submitDataComponentId]: '', 
        submitDataName: submitDataComponentId
      }
      this.commitUpdateAntragDataFromEvent(e);
    },
    commitUpdateAntragDataFromEvent($event) {
      const payload = {
        componentType: this.component.type,
        id: this.antragId,
        data: {
          ...$event
        }
      }
      this.$emit('updateStore', payload);
      this.$emit('change', $event);
      this.$emit('dataChange', { ...payload.data, });
    },
    convertValueToSave(value) {
      if (typeof value === 'number') {
        return FinancialCalculator.toGermanFormat(value, this.component.config?.precision);
      } else if (value instanceof Date && this.component.config?.monthPicker) {
        return value.getMonth() + '.' + value.getFullYear();
      } 

      return value
    },
    convertValueToForm(value) {
      if (value && typeof value !=='number' && ['TEXT_NUMBER', 'TEXT_PROZENT', 'TEXT_CURRENCY'].includes(this.component.type) ) {
        let newValue = value.split('.').join('').split(',').join('.');
        if (value.length > 0 && value.charAt(0) === '-' && !newValue.startsWith('-'))
          newValue = '-' + newValue

        value = parseFloat(newValue);
      }
      return value
    }
  },
  computed: {
    ...mapGetters({
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE
    }),
    isComponentShowHalfScreen() {
      return this.isComponentHalfSizeEnabled && COMPONENT_SHOW_HALF_SCREEN.some((componentType) => componentType === this.component.type)
    },
    originalData() {
      let value = null
      if (this.component && this.component.id) {
        if (this.data) {
          value = this.data[this.component.id]
          if (!value && value !== "") {
            value =  this.data[this.component.id.toUpperCase()]
          }
          if (!value && value !== "") {
            value =  this.data[this.component.id.toLowerCase()]
          }

        } else if (this.antragId && this.antragData) {
          value = this.antragData[this.component.id] || this.antragData[this.component.id.toUpperCase()] || this.antragData[this.component.id.toLowerCase()]
        } 
      }

      value = this.convertValueToForm(value)      
      return value
    },
    componentValidators() {
      const componentValidators = this.component.validators;

      if(!componentValidators || componentValidators.length === 0) {
        return [];
      }

      return componentValidators.map(componentValidatorToRule);
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    antragData() {
      if (this.antraegeData && this.antraegeData[this.antragId]) {
        return this.antraegeData[this.antragId]
      } else if (this.data && Object.keys(this.data).length) {
        return this.data;
      }
      return null
    },
    /**
     * The warnigs has two properties:
     * - positionWarnings
     * - antragWarnings
     */
    warnings() {
      if (this.antrag && this.antrag.warnings) {
        return this.antrag.warnings
      }
      return null
    },
    /**
     * Returns the sub property antragWarnings from the warnigs property
     */
    antragWarnings() {
      if (this.warnings && this.warnings.antragWarnings) {
        return this.warnings.antragWarnings
      }
      return null
    },
    /**
     * Returns the sub property positionWarnings from the warnigs property
     */    
    positionWarnings() {
      if (this.warnings && this.warnings.positionWarnings) {
        return this.warnings.positionWarnings
      }
      return null
    },
    foreignCurrencies() {
      return this.foreignCurrenciesProp || {};
    },
    isDisabled() {
      return this.disabled || this.isDataReadOnly || this.isUnterschrift || (this.component && this.component.disabled && isHiddenDisabled(this.component.disabled, this.antragData ? this.antragData : this.data))
    },
    isUnterschrift() {
      return this.antragData?.isSigniert === '1';
    },
    componentValues() {
      if(!this.antrag || !this.antrag.comboboxSelections || !this.antrag.comboboxSelections[this.component.id]) {
        return [];
      }

      return this.antrag.comboboxSelections[this.component.id];
    },
    beratungsMappeId() {
      return this.antrag && this.antrag.beratungsMappeData && this.antrag.beratungsMappeData.id;
    },
    isDataReadOnly() {
      return !!(this.antragData && this.antragData['READ_ONLY'] === true);
    },
    dynamicBindings() {
      if (this.component && this.component.type) {
        switch (this.component.type) {
          case 'TEXT':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                placeholder: this.component.placeholder,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched || this.component.config?.validateUntouched,
                autocomplete: this.component.config?.autocomplete,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_AREA':
            {
              let decodedValue = '';
              try {
                decodedValue = this.componentData && this.component.config?.useEncodedValue ? decodeURIComponent(this.componentData) : this.componentData;
              } catch(e) {
                decodedValue = this.componentData;
              }
              return {
                props: {
                  id: this.component.id,
                  label: this.component.label,
                  value: decodedValue,
                  placeholder: this.component.placeholder,
                  disabled: this.isDisabled,
                  useEncodedValue: this.component?.config?.useEncodedValue,
                  autoGrow: !!this.componentData,
                },
                events: {
                  input: ($event) => {this.componentData = ($event && this.component.config?.useEncodedValue ? encodeURIComponent($event) : $event)},
                  change: () => this.commitUpdateAntragData(),
                  keyup: ($event) => {
                    if(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes($event.key))
                      this.caretPosition = this.$refs?.antragComponent?.$refs?.innerTextarea?.selectionStart
                  },
                }
              }
           }
          case 'TEXT_HTML':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: (this.componentData && this.component.config?.useEncodedValue ? decodeURIComponent(this.componentData) : this.componentData),
                placeholder: this.component.placeholder,
                disabled: this.isDisabled,
                useEncodedValue: this.component?.config?.useEncodedValue, 
              },
              events: {
                input: ($event) => {
                  this.componentData = ($event && this.component.config?.useEncodedValue ? encodeURIComponent($event) : $event)
                  this.commitUpdateAntragData()
                },
              }
            }
          case 'TEXT_CHIPS': 
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData ? JSON.parse(this.componentData) : [],
                supportCustomValue:  this.component.config?.supportCustomValue,
                disableInput: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.componentData = JSON.stringify($event);
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'COMBOBOX':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.comboboxSelection,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                firstEmpty: this.component.config?.firstEmpty,
                boldValues: this.component.config?.boldValues,
                sortComboboxValues: this.component.config?.disableSorting ? false : true,
                validateUntouched: this.validateUntouched,
              },
              events: {
                change: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                  if (this.component.config?.action === 'updateKonsorteEbaseAab') {
                    this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_KONSORTE_EBASE_AAB, { antragId: this.antragId, bgsNr: $event });
                  }
                }
              }
            }
            case 'BUTTON_DECKBLATT':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                config: this.component.config,
                isSecondary: true
              },
              events: {
                click: ($event) => {
                  this.commitUpdateAntragDataDeckblattEvent($event)
                }
              }
            }
            case 'BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                click: ($event) => {
                  this.commitUpdateAntragDataControlEvent($event)
                }
              }
            }
            case 'ATTACHMENTS':
            return {
              props: {
                componentId: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                config: this.component.config,
                acceptFileTypes: '.pdf',
                antragId: this.antragId,
                antragData: this.antragData,
                antrag: this.antrag,
              },
            }
          case 'DIVIDER':
            return {
              props: {
              }
            }
          case 'SWITCHER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                inLineLabel: true,
                disabled: this.isDisabled,
                config: this.component.config,
                switchHandlerTopAlign: true
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'SINGLE_SELECTION': 
            return {
              props: {
                id: this.component.id,
                title: this.component.label,
                values: this.component.values,
                name: this.component.id,
                value: this.componentData,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'SINGLE_SELECTION_DYNAMIC_VALUE': 
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.component.values,
                name: this.component.id,
                value: this.componentData,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'MULTIPLE_SELECTION': 
            return {
              props: {
                id: this.component.id || this.component.config?.validationMessageID || Math.random().toString(36).substr(2, 10),
                title: this.component.label,
                componentFields: this.component.config?.componentFields,
                showButtonsSelectDeselect: this.component.config?.showButtonsSelectDeselect,
                antragData: this.antragData,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
            case 'MULTIPLE_SELECTION_TABLE': 
            return {
              props: {
                id: this.component.id,
                title: this.component.label,
                tableData: this.multiSelectionsTabledata,
                tableRecords: this.componentData,
                antragData: this.antragData,
                name: this.component.id,
                config: this.component.config,
              },
              events: {
                input: ($event) => {
                  //index can be zero and zero is a valid value
                  if($event?.index !== null || $event?.index !== undefined) {
                    const tableItemIndex = this.antragData[this.component.id][$event.index].myItems.map(item => item.headerKey).indexOf($event.key);
                    if(tableItemIndex >=0) {
                      this.antragData[this.component.id][$event.index].myItems[tableItemIndex].value = $event.event.value;
                    }
                  }
                },
                delete: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
          case 'MULTIPLE_SELECTION_CHECKBOX': 
            return {
              props: {
                id: this.component.id,
                title: this.component.label,
                value: this.componentData,
                values: this.component.values,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'DATE_PICKER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                isValueAsString: true,
                placeholder: this.component.placeholder,
                monthPicker: this.component.config && this.component.config.monthPicker,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched || this.component.config?.validateUntouched,
                showSofortOption: this.component.config?.showSofortOption,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                },
              },
              class: ''
            }
          case 'TEXT_CURRENCY':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                type: 'currency',
                placeholder: this.component.placeholder,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched,
                precision: this.component.config?.precision,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_FOREIGN_CURRENCY':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                type: 'currency',
                foreign_currency: this.foreignCurrencies?.[this.component.id],
                placeholder: this.component.placeholder,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_PROZENT':
            return {
              props: {
                precision: this.component.config && this.component.config.precision,
                id: this.component.id,
                label: this.component.label,
                type: 'percent',
                placeholder: this.component.placeholder,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_FORM_PERCENT':
            return {
              props: {
                precision: this.component.config && this.component.config.precision,
                id: this.component.id,
                label: this.component.label,
                type: 'formpercent',
                placeholder: this.component.placeholder,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.validateUntouched,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_NUMBER':
          case 'TEXT_NUMBER_INTEGER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                type: 'number',
                isInteger: this.component.type == 'TEXT_NUMBER_INTEGER',
                placeholder: this.component.placeholder,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                precision: this.component.config?.precision,
                validateUntouched: this.validateUntouched,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'TEXT_IBAN':
            return {
              props: {
                validation_path: this.component.id,
                label: this.component.label,
                type: 'IBAN',
                componentData: this.componentData,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData($event)
                }
              }
            }
          case 'TEXT_BIC':
            return {
              props: {
                validation_path: this.component.id,
                label: this.component.label,
                type: 'BIC',
                componentData: this.componentData,
                isComponentHalfSize: this.isComponentShowHalfScreen,
              },
              events: {
                change: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData($event)
                }
              }
            }
          case 'LABEL':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
              },
            }
          case 'HEADER':
            return {
              props: {
                label: this.component.label,
              },
            }
          case 'PERSON_PICKER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.comboboxSelection,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                isPersonPicker: true,
                sortComboboxValues: false,
              },
              events: {
                change: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                  if (this.componentData && this.component.config && this.component.config.vertretung 
                    && Object.keys(this.component.config.vertretung).length) {
                      const vertretung = Object.keys(this.component.config.vertretung).find(key =>
                        this.component.config.vertretung[key] && this.component.config.vertretung[key].length
                        && this.component.config.vertretung[key].some(elem => elem === this.componentData)
                      );
                      if (vertretung) {
                        this.commitUpdateAntragDataFromEvent({ INPUT_MINDERJAEHRIG: vertretung })
                      }
                  }
                },
                new: ($event) => {
                  const personId = 'neue_person';
                  var personType;
                  let payload = {}
                  if(this.antragData?.personenAuswahl.hasOwnProperty(this.component.id)){
                      personType = this.antragData?.personenAuswahl[this.component.id];
                  }
                  this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);

                  if (personType) {
                    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_SELECTED_EXTRA_DATA, {relationship: this.getRelationship(personType)});
                    if(personType === 'ARBEITGEBER'){
                       payload.relation = personType
                    }                 
                  }

                  this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA, payload);
                   // save empty data to set hasChanged to true
                  this.commitUpdateAntragDataFromEvent({});
                },
                edit: (personId) => {
                  if (personId) {
                    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
                    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
                    // save empty data to set hasChanged to true
                    this.commitUpdateAntragDataFromEvent({});
                  }
                },
              }
            }
          case 'SLIDER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.component.values,
                value: this.componentData,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent({[this.component.id]: $event})
                },
              }
            }
          case 'SORTABLE_OPTIONS':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                useCheckBox: !!this.component?.config?.useCheckBox,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent({[this.component.id]: $event})
                },
              }
            }
          case 'SORTABLE_INPUT_ITEMS':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                antragData: this.antragData,
                disabled: this.isDisabled,
                config: this.component.config,
                componentFields: this.component.config?.componentFields,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent({[this.component.id]: $event})
                },
                inputItem: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event)
                }
              }
            }
          case 'TIME_PICKER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                timeUnit: this.component.config?.setTimeUnit,
                validateUntouched: true,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                },
              },
              class: ''
            }
          case 'BANKVERBINDUNG_PICKER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.comboboxSelection,
                value: this.componentData,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
              },
              events: {
                change: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                },
                new: ($event) => {
                  this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
                },
                edit: (bv) => {
                  this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG, {id: bv});
                },
              }
            }
          case 'VERMITTLER_AUSWAHL':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
            case 'WERTPAPIER_AUSWAHL_FFB_DEPOT':
              return {
               props: {
                title: this.component.label,
                categoryId: this.component.id,
                config: this.component.config,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                componentType: this.component.type,
                disabled: this.isDisabled,
              },
            }

          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_AEVER': 
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections,
                componentType: this.component.type,
                aenderungTyp: this.component.config.aenderungTyp
              },
            }

            case 'WERTPAPIER_AUSWAHL_CAPITALBANK_ABBUCHUNG':
               return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections,
                componentType: this.component.type,
              },
            }
               case 'WERTPAPIER_AUSWAHL_CAPITALBANK_REDUKTION':
               return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections,
                componentType: this.component.type,
              },
            }
            
               case 'WERTPAPIER_AUSWAHL_CAPITALBANK_AUSSETZUNG':
               return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections,
                componentType: this.component.type,
              },
            }
              case 'WERTPAPIER_AUSWAHL_CAPITALBANK_BEENDEN':
               return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections,
                componentType: this.component.type,
              },
            }
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_INVESTMENT':
          case 'WERTPAPIER_AUSWAHL':
          case 'WERTPAPIER_AUSWAHL_DIREKT':
          case 'WERTPAPIER_AUSWAHL_MAX_COUNT':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                componentId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                componentType: this.component.type,
                disabled: this.isDisabled,
              },
            }
          case 'WERTPAPIER_AUSWAHL_EBASE':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
            }
          case 'WERTPAPIER_AUSWAHL_EBASE_CARDVIEW':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                disabled: this.isDisabled,
              },
            }  
          case 'WERTPAPIER_AUSWAHL_EBASE_WEITERE_DEPOTS_CARDVIEW':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                disabled: this.isDisabled,
                positionWarnings: this.positionWarnings,
                antragWarnings: this.antragWarnings,
              },
            }  
          case 'WERTPAPIER_AUSWAHL_EBASE_TL200':
            return {
              props: {
                title: this.component.label,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
              },
            }
           
          case 'WERTPAPIER_AUSWAHL_EBASE_TL200_AENDERUNG':
            return {
              props: {
                title: this.component.label,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                antragCategory: 'Serviceformular',
              },
            }
          case 'WERTPAPIER_AUSWAHL_EBASE_DEPOT_INTERN':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                warnings: this.positionWarnings,
              },
            }
          case 'WERTPAPIER_AUSWAHL_EBASE_DJE_BAV':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                warnings: this.positionWarnings,
                disabled: this.isDisabled,
              },
            }
          case 'EBASE_BANKDEPOT':
            return {
              props: {
                id: this.component.id,
                config: this.component.config,
                antragData: this.antragData,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                warnings: this.warnings,
              },
               events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'DEPOTNUMMER_ANFORDERN_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                antragId: this.antragId,
                additionalValidators: this.componentValidators,
                isComponentHalfSize: this.isComponentShowHalfScreen,
              },
              events: {
                input: ($event) => this.componentData = $event,
                change: ($event) => this.commitUpdateAntragData(),
                requested: ($event) => {
                  // It is needed to force component re-mount
                  this.componentData = $event[this.component.id];
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
          case 'ANLAGESTRATEGIEN_ANFORDERN_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                antragId: this.antragId,
                config: this.component.config,
                additionalValidators: this.componentValidators,
                isComponentHalfSize: this.isComponentShowHalfScreen,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
            case 'AENDEUNGSFORMULAR_LEGI_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                config: this.component.config,
                additionalValidators: this.componentValidators,
                isComponentHalfSize: this.isComponentShowHalfScreen,
              }
            }
          case 'DOWNLOAD_BUTTON':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
              },
            }
          case 'UPLOAD_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                antragId: this.antragId,
                config: this.component.config,
              },
              events: {
                input: ($event) => {
                    this.componentData = $event;
                    this.commitUpdateAntragData();                  
                }
              }
            }
          case 'PERSON_CHECKS':
            return {
              props: {
                id: this.component.id,
                antragId: this.antragId,
                config: this.component.config,
              },
              events: {
                update: ($event) => {
                  if (!this.isDataReadOnly) {
                    this.commitUpdateAntragDataFromEvent($event)
                  }
                }
              }
            }
          case 'GESELLSCHAFT_AUSWAHL':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                beratungsMappeId: this.beratungsMappeId,
                values: this.componentValues,
                disabled: this.isDisabled,
              },
            }
          case 'WERTPAPIER_AUSWAHL_TAUSCH':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
          }
          case 'ENTGELT':
            const componentFields = this.component.config && this.component.config.componentFields || [];
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                config: this.component.config,
                values: componentFields.reduce((result, cf) => {
                  result[cf.config.entgeltType] = this.antragData[cf.id];
                  return result;
                }, {}),
                disabled: this.isDisabled, 
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'AUSGABEAUFSCHLAG':
            return {
              props: {
                config: this.component.config,
                antragData: this.antragData,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
          }
          case 'DEPOTUEBERTRAGUNG':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                warnings: this.warnings,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {this.componentData = $event; this.commitUpdateAntragData()},
                change: ($event) => {this.componentData = $event; this.commitUpdateAntragDataFromEvent($event)},
              },
            }
          case 'WERTPAPIER_AUSWAHL_UNIQUE_WITHOUT_POSITION':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragWarnings: this.antragWarnings,
                data: this.antragData,
                antragId: this.antragId,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'WERTPAPIER_AUSWAHL_UNIQUE_WITH_POSITION':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragWarnings: this.antragWarnings,
                data: this.antragData,
                antragId: this.antragId,
                disabled: this.isDisabled,
                config: this.component.config,
                position: this.positions
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'ORIGIN_OF_FUNDS':
            const originOfFundsComponentFields = this.component.config && this.component.config.componentFields || [];
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                config: this.component.config,
                values: originOfFundsComponentFields.reduce((result, cf) => {
                  result[cf.id] = this.antragData[cf.id];
                  return result;
                }, {}),
                disabled: originOfFundsComponentFields.reduce((result, cf) => {
                  result[cf.id] = isHiddenDisabled(cf.disabled, this.antragData);
                  return result;
                }, {})
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'ANLAGE_ZIELE':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                values: this.component.values,
                config: this.component.config,
                type: this.component.type,
                antragData: this.antragData
              },
              events:  {
                save: ($event) => this.commitUpdateAntragDataFromEvent($event)
              }
            }
          case 'RISIKO_ANALYSE_TABLE':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                antragTyp: this.antrag && this.antrag.antragTyp,
                config: this.component.config,
                data: this.antragData,
              }
            }
          case 'RISIKO_ANALYSE_POSITIONEN':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                config: this.component.config,
                data: this.antragData,
              }
            }
          case 'ZIELMARKT_NEW':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                config: this.component.config,
                comboboxSelections: this.antrag.comboboxSelections,
                antragData: this.antragData,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'FFB_VERTRAGSNUMMER_COMBO':
            return {
              props: {
                value: this.componentData,
                id: this.component.id,
                label: this.component.label,
                comboboxSelections: this.antrag.comboboxSelections,
                antragData: this.antragData,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'BESPROCHENE':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                antragTyp: this.antrag && this.antrag.antragTyp,
                config: this.component.config,
                data: this.antragData,
                isDataReadOnly: this.isDataReadOnly,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'PORTFOLIO_STRUKTUR':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                values: this.comboboxSelection,
                antragData: this.antragData,
                id: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'FODB_KAUF':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                disabled: this.isDisabled,
              }
            }         
          case 'FODB_VERKAUF':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                config: this.component.config,
                antragData: this.antragData,
                disabled: this.isDisabled,
              }
            }   
            case 'CAPITALBANK_VERKAUF':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                config: this.component.config,
                data: this.antragData,
              }
            }   
          case 'CAPITALBANK_SPARPLAN':
              return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                config: this.component.config,
                data: this.antragData,
              },
            }   
          case 'FODB_DEPOT':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                disabled: this.isDisabled,
              },
            }  
            case 'TEMPLETON_DEPPOT':
               return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                disabled: this.isDisabled,
              },
            }  
            case 'DWS_SPAR_ENTNAHMEPLAN':
               return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
            }  
              case 'EBASE_FREMDWAEHRUNG_DEPOT':
               return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
            } 
          case 'FODB_TAUSCH':
            return {
              props: {
                title: this.component.label,
                id: this.component.id,
                antragId: this.antragId,
                antragData: this.antragData,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                config: this.component.config,
                data: this.antragData,
              },
             events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            } 
            case 'FODB_TAUSCH_DIMENSIONAL':
            return {
              props: {
                title: this.component.label,
                id: this.component.id,
                antragId: this.antragId,
                antragData: this.antragData,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                config: this.component.config,
                data: this.antragData,
                disabled: this.isDisabled,
              },
             events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            } 
          case 'FODB_VERKAUF_ETF':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                antragData: this.antragData,
                antragName: this.antrag.label,
                categoryId: this.component.id,
                antragTyp: this.antrag && this.antrag.antragTyp,
                comboboxSelections: this.antrag.comboboxSelections, 
                config: this.component.config,
                data: this.antragData,
              }
            }   
            case 'WERTPAPIER_AUSWAHL_DWS':
            return {
              props: {
                title: this.component.label,
                config: this.component.config,
                antragData: this.antragData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              }
            }

          case 'INVESTIERTE_BETRAGE':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                config: this.component.config,
                antragData: this.antragData,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'FODB_FONDS_AUSWAHL':
            return {
              props: {
                label: this.component.label,
                antragId: this.antragId,
                antragData: this.antragData,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragDataFromEvent($event);
                },
              }
            }
          case 'AUFTRAGSERTEILUNG':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                config: this.component.config,
                antragData: this.antragData,
                beratungsmappeId: this.beratungsMappeId,
                disabled: this.isDisabled,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }
          case 'ANGEBOTENE_GESELLSCHAFTEN':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                config: this.component.config,
                antragData: this.antragData,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }             
          case 'TABLE_RYSIKO_PRUFUNG_DETAILS':  
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                antragId: this.antragId,
                config: this.component.config,
                antragData: this.antragData,
                beratungsmappeId: this.beratungsMappeId,
              },
              events: {
                change: ($event) => this.commitUpdateAntragDataFromEvent($event),
              }
            }          
          case 'COMDIRECT_DEPOTUEBERTRAGUNG_BUTTON':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                value: this.componentData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
              events: {
                input: ($event) => {this.componentData = $event; this.commitUpdateAntragData()},
              },
            }
          case 'FODB_DEPOTUEBERTRAGUNG_INN':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                value: this.componentData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
              events: {
                input: ($event) => {this.componentData = $event; this.commitUpdateAntragData()},
              },
            }
            case 'EBASE_FONDSUMSCHICHTUNG':
              return{
                props:{
                label: this.component.label,
                config: this.component.config,
                comboboxSelections: this.antrag.comboboxSelections,
                antragData:this.antragData,
                value: this.antragData['INPUT_DEPOTPOSTIONEN'],
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                positionWarnings: this.positionWarnings,
                }
              }
            case 'EBASE_EDELMETAL_DEPOTUEBERTRAGUNG_INN':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                value: this.componentData,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
              events: {
                input: ($event) => {this.componentData = $event; this.commitUpdateAntragData()},
              },
            }
            case 'SHORTTEXT_EDITOR_COMPONENT':
            return {
              props: {
                label: this.component.label,
                config: this.component.config,
                value: this.componentData,
                initialValues: this.component.config.initialValues,
                placeholder: this.component.config.placeholder,
                categoryId: this.component.id,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                },
                change: () => this.commitUpdateAntragData()
              },
            }
          case 'COLOR_PICKER_FULL':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                showAutomaticColorButton: this.component.config.showAutomaticColorButton,
                isDefaultColorAsTextColor: this.component.config.isDefaultColorAsTextColor,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData(),
              }
            }
          case 'COLOR_PICKER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
                disabled: this.isDisabled,
                showAutomaticColorButton: this.component.config.showAutomaticColorButton,
                isDefaultColorAsTextColor: this.component.config.isDefaultColorAsTextColor,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData(),
              }
            }
          case 'INDEX_KOMBINATION':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                name: this.component.config?.kombinationName,
                value: this.componentData,
                indexValues: this.comboboxSelection,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                },
              }
            }
          case 'DOK_NODE_PERMISSION':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.antragData,
                componentFields: this.component.config?.componentFields,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
          case 'FONDSAMPEL_SLIDER':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.antragData,
                min: this.component.config?.min,
                max: this.component.config?.max,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
          case 'KONTOFUEHRUNGSGEBUEHREN':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.antragData,
                componentFields: this.component.config?.componentFields,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
          case 'SERVICEGEBUEHREN':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.antragData,
                componentFields: this.component.config?.componentFields,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                disabled: this.isDisabled,
              },
              events: {
                input: ($event) => {
                  this.commitUpdateAntragDataFromEvent($event);
                }
              }
            }
             case 'BUTTON_APPLY_TO_STRUCTURE':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.antragData,
                componentFields: this.component.config?.componentFields,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                disabled: this.isDisabled,
              },
              events: {
                click: ($event) => {
                  this.$emit('saveChangedConfigs')
                }
              }
            }
          case 'CONFIG_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                config: this.component.config,
                disabled: this.isDisabled,
              },
              events: {
                executeConfigButton: ($event) => {
                  this.$emit('executeConfigButton', $event)
                }
              }
            }
          case 'DOWNLOAD_LINK':
            return {
              props: {
                id: this.component.id,
                title: this.component.label,
                disabled: this.isDisabled,
                ...this.component?.config?.downloadLinkAttributes,
                queryParams: {
                  ...this.component?.config?.queryParams,
                }
              }
            }
          case 'TABLE_BIPRO_SIBLING_GESELLSCHAFTEN':
            return {
              props: {
                id: this.component.id,
                title: this.component.label,
                gesellschaft: this.component.config?.gesellschaft,
                disabled: this.isDisabled,
              },
              events: {
              }
            }
          case 'TEXT_PASSWORD':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                type: 'password',
                value: this.componentData,
                placeholder: this.component.placeholder,
                disabled: this.isDisabled,
                isComponentHalfSize: this.isComponentShowHalfScreen,
                validateUntouched: this.component.config?.validateUntouched,
                autocomplete: this.component.config?.autocomplete,
              },
              events: {
                input: ($event) => {this.componentData = $event},
                change: () => this.commitUpdateAntragData()
              }
            }
          case 'SELECT_FILE_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                value: this.componentData,
              },
              events: {
                input: ($event) => {
                  this.componentData = $event;
                  this.commitUpdateAntragData();
                }
              }
            }
          case 'CONFIRM_BUTTON':
            return {
              props: {
                id: this.component.id,
                label: this.component.label,
                disabled: this.isDisabled,
                config: this.component.config,
              },
              events: {
                confirmed: ($event) => {
                  this.commitUpdateAntragDataControlEvent($event)
                }
              }
            }
          case 'NACHHALTIGKEIT':
            return {
              props: {
                values: this.antragData,
                erlaubeEsgSchwach: true,
              },
              events: {
                updateStore: ($event) => {
                  this.commitUpdateAntragDataFromEvent({[$event?.componentId]: $event.value});
                }
              }
            }
          case 'CAPITALBANK_WIRTSCHAFT_EIGENTUEMER':
            return {
              props: {
                data: this.antragData,
                comboboxSelection: this.antrag.comboboxSelections
              },
              events: {
                updateStore: ($event) => {
                  this.commitUpdateAntragDataFromEvent({[$event?.componentId]: $event.value});
                }
              }
            }
          case 'WERTPAPIER_AUSWAHL_MORGENFUND_SPARPLAN':
              return {
               props: {
                title: this.component.label,
                categoryId: this.component.id,
                config: this.component.config,
                antragId: this.antragId,
                antragTyp: this.antrag && this.antrag.antragTyp,
                componentType: this.component.type,
                disabled: this.isDisabled,
              },
            }
          default:
            return {
              props: {
                label: this.component.label,
                componentType: this.component.type
              }
            }
        }
      }
      return null;
    },
    dynamicTemplate() {
      if (this.component && this.component.type) {
        switch (this.component.type) {
          case 'BUTTON':
            return () => import('@/components/core/BaseButton.vue')
          case 'BUTTON_DECKBLATT':
            return () => import('@/components/core/BaseButton.vue')
          case 'ATTACHMENTS':
            return () => import('@/components/core/forms/antraege/Attachments.vue')
          case 'TEXT_AREA':
            return () => import('@/components/core/forms/InputTextArea.vue')
          case 'TEXT_HTML':
            return () => import('@/components/core/forms/antraege/InputTextHtml.vue')
          case 'TEXT_CHIPS':
            return () => import('@/components/core/input-chips/InputChips.vue')
          case 'COMBOBOX':
            return () => import('@/components/core/forms/ComboBox.vue')
          case 'DIVIDER':
            return () => import('@/components/core/forms/FormDivider.vue')
          case 'SWITCHER':
            return () => import('@/components/core/forms/InputToggleSwitch.vue')
          case 'SINGLE_SELECTION': 
            return () => import('@/components/core/forms/radiobox/InputRadioBoxGroup.vue')
          case 'SINGLE_SELECTION_DYNAMIC_VALUE': 
            return () => import('@/components/core/forms/radiobox/InputRadioBoxGroupDynamicValue.vue')
          case 'MULTIPLE_SELECTION': 
            return () => import('@/components/core/forms/antraege/FormInputMultipleSelector.vue')
          case 'MULTIPLE_SELECTION_TABLE': 
            return () => import('@/components/core/forms/antraege/FormTableMultipleSelector.vue')
          case 'MULTIPLE_SELECTION_CHECKBOX': 
            return () => import('@/components/core/forms/antraege/FormInputMultipleSelectorCheckBox.vue')
          case 'DATE_PICKER':
            return () => import('@/components/core/forms/date-picker2/DatePickerField.vue')
          case 'TEXT':
          case 'TEXT_CURRENCY':
          case 'TEXT_FOREIGN_CURRENCY':
          case 'TEXT_PROZENT':
          case 'TEXT_FORM_PERCENT':
          case 'TEXT_NUMBER':
          case 'TEXT_NUMBER_INTEGER':
          case 'TEXT_PASSWORD':
            return () => import('@/components/core/forms/InputField.vue')
          case 'TEXT_IBAN':
          case 'TEXT_BIC':
            return () => import('@/components/core/forms/InputIBAN_BIC.vue')
          case 'LABEL':
            return () => import('@/components/core/forms/FormLabel.vue')
          case 'HEADER':
            return () => import('@/components/core/forms/FormHeader.vue')
          case 'PERSON_PICKER':
            return () => import('@/components/core/forms/EntitySelector.vue')
          case 'SLIDER':
            return () => import('@/components/core/forms/InputSlider.vue')
          case 'SORTABLE_OPTIONS':
            return () => import('@/components/core/forms/SortableOptions.vue')
          case 'SORTABLE_INPUT_ITEMS':
            return () => import('@/components/core/forms/SortableInputItems.vue')
          case 'TIME_PICKER':
            return () => import('@/components/core/forms/TimePicker.vue')
          case 'BANKVERBINDUNG_PICKER':
            return () => import('@/components/core/forms/EntitySelector.vue')
          case 'VERMITTLER_AUSWAHL':
            return () => import('@/components/core/forms/antraege/FormVermittlerAuswahl.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_AEVER':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCapitalbankAeVer.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_ABBUCHUNG':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCapitalbankAbbuchung.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_REDUKTION':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCapitalbankReduktion.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_AUSSETZUNG':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCapitalbankAussetzung.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_BEENDEN':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCapitalbankBeenden.vue')
          case 'WERTPAPIER_AUSWAHL_FFB_DEPOT':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFFBDepot.vue')
          case 'WERTPAPIER_AUSWAHL_CAPITALBANK_INVESTMENT':
          case 'WERTPAPIER_AUSWAHL':
          case 'WERTPAPIER_AUSWAHL_MAX_COUNT':
          case 'WERTPAPIER_AUSWAHL_DIREKT':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahl.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbase.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE_CARDVIEW':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseCardview.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE_WEITERE_DEPOTS_CARDVIEW':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseWeitereDepotsCardview.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE_TL200':
          case 'WERTPAPIER_AUSWAHL_EBASE_TL200_AENDERUNG':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseTL200.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE_DEPOT_INTERN':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseDepotIntern.vue')
          case 'WERTPAPIER_AUSWAHL_EBASE_DJE_BAV':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseDJEbAV.vue')
          case 'EBASE_BANKDEPOT':
            return () => import('@/components/core/forms/antraege/EbaseBankdepot.vue')
          case 'DAB_AUSFUELLHIFLE_BUTTON':
            return () => import('@/components/core/forms/antraege/DABAusfuellhilfeButton.vue')
            case 'DAB_NAMENSAENDERUNG_BUTTON':
            return() => import('@/components/core/forms/antraege/DABNamensaenderungButton.vue')
          case 'DEPOTNUMMER_ANFORDERN_BUTTON':
            return () => import('@/components/core/forms/antraege/DepotnummerAnfordernButton.vue')
          case 'ANLAGESTRATEGIEN_ANFORDERN_BUTTON':
            return () => import('@/components/core/forms/antraege/AnlagestrategienAnfordernButton.vue')
          case 'AENDEUNGSFORMULAR_LEGI_BUTTON':
            return() => import('@/components/core/forms/antraege/DWSLegiButton.vue')
          case 'DOWNLOAD_BUTTON':
            return () => import('@/components/core/forms/antraege/DownloadButton.vue')
          case 'UPLOAD_BUTTON':
            return () => import('@/components/core/forms/antraege/FormUploadButton.vue')
          case 'PERSON_CHECKS':
            return () => import('@/components/core/forms/antraege/PersonChecks.vue')
          case 'GESELLSCHAFT_AUSWAHL':
            return () => import('@/components/core/forms/antraege/GesellschaftAuswahl.vue')
          case 'WERTPAPIER_AUSWAHL_TAUSCH':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierauswahlTausch')
          case 'CAPITALBANK_VERKAUF':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCBVerkauf.vue')
          case 'CAPITALBANK_SPARPLAN':
              return ()=> import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlCBSparplan.vue')
          case 'ENTGELT':
            return () => import('@/components/core/forms/antraege/Entgelt.vue')
          case 'AUSGABEAUFSCHLAG':
            return () => import('@/components/core/forms/antraege/Ausgabeaufschlag.vue')
          case 'DEPOTUEBERTRAGUNG':
            return () => import('@/components/core/forms/antraege/depotUebertragung/DepotUebertragung.vue')
          case 'WERTPAPIER_AUSWAHL_UNIQUE_WITHOUT_POSITION':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue')
          case 'WERTPAPIER_AUSWAHL_UNIQUE_WITH_POSITION':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUniqueWithPosition.vue')
          case 'WERTPAPIER_AUSWAHL_DWS':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsDWSInvestmentKonto.vue')
          case 'ORIGIN_OF_FUNDS':
            return () => import('@/components/core/forms/antraege/OriginOfFunds.vue')
          case 'ANLAGE_ZIELE':
            return () => import('@/components/anlageziele/AnlagezieleForm.vue')
          case 'RISIKO_ANALYSE_TABLE':
            return () => import('@/components/core/forms/antraege/RisikoAnalyseTable.vue')
          case 'RISIKO_ANALYSE_POSITIONEN':
            return () => import('@/components/core/forms/antraege/RisikoAnalysePositionen.vue')
          case 'ZIELMARKT_NEW':
            return () => import('@/components/core/forms/antraege/ZielmarktNew.vue')
          case 'FFB_VERTRAGSNUMMER_COMBO':
            return () => import('@/components/core/forms/antraege/FFBVertragsnummerCombo.vue')
          case 'BESPROCHENE':
            return () => import('@/components/core/forms/antraege/Besprochene.vue')
          case 'PORTFOLIO_STRUKTUR':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/PortfolioStruktur.vue')
          case 'FODB_KAUF':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBKauf.vue')
          case 'FODB_VERKAUF':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBVerkauf.vue')
          case 'FODB_VERKAUF_ETF':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBVerkaufETF.vue')
          case 'INVESTIERTE_BETRAGE':
            return () => import('@/components/core/forms/antraege/InvestierteBetrage.vue')
          case 'FODB_FONDS_AUSWAHL':
            return () => import('@/components/core/forms/antraege/FODBFondsAuswahl.vue')
          case 'FODB_DEPOT':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBDepot.vue')
          case 'FODB_TAUSCH':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBTausch.vue')
          case 'FODB_TAUSCH_DIMENSIONAL':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlFODBDimensionalTausch.vue')
          case 'TEMPLETON_DEPPOT':
              return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlTempletonDepotCards.vue')  
          case 'EBASE_FREMDWAEHRUNG_DEPOT':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseFremdwaehrung.vue')   
          case 'DWS_SPAR_ENTNAHMEPLAN':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlDWSSparEntnahmeplan.vue')   
          case 'AUFTRAGSERTEILUNG':
            return () => import('@/components/core/forms/antraege/AuftragserteilungCards.vue')
          case 'ANGEBOTENE_GESELLSCHAFTEN':
            return () => import('@/components/core/forms/antraege/AngeboteneGesellschaften.vue')            
          case 'TABLE_RYSIKO_PRUFUNG_DETAILS':            
            return () => import('@/components/core/forms/antraege/RysikoPrufungDetails.vue')
          case 'COMDIRECT_DEPOTUEBERTRAGUNG_BUTTON':
            return () => import('@/components/core/forms/antraege/depotUebertragung/ComdirectDepotUebertragungButton.vue')
          case 'FODB_DEPOTUEBERTRAGUNG_INN':
            return () => import('@/components/core/forms/antraege/depotUebertragung/FODBDepotUebertragungInn.vue')
          case 'EBASE_FONDSUMSCHICHTUNG':
            return () => import('@/components/core/forms/antraege/WertpapierAuswahlEbaseUmschichtung.vue')  
          case 'EBASE_EDELMETAL_DEPOTUEBERTRAGUNG_INN':
            return () => import('@/components/core/forms/antraege/depotUebertragung/EbaseEdelmetalUbertragungIntern.vue')
          case 'SHORTTEXT_EDITOR_COMPONENT':
            return () => import('@/components/antrag/AntragDeckblattFehlendeAngabenComponent.vue')
          case 'COLOR_PICKER_FULL':
            return () => import('@/components/core/forms/InputColorPickerFull.vue')
          case 'COLOR_PICKER':
            return () => import('@/components/core/forms/InputColorPicker.vue')
          case 'INDEX_KOMBINATION':
            return () => import('@/components/core/forms/antraege/FormInputIndexKombination.vue')
          case 'DOK_NODE_PERMISSION':
            return () => import('@/components/core/forms/antraege/dokNodePermission/FormInputDokNodePermission.vue')
          case 'FONDSAMPEL_SLIDER':
            return () => import('@/components/core/forms/antraege/FormInputFondsampelSlider.vue')
          case 'KONTOFUEHRUNGSGEBUEHREN':
            return () => import('@/components/core/forms/antraege/gebuehren/FormInputKontofuehrungsgebuehren.vue')
          case 'BUTTON_APPLY_TO_STRUCTURE':
            return () => import('@/components/core/forms/antraege/FormInputButtonApplyToStructure.vue')
          case 'CONFIG_BUTTON':
            return () => import('@/components/core/forms/antraege/ConfigsButton.vue')
          case 'DOWNLOAD_LINK':
            return () => import('@/components/core/download/DownloadLink.vue')
          case 'SERVICEGEBUEHREN':
            return () => import('@/components/core/forms/antraege/gebuehren/FormInputServicegebuehren.vue')
          case 'TABLE_BIPRO_SIBLING_GESELLSCHAFTEN':
            return () => import('@/components/externeZugangConfig/BiproSiblingGesellschaftenTable.vue')
          case 'SELECT_FILE_BUTTON':
            return () => import('@/components/core/forms/antraege/FormSelectFileButton.vue')
          case 'CONFIRM_BUTTON':
            return () => import('@/components/core/forms/antraege/FormConfirmButton.vue')
          case 'NACHHALTIGKEIT':
            return () => import('@/components/anlegerprofil/Nachhaltigkeit.vue')
          case 'CAPITALBANK_WIRTSCHAFT_EIGENTUEMER':
            return () => import('@/components/core/forms/antraege/CapitalbankDokWirtschaftEigentuemer.vue')
          case 'WERTPAPIER_AUSWAHL_MORGENFUND_SPARPLAN':
            return () => import('@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlMorgenfundSparplan.vue')
          default:
            return () => import('./ComponentDefault.vue')
        }
      }
      return null;
    },
    showComponentAsDisabled() { // mask disabled Component without disable property
      return this.dynamicBindings?.props?.isDiabled != true  && this.component?.disabled?.IMMER;
    },
  },
}
</script>

<style scoped>
.top-component {
  position:relative;
}
.disable-component-arrea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  background-color: var(--color-background);
  cursor: not-allowed;
  padding: 0;
}
</style>