var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.pageTitle,
          actions: _vm.headerActions
        },
        on: {
          "action-SAVE-CUSTOMER": _vm.createCustomer,
          "action-SAVE-PERSON": _vm.createPerson,
          "action-RESET": _vm.reset
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: {
            tid: _vm._generateTidFromString(
              "box__container_Vorderseite_Ruckseite"
            )
          }
        },
        [
          _c(
            "div",
            { staticClass: "scans-container" },
            [
              _c(
                "DragnDropArea",
                {
                  staticClass: "scan-container",
                  attrs: {
                    hoverText: "Vorderseite hier ablegen",
                    disabled: !!_vm.frontImage
                  },
                  on: {
                    files: function($event) {
                      return _vm.uploadImage($event, true)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v("Vorderseite "),
                      _vm.frontImage
                        ? _c("PhXCircle", {
                            attrs: { size: 20 },
                            on: {
                              click: function($event) {
                                return _vm.clearImage(true)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.frontImage
                    ? [
                        _c("img", {
                          ref: "frontImage",
                          staticClass: "scans-image",
                          attrs: { src: _vm.frontImage }
                        }),
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.openCroppingDialog(true)
                              }
                            }
                          },
                          [
                            _c("PhCrop", { attrs: { size: 24 } }),
                            _vm._v(" Zuschneiden ")
                          ],
                          1
                        ),
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.rotateRight(true)
                              }
                            }
                          },
                          [
                            _c("PhArrowArcRight", { attrs: { size: 24 } }),
                            _vm._v(" Drehen ")
                          ],
                          1
                        )
                      ]
                    : _c(
                        "div",
                        { staticClass: "scans-image" },
                        [
                          _c(
                            "BaseFileSelect",
                            {
                              staticClass: "scans-image-button",
                              attrs: { isSecondary: "" },
                              on: {
                                files: function($event) {
                                  return _vm.uploadImage($event, true)
                                }
                              }
                            },
                            [_vm._v(" hochladen ")]
                          ),
                          _vm.cameraAvailable
                            ? _c(
                                "FotoButton",
                                {
                                  staticClass: "scans-image-button",
                                  attrs: {
                                    buttonClass: "foto-button",
                                    isSecondary: "",
                                    edgeCorrection: "",
                                    highQuality: "",
                                    camera: "back"
                                  },
                                  on: {
                                    file: function($event) {
                                      return _vm.uploadImage([$event], true)
                                    }
                                  }
                                },
                                [_vm._v(" Fotografieren ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ],
                2
              ),
              _c(
                "DragnDropArea",
                {
                  staticClass: "scan-container",
                  attrs: {
                    hoverText: "Rückseite hier ablegen",
                    disabled: !!_vm.backImage
                  },
                  on: {
                    files: function($event) {
                      return _vm.uploadImage($event, false)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v("Rückseite "),
                      _vm.backImage
                        ? _c("PhXCircle", {
                            attrs: { size: 20 },
                            on: {
                              click: function($event) {
                                return _vm.clearImage(false)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.backImage
                    ? [
                        _c("img", {
                          ref: "backImage",
                          staticClass: "scans-image",
                          attrs: { src: _vm.backImage }
                        }),
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.openCroppingDialog(false)
                              }
                            }
                          },
                          [
                            _c("PhCrop", { attrs: { size: 24 } }),
                            _vm._v(" Zuschneiden ")
                          ],
                          1
                        ),
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.rotateRight(false)
                              }
                            }
                          },
                          [
                            _c("PhArrowArcRight", { attrs: { size: 24 } }),
                            _vm._v(" Drehen ")
                          ],
                          1
                        )
                      ]
                    : _c(
                        "div",
                        { staticClass: "scans-image" },
                        [
                          _c(
                            "BaseFileSelect",
                            {
                              staticClass: "scans-image-button",
                              attrs: { isSecondary: "" },
                              on: {
                                files: function($event) {
                                  return _vm.uploadImage($event, false)
                                }
                              }
                            },
                            [_vm._v(" hochladen ")]
                          ),
                          _vm.cameraAvailable
                            ? _c(
                                "FotoButton",
                                {
                                  staticClass: "scans-image-button",
                                  attrs: {
                                    buttonClass: "foto-button",
                                    isSecondary: "",
                                    edgeCorrection: "",
                                    highQuality: "",
                                    camera: "back"
                                  },
                                  on: {
                                    file: function($event) {
                                      return _vm.uploadImage([$event], false)
                                    }
                                  }
                                },
                                [_vm._v(" Fotografieren ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ],
                2
              )
            ],
            1
          ),
          _vm.frontImage || _vm.backImage
            ? _c("div", { staticClass: "box__title" }, [
                _vm._v("Bitte überprüfen Sie die ausgelesenen Daten!")
              ])
            : _vm._e()
        ]
      ),
      _vm.imageToCrop
        ? _c("ImageCroppingDialog", {
            attrs: {
              src: _vm.imageToCrop,
              detectedCorners: _vm.detectedCorners
            },
            on: {
              cancel: function($event) {
                _vm.croppingFront = null
              },
              file: function($event) {
                return _vm.uploadImage([$event], _vm.croppingFront)
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3 col-md-3 col-sm-12 col-12" }, [
          _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "box__container_VerticalStepper"
                )
              }
            },
            [
              _c("VerticalStepper", {
                attrs: {
                  stepType: "tab",
                  selectedStepKey: _vm.currentStep,
                  stepperService: _vm.stepper
                },
                on: {
                  setStep: function($event) {
                    _vm.currentStep = $event
                  }
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-lg-9 col-md-9 col-sm-12 col-12" }, [
          _vm.currentStep == "Stammdaten"
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("FormHeader", { attrs: { label: _vm.currentStep } }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Anrede",
                      values: _vm.genderList.length
                        ? _vm.genderList
                        : _vm.customerDataConfig.genders,
                      validateUntouched: "",
                      firstEmpty: "",
                      tabindex: "1"
                    },
                    model: {
                      value: _vm.personalDataAddress.title,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "title", $$v)
                      },
                      expression: "personalDataAddress.title"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Name" },
                    model: {
                      value: _vm.personalDataAddress.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "lastName", $$v)
                      },
                      expression: "personalDataAddress.lastName"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Vorname" },
                    model: {
                      value: _vm.personalDataAddress.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "firstName", $$v)
                      },
                      expression: "personalDataAddress.firstName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentStep == "Adresse"
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("FormHeader", { attrs: { label: _vm.currentStep } }),
                  _c("InputField", {
                    attrs: { label: "Straße" },
                    model: {
                      value: _vm.personalDataAddress.street,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "street", $$v)
                      },
                      expression: "personalDataAddress.street"
                    }
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-3 col-md-3 col-sm-12 col-12" },
                      [
                        _c("InputField", {
                          attrs: { label: "Postleitzahl" },
                          model: {
                            value: _vm.personalDataAddress.zip,
                            callback: function($$v) {
                              _vm.$set(_vm.personalDataAddress, "zip", $$v)
                            },
                            expression: "personalDataAddress.zip"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-9 col-md-9 col-sm-12 col-12" },
                      [
                        _c("InputField", {
                          attrs: { label: "Ort" },
                          model: {
                            value: _vm.personalDataAddress.city,
                            callback: function($$v) {
                              _vm.$set(_vm.personalDataAddress, "city", $$v)
                            },
                            expression: "personalDataAddress.city"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("ComboBox", {
                    attrs: {
                      label: "Land",
                      values: _vm.customerDataConfig.countries
                    },
                    model: {
                      value: _vm.personalDataAddress.country,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "country", $$v)
                      },
                      expression: "personalDataAddress.country"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentStep == "Geburtstag und Staatsangehörigkeit"
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("FormHeader", { attrs: { label: _vm.currentStep } }),
                  _c("DatePickerField", {
                    attrs: { label: "Geburtsdatum" },
                    model: {
                      value: _vm.personalDataBirth.dayOfBirth,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataBirth, "dayOfBirth", $$v)
                      },
                      expression: "personalDataBirth.dayOfBirth"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Geburtsort" },
                    model: {
                      value: _vm.personalDataBirth.placeOfBirth,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataBirth, "placeOfBirth", $$v)
                      },
                      expression: "personalDataBirth.placeOfBirth"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Staatsangehörigkeit",
                      values: _vm.customerDataConfig.nationalities
                    },
                    model: {
                      value: _vm.personalDataBirth.nationality,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataBirth, "nationality", $$v)
                      },
                      expression: "personalDataBirth.nationality"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Geburtsname" },
                    model: {
                      value: _vm.personalDataBirth.nameAtBirth,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataBirth, "nameAtBirth", $$v)
                      },
                      expression: "personalDataBirth.nameAtBirth"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentStep == "Legitimationsurkunde"
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("FormHeader", { attrs: { label: _vm.currentStep } }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Art der Urkunde",
                      values: _vm.customerDataConfig.legitimationTypes
                    },
                    model: {
                      value: _vm.legitimation.legitimType,
                      callback: function($$v) {
                        _vm.$set(_vm.legitimation, "legitimType", $$v)
                      },
                      expression: "legitimation.legitimType"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Ausweisnummer" },
                    model: {
                      value: _vm.legitimation.legitimNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.legitimation, "legitimNumber", $$v)
                      },
                      expression: "legitimation.legitimNumber"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Ausstellungsbehörde" },
                    model: {
                      value: _vm.legitimation.authority,
                      callback: function($$v) {
                        _vm.$set(_vm.legitimation, "authority", $$v)
                      },
                      expression: "legitimation.authority"
                    }
                  }),
                  _c("DatePickerField", {
                    attrs: { label: "Ausstellungsdatum", isValueAsString: "" },
                    model: {
                      value: _vm.legitimation.dateOfIssue,
                      callback: function($$v) {
                        _vm.$set(_vm.legitimation, "dateOfIssue", $$v)
                      },
                      expression: "legitimation.dateOfIssue"
                    }
                  }),
                  _c("DatePickerField", {
                    attrs: { label: "Gültig bis", isValueAsString: "" },
                    model: {
                      value: _vm.legitimation.validTo,
                      callback: function($$v) {
                        _vm.$set(_vm.legitimation, "validTo", $$v)
                      },
                      expression: "legitimation.validTo"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }