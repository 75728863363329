<template>
  <div 
    class="sortable-list__container clearfix" 
    :class="{ 
      'sortable-disabled': disabled, 
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import BrowserSupport from '@/browser-support';

const SCROLL_OFFSET_DEFAULT = 32;
const SORTABLE_LIST_CONTAINER_SELECTOR = '.sortable-list__container';
const DATA_SORTABLE_CONTAINER_SELECTOR = '[data-sortable-container]';
const DATA_SORTABLE_ITEM_ATTR = 'data-sortable-item';
const DATA_SORTABLE_ITEM_SELECTOR = '[data-sortable-item]';
const DATA_SORTABLE_ITEM_HANDLER_SELECTOR = '[data-sortable-item-handler]';
const StyleClasses = Object.freeze({
  DRAGGING: 'sortable-dragging',
  CURRENT: 'sortable-list--dragging',
  ITEM_DRAGGED: 'sortable-list__item-dragged',
  ITEM_PLACEHOLDER: 'sortable-list__item-placeholder',
});
const Direction = Object.freeze({
  UP: 'up', 
  DOWN: 'down', 
  RIGHT: 'right', 
  LEFT: 'left', 
});
const Positioned = Object.freeze({
  HORIZONTALLY: 'horizontally', 
  VERTICALLY: 'vertically', 
});

const PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
const NO_PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;

const COLGROUP_SELECTOR = 'colgroup';
const RIGHT_MOUSE_KEY = 3;

function eventCoord(event, coord) {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
}

const TABLE_TAGS  = ['TBODY'];
function isTableTag(el) {
  if(!el) return false;
  const tagName = el.tagName.toUpperCase();
  return TABLE_TAGS.includes(tagName);
}

function sideSize(el, direction) {
  if (!el) return 0;
  const style = window.getComputedStyle(el)

  switch(direction) {
    case 'top':
      return parseFloat(style.paddingTop) 
        + parseFloat(style.borderTopWidth);
    case 'left':
      return parseFloat(style.paddingLeft) 
        + parseFloat(style.borderLeftWidth);
    case 'bottom':
      return parseFloat(style.paddingBottom) 
        + parseFloat(style.borderBottomWidth);
    case 'right':
      return parseFloat(style.paddingRight) 
        + parseFloat(style.borderRightWidth);
    default:
      return 0;
  }
}

/**
 * SortableList
 * 
 * Example:
 * <SortableList :items="items">
 *   <ul data-sortable-container>
 *     <li data-sortable-item>
 *       <span data-sortable-item-handler>Handler</span>...
 *     </li>
 *     <li data-sortable-item>
 *       <span data-sortable-item-handler>Handler</span>...
 *     </li>
 *   </ul>
 * </SortableList>
 */
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    scrollOffsetTop: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dragStart', 'dragEnd', 'orderChanged'],
  data() {
    return {
    };
  },
  watch: {
    items: {
      handler() {
        this.$nextTick(this.prepareComponent);
      },
      immediate: true,
    }
  },
  methods: {
    prepareComponent() {
      requestAnimationFrame(() => {
        const { $el:rootEl } = this;
        [ ...rootEl.querySelectorAll(DATA_SORTABLE_ITEM_HANDLER_SELECTOR) ]
          .filter(el => el.closest(SORTABLE_LIST_CONTAINER_SELECTOR) === rootEl)
          .forEach(el => {
            el.removeEventListener('mousedown', this.dragStart, NO_PASSIVE_OPTIONS);
            el.removeEventListener('touchstart', this.dragStart, NO_PASSIVE_OPTIONS);

            el.addEventListener('mousedown', this.dragStart, NO_PASSIVE_OPTIONS);
            el.addEventListener('touchstart', this.dragStart, NO_PASSIVE_OPTIONS);
          });
      });
    },
    dragStart(event) {
      const vm = this;

      const { 
        scrollOffsetTop = 0, 
        disabled, 
      } = vm;
      if(disabled || !event.cancelable || event.which === RIGHT_MOUSE_KEY) return;

      event.preventDefault();
      event.stopImmediatePropagation();

      vm.$emit('dragStart');

      let isDragging = true;

      const { $el:rootEl } = vm;
      rootEl.classList.add(StyleClasses.CURRENT);

      const handlerEl = event.target.closest(DATA_SORTABLE_ITEM_HANDLER_SELECTOR) || event.target;
      const containerEl = handlerEl.closest(DATA_SORTABLE_CONTAINER_SELECTOR);
      if (!containerEl || containerEl.closest(SORTABLE_LIST_CONTAINER_SELECTOR) !== rootEl) {
        console.error(`a "${DATA_SORTABLE_CONTAINER_SELECTOR}" must be defined. Please check SortableList:`, rootEl);
        return;
      }

      const scrollContainerEl = findScrollContainerEl(containerEl);
      let autoScrollInterval = null;
      let lastAutoScrollDirection = null;

      let currentX = eventCoord(event, 'clientX');
      let currentY = eventCoord(event, 'clientY');
      let lastX = currentX;
      let lastY = currentY;
      let horizontalDir = '';
      let verticalDir = '';

      let draggedEl = handlerEl.closest(DATA_SORTABLE_ITEM_SELECTOR) || handlerEl;

      const startIndex = findChildren().indexOf(draggedEl);
      let endIndex = -1;

      // set boundings
      const scrollBounding = scrollContainerEl ? scrollContainerEl.getBoundingClientRect() : null;
      const draggedBounding = draggedEl.getBoundingClientRect();
      let containerBounding = {};
      let placeholderElBounding = {};
      let childrenBounding = {};

      // add placeholder element
      const placeholderEl = createPlaceholderEl(draggedEl, draggedBounding.width, draggedBounding.height);
      draggedEl.parentNode.insertBefore(placeholderEl, draggedEl);

      // set offset
      let offsetX = currentX - draggedBounding.x;
      let offsetY = currentY - draggedBounding.y;

      const originalDraggedEl = draggedEl;

      if(isTableTag(draggedEl)) { // when it is a table element copy the table and colgroup structured to keep the sizes and style
        const containerElCloned = containerEl.cloneNode();
        const colgroupElCloned = containerEl.querySelector(COLGROUP_SELECTOR)?.cloneNode(true);

        containerElCloned.style.width = `${draggedBounding.width}px;`;
        if(colgroupElCloned) {
          containerElCloned.appendChild(colgroupElCloned);
        }
        containerElCloned.appendChild(draggedEl);

        draggedEl = containerElCloned; // replace the dragged element to the parent element
      }

      // set style
      draggedEl.classList.add(StyleClasses.ITEM_DRAGGED);
      draggedEl.style.cssText = `
        width: ${draggedBounding.width}px;
        height: ${draggedBounding.height}px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        transform: translate(${currentX - offsetX}px, ${currentY - offsetY}px);
      `;

      document.body.appendChild(draggedEl);

      // Prepare items
      findChildren().forEach((el, index) => {
        const { height } = el.getBoundingClientRect();
        el.style.height = `${height}px`;
        el.dataset.index = index;
      });

      // add global classes
      document.querySelector('html').classList.add(StyleClasses.DRAGGING);

      function findScrollContainerEl(el) {
        if (!el) return null;

        const scrollContainerEl = (node) => {
          if(node === null) return null;
          return node.scrollHeight > node.clientHeight ? node : scrollContainerEl(node.parentNode);
        };

        return scrollContainerEl(el);
      }

      function findChildren() {
        return [ ...containerEl.querySelectorAll(DATA_SORTABLE_ITEM_SELECTOR) ]
          .filter(el => el.closest(DATA_SORTABLE_CONTAINER_SELECTOR) === containerEl);
      }

      function createPlaceholderEl(el, width, height) {
        if(!el) return ;

        const cloneNode = (element) => {
          if(isTableTag(element)) { // when it is a table tag the placeholder element is replaced by a div due to table elements are problematic to style
            const createdEl = document.createElement('div');
            createdEl.setAttribute(DATA_SORTABLE_ITEM_ATTR, ''); // add required attribute
            return createdEl;
          } else {
            return element.cloneNode();
          }
        };

        const placeholderEl = cloneNode(el);
        placeholderEl.classList.add(StyleClasses.ITEM_PLACEHOLDER);
        placeholderEl.style.cssText = `
          width: ${width}px;
          height: ${height}px;
        `;

        return placeholderEl;
      }

      function preventSelectionStart(event) {
        event.preventDefault();
        event.stopPropagation();
      }

      function drag(event) {
        if(!draggedEl || !event.cancelable) return;

        event.preventDefault();
        event.stopImmediatePropagation();

        requestAnimationFrame(() => {
          if (!isDragging) return;

          currentX = eventCoord(event, 'clientX');
          currentY = eventCoord(event, 'clientY');
          const xDiff = currentX - lastX;
          const yDiff = currentY - lastY;
          horizontalDir = (xDiff < 0 ? Direction.LEFT : (xDiff > 0 ? Direction.RIGHT : ''));
          verticalDir = (yDiff < 0 ? Direction.UP : (yDiff > 0 ? Direction.DOWN : ''));
          lastX = currentX;
          lastY = currentY;
  
          // set style
          draggedEl.style.transform = `translate(${currentX - offsetX}px, ${currentY - offsetY}px)`;
  
          // set boundings
          placeholderElBounding = placeholderEl.getBoundingClientRect(); // TODO reuse this value and only set when it is empty (should be empty when starts and when it reorders)
          containerBounding = containerEl.getBoundingClientRect(); // TODO reuse this value and only set when it is empty (should be empty when starts and when it scrolls)

          requestAnimationFrame(() => {
            if(reorder()) {
              placeholderElBounding = placeholderEl.getBoundingClientRect(); // TODO reuse this value and only set when it is empty (should be empty when starts and when it reorders)
              containerBounding = containerEl.getBoundingClientRect(); // TODO reuse this value and only set when it is empty (should be empty when starts and when it scrolls)
            }
            requestAnimationFrame(() => autoVerticalScroll());
          })
        });
      }

      function autoVerticalScroll() {
        if(!scrollContainerEl) return;

        const autoScroll = (direction) => {
          if(lastAutoScrollDirection === direction) return;

          lastAutoScrollDirection = direction;

          clearInterval(autoScrollInterval);
          autoScrollInterval = setInterval(() => {
            if (!isDragging) {
              clearInterval(autoScrollInterval);
              return;
            }

            const top = scrollContainerEl.scrollTop + (SCROLL_OFFSET_DEFAULT * (direction ? 0.5 : -0.5));
            scrollContainerEl.scrollTo({
              top,
              left: 0,
              behavior: 'smooth',
            });
          }, 50);
        }

        const scrollElY = scrollBounding.y >= 0 ? scrollBounding.y : 0;
        const scrollElHeight = scrollContainerEl.clientHeight;

        const scrollBottomLimitSize = scrollElY + scrollElHeight;
        const scrollTopLimitSize = scrollElY + scrollOffsetTop;
        const placeholderY = placeholderElBounding?.y;

        if((currentY + SCROLL_OFFSET_DEFAULT) > scrollBottomLimitSize || (placeholderY + SCROLL_OFFSET_DEFAULT) > scrollBottomLimitSize) {
          autoScroll(true);
        } else if((currentY - SCROLL_OFFSET_DEFAULT) < scrollTopLimitSize || (placeholderY - SCROLL_OFFSET_DEFAULT) < scrollTopLimitSize) {
          autoScroll(false);
        } else {
          clearInterval(autoScrollInterval);
          lastAutoScrollDirection = null;
        }
      }

      function reorder() {
        const currentHoverEl = findCurrentHoverEl();
        if(!currentHoverEl) return;

        const currentItemHoverEl = currentHoverEl.closest(DATA_SORTABLE_ITEM_SELECTOR) || currentHoverEl;
        if(!currentItemHoverEl || !currentItemHoverEl.hasAttribute(DATA_SORTABLE_ITEM_ATTR) || currentItemHoverEl.parentNode !== placeholderEl.parentNode) return;

        // set boundings
        const currentItemHoverElBounding = currentItemHoverEl.getBoundingClientRect();
        const positioned = placeholderElBounding?.y === currentItemHoverElBounding.y ? Positioned.HORIZONTALLY : Positioned.VERTICALLY;

        const reorderEl = (() => {
          if((horizontalDir === Direction.LEFT && positioned === Positioned.HORIZONTALLY 
              || verticalDir === Direction.UP && positioned === Positioned.VERTICALLY) 
            && currentItemHoverEl.previousElementSibling !== placeholderEl) {
            return currentItemHoverEl;
          } else if((horizontalDir === Direction.RIGHT && positioned === Positioned.HORIZONTALLY 
              || verticalDir === Direction.DOWN && positioned === Positioned.VERTICALLY) 
            && currentItemHoverEl.nextElementSibling !== placeholderEl) {
            if(currentItemHoverEl.nextElementSibling) {
              return currentItemHoverEl.nextElementSibling;
            } else {
              return placeholderEl;
            }
          }
          return null;
        })();

        if (reorderEl) {
          trackChildrenBounding();
          if (reorderEl != placeholderEl) {
            placeholderEl.parentNode.insertBefore(placeholderEl, reorderEl);
          } else {
            placeholderEl.parentNode.appendChild(placeholderEl);
          }
          reorderAnimation();
          return true;
        }

        return false;
      }

      function findCurrentHoverEl() {
        const sizePadding = 1;
        const parentMinX = containerBounding.left + (sideSize(containerEl, 'left') + sizePadding);
        const parentMaxX = containerBounding.right - (sideSize(containerEl, 'right') + sizePadding);
        const parentMinY = containerBounding.top + (sideSize(containerEl, 'top') + sizePadding);
        const parentMaxY = containerBounding.bottom - (sideSize(containerEl, 'bottom') + sizePadding);

        const checkedX = checkBoundaryValue(currentX, parentMinX, parentMaxX);
        const checkedY = checkBoundaryValue(currentY, parentMinY, parentMaxY);

        draggedEl.style.display = 'none';
        const currentHoverEl = document.elementFromPoint(checkedX, checkedY);
        draggedEl.style.display = '';

        return currentHoverEl;
      }

      function checkBoundaryValue(value, min, max) {
        if(value >= min && value <= max) {
          return value;
        } else if(min > value) {
          return min;
        } else if(max < value) {
          return max;
        }
        return value;
      }

      function trackChildrenBounding() {
        childrenBounding = {};
        findChildren().forEach(el => {
          childrenBounding[el.dataset.index] = el.getBoundingClientRect();
        });
      }

      function reorderAnimation() {
        findChildren().forEach(el => {
          const lastBounding = childrenBounding[el.dataset.index];
          if (!lastBounding) return;

          const bounding = el.getBoundingClientRect();
          const diffX = lastBounding.x - bounding.x;
          const diffY = lastBounding.y - bounding.y;

          el.style.pointerEvents = 'none';
          el.style.transform = `translate(${diffX}px, ${diffY}px)`;
          requestAnimationFrame(() => {
            el.style.transition = `transform .3s ease`;
            el.style.transform = `translate(0px, 0px)`;
          });

          el.addEventListener('transitionend', onReorderAnimationEnd);
        });
      }

      function onReorderAnimationEnd() {
        findChildren().forEach(el => {
          el.style.pointerEvents = null;
          el.style.transform = null;
          el.style.transition = null;
          el.removeEventListener('transitionend', onReorderAnimationEnd);
        });
      }

      function dragEnd() {
        if(!draggedEl) return;

        vm.$emit('dragEnd');

        isDragging = false;

        const { $el:rootEl } = vm;
        rootEl.classList.remove(StyleClasses.CURRENT);

        draggedEl.classList.remove(StyleClasses.ITEM_DRAGGED);
        draggedEl.style = {};

        endIndex = findChildren().indexOf(placeholderEl);

        placeholderEl.parentNode.replaceChild(originalDraggedEl, placeholderEl);
        if(originalDraggedEl !== draggedEl) {
          document.body.removeChild(draggedEl);
        }

        // Remove style
        findChildren().forEach(el => {
          el.style = {};
          delete el.dataset.index;
          el.removeEventListener('transitionend', onReorderAnimationEnd);
        });

        emitOrderChangedIfNeeded();

        // remove global classes
        document.querySelector('html').classList.remove(StyleClasses.DRAGGING);

        // remove events
        document.removeEventListener('mousemove', drag, NO_PASSIVE_OPTIONS);
        document.removeEventListener('mouseup', dragEnd, PASSIVE_OPTIONS);
        document.removeEventListener('touchmove', drag, NO_PASSIVE_OPTIONS);
        document.removeEventListener('touchend', dragEnd, PASSIVE_OPTIONS);
        document.removeEventListener('selectstart', preventSelectionStart, NO_PASSIVE_OPTIONS);
      }

      function emitOrderChangedIfNeeded() {
        if(startIndex >= 0 && endIndex >= 0 & startIndex !== endIndex) {
          const { items } = vm;
          const itemsCopy = [ ...items ];
          const removeItems = itemsCopy.splice(startIndex, 1);
          itemsCopy.splice(endIndex, 0, removeItems[0]);

          vm.$emit('orderChanged', itemsCopy);
        }
      }

      // add events
      document.addEventListener('mousemove', drag, NO_PASSIVE_OPTIONS);
      document.addEventListener('mouseup', dragEnd, PASSIVE_OPTIONS);
      document.addEventListener('touchmove', drag, NO_PASSIVE_OPTIONS);
      document.addEventListener('touchend', dragEnd, PASSIVE_OPTIONS);
      document.addEventListener('selectstart', preventSelectionStart, NO_PASSIVE_OPTIONS);
    },
  },
}
</script>

<style>
.sortable-dragging,
.sortable-dragging * {
  cursor: grabbing;
  user-select: none !important;
  touch-action: none !important;
}

.sortable-list__container,
.sortable-list__container [data-sortable-container] {
  position: relative;
}
.sortable-list__container .sortable-list__item-placeholder {
  background-color: rgba(204,204,204,0.4);
  border-radius: 8px;
  pointer-events: none;
}

.sortable-list--dragging [data-sortable-item] [data-sortable-item] {
  pointer-events: none !important;
}
.sortable-list__container [data-sortable-item-handler] {
  cursor: grab;
}
.sortable-list__container [data-sortable-item-handler]:disabled,
.sortable-list__container [data-sortable-item-handler][disabled] {
  cursor: not-allowed;
}
.sortable-list__item-dragged {
  background-color: var(--color-box);
  border-radius: 8px;
  list-style: none !important;
  margin: 0 !important;
  opacity: 0.8;
  overflow: hidden;
  pointer-events: none !important;
  touch-action: inherit !important;
}
.sortable-list__item-dragged [data-sortable-item-handler] {
  cursor: grabbing;
}
.sortable-list__container.sortable-disabled [data-sortable-item-handler] {
  display: none !important;
}
</style>
