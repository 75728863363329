var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.title,
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.optionsMenu,
          showBackButton: false
        }
      }),
      _vm.eingegangeneDokumente
        ? _c(
            "div",
            { staticClass: "cards__container layout__negative-margin--8" },
            _vm._l(_vm.eingegangeneDokumente.eingegangeneDokumente, function(
              eingegangenesDokument
            ) {
              return _c(
                "div",
                { staticClass: "box__container cards__item" },
                [
                  _c("div", { staticClass: "cards__item-header" }, [
                    _c(
                      "div",
                      { staticClass: "box__title breakable-words" },
                      [
                        _c("DownloadLink", {
                          attrs: {
                            downloadServicePath: "/dokumentEingangData",
                            title:
                              eingegangenesDokument.subject +
                              " (" +
                              eingegangenesDokument.id +
                              ")",
                            queryParams: { id: eingegangenesDokument.id }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", [
                      _c("dl", { staticClass: "key-value--item" }, [
                        _c("dt", { staticClass: "value--item-label" }, [
                          _vm._v(" Status: ")
                        ]),
                        _c("dd", { staticClass: "value--item-value" }, [
                          _vm._v(_vm._s(eingegangenesDokument.status.label))
                        ])
                      ]),
                      _c("dl", { staticClass: "key-value--item" }, [
                        _c("dt", { staticClass: "value--item-label" }, [
                          _vm._v(" Zuletzt geändert am: ")
                        ]),
                        _c("dd", { staticClass: "value--item-value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                eingegangenesDokument.dateUpdated
                                  ? eingegangenesDokument.dateUpdated
                                  : eingegangenesDokument.d
                              )
                          )
                        ])
                      ]),
                      _c("dl", { staticClass: "key-value--item" }, [
                        _c("dt", { staticClass: "value--item-label" }, [
                          _vm._v(" Art des Dokuments: ")
                        ]),
                        _c("dd", { staticClass: "value--item-value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                eingegangenesDokument.documentType
                                  ? eingegangenesDokument.documentType
                                  : "Unbekannt"
                              )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", [
                    _vm.signaturesForAuthor &&
                    _vm.signaturesForAuthor[eingegangenesDokument.id]
                      ? _c("img", {
                          staticClass: "signature-img",
                          attrs: {
                            src:
                              _vm.signaturesForAuthor[eingegangenesDokument.id]
                                .img
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("InputTextArea", {
                    attrs: { label: "Bemerkung" },
                    on: {
                      change: function($event) {
                        return _vm.POST_BEMERKUNG(eingegangenesDokument)
                      }
                    },
                    model: {
                      value: eingegangenesDokument.bemerkung,
                      callback: function($$v) {
                        _vm.$set(eingegangenesDokument, "bemerkung", $$v)
                      },
                      expression: "eingegangenesDokument.bemerkung"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "action-button-list mt-3" },
                    [
                      _c("BaseButton", {
                        attrs: { label: "Ablehnen", isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.POST_STATUS(
                              eingegangenesDokument,
                              "ABGELEHNT"
                            )
                          }
                        }
                      }),
                      _c("BaseButton", {
                        attrs: { label: "Annehmen" },
                        on: {
                          click: function($event) {
                            return _vm.POST_STATUS(
                              eingegangenesDokument,
                              "GESENDET"
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _c("div", [_c("NoData")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }