<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="SMS" />

    <BaseFilter
      v-if="defaultOptions"
      title="SMS Filter"
      filterId="PostfachSMSFilter"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        rowId="rowid"
        :exportConfig="{roottext: 'Postfach'}"
        :mobileConfig="{title: 'name', headers: ['subject', 'dateTime', 'contact']}"
        @clickRow="clickBetreff"
        @click-subject="clickBetreff"
        @click-name="openCustomerNewTab($event.nummer)"
      />
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, PillColumn} from "@/components/table2/table_util.js";

import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';

export default {
  mixins: [postfachTableMixin],
  components: {
    Table,
  },
  data() {
    return {
      category: 'sms',
      mailboxLabel: 'SMS',
      filterConfig: {
        placeholderForDefSearchInput: 'Mobilnummer',
        defaultSearchInputKeys: ['subject'],
        hideFirstColumn: true,
      },
    }
  },
  computed: {
    headers() {
        return {
            lockedLeft: [
              PillColumn("status", "Status"),
              DateTimeColumn("dateTime", "Datum", undefined, 1),
              TextColumn("subject", "Betreff").makeLink(),
            ],
            center: [
              TextColumn("name", "Name").makeConditionalLink('hasNummer').makeAlwaysVisible(),
              TextColumn("contact", "Kontakt"),
            ],
            lockedRight: []
        };
    },
    rows() {
        return this.records.map(record => {
            return {
                ...record,
                rowid: (record.commId + this.category + record.date),
                status: this.getStatusMetadata(record),
                subject: record.subject || "[kein Betreff]",
                dateTime: record.date,
                hasNummer: this.canOpenCustomer(record),
            };
        });
    },
    metadata() {
      const result = [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
              emptyDenied: true,
            },
            {
              type: 'text',
              label: 'Name',
              key: 'name',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
              emptyDenied: true,
            },
            {
                type: 'datepicker',
                label: 'Datum',
                key: 'datum',
                emptyDenied: true,
            },
          ],
        },
      ];
      return result;
    },
  },
  methods: {
    getStatusMetadata(whatRow) {
      if (whatRow?.status?.warning) {
        return {type: "warning", label: whatRow?.status?.warning};
      } else if (whatRow?.status?.success) {
        return {type: "success", label: whatRow?.status?.success};
      } else if (whatRow?.status?.danger) {
        return {type: "danger", label: whatRow?.status?.danger};
      }
    },
    clickBetreff(row) {
      if (row.commId) {
        this.$router.push(`/communication/postfach/${this.routeStep}/${this.category}/${row.commId}`);
      }
    },
  },
}
</script>