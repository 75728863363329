<template>

  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Vermittlernummern Bearbeiten" />

    <div class="box__container" tid="6a87f26c-3eb8-4874-8e2b-e3742e57f9a1" v-if="formData">
       <ComboBox
        label="Gesellschaft"
        v-model="formData.gesellschaftId"
        :values="gesellschaften"
        :disabled="gesellschaftDisabled">
      </ComboBox>     

       <InputField
        label="Fremdnummer"
        v-model="formData.itemNummer">
      </InputField>    

       <InputField
        label="Hauptnummer"
        v-model="formData.hauptNummer">
      </InputField>      

      <DatePickerField label="mitgeteilt am" isValueAsString v-model="formData.mitgeteiltAm"/>   
      
      <InputToggleSwitch v-model="formData.versteckeNummer" label="Versteckte Nummer" inLineLabel suppressValidationMessage/>  

      <InputField
        label="Bemerkung"
        v-model="formData.bemerkung">
      </InputField>    

      <p>Bitte verifizieren Sie, dass die Fremdnummer auch wirklich zu diesem Vermittler gehört, da eine falsche Fremdnummer zu Sicherheitsproblemen und Fehlverhalten bei der Abrechnung führen kann.</p> 

      <InputToggleSwitch v-model="formData.itemBevorzugt" label="Bevorzugte Fremdnummer" inLineLabel suppressValidationMessage/>          

    </div>    


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERMITTLERNUMMERN_TYPES from "../../store/vermittlernummern/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    DatePickerField,
    InputToggleSwitch,
  },
  data: function () {
      return {
        resultCount: null,
        unsignedAttachments: [],
        signedAttachments: [],
        currentFilename: null,
        downloadErrorMessage: null,
        filtered: false,
        loading: false,
        formData : null,
        createVermittlernummer: !!this.$route.params.createVermittlerNummer ?? false
      }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedFremdnr: VERMITTLERNUMMERN_TYPES.GETTERS.SELECTED_FREMDNR,
      versicherungenData: VERMITTLERNUMMERN_TYPES.GETTERS.VERSICHERUNGEN_DATA,
    }),
    gesellschaften() {
      return this.versicherungenData?.gesellschaften
    },
    gesellschaftDisabled(){
      return !this.createVermittlernummer
    }
  },
  mounted() {
    this.formData = this.selectedFremdnr;
    this.formData.versteckeNummer = !this.formData.sichtbar;
  },
  methods: {
    executeAction(actionData) {
      console.log(actionData.key);
      switch (actionData.key) {
        case 'EDIT_ITEM':
          console.log(actionData);
          break;
        case 'REMOVE_ITEM':
          console.log(actionData);
          this.$store.dispatch(VERMITTLERNUMMERN_TYPES.ACTIONS.REMOVE_ROW, {itemNummer: actionData?.row?.itemNummer}).then((response) => {
            console.log(response);
            this.retrieveData();  
          }); 
          break;     
        case 'SEND_EMAIL':
          console.log(actionData);
          break;                  
      }
    },   
    async saveBeforeLeave() {
      if(!this.formData.gesellschaftId || !this.formData.itemNummer){
        return
      }
      this.formData.sichtbar = !this.formData?.versteckeNummer;
      this.formData.istVersicherung = false;

      await this.$store.dispatch(
              VERMITTLERNUMMERN_TYPES.ACTIONS.PERSIST_ROW, this.formData
            );  
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlernummern Bearbeiten', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    await this.saveBeforeLeave();

    next();
  },


    
}
</script>