var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Konfiguration",
          actions: _vm.headerActions
        },
        on: { "action-DISCARD": _vm.discard }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "configuration",
          stepperMediator: _vm.tabsStepperMediator,
          selectedStepKey: _vm.currentStep,
          selectedSubstepKey: _vm.currentSubstep,
          hasFertig: false,
          showSaveSpinner: _vm.isLoading
        },
        on: {
          "set-step": function($event) {
            return _vm.setStep($event)
          },
          "set-substep": function($event) {
            return _vm.setSubstep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }