var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-BERATUNG_2018": function($event) {
            return _vm.createBeratungsmappe("BERATUNG_2018")
          }
        }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "d6d497d4-7dca-4658-8914-928b81f4c0d8",
          title: "Beratungsmappen filtern",
          metadata: _vm.filterMetadata
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "5d455d91-7c38-429c-8f35-64a522145a20" }
        },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "036f03f6-8bcc-4cf5-8b33-f48039d63a32",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "click-art": function($event) {
                    return _vm.editBeratungsmappe($event.data)
                  },
                  "action-copy": function($event) {
                    return _vm.copyBeratungsmappe($event.data)
                  },
                  "action-delete": function($event) {
                    return _vm.confirmDeleteBeratungsmappe($event.data)
                  }
                }
              })
            : _vm.loadingBeratungsmappeList
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm.rows.length === 0
            ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteBeratungsmappeConfirmation",
          attrs: {
            modalTitle: "Beratungsmappe löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteBeratungsmappe()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deleteBeratungsmappeMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }