<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="News" 
      :actions="headerActions" 
      @action-MARK-AS-READ="markSelectedAsRead()"
      @action-MARK-AS-UNREAD="markSelectedAsUnread()"
      @action-MARK-AS-MAJOR="changeMajorForSelected(true)"
      @action-MARK-AS-NOT-MAJOR="changeMajorForSelected(false)"
      @action-ADD="addNews"
    />

    <MscNewsList v-model="markedNews" enableFilter/>
  </div>
</template>

<script>
import MscNewsList from '@/components/mscnews/MscNewsList.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import MSC_NEWS_TYPES from '@/store/mscnews/types'
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

export default {
    components: {
        MscNewsList,
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    data() {
        return {
            markedNews: []
        }
    },
    computed: {
      ...mapGetters({
        isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        mscAllNews: MSC_NEWS_TYPES.GETTERS.MSC_NEWS,
      }),
      headerActions() {
        const { markedNews, } = this;
        return [
          PageHeaderSimpleAction('MARK-AS-READ', 'Markierte News als gelesen bestätigen')
            .withDisabled(() => !markedNews?.length),
          PageHeaderSimpleAction('MARK-AS-UNREAD', 'als ungelesen zu markieren')
            .withDisabled(() => !markedNews?.length),
          PageHeaderSimpleAction('MARK-AS-MAJOR', 'als wichtig zu markieren')
            .withDisabled(() => !markedNews?.length),
          PageHeaderSimpleAction('MARK-AS-NOT-MAJOR', 'als unwichtig zu markieren')
            .withDisabled(() => !markedNews?.length),
          ...(this.isOriginalUserIntern ? [PageHeaderSimpleAction('ADD', 'Neue News')] : []),
        ];
      },
    },
    methods: {
        async markSelectedAsRead() {
          await this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.MARK_AS_READ, this.markedNews.map(news => news.id));
          this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS_COUNT);
          this.markedNews = [];  
        },
        async markSelectedAsUnread() {
          await this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.MARK_AS_UNREAD, this.markedNews.map(news => news.id));
          this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS_COUNT);
          this.markedNews = [];  
        },
        async changeMajorForSelected(set) {
          const ids = this.markedNews.filter(news => news.isWichtig === !set).map(news => news.id);
          await this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.TOGGLE_NEWS_MAJOR, {set, ids});
          const mscAllNews = [...this.mscAllNews];
          ids.forEach(id => {
            const idx = mscAllNews.findIndex(m => m.id == id);
            if (idx != -1) {
              mscAllNews[idx].wichtig = set;
            }
          })
          this.$store.commit(MSC_NEWS_TYPES.MUTATIONS.GET_MSC_NEWS_SUCCESS, {mscNews: mscAllNews});
          this.markedNews = [];  
        },
        addNews() {
          this.$router.push('/intern/news-msc-edit')
        },
    },
}
</script>
