var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Mustervorlagen"
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c(
            "div",
            [
              _c(
                "BaseButton",
                {
                  staticClass: "mb-3",
                  on: {
                    click: function($event) {
                      return _vm.produceForm()
                    }
                  }
                },
                [_vm._v("PDF")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Vermittler Daten")
          ]),
          _c("ComboBox", {
            attrs: {
              label: "Vermittlerauswahl",
              values: _vm.vermittlerauswahlValues,
              isComponentHalfSize: true,
              sortComboboxValues: false
            },
            model: {
              value: _vm.form.vermittlerauswahl,
              callback: function($$v) {
                _vm.$set(_vm.form, "vermittlerauswahl", $$v)
              },
              expression: "form.vermittlerauswahl"
            }
          }),
          _c("InputField", {
            attrs: { label: "Firma Name", isComponentHalfSize: true },
            model: {
              value: _vm.form.firmaName,
              callback: function($$v) {
                _vm.$set(_vm.form, "firmaName", $$v)
              },
              expression: "form.firmaName"
            }
          }),
          _c("InputField", {
            attrs: { label: "Straße, Hausnummer", isComponentHalfSize: true },
            model: {
              value: _vm.form.strassehnr,
              callback: function($$v) {
                _vm.$set(_vm.form, "strassehnr", $$v)
              },
              expression: "form.strassehnr"
            }
          }),
          _c("InputField", {
            attrs: { label: "PLZ,Ort", isComponentHalfSize: true },
            model: {
              value: _vm.form.plzOrt,
              callback: function($$v) {
                _vm.$set(_vm.form, "plzOrt", $$v)
              },
              expression: "form.plzOrt"
            }
          }),
          !_vm.isErklaerung && !_vm.isBestUebertragungVers
            ? _c("div", { staticClass: "box__title mt=5" }, [
                _vm._v("Daten des abgebenden Pool")
              ])
            : _vm._e(),
          !_vm.isErklaerung && !_vm.isBestUebertragungVers
            ? _c("InputField", {
                attrs: { label: "Pool Name/Firma", isComponentHalfSize: true },
                model: {
                  value: _vm.form.poolName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "poolName", $$v)
                  },
                  expression: "form.poolName"
                }
              })
            : _vm._e(),
          !_vm.isErklaerung && !_vm.isBestUebertragungVers
            ? _c("InputField", {
                attrs: {
                  label: "Straße, Hausnummer",
                  isComponentHalfSize: true
                },
                model: {
                  value: _vm.form.poolStrasseHnr,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "poolStrasseHnr", $$v)
                  },
                  expression: "form.poolStrasseHnr"
                }
              })
            : _vm._e(),
          !_vm.isErklaerung && !_vm.isBestUebertragungVers
            ? _c("InputField", {
                attrs: { label: "PLZ,Ort", isComponentHalfSize: true },
                model: {
                  value: _vm.form.poolPlzOrt,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "poolPlzOrt", $$v)
                  },
                  expression: "form.poolPlzOrt"
                }
              })
            : _vm._e(),
          !_vm.isErklaerung && !_vm.isBestUebertragungVers
            ? _c("InputField", {
                attrs: { label: "Vermittlernummer", isComponentHalfSize: true },
                model: {
                  value: _vm.form.vermittlernummer,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "vermittlernummer", $$v)
                  },
                  expression: "form.vermittlernummer"
                }
              })
            : _vm._e(),
          _vm.isErklaerung
            ? _c("DatePickerField", {
                attrs: {
                  label: "Kunden informiert am",
                  isComponentHalfSize: true
                },
                model: {
                  value: _vm.form.informiertAm,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "informiertAm", $$v)
                  },
                  expression: "form.informiertAm"
                }
              })
            : _vm._e(),
          _vm.isBestUebertragungVers
            ? _c("InputField", {
                attrs: {
                  label: "Vermittlernummer Gesellschaft",
                  isComponentHalfSize: true
                },
                model: {
                  value: _vm.form.vermittlernrGesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "vermittlernrGesellschaft", $$v)
                  },
                  expression: "form.vermittlernrGesellschaft"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    "Die Bestandsfreigabe muss bei der Fondskonzept eingereicht werden."
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }