<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Personen suchen" 
      :actions="headerActions"
      @action-ADD="addSelected()"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Personen suchen"
      filterId="aacc74a0-6db1-4e9b-b2b1-3e762867f5f9"
      :metadata="searchMetadata"
      hasSmartSearch
      :configFilter="configFilter"
      :showSaveButton="false"
      :defaultOptions="defaultOptions"
      @onFilter="search($event)" 
    />

    <div v-if="searched" class="box__container">
        <div class="box__title">Personen</div>
        <GhostLoading v-if="searching" type="table" />
        <Table v-else-if="rows.length"
            tableId="22f54d86-454a-4d12-bb48-327d98527541"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            rowId="personId"
            v-model="selectedPeople"
        />
        <div v-else>Keine Daten</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PageHeaderLoadingAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { searchMenu, prepareFilters } from './personSearchConstants.js';


export default {
  data() {
    return {
        searched: false,
        configFilter: {
            placeholderForDefSearchInput: 'Name, Vorname, Kundennummer',
            checkDefaultSearchInput: false,
        },
        defaultOptions: null,
        selectedPeople: [],
        loading: false,
        searching: false,
        headers: {
            lockedLeft: [
                TextColumn("lastName", "Name"),
                TextColumn("firstName", "Vorname"),
            ],
            center: [
                TextColumn("kundennr", "Kundennummer"),
                TextColumn("relation", "Beziehung"),
                TextColumn("address", "Anschrift"),
                TextColumn("phone", "Telefon"),
                TextColumn("email", "E-Mail"),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
      peopleFound: CUSTOMERDATA_TYPES.GETTERS.PEOPLE_FOUND,
    }),
    rows() {
      return this.peopleFound || [];
    },
    relationParam() {
      return this.$route.query?.relation || '';
    },
    isModusAuswahlArbeitgeber() {
      return this.relationParam === 'ARBEITGEBER';
    },
    searchMetadata() {
      return searchMenu(this.isModusAuswahlArbeitgeber);
    },
    hasPeopleSelected() {
      return this.selectedPeople?.length > 0;
    },
    headerActions() {
      const { loading, hasPeopleSelected, } = this;
      return [
        PageHeaderLoadingAction('ADD', 'Hinzufügen', () => loading).withDisabled(() => !hasPeopleSelected),
      ];
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    async search(filters) {
        this.searching = true;
        this.selectedPeople = [];
        const params = prepareFilters(filters);
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_PEOPLE, params);
        this.searching = false;
        this.searched = true;
    },
    async addSelected() {
      const payload = {
        personRelationships: this.selectedPeople.filter(p => this.relationParam || !p.relation).map(p => ({ 
          personId: p.personId,
          relation: this.relationParam,
        })),
      };

      this.loading = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.ADD_PEOPLE_RELATIONSHIP, payload);
      this.loading = false;

      this.selectedPeople = [];
      this.navigateToBackUrl();
    },
    navigateToBackUrl() {
      const backUrl = this.$route.query?.backUrl;
      if(!backUrl) return ;

      this.$router.push({
        path: backUrl,
        query: {
          backAction: true,
        },
      })
    },
    setFilterDefaultOptions() {
      this.defaultOptions = {
        ...(this.isModusAuswahlArbeitgeber ? { onlyEmployers: true, } : {}),
      };
    },
  },
  mounted() {
    this.setFilterDefaultOptions();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseFilter,
    Table,
    GhostLoading,
  },
}
</script>
