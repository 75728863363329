var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Bearbeitung einer Honorar/Serviceleistungsabrechnung",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addNewRow()
          }
        }
      }),
      _vm.editorLayoutData && _vm.editorLayoutData.tabs
        ? _c(
            "div",
            { attrs: { tid: "531796f4-fa9a-44e7-af76-9f83f9f77479" } },
            [
              _c("StepperForm", {
                ref: "stepperForm",
                attrs: {
                  stepType: "tab",
                  stepperName: "honorar-bearbeiten",
                  stepperMediator: _vm.tabsStepperMediator,
                  hasFertig: false,
                  selectedStepKey: _vm.selectedStepKey,
                  selectedSubstepKey: _vm.selectedSubstepKey
                },
                on: { "set-step": _vm.setStepByKey },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "contentTemplate",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _vm.tableRows.length
                                ? _c("Table", {
                                    staticClass: "table-container",
                                    attrs: {
                                      tableId:
                                        "a5a1d818-1d43-4399-9a07-034c0ecfc25c",
                                      rowId: "rowIndex",
                                      headers: _vm.tableHeaders,
                                      rows: _vm.tableRows,
                                      rowsPerPage: 20,
                                      count: _vm.resultCount
                                    },
                                    on: {
                                      action: function($event) {
                                        return _vm.executeAction($event)
                                      },
                                      "click-bezeichnung": function($event) {
                                        return _vm.bearbeiten($event)
                                      },
                                      "click-datum": function($event) {
                                        return _vm.bearbeiten($event)
                                      }
                                    }
                                  })
                                : _vm.loading
                                ? _c("GhostLoading", {
                                    attrs: { type: "table" }
                                  })
                                : _c("NoData")
                            ],
                            1
                          ),
                          _c("div", { staticClass: "box__container" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _vm._v(" Bruttobetrag ")
                              ]),
                              _c("div", { staticClass: "col text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.subTotals.summeBrutto
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  4150483084
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Bearbeiten",
            showCloseButton: true,
            labelButtonConfirm: "Ok"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.persistRow()
            }
          }
        },
        _vm._l(Object.keys(_vm.form), function(input) {
          return _c(
            "div",
            { key: input },
            [
              _vm.form[input].columnType == "DateColumn"
                ? _c("DatePickerField", {
                    attrs: { label: _vm.form[input].label },
                    model: {
                      value: _vm.form[input].value,
                      callback: function($$v) {
                        _vm.$set(_vm.form[input], "value", $$v)
                      },
                      expression: "form[input].value"
                    }
                  })
                : _c("InputField", {
                    attrs: {
                      label: _vm.form[input].label,
                      type: _vm.form[input].type
                    },
                    model: {
                      value: _vm.form[input].value,
                      callback: function($$v) {
                        _vm.$set(_vm.form[input], "value", $$v)
                      },
                      expression: "form[input].value"
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: { modalTitle: "Tarif löschen", labelButtonConfirm: "Löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm._v(
            " Soll der Eintrage für " +
              _vm._s(_vm.editRow.bezeichnung) +
              " wirklich gelöscht werden? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }