<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="E-Mail Zugang" 
            :actions="headerActions"
            @action-NEW-SERVER="addNewServer()"
        />

        <div class="row my-0" v-if="stepper">
            <div class="d-lg-none col-12">
                <div class="antrag-step__container box__container">
                <Stepper2
                    stepType="step"
                    :visible="true"
                    :selectedStepKey="stepKey"
                    :stepperService="stepper"
                    @setStep="setStepByKey"
                    ref="pieStepper"
                ></Stepper2> 
                </div>
            </div>
        </div>

        <ContentWithStepper>
            <template #stepper v-if="stepper">
                <div class="box__container">
                <VerticalStepper
                    stepType="step"
                    :visible="true"
                    :selectedStepKey="stepKey"
                    :stepperService="stepper"
                    @setStep="setStepByKey"
                    ref="verticalStepper"
                    class="responsive-stepper"
                ></VerticalStepper>
                </div>
            </template>
            <template #content>
                <router-view />
                <div class="row box__container">
                    <BaseButton v-if="stepKey !== 'eingang'" @click="prevStep()" isPrimary >Zurück</BaseButton>
                    <BaseButton v-if="stepKey !== 'ausgang'" isPrimary @click="nextStep()">Weiter</BaseButton>
            </div>
            </template>
        </ContentWithStepper>

    </div>
</template>

<script>
import E_MAIL_ACCESS_TYPES from "@/store/eMailAccess/types";

import Stepper2 from '@/components/stepper/Stepper2.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import BaseButton from '@/components/core/BaseButton.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    components: {
        Stepper2,
        VerticalStepper,
        OptionMenu,
        PageHeaderTitleNavigation,
        ContentWithStepper,
        BaseButton,
    },
    data() {
        return {
            stepper:  new StepperMediator([
                { stepKey: 'eingang', label: 'Eingang', success: true },
                { stepKey: 'ausgang', label: 'Ausgang', success: true },
            ]),
        }
    },
    computed: {
        headerActions() {
            return [
                PageHeaderSimpleAction('NEW-SERVER', 'Neuer Server').withVisible(() => this.isEingang),
            ];
        },
        isEingang() {
            return this.stepKey === 'eingang';
        },
        stepKey() {
            return this.$route.path.substring(this.$route.path.lastIndexOf('/')+1);
        }
    },
    methods: {
        setStepByKey(stepKey = 'eingang') {
            if (this.stepKey !== stepKey) {
                this.$router.push(`/intern/email-access/${stepKey}`);
            }
        },
        nextStep() {
            this.setStepByKey('ausgang');
        },
        prevStep() {
            this.setStepByKey('eingang');
        },
        addNewServer() {
            this.$store.commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, {});
            this.$addBreadcrumb({
                label: this.breadcrumbLabel, 
                fullPath: this.$route.fullPath,
                breadcrumb: 'E-Mail Eingangsserver bearbeiten',
            });
            this.$router.push({path: `/intern/email-access/email-server-edit/0`});
        },
    }
}
</script>

<style>

</style>