<template>
  <div>
    <InputToggleSwitch v-model="form.isEingang" label="Eingang" inLineLabel :disabled="this.form.isMoveInbox || this.form.isMoveOutbox || loading" @input="save()" />
    <InputToggleSwitch v-model="form.isAusgang" label="Ausgang" inLineLabel :disabled="this.form.isMoveInbox || this.form.isMoveOutbox || loading" @input="save()" />
    <InputToggleSwitch v-model="form.isMSCSent" label="Gesendete E-Mails werden hier gespeichert" :disabled="loading" inLineLabel @input="save()" />
    <InputToggleSwitch v-model="form.isMoveInbox" label="Importierte eingegangene E-Mails werden hierhin verschoben" :disabled="loading" inLineLabel @input="save()" />
    <InputToggleSwitch v-model="form.isMoveOutbox" label="Importierte gesendete E-Mails werden hierhin verschoben" :disabled="loading" inLineLabel @input="save()" />
    <BaseButton isLink @click="standardSettings()">Standardeinstellung</BaseButton>
  </div>
</template>

<script>
import E_MAIL_ACCESS_TYPES from '@/store/eMailAccess/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  components: {
    InputToggleSwitch,
    BaseButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    data(value) {
      if (value && Object.keys(value).length) {
        this.form = Object.assign(this.form, {
          isEingang: value.isEingang,
          isAusgang: value.isAusgang,
          isMSCSent: value.isMSCSent,
          isMoveInbox: value.isMoveInbox,
          isMoveOutbox: value.isMoveOutbox,
          serverId: value.serverId,
        });
        this.form.folderName = value.fullFolderName || value.folderName;
      }
    }
  },
  data() {
    return {
      form: {
        isEingang: false,
        isAusgang: false,
        isMSCSent: false,
        isMoveInbox: false,
        isMoveOutbox: false,
        folderName: '',
        serverId: '',
      },
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      await this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SAVE_FOLDER_STRUCTURE, this.form);
      this.loading = false;
    },
    standardSettings() {
      this.$confirmModal({
        title: 'Standardeinstellung',
        message: `Wollen Sie Standardeinstellung für <b>${this.data.folderName}</b> setzen?`,
        labelButtonConfirm: 'Ja',
        labelButtonCancel: 'Nein',
        showCancelButton: true,
      })
      .then(() => this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.RESET_FOLDER_STRUCTURE, this.form));
    },
  },
  beforeDestroy() {
    this.save();
  },
}
</script>
