var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: _vm.title,
              actions: _vm.headerActions
            },
            on: {
              "action-HONORAR": function($event) {
                return _vm.speichernHonorargebuehren()
              },
              "action-ZURUCKSETZEN": function($event) {
                return _vm.zuruecksetzen()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "action-PDF",
                fn: function() {
                  return [
                    _c("DownloadLink", {
                      attrs: {
                        asButton: "",
                        title: "PDF",
                        downloadServicePath: "/getStoppuhrFormular",
                        queryParams: {
                          id: _vm.stoppuhrForm.id
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          },
          _vm.$listeners
        )
      ),
      _vm.stoppuhr && _vm.stoppuhr.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("StoppuhrWatch"),
              _c("InputField", {
                attrs: { label: "Name", disabled: "", isComponentHalfSize: "" },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Kategorie",
                  isComponentHalfSize: "",
                  values: _vm.stoppuhr
                },
                on: {
                  input: function($event) {
                    return _vm.processFormChange("kategorie", $event)
                  }
                },
                model: {
                  value: _vm.form.kategorie,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "kategorie", $$v)
                  },
                  expression: "form.kategorie"
                }
              }),
              _c("InputField", {
                attrs: { label: "Thema", isComponentHalfSize: "" },
                on: {
                  change: function($event) {
                    return _vm.processFormChange("betreff", $event)
                  }
                },
                model: {
                  value: _vm.form.betreff,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "betreff", $$v)
                  },
                  expression: "form.betreff"
                }
              }),
              _c("InputTextArea", {
                attrs: { label: "Beschreibung", isComponentHalfSize: "" },
                on: {
                  change: function($event) {
                    return _vm.processFormChange("beschreibung", $event)
                  }
                },
                model: {
                  value: _vm.form.beschreibung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "beschreibung", $$v)
                  },
                  expression: "form.beschreibung"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }