var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _vm.askOptions
        ? _c("ExterneZugangAskOptions", {
            attrs: {
              askOptions: _vm.askOptions,
              confirmButtonLabel: _vm.actionLabel,
              data: _vm.data,
              title: _vm.externeZugang.companyLabel
            },
            on: {
              close: function($event) {
                _vm.resetExterneZugang()
                _vm.navigateToVersicherungstoolsHome()
              },
              onConfirmButton: function($event) {
                _vm.askOptionsConfirmed($event)
                _vm.navigateToVersicherungstoolsHome()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }