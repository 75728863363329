
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES, ROLES, BROKER_STRUCTURE_ROLES, } from '@/router/roles'

import limitRoutes from './limit'

const CUSTOMER_COMPONENT_HOME = () => import(/* webpackChunkName: "customer-search" */ '@/components/customerSearch/CustomerSearch.vue')
const BROKER_SEARCH_COMPONENT = () => import(/* webpackChunkName: "broker-search" */ '@/components/brokerSearch/BrokerSearch.vue')

export default [
  {
    path: 'customer-search',
    name: 'customer-search',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
        ]
      },
    },
    component: () => import(/* webpackChunkName: "customer-search" */ '@/components/customerSearch/CustomerSearch.vue'),
  },
  {
    path: 'maklersuche',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN, EMPLOYEE_ROLES.ZUGRIFF_UNTERMAKLER, BROKER_LEVEL_ROLES.ZUGRIFF_UNTERMAKLER],
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_UNTERMAKLER, BROKER_LEVEL_ROLES.ZUGRIFF_UNTERMAKLER],
        ]
      }
    },
    component: () => import(/* webpackChunkName: "new-customer" */ '@/components/brokerSearch/BrokerSearch.vue'),
  },
  {
    path: 'kunde-anlegen',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
        ],
        denied: [ROLES.IS_CONFIGURATION_READ_ONLY]
      }
    },
    components: {
      default: CUSTOMER_COMPONENT_HOME,
      modal: () => import(/* webpackChunkName: "new-customer" */ '@/views/customer/NewCustomer.vue'),
    },
  },
  {
    path: 'kundegruppe-anlegen',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
        ],
        denied: [ROLES.IS_CONFIGURATION_READ_ONLY]
      }
    },
    components: {
      default: CUSTOMER_COMPONENT_HOME,
      modal: () => import(/* webpackChunkName: "new-super-customer" */ '@/views/customer/NewSuperCustomer.vue'),
    },
  },
  {
    path: 'kunde-anlegen-personalausweis',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
        ],
        denied: [ROLES.IS_CONFIGURATION_READ_ONLY]
      }
    },
    components: {
      modal: () => import(/* webpackChunkName: "new-customer" */ '@/views/customer/NewCustomerIdScan.vue'),
    },
  },
  {
    path: 'customer-search/:value',
    component: () => import(/* webpackChunkName: "new-customer" */ '@/components/customerSearch/CustomerSearch.vue'),
  },
  {
    path: 'immobilien',
    component: () => import(/* webpackChunkName: "sprod-immobilien-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
      },
    },
    children: [
      {
        path: '',
        redirect: 'search',
      },
      {
        path: 'search',
        component: () => import(/* webpackChunkName: "sprod-immobilien-search" */ '@/components/otherProducts/OtherProductsSearch.vue'),
        props: {
          type: 'Immobilie',
        },
      },
    ],
  },  
  {
    path: 'transaktionen',
    component: () => import(/* webpackChunkName: "Transactions" */ '@/components/transactions/Transactions.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS], 
          [VIEW_ROLES.VIEW_INTERN],
        ],
      } 
    },
  },  
  {
    path: 'verbindlichkeiten',
    component: () => import(/* webpackChunkName: "base_verbindlichkeiten_view" */ '@/views/verbindlichkeiten/BaseVerbindlichkeitenView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN], 
          [VIEW_ROLES.VIEW_INTERN],
        ],
      }
    },
    children: [
      {
        path: '',
        redirect: 'credit',
      },
      {
        path: 'credit',
        component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "credit-search" */ '@/views/home/CreditSearch.vue'),
          },
        ],
      },
    ]
  },  
  {
    path: 'weitere-verbindlichkeiten',
    component: () => import(/* webpackChunkName: "sprod-weitere-verbindlichkeiten-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN],
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
    children: [
      {
        path: '',
        redirect: 'search',
      },
      {
        path: 'search',
        component: () => import(/* webpackChunkName: "sprod-weitere-verbindlichkeiten-search" */ '@/components/otherProducts/OtherProductsSearch.vue'),
        props: {
          type: 'WeitereVerbindlichkeiten',
        },
      },
    ],
  },  
  {
    path: 'weitere-vermoegen',
    component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    children: [
      {
        path: '',
        redirect: 'search',
      },
      {
        path: 'search',
        component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-search" */ '@/components/otherProducts/OtherProductsSearch.vue'),
        props: {
          type: 'WeitereVermoegen',
        },
      },
    ],
  },  
  {
    path: 'maklersuche/:value',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      }
    },
    component: () => import(/* webpackChunkName: "maklersuche" */ '@/components/brokerSearch/BrokerSearch.vue'),
  },
  {
    path: 'stornos',
    name: 'stornos',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.STORNOS_WARNUNGEN_BESTAETIGEN]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "stornos-warnungen" */ '@/views/intern/StornoWarnungen.vue')
  },
  {
    path: 'eingegangene-transaktionen',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_TRANSAKTIONEN_VERLAUF, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
      }
    },
    component: () => import(/* webpackChunkName: "eingegangene-transaktionen" */ '@/views/intern/EingegangeneTransaktionen.vue'),
  },
  {
    path: 'customer-category',
    component: () => import(/* webpackChunkName: "customer-category" */ '@/views/intern/CustomerCategory.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
      },
    },
  },
  {
    path: 'vermittlerwechsel',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS], [VIEW_ROLES.VIEW_INTERN]],
      }
    },
    component: () => import(/* webpackChunkName: "vermittlerwechsel" */ '@/views/BaseRouterView.vue'),
    children:[
      {
        path: '',
        component: () => import(/* webpackChunkName: "vermittlerwechsel" */ '@/views/intern/VermittlerwechselStatus.vue'),
      },
      {
        path: 'eintragBearbeiten',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_INTERN], [VIEW_ROLES.VIEW_BROKER_AS_INTERN]],
          }
        },
        component: () => import(/* webpackChunkName: "eintragBearbeiten" */ '@/views/intern/VermittlerwechselStatusBearbeiten.vue'),
        children:[
        ]
      },
    ]
  },

  {
    path: 'merge-people',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "mergePeople" */ '@/views/intern/MergePeople.vue'),
  },

  {
    path: 'limit',
    meta: {
      roles: {
        allowed:[[VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]]
      },
    },
    component: () => import(/* webpackChunkName: "LimitOverview" */ '@/views/BaseRouterView.vue'),
    children: [...limitRoutes]
  },
  {
    path: 'kunden-loeschen',
    component: () => import(/* webpackChunkName: "kunden-loeschen" */ '@/components/customerSearch/KundenLoeschenConfirmation.vue'),
  },  
  {
    path: 'vermittler-anlegen',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.UNTERVERMITTLER_NEU, ROLES.DARF_VERMITTLER_ANLEGEN],
        ],
      }
    },
    components: {
      default: BROKER_SEARCH_COMPONENT,
      modal: () => import(/* webpackChunkName: "makler-anlegen" */ '@/views/broker/NewBroker.vue'),
    },
  },
]