<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" 
      title="Alle Quartalsberichte" 
      :actions="headerActions" 
      @action-SEND="sendEmail()"
      @action-DOWNLOAD="downloadFile()"
      @action-BERECHNEN="berechnen()"
    />

    <template v-if="!loading && nodeId">
      <DocumentsFilter />
    </template>

    <div class="box__container" tid="0b82f193-2e28-423b-998a-571f835e5ced">
        <DocumentsTable v-if="!loading && nodeId" :title="TABLE_TITLE" tableId="96d2ede3-62a3-45ed-87a8-9935115be741" 
          :nodeId="nodeId" showNoDataMessageWithIcon :rowsPerPage="rowsPerPage" :showActionColumn="false" />
        <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
    </div>

    <BaseModal
      ref="ergebnisModal"
      labelButtonCancel="Abbrechen"
      :showCancelButton="false"
      :showConfirmButton="true"
      labelButtonConfirm="Ok"
      size="lg">
      <template v-slot:modalTitle>
        Ergebnis
      </template>
      <div class="mb-1" v-if="ergebnis" v-html="sanitize(ergebnis)"></div>
    </BaseModal>  
    
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import QUARTALSBERICHTE_TYPES from "@/store/quartalsberichte/types";
import DocumentsFilter from '@/components/documents/DocumentsFilter.vue';
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import { ROLES, VIEW_ROLES } from '@/router/roles';
import BaseButton from '@/components/core/BaseButton.vue';
import {sanitize} from '@/helpers/string-helper.js';
import BaseModal from '@/components/core/BaseModal.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, PageHeaderLoadingAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam } from '@/helpers/utils-helper';

const TABLE_TITLE = 'Quartalsberichte';

export default {
  mixins: [InteractiveHelpCommonsMixin],
	props: {},
	data() {
		return { 
      TABLE_TITLE,
      loading: false,
      nodeId: null,
      rowsPerPage: 20,
      ergebnis: null,
      preparingEmail: false,
    }
  },
  computed: {
    ...mapGetters({
        isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER_AS_INTERN, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, VIEW_ROLES.VIEW_INTERN]);
    },
    headerActions() {
      return [
        PageHeaderLoadingAction('SEND', 'Quartalsbericht zusenden', () => this.preparingEmail),
        PageHeaderSimpleAction('DOWNLOAD', 'aktueller Quartalsbericht'),
        PageHeaderSimpleAction('BERECHNEN', 'Aktuellen Quartalsbericht neu berechnen')
          .withVisible(() => !this.loading && this.nodeId && this.isIntern),
      ];
    },

  },
  methods: {
    async retrieveQuartalsberichteNode() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          QUARTALSBERICHTE_TYPES.ACTIONS.RETRIEVE_QUARTALSBERICHTE_NODE,
          {
            root: "quartal_berichte",
          }
        ).then( (response) => {
          this.nodeId = response?.nodes[0].nodeId;
        });
      } finally {
        this.loading = false;
      }
    },
    async sendEmail() {
      try {
        this.preparingEmail = true;
        await this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL, {}).then(response => {
          const customResponse = {
            ...response.data,
            attachments: response?.data?.attachments,
          }
  
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL, customResponse);
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {type: 'mailcomposer-single', action: 'clear'});
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response?.data?.attachments);
          this.$router.push({path: '/communication/mailcomposer-single', query: {replaceContent: true}});
        })
      } finally {
        this.preparingEmail = false;
      }
    },
    downloadFile() {
      const token = this.$store.getters[CORE_TYPES.GETTERS.GET_TOKEN];
      const apiAddress = this.$store.getters[CORE_TYPES.GETTERS.API_ADDRESS];
      const params = makeQueryParam({ token });
      const url = `${apiAddress}/download_service/getAktuellerQuartalsbericht/aktuellerQuartalsbericht.pdf?${params}`;
      window.open(url);
    },
    berechnen() {
      try {
        this.loading = true;
        this.$store.dispatch(
          QUARTALSBERICHTE_TYPES.ACTIONS.QUARTALSBERICHTE_BERECHNEN, {}
        ).then( (response) => {
          this.retrieveQuartalsberichteNode();
          this.ergebnis = response?.value;
          this.$refs.ergebnisModal.open();
        });
      } finally {
        this.loading = false;
      }
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },

  },
	mounted() {
   this.retrieveQuartalsberichteNode();

	},
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Quartalberichte',
      to,
      from,
      breadcrumb: 'Quartalberichte',
    });

    next()
  },
	components: {
    GhostLoading,
    NoData,
    DocumentsFilter,
    DocumentsTable,
    BaseButton,
    BaseModal,
    OptionMenu,
    PageHeaderTitleNavigation,
	}
}
</script>

<style scoped>

</style>
