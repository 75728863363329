var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Einstellungen",
          subtitle: _vm.configGroupLabel,
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          }
        }
      }),
      _vm.configHints.length || _vm.configErrors.length
        ? _c("HinweiseUndFehler", {
            attrs: { hints: _vm.configHints, errors: _vm.configErrors }
          })
        : _vm._e(),
      !_vm.isLoading && !_vm.hasComponentsDef
        ? _c("div", { staticClass: "box__container" }, [_c("NoData")], 1)
        : _vm.isSmallOrMediumScreen
        ? [
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("GhostLoading", {
                      attrs: {
                        type: "block",
                        config: { block: { height: "250px" } }
                      }
                    })
                  ],
                  1
                )
              : _c("ConfigStepper3", {
                  attrs: {
                    configStep: _vm.componentsDef,
                    configData: _vm.data,
                    selectedStep: _vm.selectedStep,
                    selectedSubstep: _vm.selectedSubstep
                  },
                  on: { setStep: _vm.onSetStep, setSubstep: _vm.onSetSubstep }
                })
          ]
        : _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u([
              {
                key: "stepper",
                fn: function() {
                  return [
                    _c("ConfigVerticalStep", {
                      attrs: {
                        configStep: _vm.componentsDef,
                        configData: _vm.data,
                        selectedStep: _vm.selectedStep,
                        selectedSubstep: _vm.selectedSubstep,
                        disabled: _vm.isLoading
                      },
                      on: {
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        },
                        setStep: _vm.onSetStep,
                        setSubstep: _vm.onSetSubstep
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "antrag-components__container" },
                      [
                        _vm.isLoading
                          ? _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("GhostLoading", {
                                  attrs: {
                                    type: "block",
                                    config: { block: { height: "120px" } }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._l(_vm.componentsDefByDivider, function(
                              componentList,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "box__container substep-container",
                                  attrs: {
                                    "data-position":
                                      _vm.componentsDefByStep.stepKey +
                                      "-" +
                                      index
                                  }
                                },
                                [
                                  _vm._l(componentList, function(
                                    component,
                                    index
                                  ) {
                                    return [
                                      !_vm.isComponentHidden(component)
                                        ? _c(
                                            "div",
                                            {
                                              key: index + component.id,
                                              staticClass: "antrag-component",
                                              class: {
                                                indented:
                                                  component.config &&
                                                  component.config.indented
                                              }
                                            },
                                            [
                                              _c("AntragComponent", {
                                                attrs: {
                                                  component: component,
                                                  data: _vm.allData,
                                                  disabled:
                                                    component.config &&
                                                    component.config.disabled,
                                                  comboboxSelection: _vm.getComboboxSelection(
                                                    component
                                                  ),
                                                  isComponentHalfSizeEnabled: ""
                                                },
                                                on: {
                                                  dataChange: function($event) {
                                                    return _vm.onDataChange(
                                                      $event
                                                    )
                                                  },
                                                  executeConfigButton: function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "executeConfigButton",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            })
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c("BaseModal", {
        ref: "editModal",
        attrs: {
          modalTitle: _vm.editModalTitle,
          size: "lg",
          showDefaultButtons: false,
          actions: _vm.modalActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          opened: function($event) {
            return _vm.scrollToSelectedSubstep(false)
          },
          close: _vm.saveChanges
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "antrag-components__container" },
                  [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: "120px" } }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._l(_vm.componentsDefByDivider, function(
                          componentList,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "box__container substep-container",
                              attrs: {
                                "data-position":
                                  _vm.componentsDefByStep.stepKey + "-" + index
                              }
                            },
                            [
                              _vm._l(componentList, function(component, index) {
                                return [
                                  !_vm.isComponentHidden(component)
                                    ? _c(
                                        "div",
                                        {
                                          key: index + component.id,
                                          staticClass: "antrag-component",
                                          class: {
                                            indented:
                                              component.config &&
                                              component.config.indented
                                          }
                                        },
                                        [
                                          _c("AntragComponent", {
                                            attrs: {
                                              component: component,
                                              data: _vm.allData,
                                              disabled:
                                                component.config &&
                                                component.config.disabled,
                                              comboboxSelection: _vm.getComboboxSelection(
                                                component
                                              ),
                                              isComponentHalfSizeEnabled: ""
                                            },
                                            on: {
                                              dataChange: function($event) {
                                                return _vm.onDataChange($event)
                                              },
                                              executeConfigButton: function(
                                                $event
                                              ) {
                                                return _vm.$emit(
                                                  "executeConfigButton",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        })
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }