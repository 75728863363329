<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Marketing" 
      :actions="headerActions" 
      @action-ADD="addMarketing"
    />

    <MscMarketingList/>
  </div>
</template>

<script>
import MscMarketingList from '@/components/mscmarketing/MscMarketingList.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
      MscMarketingList,
      OptionMenu,
      PageHeaderTitleNavigation,
  },
  data() {
    return {
    }
  },
  computed: {
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neue Marketing Kampagne'),
      ];
    },
  },
  methods: {
    addMarketing() {
      this.$router.push('/intern/marketing-campaign/marketing-campaign-edit/new')
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück",
      to,
      from,
    });
    next();
  },
}
</script>
