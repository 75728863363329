<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="pageTitle" />

    <div class="box__container">
      <div v-if="confirmationInfo && confirmationInfo.pruefnummer">
        <p>{{ confirmationInfo.anrede }}</p>
        <div v-if="confirmationInfo.alreadyConfirmed">
          <p>
            Ihr <DownloadLink target="_blank" rel="noopener noreferer" 
              :title="apTitle"
              :disabled="isSomeButtonLoading"
              :href="linkmaker()"
            />
             ist bereits bestätigt.
          </p>
          <p>Vielen Dank!</p>
        </div>
        <div v-else>
          <p>
            die WealthKonzept AG ist als Ihr Vermögensverwalter gesetzlich dazu verpflichtet in regelmäßigen Abständen die Aktualität Ihrer persönlichen Verhältnisse, wie sie im Anlegerprofil dokumentiert sind, zu überprüfen. Hat sich Ihr Anlegerprofil in dieser Hinsicht geändert?
          </p>
          <p>
            <DownloadLink target="_blank" rel="noopener noreferer" 
              :title="apTitle"
              :disabled="isSomeButtonLoading"
              :href="linkmaker()"
            />        
          </p>
          <InputRadioBoxGroup 
            v-model="apHasChanged" 
            :disabled="isSomeButtonLoading"
            :values="values"
            labelClass="font-bold"
          />

          <BaseButton @click="confirm" :disabled="apHasChanged === null || isSomeButtonLoading">
            Bestätigen
            <AnimatedSpinner v-if="loading.confirm" />
          </BaseButton>
        </div>
      </div>
      <GhostLoading v-else-if="loading.init" type="block" :config="{ block: { height: 250, }, }" />
      <NoData v-else content="Keine Daten" />
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

import axios from 'axios';
import CORE_TYPES from '@/store/core/types';
import { makeQueryParam } from '@/helpers/utils-helper';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    AnimatedSpinner,
    DownloadLink,
    InputRadioBoxGroup,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      confirmationInfo: {},
      apHasChanged: null,
      schiene: 'WEALTH_KONZEPT',
      values: [{ value: true, label: 'Ja, ich möchte Änderungen erfassen' }, { value: false, label: 'Nein, mein Anlegerprofil hat sich nicht geändert, meine persönlichen Verhältnisse stellen sich immer noch wie dort dar' }],
      loading: {
        init: false,
        confirm: false,
      },
    };
  },
  mounted() {
    this.initialize()
  },
  computed: {
    ...mapGetters({
      token: CORE_TYPES.GETTERS.GET_TOKEN,
    }),
    computedDate() {
      if (this.confirmationInfo.dateStand) {
        const dateStand = new Date(this.confirmationInfo.dateStand)
        return this.formatDate(dateStand)
      }
    },
    apTitle() {
      return 'Anlegerprofil vom ' + this.computedDate
    },
    pageTitle() {
      if (this.schiene === 'WEALTH_KONZEPT')
        return 'Anlegerprofil Wealthkonzept bestätigen'
      return 'Anlegerprofil bestätigen'
    },
    isSomeButtonLoading() {
      return !!(Object.values(this.loading).filter(valueLoading => valueLoading).length)
    },
  },
  methods: {
    confirm() {
      if (this.apHasChanged === null) {
        return
      }

      if (this.apHasChanged) {
        this.navigateToAnlegerprofil()
      } else {
        this.confirmAnlegerprofil()
      }
    },
    navigateToAnlegerprofil() {
      let route = 'anlegerprofil'
      if (this.schiene === 'WEALTH_KONZEPT')
        route = 'anlegerprofilWK'
      this.$router.push({
        path: '/beratung/' + route,
        query: {fromConfirm: true},
      })
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    },
    linkmaker (){
      const token = this.token
      const pruefnummer = this.confirmationInfo.pruefnummer

      const params = makeQueryParam({ pruefnummer, token })
      
      return `${process.env.VUE_APP_API}/anlegerprofil/get_file_link?${params}`;
    },
    initialize() {
      this.loading.init = true

      let serviceUrl = '/anlegerprofil/getConfirmationInfo';
      axios.get(`${process.env.VUE_APP_API}/${serviceUrl}?apSchiene=${this.schiene}`, config).then((response) => {
        if (response && response.data) {
          this.confirmationInfo = response.data
        }
      }).finally(() => this.loading.init = false)
    },
    openDoneModal() {
      this.$confirmModal({
        title: 'Anlegerprofil bestätigt',
        message: 'Vielen Dank!',
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      }).then(() => {})
      .catch(() => {})
      .finally(() => this.$router.push('/home'))
    },
    confirmAnlegerprofil() {
      this.loading.confirm = true

      let serviceUrl = '/anlegerprofil/confirmedByKunde';
      axios.post(`${process.env.VUE_APP_API}/${serviceUrl}?pruefnummer=${this.confirmationInfo.pruefnummer}`, config)
        .then((response) => {
        if (response && response.status === 200) {
          this.openDoneModal()
        }
      }).finally(() => this.loading.confirm = false)
    },
  },
};
</script>