<template>
  <div>
    <div class="row">
      <div class="col-12">
        <FondsFinderSuchMaske/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderPositionsList 
        :addPositionsOnBack="addPositionsOnBack" 
        :goBackEnabled="goBackEnabled" 
        @goBack="goBack($event, true)"/>
      </div>
    </div>
  </div>
</template>

<script>
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import CORE_TYPES from '@/store/core/types'

export default {
  components: {
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
  },
  props: {
    addPositionsOnBack: {
      type: Boolean,
      default: true,
    },
    goBackEnabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goBack(fonds,popSaveBackToPreviousPage) {
      if (popSaveBackToPreviousPage) {
        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      }
      this.$emit('goBack', fonds)
    }
  }
}
</script>

<style scoped>

</style>