<template>
  <div class="mb-4">
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
      tid="fd8cb34a-5ef2-4a09-96c6-6194743f2460"
    />
    <DashboardPanel
      id="a207c3dd-7839-4d79-a5f2-6c4123e0b17be"
      :data="dashboardData"
      ignoreUserLevelConfig
      :canEdit="true"
      :isMasonry="false"
      :is3Columns="true"
      :headerActions="headerActions"
      :noPrimaryAction="false"
      @executeAction="handleExecuteAction($event)"
      @action-UNTERLAGEN="gotoUnterlagen()"
    >
      <template #title> Fondsinfo </template>
      <template #subtitle>
        <template v-if="fundsName">
          <span class="subtitle-part">{{ fundsName }}</span>
          <span v-if="$route.params.isin"> | </span>
        </template>
        <template v-if="$route.params.isin">
          <span class="subtitle-part">{{ $route.params.isin }}</span>
        </template>        
      </template>
    </DashboardPanel>
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhCheck, PhWarning } from "phosphor-vue";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import DashboardPanel from "@/components/dashboard/DashboardPanel.vue";
import NoData from "@/components/core/NoData.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { VIEW_ROLES } from "@/router/roles.js";
import Pill from "@/components/core/Pill.vue";
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {},

  mixins: [InteractiveHelpCommonsMixin],
  validators: {},

  data: function () {
    return {
      showAdditional: false,
      path: {
        BETEILIGUNGEN: "beteiligungen",
        VERMOGENSUBERSICHT: "vermogensubersicht",
      },
      tableData: {
        headers: {},
        records: [],
      },
      selectedRowsValues: [],
    };
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      fundsDetail: FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
    }),
    headerActions() {
      const actions = [];

      actions.push(PageHeaderSimpleAction('UNTERLAGEN', 'Unterlagen'));

      return actions;
    },
    currentPath() {
      if (this.$route.path.includes(this.path.BETEILIGUNGEN)) {
        return this.path.BETEILIGUNGEN;
      }
      if (this.$route.path.includes(this.path.VERMOGENSUBERSICHT)) {
        return this.path.VERMOGENSUBERSICHT;
      }
      return this.path.VERMOGENSUBERSICHT;
    },
    fundsName() {
      return this.fundsDetail?.namme || "";
    },
    isViewCustomerAsBypass() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    },
    dashboardData() {
      const data = { widgets: [] };

      if (this.currentPath == this.path.BETEILIGUNGEN) {
        data.widgets = [
          ...data.widgets,
          {
            name: "ALLGEMEIN",
            title: "Allgemeine",
            component: () =>
              import("@/components/fondsinfo/cards/CardAllgemein.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
          {
            name: "AUSSCHUTTUNG",
            title: "Ausschüttung",
            component: () =>
              import("@/components/fondsinfo/cards/CardAusschuttung.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
          {
            name: "STEUERLICHES_ERGEBNIS",
            title: "Steuerliches Ergebnis",
            component: () =>
              import(
                "@/components/fondsinfo/cards/CardSteuerlichesErgebnis.vue"
              ),
            loading: () => false,
          },
          {
            name: "ERBSCHAFTSSTEUERLICHE_WERTE",
            title: "Erbschaftssteuerliche Werte",
            component: () =>
              import(
                "@/components/fondsinfo/cards/CardErbschaftssteuerlicheWerte.vue"
              ),
            loading: () => false,
          },
          {
            name: "EINZAHLUNGEN",
            title: "Einzahlungen",
            component: () =>
              import("@/components/fondsinfo/cards/CardEinzahlungen.vue"),
            loading: () => false,
          },
          {
            name: "MITTELHERKUNFT",
            title: "Mittelherkunft",
            component: () =>
              import("@/components/fondsinfo/cards/CardMittelherkunft.vue"),
            loading: () => false,
          },
          {
            name: "INVESTITIONSKOSTEN",
            title: "Investitionskosten",
            component: () =>
              import("@/components/fondsinfo/cards/CardInvestitionskosten.vue"),
            loading: () => false,
          },
          {
            name: "DOKUMENTE",
            title: "Dokumente",
            component: () =>
              import("@/components/fondsinfo/cards/CardDokumente.vue"),
            loading: () => false,
          },
        ];
      } else if (this.currentPath == this.path.VERMOGENSUBERSICHT) {
        data.widgets = [
          ...data.widgets,
          {
            name: "DETAIL",
            title: "Stammdaten",
            component: () =>
              import("@/components/fondsinfo/cards/CardDetail.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },
          {
            name: "DETAIL2",
            title: "Schwerpunkt",
            mainStep: "DETAIL",
            component: () =>
              import("@/components/fondsinfo/cards/CardSchwerpunkt.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },    
          {
            name: "DETAIL3",
            title: "Zielmarktdaten (MiFID)",
            mainStep: "DETAIL",
            component: () =>
              import("@/components/fondsinfo/cards/CardZielmarktdaten.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },      
          {
            name: "DETAIL4",
            title: "Kosten",
            mainStep: "DETAIL",
            component: () =>
              import("@/components/fondsinfo/cards/CardKosten.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },    
          {
            name: "DETAIL5",
            title: "Kosten Ex-Ante",
            mainStep: "DETAIL",
            component: () =>
              import("@/components/fondsinfo/cards/CardKostenExAnte.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },     
          {
            name: "DETAIL6",
            title: "Kosten ExPost",
            mainStep: "DETAIL",
            component: () =>
              import("@/components/fondsinfo/cards/CardKostenExPost.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },                          
          {
            name: "CHARTS1",
            title: "Wertentwicklung",
            mainStep: "CHARTS",
            component: () =>
              import("@/components/fondsinfo/cards/CardWertentwicklung.vue"),
            loading: () => false,
          },
          {
            name: "CHARTS2",
            title: "Jährliche Rendite",
            mainStep: "CHARTS",
            component: () =>
              import("@/components/fondsinfo/cards/CardJaehrlicheRendite.vue"),
            loading: () => false,
          },
          {
            name: "CHARTS3",
            title: "Rendite / Risiko",
            mainStep: "CHARTS",
            component: () =>
              import("@/components/fondsinfo/cards/CardRenditeRisiko.vue"),
            loading: () => false,
          },
          {
            name: "PERFORMANCE",
            title: "Performance",
            component: () =>
              import("@/components/fondsinfo/cards/CardPerformance.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          },
          {
            name: "RISIKO",
            title: "Risiko",
            component: () =>
              import("@/components/fondsinfo/cards/CardRisiko.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],               
          },
        ];

        if (
          this.hasRoles([
            [this.VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
            [this.VIEW_ROLES.VIEW_BROKER],
            [this.VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          ])
        ) {
          data.widgets.push({
            name: "PURCHASEOPTS",
            title: "Bezugsmöglichkeiten",
            component: () =>
              import(
                "@/components/fondsinfo/cards/CardBezugsmoglichkeiten.vue"
              ),
            visible: () => true,
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],            
          });
        }
      }

      return data;
    },
  },
  mounted: async function () {
    this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_DETAILS, {
      isin: this.$route.params?.isin,
      bgsNr: this.$route.params?.begleitscheinnummer,
    });
  },

  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zum Überblick",
      to,
      from,
    });

    next();
  },

  methods: {
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    gotoUnterlagen() {
      this.$router.push(
            `/shared/vermogensubersicht/fondsinfo/${this.$route.params?.isin}?stepKey=UNTERLAGEN`
          );

    },
    handleExecuteAction(actionData) {
      if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
        if (actionData?.component?.$refs.table) {
          actionData?.component?.$refs.table.openColumnsConfig();
        } else if (actionData.action?.tableContainer) {
          actionData.component.$refs[actionData.action?.tableContainer].$refs.table.openColumnsConfig();
        }
      } else {
        if (actionData.widget?.mainStep) {
          let url = `/shared/vermogensubersicht/fondsinfo/${this.$route.params?.isin}?stepKey=${actionData?.widget?.mainStep}`;

          if (actionData?.widget?.subStep) {
            url = `${url}?substep=${actionData?.widget?.subStep}`;
          }

          this.$router.push(url);
        } else {
          console.log( `/shared/vermogensubersicht/fondsinfo/${this.$route.params?.isin}?stepKey=${actionData?.widget?.name}`);
          this.$router.push(
            `/shared/vermogensubersicht/fondsinfo/${this.$route.params?.isin}?stepKey=${actionData?.widget?.name}`
          );
        }
      }
    },
  },

  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    NoData,
    Pill,
  },
};
</script>

<style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>

<!-- <style src='./InsuranceOverview.scss' lang='scss' scoped></style> -->
