var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: {
          label: "Depotauswahl",
          values: _vm.comboboxSelections[_vm.id],
          value: _vm.antragData[_vm.id]
        },
        on: {
          change: function($event) {
            return _vm.onChangeBankdepot($event)
          }
        }
      }),
      _c("hr"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("InputField", {
            staticClass: "col-12 col-lg-6 px-3",
            attrs: {
              label: "Bankdepot-Nr.",
              isEmbedded: true,
              value: _vm.antragData["INPUT_AUSL_FE_DEPOTNR"]
            },
            on: {
              change: function($event) {
                return _vm.onChange("INPUT_AUSL_FE_DEPOTNR", $event)
              }
            }
          }),
          _c("InputField", {
            staticClass: "col-12 col-lg-6 px-3",
            attrs: {
              label: "Name der Bank",
              isEmbedded: true,
              value: _vm.antragData["INPUT_AUSL_FE_BANK"]
            },
            on: {
              change: function($event) {
                return _vm.onChange("INPUT_AUSL_FE_BANK", $event)
              }
            }
          }),
          _c("InputField", {
            staticClass: "col-12 col-lg-6 px-3",
            attrs: {
              label: "IBAN",
              isEmbedded: true,
              value: _vm.antragData["INPUT_AUSL_FE_BLZ"]
            },
            on: {
              change: function($event) {
                return _vm.onChange("INPUT_AUSL_FE_BLZ", $event)
              }
            }
          }),
          _c("InputField", {
            staticClass: "col-12 col-lg-6 px-3",
            attrs: {
              label: "BIC",
              isEmbedded: true,
              value: _vm.antragData["INPUT_AUSL_FE_BIC"]
            },
            on: {
              change: function($event) {
                return _vm.onChange("INPUT_AUSL_FE_BIC", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }