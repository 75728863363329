<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation title="ExPost Berechnung" />

        <div class="box__container" v-if="isFA">
            <BaseCollapsable>
                <template v-slot:title><div class="box__title">Hinweise</div></template>
                <template v-slot:content>
                    <div class="font-strong">Serienbrief</div>
                    <div>Erstellt die Datei wie beim Serienbriefversand. Aktueller Unterschied: Extraseite Mailadresse.</div>
                    <div class="font-strong mt-2">Detailierter Auszug</div>
                    <div>Teilt den Auszug auf die einzelnen Depots auf.</div>
                </template>
            </BaseCollapsable>
        </div>

        <div class="box__container">
            <div class="box__title mb-4">ExPost Berechnung</div>
            
            <div class="box__container-wrap">
                <div class="box__container-rows">
                    <div class="label-value--list">
                        <div class="label-value--item">
                            <div :class="!isFA && 'label-value--item-label'">Berechnung für Jahr:</div>
                            <div class="label-value--item-value font-strong">{{jahr}} <PhCheck :size="16" :style="{ opacity: 0 }" /></div>
                        </div>
                        <div v-if="isInternLogin">
                            <div v-if="isFA">
                                <InputToggleSwitch v-model="checkSerienbrief" label="Serienbrief" inLineLabel suppressValidationMessage />
                                <InputToggleSwitch v-model="checkDetail" label="detailierter Auszug" inLineLabel suppressValidationMessage />

                            </div>
                            <div v-else>
                                <div class="label-value--item" v-for="(entry, index) in entries" :key="index">
                                    <div class="label-value--item-label">{{entry.label}}</div>
                                    <div class="label-value--item-value">{{entry.value}} <PhCheck :size="16" class="color-success" :style="{ opacity: entry.value === 'Ja' ? 1 : 0 }" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-row">
                    <div>
                        <DownloadLink
                            asLink
                            title="Makler PDF anzeigen / Vorschau"
                            downloadServicePath="/ex_post_berechnung"
                            filename="ExPostKosteninformation.pdf"
                            :queryParams="{ checkSerienbrief: checkSerienbrief, checkDetail: checkDetail }"
                        /> 
                    </div>
                    <div>
                        <DownloadLink v-if="isInternLogin && !isFA"
                            asLink
                            title="WealthKonzept PDF anzeigen / Vorschau"
                            downloadServicePath="/ex_post_berechnung"
                            filename="ExPostKosteninformation.pdf"
                            :queryParams="{ type: 'wk'}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PhCheck } from 'phosphor-vue';
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import EXPOST_TYPES from "@/store/expost/types";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';

export default {
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        DownloadLink,
        InputToggleSwitch,
        BaseCollapsable,
        PhCheck,
    },
    data() {
        return {
            checkSerienbrief: '0',
            checkDetail: '0',
        }
    },
    mounted() {
        this.$store.dispatch(EXPOST_TYPES.ACTIONS.GET_BERECHNUNG);
    },
    computed: {
        ...mapGetters({
            data: EXPOST_TYPES.GETTERS.BERECHNUNG,
            isInternLogin: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            isFA: CORE_TYPES.GETTERS.IS_FA,
        }),
        entries() {
            return this.data && Object.keys(this.data).filter(key => key !== 'jahr').map(key => {return {label: key, value: this.data[key]}}) || [];
        },
        jahr() {
            return this.data?.jahr || '';
        },
        queryParams() {
            return { checkSerienbrief: this.checkSerienbrief, checkDetail: this.checkDetail }
        }
    }
}
</script>

<style src='@/components/steckbrief/steckbrief.scss' lang='scss' scoped></style>