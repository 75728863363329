var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title:
            "Wertpapiere für " +
            ((_vm.selectedFavorit && _vm.selectedFavorit.labelShort) || ""),
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addFond()
          },
          "action-FONDSVERGLEICH": function($event) {
            return _vm.openFondsvergleich()
          },
          "action-IMPORT": function($event) {
            return _vm.$refs.importWertpapiereModal.open()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputTextArea", {
            attrs: { label: "Bemerkung", disabled: !_vm.isEditBem, rows: 4 },
            on: { change: _vm.editBemerkung },
            model: {
              value: _vm.bemerkung,
              callback: function($$v) {
                _vm.bemerkung = $$v
              },
              expression: "bemerkung"
            }
          })
        ],
        1
      ),
      !_vm.loading || (_vm.rows && _vm.rows.length)
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  disabled: _vm.loading,
                  isComponentHalfSize: "",
                  placeholder:
                    "Suchen nach Wertpapier / Gesellschaft / Kategorie / ISIN"
                },
                model: {
                  value: _vm.descriptionSearch,
                  callback: function($$v) {
                    _vm.descriptionSearch = $$v
                  },
                  expression: "descriptionSearch"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("WertpapierTable", {
            attrs: {
              tableId: "cf2fd13a-37ba-40f2-aea0-5937f3aeae4a",
              rows: _vm.rows,
              headers: _vm.wpHeaders,
              headerActions: _vm.tableHeaderActions,
              loading: _vm.loading,
              exportConfig: _vm.exportConfig
            },
            on: {
              "action-DELETE": function($event) {
                return _vm.deleteWertpapier([$event])
              },
              "headerAction-BULK_DELETE": function($event) {
                return _vm.deleteWertpapier(_vm.selectedRows)
              }
            },
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "importWertpapiereModal",
          attrs: {
            labelButtonConfirm: "Importieren",
            modalTitle: "Wertpapiere importieren"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.importWertpapiere()
            },
            close: function($event) {
              return _vm.resetImportData()
            }
          }
        },
        [
          _c(
            "DragnDropArea",
            {
              attrs: { hoverText: "Hochladen" },
              on: { files: _vm.onFileChange }
            },
            [
              _vm._v(" ISIN-Liste:"),
              _c("br"),
              _c(
                "BaseFileSelect",
                {
                  class: { "mb-3": !_vm.importData.file },
                  attrs: { accept: ".txt" },
                  on: { files: _vm.onFileChange }
                },
                [_vm._v(" Textdatei hochladen ")]
              ),
              _c("div", [
                _vm.importData.file
                  ? _c(
                      "div",
                      { staticClass: "input-chip" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.importData.file &&
                                _vm.importData.file.fileName
                            )
                          )
                        ]),
                        _c("ph-x-circle", {
                          staticClass: "clickable ml-1",
                          attrs: { size: 16 },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.importData.file = null
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "mb-3" }, [
                _c("b", [_vm._v("Dateiformat:")]),
                _vm._v(" Textdatei (.txt)"),
                _c("br"),
                _c("b", [_vm._v("Dateiaufbau:")]),
                _c("br"),
                _c("b", [_vm._v("Beispiel 1:")]),
                _c("br"),
                _vm._v(" ISIN 1"),
                _c("br"),
                _vm._v(" ISIN 2"),
                _c("br"),
                _vm._v(" ISIN ..."),
                _c("br"),
                _c("b", [_vm._v("Beispiel 2:")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" ISIN 1, ISIN 2, ISIN ..."),
                _c("br")
              ]),
              !(
                _vm.selectedFavorit.fondsshop &&
                !_vm.options.zugriffUneingeschraenkt
              )
                ? _c("InputToggleSwitch", {
                    staticClass: "mt-2",
                    staticStyle: { "padding-bottom": "0px" },
                    attrs: {
                      label: "<b>Alle</b> Wertpapiere ersetzen",
                      inLineLabel: ""
                    },
                    model: {
                      value: _vm.importData.replace,
                      callback: function($$v) {
                        _vm.$set(_vm.importData, "replace", $$v)
                      },
                      expression: "importData.replace"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }