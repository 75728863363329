import { BROKER_STRUCTURE_ROLES, ROLES, VIEW_ROLES } from '@/router/roles'

function generateGesellschaftChildren(roles) {
  const items = [{
    path: 'overview',
    meta: {
      roles: {
        allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
      },
    },
    component:() => import(/* webpackChunkName: "gesellschaft-overview" */ '@/views/gesellschaft/GesellschaftOverview.vue'),
  },
  {
    path: 'FONDSGESELLSCHAFTEN',
    meta: {
      roles: {
        allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
      },
    },
  },
  {
    path: 'BETEILIGUNGEN',
    meta: {
      roles: {
        allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS]
      }
    }
  },
  {
    path: 'VERSICHERUNGEN_FP_SERVICE_GMBH',
    meta: {
      roles: {
        allowed: [[BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_FPP_PLUS, ROLES.IS_MEHRFACHAGENT]],
        denied: [[ROLES.IS_FPP_PLUS_MASTER]],
      }
    }
  },
  {
    path: 'VERSICHERUNGEN_FP_GMBH',
    meta: {
      roles: {
        allowed: [[BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_FPP_PLUS]],
        denied: [[ROLES.IS_FPP_PLUS_MASTER], [ROLES.IS_MEHRFACHAGENT]],
      }
    }
  },
  {
    path: 'VERSICHERUNGEN_FP_GEWERBE_GMBH',
    meta: {
      roles: {
        allowed: [[BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_FPP_PLUS]],
        denied: [[ROLES.IS_FPP_PLUS_MASTER], [ROLES.IS_MEHRFACHAGENT]],
      }
    }
  },
  {
    path: 'VERSICHERUNGEN',
    meta: {
      roles: {
        allowed: [
          [BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
          [BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_FPP_PLUS_MASTER]
        ],
        denied: [ROLES.IS_FPP_PLUS_NOT_MASTER],
      }
    }
  },
  {
    path: 'BANKEN_PLATTFORMEN',
    meta: {
      roles: {
        allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
      },
    },
  },
  {
    path: 'NONE',
  }]

  return items.map(item => ({
    path: item.path,
    meta: item.meta,
    component: 'component' in item ? item.component : () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component:() => import(/* webpackChunkName: "gesell-search" */ '@/views/gesellschaft/GesellschaftSearch.vue'),
      },
      ...generateGesellschaftItemChildren(roles),
    ]
  }))
}


export function generateGesellschaftItemChildren(roles) {
  return [
    {
      path: ':gesellId',
      component: () => import(/* webpackChunkName: "gesellschaft" */ '@/views/BaseRouterView.vue'),
      meta: {
        roles
      },
      children: [
        {
          path: '',
          redirect: 'zentrale',
        },
        {
          path: 'zentrale',
          component: () => import(/* webpackChunkName: "gesell-zentrale" */ '@/views/gesellschaft/broker/GesellschaftZentrale.vue'),
        },
        {
          path: 'ansprechpartnerList',
          component: () => import(/* webpackChunkName: "gesell-apview" */ '@/views/BaseRouterView.vue'),
          children: [
            {
              path: '',
              component: () => import(/* webpackChunkName: "gesell-aplist" */ '@/views/gesellschaft/GesellschaftAnsprechpartner.vue'),
            },
            {
              path: 'ansprechpartner/:personId?',
              component: () => import(/* webpackChunkName: "gesell-ap" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerMain.vue'),
              children: [
                {
                  path: '',
                  redirect: 'stammdaten'
                },
                {
                  path: 'stammdaten',
                  component: () => import(/* webpackChunkName: "gesell-ap-personendaten" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerPersonenDaten.vue')
                },
              ]
            },
          ],
        },
        {
          path: 'formulare',
          component: () => import(/* webpackChunkName: "gesell-formulare" */ '@/views/gesellschaft/broker/GesellschaftFormulareList.vue'),
        },
        {
          path: 'news',
          component: () => import(/* webpackChunkName: "gesell-news" */ '@/views/gesellschaft/broker/GesellschaftNews.vue'),
        },
        {
          path: 'eigeneDokumente',
          component: () => import(/* webpackChunkName: "gesell-eigene-dokumente" */ '@/views/gesellschaft/broker/GesellschaftEigeneDokumente.vue'),
        },
      ]
    },
  ];
}

export function generateGesellschaftRoute(roles) {
  return {
    path: 'gesellschaften',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles,
      isComplete: true,
    },
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      ...generateGesellschaftChildren(roles),
    ],
  }
}

