var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Zubringercourtage bearbeiten",
          actions: _vm.headerActions
        },
        on: { "action-BACK": _vm.back, "action-CANCEL": _vm.abbrechen }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c("InputField", {
              attrs: { label: "Bereich", disabled: "" },
              model: {
                value: _vm.form.combo_bereich,
                callback: function($$v) {
                  _vm.$set(_vm.form, "combo_bereich", $$v)
                },
                expression: "form.combo_bereich"
              }
            }),
            _c("InputField", {
              attrs: { label: "Zubringer" },
              on: { input: _vm.onChangeZubringer },
              model: {
                value: _vm.form.input_zubringer,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_zubringer", $$v)
                },
                expression: "form.input_zubringer"
              }
            }),
            _c("InputField", {
              attrs: { disabled: "" },
              model: {
                value: _vm.form.input_zubringer_name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_zubringer_name", $$v)
                },
                expression: "form.input_zubringer_name"
              }
            }),
            _c("InputField", {
              attrs: {
                type: "percent",
                precision: 4,
                label: "Abschluss / Einmalige VV-Strategiegebühr"
              },
              model: {
                value: _vm.form.input_abschluss,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_abschluss", $$v)
                },
                expression: "form.input_abschluss"
              }
            }),
            _c("InputField", {
              attrs: { type: "percent", precision: 4, label: "Bestand" },
              model: {
                value: _vm.form.input_bestand,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_bestand", $$v)
                },
                expression: "form.input_bestand"
              }
            }),
            _c("InputField", {
              attrs: {
                type: "percent",
                precision: 4,
                label: "Servicegebühr / VV-Vermittlungsgebühr"
              },
              model: {
                value: _vm.form.input_servicegebuehr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_servicegebuehr", $$v)
                },
                expression: "form.input_servicegebuehr"
              }
            }),
            _c("InputField", {
              attrs: {
                type: "percent",
                label: "MwSt. mit der die Courtage versteuert wird",
                disabled: ""
              },
              model: {
                value: _vm.form.input_mwst,
                callback: function($$v) {
                  _vm.$set(_vm.form, "input_mwst", $$v)
                },
                expression: "form.input_mwst"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }