var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAktivitaetenAccess
    ? _c("div", { staticClass: "box__container-wrap" }, [
        _vm.showTitle
          ? _c("div", { staticClass: "box__title" }, [_vm._v("Aufgaben")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "box__container-rows" },
          [
            !_vm.loading && _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 5,
                    rowId: "rowId",
                    hidePagination: "",
                    mobileConfig: _vm.mobileConfig
                  },
                  on: { "click-subject": _vm.openDayView }
                })
              : _vm.loading
              ? _c("GhostLoading", {
                  attrs: { type: "table", config: { table: { rows: 5 } } }
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ],
          1
        ),
        !_vm.loading && !_vm.$isSmallScreen
          ? _c(
              "div",
              { staticClass: "bottom-row text-right mt-3" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/communication/postfach/aktivitaeten" } },
                  [_vm._v("weitere...")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }