var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Wertpapiersuche",
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _c("WertpapierSearch", {
        attrs: {
          name: "bgsSuche",
          respond: 1,
          type: _vm.type,
          singleSelection: "",
          isSelectable: ""
        },
        on: { "confirm-selected": _vm.setAuswahl }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }