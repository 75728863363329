<template>
  <div>
    <InputCheckBoxGroup v-if="!isFA"
      v-model="form"
      :disabled="isDisabled || isRefusedPage"
      title="Zulagenberechtigung bzw. Interesse an der Nutzung"
      :values="[ 
        { label: 'Arbeitnehmersparzulage', path: 'employeeSavingAllowance' },
        { label: 'Riester-Rente', path: 'riesterPension' }, 
        { label: 'Rürup-Rente', path: 'ruerupPension' },
        { label: 'Wohnungsbauprämie', path: 'buildingSubsidy' },
        { label: 'bAV', path: 'fedInsuranceComm' },
      ]"
      @checkBoxToggle="updateStoreCheckBoxGroup('finance', $event)"/>
    <InputField v-if="!isWealthKonzept"
      label="Wieviele Personen sind während des Anlagezeitraumes auf Ihre finanzielle Unterstützung angewiesen (z.B. nichtarbeitende Ehe-/Lebenspartner, Kinder, zu pflegende Angehörige etc.)?"
      v-model="numberPerson"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @change="updateStore('finance', 'numberPerson', $event)" />
    <InputField v-if="!isWealthKonzept"
      label="Bei Minderjährigen: Der Anlagebetrag stammt aus einer Zuwendung / Schenkung von (z.B. Eltern, Großeltern, Tante):"
      v-model="carePerson"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @change="updateStore('finance', 'carePerson', $event)" />
    <InputTextArea
      label="Zusätzliche Angaben zu den finanziellen Verhältnissen"
      v-model="annotationFinance"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @change="updateStore('finance', 'annotationFinance', $event)">
    </InputTextArea>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  validators: {},
  components: {
    InputField,
    InputCheckBoxGroup,
    InputTextArea,
  },
  data() {
    return {
      form: {
        employeeSavingAllowance: '',
        riesterPension: '',
        ruerupPension: '',
        buildingSubsidy: '',
        fedInsuranceComm: '',
      },
      numberPerson: '',
      carePerson: '',
      annotationFinance: '',
    };
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
  },
  mounted() {
    this.createForm();
     this.updateWarnings();
  },
  methods: {
    createForm() {
      if (this.data && this.data.finance) {
        this.form.employeeSavingAllowance = this.data.finance.employeeSavingAllowance;
        this.form.riesterPension = this.data.finance.riesterPension;
        this.form.ruerupPension = this.data.finance.ruerupPension;
        this.form.buildingSubsidy = this.data.finance.buildingSubsidy;
        this.form.fedInsuranceComm = this.data.finance.fedInsuranceComm;
        this.numberPerson = this.data.finance.numberPerson;
        this.carePerson = this.data.finance.carePerson;
        this.annotationFinance = this.data.finance.annotationFinance;
      }
    },
  },
}
</script>

<style scoped>

</style>