<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="Übertrag von Transaktionen"
            :actions="headerActions"
            @action-umbuchen="umbuchen"
        >
        </PageHeaderTitleNavigation>

        <div class="box__container">
            <div class="box__title mb-4">Übertrag von Transaktionen</div>
            
            <div class="box__container-wrap">
                <div class="box__container-rows">
                    <div class="label-value--list">
                        <div class="label-value--item">
                            <div class="font-strong">Die Transaktionen des Vertrags:</div>
                        </div>
                        <div class="label-value--item" v-for="(entry, index) in aktuellBgsData" :key="index">
                            <div class="label-value--item-label">{{entry.label}}</div>
                            <div class="label-value--item-value">{{entry.value}}</div>
                        </div>
                        <div v-if="newBgsData.length">
                            <div class="label-value--item mt-4">
                                <div class="font-strong">könnten auf folgenden Vertrag übertragen werden:</div>
                            </div>
                            <div class="label-value--item " v-for="(entry, index) in newBgsData" :key="index">
                                <div class="label-value--item-label">{{entry.label}}</div>
                                <div class="label-value--item-value">{{entry.value}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    mounted() {
        this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_TRANSAKTIONEN_UEBERTRAGEN_DATA, this.bgsnr)
    },
    computed: {
         ...mapGetters({
            data: DEPOTPOSITIONENEDIT_TYPES.GETTERS.TRANSAKTIONEN_UEBERTRAGEN_DATA,
            backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
        }),
        bgsnr() {
            return this.$route.params.bgsnr || '';
        },
        aktuellBgsData() {
            return this.data?.aktuellBgsData && Object.keys(this.data.aktuellBgsData).map(key => {return {label: key, value: this.data.aktuellBgsData[key]}}) || [];
        },
        newBgsData() {
            return this.data?.newBgsData && Object.keys(this.data.newBgsData).map(key => {return {label: key, value: this.data.newBgsData[key]}}) || [];
        },
        headerActions() {
            const actions = [];
            if (this.newBgsData.length) {
                actions.push(PageHeaderSimpleAction('umbuchen', 'Umbuchen'));
            }
            return actions;
        },
    },
    methods: {
        umbuchen() {
            this.$confirmModal({
                title: 'Transaktionen umbuchen', 
                message: 'Wollen Sie die Transaktionen wirklich umbuchen?', 
                labelButtonConfirm: 'Umbuchen',
            }).then(async () => {
                await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TRANSAKTIONEN_UEBERTRAGEN, this.bgsnr);
                if (this.backToPreviousData?.length > 0) {
                    this.$router.go(-1);
                } else {
                    this.$router.push('/home/depotpositionenedit');
                }
            })
        }
    }
}
</script>

<style src='@/components/steckbrief/steckbrief.scss' lang='scss' scoped></style>