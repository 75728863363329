<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Vermögensverwaltung"
    />

    <div class="box__container">
      <VVWertpapiereList
        :positions="positions"
        :schemaId="schemaId"
        :isLoading="isLoading"
      />
    </div>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import VVWertpapiereList from "@/components/vermoegensverwaltung/VVWertpapiereList.vue";

export default {
  mixins: [mixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    VVWertpapiereList,
  },
  data: function () {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch(VV_TYPES.ACTIONS.GET_STRATEGIEN_SCHEMA_DATA, {
        schemaId: this.schemaId,
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    positions() {
      return this.schemaData?.varianten?.length && this.schemaData.varianten[0]
        ? this.schemaData.varianten[0].positions
        : [];
    },
    isLoading() {
      return this.loading;
    },
  },
};
</script>

<style>
</style>