var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          tid: "fd8cb34a-5ef2-4a09-96c6-6194743f2460"
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "a207c3dd-7839-4d79-a5f2-6c4123e0b17be",
          data: _vm.dashboardData,
          ignoreUserLevelConfig: "",
          canEdit: true,
          isMasonry: false,
          is3Columns: true,
          headerActions: _vm.headerActions,
          noPrimaryAction: false
        },
        on: {
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          },
          "action-UNTERLAGEN": function($event) {
            return _vm.gotoUnterlagen()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Fondsinfo ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm.fundsName
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _vm._v(_vm._s(_vm.fundsName))
                      ]),
                      _vm.$route.params.isin
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.$route.params.isin
                  ? [
                      _c("span", { staticClass: "subtitle-part" }, [
                        _vm._v(_vm._s(_vm.$route.params.isin))
                      ])
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }