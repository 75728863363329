var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Jahrescheckliste",
          actions: _vm.headerActions
        },
        on: {
          "action-excel": function($event) {
            return _vm.getExcellFile()
          }
        }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "jahrescheckliste",
          stepperMediator: _vm.getStepperMediator(),
          selectedStepKey: _vm.selectedStepKey
        },
        on: {
          "next-step": _vm.nextStep,
          "previous-step": _vm.previousStep,
          "set-step": _vm.setStepByKey,
          "on-fertig": _vm.close
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.selectedStepKey == "punkt1"
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            values: [{ label: "2024", value: "2024" }],
                            label: "JAHRESCHECKLISTE für Jahr:",
                            isComponentHalfSize: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fieldChanged({
                                name: "INPUT_JAHR",
                                value: $event
                              })
                            }
                          },
                          model: {
                            value: _vm.form.INPUT_JAHR,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "INPUT_JAHR", $$v)
                            },
                            expression: "form.INPUT_JAHR"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            hidden: true,
                            disabled: true,
                            isComponentHalfSize: ""
                          },
                          model: {
                            value: _vm.form.PARAM_ID,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "PARAM_ID", $$v)
                            },
                            expression: "form.PARAM_ID"
                          }
                        }),
                        _c("ChecklistePunkt1", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt2"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt2", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt3"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt3", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt4"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt4", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt5"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt5", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt6"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt6", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt7"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt7", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt8"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt8", {
                          attrs: { form: _vm.form, paramId: _vm.form.PARAM_ID },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedStepKey == "punkt9"
                  ? _c(
                      "div",
                      [
                        _c("ChecklistePunkt9", {
                          attrs: { form: _vm.form },
                          on: {
                            fieldChanged: function($event) {
                              return _vm.fieldChanged($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "komplett",
          attrs: { showConfirmButton: false, labelButtonCancel: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Formular ist bereits signiert ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(" Das Dokument wurde bereits elektronisch signiert. "),
            _c("br"),
            _vm._v(" Eine Änderung ist daher nicht mehr möglich. "),
            _c("br")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }