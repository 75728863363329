<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Nicht berücksichtigte E-Mail-Adressen" 
      :actions="headerActions" 
      @action-ADD="hasNew=true"
    />

    <div class="box__container">
      <div  v-if="emailList.length">
        <div v-for="(email, index) of emailList" :key="index" class="mb-4">
          <div class="email-items__container">
            <div class="email-items__input">
              <InputField 
                :value="email.label"
                :id="'email' + index"
                validateUntouched
                @input="updateValue(email, $event)"
                @change="save(email, index, $event)"
              />
            </div>
            <div class="email-items__button">
              <BaseButton @click="removeEmail(email)" isLink><PhTrashSimple :size="16" /></BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        Keine Daten.
      </div>

      <div class="email-items__container" v-if="hasNew">
        <div class="email-items__input">
          <InputField 
            v-model="newAdresse"
            validateUntouched
            @change="saveNewAddress"
          />
        </div>
        <div class="email-items__button">
          <BaseButton @click="removeEmail()" isLink><PhTrashSimple :size="16" /></BaseButton>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import E_MAIL_ACCESS_TYPES from '@/store/eMailAccess/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import {  PhTrashSimple } from 'phosphor-vue';
import validator from "@/mixins/validator";
import { email } from "@/mixins/validator/rules";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [validator],
  validators: {
    newAdresse: [email('', {emptyAllowed: true})]
  },
  components: {
    InputField,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    PhTrashSimple,
  },
  data() {
    return {
      hasNew: false,
      newAdresse: '',
    };
  },
  mounted() {
    this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_IGNORED_ADDRESSES, this.serverId);
  },
  watch: {
    emailList: {
      immediate: true,
      handler(list) {
        list?.filter(item => item && this.$configureValidators).forEach((item, index) => 
          this.$configureValidators({ 
            ['email'+index]: [email('', {emptyAllowed: true})],
          }))
        if (this.hasNew) {
          this.hasNew = false;
          this.newAdresse = '';
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      ignoredAddresses: E_MAIL_ACCESS_TYPES.GETTERS.IGNORED_ADDRESSES,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neue Adresse'),
      ];
    },
    serverId() {
      return this.$route.params.id || '';
    },
    emailList() {
      return this.ignoredAddresses || [];
    },
  },
  methods: {
    updateValue(email, event) {
      if (email?.label) {
        email.label = event
      }
    },
    removeEmail(data) {
      if (!data?.value) {
        this.hasNew = false;
        this.newAdresse = '';
      } else {
        this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.DELETE_IGNORED_ADDRESS, {sid: this.serverId, eid: data?.value});
      }
    },
    save(email, index, value) {
      if (this.validation && this.validation[`email${index}`]?.valid) {
        this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SAVE_IGNORED_ADDRESS, {sid: this.serverId, data: {address: value, id: email?.value}});
      }
    },
    saveNewAddress(value) {
      if (this.validation?.newAdresse?.valid && value) {
        this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SAVE_IGNORED_ADDRESS, {sid: this.serverId, data: {address: value, id: ''}});
      }
    },
  },

}
</script>
<style scoped>
.email-items__container {
  display: flex;
}
.email-items__input {
  flex: 1 0 auto;
  width: 50%;
}
.email-items__button {
  flex: 1 1 auto;
  padding-left: 1rem;
}
</style>
