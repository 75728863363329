<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :title="$appNavigation.currentMenu.label"
    >
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <Table
        class="table-container"
        v-if="!loading && rows && rows.length"
        :title="TABLE_TITLE"
        ref="emailListe"
        tableId="99c65275-ee0b-45c9-9313-96fb26f7515b"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        @click-datum="viewEmail"
      >
        <template v-slot:status="row">
          <div>
            {{ row.status }}
          </div>
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  SlotColumn,
  DateColumn,
} from "@/components/table2/table_util.js";
import AUFTRAEGE_TYPES from "@/store/maklerauftraege/types";
import { mapGetters } from "vuex";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";

const TABLE_TITLE = "E-Mailliste Maklerauftrag";

export default {
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      TABLE_TITLE,
      rowsPerPage: 20,
      loading: false,
      uebertragVertragId: null,
    };
  },
  mounted() {
    if (!this.$route.query?.versUebertragId) {
      return;
    }
    this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.GET_EMAILS_SENT, {
      id: this.$route.query?.versUebertragId,
    });
  },
  computed: {
    ...mapGetters({
      emailsSent: AUFTRAEGE_TYPES.GETTERS.GET_EMAILS_SENT,
    }),
    versUebertragId() {
      return this.$route.query?.versUebertragId;
    },
    headers() {
      let result = {
        lockedLeft: [
          SlotColumn('status', 'Status'),
          DateColumn('datum', 'Datum').makeLink(),
        ],
        center: [
          TextColumn('empfaenger', 'Empfänger'),
          TextColumn('betreff', 'Betreff'),
          TextColumn('absender', 'Absender'),
        ],
      };

      return result;
    },
    rows() {
      return this.emailsSent;
    },
  },
  methods: {
    closeInfoModal() {
      this.infoTitle = null;
      this.infoText = null;
    },
    viewEmail(event) {
      if (event?.id) {
        this.$router.push(`/communication/postfach/emails/makler/${event.id}/${this.$route.query?.insuranceId}/${this.$route.query?.userId}`);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zu den E-Mailliste Maklerauftrag",
      to,
      from,
    });
    next();
  },
};
</script>

<style>
</style>