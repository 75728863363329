var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Regelmäßige Risikoprüfung Ergebnis" }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Regelmäßige Risikoprüfung Ergebnis")
        ]),
        _c("div", { staticClass: "mt-2" }, [
          _vm._v(
            "Hier finden Sie Ihre Kunden deren aktuelles Depot von den Angaben im aktuellsten unterschriebenen Anlegerprofil abweicht."
          )
        ]),
        _c("div", [
          _vm._v(
            "Die Liste wird einmal täglich aktualisiert. Bitte beachten Sie, dass nur Kunden aufgeführt sind, bei denen in den Einstellungen die laufene Überprüfung der Geeignetheit bzw. der Risikoallokation aktiviert ist. "
          )
        ]),
        _c("div", [
          _vm._v(
            "Da das neuste unterschriebene Anlegerprofil zugrunde gelegt wird, verschwinden Kunden erst sobald das angepasste Anlegerprofil unterschrieben wurde."
          )
        ]),
        _c(
          "div",
          {
            staticClass:
              "layout__negative-margin--8 antrag-action-button__container"
          },
          [
            !_vm.isIntern
              ? _c(
                  "BaseButton",
                  {
                    attrs: { isPrimary: "" },
                    on: {
                      click: function($event) {
                        return _vm.reload()
                      }
                    }
                  },
                  [_vm._v("Aktualisieren")]
                )
              : _vm._e(),
            !_vm.isIntern
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label: "Struktur anzeigen",
                    inLineLabel: "",
                    suppressValidationMessage: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.reload()
                    }
                  },
                  model: {
                    value: _vm.includeStruktur,
                    callback: function($$v) {
                      _vm.includeStruktur = $$v
                    },
                    expression: "includeStruktur"
                  }
                })
              : _vm._e(),
            !_vm.isIntern
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label: "Zielmarktfehler anzeigen",
                    inLineLabel: "",
                    suppressValidationMessage: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.reload()
                    }
                  },
                  model: {
                    value: _vm.includeZielmarkt,
                    callback: function($$v) {
                      _vm.includeZielmarkt = $$v
                    },
                    expression: "includeZielmarkt"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      hidePagination: "",
                      rowId: "risikopruefungErgebnis"
                    },
                    on: {
                      "click-kundenName": _vm.openCustomerNewTab,
                      "action-excel": _vm.openExcelFile
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "bemerkung",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: { value: row["bemerkung"] },
                                on: {
                                  change: function($event) {
                                    return _vm.saveBemerkung($event, row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3390098527
                    )
                  })
                ],
                1
              )
            : _c("NoData", { attrs: { content: "Keine Informationen" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }