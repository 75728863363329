var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Erfassung Position Papierantrag"
        }
      }),
      _vm.searchMode
        ? [
            _c("FondsFinderSuchMaske", {
              attrs: {
                isZertifikat: _vm.fondsArt === "ZERTIFIKATE",
                isBeteiligung: _vm.fondsArt === "GESCHLOSSENEFONDS"
              }
            }),
            _c("FondsFinderPositionsList", {
              attrs: { goBackEnabled: "", singleSelectionProp: "" },
              on: {
                goBack: function($event) {
                  return _vm.goBackButton()
                },
                getSelectedOutcome: function($event) {
                  return _vm.onselectionDone($event)
                }
              }
            })
          ]
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Wertpapierauswahl ")
                ]),
                _vm._l(_vm.fondsArtValues, function(fondsArt) {
                  return [
                    _c("BaseButton", {
                      key: fondsArt.value,
                      attrs: { label: fondsArt.label },
                      on: {
                        click: function($event) {
                          return _vm.openFormsFinder(fondsArt.value)
                        }
                      }
                    })
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _vm.rows.length && _vm.headers.length
                  ? _c("WertpapierTable", {
                      attrs: { rows: _vm.rows, headers: _vm.headers }
                    })
                  : _c("div", { staticClass: "col-12 color-danger" }, [
                      _vm._v("Bitte wählen Sie eine Position aus.")
                    ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-3 col-md-3 col-sm-3" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Anlagebetrag (Euro)",
                          type: "text",
                          validateUntouched: ""
                        },
                        model: {
                          value: _vm.INPUT_BETRAG,
                          callback: function($$v) {
                            _vm.INPUT_BETRAG = $$v
                          },
                          expression: "INPUT_BETRAG"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-3 col-md-3 col-sm-3" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Ausgabeaufschlag %",
                          type: "formpercent"
                        },
                        model: {
                          value: _vm.INPUT_AA,
                          callback: function($$v) {
                            _vm.INPUT_AA = $$v
                          },
                          expression: "INPUT_AA"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("InputRadioBoxGroup", {
                  attrs: { title: "Art", values: _vm.anlageartValues },
                  model: {
                    value: _vm.INPUT_ANLAGE_ART,
                    callback: function($$v) {
                      _vm.INPUT_ANLAGE_ART = $$v
                    },
                    expression: "INPUT_ANLAGE_ART"
                  }
                }),
                _vm.INPUT_ANLAGE_ART === "SPARPLAN" ||
                _vm.INPUT_ANLAGE_ART === "ENTNAHMEPLAN"
                  ? _c("InputRadioBoxGroup", {
                      attrs: { title: "Turnus", values: _vm.frequenzenValues },
                      model: {
                        value: _vm.INPUT_PLAN_TURNUS,
                        callback: function($$v) {
                          _vm.INPUT_PLAN_TURNUS = $$v
                        },
                        expression: "INPUT_PLAN_TURNUS"
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-lg-3 col-md-3 col-sm-3" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: "Bezug (Lagerstelle)",
                        values: _vm.bezugAuswahlValues
                      },
                      on: {
                        change: function($event) {
                          return _vm.retrieveAA($event)
                        }
                      },
                      model: {
                        value: _vm.INPUT_BEZUG_AUSWAHL,
                        callback: function($$v) {
                          _vm.INPUT_BEZUG_AUSWAHL = $$v
                        },
                        expression: "INPUT_BEZUG_AUSWAHL"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Fehler Liste",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Zurück zur Beratungsmappe"
          },
          on: {
            onCancelButton: function($event) {
              return _vm.cancelSaving()
            },
            onConfirmButton: _vm.confirmSaving
          }
        },
        [
          _vm.response
            ? _c(
                "div",
                [
                  _vm._l(_vm.response.errorTable, function(errorItem) {
                    return _c("div", { key: errorItem.value }, [
                      _c("span", {
                        class: {
                          "ampel-rot": errorItem.style == "E",
                          "ampel-gelb": errorItem.style == "W"
                        }
                      }),
                      _vm._v(
                        _vm._s(errorItem.label + ": " + errorItem.value) + " "
                      )
                    ])
                  }),
                  _vm.response.onlyWarn
                    ? _c(
                        "div",
                        [
                          _c("InputToggleSwitch", {
                            staticClass: "mt-3",
                            attrs: {
                              label: "Trotzdem speichern",
                              inLineLabel: true
                            },
                            model: {
                              value: _vm.response.noWarn,
                              callback: function($$v) {
                                _vm.$set(_vm.response, "noWarn", $$v)
                              },
                              expression: "response.noWarn"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-4 pl-0" },
        [
          !_vm.searchMode
            ? _c(
                "BaseButton",
                {
                  staticClass: "mr-2",
                  attrs: { isSecondary: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancelSaving()
                    }
                  }
                },
                [_c("span", [_vm._v("Zurück")])]
              )
            : _vm._e(),
          !_vm.searchMode
            ? _c(
                "BaseButton",
                {
                  staticClass: "mr-2",
                  attrs: { disabled: !_vm.rows.length || !_vm.INPUT_BETRAG },
                  on: {
                    click: function($event) {
                      return _vm.doSave(false)
                    }
                  }
                },
                [_c("span", [_vm._v("Fertigstellen")])]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }