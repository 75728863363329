var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "uploadModal",
          attrs: {
            modalTitle: "Datei hochladen",
            confirmDisabled: !_vm.canUpload,
            labelButtonConfirm: "Hochladen"
          },
          on: { onConfirmButton: _vm.doUpload, close: _vm.close }
        },
        [
          _c("div", [
            _vm.additionalInputs
              ? _c(
                  "div",
                  { staticClass: "grid" },
                  [
                    _vm.uploadType != _vm.upload_Type.FROM_DOKUMENTENARCHIV &&
                    _vm.cameraAvailable &&
                    _vm.allowFotoUpload
                      ? _c(
                          "FotoButton",
                          {
                            staticClass: "mb-1",
                            staticStyle: { float: "left" },
                            attrs: {
                              isSecondary: "",
                              edgeCorrection: "",
                              highQuality: "",
                              camera: "back"
                            },
                            on: { file: _vm.onFoto }
                          },
                          [_vm._v(" Fotografieren ")]
                        )
                      : _vm._e(),
                    _vm.multipleFileUpload
                      ? _c("MultiFileSelect", {
                          attrs: {
                            disabled:
                              _vm.uploadType ==
                              _vm.upload_Type.FROM_DOKUMENTENARCHIV,
                            maxFileSizeMB: _vm.maxFileSizeMB,
                            accept: _vm.accept
                          },
                          on: { input: _vm.dropFiles },
                          model: {
                            value: _vm.fileList,
                            callback: function($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList"
                          }
                        })
                      : _c("BaseFileSelect", {
                          attrs: {
                            disabled:
                              _vm.uploadType ==
                              _vm.upload_Type.FROM_DOKUMENTENARCHIV,
                            accept: _vm.accept
                          },
                          on: { files: _vm.dropFiles }
                        }),
                    _vm.additionalInputs.length
                      ? _c("UploadInputList", {
                          attrs: { inputs: _vm.additionalInputs },
                          model: {
                            value: _vm.formData,
                            callback: function($$v) {
                              _vm.formData = $$v
                            },
                            expression: "formData"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }