<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="isAnlageBeispiel ? 'Anlagebeispiel' : 'Anlageempfehlung'"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-EMAIL="toActions('email')"
      @action-PDF="toActions('pdf')"
      @action-COURTAGE="toActions('courtage')"
      @action-ORDER="toActions('order')">
    </PageHeaderTitleNavigation>

    <div class="row">
      <div class="col-12">
        <StepperForm
          ref="stepper"
          stepType="step"
          :stepperMediator="getStepperMediator()"
          :hasFertig="false"
          :selectedStepKey="selectedStepKey"
          :selectedSubstepKey="selectedSubstepKey"
          :showSaveSpinner="showSaveSpinner"
          :hideNavigationButtons="hideNavigationButtons"
          @set-step="setStep"
          @on-fertig="fertig"
          @set-substep="setSubstep"
        >
          <template #headerTemplate>
            <investment-advice-sidetext @navigate_to="navigateTo" :warnings="warnings" :hinweise="hinweise"></investment-advice-sidetext>
          </template>
          <template v-slot:contentTemplate="props">         
            <div v-if="props.data.stepKey === 'calculation-parameters'">
              <ParametersStep ref="step" :id="id" @updated="updateParameters" 
                @display_server_question='displayServerQuestion'
                @init_warnings="initWarnings" 
                @init_hinweise="initHinweise" 
                />
            </div>
            <div v-else-if="props.data.stepKey === 'my-depot'">
              <!-- <my-depot-step ref="step" :id="id" :tab="props.currentSubstep.substepKey"
                @init_warnings="initWarnings" @init_hinweise="initHinweise"></my-depot-step> -->
              <template v-if="selectedSubstepKey === 'Sparplan'">
                <InvestmentAdviceTable ref="step" :id="id" :stepName="stepNames.MYDEPOT_SPARPLAN" 
                  @init_warnings="initWarnings" @init_hinweise="initHinweise"></InvestmentAdviceTable>
              </template>
              <template v-else>
                <InvestmentAdviceTable ref="step" :id="id" :stepName="stepNames.MYDEPOT_EINMALANLAGE" 
                  @init_warnings="initWarnings" @init_hinweise="initHinweise"></InvestmentAdviceTable>
              </template>
            </div>
            <div v-else-if="props.data.stepKey === 'wealthkonzept'">
              <InvestmentAdviceTable ref="step" :id="id" :stepName="stepNames.WK" 
                @init_warnings="initWarnings" @init_hinweise="initHinweise"></InvestmentAdviceTable>
              <!-- <wealth-konzept-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></wealth-konzept-step> -->
            </div>
            <div v-else-if="props.data.stepKey === 'favoriten'">
              <InvestmentAdviceTable ref="step" :id="id" :stepName="stepNames.FAVORITES" 
                @init_warnings="initWarnings" @init_hinweise="initHinweise"></InvestmentAdviceTable>
            </div>
            <div v-else-if="props.data.stepKey === 'wertpapierauswahl'">
              <fondsfinder-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></fondsfinder-step></div>
            <div v-else-if="props.data.stepKey === 'depotoptimierung'">
              <optimierung-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></optimierung-step></div>
            <div v-else-if="props.data.stepKey === 'benchmark'">
              <benchmarking-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></benchmarking-step></div>
            <div v-else-if="props.data.stepKey === 'ergebnis-anlageallocation'">
              <ErgebnisAnlageallocation ref="step" :id="id" :tab="props.data.stepKey" 
                @init_warnings="initWarnings" @init_hinweise="initHinweise" 
                @display_server_question='displayServerQuestion'
              /></div>
            <div v-else-if="props.data.index === 8 || props.data.index === 9 || props.data.index === 10">
              <ergebnis-step ref="step" :id="id" :tab="props.data.stepKey" :subtab="props.currentSubstep.substepKey" 
                @init_warnings="initWarnings" @init_hinweise="initHinweise" 
                @display_server_question='displayServerQuestion'
              ></ergebnis-step></div>
            <div v-else-if="props.data.stepKey === 'pdf'">
              <action-pdf-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></action-pdf-step></div> 
            <div v-else-if="props.data.stepKey === 'courtage'">
              <action-courtage-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></action-courtage-step></div> 
            <div v-else-if="props.data.stepKey === 'order'">
              <action-order-step ref="step" :id="id" @init_warnings="initWarnings" @init_hinweise="initHinweise"></action-order-step></div>
            <div v-else-if="props.data.stepKey === 'email'"><ActionEmailStep  ref="step" :id="id" /></div>
          </template>
          <!-- <template v-if="selectedStepKey === 'order'" #customBottomButtons>
            <ActionOrderStepButtons 
              v-if="selectedStepKey === 'order'"
              :id="id"
            />
          </template> -->
        </StepperForm>
      </div>
    </div>
    <BaseModal ref="infoModal"
      :showCancelButton="false" 
      labelButtonConfirm="Schließen"
      @close="closeMessage"
    >
      <template v-slot:modalTitle>
        <!-- <PhInfo class="color-danger mr-2"/> -->
        {{serverModalTitle}}
      </template>
      <div>
        <div v-html="sanitize(serverModalMessage)"></div>
      </div>
    </BaseModal>
    <BaseModal ref="yesNoModal" 
      :labelButtonConfirm="serverModalYesNo.yesText"
      :labelButtonCancel="serverModalYesNo.noText"
      @onCancelButton="handleNo"
      @onConfirmButton="handleYes">
      <template v-slot:modalTitle>
        <PhInfo style="color: var(--color-danger)"/> {{serverModalYesNo.label}}
      </template>
      <div>
        <div v-html="sanitize(serverModalYesNo.text)"></div>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import StepperForm from "@/components/stepper/StepperForm.vue";
import { StepperMediator } from "@/components/stepper/utils";
import ParametersStep from './steps/ParametersStep.vue';
import FondsfavoritenStep from './steps/FondsfavoritenStep.vue';
import OptimierungStep from './steps/OptimierungStep.vue';
import BenchmarkingStep from './steps/BenchmarkingStep.vue';
import ErgebnisAnlageallocation from './steps/ErgebnisAnlageallocation.vue';
import ErgebnisStep from './steps/ErgebnisStep.vue';
import FondsfinderStep from './steps/FondsfinderStep.vue';
import ActionPdfStep from './steps/ActionPdfStep.vue';
import ActionEmailStep from './steps/ActionEmailStep.vue'
import ActionOrderStep from './steps/ActionOrderStep.vue';
import ActionCourtageStep from './steps/ActionCourtageStep.vue';
import InvestmentAdviceTable from './steps/InvestmentAdviceTable.vue'
import InvestmentAdviceSidetext from './InvestmentAdviceSidetext.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import {PhInfo} from 'phosphor-vue'
import {sanitize} from '@/helpers/string-helper.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { BROKER_LEVEL_ROLES } from '@/router/roles';

export default {
  data: function () {
    return {
      warnings: [],
      hinweise:[],
      serverModalTitle: '',
      serverModalMessage: '',
      serverModalYesNo: {},
      selectedAction: '',
      selectedStepKey: 'calculation-parameters',
      selectedSubstepKey: '',
      showSaveSpinner: false,
      refreshOutcome: false,
      insMappe: false,
    };
  },
  props: {
    id: {},
    routeStep: {},
    mappeId: '',
  },
  computed: {
    ...mapGetters({
      isAnlageBeispiel: CORE_TYPES.GETTERS.IS_ANLAGE_BEISPIEL,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      parameters: INVESTMENT_ADVICE.GETTERS.PARAMETERS,
      message: INVESTMENT_ADVICE.GETTERS.GET_MESSAGE,
      lastVisitStep: INVESTMENT_ADVICE.GETTERS.LAST_VISIT_STEP,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES
    }),
    mainParameters() {
      return this.parameters(INVESTMENT_ADVICE.STEPS.MAIN) || {};
    },
    showWealthKonzept() {
      const paran = this.mainParameters?.data?.parameterData;
      if (paran && (!paran.isWealthKonzeptEnabled || paran.vorgang.indexOf('DEPOTOPTMIERUNG') != -1 )) {
        return false
      }
      return true;
    },
    showMyDepot() {
      const paran = this.mainParameters?.data?.parameterData;
      if (paran && (paran.vorgang.indexOf('DEPOTOPTMIERUNG') != -1 )) {
        return false
      }
      return true;
    },
    stepNames() {
      return INVESTMENT_ADVICE.STEPS;
    },
    steps( ) {
      return [
        {
          stepKey: 'calculation-parameters',
          label: 'Einstellungen',
          totalProgress: 1,
          index:0
        },
        {
          stepKey: 'my-depot',
          label: 'MyDepot',
          totalProgress: 1,
          index:1,
          hidden: !this.showMyDepot,
          substeps: [
            {
              substepKey: 'Einmalanlage',
              title: 'Einmalanlage',
            },
            {
              substepKey: 'Sparplan',
              title: 'Sparplan',
            },
          ],
        },
        {
          stepKey: 'wealthkonzept',
          label: 'WealthKonzept',
          totalProgress: 1,
          index:2,
          hidden: !this.showWealthKonzept,
        },
        {
          stepKey: 'favoriten',
          label: 'Fondsfavoriten',
          totalProgress: 1,
          index:3
        },
        {
          stepKey: 'wertpapierauswahl',
          label: 'Wertpapiersuche',
          totalProgress: 1,
          index:4
        },
        {
          stepKey: 'depotoptimierung',
          label: 'Vorgaben für Depotoptimierung',
          totalProgress: 1,
          hidden: !this.mainParameters?.data?.parameterData?.depotoptimierung,
          index:5
        },
        {
          stepKey: 'benchmark',
          label: 'Benchmark',
          totalProgress: 1,
          index:6
        },
        {
          stepKey: 'ergebnis-anlageallocation',
          label: 'Anlageallokation',
          totalProgress: 1,
          index:7,
        },
        {
          stepKey: 'ergebnis-depotoptimierung',
          label: 'Depotoptimierung',
          totalProgress: 1,
          index:8,
          hidden: !this.mainParameters?.data?.parameterData?.depotoptimierung,
        },
        {
          stepKey: 'ergebnis-backtesting',
          label: 'Backtesting',
          totalProgress: 1,
          index:9,
          substeps: [
            {
              substepKey: 'Benchmarking',
              title: 'Benchmarking',
            },
            {
              substepKey: 'Details',
              title: 'Details',
            },
            {
              substepKey: 'Bestandsdepot',
              title: 'Bestandsdepot',
              hidden: ('-5' === this.mainParameters?.data?.parameterData?.bestandsdepot),
            },
            {
              substepKey: 'Performance',
              title: 'Performance',
            },
            {
              substepKey: 'Diagramme',
              title: 'Struktur-Diagramme',
            },
          ],
        },
        {
          stepKey: 'pdf',
          label: 'PDF',
          totalProgress: 1,
          hidden: this.selectedAction !== 'pdf',
          index:11,
        },
        {
          stepKey: 'courtage',
          label: 'Courtage',
          totalProgress: 1,
          hidden: this.selectedAction !== 'courtage',
          index:12
        },
        {
          stepKey: 'order',
          label: 'Order',
          totalProgress: 1,
          hidden: this.selectedAction !== 'order',
          index:13
        },
        {
          stepKey: 'email',
          label: 'E-Mail',
          totalProgress: 1,
          hidden: this.selectedAction !== 'email',
          index:14,
        },
      ]
    },
    hideNavigationButtons() {
      return this.selectedStepKey == 'ergebnis-backtesting' || this.selectedStepKey == 'pdf' 
          || this.selectedStepKey == 'order' || this.selectedStepKey == 'courtage' || this.selectedStepKey == 'email'
    },
    actions() {
      return [
        PageHeaderSimpleAction('PDF', 'PDF generieren').withDisabled(() => this.actionsDisabled('PDF')),
        PageHeaderSimpleAction('ORDER', 'Order').withDisabled(() => this.actionsDisabled('ORDER')),
        PageHeaderSimpleAction('EMAIL', 'E-Mail').withDisabled(() => this.actionsDisabled('EMAIL')),
        PageHeaderSimpleAction('COURTAGE', 'Courtage').withDisabled(() => this.actionsDisabled('COURTAGE')),
      ]
    },
  },
  components: {
    StepperForm,
    ParametersStep,
    FondsfavoritenStep,
    FondsfinderStep,
    BenchmarkingStep,   
    ErgebnisAnlageallocation,
    ErgebnisStep,    
    OptimierungStep,
    ActionPdfStep,
    ActionEmailStep,
    ActionOrderStep,
    ActionCourtageStep,
    InvestmentAdviceSidetext,
    BaseModal,
    PhInfo,
    PageHeaderTitleNavigation,
    OptionMenu,
    InvestmentAdviceTable,
  },
  methods: {
    actionsDisabled(action) {
      return action === 'PDF' ? false : !this.isCustomer;
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    async submitChanges() {
      await this.$refs?.step?.submitChanges();
    },
    displayMessage(message) {
      if (message?.show) {
        this.serverModalTitle = message?.title || 'Anlageempfehlung';
        this.serverModalMessage = message.text;
        this.$refs.infoModal.open();
      }
    },
    closeMessage() {
      this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {show: false, title: '', text: ''})
    },
    displayServerQuestion(question) {
      this.serverModalYesNo = question;
      this.$refs.yesNoModal.open();
    },
    handleNo() {
      if (this.serverModalYesNo.noURL) {
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SUBMIT_DIALOG_ANSWER, {url: this.serverModalYesNo.noURL, step: this.serverModalYesNo.step}); 
      }
      if (this.serverModalYesNo.noCb) {
        this.serverModalYesNo.noCb();
      }
    },
    handleYes() {
      if (this.serverModalYesNo.yesURL) {        
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SUBMIT_DIALOG_ANSWER, {url: this.serverModalYesNo.yesURL, step: this.serverModalYesNo.step});   
      }
      if (this.serverModalYesNo.yesCb) {
        this.serverModalYesNo.yesCb();
      }
    },
    getStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    navigateTo(place) {
      let step = this.steps.find(step=>place.includes(step.label));
      if (step) {
        this.$refs.stepper.setStepByKey(step.stepKey);
      } else {
        this.$refs['step' + this.$refs.stepper.currentStep.index].toTab(place);
      }
    },
    setStep(stepKey) { 
      if (this.selectedStepKey === stepKey) {
        return ;
      } 
      this.selectedSubstepKey = null;
      this.setStepByKey(stepKey);
    },  
    setSubstep(params) {
      this.selectedSubstepKey = params?.substepKey;
      this.setStepByKey(params?.stepKey);
    },
    async setStepByKey(stepKey) {
      this.showSaveSpinner = true;
      await this.submitChanges();
      this.initHinweise('');
      this.initWarnings('');
      if (!this.selectedSubstepKey) {
        const step  = this.steps.find(s=>s.stepKey === stepKey);
        if (step?.substeps?.length) {
          this.selectedSubstepKey = step.substeps[0].substepKey;
        }
      }
      this.selectedStepKey = stepKey;
      this.showSaveSpinner = false;
      this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.LAST_VISIT_STEP, {stepKey, substepKey: this.selectedSubstepKey})
      // selectedAction
    },
    fertig(data) {
    },
    toActions(action) {
      if(!this.steps?.length) {
        return ;
      }
      this.selectedAction = action;
      // this.$refs.stepper.setStep(this.steps.find(s=>s.stepKey === this.selectedAction).stepKey);
      this.setStep(this.steps.find(s=>s.stepKey === this.selectedAction).stepKey);
    },
    updateParameters(parameters) {      
      if (parameters.parameterData) {
        this.showStep({
          stepKey: 'depotoptimierung',
          show: parameters.parameterData.depotoptimierung,
        });

        this.showStep({
          stepKey: 'ergebnis-depotoptimierung',
          show: parameters.parameterData.depotoptimierung,
        });
        this.showStep({
          stepKey: 'wealthkonzept',
          show: parameters.parameterData.isWealthKonzeptEnabled && this.hasRoles([
            [BROKER_LEVEL_ROLES.MAKLER_VV_DARF_VERKAUFEN, BROKER_LEVEL_ROLES.MAKLER_VV_IS_ADVISOR]
          ]),
        });
      }
    },
    initWarnings(e) {
      this.warnings = e;
    },
    initHinweise(e) {
      this.hinweise = e;
    },
    showStep(params) {
    },
    addWertpapiere() {      
      if (this.$route.query?.addwp && this.id > 0 ) {
        const isinLst = this.$route.query.addwp;
        this.$route.query.addwp = '';
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.ADD_POSITIONS, {
          adviceId :this.id,
          add: {
            quelle: INVESTMENT_ADVICE.QUELLE.MANUELLESUCHE, 
            singlePositionIsin: isinLst
          }
        });
      }
    },
  },
  beforeDestroy() {
    if (Object.values(this.$refs).filter(ref => ref && ref.isOpen))
      Object.values(this.$refs).filter(ref => ref && ref.isOpen).forEach(ref => {
        ref.close();
      });
  },
  async beforeCreate() {
    if (this.$route.query?.addwp /*&& this.id > 0 */) {
      await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE, {adviceId: '-1'});   
    }
  },
  
  mounted() {
    if (this.$route.query.step) {
      this.$refs.stepper.setStep(this.steps.find(s=>s.stepKey === this.$route.query.step).stepKey);
    } else if (this.$route.query.backAction) {
      if (this.lastVisitStep.stepKey) {
        if ( {'email':1, 'order':1, 'courtage':1, 'pdf':1}[this.lastVisitStep.stepKey]) {
          this.toActions(this.lastVisitStep.stepKey);
        } else {
          this.$refs.stepper.setSubstep(this.lastVisitStep);
        }
      } else {
        this.$refs.stepper.setStep('calculation-parameters');
      }
    }
  },

  watch: {
    'id': 'addWertpapiere',
    'message': 'displayMessage',
    mainParameters(param) {
      if (this.mappeId && param.data?.anlageempfehlungId > 0 && !this.insMappe) {
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.MAPPE_INVESTMENT_ADVICE, {
          adviceId: param.data?.anlageempfehlungId,
          mappeId: this.mappeId
        }).then(() => {this.insMappe = true;});   
      }
    }
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  padding: 10px;
  background: rgb(44, 41, 41);
  height: 95%;
  box-sizing: border-box;
  margin: 5px;
}
.investmentadvice-sidetext__container {
  background-color: var(--color-workspaces-nav-background);
  color: var(--color-workspaces-nav-text);
  padding: 1em;
}
.nav-tabs {
  padding-left: 0px;
}
.ia-vertical-stepper {
  display: none;
}
@media (min-width: 992px) {
  .ia-vertical-stepper {
    display: block;
  }
}
</style>