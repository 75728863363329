var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "DBM",
          subtitle: _vm.$appNavigation.currentMenu.label
        }
      }),
      this.refreshInterval != 0 || _vm.showLoadButton
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              this.refreshInterval != 0
                ? _c(
                    "BaseButton",
                    {
                      attrs: { disabled: this.syncRun },
                      on: { click: _vm.run }
                    },
                    [_vm._v(_vm._s(_vm.buttonCol) + " ")]
                  )
                : _vm._e(),
              _vm.showLoadButton
                ? _c("BaseButton", { on: { click: _vm.handleLoad } }, [
                    _vm._v(_vm._s(_vm.loadButtonLabel) + " ")
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }