<template>
  <div
    v-if="asCalendar"
    class="date-picker-field__container input-forms__container"
  >
    <DatePickerSelectors
      :isRangePicker="isRangePicker"
      :datePickerSelectorsInline="datePickerSelectorsInline"
      :showSecondarySelector="showSecondarySelector"
      :controlsSize="controlsSize"
      :monthPicker="monthPicker"
      :highlightWeek="highlightWeek"
      :selectedDay="selectedDay"
      :selectedRange="selectedRange"
      :disabled="disabled"
      @onSelectDay="
        selectDay(dateOrRangeObject($event.date, $event.range), false)
      "
      @onSelectMonth="selectMonth($event)"
    />
  </div>
  <DatePickerDropdown
    v-else
    :label="label"
    class="date-picker-field__container input-forms__container"
    ref="datePickerDropDown"
    :disabled="disabled || inputmode != INPUTMODE_NONE"
    :isRangePicker="isRangePicker"
    @enableKeyboard="switchToKeyboard()"
    @showedPicker="$emit('showedPicker', $event)"
  >
    <template #field>
      <slot>
        <InputField
          ref="inputContainerEl"
          v-model="inputTextValue"
          class="pb-0"
          :class="{
            'date-picker-field__input-none': inputmode === INPUTMODE_NONE,
          }"
          suppressValidationMessage
          :inputmode="inputmode"
          :isComponentHalfSize="isComponentHalfSize"
          :label="label"
          :labelClass="labelClass"
          :placeholder="placeholder"
          :disabled="disabled"
          :inputClass="inputClass"
          @focus="onInputFieldFocus"
          @onBlur="$emit('onBlur')"
          @change="onChangeInputText"
          @reset="triggerValidator()"
        >
          <template v-if="inputmode !== INPUTMODE_NONE" #icon>
            <PhCalendarBlank
              :size="16"
              tabindex="-1"
              @click="switchToCalendarDropdown()"
            />
          </template>
        </InputField>
      </slot>
      <div v-if="isValidationConfigDone">
        <div
          class="input-forms__errors-container"
          :key="validation.updated"
          v-if="!suppressValidationMessage && validation"
        >
          <div
            class="fc-form-danger"
            v-for="error in validation.getErrors(
              validationPath,
              validateUntouched
            )"
            :key="error"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </template>

    <template #container>
      <div
        class="datepicker__container"
        :class="{ 'is-range-picker': isRangePicker }"
      >
        <DatePickerSelectors
          :isRangePicker="isRangePicker"
          :datePickerSelectorsInline="datePickerSelectorsInline"
          :showSecondarySelector="showSecondarySelector"
          :controlsSize="controlsSize"
          :monthPicker="monthPicker"
          :highlightWeek="highlightWeek"
          :selectedDay="selectedDay"
          :selectedRange="selectedRange"
          :disabled="disabled"
          @onSelectDay="
            selectDay(dateOrRangeObject($event.date, $event.range), false)
          "
          @onSelectMonth="selectMonth($event)"
        />
        <div class="datepicker__shortcut" v-if="showPreset">
          <div
            v-if="showDatePresets"
            class="datepicker__preset-container layout__negative-margin--8"
          >
            <a
              @click="selectDate(preset.date)"
              v-for="preset in presetDays"
              :key="preset.days"
            >
              {{ preset.label }}
            </a>
          </div>

          <div
            v-else-if="showRangePresets"
            class="datepicker__preset-container layout__negative-margin--8"
          >
            <a
              @click="selectRangeFromPreset(preset.range)"
              v-for="preset in presetPeriods"
              :key="preset.days"
            >
              {{ preset.label }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </DatePickerDropdown>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import dayjs from "dayjs";
import { rangeToDayjs, toDayjs } from "@/helpers/date-helper.js";
import validatorComponentUtils from "@/mixins/validator/validator-component-utils";
import DatePickerDropdown from "@/components/core/forms/date-picker2/DatePickerDropdown.vue";
import DatePickerSelectors from "@/components/core/forms/date-picker2/DatePickerSelectors.vue";
import { PhCalendarBlank } from "phosphor-vue";

const SOFORT_LABEL = "sofort";

const DATE_PICKER_CONTAINER_SELECTOR = ".date-picker-field__container";
const INPUTMODE_NONE = "none"; // no keyboard visible

/**
 * By default v-model works with Date() object
 *
 * it's possible to work with string by using the prop 'isValueAsString'
 *
 * it's possible to work with a range of dates. Please have a look at 'isRangePicker'
 */

export default {
  mixins: [validatorComponentUtils],
  props: {
    /**
     * shows only the datepicker selector with no field
     */
    asCalendar: {
      type: Boolean,
      default: false,
    },
    showSecondarySelector: {
      type: Boolean,
      default: false,
    },
    datePickerSelectorsInline: {
      type: Boolean,
      default: true,
    },
    controlsSize: {
      type: Number,
      default: 16,
    },
    // control the shortcuts visibility
    showPreset: {
      type: Boolean,
      default: true,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    highlightWeek: {
      type: [String, Boolean],
      default: false,
    },
    // this format is used to show the date on InputField and also as the value when 'isValueAsString' props is enabled
    dateFormat: {
      type: String,
      default: "DD.MM.YYYY",
    },
    // this format is used to show the date on InputField and also as the value when 'isValueAsString' props is enabled
    monthFormat: {
      type: String,
      default: "MM.YYYY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // for the InputField
    placeholder: {
      type: String,
      default: "",
    },
    // for the InputField
    label: {
      type: String,
    },
    // for the InputField
    labelClass: {
      type: String,
    },
    // for the InputField
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
    /**
     * It enables the range picker.
     *
     * the data comes in via :value="{ from: myFromVar, to: myToVar }"
     * and get's out via event (e.g. onSelectRange, onSelectRangeAsText).
     * Please have a look at the emits: [] var to find out more options
     *
     */
    isRangePicker: {
      type: Boolean,
      default: false,
    },
    // use String formatted instead of Date on v-model and value property
    isValueAsString: {
      type: Boolean,
      default: false,
    },
    // emits the value as null instead of "" for 'input' and 'change' events
    emptyValueAsNull: {
      type: Boolean,
      default: false,
    },
    /**
     * Prop used for v-model in case of regular DatePicker.
     *
     * You can use v-model="myVar" or the combination of :value=myVar and @input="myVar = $event".
     * Please have a look at the emits: [] var to find out more options
     *
     * Pay attention: value is an object ({ from: '<value from>', to: '<value to>', }) type when isRangePicker=true is set
     *
     */
    value: {
      type: [String, Date, Object],
      default: null,
    },
    validateUntouched: {
      type: Boolean,
      default: false,
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false,
    },
    showSofortOption: {
      type: Boolean,
      default: false,
    },
    /**
     * For range picker only:
     * when true: the first date selector only changes "from" and the second only changes "to"
     *
     * when false: it doesn't matter which selector the user clicks, the first click selects "from", the second click selects "to"
     */
    dedicatedSelector: {
      type: Boolean,
      default: false,
    },
    renderDanger: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "input",
    "change",

    "onSelectDay",
    "onSelectDayAsText",

    "onSelectMonth",
    "onSelectMonthAsText",

    "onSelectRange",
    "onSelectRangeAsText",
    "onSelectRangeFrom",
    "onSelectRangeFromAsText",
    "onSelectRangeTo",
    "onSelectRangeToAsText",
  ],
  data() {
    return {
      inputTextValue: null,
      inputmode: INPUTMODE_NONE,
      selectedDay: null,
      selectedRange: {
        from: null,
        to: null,
      },
      currentPeriodSelector: null,
      lastInputEmitted: null,
      firstValidation: true,
      INPUTMODE_NONE,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.handleInputValue(newValue);
        if (this.validateUntouched) {
          this.triggerValidator();
        }
      },
      immediate: true,
    },
  },
  computed: {
    inputClass() {
      return {
        "fc-form-danger":
          this.renderDanger ||
          (this.isValidationConfigDone &&
            this.validation.isInvalid(
              this.validationPath,
              this.validateUntouched
            )),
      };
    },
    showDatePresets() {
      return this.showPreset && !this.isRangePicker && !this.monthPicker;
    },
    showRangePresets() {
      return this.showPreset && this.isRangePicker;
    },

    presetDays() {
      const today = dayjs().utc();

      let options = [
        { label: "Heute", date: today.clone() },
        { label: "Gestern", date: today.subtract(1, "days") },
        { label: "Vor einer Woche", date: today.subtract(7, "days") },
      ];

      if (this.isSofortValid) {
        options = [{ label: SOFORT_LABEL, date: SOFORT_LABEL }, ...options];
      }

      return options;
    },
    isSofortValid() {
      return !this.isRangePicker && !this.monthPicker && this.showSofortOption;
    },
    presetPeriods() {
      const today = dayjs().utc();
      return [
        {
          label: "letzte 7 Tage",
          range: { to: today.clone(), from: today.subtract(7, "days") },
        },
        {
          label: "letzte 14 Tage",
          range: { to: today.clone(), from: today.subtract(14, "days") },
        },
        {
          label: "letzter Monat",
          range: { to: today.clone(), from: today.subtract(1, "months") },
        },
        {
          label: "letzte 3 Monate",
          range: { to: today.clone(), from: today.subtract(3, "months") },
        },
      ];
    },
  },
  methods: {
    dateOrRangeObject(date, range) {
      if (this.isRangePicker && this.dedicatedSelector) {
        return range;
      }

      return date;
    },
    onInputFieldFocus() {
      this.$setTouched();
    },
    handleInputValue(newValue) {
      if (typeof newValue === "string") {
        this.onChangeInputText(newValue, true);
      } else if (dayjs.isDayjs(newValue)) {
        this.selectDay(newValue, true);
      } else if (newValue?.getMonth && dayjs(newValue).isValid()) {
        this.selectDay(dayjs(newValue), true);
      } else if (newValue?.from || newValue?.to) {
        this.selectRangeFromPreset(rangeToDayjs(newValue), true);
      } else {
        this.resetValue();
      }
    },
    resetValue() {
      this.inputTextValue = "";
      this.selectedRange = {
        from: null,
        to: null,
      };
      this.selectedDay = null;
    },
    onChangeInputText(value, isValueFromProps) {
      this.$setDirty();
      if (this.isRangePicker) {
        this.selectRangeFromPreset(rangeToDayjs(value), isValueFromProps);
      } else {
        let date = toDayjs(value);
        if (this.isSofortValid && SOFORT_LABEL === value) {
          date = value;
        }

        this.selectDay(date, isValueFromProps);
      }
    },

    selectRangeFromPreset(range, isValueFromProps) {
      this.selectedRange = { from: null, to: null };

      this.selectRange(range, isValueFromProps);
    },
    selectDay(date, isValueFromProps) {
      if (this.isRangePicker) {
        this.selectRange(date, isValueFromProps);
      } else {
        if (this.monthPicker) {
          this.selectMonth(date, isValueFromProps);
        } else {
          this.selectDate(date, isValueFromProps);
        }
      }
    },
    selectRange(date, isValueFromProps) {
      if (date?.from || date?.to) {
        this.selectedRange = rangeToDayjs(date);
      } else if (
        (this.selectedRange.from && this.selectedRange.to) ||
        !this.selectedRange.from ||
        date.isSameOrBefore(this.selectedRange.from, "day")
      ) {
        this.selectedRange.from = date.clone?.() || null;
        this.selectedRange.to = null;
      } else {
        this.selectedRange.to = date.clone?.() || null;
      }

      const formattedRange = {
        from: this.selectedRange.from?.format?.(this.dateFormat) || "",
        to: this.selectedRange.to?.format?.(this.dateFormat) || "",
      };

      const outputRange = {
        from: this.selectedRange.from?.toDate?.() || null,
        to: this.selectedRange.to?.toDate?.() || null,
      };

      if (formattedRange.from || formattedRange.to) {
        this.inputTextValue = `${formattedRange.from} - ${formattedRange.to}`;
      } else {
        this.inputTextValue = "";
      }

      if (!isValueFromProps) {
        this.$emit("onSelectRange", outputRange);
        this.$emit("onSelectRangeAsText", formattedRange);
        this.$emit("onSelectRangeTo", outputRange.to);
        this.$emit("onSelectRangeToAsText", formattedRange.to);
        this.$emit("onSelectRangeFrom", outputRange.from);
        this.$emit("onSelectRangeFromAsText", formattedRange.from);

        const emitValue = this.isValueAsString
          ? this.inputTextValue
          : outputRange;
        this.emitInputAndChange(emitValue);
      }
    },
    selectDate(date, isValueFromProps) {
      if (this.isSofortValid && SOFORT_LABEL === date) {
        this.selectOptionSofortOption(date, isValueFromProps);
        return;
      }

      this.selectedDay = date?.clone() || null;
      this.inputTextValue = this.selectedDay?.format(this.dateFormat) || "";
      this.triggerValidator();
      this.$refs.datePickerDropDown?.close?.();
      if (!isValueFromProps) {
        this.$emit("onSelectDay", this.selectedDay?.toDate() || null);
        this.$emit("onSelectDayAsText", this.inputTextValue);

        const emitValue = this.isValueAsString
          ? this.inputTextValue
          : this.selectedDay?.toDate() || null;
        this.emitInputAndChange(emitValue);
      }
    },
    selectMonth(date, isValueFromProps) {
      if (this.monthPicker) {
        this.selectedDay = date?.clone();
        this.inputTextValue = this.selectedDay?.format(this.monthFormat) || "";
        this.triggerValidator();
        this.$refs.datePickerDropDown?.close?.();
        if (!isValueFromProps) {
          this.$emit("onSelectMonth", this.selectedDay?.toDate() || null);
          this.$emit("onSelectMonthAsText", this.inputTextValue);

          const emitValue = this.isValueAsString
            ? this.inputTextValue
            : this.selectedDay?.toDate() || null;
          this.emitInputAndChange(emitValue);
        }
      }
    },
    selectOptionSofortOption(sofortOption, isValueFromProps) {
      this.selectedDay = null;
      this.inputTextValue = sofortOption || SOFORT_LABEL;
      this.triggerValidator();
      this.$refs.datePickerDropDown?.close?.();
      if (!isValueFromProps) {
        this.$emit("onSelectDayAsText", this.inputTextValue);
        this.emitInputAndChange(this.inputTextValue);
      }
    },
    emitInputAndChange(value) {
      const { emptyValueAsNull } = this;

      const checkedValue = emptyValueAsNull && value === "" ? null : value;
      this.$emit("input", checkedValue);
      this.$emit("change", checkedValue);
    },
    triggerValidator() {
      this.$runValidator(this.selectedDay, this.firstValidation);
      this.firstValidation = false;
    },
    switchToCalendarDropdown() {
      let fallbackTimeoutId = null;

      const openModal = () => {
        clearTimeout(fallbackTimeoutId);
        this.$refs.datePickerDropDown?.openModal?.();
        visualViewport.removeEventListener("resize", openModal);
      };

      requestAnimationFrame(() => {
        this.inputmode = INPUTMODE_NONE;
        visualViewport.addEventListener("resize", openModal);
        fallbackTimeoutId = setTimeout(() => openModal(), 1000); // execute a fallback to open the dropdown when there is no virtual keyboard available
      });
    },
    switchToKeyboard() {
      const { datePickerDropDown, inputContainerEl } = this.$refs;
      const inputEl = inputContainerEl.$el.querySelector("input");

      datePickerDropDown.close();
      this.inputmode = null;
      inputEl.focus();

      const scrollToIntoView = () => {
        const rootEl = this.$el;
        const bounding = rootEl.getBoundingClientRect();
        const top = bounding.top + visualViewport.pageTop;
        const offsetY = 12;

        requestAnimationFrame(() =>
          window.scrollTo({
            top: top + bounding.height + offsetY - visualViewport.height,
            left: 0,
            behavior: "smooth",
          })
        );

        // remove event
        visualViewport.removeEventListener("resize", scrollToIntoView);
      };

      const enableInputModeNone = (event) => {
        if (this._isElementOnInputContext(event.relatedTarget)) return;

        // remove events
        visualViewport.removeEventListener("resize", scrollToIntoView); // remove resize event listener here also to avoid stacking it when there is no virtual keyboard
        inputEl.removeEventListener("blur", enableInputModeNone);

        // enable inputmode
        this.inputmode = INPUTMODE_NONE;
      };

      // add events
      visualViewport.addEventListener("resize", scrollToIntoView);
      inputEl.addEventListener("blur", enableInputModeNone);
    },
    _isElementOnInputContext(element) {
      if (!element) return false;

      const rootEl = this.$el;
      return (
        element === rootEl ||
        element.closest?.(DATE_PICKER_CONTAINER_SELECTOR) === rootEl
      );
    },
  },
  components: {
    InputField,
    DatePickerDropdown,
    DatePickerSelectors,
    PhCalendarBlank,
  },
};
</script>

<style scoped>
.datepicker__container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.datepicker__preset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  .datepicker__container.is-range-picker {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px), screen and (max-height: 575px) {
  .datepicker__container {
    justify-content: center;
  }

  /deep/ .date-picker-field__input-none input {
    caret-color: transparent;
    color: transparent;
    text-shadow: 0 0 0 var(--color-text);
  }
}

@media only screen and (max-width: 660px) {
  .datepicker__container.is-range-picker {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .datepicker__container {
    flex-direction: column;
  }
}
</style>
