<template>
  <div>

    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      @action-EDIT-ROWS="$refs.externeZugangBankConfigModal.open()" 
    />

    <div class="box__container">
        <Table v-if="!isLoading && rows.length"
            tableId="f95b040b-84d0-40d6-8182-18c041ad50e8"
            :title="TABLE_TITLE"
            :headers="headers"
            :rows="rows"
            rowId="label"
            :exportConfig="{roottext: 'Externe Zugaenge'}"
            @click-label="openExterneZugang"
        />
        <GhostLoading v-else-if="isLoading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
    </div>

    <ExterneZugangBankFFBModal ref="externeZugangBankFFBModal" :externeZugangBank="externeZugangBank"/>

    <ExterneZugangBankConfigModal ref="externeZugangBankConfigModal" :defaultList="list" @onSave="getConfig(true)" @onRestoreDefault="getConfig(true)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EXTERNE_ZUGANG_BANK_TYPES from '@/store/externeZugangBank/types';

import Table from '@/components/table2/Table.vue';
import ExterneZugangBankFFBModal from './ExterneZugangBankFFBModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ExterneZugangBankConfigModal from '@/components/externeZugangBank/ExterneZugangBankConfigModal.vue';

import { FFB, EBASE_AAB, TABLE_HEADERS } from './externeZugangBankUtils';

const TABLE_TITLE = 'Externe Zugänge Liste';
import mixin, { configMixin, } from './externeZugangBankUtils.js'

export default {
  mixins: [mixin, configMixin],
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      headers: TABLE_HEADERS,
      externeZugangBank: null,
    };
  },
  computed: {
    ...mapGetters({
      list: EXTERNE_ZUGANG_BANK_TYPES.GETTERS.LIST,
    }),
    isLoading() {
      return this.loading || this.loadingConfig;
    },
    rows() {
      return this.filterVisibleItems(this.list, this.configuredList);
    },
  },
  methods: {
    async init() {
      this.getList();
      this.getConfig(true);
    },
    async getList() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.GET_LIST);
        if (this.rows?.length === 1) {
          this.openExterneZugang(this.rows[0])
        }
      } finally {
        this.loading = false;
      }
    },
    openExterneZugang(externeZugangBank) {
      this.externeZugangBank = externeZugangBank;

      this.$nextTick(() => {
        switch(externeZugangBank?.id) {
          case FFB:
          case EBASE_AAB:
            if (Object.keys(externeZugangBank?.combos?.depotnummerCombos || {}).length === 1 
              && externeZugangBank?.combos?.depotnummerCombos[Object.keys(externeZugangBank?.combos?.depotnummerCombos)[0]]?.length === 1) {
              this.openExterneZugangBank({
                lagerstelle: externeZugangBank?.id,
                konsorte: Object.keys(externeZugangBank?.combos?.depotnummerCombos)[0], 
                depotnr: externeZugangBank?.combos?.depotnummerCombos[Object.keys(externeZugangBank?.combos?.depotnummerCombos)[0]][0]
              })
            } else {
              this.$refs.externeZugangBankFFBModal.open();
            }
            break;
          default:
            this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.OPEN_URL, externeZugangBank);
            break;
        }
      });
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Table,
    ExterneZugangBankFFBModal,
    GhostLoading,
    NoData,
    OptionMenu,
    PageHeaderTitleNavigation,
    ExterneZugangBankConfigModal,
  },
}
</script>
