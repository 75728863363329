<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation
            :title="title"
            :id="$appNavigation.currentOptionMenuId">
        </PageHeaderTitleNavigation>

        <BaseFilter :filterId="saveKey" 
            :metadata="searchMenu" :configFilter="configFilter"
            :defaultOptions="defaultOptions" 
            :openFilterOnMount="false"
            @onFilter="handleSearch"
            @onFilterZurucksetzen="$emit('onFilterZurucksetzen')">
            <slot></slot>
        </BaseFilter>

        <div class="box__container">
            <EventsFkTable :events="rows" :rowsPerPage="20" :title="title" />
        </div>
    </div>
</template>

<script>
import EventsFkTable from '@/components/broker/EventsFkTable.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { mapGetters } from 'vuex';
import MSC_NEWS_TYPES from '@/store/mscnews/types.js';
import dayjs from "dayjs";
import CORE_TYPES from '@/store/core/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export default {
    components: {
        EventsFkTable,
        PageHeaderTitleNavigation,
        BaseFilter,
        OptionMenu,
    },
    data() {
        return {
            saveKey: "fkEventsSearchAdvanced",
            configFilter: { 
                noResetOnDefaultSearchInputExit: true,
            },
            rows: [],
            defaultOptions: {
                event: '',
            },
        }
    },
    watch: {
        events(value) {
            this.handleSearch();
        }
    },
    computed: {
        ...mapGetters({
            events: MSC_NEWS_TYPES.GETTERS.FK_EVENTS,
            isFA: CORE_TYPES.GETTERS.IS_FA,
        }),
        title() {
            return this.isFA ? 'FinanzAdmin Events' : 'FondsKonzept Events'
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Events',
                            key: 'event',
                        },
                        {
                            type: 'combobox',
                            label: 'Kategorie',
                            key: 'category',
                            comboOptions: this.categories,
                        },
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'dateRange',
                        },
                    ]
                }
            ]
        },
        categories() {
            const categories = this.events?.map(event => event.category)
            .sort((a, b) => a?.localeCompare(b?.localeCompare))
            .filter((cat, pos, self) => self.indexOf(cat) === pos)
            .map(cat => { return { label: cat, value: cat }}) || [];
            
            categories.unshift({ label: 'Alle', value: ''});
            return categories;
        },
    },
    methods: {
        handleSearch(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            const cat = filters['category'];
            const text = filters['event'];
            const zeit = filters['dateRange'];

            let events = cat && this.events?.filter(event => event?.category === cat) || this.events || [];
            if (text) {
                events = events.filter(event => event && event['event_title']?.toLowerCase().includes(text.toLowerCase()));
            }
            if (zeit?.length) {
                const min = zeit.filter(elem => elem?.key === 'min')?.map(elem => elem?.value || '')?.pop();
                const max = zeit.filter(elem => elem?.key === 'max')?.map(elem => elem?.value || '')?.pop();
                const minDate = min && dayjs(min, 'DD.MM.JJJJ')?.toDate();
                const maxDate = max && dayjs(max, 'DD.MM.JJJJ')?.toDate();
                events = events?.filter(event =>(!minDate || (event.startdate && (event.startdate > minDate)))
                    && (!maxDate || event.startdate && (event.startdate < maxDate)));
            }
            this.rows = events;
        }
    },
}
</script>

<style>

</style>