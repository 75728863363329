var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          title: "Wertpapiere",
          tableId: "579b1932-470b-4522-8482-1266152082a3",
          headers: _vm.newHeaders,
          rows: _vm.records,
          rowsPerPage: _vm.records.length,
          id: _vm._generateTidFromString(
            "WERTPAPIER_NAME" + "ANTEILE" + "GESELLSCHAFT"
          ),
          hidePagination: ""
        },
        scopedSlots: _vm._u([
          {
            key: "WERT_FW",
            fn: function(row) {
              return [
                _c("CurrencyLabel", {
                  attrs: {
                    value: row.WERT_FW,
                    symb: _vm.currencyTypeToLocaleIdentifier(row)
                  }
                })
              ]
            }
          },
          {
            key: "GUV",
            fn: function(row) {
              return [
                _c("CurrencyLabel", {
                  attrs: {
                    value: row.WERT_FW.procentPA || row.WERT_FW.procent,
                    symb: "%"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }