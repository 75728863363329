<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="title" 
      :subtitle="subtitle" 
      :actions="headerActions" 
      @action-DISCARD="discardChanges()"
      @action-PDF="navigateTo('/persoenlichedaten/pdf-kundendaten')"
      @action-VCARD="navigateTo('/persoenlichedaten/vcard-kundendaten')"
      @action-COPY="openCopyCustomerModal()"
      @action-COPY-GROUP="openCopyCustomerGroupModal()"
      @action-BRIEF="navigateTo('/communication/mailcomposer-brief')"
      @action-EMAIL="navigateTo('/communication/mailcomposer')"
      @action-TICKET="navigateTo('/communication/tickets/new-ticket')"
      @action-ACTIVITY="navigateTo('/communication/postfach/termine')"
    />

    <BaseModal ref="copyCustomerModal" :showDefaultButtons="false">
      <template #modalTitle>Kunde kopieren</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer angelegt werden?
      </template>
      <template #footer>
        <BaseButton class="mr-16px" isSecondary @click="closeCopyCustomerModal()" :disabled="loading">Nein</BaseButton>
        <BaseButton @click="copyCustomer()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="copyCustomerGroupModal" :showDefaultButtons="false">
      <template #modalTitle>Gruppenoberhaupt erzeugen</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe nochmal angelegt werden?
      </template>
      <template #footer>
        <BaseButton class="mr-16px" isSecondary @click="closeCopyCustomerGroupModal()" :disabled="loading">Nein</BaseButton>
        <BaseButton @click="copyCustomerGroup()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { ROLES, VIEW_ROLES } from '@/router/roles';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    BaseModal,
    AnimatedSpinner,
  },
  props: {
    title: {
      type: String,
      default: 'Profil',
    },
    subtitle: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isNewPerson: CUSTOMERDATA_TYPES.GETTERS.IS_NEW_PERSON,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
    }),
    hasDataEdited() {
      return Object.keys(this.customerDataEdited).length > 0;
    },
    canCopyCustomerGroup() {
      return this.hasRoles([[ROLES.FA, VIEW_ROLES.VIEW_INTERN], [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]);
    },
    headerActions() {
      const { loading, hasDataEdited, isNewPerson, isBrokerOrByPass, canCopyCustomerGroup, } = this;
      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen')
          .withDisabled(() => loading || !hasDataEdited || isNewPerson),
        PageHeaderSimpleAction('PDF', 'PDF Kundendaten').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('VCARD', 'vCard Kundendaten').withDisabled(() => loading) 
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('COPY', 'Kunde kopieren').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('COPY-GROUP', 'Gruppenoberhaupt erzeugen').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass && canCopyCustomerGroup),
        PageHeaderSimpleAction('BRIEF', 'Brief aus Vorlage').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('TICKET', 'Neue Nachricht').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('EMAIL', 'Neue E-Mail').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('ACTIVITY', 'Terminübersicht').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
      ];
    },
  },
  methods: {
    discardChanges() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
    },
    navigateTo(event) {
      if(this.$route.fullPath === event) return;
      this.$router.push({ path: `${event}` });
    },
    openCopyCustomerModal() {
      this.$refs.copyCustomerModal.open();
    },
    closeCopyCustomerModal() {
      this.$refs.copyCustomerModal.close();
    },
    copyCustomer() {
      this.loading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA).then(() => {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA).then(() => {
          this.closeCopyCustomerModal();
        })
        .finally(() => {
          this.loading = false;
        });
      });
    },
    openCopyCustomerGroupModal() {
      this.$refs.copyCustomerGroupModal.open();
    },
    closeCopyCustomerGroupModal() {
      this.$refs.copyCustomerGroupModal.close();
    },
    copyCustomerGroup() {
      this.loading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA).then(() => {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA_AS_GROUP_CUSTOMER).then(() => {
          this.closeCopyCustomerGroupModal();
        }).finally(() => {
          this.loading = false;
        });
      });
    },
  },
}
</script>
