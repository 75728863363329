var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards__container" },
    [
      _c("div", { staticClass: "fondsfinder-button__container" }, [
        _vm.title
          ? _c(
              "div",
              { staticClass: "input-forms__label-content--bigger mb-3" },
              [
                _c("ph-bank", { attrs: { size: 16 } }),
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ],
              1
            )
          : _vm._e(),
        _vm.config && _vm.config.buttonText
          ? _c(
              "div",
              [
                _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                  _vm._v(_vm._s(_vm.config.buttonText))
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.hasPositions
        ? _c(
            "div",
            { staticClass: "cards__items" },
            _vm._l(_vm.positions, function(pos, index) {
              return _c(
                "div",
                {
                  key: pos.isin + index,
                  ref: "cards",
                  refInFor: true,
                  staticClass: "box__container cards__item",
                  class: _vm.getPositionClasses(pos)
                },
                [
                  _c(
                    "PageHeaderTitleNavigation",
                    _vm._g(
                      {
                        attrs: {
                          id: pos.isin + index,
                          actions: _vm.headerActions(pos),
                          noPrimaryAction: true,
                          showBackButton: false
                        },
                        on: {
                          "action-DELETE": function($event) {
                            return _vm.openModalDeleteFond(pos)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._t("title", function() {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "cards__item--header " },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.gotoFondsinfo(pos)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(pos.fondsname))]
                                          )
                                        ]
                                      )
                                    ]
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "subtitle",
                              fn: function() {
                                return [
                                  _vm._t("subtitle", function() {
                                    return [
                                      _c("div", { staticClass: "font-bold" }, [
                                        _c("span", [
                                          _vm._v("ISIN: " + _vm._s(pos.isin))
                                        ])
                                      ])
                                    ]
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      _vm.$listeners
                    )
                  ),
                  _c(
                    "div",
                    { staticClass: "cards__item--inputs" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          label: "Ordertyp",
                          isEmbedded: "",
                          value: pos.modus,
                          id: "modus" + pos.index,
                          disabled: _vm.disabled,
                          values: _vm.orderTypValues
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateModus($event, pos, "modus")
                          }
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          isEmbedded: "",
                          type: "currency",
                          label: "Anlagebetrag",
                          value: !pos.betrag ? null : pos.betrag,
                          id: "betrag" + pos.index,
                          disabled: _vm.disabled,
                          placeholder:
                            pos.modus == "SPARPLAN" ? "mind.50" : "mind.2500"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeInputField($event, pos, "betrag")
                          }
                        }
                      }),
                      pos.modus != "SPARPLAN"
                        ? _c("ComboBox", {
                            attrs: {
                              isEmbedded: "",
                              label: "Währung",
                              value: pos.waehrung,
                              id: "waehrung" + pos.index,
                              disabled: _vm.disabled,
                              values: _vm.waehrungValues
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  pos,
                                  "waehrung"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      pos.modus == "SPARPLAN"
                        ? _c("ComboBox", {
                            attrs: {
                              label: "Turnus",
                              isEmbedded: "",
                              value: pos.frequenz,
                              id: "frequenz" + pos.index,
                              disabled: _vm.disabled,
                              values: _vm.turnusValues
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  pos,
                                  "frequenz"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      pos.modus == "SPARPLAN"
                        ? _c("DatePickerField", {
                            attrs: {
                              isEmbedded: "",
                              label: "Beginn",
                              value: pos.beginn,
                              monthPicker: "",
                              id: "beginn" + pos.index,
                              disabled: _vm.disabled,
                              isValueAsString: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  pos,
                                  "beginn"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      pos.modus == "SPARPLAN"
                        ? _c("InputField", {
                            attrs: {
                              label: "Dynamisierung",
                              isEmbedded: "",
                              id: "dynamisierung" + pos.index,
                              value: pos.dynamisierung,
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  pos,
                                  "dynamisierung"
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }