var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Änderungshistorie",
          actions: _vm.pageHeaderTitleActions
        },
        on: { "action-MERGE_VERSIONS": _vm.mergeVersions }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("Table", {
                attrs: {
                  title: _vm.historyTitle,
                  rows: _vm.rows,
                  headers: _vm.headers
                },
                on: {
                  "action-AENDERUNGSLOG": _vm.loadAenderungslog,
                  "action-VERSIONLOG": _vm.showVersions,
                  "action-SIGNATUR_ZURUECKSETZEN": _vm.resetSignature
                },
                scopedSlots: _vm._u([
                  {
                    key: "bezeichnung",
                    fn: function(row) {
                      return [
                        row.hasAccess && row.dokId
                          ? _c("DownloadLink", {
                              attrs: {
                                title: row.bezeichnung,
                                downloadServicePath:
                                  "/get_aenderungshistorie_file",
                                filename: row.filename,
                                queryParams: {
                                  dokId: row.dokId
                                }
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(row.bezeichnung))])
                      ]
                    }
                  }
                ])
              })
        ],
        1
      ),
      _vm.isShowVersions || _vm.isShowLog
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.loadingSubtable
                ? _c("GhostLoading", {
                    attrs: { type: "table", config: { table: { rows: 10 } } }
                  })
                : [
                    _vm.isShowVersions
                      ? _c("Table", {
                          attrs: {
                            title: _vm.versionTitle,
                            headers: _vm.versionHeaders,
                            rows: _vm.versionRows,
                            hidePagination: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "version",
                                fn: function(row) {
                                  return [
                                    row.hasAccess && row.dokId
                                      ? _c("DownloadLink", {
                                          attrs: {
                                            title: row.version,
                                            downloadServicePath:
                                              "/get_aenderungshistorie_file",
                                            filename: row.filename,
                                            queryParams: {
                                              dokId: row.dokId,
                                              version: row.version
                                            }
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(row.version))
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2094092855
                          ),
                          model: {
                            value: _vm.selectedVersionDocs,
                            callback: function($$v) {
                              _vm.selectedVersionDocs = $$v
                            },
                            expression: "selectedVersionDocs"
                          }
                        })
                      : _vm.isShowLog
                      ? _c("Table", {
                          attrs: {
                            title: _vm.logTitle,
                            headers: _vm.logHeaders,
                            rows: _vm.logRows,
                            rowId: "aenderung",
                            hidePagination: ""
                          }
                        })
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }