var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Wertpapiersuche",
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Umtausch von:")]),
          _c("ComboBox", {
            attrs: { values: _vm.depotComboboxValues },
            on: { change: _vm.bgsNrSourceChanged },
            model: {
              value: _vm.bgsNr,
              callback: function($$v) {
                _vm.bgsNr = $$v
              },
              expression: "bgsNr"
            }
          }),
          _c("div", { staticClass: "row" }, [
            _vm.anteileInfo
              ? _c("div", { staticClass: "col-12 d-flex my-2 anteile" }, [
                  _c("span", { staticClass: "mr-3" }, [
                    _vm._v("Anteile: " + _vm._s(_vm.anteileInfo["anteile"]))
                  ]),
                  _c(
                    "span",
                    { staticClass: "mr-3" },
                    [
                      _vm._v(
                        "Anteilspreis: " +
                          _vm._s(_vm.anteileInfo["anteilspreis"])
                      ),
                      _c("ph-currency-eur", { attrs: { size: 16 } })
                    ],
                    1
                  ),
                  _c(
                    "span",
                    [
                      _vm._v(
                        "Gesamter Wert: " +
                          _vm._s(_vm.anteileInfo["gesamterWert"])
                      ),
                      _c("ph-currency-eur", { attrs: { size: 16 } })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.modus === "depotpossuche"
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Umtausch nach:")
              ]),
              _c("ComboBox", {
                attrs: { values: _vm.bgsNrTargetValues },
                model: {
                  value: _vm.bgsNrTarget,
                  callback: function($$v) {
                    _vm.bgsNrTarget = $$v
                  },
                  expression: "bgsNrTarget"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.modus === "wpsuche"
        ? [
            _c("FondsFinderSuchMaske"),
            _c("FondsFinderPositionsList", {
              ref: "fondsfinderResult",
              attrs: {
                lagerstelle: _vm.lagerstelle,
                goBackEnabled: false,
                hasDefaultButton: false,
                showMobileSelectionAlways: ""
              }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.addTauschPositions()
                }
              }
            },
            [_vm._v(" Umtauschen ")]
          )
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmAddPositions",
          attrs: {
            modalTitle: "Wertpapier hinzugefügt!",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v("Wertpapier hinzugefügt!")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptySelection",
          attrs: {
            modalTitle: "Kein Auswahl",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v("Bitte wählen Sie eine Position aus.")])]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }