var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.selectedSpartenTyp.bezeichnung,
          actions: _vm.headerActions
        }
      }),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c("ConfigEditVerticalStep", {
                  ref: "stepper",
                  attrs: {
                    configStep: _vm.steps,
                    configData: _vm.emptyData,
                    selectedStep: _vm.selectedStep,
                    disabled: _vm.isLoading,
                    actionsConfig: _vm.actionsConfig
                  },
                  on: {
                    collapsed: function($event) {
                      _vm.stepperCollapsed = $event
                    },
                    setStep: _vm.setSelectedStep,
                    setSubstep: _vm.setSelectedSubstep,
                    stepEdited: function($event) {
                      return _vm.stepEdited($event)
                    },
                    removeStep: function($event) {
                      return _vm.removeStep($event)
                    },
                    addStep: function($event) {
                      return _vm.addStep()
                    },
                    orderChanged: function($event) {
                      return _vm.onStepOrderChanged($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _vm.loading || _vm.selectedStep
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _vm.loading
                          ? _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: "120px" } }
                              }
                            })
                          : _vm.selectedStepForm.configComponents &&
                            _vm.selectedStepForm.configComponents
                          ? _c(
                              "SortableList",
                              {
                                key:
                                  (_vm.selectedStepForm.configComponents &&
                                    _vm.selectedStepForm.configComponents
                                      .length) ||
                                  0,
                                staticClass: "configs-components__sortable",
                                attrs: {
                                  items: _vm.selectedStepForm.configComponents
                                },
                                on: {
                                  orderChanged: function($event) {
                                    return _vm.onOrderChanged($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "configs-components",
                                    attrs: { "data-sortable-container": "" }
                                  },
                                  _vm._l(
                                    _vm.selectedStepForm.configComponents,
                                    function(component, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: component._uuid || component.id,
                                          staticClass:
                                            "configs-component--item",
                                          class: {
                                            active:
                                              _vm.editEnabled[
                                                component._uuid || component.id
                                              ]
                                          },
                                          attrs: { "data-sortable-item": "" }
                                        },
                                        [
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "configs-component--item-input"
                                              },
                                              [
                                                _c("AntragComponent", {
                                                  attrs: {
                                                    component: component,
                                                    data: _vm.emptyData,
                                                    disabled: "",
                                                    isComponentHalfSizeEnabled:
                                                      ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "configs-component--item-actions"
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn-clear",
                                                    attrs: {
                                                      type: "button",
                                                      disabled: _vm.isLoading,
                                                      "data-sortable-item-handler":
                                                        ""
                                                    }
                                                  },
                                                  [
                                                    _c("PhList", {
                                                      attrs: { size: 16 }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _c("BaseDropdownMenu", {
                                                ref: "dropdownMenu",
                                                refInFor: true,
                                                attrs: {
                                                  disabled: _vm.isLoading,
                                                  placement: "bottom-right"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "hook-target",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-clear clickable",
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  _vm.isLoading
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "PhDotsThreeVertical",
                                                                {
                                                                  attrs: {
                                                                    size: 16
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "default",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "configs-component--item-actions--list list"
                                                            },
                                                            [
                                                              [
                                                                _c("li", [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn-clear clickable",
                                                                      attrs: {
                                                                        type:
                                                                          "button",
                                                                        disabled:
                                                                          _vm.isLoading
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editParameter(
                                                                            index,
                                                                            component
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Bearbeiten"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ],
                                                              _c("li", {
                                                                staticClass:
                                                                  "item-divider"
                                                              }),
                                                              _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-clear clickable",
                                                                    attrs: {
                                                                      type:
                                                                        "button",
                                                                      disabled:
                                                                        _vm.isLoading
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeComponent(
                                                                          component,
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Löschen"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedStep
                          ? _c(
                              "div",
                              { staticClass: "configs-component--actions" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-clear clickable",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.insertParameter()
                                      }
                                    }
                                  },
                                  [
                                    _c("PhPlus", { attrs: { size: 16 } }),
                                    _vm._v(" neuer Parameter ")
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editParameterModal",
          attrs: {
            showDefaultButtons: false,
            modalTitle: "Parameter bearbeiten"
          }
        },
        [
          _c(
            "div",
            [
              !_vm.addingNewTab && _vm.selectedStep !== "Neue Gruppe"
                ? _c("ComboBox", {
                    attrs: {
                      label: "Tab",
                      values: _vm.tabs,
                      firstEmpty: false,
                      validateUntouched: true
                    },
                    model: {
                      value: _vm.configDefinitionForm.gruppe,
                      callback: function($$v) {
                        _vm.$set(_vm.configDefinitionForm, "gruppe", $$v)
                      },
                      expression: "configDefinitionForm.gruppe"
                    }
                  })
                : _vm._e(),
              _vm.addingNewTab || _vm.selectedStep == "Neue Gruppe"
                ? _c("InputField", {
                    attrs: { label: "Gruppe" },
                    model: {
                      value: _vm.configDefinitionForm.gruppe,
                      callback: function($$v) {
                        _vm.$set(_vm.configDefinitionForm, "gruppe", $$v)
                      },
                      expression: "configDefinitionForm.gruppe"
                    }
                  })
                : _vm._e(),
              _c("InputField", {
                attrs: { disabled: !_vm.addingNewComponent, label: "Name" },
                model: {
                  value: _vm.configDefinitionForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.configDefinitionForm, "name", $$v)
                  },
                  expression: "configDefinitionForm.name"
                }
              }),
              _c("InputTextArea", {
                attrs: { label: "Bezeichnung" },
                model: {
                  value: _vm.configDefinitionForm.bezeichnung,
                  callback: function($$v) {
                    _vm.$set(_vm.configDefinitionForm, "bezeichnung", $$v)
                  },
                  expression: "configDefinitionForm.bezeichnung"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Typ",
                  values: _vm.moeglicheTypen,
                  firstEmpty: true,
                  validateUntouched: true,
                  disabled: !_vm.addingNewComponent
                },
                on: {
                  input: function($event) {
                    return _vm.setEinheit()
                  }
                },
                model: {
                  value: _vm.configDefinitionForm.type,
                  callback: function($$v) {
                    _vm.$set(_vm.configDefinitionForm, "type", $$v)
                  },
                  expression: "configDefinitionForm.type"
                }
              }),
              _vm.configDefinitionForm.type == "TEXT_CURRENCY" ||
              _vm.configDefinitionForm.type == "TEXT_PROZENT"
                ? _c("ComboBox", {
                    attrs: {
                      label: "Einheit",
                      values: _vm.einheitTypen,
                      firstEmpty: true,
                      disabled: "",
                      validateUntouched: true
                    },
                    model: {
                      value: _vm.configDefinitionForm.einheit,
                      callback: function($$v) {
                        _vm.$set(_vm.configDefinitionForm, "einheit", $$v)
                      },
                      expression: "configDefinitionForm.einheit"
                    }
                  })
                : _vm._e(),
              _vm.configDefinitionForm.type == "TEXT_CURRENCY" ||
              _vm.configDefinitionForm.type == "TEXT_PROZENT"
                ? _c("InputField", {
                    attrs: {
                      label: "Nachkommastellen",
                      type: "number",
                      validateUntouched: true
                    },
                    model: {
                      value: _vm.configDefinitionForm.nachkommastellen,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.configDefinitionForm,
                          "nachkommastellen",
                          $$v
                        )
                      },
                      expression: "configDefinitionForm.nachkommastellen"
                    }
                  })
                : _vm._e(),
              !_vm.addingNewComponent
                ? _c("div", [_vm._v("Typ: " + _vm._s(_vm.typLabel))])
                : _vm._e(),
              (!_vm.addingNewComponent &&
                _vm.configDefinitionForm.type == "TEXT_CURRENCY") ||
              _vm.configDefinitionForm.type == "TEXT_PROZENT"
                ? _c("div", [_vm._v("Einheit: " + _vm._s(_vm.einheitLabel))])
                : _vm._e(),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Sichtbar",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.configDefinitionForm.visible,
                  callback: function($$v) {
                    _vm.$set(_vm.configDefinitionForm, "visible", $$v)
                  },
                  expression: "configDefinitionForm.visible"
                }
              }),
              _vm.configDefinitionForm.type == "COMBOBOX"
                ? _c(
                    "div",
                    { staticClass: "col-12 form-control" },
                    [
                      _c("InputField", {
                        staticClass: "align-end",
                        attrs: { label: "neuer Wert" },
                        model: {
                          value: _vm.neuerWert,
                          callback: function($$v) {
                            _vm.neuerWert = $$v
                          },
                          expression: "neuerWert"
                        }
                      }),
                      _vm.neuerWert || _vm.neuerWert.length
                        ? _c(
                            "a",
                            {
                              staticClass: "align-end",
                              staticStyle: { "flex-grow": "0" },
                              on: {
                                click: function($event) {
                                  return _vm.hinzufuegen()
                                }
                              }
                            },
                            [_c("PhPlus", { attrs: { size: 24 } })],
                            1
                          )
                        : _vm._e(),
                      !_vm.neuerWert || !_vm.neuerWert.length
                        ? _c("PhProhibit", { attrs: { size: 24 } })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tableRows.length &&
              _vm.configDefinitionForm.type == "COMBOBOX"
                ? _c("Table", {
                    staticClass: "table-container",
                    attrs: {
                      tableId: "0f6ea022-b13a-484d-a191-cd101dfa9177",
                      title: "Mögliche Werte",
                      headers: _vm.tableHeaders,
                      rows: _vm.tableRows,
                      rowsPerPage: 20,
                      count: "25"
                    },
                    on: {
                      action: function($event) {
                        return _vm.executeAction($event)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isPrimary: "",
                        disabled: !this.validation.isValid(
                          "configDefinitionForm"
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateParameter()
                        }
                      }
                    },
                    [_vm._v(" Bestätigen ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelUpdateParameter()
                        }
                      }
                    },
                    [_vm._v(" Abbrechen ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }