<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      title="Schriftverkehr"
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      @action-NEW_MAIL="handleNewEmail()"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Schriftverkehr Filter"
      filterId="PostfachSchriftverkehrFilter"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        v-model="selectedRows"
        rowId="commId"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        :headerActions="tableHeaderActions"
        :exportConfig="{roottext: 'Postfach'}"
        :mobileConfig="{title: 'name', headers: ['subject', 'date']}"
        @click-name="openCustomerNewTab($event.nummer)"
        @action-DELETE="deleteRow"
        @headerAction-BULK_DELETE="deleteSelected"
      >

        <template v-slot:subject="row">
          <DownloadLink
            v-if="!row.mobileTableContext"
            :title="row.subject"
            :disabled="!row.commId"
            downloadServicePath="/get_simple_file"
            :filename="row.subject.includes('.pdf') ? row.subject : `${row.subject}.pdf`"
            :queryParams="{
                fileId: row.commId
            }"
          />
          <div v-else>{{row.subject}}</div>
        </template>

      </Table>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, SlotColumn, ConfirmedAction, ActionColumn} from "@/components/table2/table_util.js";
import { PhPaperclip } from "phosphor-vue";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
  mixins: [postfachTableMixin],
  components: {
    Table,
    PhPaperclip,
    DownloadLink,
  },
  data() {
    return {
      category: 'schriftverkehr',
      mailboxLabel: 'Schriftverkehr',
      filterConfig: {
        placeholderForDefSearchInput: 'Betreff',
        defaultSearchInputKeys: ['subject'],
        hideFirstColumn: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
    }),
    headers() {            
      return {
        lockedLeft: [
          DateColumn("date", "Datum", undefined, 1).alignLeft(),
        ],
        center: [
          SlotColumn("subject", "Betreff", undefined, 1),
          TextColumn("name", "Name").makeConditionalLink('hasNummer').makeAlwaysVisible(),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    rows() {
      return this.records.map(record => ({
        ...record,
        subject: record.subject || "[kein Betreff]",
        hasNummer: this.canOpenCustomer(record),
        actions: this.makeActions(record),
        downloadLinkOptions: {
          downloadServicePath: "/get_simple_file",
          filename: record.subject.includes('.pdf') ? record.subject : `${record.subject}.pdf`,
          queryParams: {
            fileId: record.commId,
          },
          disabled: !record.commId,
        },
      }))
    },
    metadata() {
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
              emptyDenied: true,
            },
            {
              type: 'combobox',
              label: 'Postfach',
              key: 'postfach',
              comboOptions: [
                {
                  label: 'Eingang',
                  value: 'Eingang',
                },
                {
                  label: 'Ausgang',
                  value: 'Ausgang',
                },
              ],
            },
            {
              type: 'text',
              label: 'Name',
              key: 'name',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
              emptyDenied: true,
            },
            {
                type: 'datepicker',
                label: 'Datum',
                key: 'datum',
                emptyDenied: true,
            },
          ],
        },
      ]
    },
    headerActions() {
        return [
            PageHeaderSimpleAction('NEW_MAIL', 'Neuer Brief').withVisible(() => this.isBrokerOrBypass),
        ];
    },
    tableHeaderActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withDisabled(() => !this.selectedRows.length)
      ];
    },
  },
  methods: {
    makeActions(row) {
      let actions = []

      if (row.deletable) {
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll das Dokument "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"))
      }

      return actions
    },
    handleNewEmail() {
      if (this.isCustomer) {
        this.$router.push({ path: `/communication/mailcomposer-brief` })
      } else {
        this.$router.push({ path: `/communication/mailcomposer-serienbrief` })
      }
    },
  },
}
</script>