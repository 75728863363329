var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "broker-overview__container" },
    [
      _c("PageHeaderActionCard", { attrs: { showHeaderCard: false } }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "ef19d4b5-ec24-4968-bcda-8dcc8a5d8ae0" }
        },
        [
          _vm._m(0),
          !_vm.isSmallScreen
            ? _c("div", [
                _vm.isBrokerOrBypass
                  ? _c(
                      "div",
                      { staticClass: "layout__negative-margin--8" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: {
                              tid: "79c06e76-b793-4a67-93b8-8efe5f89d794",
                              isSecondary: ""
                            },
                            on: { click: _vm.newTask }
                          },
                          [_vm._v(" Neue Aufgabe ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "1a50adc9-d591-4604-931c-d171c1eee47a" }
        },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Aufgaben")]),
          _vm.brokerActivities
            ? _c(
                "div",
                [
                  _vm.rows.length
                    ? _c(
                        "div",
                        [
                          _c("Table", {
                            attrs: {
                              headers: _vm.headers,
                              mobileConfig: {
                                title: "Aufgaben / Termine",
                                headers: ["status", "label", "begin"]
                              },
                              rows: _vm.rows,
                              rowId: "index",
                              rowsPerPage: 20
                            },
                            on: { "click-label": _vm.openDayView }
                          })
                        ],
                        1
                      )
                    : _c("NoData", {
                        attrs: { content: "Keine Aufgaben vorhanden" }
                      })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("div", [
        _c("span", { staticClass: "box__title" }, [
          _vm._v("Aufgaben / Termine")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }