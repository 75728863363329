var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: {
            files: function($event) {
              return _vm.openAddDocumentModal($event)
            }
          }
        },
        [
          _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.parentOptionMenuId,
              defaultMenu: _vm.$appNavigation.parentOptionMenu,
              tid: "392a5ad6-ee50-4f80-b79c-ef84d0f450c8"
            }
          }),
          _c("DashboardPanel", {
            attrs: {
              id: "256017ba-71fb-4530-b3b9-c9b510987cb",
              data: _vm.dashboardData,
              canEdit: _vm.isEditable,
              defaultOptionsMenu: _vm.customOptionsMenu,
              is3Columns: "",
              headerActions: _vm.headerActions,
              noPrimaryAction: false
            },
            on: {
              executeAction: function($event) {
                return _vm.handleExecuteAction($event)
              },
              "action-BEARBEITEN": function($event) {
                return _vm.navigateTo(
                  "/persoenlichedaten/customer-data/steps/stammdaten"
                )
              },
              "action-PDF": function($event) {
                return _vm.navigateTo("/persoenlichedaten/pdf-kundendaten")
              },
              "action-VCARD": function($event) {
                return _vm.navigateTo("/persoenlichedaten/vcard-kundendaten")
              },
              "action-COPY": function($event) {
                return _vm.openCopyCustomerModal()
              },
              "action-COPY-GROUP": function($event) {
                return _vm.openCopyCustomerGroupModal()
              },
              "action-BRIEF": function($event) {
                return _vm.navigateTo("/communication/mailcomposer-brief")
              },
              "action-EMAIL": function($event) {
                return _vm.navigateTo("/communication/mailcomposer")
              },
              "action-TICKET": function($event) {
                return _vm.navigateTo("/communication/tickets/new-ticket")
              },
              "action-ACTIVITY": function($event) {
                return _vm.navigateTo("/communication/postfach/aktivitaeten")
              },
              "action-HOBBYS-VORLIEBEN": function($event) {
                return _vm.openStep("hobbys-vorlieben")
              },
              "action-COURTAGE": function($event) {
                return _vm.openStep("courtage")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.fullname) + " ")]
                },
                proxy: true
              },
              {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm.userIdUnterNr
                      ? [
                          _c("span", { staticClass: "subtitle-part" }, [
                            _vm._v(_vm._s(_vm.userIdUnterNr))
                          ])
                        ]
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "copyCustomerModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Kunde kopieren")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomer()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "copyCustomerGroupModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Gruppenoberhaupt erzeugen")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe nochmal angelegt werden? "
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeCopyCustomerGroupModal()
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.copyCustomerGroup()
                      }
                    }
                  },
                  [
                    _vm._v(" Ja "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.modalTitle,
            size: "lg",
            actions: _vm.baseModalActions,
            showCancelButton: false,
            showConfirmButton: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.saveChanges()
            },
            "action-LEGITIMATION_DELETE_IMAGES":
              _vm.gespeicherteAusweisfotosLoeschen,
            "action-LEGITIMATION_SAVE": _vm.saveLegitimation
          }
        },
        [
          _vm.editComponent == "stammdaten"
            ? _c("Stammdaten", {
                attrs: { substepProperty: _vm.substepProperty }
              })
            : _vm._e(),
          _vm.editComponent == "zusaetzliche-personen"
            ? _c("ZusaetzlichePersonen")
            : _vm._e(),
          _vm.editComponent == "kommunikationsdaten"
            ? _c("Kommunikationsdaten")
            : _vm._e(),
          _vm.editComponent == "sonstiges" ? _c("Sonstiges") : _vm._e(),
          _vm.editComponent == "bankverbindung"
            ? _c("Bankverbindung")
            : _vm._e(),
          _vm.editComponent == "arbeitgeber" ? _c("Arbeitgeber") : _vm._e(),
          _vm.editComponent == "hobbys-vorlieben"
            ? _c("HobbysVorlieben")
            : _vm._e(),
          _vm.editComponent == "schulbildung-und-qualifikationen"
            ? _c("SchulbildungUndQualifikationen")
            : _vm._e(),
          _vm.editComponent == "pep"
            ? _c("PolitischExponiertePerson")
            : _vm._e(),
          _vm.editComponent == "courtage" ? _c("Courtage") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }