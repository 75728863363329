var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Wertpapiersuche"
        }
      }),
      _c("FondsFinderSuchMaske"),
      _c("FondsFinderPositionsList", {
        ref: "fondsfinderResult",
        attrs: {
          lagerstelle: _vm.lagerstelle,
          goBackEnabled: false,
          hasDefaultButton: false,
          showMobileSelectionAlways: ""
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.addPositions()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.detailedAddPositionsButtonText) + " ")]
          )
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmAddPositions",
          attrs: {
            modalTitle: "Wertpapier hinzugefügt!",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v("Wertpapier hinzugefügt!")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptySelection",
          attrs: {
            modalTitle: "Kein Auswahl",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v("Bitte wählen Sie eine Position aus.")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }