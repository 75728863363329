<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Dokumente hinzufügen"
      isSticky
      :actions="headerActions"
      @action-SELECT-FILE="onFileChange"
      @action-RESET="resetState()"
      @action-SEND="sendDocuments()"
    />

    <div class="box__container">
      <div class="box__title">
        PDF Datei mit Barcode hochladen und verarbeiten
      </div>

      <DragnDropArea
        @files="onFileChange"
        :disabled="sendRunning || isEingang"
        hoverText="PDF Datei mit Barcode hochladen und verarbeiten"
      >
        <div class="font-bold mb-3" v-if="uploadStatus">
          {{ uploadStatus }} <AnimatedSpinner />
        </div>

        <InputToggleSwitch
          v-model="isUpdateNextBarcodes"
          @input="updateNextBarcodes(filesWithoutError[0].id, $event)"
          inLineLabel
          suppressValidationMessage
          :disabled="sendRunning || filesWithoutError.length != 1"
          label="Folgedokumente ohne Barcode anhängen"
        ></InputToggleSwitch>
        <InputToggleSwitch
          v-model="isUpdateDeckblatt"
          @input="updateDeckblatt(filesWithoutError[0].id, $event)"
          inLineLabel
          suppressValidationMessage
          :disabled="sendRunning || this.uploadedFiles.length != 1"
          label="Nur Barcodes vom Deckblatt verwenden"
        ></InputToggleSwitch>
        <InputToggleSwitch
          v-model="nurFehlerhafte"
          @input="toggledFehlerhafte"
          label="nur fehlerhafte Daten anzeigen"
          inLineLabel
          suppressValidationMessage
        />
        <InputToggleSwitch
          v-model="showDeleted"
          label="gelöschte Daten anzeigen"
          inLineLabel
          suppressValidationMessage
        />
        <InputToggleSwitch
          v-model="nurArchivieren"
          label="Dokumente nur archivieren, keine Weiterleitung"
          inLineLabel
          suppressValidationMessage
        />
      </DragnDropArea>
    </div>

    <div
      v-if="this.filesWithoutError.length && !canSend && showBarcodeControls"
      class="box__container"
    >
      <div>
        <span class="error-message">{{ errorMessage }}</span>
      </div>
    </div>

    <DragnDropArea
      @files="onFileChange"
      :disabled="isEingang"
      hoverText="PDF Datei mit Barcode hochladen und verarbeiten"
      class="card-container"
    >
      <div
        v-for="row in rows"
        :key="row.index"
        class="col-12 col-md-6 col-xl-4"
      >
        <div class="box__container card">
          <a
            v-if="!row.page.sendet"
            class="card-action"
            @click="
              togglePage(row.page.eingangId, row.page.rownum, !row.page.active)
            "
          >
            Seite {{ row.page.active ? "Löschen" : "Wiederherstellen" }}
          </a>
          <span>
            <!--a @click="showPage(row.page.eingangId, row.page.rownum)" class="card-content">
                    {{row.Bezeichnung &&row.Bezeichnung.trim().length>0 ?row.Bezeichnung:"Seite nicht anerkannt"}}
                  </a-->
            <DownloadLink
              target="_blank"
              rel="noopener noreferer"
              :title="
                row.Bezeichnung && row.Bezeichnung.trim().length > 0
                  ? row.Bezeichnung
                  : 'Seite nicht anerkannt'
              "
              downloadServicePath="/page_download"
              :queryParams="{
                eingangId: row.page.eingangId,
                pageNo: row.page.rownum,
              }"
            />
            | {{ row.Dateityp | titleCasing }}
          </span>
          <div style="padding-left: 30px; margin-top: 16px">
            <span class="card-left">Status:</span>
            <span
              class="card-content"
              :class="[
                row.page.error ? 'error-message' : '',
                row.page.active ? '' : 'page-inactive',
              ]"
              style="margin-bottom: 5px"
              >{{ row.Status }}</span
            ><br />
            <span class="card-left">Kundenname:</span>
            <a class="card-content" @click="openCustomerNewTab(row)">{{
              row.Kundenname
            }}</a
            ><br />
            <span class="card-left">Gesellschaft:</span>
            <span class="card-content">{{ row.Gesellschaft }}</span
            ><br />
          </div>
          <InputField
            :value="
              unsavedBarcodes[rowKey(row)]
                ? unsavedBarcodes[rowKey(row)].barcode
                : row.page.barcode
            "
            @change="changeBarcode(row, $event)"
            label="Barcode"
            labelClass="small-text"
            style="maxwidth: 250px"
            :disabled="sendRunning || row.page.sendet"
          />
          <InputField
            :value="
              unsavedBarcodes[rowKey(row)]
                ? unsavedBarcodes[rowKey(row)].pageNo
                : row.page.pageNo
            "
            @change="changePageIndex(row, $event)"
            label="Seite"
            labelClass="small-text"
            style="maxwidth: 250px"
            :disabled="sendRunning || row.page.sendet"
            type="number"
          />
        </div>
      </div>
    </DragnDropArea>

    <div class="box__container" v-if="this.collatedFiles.length">
      <Table
        title="Erfolgreich zugeordnete Dateien"
        :headers="collatedHeaders"
        :rows="collatedFiles"
        rowId="barcode"
        @click-kundenname="openCustomerNewTab"
      />
    </div>
  </div>
</template>

<script>
import FILEUPLOAD_TYPES from "@/store/fileUpload/types";
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from "@/helpers/log-message-helper";

import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import { uploadFileChunked } from "@/helpers/upload-helper";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import { PhMagnifyingGlass, PhTrash, PhFilePlus } from "phosphor-vue";
import CORE_TYPES from "@/store/core/types";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import Vue from "vue";

import {
  PageHeaderFileSelectAction,
  PageHeaderSimpleAction,
} from "@/components/core/header-title-navigation/page-header-utils";

export default {
  components: {
    BaseButton,
    Table,
    DragnDropArea,
    BaseFileSelect,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputToggleSwitch,
    AnimatedSpinner,
    DownloadLink,
    PhMagnifyingGlass,
    PhTrash,
    PhFilePlus,
  },
  mounted() {
    if (this.$route.query && this.$route.query.eid) {
      this.loadEingang(this.$route.query.eid);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetState();
    next();
  },
  data: () => {
    return {
      apiAddress: process.env.VUE_APP_API,
      nurArchivieren: false,
      nurFehlerhafte: false,
      isUpdateDeckblatt: false,
      isUpdateNextBarcodes: false,
      showDeleted: true,

      headers: {
        lockedLeft: [
          TextColumn("Bezeichnung", "Bezeichnung").makeAlwaysVisible(),
        ],
        center: [
          TextColumn("Gesellschaft", "Gesellschaft"),
          TextColumn("Kundenname", "Kundenname").makeLink(),
          TextColumn("Dateityp", "Dateityp"),
        ],
        lockedRight: [
          SlotColumn("Barcode", "Barcode", 200, 0),
          SlotColumn("Seite", "Seite", 50, 0),
          SlotColumn("Status", "Status", 200, 1),
          ActionColumn("actions", "Aktionen"),
        ],
      },
      collatedHeaders: {
        lockedLeft: [
          TextColumn("bezeichnung", "Bezeichnung").makeAlwaysVisible(),
        ],
        center: [
          TextColumn("gesellschaft", "Gesellschaft"),
          TextColumn("kundenname", "Kundenname").makeLink(),
          TextColumn("fileType", "Dateityp"),
        ],
        lockedRight: [
          SlotColumn("barcode", "Barcode", 200, 0),
          SlotColumn("pages", "Seiten", 50, 0),
        ],
      },
      showBarcodeControls: true,

      unsavedBarcodes: {},
    };
  },
  computed: {
    ...mapGetters({
      collatedFiles: FILEUPLOAD_TYPES.GETTERS.RESULT_TABLE,
      uploadedFiles: FILEUPLOAD_TYPES.GETTERS.PDF_UPLOADS,
      uploadStatus: FILEUPLOAD_TYPES.GETTERS.PDF_UPLOAD_STATUS,
      updatedDeckblatt: FILEUPLOAD_TYPES.GETTERS.PDF_UPDATED_DECKBLATT,
      SEND_RUN: FILEUPLOAD_TYPES.GETTERS.SEND_RUN,
    }),
    headerActions() {
      const actions = [];

      if (this.uploadedFiles?.length) {
        actions.push(
          PageHeaderSimpleAction("SEND", "Zuordnen").withDisabled(
            () => !this.canSend && !this.canRecheck
          )
        );
        actions.push(
          PageHeaderSimpleAction("RESET", "Zurücksetzen").withVisible(
            () => !this.isEingang
          )
        );
        actions.push(
          PageHeaderFileSelectAction(
            "SELECT-FILE",
            "Dokument auswählen",
            ".pdf"
          )
            .withVisible(() => !this.isEingang && !this.uploadStatus)
            .withDisabled(() => this.sendRunning)
        );
      } else {
        actions.push(
          PageHeaderFileSelectAction(
            "SELECT-FILE",
            "Dokument auswählen",
            ".pdf"
          )
            .withVisible(() => !this.isEingang && !this.uploadStatus)
            .withDisabled(() => this.sendRunning)
        );
        actions.push(
          PageHeaderSimpleAction("RESET", "Zurücksetzen").withVisible(
            () => !this.isEingang
          )
        );
        actions.push(
          PageHeaderSimpleAction("SEND", "Zuordnen").withDisabled(
            () => !this.canSend && !this.canRecheck
          )
        );
      }

      return actions;
    },
    isEingang() {
      return this.$route.query != null && this.$route.query.eid != null;
    },
    filesWithoutError() {
      return this.uploadedFiles.filter((file) => !file.error);
    },
    sendRunning() {
      return this.SEND_RUN;
    },

    canSend() {
      return this.errorMessage == null && !this.sendRunning;
    },
    canRecheck() {
      return (
        !this.sendRunning &&
        this.errorMessage &&
        this.filesWithoutError.some((file) =>
          file.barkodePages.some(
            (page) => page.error && page.active && !page.sendet
          )
        )
      );
    },
    errorMessage() {
      if (this.filesWithoutError.length < 1)
        return "Bitte laden Sie eine Datei hoch";
      if (
        !this.filesWithoutError.some((file) =>
          file.barkodePages.some((page) => page.active && !page.sendet)
        )
      )
        return "Alle seiten sind deaktiviert";
      if (
        this.filesWithoutError.some((file) =>
          file.barkodePages.some(
            (page) => page.error && page.active && !page.sendet
          )
        )
      )
        return "Es gibt Seiten mit Fehlern";
      const takenPageNos = {};
      for (let i = 0; i < this.filesWithoutError.length; i++) {
        const file = this.filesWithoutError[i];

        for (let j = 0; j < file.barkodePages.length; j++) {
          const page = file.barkodePages[j];
          if (!page.pageNo) continue;
          if (page.pageNo < 0) return "Seitenzahlen dürfen nicht negativ sein.";
          if (takenPageNos[page.pageNo + "_" + page.barcode])
            return "Zwei Seiten haben die gleiche Seitenzahl oder die Seitenzahl ist ungültig.";
          takenPageNos[page.pageNo + "_" + page.barcode] = true;
        }
      }
      return null;
    },

    rows() {
      const rows = [];
      this.filesWithoutError.forEach((file) => {
        file.barkodePages.forEach((page, index) => {
          if (
            (!this.nurFehlerhafte || page.error) &&
            (this.showDeleted || page.active)
          ) {
            rows.push({
              Deckblatt: "",
              Bezeichnung: page.bezeichnung,
              Gesellschaft: page.gesellschaft,
              Kundenname: page.kundenname,
              Dateityp: page.fileType,
              Barcode: "",
              Seite: "" + page.rownum,
              Status: page.active
                ? page.error || file.error || file.status
                : "entfernt",
              page: page,
              index,
              actions: [
                SimpleAction("PDF", PhMagnifyingGlass, "Anzeigen"),
                SimpleAction(
                  "TOGGLE",
                  page.active ? PhTrash : PhFilePlus,
                  page.active ? "Löschen" : "Wiederherstellen"
                ),
              ],
            });
          }
        });
      });
      return rows;
    },
    collatedRows() {
      return this.collatedFiles.map((file) => {
        const page =
          file.barkodePages.find((page) => page.active && !page.sendet) ||
          file.barkodePages[0];
        return {
          ...page,
          page,
          pages: file.barkodePages.filter((page) => page.active && !page.sendet)
            .length,
        };
      });
    },
  },
  methods: {
    async loadEingang(id) {
      this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.GET_EINGANG, id);
    },
    togglePage(eingangId, pageNo, active) {
      this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_TOGGLE_ACTIVE, {
        eingangId,
        pageNo,
        active,
      });
    },
    rowKey(tableRow) {
      return `${tableRow.page.eingangId}|${tableRow.page.rownum}`;
    },
    changeBarcode(tableRow, barcode) {
      if (this.nurFehlerhafte) {
        Vue.set(this.unsavedBarcodes, this.rowKey(tableRow), {
          eingangId: tableRow.page.eingangId,
          rownum: tableRow.page.rownum,
          pageNo: tableRow.page.pageNo,
          ...(this.unsavedBarcodes[this.rowKey(tableRow)] || {}),
          barcode,
        });
      } else {
        this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_CHANGE_BARCODE, {
          eingangId: tableRow.page.eingangId,
          rownum: tableRow.page.rownum,
          barcode,
          pageNo: tableRow.page.pageNo,
        });
      }
    },
    changePageIndex(tableRow, newPageId) {
      let pageNo = "";
      if (newPageId != "") pageNo = parseInt(newPageId);
      if (pageNo == "" || pageNo) {
        if (this.nurFehlerhafte) {
          Vue.set(this.unsavedBarcodes, this.rowKey(tableRow), {
            eingangId: tableRow.page.eingangId,
            rownum: tableRow.page.rownum,
            barcode: tableRow.page.barcode,
            ...(this.unsavedBarcodes[this.rowKey(tableRow)] || {}),
            pageNo,
          });
        } else {
          this.$store.dispatch(
            FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_CHANGE_BARCODE,
            {
              eingangId: tableRow.page.eingangId,
              rownum: tableRow.page.rownum,
              barcode: tableRow.page.barcode,
              pageNo,
            }
          );
        }
      }
    },
    showPage(eingangId, pageNo) {
      this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SHOW, {
        eingangId,
        pageNo,
      });
    },
    updateDeckblatt(eingangId, event) {
      if (event) {
        this.$store
          .dispatch(FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SET_DECKBLATT, eingangId)
          .then(() => {
            this.showBarcodeControls = false;
          });
      } else {
        if (this.filesWithoutError?.length && !this.canSend) {
          this.showBarcodeControls = true;
        }
      }
    },

    updateNextBarcodes(eingangId, event) {
      if (event) {
        this.$store
          .dispatch(
            FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SET_NEXT_BARCODES,
            eingangId
          )
          .then(() => {
            this.showBarcodeControls = false;
          });
      } else {
        if (this.filesWithoutError?.length && !this.canSend) {
          this.showBarcodeControls = true;
        }
      }
    },

    async onFileChange(files) {
      if (this.sendRunning) {
        return;
      }
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN, true);
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.RESULT_TABLE, []);
      if (this.isEingang) {
        return;
      }

      for (var i = 0; i < files.length; i++) {
        let file = files[i];

        let id = await uploadFileChunked(file);
        let eingangId = null;
        let upl = this.$store.getters[FILEUPLOAD_TYPES.GETTERS.PDF_UPLOADS];
        if (upl && upl.length == 1) {
          eingangId = upl[0].id;
        }
        let dat = {};
        dat.tempFileId = id;
        dat.eingangId = eingangId;
        await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.UPLOAD_PDF, dat);
      }
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN, false);
    },

    async sendDocuments() {
      if (!this.canSend && this.canRecheck) {
        this.recheckDocuments();
        return;
      }
      if (this.sendRunning) {
        return;
      }
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN, true);
      let file = this.filesWithoutError[0];

      await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.SEND_PDF, {
        eingangId: file.id,
        noSend: this.nurArchivieren,
        noCutDeckblatt: this.isUpdateDeckblatt,
      });

      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN, false);
    },

    toggledFehlerhafte(isTrue) {
      if (!isTrue) {
        this.changeBarcodesDelayed();
      }
    },

    async changeBarcodesDelayed() {
      for (const value of Object.values(this.unsavedBarcodes)) {
        // make sure the barcode was changed
        if (
          !this.rows.some(
            (tableRow) =>
              tableRow.page.eingangId == value.eingangId &&
              tableRow.page.rownum == value.rownum &&
              tableRow.page.pageNo == value.pageNo &&
              tableRow.page.barcode == value.barcode
          )
        )
          await this.$store.dispatch(
            FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_CHANGE_BARCODE,
            value
          );
      }
      this.unsavedBarcodes = {};
    },

    async recheckDocuments() {
      if (this.nurFehlerhafte) {
        // update barcodes that haven't been checked before
        await this.changeBarcodesDelayed();
      }
      if (this.canSend) {
        this.sendDocuments();
      } else if (
        this.filesWithoutError.some((file) =>
          file.barkodePages.some(
            (page) =>
              page.error == "Kein Barcode" && page.active && !page.sendet
          )
        )
      ) {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(
            "Es muss zwingend ein Barcode eingetragen werden. Die Eingabe einer Seitenzahl ist optional.",
            "danger"
          )
        );
      } else {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(
            "Es gibt noch Fehler im Dokument. Bitte korrigieren Sie diese, um das Dokument zuzuordnen",
            "danger"
          )
        );
      }
    },

    resetState() {
      this.isUpdateDeckblatt = false;
      this.isUpdateNextBarcodes = false;
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.RESET_STATE);
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN, false);
      this.$store.commit(FILEUPLOAD_TYPES.MUTATIONS.RESULT_TABLE, []);
    },
    openCustomerNewTab(row) {
      if (row?.page?.kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: row.page.kundennr,
        });
      } else if (row && row.kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: row.kundennr,
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.canSend && !this.canRecheck) {
      this.$confirmModal({
        title: "Es gibt nicht-zugeordnete Dokumente",
        message: `Möchten Sie die Seite trotzdem verlassen?`,
        labelButtonCancel: "Seite verlassen",
        labelButtonConfirm: "Dokumente zuordnen",
      }).then(() => {
        this.sendDocuments().then(() => next())
      })
      .catch(() => next())

    } else {
      next();
    }
  },
};
</script>

<style scoped>
.error-message {
  color: var(--color-danger);
}
.page-inactive {
  text-decoration: line-through;
}
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.card {
  margin-left: 12px;
  margin-right: 12px;
}
.card-left {
  width: 110px;
  display: inline-block;
  margin-left: -30px;
}
.card-action {
  float: right;
  text-align: right;
}
.card-content {
  word-break: break-word;
}
::v-deep .small-text {
  font-size: small;
}
</style>
