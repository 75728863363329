<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="depotTitle" subtitle="Kennzahlen" :actions="headerActions" />

    <HinweiseUndFehler :hints="hints" />

    <div class="box__container">
      <div class="box__title">Kennzahlen</div>

      <div v-if="loading" class="text-centered">
        <GhostLoading type="table" :config="{ table: { rows: 9, }, }" />
        <GhostLoading type="table" :config="{ table: { rows: 7, }, }" />
      </div>
      <div v-else-if="kennzahlenData.timeoutMessage" class="text-centered">
        <div>{{ kennzahlenData.timeoutMessage }}</div>
        <div class="mt-3"><BaseButton @click="refresh()" class="m-0">Aktualisieren</BaseButton></div>
      </div>
      <template v-else>
        <KennzahlenTableFrst />
        <KennzahlenTableScnd />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import KENNZAHLEN_TYPES from '@/store/kennzahlen/types';

import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import KennzahlenTableFrst from './kennzahlen/KennzahlenTableFrst.vue';
import KennzahlenTableScnd from './kennzahlen/KennzahlenTableScnd.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      depotId: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      kennzahlenData: KENNZAHLEN_TYPES.GETTERS.KENNZAHLEN_DATA,
    }),
    hints() {
      if(!this.kennzahlenData?.dataFrst?.length && !this.kennzahlenData?.dataScnd?.length) {
        return [];
      }

      const dataFrst = this.kennzahlenData?.dataFrst?.map(item => item.info)?.filter(item => item !== null);
      const dataScnd = this.kennzahlenData?.dataScnd?.map(item => item.info)?.filter(item => item !== null);
      return [].concat(dataFrst).concat(dataScnd);
    },
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
  },
  watch: {
    depotId() {
      this.findKennzahlenData(this.depotId);
    },
  },
  methods: {
    refresh() {
      this.findKennzahlenData(this.depotId);
    },
    async findKennzahlenData(depotId) {
      this.loading = true;
      await this.$store.dispatch(KENNZAHLEN_TYPES.ACTIONS.FIND_KENNZAHLEN_DATA, { depotId, });
      this.loading = false;
    },
  },
  mounted() {
    this.findKennzahlenData(this.depotId);
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,from,
    });
    next();
  },
  components: {
    HinweiseUndFehler,
    BaseButton,
    KennzahlenTableFrst,
    KennzahlenTableScnd,
    GhostLoading,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
}
</script>
