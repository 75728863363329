<template>
  <div>
    <FormLabel class="col-12" :config="{'bold': true}" label="Ich/wir wünsche/n eine Anlage in folgenden Risikoklassen laut SRI: (SRI 1 = geringstes Risiko, SRI 7 = höchste Risiko)"/>
    <div class="col-12 my-2">
      <FormLabel :config="{'small': true}" :label="isWealthKonzept ? labelSRIWealthKonzept : labelSRI"/>
    </div>

    <InputCheckBoxGroup 
      v-model="form"
      :disabled="isDisabled || isRefusedPage || isWealthKonzept"
      :values="[ 
        { label: '<b>SRI 1:</b> Wertpapiere mit sehr geringer Schwankungsbreite (kleiner als 0,5% p.a.).<br> Empfohlener Anlagehorizont mindestens 1 Jahr und länger, sehr geringe Kapitalverluste.<br>&nbsp;', 
        path: 'sri1'},

        { label: '<b>SRI 2:</b> Wertpapiere mit geringer Schwankungsbreite (zwischen 0,5% und 5% p.a.).<br> Empfohlener Anlagehorizont mindestens 3 Jahre und länger, geringe Kapitalverluste.<br>&nbsp;', 
        path: 'sri2'},

        { label: '<b>SRI 3:</b> Wertpapiere mit geringer bis mittlerer Schwankungsbreite (zwischen 5% und 12% p.a.).<br> Empfohlener Anlagehorizont mindestens 5 Jahre und länger, Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;', 
        path: 'sri3'},

        { label: '<b>SRI 4:</b> Wertpapiere mit mittlerer Schwankungsbreite (zwischen 12% und 20% p.a.).<br> Empfohlener Anlagehorizont mindestens 5 Jahre und länger, Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;', 
        path: 'sri4'},

        { label: '<b>SRI 5:</b> Wertpapiere mit erhöhter Schwankungsbreite (zwischen 20% und 30% p.a.).<br> Empfohlener Anlagehorizont mindestens 7 Jahre und länger, höhere Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;', 
        path: 'sri5'},

        { label: '<b>SRI 6:</b> Wertpapiere mit hoher Schwankungsbreite (zwischen 30% und 80% p.a.).<br> Empfohlener Anlagehorizont mindestens 10 Jahre und länger, höhere Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen.<br>&nbsp;', 
        path: 'sri6'},

        { label: '<b>SRI 7:</b> Wertpapiere mit sehr hoher Schwankungsbreite (höher als 80% p.a.).<br> Empfohlener Anlagehorizont mindestens 10 Jahre und länger, sehr hohe Verlustrisiken aus Aktien-, Zins- und Währungsschwankungen bis Totalverlust.<br>&nbsp;', 
        path: 'sri7'}
      ]"
      @checkBoxToggle="updateStoreCheckBoxGroup('riskRange', $event)"/>
    <InputField v-if="!isWealthKonzept"
      label="Beschreibung der Risikobereitschaft (Risikokennzahl) mit eigenen Worten falls obige Fallgruppen nicht ausreichend sind"
      v-model="form.annotationRisikobereitschaft"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
       @change="updateStore('riskRange', 'annotationRisikobereitschaft', $event)" />
    <InputRadioBoxGroup 
      title="Bedarf an Liquidität bei bestimmten Anlagen"
      v-model="form.liquidity" 
      :disabled="isDisabled || isRefusedPage"
      :values="radioBtns"
       @input="updateStore('riskRange', 'liquidity', $event)" />
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from "@/mixins/validator";

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputField,
    InputCheckBoxGroup,
    InputRadioBoxGroup,
    FormLabel,
  },
  data() {
    return {
      form: {},
      radioBtns: [ {
        label: 'Ich/wir bestätige(n), dass kein zusätzlicher Liquiditätsbedarf zu den bestehenden oder neu zu investierenden Anlagen,'
          + ' wie z.B. einer möglichen Nachschusspflicht bei bestehenden Anlagen besteht. Ferner besteht auch kein bereits bekannter'
          + ' zusätzlicher Liquiditätsbedarf, der sich z.B. aus dem Eintritt in die Rente, Finanzierung einer Ausbildung von'
          + ' Familienangehörigen, Renovierungskosten für Immobilien etc. ergeben könnte.',
        value: '1'
      },
      {
        label: 'Ich/wir bestätige(n), dass ein bestehender zusätzlicher Liquiditätsbedarf in den Anlagen über die Anlageziele oder'
          + ' Anlagedauer, bzw. den weiteren Anlagen bereits berücksichtig ist.',
        value: '0'
      }],
      labelSRI: 'Der Vermittler/Berater und der/die Kunde/n haben die mögliche Anlage in sieben Risikoklassen laut SRI* eingeteilt, wobei SRI 1 geringstes Risiko und SRI 7 höchstes Risiko bedeutet.',
      labelSRIWealthKonzept: 'Eventuell abweichend von Ihren möglichen bisherigen Angaben zu den Risikoklassen für den Erwerb von Wertpapieren, '
						+ 'gemäß einem vorherigen oder zusätzlichen Anlegerprofil, gelten folgende Risikoklassen. Der Vermittler/Berater und der/die Kunde/n haben die möglichen '
						+ 'Wertpapiere für eine Anlage in eine Wealthkonzept Vermögensverwaltungsstrategie in sieben Risikoklassen laut SRI eingeteilt.',
    };
  },
  computed: {
    ...mapGetters({
      data: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'risikobereitschaft');
    },
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
      this.form = {
        sri1: this.data.riskRange.sri1,
        sri2: this.data.riskRange.sri2,
        sri3: this.data.riskRange.sri3,
        sri4: this.data.riskRange.sri4,
        sri5: this.data.riskRange.sri5,
        sri6: this.data.riskRange.sri6,
        sri7: this.data.riskRange.sri7,
        annotationRisikobereitschaft: this.data.riskRange.annotationRisikobereitschaft || '',
        liquidity: this.data.riskRange.liquidity || '',
      };
    },
  },
}
</script>

<style scoped>
</style>