var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c("Table", {
                ref: "emailListe",
                staticClass: "table-container",
                attrs: {
                  title: _vm.TABLE_TITLE,
                  tableId: "99c65275-ee0b-45c9-9313-96fb26f7515b",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 25
                },
                on: { "click-datum": _vm.viewEmail },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "status",
                      fn: function(row) {
                        return [
                          _c("div", [_vm._v(" " + _vm._s(row.status) + " ")])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1053750078
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }