import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

import { ROLES, } from '@/router/roles';

import { PrimaryMenu, MenuItem, GroupMenu, TabMenu, ConditionalLabelsBuild, AsyncMenu, } from './../menu-utils';

import { PhGitFork, } from 'phosphor-vue';

import UndrawDashboard from '@/components/icons/undraw/UndrawDashboard.vue'
import UndrawContentTeam from '@/components/icons/undraw/UndrawContentTeam.vue'
import UndrawProfile from '@/components/icons/undraw/UndrawProfile.vue'
import UndrawNewspaper from '@/components/icons/undraw/UndrawNewspaper.vue'
import UndrawPreferences from '@/components/icons/undraw/UndrawPreferences.vue'
import UndrawAuthenticate from '@/components/icons/undraw/UndrawAuthenticate.vue'
import UndrawCreativity from '@/components/icons/undraw/UndrawCreativity.vue'
import UndrawDetailedAnalysis from '@/components/icons/undraw/UndrawDetailedAnalysis.vue'
import UndrawOnlineFriends from '@/components/icons/undraw/UndrawOnlineFriends.vue'
import UndrawTeamChat from '@/components/icons/undraw/UndrawTeamChat.vue'
import UndrawInvest from '@/components/icons/undraw/UndrawInvest.vue'
import UndrawReviewedDocs from '@/components/icons/undraw/UndrawReviewedDocs.vue'
import UndrawGoals from '@/components/icons/undraw/UndrawGoals.vue'
import UndrawDiary from '@/components/icons/undraw/UndrawDiary.vue'
import UndrawNewsletter from '@/components/icons/undraw/UndrawNewsletter.vue'
import UndrawPersonalInformation from '@/components/icons/undraw/UndrawPersonalInformation.vue'
import UndrawSecureLogin from '@/components/icons/undraw/UndrawSecureLogin.vue'
import UndrawSwitches from '@/components/icons/undraw/UndrawSwitches.vue'
import UndrawTransferFiles from '@/components/icons/undraw/UndrawTransferFiles.vue'
import UndrawMarketing from '@/components/icons/undraw/UndrawMarketing.vue'
import UndrawSettings from '@/components/icons/undraw/UndrawSettings.vue'
import UndrawEmployee from '@/components/icons/undraw/UndrawEmployee.vue'
import UndrawBipro from '@/components/icons/undraw/UndrawBipro.vue'
import UndrawVideoFiles from '@/components/icons/undraw/UndrawVideoFiles.vue'
import UndrawPitching from '@/components/icons/undraw/UndrawPitching.vue'
import UndrawReceipt from '@/components/icons/undraw/UndrawReceipt.vue'
import UndrawEmptyCart from '@/components/icons/undraw/UndrawEmptyCart.vue'
import UndrawCheckboxes from '@/components/icons/undraw/UndrawCheckboxes.vue';

export default PrimaryMenu('/intern', 'Interner Bereich', PhGitFork, [
  MenuItem('/intern/vermittlerdaten', 'Vermittlerdaten', UndrawProfile, [
    TabMenu('/intern/vermittlerdaten/vermittlerdaten', 'Stammdaten'),
    TabMenu('/intern/vermittlerdaten/email', 'E-Mail'),
    TabMenu('/intern/vermittlerdaten/bankverbindung', 'Bankverbindung'),
    TabMenu('/intern/vermittlerdaten/courtage', 'Courtage'),
    TabMenu('/intern/vermittlerdaten/facsimile', 'Facsimile'),
    TabMenu('/intern/vermittlerdaten/zulassung', 'Zulassung'),
    TabMenu('/intern/vermittlerdaten/vertragsdaten', 'Vertragsdaten'),
    TabMenu('/intern/vermittlerdaten/activities-termine', 'Aufgaben / Termine'),
    TabMenu('/intern/vermittlerdaten/aenderungslog', 'Änderungslog'),
    TabMenu('/intern/vermittlerdaten/stornoreserve', 'Stornoreserve'),
    TabMenu('/intern/vermittlerdaten/bemerkungen', 'Bemerkungen'),
    TabMenu('/intern/vermittlerdaten/besuchsberichte', 'Besuchsberichte und Bemerkungen'),
    TabMenu('/intern/vermittlerdaten/sonstiges', 'Sonstiges'),
  ]),
  MenuItem('/intern/dashboard-view', 'Vermittler Dashboard', UndrawDashboard),
  MenuItem('/intern/homepageselection', 'Homepage', UndrawContentTeam),
  MenuItem('/intern/news-msc', 'News', UndrawNewspaper),
  MenuItem('/intern/marketing-campaign', 'Marketing Kampagne', UndrawMarketing),
  MenuItem('/intern/mailvorlage', 'Vorlagen <nobr>Briefe / E-Mails</nobr>', UndrawPreferences, [
    TabMenu('/intern/mailvorlage/list', 'Vorlagen <nobr>Briefe / E-Mails</nobr>'),
    TabMenu('/intern/mailvorlage/template-background', 'PDF für Hintergrund Briefvorlage'),
  ]),
  MenuItem('/intern/antragsliste-versicherungen', 'Antragsliste Versicherungen', UndrawCheckboxes),
  MenuItem('/intern/spartentypList', 'Spartentypen bearbeiten', UndrawSwitches ),
  MenuItem('/intern/change-password', 'Passwort ändern', UndrawAuthenticate),
  MenuItem('/intern/berichte/risikopruefung-ergebnis', 'Regelmäßige Risikoprüfung'),
  MenuItem('/intern/negativerklaerung', 'Zuverlässigkeits&shy;erklärung', UndrawPreferences),
  MenuItem('/intern/edit-layout', 'Layout bearbeiten', UndrawCreativity),
  MenuItem('/intern/berichte/status-ffb-video-idents', 'Status VideoIdents'),
  MenuItem('/intern/makler-statistiken', 'Vermittler Statistiken', UndrawDetailedAnalysis, [
    TabMenu('/intern/makler-statistiken/allgemein', 'Allgemein'),
    TabMenu('/intern/makler-statistiken/investment-fonds', 'Investment Fonds'),
    TabMenu('/intern/makler-statistiken/beteiligung', 'Beteiligung'),
    TabMenu('/intern/makler-statistiken/versicherung', 'Versicherung'),
    TabMenu('/intern/makler-statistiken/courtagen', 'Courtagen'),
    TabMenu('/intern/makler-statistiken/mitarbeiter', 'Mitarbeiter'),
  ]),
  MenuItem('/intern/berichte/steuer-daten', 'Steuerliche Daten'),
  MenuItem('/intern/ansprechpartner', 'Mitarbeiter', UndrawEmployee),
  MenuItem('/intern/settings', 'Zugangsverwaltung', UndrawPreferences, [
    TabMenu('/intern/settings/access-management', 'Zugangsverwaltung', UndrawSecureLogin),
    TabMenu('/intern/settings/change-2fa-interval', '2FA Intervall ändern', UndrawSecureLogin),
    TabMenu('/intern/settings/two-factor-sms-option', 'Zwei-Faktor-Authentifizierung per SMS (kostenpflichtig)'),
  ]),
  MenuItem('/intern/ansprechpartner-daten', 'Mitarbeiterdaten'),
  MenuItem('/intern/mitarbeiterstruktur', 'Vermittlerstruktur', UndrawOnlineFriends),
  GroupMenu('/intern/aufsichtsrechtliches', 'Aufsichtsrechtliches', UndrawPitching, [
    MenuItem('/intern/aufsichtsrechtliches/geldwaeschebelehrung', 'Geldwäsche&shy;belehrung', UndrawPitching),
    MenuItem('/intern/aufsichtsrechtliches/wag-pruefung', 'WAG-Prüfung', UndrawPitching),
    MenuItem('/intern/aufsichtsrechtliches/geldwaeschebelehrungmitarbeiter', 'Geldwäsche&shy;belehrung  Mitarbeiter', UndrawPitching),
    MenuItem('/intern/aufsichtsrechtliches/jahrescheckliste', 'Vertriebspartner Jahrescheckliste', UndrawPitching),
    MenuItem('/intern/aufsichtsrechtliches/jahrescheckliste-auswertung', 'Jahrescheckliste Auswertung', UndrawDetailedAnalysis),
    MenuItem('/intern/aufsichtsrechtliches/persoenliches-geschaeft', 'Persönliches Geschäft', UndrawPitching),
  ]).withAutoSelectFirst(),
  MenuItem('/intern/geldwaeschebelehrungmitarbeiter', 'Geldwäsche&shy;belehrung  Mitarbeiter', UndrawOnlineFriends),
  MenuItem('/intern/datenschutzschulungmitarbeiter', 'Datenschutz&shy;schulung Mitarbeiter', UndrawOnlineFriends),
  MenuItem('/intern/geldwaeschebelehrung', 'Geldwäsche&shy;belehrung', UndrawOnlineFriends),
  MenuItem('/intern/datenschutzschulung', 'Datenschutz&shy;schulung', UndrawOnlineFriends),
  MenuItem('/intern/email-access', '<nobr>E-Mail</nobr> Zugang', UndrawNewsletter),
  MenuItem('/intern/expost', 'ExPost', UndrawTeamChat),
  MenuItem('/intern/maklerauftrag', 'Maklerauftrag Versicherungen', UndrawInvest),
  MenuItem('/intern/abrechnung', 'Abrechnungen', UndrawReviewedDocs),
  MenuItem('/intern/courtageliste', 'Courtagetabellen', UndrawReviewedDocs, [
    TabMenu('/intern/courtageliste/courtagetabelle-investment/offene-wertpapiere', 'Offene Wertpapiere', UndrawReviewedDocs),
    TabMenu('/intern/courtageliste/courtagetabelle-investment/beteiligungen', 'Beteiligungen', UndrawReviewedDocs),
    TabMenu('/intern/courtageliste/courtagetabelle-versicherung', 'Versicherung', UndrawReviewedDocs),
  ]),
  MenuItem('/intern/gdv', ConditionalLabelsBuild().add('OMDS Datenimport', [ROLES.FA]).add('GDV Datenimport').build(), UndrawReviewedDocs, [
    TabMenu('/intern/gdv/gdv-import-monitor', 'Dateien in Verarbeitung', UndrawReviewedDocs),
    TabMenu('/intern/gdv/import', 'Datenimport', UndrawReviewedDocs),
    TabMenu('/intern/gdv/gdv-interfaces', 'Interface Verwalten', UndrawReviewedDocs),
  ]),
  GroupMenu('/intern/bipro', 'BiPRO', UndrawBipro, [
    MenuItem('/intern/bipro/documents', 'BiPRO Dokumente', UndrawBipro),
    MenuItem('/intern/bipro/gdv-files', ConditionalLabelsBuild().add('BiPRO <nobr>OMDS-Dateien</nobr>', [ROLES.FA]).add('BiPRO <nobr>GDV-Dateien</nobr>').build(), UndrawBipro),
    MenuItem('/intern/bipro/exceptions', 'Festlegung der BiPRO Ausnahmen', UndrawBipro),
    MenuItem('/intern/bipro/download_overview', 'BiPRO Download Zusammen&shy;fassungen', UndrawBipro),
    MenuItem('/intern/bipro/lieferungen', 'BiPRO Lieferungsübersicht', UndrawBipro),
    MenuItem('/intern/bipro/abrechnungen_courtage', 'BiPRO Provisions&shy;abrechnungen', UndrawBipro),
    MenuItem('/intern/bipro/mahnungen', 'BiPRO Mahn- / Dynamik- / Aktionslisten', UndrawBipro),
    MenuItem('/intern/bipro/biProGesellschaftZuordnen', 'Zuordnung Gesellschaften zu BiPRO Zugängen', UndrawBipro),
    MenuItem('/intern/bipro/categories', 'Feststellung der BiPRO Kategorien', UndrawBipro),
  ]).withAutoSelectTo('/intern/bipro/documents'),
  
  GroupMenu('/intern/wirtschaftspruefung', 'Wirtschaftsprüfung', UndrawGoals, [
    MenuItem('/intern/wirtschaftspruefung/vertrag-wirtschaftspruefung', 'Vertrag Wirtschaftsprüfung', UndrawGoals), 
    MenuItem('/intern/wirtschaftspruefung/informationen-pruefbericht', 'Informationen zum Prüfbericht', UndrawGoals),
  ]).withAutoSelectTo('/intern/wirtschaftspruefung/vertrag-wirtschaftspruefung'),

  MenuItem('/intern/ansprechpartner-facsimile', 'Facsimile', UndrawReviewedDocs),
  MenuItem('/intern/pinlist', 'Freischaltungen mit PIN', UndrawReviewedDocs),
  MenuItem('/intern/mydepot', 'MyDepot', UndrawDiary),
  MenuItem('/intern/fondsfavoritenliste', 'Favoritenliste Wertpapiere', UndrawReviewedDocs),
  MenuItem('/intern/fondsantragcheck', 'Fondsanträge prüfen', UndrawOnlineFriends),
  MenuItem('/intern/marktberichtUploaden', 'Marktbericht Uploaden'),
  GroupMenu('/intern/configs', 'Einstellungen', UndrawSettings, [
    MenuItem('/intern/configs/fee-properties', ConditionalLabelsBuild().add('Dienstleistungsvertrag', [ROLES.FA]).add('Honorar / Serviceleistung Einstellungen').build()),
    MenuItem('/intern/configs/risikoklassen-definition', 'Einstellungen Anlegerprofil'),
    MenuItem('/intern/configs/makler34f', 'Gesellschaften Kundenerst&shy;information / Rahmenvertrag'),
    MenuItem('/intern/configs/makler-texte', 'Beratungs&shy;dokumentation Versicherungen Textbausteine'),
    MenuItem('/intern/configs/externe-zugaenge', 'Externe Zugänge einrichten'),
    MenuItem('/intern/configs/configuration', 'Konfiguration'),
    MenuItem('/intern/configs/calendar-settings', 'Terminkalender'),
    MenuItem('/intern/configs/change-2fa-interval', '2FA Intervall ändern', UndrawSecureLogin),
    AsyncMenu(CONFIG_DEFINITION_TYPES.ACTIONS.FIND_CONFIG_DEFINITION_OPTIONS_MENU, 'Einstellungen Asynchrone Menüs'),
  ]),
  MenuItem('/intern/notwendige-unterlagen', 'Notwendige Unterlagen', UndrawPreferences),
  MenuItem('/intern/dcs-synchronisation','DCS Synchronisation'),
  MenuItem('/intern/configs-edit', 'Einstellungen bearbeiten'),
  MenuItem('/intern/nafi-zugang', 'Makler bei Nafi anlegen'),
  MenuItem('/intern/mustervorlagen', 'Mustervorlagen Bestandsfreigaben / -übertragungen'),
  MenuItem('/intern/internestatistiken', 'Interne Statistiken'),
  MenuItem('/intern/mrmoney', 'Mr. Money <nobr>PDF-Box</nobr>', UndrawOnlineFriends),
  MenuItem('/intern/infobriefe', 'Infobriefe', UndrawPersonalInformation),
  MenuItem('/intern/versicherungstarife', 'Versicherungstarife', UndrawOnlineFriends),
  // produkte requires fondsfinder; fondsfinder does currently not work at all for intern sessions / intern queryUser
  // MenuItem('/intern/vv', 'Vermögens&shy;verwaltung', null, [
  //   TabMenu('/intern/vv/strategien', 'Strategien'),
  //   TabMenu('/intern/vv/produkte?categoryId=vv_produkte', 'Produktliste'),
  // ]),
  MenuItem('/intern/suitability-test', 'Suitability Test', UndrawReviewedDocs),
  MenuItem('/intern/eingegangene-dokumente', 'Eingegangene Dokumente', UndrawReviewedDocs),
  MenuItem('/intern/vublock', 'Versicherung Block-Bestandsübertrag', UndrawTransferFiles),
  MenuItem('/intern/build-info', 'MSC3 Build Info'),
  MenuItem('/intern/gesellschaften', 'Gesellschaften', UndrawTransferFiles),
  MenuItem('/intern/ins_zip_file', 'Versicherungsdokumente hochladen/zuordnen', UndrawOnlineFriends),
  MenuItem('/intern/infofilme', 'Infofilme / Webinare', UndrawVideoFiles),
]);
