<template>
  <div>
    <DragnDropArea hoverText="Datei hier ablegen" @files="openAddVersicherungPage($event)" :disabled="isBroker">
      <InsuranceOptionMenu 
        v-if="isCustomer" 
        :headerActions="headerActions" 
        @action-ADD="navigateToAuswahlVertragsart()"
        @action-VERSICHERUNG_KUENDIGUNG="handleVersicherungKuendigung()"
        @action-KUENDIGUNGSMAIL="handleMailComposerCancelInsurance()"
      />
      <div v-else>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
          :id="$appNavigation.currentOptionMenuId"
          :title="$appNavigation.currentMenu.label">
        </PageHeaderTitleNavigation>
      </div>

      <BaseFilter
        ref="baseFilter"
        filterId="Versicherungssuche"
        showSaveButton
        title="Versicherungssuche"
        saveKey="customerSearchAdvanced" 
        :metadata="searchMetadata"
        hasSmartSearch
        :configFilter="configFilter"
        :defaultOptions="defaultOptions"
        :openFilterOnMount="false"
        @onFilter="handleSearch($event)"
        @storedFiltersLoaded="initSearch($event)"
        @onFilterZurucksetzen="insuranceCancelingViewEnabled = false"
        @changeSubFilter="handleFilterChanges"
      >
      </BaseFilter>
      <HandleTablePin keyOfPIN="TABELLE_VERSICHERUNGEN" />

      <div v-if="filtered" class="box__container" tid="6eb27d9e-918c-4dc5-91bb-53b6a3f21b42">
        <InsuranceGroupTable 
          ref = "versicherungsliste"
          tableId="154e6a24-0fe9-404c-bd2d-625cf9358c41"
          :headers="headers"
          :pages="pages"
          :count="insurancesCount"
          :pageCount="pageCount"
          :pageSize="rowsPerPage"
          :menuRowsPerPage="menuRowsPerPage"
          :exportConfig="exportConfig"
          :isLoading="isLoading && !isScrollLoading"
          :selected="selectedRows"
          :selectionAsBlacklist="selectionAsBlacklist"
          :backAction="backAction && backAction.action"
          :headerActions="tableHeaderActions"
          :insuranceCancelingViewEnabled="insuranceCancelingViewEnabled"
          :mobileConfig="{ title: 'bezeichnung', headers: ['gesellschaftText', 'status', 'nummer'] }"
          dragnDropFilesOnRow
          @selected="onSelect"
          @selectionAsBlacklist="selectionAsBlacklist = $event"
          @rowsPerPage="changeRowsPerPage"
          @action="handleInsuranceAction($event)"
          @requestPage="requestPage"
          @onSort="sortDataByColumn($event)"
          @row-files="handleRowFiles($event)"
          @item-selected="openItemSelectedOverview($event)"
          @headerAction-VERTRAG-VEREINIGEN="openVertragVereinigenModal()" 
          @headerAction-MAIL-COMPOSER-COMPANY="handleMailComposerCompany()"
          @onScroll="onScroll"
          @scrollLoading="scrollLoading = $event"
          :mitZuordnenAktion="mitZuordnenAktion"
          @zuordnenAktion="handleInsuranceAction($event)"
        />
      </div>
      <ShowDeeplinksModal ref="showDeeplinksModal" @open-item="openItemSelectedDetail"></ShowDeeplinksModal>   
    </DragnDropArea>

    <DeleteInsuranceModal ref="deleteModal" :insurance="insuranceToDelete" :insuranceLabel="getInsuranceLabel(insuranceToDelete)"
      @delete="removeInsurance()">
    </DeleteInsuranceModal>

    <VertragVereinigenModalNew ref="vertragVereinigenModalNew" :vertragList="selectedAndLoaded" @save="mergeInsurances($event)"/>

    <VertragVereinigenModal ref="vertragVereinigenModal" @save="mergeInsurances($event)"/>

    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend">
      <InsuranceCompanyMailContacts :records="mailUsers" @selectedRows="onSelectedRow" />
    </BaseModal> 
  </div>
</template>

<script>
import { MENU_ROWS_PER_PAGE } from '@/components/table2/table_util';
import { PhPencilLine } from 'phosphor-vue';
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import InsuranceGroupTable from '@/components/versicherungen/InsuranceGroupTable.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import CORE_TYPES from '@/store/core/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import BROKERDATA_TYPES from '@/store/brokerData/types';
import InsuranceOptionMenu from './InsuranceOptionMenu.vue';
import DeleteInsuranceModal from './DeleteInsuranceModal.vue'
import VertragVereinigenModalNew from './VertragVereinigenModalNew.vue';
import VertragVereinigenModal from './VertragVereinigenModal.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import InsuranceCompanyMailContacts from '@/views/versicherungen/InsuranceCompanyMailContacts.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import HandleTablePin from '@/components/core/HandleTablePin.vue';
import ShowDeeplinksModal from '@/views/versicherungen/ShowDeeplinksModal.vue'
import dayjs from "dayjs";
import EVENT_BUS, { REFRESH_INSURANCE_TABLE } from '@/event-bus';

import {
    prepareFilters,
    searchMenu,
    headers,
} from './insuranceSearchConstants';
import insuranceGroupMixin from './insurance-group-mixin';
import ROLES, { VIEW_ROLES }from '@/router/roles';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [insuranceGroupMixin],
	data() {
		return {
            searchMetadata: {},
            filtered: false,
            insuranceToDelete: null,
            defaultOptions: {
                inklInaktive: {
                    value: true,
                },
            },
            defaultFilters: [
                {
                    group: "Allgemein",
                    key: "inklInaktive",
                    value: true,
                },
            ],
            filtersObj: {},
            mailUsers: [],
            insuranceCancelingViewEnabled: false,
            isScrollLoading: false,
            mitZuordnenAktion: false,
            menuRowsPerPage: MENU_ROWS_PER_PAGE,
        }
    },
  computed: {
    ...mapGetters({
      insurancesFiltered: VERSICHERUNG_TYPES.GETTERS.INSURANCES_FILTERED,
      insuranceFiltersData: VERSICHERUNG_TYPES.GETTERS.INSURANCE_FILTERS_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      mailLinkedObject: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    rowsPerPage() {
      return this.tablePageSize <= 0 ? 25 : this.tablePageSize;
    },
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    headerActions() {
      const { isCustomer, isSuperCustomer} = this;

      let actions = [];

      if(this.insuranceCancelingViewEnabled) {
        return [
           PageHeaderSimpleAction('KUENDIGUNGSMAIL', 'Kündigungsmail')
           .withDisabled(() => this.numberSelected == 0),
          PageHeaderSimpleAction('ADD', 'Versicherung hinzufügen')
        ];
      }
      return [
        PageHeaderSimpleAction('ADD', 'Versicherung hinzufügen'),
        /* An Insurance can be bulk cancelled if and only if: 
         * - there is maklervollmacht document;
         * - there aren't any calendar event;
         * - is not FA access; 
         * - field pol_nr is not null;
         */
        PageHeaderSimpleAction('VERSICHERUNG_KUENDIGUNG', 'Versicherungen kündigen')
      ];
    },
    isOpenInNewTab() {
      return this.hasRoles([[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]])
    },
    isCustomer() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER])
    },
    headers() {
      return headers(this.isFPPlus, this.isFA, this.isIntern, this.$isSmallScreen, this.mitZuordnenAktion);
    },
    configFilter() {
      const searchKeysBroker = ['vertragGesellschaft', 'versSparten', 'versNummer', 'lastName', 'firstName'];
      const searchKeysCustomer = ['vertragGesellschaft', 'versSparten', 'versNummer'];

      const searchKeys = () => {
        if(this.isBroker || this.isIntern) {

          return searchKeysBroker;
        }
        return searchKeysCustomer;
      }
      
      return {
        placeholderForDefSearchInput: this.isBroker || this.isIntern ? `Gesellschaft, Vertragsart, VSNR, Name, Vorname` : 'Gesellschaft, Vertragsart, VSNR',
        checkDefaultSearchInput: true,
        defaultSearchInputKeys: searchKeys(),
        noResetOnDefaultSearchInputExit: true,
        filterType: 'versicherung',
        keepFiltersStateBeforeRoutingChange: !this.insuranceCancelingViewEnabled,
        ignoreStoredFilter: this.mitZuordnenAktion
      }
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('VERTRAG-VEREINIGEN', 'Vertrag vereinigen')
          .withDisabled(() => !this.allSelectedLoaded || this.numberSelected !== 2),
        PageHeaderSimpleAction('MAIL-COMPOSER-COMPANY', 'Rundmail an Gesellschaften')
          .withVisible(() => this.isTest && !this.isFA)
          .withDisabled(() => this.numberSelected == 0),
      ];
    },
    exportConfig() {
      const name = "Versicherungsliste " + dayjs(new Date()).format("DD MMM YYYY HH mm");
      return {
        pdf: { name: name + ".pdf" },
        xls: { name: name + ".xlsx" },
        title: "Versicherungsliste",
        dispatch: this.exportAsFile,
      };
    },
  },
  watch: {
    insuranceFiltersData: {
      handler(value) {
        if(value) {
          this.searchMetadata = searchMenu(value, this.isFPPlus, this.isFA, this.isIntern);
        }
      },
      immediate: true,
    },
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
    rowsPerPage() {
      this.requestPage(0, true);
    },
  },
  methods: {
    onScroll(onScrollEnd) {
      this.requestPage(this.currentPage + 1, true).finally(() => {
        onScrollEnd()
      });
      
    },
    changeRowsPerPage(event) {
      if(event) {
        this.$store.commit(BROKERDATA_TYPES.MUTATIONS.PUT_BROKER_PAGE_TABLE_SIZE, event);
      }
    },
    navigateToAuswahlVertragsart() {
      this.$router.push('/home/versicherungen/auswahl-vertragsart');
    },
    openSavedItem(vertragId) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, { versVertrag: vertragId })
        .then((response) => {
          const insurance = response?.insurance;
          this.openItemSelectedDetail(insurance);
        });
    },
    initSearch(storedDefaultFilters) {
      if (!this.filtered && this.isByPass) {
        const filter = storedDefaultFilters ? storedDefaultFilters : this.defaultFilters;
        this.handleSearch(filter);
      }
    },    
    handleSearch(filters) {
      this.filtered = true;
      this.filtersObj = prepareFilters(filters);
      const onlyCancelableInsurancesFilterSelected = filters.map(row => row.key).includes('onlyCancelableInsurances');
      if(onlyCancelableInsurancesFilterSelected) {
        this.insuranceCancelingViewEnabled = true;
      }
      this.requestPage(0, true);
    },
    handleMailComposerCompany() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.storeSelectedAndKundbar()
      .then(() => {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
          ...this.mailLinkedObject,
          toCompany: true,
        });
        this.$router.push(`/communication/mailcomposer-insurance-rund`);
      })
    },
    handleMailComposerCancelInsurance() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
        
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
        });


      this.storeSelectedAndKundbar()
      .then(() => {
        this.$router.push(`/communication/mailcomposer-insurance-cancel`);
      })
    },
    handleVersicherungKuendigung() {
      this.insuranceCancelingViewEnabled = true;
      this.$refs.baseFilter.findAndAddNewFilterRowByMenuItemKey({key: 'onlyCancelableInsurances'}, true);
      this.$nextTick(() => {
        this.$refs.baseFilter.emitFilterEvent();
      });
    },
    openVertragVereinigenModal() {
      this.$refs.vertragVereinigenModalNew.open();
    },
    loadFilterComponents(){
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_FILTERS_DATA);
    },
    mergeInsurances(insurances){
        if(insurances){
            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.VERSICHERUNG_VEREINIGEN, {
                insuranceToKeep: insurances.toKeep.vertragId,
                insuranceToRemove: insurances.toRemove.vertragId,
            }).then(() => {
                this.selectionAsBlacklist = false;
                this.selectedRows = [];
                EVENT_BUS.$emit(REFRESH_INSURANCE_TABLE)
            });
        }
    },
    onSelectedRow(rows) {
      this.selectedRowsValues = rows;
    },  
    exportAsFile(fileType) {
      const params = {
        ...this.filtersObj,
        section: fileType == "pdf" ? 0 : 1,
        rowsPerPage: this.rowsPerPage,
        maxCount: this.insurancesFiltered?.count || 0,
        cols: this.$refs?.versicherungsliste?.$refs?.InsuranceGroupTable?.$refs?.sortableTable?.visibleHeadersFlat
        .filter(h => h.exportFn).map(h => [h.key, h.label])
      };
      if (this.filterType) {
        params.filterType = this.filterType;
      }
      if (this.sortByColumn) {
        Object.assign(params, this.sortByColumn);
      }
      return this.$store.dispatch(
        VERSICHERUNG_TYPES.ACTIONS.EXPORT_INSURANCES,
        params
      );
    },
    handleFilterChanges(event, filterRows) {
      const onlyCancelableInsurancesFilterSelected = filterRows.map(row => row.secondaryKey).includes('onlyCancelableInsurances');
      // only change the state when the filter is removed, never when is added.
      if(this.insuranceCancelingViewEnabled && !onlyCancelableInsurancesFilterSelected) {
        this.insuranceCancelingViewEnabled = false;
      }
    },
    registerEventBus() {
      // Refresh Insurance Table
      EVENT_BUS.$on(REFRESH_INSURANCE_TABLE, () => {
        this.requestPage(0, true)
      });
    },
    unregisterEventBus() {
      EVENT_BUS.$off(REFRESH_INSURANCE_TABLE);
    },
  },
    mounted() {
        if (typeof(this.$route.query.backAction) == "object")
            this.backAction = this.$route.query.backAction;
        if (typeof(this.$route.query.defaultOptions) == "object")
            this.defaultOptions = this.$route.query.defaultOptions;
        
        if(this.$route.query.mitZuordnenAktion) {
          this.mitZuordnenAktion = true;
        }

        this.loadFilterComponents();
        this.registerEventBus();
    },
    destroyed(){
      this.unregisterEventBus();
    }, 
	components: {
    PhPencilLine,
    InsuranceGroupTable,
    BaseFilter,
    DragnDropArea,
    InsuranceOptionMenu,
    DeleteInsuranceModal,
    VertragVereinigenModalNew,
    AppointmentEditCreate,
    BaseModal,
    VertragVereinigenModal,
    InsuranceCompanyMailContacts,
    OptionMenu,
    PageHeaderTitleNavigation,
    HandleTablePin,
    ShowDeeplinksModal,
	}
}
</script>
