var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "5d00853a-a67d-4b41-81a7-872bef10f95d",
          data: _vm.dashboardData,
          externalConfig: _vm.externalWidgetsConfig,
          isExternalLoading: _vm.externalLoading
        },
        on: {
          saved: function($event) {
            return _vm.saveInternOverviewConfiguration($event)
          },
          restored: function($event) {
            return _vm.saveInternOverviewConfiguration($event)
          },
          executeAction: function($event) {
            return _vm.handleExecuteAction($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "fkEventsModal",
          attrs: { labelButtonCancel: "Schließen", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.fkEventsTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_c("EventsFkOverview")], 1)]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }