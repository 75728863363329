<template>
    <BaseModal ref="EditCommentModal" size="md" :showCancelButton="true" labelButtonConfirm="Speichern"
        @onConfirmButton="complete()">
        <template #modalTitle>
            <div class="option-menu-edit-modal--title-container">
                <div class="option-menu-edit-modal--title">Bemerkung bearbeiten</div>
            </div>
        </template>
        <template #default>
            <div>
                <InputTextArea v-model="bemerkungThema"
                    label="Thema"/>
            </div>
            <div>
                <InputTextArea v-model="bemerkungText"
                    label="Bemerkung" autoGrow />
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";

export default {
    components: {
        BaseModal,
        InputTextArea,
    },
    data() {
        return {
            bemerkungThema: '',
            bemerkungText: '',
            mappenID: '',
        }
    },
    methods: {
        open(data) {
            this.bemerkungThema = data.bemerkungThema;
            this.bemerkungText = data.bemerkungText;
            this.mappenID = data.beratungsmappennr;
            this.$refs.EditCommentModal.open();
        },
        close() {
            this.$refs.EditCommentModal.close();
        },
        complete() {
            this.$emit('completed', { bemerkungText: this.bemerkungText, mappenID: this.mappenID, bemerkungThema: this.bemerkungThema });
            this.close();
        }
    }
}
</script>