var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Personen zusammenführen"
        }
      }),
      _c("BaseCollapsable", {
        staticClass: "box__container",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" Hinweise ")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    " Hier können Sie nach mehrfach angelegten Personen suchen und diese zusammenführen. "
                  )
                ]),
                _c("p", { staticClass: "font-strong" }, [
                  _vm._v(
                    " Achtung, bitte überzeugen Sie sich, dass die von Ihnen in der Liste ausgewählten Personen, auch wirklich identisch sind. Eine spätere Trennung ist nur mit großem Aufwand möglich. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    ' Bitte klicken Sie dazu auf eine Person in der oberen Liste. Es werden die als identisch erkannten Personen in der unteren Liste aufgeführt. Markieren Sie dort die Personen, die zusammengeführt werden sollen und drücken Sie "Markierte Personen zusammenführen". '
                  )
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showHinweis,
          callback: function($$v) {
            _vm.showHinweis = $$v
          },
          expression: "showHinweis"
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "button__container" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "Name und Vorname",
                disabled: true || _vm.loading,
                inLineLabel: ""
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Geburtsdatum",
                disabled: _vm.loading,
                inLineLabel: ""
              },
              model: {
                value: _vm.filter.checkBirthday,
                callback: function($$v) {
                  _vm.$set(_vm.filter, "checkBirthday", $$v)
                },
                expression: "filter.checkBirthday"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: { label: "PLZ", disabled: _vm.loading, inLineLabel: "" },
              model: {
                value: _vm.filter.checkPLZ,
                callback: function($$v) {
                  _vm.$set(_vm.filter, "checkPLZ", $$v)
                },
                expression: "filter.checkPLZ"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Straße",
                disabled: _vm.loading,
                inLineLabel: ""
              },
              model: {
                value: _vm.filter.checkStreet,
                callback: function($$v) {
                  _vm.$set(_vm.filter, "checkStreet", $$v)
                },
                expression: "filter.checkStreet"
              }
            })
          ],
          1
        )
      ]),
      _c("DuplicatedPeopleList", {
        attrs: {
          loading: _vm.duplicatedPeopleStatus.loading,
          error: _vm.duplicatedPeopleStatus.error
        },
        on: { showUnifiablePeople: _vm.loadUnifiablePeople }
      }),
      _vm.selectedDuplicatedPerson
        ? _c("UnifiablePeopleList", {
            ref: "unifiablePeople",
            attrs: {
              loading: _vm.unifiablePeopleStatus.loading,
              error: _vm.unifiablePeopleStatus.error
            },
            on: { peopleMerged: _vm.loadDuplicatedPeople }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }