import { mapGetters } from 'vuex';
import E_MAIL_ACCESS_TYPES from "@/store/eMailAccess/types";

const mixin = {
    data() {
        return {
            form: {
                server: '',
                type: '',
                port: '',
                ssl: true,
                name: '',
                freigeschaltet: false,
                aktiv: true,
                passwd: '',
                zertifikatCheck: false,
                externen: false,
            },
            passwordType: 'text',
            defaultMicrosoftServer: 'outlook.office365.com',
            microsoftConfirmed: false,
            initialBenutzerName: '',
            accessTitle: "E-Mail Eingangsserver bearbeiten",
        }
    },
    watch: {
        msGraphData(value) {
            this.initForm(value);
        }
    },
    computed: {
        ...mapGetters({
            getMsGraphData: E_MAIL_ACCESS_TYPES.GETTERS.GET_MS_GRAPH_EMAIL_DATA,
        }),
        msGraphData() {
            return this.getMsGraphData || {};
        },
        isMicrosoft() {
            return this.form.type === 'msgraph';
        },
        isMicrosoftlinkVisible() {
            return this.isMicrosoft && (this.initialBenutzerName != this.form.name?.trim() || !this.microsoftConfirmed);
        }
    },
    methods: {
        initForm(data) {
            if (data) {
                this.form = Object.assign(this.form, data);
                /* in case the user entered an user name, but changed the name on the microsoft page -> we set the changed name in the form */
                if (this.$route.query.AUTHORIZED_ACCOUNT && this.$route.query.AUTHORIZED_ACCOUNT !== this.$route.query.INPUT_EINGANG_USER && this.form.name !== this.$route.query.AUTHORIZED_ACCOUNT) {
                    this.form.name = this.$route.query.AUTHORIZED_ACCOUNT;
                }
                this.microsoftConfirmed = data.microsoftConfirmed;
                if (!this.initialBenutzerName) {
                    this.initialBenutzerName = data.name;
                }
            }
            if (!this.form.id) {
                this.accessTitle = "E-Mail Eingangsserver anlegen";
              }
            this.handlePswField(!!this.form.passwd);
        },
        typeChanged(type) {
            if (type == "msgraph") {
                if (this.msGraphData.server) {
                    this.form.port = this.msGraphData.port || this.form.port;
                    this.form.ssl = typeof this.msGraphData.ssl == 'boolean' ? this.msGraphData.ssl : this.form.ssl;
                    this.form.name = this.msGraphData.name;
                    this.form.server = this.msGraphData.server;
                    this.form.passwd = this.msGraphData.passwd;
                } else {
                    this.form.server = this.defaultMicrosoftServer;
                    this.form.name = '';
                }
                this.form.externen = true;
            } else if (!type) { 
                this.form.server = '';
                this.form.port = '';
                this.form.passwd = '';
                this.form.name = '';
                this.form.zertifikatCheck = false;
                this.form.externen = false;
            } else {
                this.form.server = '';
                this.form.name = '';
                this.form.externen = true;
            }
        },
        isChanged() {
            return Object.keys(this.form).some(key => this.msGraphData[key] !== this.form[key]);
        },
        handlePswField(isToCheck) {
            if (this.passwordType === 'text' && isToCheck) {
              this.passwordType = 'password';
            }
        },
        updateMicrosoftConfirmed() {
            if (this.microsoftConfirmed) {
              this.microsoftConfirmed = false;
            }
        },
        updateInitialBenutzerName(){
            this.initialBenutzerName = this.form.name;
        }
    }
}

export default mixin;