var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-edit-param-label__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "News bearbeiten",
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions
        },
        on: { "action-SAVE": _vm.saveNews }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "input-forms__container" },
          [
            _c("InputField", {
              attrs: { label: "Titel" },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = $$v
                },
                expression: "title"
              }
            }),
            _c("div", { staticClass: "input-forms__label-container" }, [
              _c("div", { staticClass: "input-forms__label-content" }, [
                _vm._v("Text")
              ]),
              _c(
                "div",
                { staticClass: "input-forms__input-container" },
                [
                  _c("HtmlEditor", {
                    attrs: { value: _vm.value },
                    on: {
                      input: function($event) {
                        return _vm.onUpdate($event)
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("InputCheckBoxGroup", {
                  attrs: {
                    title: "Anzeigen für",
                    value: _vm.forValue,
                    values: _vm.forValues
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: {
            files: function($event) {
              return _vm.addDocument($event)
            }
          }
        },
        [
          _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              { staticClass: "d-block justify-content-between" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between hide-on-large-screen"
                  },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Anhänge")
                    ]),
                    _c(
                      "BaseContextMenu",
                      [
                        _c(
                          "ContextMenuItem",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openAddDocument()
                              }
                            }
                          },
                          [
                            _vm._t("default", function() {
                              return [_vm._v("Datei hochladen")]
                            })
                          ],
                          2
                        ),
                        _c(
                          "ContextMenuItem",
                          {
                            attrs: { disabled: "" },
                            on: { click: _vm.handleAddDocuments }
                          },
                          [_vm._v(" Infofilme auswählen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.rows,
                    hidePagination: ""
                  },
                  on: {
                    "action-DELETE": _vm.deleteFile,
                    "action-EDIT": _vm.editFile
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "name",
                      fn: function(row) {
                        return [
                          !row.noPreview
                            ? _c("DownloadLink", {
                                attrs: {
                                  title: row.dateiname || row.name,
                                  filename:
                                    row.bezeichnung || row.fileName || row.name,
                                  downloadServicePath: "/get_email_attachment",
                                  queryParams: {
                                    attId: row.copyFileId,
                                    tempFileId: row.id
                                  }
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(row.dateiname || row.name))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("div", { staticClass: "hide-on-small-screen" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                    },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.openAddDocument()
                            }
                          }
                        },
                        [
                          _vm._t("default", function() {
                            return [_vm._v("Datei hochladen")]
                          })
                        ],
                        2
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "", disabled: "" },
                          on: { click: _vm.handleAddDocuments }
                        },
                        [_vm._v(" Infofilme auswählen ")]
                      ),
                      _vm._t("maklervollmacht")
                    ],
                    2
                  )
                ])
              ],
              1
            )
          ]),
          _c("AddDocument", {
            ref: "addDocumentModal",
            attrs: { vtgId: _vm.$route.params.id, nodeId: _vm.nodeId }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "addDocument",
          attrs: {
            labelButtonConfirm: "Upload starten",
            showCancelButton: false
          },
          on: {
            onConfirmButton: _vm.uploadFile,
            onCancelButton: _vm.closeAddDocumentModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Datei hochladen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c(
                "DragnDropArea",
                {
                  attrs: { hoverText: "Datei hier ablegen" },
                  on: {
                    files: function($event) {
                      return _vm.addDocument($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "BaseButton",
                              {
                                staticClass: "upload-btn",
                                attrs: { isSecondary: "" }
                              },
                              [
                                _vm._t("default", function() {
                                  return [_vm._v("Datei hochladen")]
                                }),
                                _c("input", {
                                  attrs: {
                                    type: "file",
                                    accept: _vm.acceptFileTypes,
                                    multiple: ""
                                  },
                                  on: { change: _vm.onAddFiles }
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(_vm._s(_vm.fileName))
                        ])
                      ]),
                      _c("InputField", {
                        attrs: { label: "Bezeichnung" },
                        model: {
                          value: _vm.fileTitle,
                          callback: function($$v) {
                            _vm.fileTitle = $$v
                          },
                          expression: "fileTitle"
                        }
                      }),
                      _c("InputTextArea", {
                        attrs: { label: "Bemerkung" },
                        model: {
                          value: _vm.fileDescription,
                          callback: function($$v) {
                            _vm.fileDescription = $$v
                          },
                          expression: "fileDescription"
                        }
                      }),
                      _c("div", [
                        _c("label", { staticClass: "gridlabel" }, [
                          _vm._v("Stand")
                        ]),
                        _c(
                          "div",
                          [
                            _c("DatePickerField", {
                              attrs: { dateFormat: "DD.MM.YYYY", disabled: "" },
                              model: {
                                value: _vm.fileDate,
                                callback: function($$v) {
                                  _vm.fileDate = $$v
                                },
                                expression: "fileDate"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }