import { redirectToFirstAllowedChild, } from '@/router/index'
import { EMPLOYEE_ROLES, ROLES, VIEW_ROLES, BROKER_STRUCTURE_ROLES, BROKER_LEVEL_ROLES } from '@/router/roles'
import { MAP_STEP_KEY_CONFIG_CONTEXT, SUBSTEP_KEY_SEPARATOR, STEP_KEY_SEPARATOR, } from '@/menu/menu-config-utils';

export default [
  {
    path: 'configs',
    component: () => import(/* webpackChunkName: "base-configs" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
          [VIEW_ROLES.VIEW_INTERN],
          [ROLES.IS_KUNDENZUGANG],
          [VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH],
          [VIEW_ROLES.VIEW_CUSTOMER_ONLY],
        ],
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "configs-overview" */ '@/views/core/configs/ConfigsOverview.vue'),
      },
      {
        path: 'view/:id',
        component: () => import(/* webpackChunkName: "configs" */ '@/views/core/configs/Configs.vue'),
      },
      {
        path: 'fee-properties',
        meta: {
          roles: {
            allowed: [
              [ROLES.SHOW_FEE, VIEW_ROLES.VIEW_BROKER],
              [ROLES.SHOW_FEE, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "fee-properties-config" */ '@/views/core/configs/feePropertiesConfig/FeePropertiesConfig.vue'),
      },
      {
        path: 'ex-post-manuelle',
        meta: {
          roles: {
            allowed: [
              [ROLES.SHOW_FEE, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "ex-post-manuelle-config" */ '@/views/core/configs/exPostManuelleConfig/ExPostManuelleConfig.vue'),
      },
      {
        path: 'risikoklassen-definition',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "risikoklassen-definition-config" */ '@/views/core/configs/risikoklassenDefinitionConfig/RisikoklassenDefinitionConfig.vue'),
      },
      {
        path: 'makler34f',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "makler34f-config" */ '@/views/core/configs/makler34fConfig/Makler34fConfig.vue'),
      },
      {
        path: 'makler-texte',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER, ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "makler-texte-config" */ '@/views/core/configs/maklerTexte/MaklerTexteConfig.vue'),
      },
      {
        path: 'externe-zugaenge',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "externe-zugaenge-config" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "externe-zugaenge-config" */ '@/views/core/configs/externeZugaengeConfig/ExterneZugaengeConfig.vue'),
          },
          {
            path: 'configs-extern-zugang-edit/:type/:id',
            component: () => import(/* webpackChunkName: "configs-extern-zugang-edit" */ '@/views/core/configs/externeZugaengeConfig/AddExterneZugangConfig.vue'),
          },
        ],
      },
      {
        path: 'configuration',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER],
              [VIEW_ROLES.VIEW_BROKER],
              [VIEW_ROLES.VIEW_INTERN],
            ],
            denied: [ROLES.IS_USER_UNTERNR],
          },
        },
        component: () => import(/* webpackChunkName: "configuration" */ '@/views/configuration/Configuration.vue'),
        children: [
          {
            path: '',
            redirect: (route) => redirectToFirstAllowedChild(route, ['menu-config', 'genereller-zugriff', 'weitere-zugriffsrechte']),
          },
          {
            path: 'menu-config',
            meta: {
              roles: {
                allowed: [
                  [VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.ALLOW_CONFIG_FC_CONFIG_GLOBAL],
                  [VIEW_ROLES.VIEW_BROKER_ONLY, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                  [VIEW_ROLES.VIEW_BROKER_AS_BROKER, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                  [VIEW_ROLES.VIEW_CUSTOMER_ONLY, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                  [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                  [ROLES.FA, VIEW_ROLES.VIEW_BROKER, ROLES.IS_BYPASS_SLASH, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                  [ROLES.FA, VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH, BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT],
                ],
              },
            },
            props: route => {
              // configContext
              const currentStep = route.query?.step?.split(STEP_KEY_SEPARATOR)?.pop?.() || '';
              const configContext = MAP_STEP_KEY_CONFIG_CONTEXT?.[currentStep] || '';

              // currentPath
              const currentSubstep = route.query?.substep || null;
              const currentPath = currentSubstep?.split(SUBSTEP_KEY_SEPARATOR)?.pop?.() || '';

              return {
                configContext,
                currentPath,
              };
            },
            component: () => import(/* webpackChunkName: "menu-config" */ '@/views/menu/MenuConfig.vue'),
          },
          {
            path: 'weitere-zugriffsrechte',
            meta: {
              roles: {
                allowed: [
                  [VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
                ],
              },
            },
            props: {
              userPermissionTarget: 'MAKLER',
            },
            component: () => import(/* webpackChunkName: "weitere-zugriffsrechte" */ '@/views/core/configs/brokerPermissions/AdditionalPermissions.vue'),
          },
          {
            path: 'genereller-zugriff',
            meta: {
              roles: {
                allowed: [
                  [VIEW_ROLES.VIEW_BROKER_AS_INTERN],
                ],
              },
            },
            component: () => import(/* webpackChunkName: "genereller-zugriff" */ '@/views/core/configs/brokerPermissions/BrokerPermissions.vue'),
          },
        ],
      },
      {
        path: 'calendar-settings',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER_ONLY, EMPLOYEE_ROLES.ZUGRIFF_TERMINE], 
              [VIEW_ROLES.VIEW_INTERN],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "calendar-settings-config" */ '@/components/calendar/settings/CalendarSettings.vue'),
      },
      {
        path: 'broker-permissions',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER_AS_INTERN, ROLES.TEST_USER],
            ],
          },
        },
        component: () => import(/* webpackChunkName: "broker-permissions-config" */ '@/views/core/configs/brokerPermissions/BrokerPermissions.vue'),
      },
      {
        path: 'change-2fa-interval',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.MULTI_FACTOR_AUTHENTICATION_ACTIVATED]]
          }
        },
        component: () => import(/* webpackChunkName: "change-2fa-interval-config" */ '@/views/core/Change2FaInterval.vue')
      },
    ],
  },
  {
    path: 'configs-edit',
    component: () => import(/* webpackChunkName: "base-configs-edit" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN, ROLES.EDIT_CONFIGS_DEFINITION],
        ],
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "config-list" */ '@/views/core/configs/ConfigList.vue'),
        meta: {
          breadcrumb: { label: 'Einstellungen bearbeiten', },
        },
      },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "configs-edit" */ '@/views/core/configs/ConfigEdit.vue'),
        meta: {
          breadcrumb: { label: 'Neu', },
        },
      },
    ],
  },
  {
    path: 'import-config',
    meta: {
      // uncomment to import configs
      // menu: { label: 'Import Config' },
      roles: {
        allowed: [
          VIEW_ROLES.VIEW_INTERN
        ]
      }
    },
    component: () => import(/* webpackChunkName: "import-config" */ '@/views/menu/ImportConfig.vue'),
  }
]
