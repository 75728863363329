import SCHADENSMELDUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RETRIEVE_SCHADENSMELDUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'schadensmeldungen', payload)
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}