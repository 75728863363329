var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title:
            (_vm.values.combo_plantyp === "2" ? "Entnahmeplan" : "Sparplan") +
            " Info",
          actions: _vm.headerActions
        },
        on: {
          "action-BACK": _vm.back,
          "action-CANCEL": _vm.abbrechen,
          "action-REMOVE": _vm.remove
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _vm.showFields
              ? _c("Fields", {
                  ref: "refFields",
                  attrs: {
                    fields: _vm.fields,
                    combos: _vm.combos,
                    tableData: {},
                    valuesProp: _vm.values,
                    isNotReactive: true,
                    validators: _vm.validators
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }