import { EMPLOYEE_ROLES, ROLES, VIEW_ROLES } from '@/router/roles'

import configsRoutes from './configs'

import store from '@/store';
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default [
  {
    path: '',
    redirect: 'overview'
  },
  {
    path: 'overview',
    component: () => import(/* webpackChunkName: "persoenlichedaten-overview"*/ '@/views/personliche-daten/PersoenlicheDatenOverview.vue'),
  },
  {
    path: 'customer-data',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'kundendaten-overview',
      },
      {
        path: 'kundendaten-overview',
        component: () => import(/* webpackChunkName: "kundendaten-overview"*/ '@/components/persoenliche-daten/KundendatenOverview.vue'),
      },
      {
        path: 'steps',
        component: () => import(/* webpackChunkName: "customer-data" */  '@/components/persoenliche-daten/CustomerData.vue'),
        children: [
          {
            path: '',
            redirect: 'stammdaten',
          },
          {
            path: 'stammdaten',
            component: () => import(/* webpackChunkName: "stammdaten" */ '@/components/persoenliche-daten/Stammdaten.vue')
          },
          {
            path: 'kommunikationsdaten',
            component: () => import(/* webpackChunkName: "kommunikationsdaten" */ '@/components/persoenliche-daten/Kommunikationsdaten.vue')
          },
          {
            path: 'zusaetzliche-personen',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "zusaetzliche-personen" */ '@/components/persoenliche-daten/ZusaetzlichePersonen.vue')
          },
          {
            path: 'sonstiges',
            component: () => import(/* webpackChunkName: "sonstiges" */ '@/components/persoenliche-daten/Sonstiges.vue'),
            meta: {
              roles: {
                allowed: [ROLES.DARF_VERSICHERUNG_MAKLERAUFTRAG_SEHEN],
              },
            },
          },      
          {
            path: 'gruppenmitglieder',
            meta: {
              roles: {
                allowed: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "gruppenmitglieder" */ '@/components/persoenliche-daten/Gruppenmitglieder.vue'),
          },
          {
            path: 'bankverbindung',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "bankverbindung" */ '@/components/persoenliche-daten/Bankverbindung.vue')
          },
          {
            path: 'arbeitgeber',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "arbeitgeber" */ '@/components/persoenliche-daten/Arbeitgeber.vue')
          },
          {
            path: 'aenderungslog',
            meta: {
              roles: {
                allowed: [ROLES.IS_FPP_PLUS_MASTER, ROLES.IS_FPP_PLUS, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
              },
            },
            component: () => import(/* webpackChunkName: "persoenliche-daten-aenderungslog" */ '@/components/persoenliche-daten/Aenderungslog.vue')
          },
          {
            path: 'hobbys-vorlieben',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "hobbys-vorlieben" */ '@/components/persoenliche-daten/HobbysVorlieben.vue'),
          },
          {
            path: 'schulbildung-und-qualifikationen',
            meta: {
              roles: {
                allowed: [ROLES.SUPER_CUSTOMER, VIEW_ROLES.VIEW_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "schulbildung-und-qualifikationen" */ '@/components/persoenliche-daten/SchulbildungUndQualifikationen.vue')
          },
          {
            path: 'pep',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
                denied: [ROLES.SUPER_CUSTOMER]
              },
            },
            component: () => import(/* webpackChunkName: "politischexponierteperson" */ '@/components/persoenliche-daten/PolitischExponiertePerson.vue')
          },
          {
            path: 'finanzielle-verhaltnisse',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "finanzielle-verhaltnisse" */ '@/components/persoenliche-daten/FinanzielleVerhaltnisse.vue')
          },
          {
            path: 'einkommen-sozialversicherungen',
            meta: {
              roles: {
                allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.FK]],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "einkommen-sozialversicherungen" */ '@/components/persoenliche-daten/EinkommenSozialversicherungen.vue'),
          },
          {
            path: 'einkommen-sozialversicherungen-partner',
            meta: {
              roles: {
                allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.FK]],
                denied: [ROLES.SUPER_CUSTOMER],
              },
            },
            component: () => import(/* webpackChunkName: "einkommen-sozialversicherungen-partner" */ '@/components/persoenliche-daten/EinkommenSozialversicherungenPartner.vue'),
          },
          {
            path: 'courtage',
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
                denied: [[ROLES.FA], [ROLES.SUPER_CUSTOMER]],
              },
            },
            component: () => import(/* webpackChunkName: "courtage" */ '@/components/persoenliche-daten/Courtage.vue'),
          },
        ]
      },
    ]
  },
  {
    path: 'person-data-overview/:personId',
    component: () => import(/* webpackChunkName: "person-overview" */  '@/components/persoenliche-daten/person-daten/PersonDatenOverview.vue'),
    meta: {
    },
  },
  {
    path: 'person-data/:personId',
    component: () => import(/* webpackChunkName: "person" */  '@/components/persoenliche-daten/person-daten/PersonData.vue'),
    meta: {
    },
    children: [
      {
        path: '',
        redirect: 'stammdaten'
      },
      {
        path: 'stammdaten',
        component: () => import(/* webpackChunkName: "person-stammdaten" */ '@/components/persoenliche-daten/Stammdaten.vue')
      },
      {
        path: 'kommunikationsdaten',
        component: () => import(/* webpackChunkName: "kommunikationsdaten" */ '@/components/persoenliche-daten/Kommunikationsdaten.vue')
      },
      {
        path: 'arbeitgeber',
        component: () => import(/* webpackChunkName: "person-arbeitgeber" */ '@/components/persoenliche-daten/Arbeitgeber.vue')
      },
      {
        path: 'dokumente',
        component: () => import(/* webpackChunkName: "person-dokumente" */ '@/components/persoenliche-daten/person-daten/PersonDokumente.vue')
      },
      {
        path: 'finanzielle-verhaltnisse',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
          },
        },
        component: () => import(/* webpackChunkName: "person-finanzielle-verhaltnisse" */ '@/components/persoenliche-daten/FinanzielleVerhaltnisse.vue')
      },
      {
        path: 'schulbildung-und-qualifikationen',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
          },
        },
        component: () => import(/* webpackChunkName: "person-schulbildung-und-qualifikationen" */ '@/components/persoenliche-daten/SchulbildungUndQualifikationen.vue')
      },
      {
        path: 'pep',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
          },
        },
        component: () => import(/* webpackChunkName: "person-politischexponierteperson" */ '@/components/persoenliche-daten/PolitischExponiertePerson.vue')
      },
    ],
  },
  {
    path: 'change-password',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_ONLY],
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
      },
    },
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/core/ChangePassword.vue')
  },
  {
    path: 'change-2fa-interval',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_ONLY, ROLES.MULTI_FACTOR_AUTHENTICATION_ACTIVATED]],
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
      },
    },
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/core/Change2FaInterval.vue')
  },
  {
    path: 'privacy',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_ONLY],
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
      },
    },
    
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'main',
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "privacy" */ '@/views/core/privacy/PrivacyTerms.vue'),
      },
      {
        path: 'terms-content',
        component: () => import(/* webpackChunkName: "privacy-notice" */ '@/views/core/privacy/TermsContent.vue'),
      },
    ]
  },
  
  ...configsRoutes,
  {
    path: 'settings',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
      }
    },
    component: () => import(/* webpackChunkName: "customer-settings" */ '@/views/core/settings/Settings.vue'),
    children: [
      {
        path: '',
        redirect: 'access-management'
      },
      {
        path: 'access-management',
        component: () => import(/* webpackChunkName: "access-management" */ '@/components/settings/AccessManagement.vue'),
      },
      {
        path: 'two-factor-sms-option',
        component: () => import(/* webpackChunkName: "two-factor-sms-option" */ '@/components/settings/TwoFactorSMSOption.vue'),
        meta: {
          roles: {
            allowed: [ROLES.MULTI_FACTOR_AUTHENTICATION_ACTIVATED],
          },
        },
      },
    ],
  },
  {
    path: 'grundlagenwissen-herunterladen',
    meta: {
      roles: {
        allowed: [
          [ROLES.SHOW_GRUNDLAGENWISSEN, VIEW_ROLES.VIEW_CUSTOMER_ONLY]
        ],
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
      }
    },
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/core/privacy/Grundlagenwissen.vue')
  },
  {
    path: 'privacy-infos',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/core/privacy/PrivacyInfos.vue')
  },
  {
    path: 'conflict-of-interests-principles',
    component: () => import(/* webpackChunkName: "conflict-of-interest-principles" */ '@/views/core/conflict-of-interest/ConflictOfInterestPrinciples.vue'),
    meta: {
      roles: {
        allowed: [
          [ROLES.SHOW_ZUSATZINFORMATION_INTERESSENKONFLIKT, ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_ONLY]
        ],
        denied: [VIEW_ROLES.VIEW_KUNDENSICHT]
      }
    }
  }, 
  {
    path: 'bemerkungen',
    component: () => import(/* webpackChunkName: "bemerkungen" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed:[[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [ROLES.BEMERKUNG_VISIBLE_KUNDE]]
        
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "bemerkungen" */ '@/components/persoenliche-daten/Bemerkungen.vue'),
      },
      {
        path: 'bemerkung/:commId?',
        component: () => import(/* webpackChunkName: "kunde-bemerkung-bearbeiten" */ '@/views/communication/PostfachBemerkung.vue')
      },
    ],
  },
  {
    path: 'pdf-kundendaten',
    meta: {
      roles: {
        allowed:[[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
      },
    },
    component: () => import(/* webpackChunkName: "bemerkungen" */ '@/components/persoenliche-daten/PdfKundendaten.vue'),
  },
  {
    path: 'vcard-kundendaten',
    beforeEnter: async () => {
      const params = { getVCard: "" };
      const response = await store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.LOAD_VCARD_KUNDENDATEN, params);
      const contentType = response.headers['content-type'];

      const contentDisposition = response.headers['content-disposition'];
      let filename = "contact.vcf";  // Default filename if not found
      if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (filenameMatch && filenameMatch[1]) {
              filename = filenameMatch[1].replace(/['"]/g, '');
          }
      }
      if (contentType && contentType.startsWith('application/base64')) {
          viewDocument({
              data: response.data,
              filename: filename,
              contentType: 'text/vcard',
          });
      }
      return false;
    },
    meta: {
      roles: {
        allowed:[[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
      },
    },
    component: () => import(/* webpackChunkName: "vcard" */ '@/components/persoenliche-daten/VCardKundendaten.vue'),
  },
  {
    path: 'vermittlerwechselintern',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.FK]
        ],
        denied: [
          [ROLES.SUPER_CUSTOMER]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "vermittlerwechselintern" */ '@/views/vermittlerwechselintern/VermittlerwechselIntern.vue'),
  },
  {
    path: 'add-existent-people',
    component: () => import(/* webpackChunkName: "add-existent-people" */ '@/components/persoenliche-daten/person-search/PersonSearch.vue'),
  },
  {
    path: 'sub-customer-search',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [ROLES.SUPER_CUSTOMER]
      },
    },
    children: [
      {
        path: '',
        redirect: 'customer',
      },
      {
        path: ':folder',
        component: () => import(/* webpackChunkName: "sub-customer-search" */ '@/components/customerSearch/CustomerSearchSelectItems.vue'),
        props: {
          filterTypeProp: 'Customer',
        },
      },
    ],
  },
  {
    path: 'new-person-id-scan',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
      },
    },
    props: {
      type: 'PERSON',
    },
    component: () => import(/* webpackChunkName: "new-person-id-scan" */ '@/views/customer/NewCustomerIdScan.vue'),
  },
  {
    path: 'kundennummern-gesellschaft',
    meta: {
      menu: { label: 'Kundennummern Gesellschaft', },
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]],
        denied: [ROLES.SUPER_CUSTOMER],
      },
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "kundennummern-gesellschaft" */ '@/views/kundenGesellNr/KundenGesellNrList.vue'),
        meta: {
          breadcrumb: { label: 'Kundennummern Gesellschaft', },
        },
      },
      {
        path: 'edit/:fremdnr/:gesellId',
        component: () => import(/* webpackChunkName: "kundennummern-gesellschaft" */ '@/views/kundenGesellNr/KundenGesellNrEdit.vue'),
        meta: {
          breadcrumb: { label: 'Bearbeiten', },
        },
      },
    ],
  },
];
