var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Alle Quartalsberichte",
          actions: _vm.headerActions
        },
        on: {
          "action-SEND": function($event) {
            return _vm.sendEmail()
          },
          "action-DOWNLOAD": function($event) {
            return _vm.downloadFile()
          },
          "action-BERECHNEN": function($event) {
            return _vm.berechnen()
          }
        }
      }),
      !_vm.loading && _vm.nodeId ? [_c("DocumentsFilter")] : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "0b82f193-2e28-423b-998a-571f835e5ced" }
        },
        [
          !_vm.loading && _vm.nodeId
            ? _c("DocumentsTable", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  tableId: "96d2ede3-62a3-45ed-87a8-9935115be741",
                  nodeId: _vm.nodeId,
                  showNoDataMessageWithIcon: "",
                  rowsPerPage: _vm.rowsPerPage,
                  showActionColumn: false
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "ergebnisModal",
          attrs: {
            labelButtonCancel: "Abbrechen",
            showCancelButton: false,
            showConfirmButton: true,
            labelButtonConfirm: "Ok",
            size: "lg"
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Ergebnis ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.ergebnis
            ? _c("div", {
                staticClass: "mb-1",
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.ergebnis)) }
              })
            : _vm._e()
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }