<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Bearbeitung einer Honorar/Serviceleistungsabrechnung" 
      :actions="headerActions" 
      @action-ADD="addNewRow()" 
    />

    <div tid="531796f4-fa9a-44e7-af76-9f83f9f77479" v-if="editorLayoutData && editorLayoutData.tabs">

      <StepperForm
        ref="stepperForm"
        stepType="tab"
        stepperName="honorar-bearbeiten"
        :stepperMediator="tabsStepperMediator"
        :hasFertig="false"
        :selectedStepKey="selectedStepKey"
        :selectedSubstepKey="selectedSubstepKey"
        @set-step="setStepByKey"
      >
        <template #contentTemplate>
          <div class="box__container">
            <Table
              tableId="a5a1d818-1d43-4399-9a07-034c0ecfc25c"
              rowId="rowIndex"
              class="table-container"
              v-if="tableRows.length"
              :headers="tableHeaders"
              :rows="tableRows"
              :rowsPerPage="20"
              :count="resultCount"
              @action="executeAction($event)"
              @click-bezeichnung="bearbeiten($event)"
              @click-datum="bearbeiten($event)"
            >
              
            </Table>
            <GhostLoading v-else-if="loading" type="table" />
            <NoData v-else />

          </div>

          <div class="box__container">
            <div class="row">
              <div class="col">
                Bruttobetrag
              </div>    
              <div class="col text-right">
                {{subTotals.summeBrutto | currency}}
              </div>                   
            </div>
          </div>


        </template>
      </StepperForm>

    </div>    

     <BaseModal  @onConfirmButton="persistRow()" ref="editModal" modalTitle="Bearbeiten"  :showCloseButton="true"  labelButtonConfirm="Ok">

          <div v-for="input in Object.keys(form)" v-bind:key="input">

            <DatePickerField
              v-if="form[input].columnType == 'DateColumn'"
              v-model="form[input].value"
              :label="form[input].label"
            />   

            <InputField
              v-else
                      :label="form[input].label"
                    v-model="form[input].value"
                      :type="form[input].type"
                      >
                  </InputField>   
                  
                  
          </div>
      
    </BaseModal>  
    
    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Tarif löschen" labelButtonConfirm="Löschen">
      Soll der Eintrage für {{editRow.bezeichnung}} wirklich gelöscht werden?
    </BaseModal>     


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import HONORARE_TYPES from "@/store/honorare/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from '@/components/core/forms/InputField.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, DateColumn, PercentageColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import {PhPencil, PhTrash } from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import dayjs from 'dayjs'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    StepperForm,
    GhostLoading,
    NoData,
    Table,
    BaseModal,
    BaseButton,
    DatePickerField,
  },
  data: function () {
      return {
        resultCount: null,
        loading: false,
        formData : null,
        selectedStepKey: "HONORARE1",
        selectedSubstepKey: '',
        form: {},
      }
  },
  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' € ';
    },
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      editorLayoutData: HONORARE_TYPES.GETTERS.EDITOR_LAYOUT_DATA,
      selectedRow: HONORARE_TYPES.GETTERS.SELECTED_ROW,
      editRow: HONORARE_TYPES.GETTERS.EDIT_ROW,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Hinzufügen'),
      ];
    },
    subTotals() {
      return {
        summeBasis: this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.summeBasis,
        summeBrutto: this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.summeBrutto,
        summeNetto: this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.summeNetto,
      };
    },
    tableHeaders() {
      const lockedLeft = [];  
      const center = [];  

      const columns = this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.columns;

      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];

        if (index == 0) {
          lockedLeft.push(TextColumn(column.key, column.value).makeLink());
        } else {
           switch (column.type) {
            case "CurrencyColumn":
              center.push(CurrencyColumn(column.key, column.value).withSumFooter());
              break;
             case "PercentageColumn":
              center.push(PercentageColumn(column.key, column.value, 3));
              break;    
             case "DateColumn":
              center.push(DateColumn(column.key, column.value));
              break;                     
            default:
              center.push(TextColumn(column.key, column.value));
              break;
           }
           
        }
        
      }

      const lockedRight = [
              ActionColumn("actions", "Aktionen"),
          ];

      return {
        lockedLeft,
        center,
        lockedRight,
      };  


    },
    tableRows() {
      const fullActions = [
          SimpleAction("EDIT_ITEM", PhPencil, "Bearbeiten"),
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      const rows = this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.rows;

      return rows?.map(data => ({
        ...data,
        actions: fullActions,
      }));
      // return this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.rows;
    },
    tabsStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    steps() {
      return  this.editorLayoutData?.tabs?.map(eld => { return {
          stepKey: eld.stepKey,
          label: eld.tabName,
          totalProgress: 1       
      }


      });
    }
       
  },
  mounted() {
      
  },
  methods: {
    async setStepByKey(stepKey) {
      if(this.selectedStepKey === stepKey ) {
        return ;
      }

      this.selectedStepKey = stepKey

    },
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          this.bearbeiten(actionData.row);
          break;
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
      }
    },   
    confirmItemRemoval(row) {
      this.$store.commit(HONORARE_TYPES.MUTATIONS.EDIT_ROW, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      const  feeId = this.selectedRow?.id;

      this.loading = true;

      let params = {};


      const fields = Object.keys(this.form);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];

        params[field] = this.form[field].value;
        
      }

      params["identifier"] = this.editRow?.identifier;
      params["editFeeType"] = this.editRow?.editFeeType || this.selectedStepKey;

      const payload =  {
          feeId,
          params
      };

      this.$store.dispatch(HONORARE_TYPES.ACTIONS.DELETE_POSITION, payload).then(() => {
        
            this.$store.dispatch(
                  HONORARE_TYPES.ACTIONS.GET_EDITOR_LAYOUT_DATA, 
                  {feeId}
                ).then(() => {
                    this.loading = false;
                });       

      }); 
    },   
    addNewRow() {
      this.form = {};

      this.$store.commit(HONORARE_TYPES.MUTATIONS.EDIT_ROW, {});

      const columns = this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.columns;

      const today = dayjs().utc();
      const datum =  today.clone().format("DD.MM.YYYY");

      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        const fieldMetadata = {};

        fieldMetadata.label = column.value;
        fieldMetadata.type = column.type == 'CurrencyColumn' ? 'currency' :  (column.type == 'PercentageColumn' ? "percent" : "");
        fieldMetadata.columnType = column.type;
        fieldMetadata.value = column.type == 'DateColumn' ? datum : '';
        // this.form[column.key] = fieldMetadata;
        this.$set(this.form, column.key, fieldMetadata);
        
      }     

      this.$refs.editModal.open();
    },
    bearbeiten(row) {
      this.form = {};

      this.$store.commit(HONORARE_TYPES.MUTATIONS.EDIT_ROW, row);

      const columns = this.editorLayoutData?.tabs?.find(t => t.stepKey == this.selectedStepKey)?.columns;

      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        const fieldMetadata = {};

        fieldMetadata.label = column.value;
        fieldMetadata.type = column.type == 'CurrencyColumn' ? 'currency' :  (column.type == 'PercentageColumn' ? "percent" : "");


        if (column.type == 'CurrencyColumn' || column.type == 'PercentageColumn') {
          fieldMetadata.value = row[column.key].replace('.', '').replace(',', '.');
        } else {
          fieldMetadata.value = row[column.key];
        }

        // this.form[column.key] = fieldMetadata;
        this.$set(this.form, column.key, fieldMetadata);

        
      }

      this.$refs.editModal.open();

    },     
    persistRow() {
       const  feeId = this.selectedRow?.id;

      this.loading = true;

      let params = {};


      const fields = Object.keys(this.form);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];


        // Unfortunately the backend is expecting formatted numbers
        if (this.form[field]?.type == 'currency' || this.form[field]?.type == 'percent'
          || this.form[field]?.columnType == 'CurrencyColumn' || this.form[field]?.columnType == 'PercentageColumn') {
          const fieldValue = this.form[field]?.value;
          
          if (fieldValue) {

            // So I had to do this
            params[field] =  new Intl.NumberFormat('de-DE', {
                style: 'decimal'
            }).format(fieldValue);

          }
          
        } else {
          params[field] = this.form[field].value;
        }
        
      }

      params["identifier"] = this.editRow?.identifier;
      params["editFeeType"] = this.editRow?.editFeeType || this.selectedStepKey;

      const payload =  {
          feeId,
          params
      };
      

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.SAVE_EDIT_POSITION, payload
            ).then(() => {

                this.$store.dispatch(
                        HONORARE_TYPES.ACTIONS.GET_EDITOR_LAYOUT_DATA, 
                        {feeId}
                      ).then(() => {
                          this.loading = false;
                      });

              
              
            });          
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Bearbeitung einer Honorar', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    next();
  },


    
}
</script>