<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Formulare für Maklerauftrag Versicherungen" 
      :actions="headerActions" 
      @action-ADD="editOrCreate()" 
      @action-TOGGLE_ACCESS="setAccessForBroker" 
    />

    <div class="box__container">
      <Table 
        v-if="rows && rows.length"
        hidePagination
        :headers="headers"
        :rows="rows"
        @click-label="editFormDocuments"
        @action-pdf="openPreview"
        @action-COPY="openCopyFormModal"
        @action-edit="editOrCreate"
        @action-DELETE="deleteForm" 
        @action-release="openReleaseModal"
        @action-disable="disableForm"
        @action-transfer="transferToBroker"
      >

        <template v-slot:struktur="row">
          <InputToggleSwitch 
            v-if="row.owner"
            :disabled="loading"
            :value="row.struktur"
            @input="handleEditDocument({row, key: 'STRUKTUR', value: $event, objKey: 'struktur'})"
            inLineLabel
          />
          <span v-else></span>
        </template>

        <template v-slot:beratung="row">
          <ComboBox
            :value="row.beratung"
            :values="beratungValues"
            :disabled="loading"
            @change="handleEditDocument({row, key: 'BERATUNG', value: $event, objKey: 'beratung'})"
          />
        </template>
      
      </Table>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }" />
      <NoData v-else />
    </div>
    
    <BaseModal 
      ref="releaseModal"
      modalTitle="Dokument veröffentlichen"
      labelButtonConfirm="Veröffentlichen"
      :confirmDisabled="isDateBeforeNow(releaseFormData.releaseDate)" 
      @onConfirmButton="onConfirmReleaseModal"
    >

      <InputField
          label="Bezeichnung"
          :value="selectedRow.label"
          isComponentHalfSize
          disabled />
      <div class="box__title">Veröffentlichungsdatum</div>
      <InputToggleSwitch
        label="sofort"
        v-model="releaseFormData.releaseNow"/>
      <DatePickerField
        v-if="!releaseFormData.releaseNow"
        label="oder am"
        isValueAsString
        v-model="releaseFormData.releaseDate"/>
      <div class="fc-form-danger" v-if="isDateBeforeNow(releaseFormData.releaseDate)">Datum darf nicht in der Vergangenheit sein</div>
    </BaseModal>

    <BaseModal 
      ref="createFormModal"  
      :modalTitle="formData.id ? 'Antrag bearbeiten' : 'Antrag erstellen'"
      :labelButtonConfirm="formData.id ? 'Speichern' : 'Erstellen'"
      :confirmDisabled="!formData.label"
      @onConfirmButton="onConfirmCreateFormModal"
    >

      <InputField
        label="Bezeichnung"
        v-model="formData.label"
      />
      <InputTextArea
        label="Bemerkung"
        v-model="formData.bemerkung"
      />
      <ComboBox
        v-if="isFPPlus"
        label="Anbindung"
        v-model="formData.uebertragType"
        :values="uebertragTypeValues"
        :sortComboboxValues="false"
      />

    </BaseModal>

    <BaseModal 
      ref="copyFormModal"
      modalTitle="Antrag kopieren"
      :labelButtonConfirm="'Kopieren'"
      :confirmDisabled="!formData.label"
      @onConfirmButton="copyForm"
    >
      <InputField
        label="Bezeichnung"
        v-model="formData.label"
      />
      <InputTextArea
        label="Bemerkung"
        v-model="formData.bemerkung"
      />
      <ComboBox
        v-if="isFPPlus"
        label="Anbindung"
        v-model="formData.uebertragType"
        :values="uebertragTypeValues"
        :sortComboboxValues="false"
      />

    </BaseModal>

    <BaseModal 
      ref="transferToBrokerModal"
      modalTitle="Antrag übertragen"
      labelButtonConfirm="Übertragen"
      @close="transferId = null"
      @onConfirmButton="onConfirmTransferToBrokerModal"
    >

      <InputField
        label="Makler an den der Antrag übertragen werden soll"
        placeholder="Maklernummer"
        v-model="transferId"
        isComponentHalfSize
      />

    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { makeQueryParam } from '@/helpers/utils-helper';
import InputField from '@/components/core/forms/InputField.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, ConfirmedAction, PillColumn, SlotColumn} from "@/components/table2/table_util.js";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import axios from 'axios';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import dayjs from "dayjs";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { PageHeaderSimpleAction, PageHeaderConfirmedAction, } from '@/components/core/header-title-navigation/page-header-utils';
import ComboBox from '@/components/core/forms/ComboBox.vue';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    BaseModal,
    InputTextArea,
    DatePickerField,
    InputToggleSwitch,
    GhostLoading,
    NoData,
    ComboBox,
  },
  data() {
    return {
      selectedRow: {},
      releaseFormData: {
        releaseNow: true,
        releaseDate: null,
      },
      formData: {
        id: null,
        label: null,
        bemerkung: null,
        uebertragType: null,
      },
      brokerHasAccess: null,
      formList: [],
      loading: false,
      transferId: null,
      beratungValues: [
        {
          label: 'Ja',
          value: 'JA',
        },
        {
          label: 'Ja (inkl. Struktur)',
          value: 'JA_STRUKTUR',
        },
        {
          label: 'Nein',
          value: 'NEIN',
        },
      ],
      uebertragTypeValues: [
        {
          label: 'FP+ GmbH',
          value: 'FPPLUS_PRIVAT',
        },
        {
          label: 'FP+ Gewerbe GmbH',
          value: 'FPPLUS_GEWERBE',
        },
        {
          label: 'FP+ GBR',
          value: 'FPPLUS_GBR',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neues Formular erstellen').withVisible(() => this.brokerHasAccess).withDisabled(() => this.loading),
        PageHeaderConfirmedAction('TOGGLE_ACCESS', 'Tool sperren', 'Soll das Tool für diesen Makler gesperrt werden?', 'Tool sperren', 'Sperren')
        .withVisible(() => this.isIntern && this.brokerHasAccess !== null && this.brokerHasAccess)
        .withDisabled(() => this.loading),
        PageHeaderConfirmedAction('TOGGLE_ACCESS', 'Tool freischalten', 'Soll das Tool für diesen Makler freigeschaltet werden?', 'Tool freischalten', 'Freischalten')
        .withVisible(() => this.isIntern && this.brokerHasAccess !== null && !this.brokerHasAccess)
        .withDisabled(() => this.loading),
      ];
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", "Bezeichnung").makeConditionalLink('owner'),
            ],
            center: [
                PillColumn("status", "Status"),
                TextColumn("dateRelease", "Veröffentlichungsdatum"),
                SlotColumn("struktur", "Für Struktur freigeschaltet", undefined, 1),
                SlotColumn("beratung", "Für Beratung verwendet", undefined, 1),
                ...(this.isFPPlus ? [TextColumn('type', 'Anbindung')] : []),
                TextColumn("createdBy", "Ersteller"),
                TextColumn("bemerkung", "Bemerkung"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    rows() {
      let records = this.formList.map(row => {
        let actions = this.makeActions(row);
        
        let status = null;
        if (row.aktiv)
          status = {label: "aktiv", type: "success"};
        else
          status = {label: "inaktiv", type: "danger"};

        let type = this.uebertragTypeValues.find(uebertragType => uebertragType.value === row.uebertragType)?.label

        return {...row, status, type, actions};
      });

      return records
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    openCopyFormModal(row) {
      this.selectedRow = row

      this.formData = {
        id: null,
        label: row.label,
        bemerkung: null,
        uebertragType: this.isFPPlus ? row.uebertragType || 'FPPLUS_PRIVAT' : null,
      }

      this.$refs.copyFormModal.open()
    },
    copyForm() {
      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/dynamicforms/copy?formId=${this.selectedRow.id}`, this.formData, config).then(() => 
        this.initialize()
      ).catch(() => this.loading = false)
    },
    onConfirmTransferToBrokerModal() {
      if (!this.transferId) {
        return
      }
      this.loading = true

      const params = makeQueryParam({maklernr: this.transferId, formId: this.selectedRow.id})

      axios.get(`${process.env.VUE_APP_API}/dynamicforms/transfer?${params}`, config)
      .finally(() => this.loading = false)
    },
    transferToBroker(row) {
      this.selectedRow = row
      this.$refs.transferToBrokerModal.open()
    },
    setAccessForBroker() {
      if (!this.isIntern) {
        return
      }
      this.loading = true

      axios.get(`${process.env.VUE_APP_API}/dynamicforms/toggleAccess`, config).then(() => 
        this.initialize()
      ).catch(() => this.loading = false)
    },
    editFormDocuments(row) {
      if (row.owner) {
        this.selectedRow = row
        this.$router.push(`/intern/maklerauftrag/${row.id}`)
      }
    },
    openPreview(row) {
      this.loading = true

      axios.get(`${process.env.VUE_APP_API}/dynamicforms/preview?formId=${row.id}`, config).then(response => {
        if (response && response.data) {
          viewDocument({data: response.data.bytes, contentType: 'application/pdf', filename: row.label})
        }
      }).finally(() => this.loading = false)
    },
    editOrCreate(row) {
      if (row) {
        Object.keys(this.formData).forEach(k => this.formData[k] = row[k])
      } else {
        Object.keys(this.formData).forEach(k => this.formData[k] = null)
      }

      if (this.isFPPlus && !this.formData.uebertragType) {
        this.formData.uebertragType = 'FPPLUS_PRIVAT'
      }
      
      this.$refs.createFormModal.open()
    },
    onConfirmCreateFormModal() {
      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/dynamicforms/createOrEdit`, this.formData, config).then(() => 
        this.initialize()
      ).catch(() => this.loading = false)
    },
    isDateBeforeNow(stringDate) {
      if (!stringDate)
        return false

      let nowDateString = dayjs().format('DD.MM.YYYY')
      let nowDate = dayjs(nowDateString, 'DD.MM.YYYY', true)
      let date= dayjs(stringDate, 'DD.MM.YYYY', true)
      return date.isBefore(nowDate)
    },
    initialize() {
      this.loading = true

      axios.get(`${process.env.VUE_APP_API}/dynamicforms/list`, config).then(response => {
        if (response && response.data) {
          this.formList = response.data.list || []
          this.brokerHasAccess = response.data.darfBearbeiten
        }
      }).finally(() => this.loading = false)
    },
    makeActions(row) {
      const actions = [
        SimpleAction("pdf", "PhFileArrowDown", "PDF"),
        SimpleAction("COPY", "PhCopy", "kopieren")
      ]

      if (row.owner) {

        if (row.aktiv) {
          actions.push(SimpleAction("disable", "PhArrowSquareOut", "deaktivieren"))
        } else {
          actions.push(SimpleAction("release", "PhArrowSquareOut", "veröffentlichen"))
        }

        actions.push(SimpleAction("edit", "PhNotePencil", "bearbeiten"))

        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Wollen Sie den Antrag "${row.label}" wirklich löschen?`, 'Antrag löschen', "Löschen"))
      }

      if (this.isIntern) {
        actions.push(SimpleAction("transfer", "PhArrowBendDoubleUpRight", "Formular übertragen"))
      }

      return actions;
    },
    deleteForm(row) {
      this.loading = true

      axios.delete(`${process.env.VUE_APP_API}/dynamicforms/delete?id=${row.id}`, config).then(() => 
        this.initialize()    
      ).catch(() => this.loading = false)
    },
    handleEditDocument({row, key, value, objKey}) {
      this.loading = true;

      const payload = {
        id: row.id,
        [objKey]: value
      }

      axios.post(`${process.env.VUE_APP_API}/dynamicforms/executeAction?action=${key}`, payload, config)
        .then(response => {
          if (response && response.data && response.data.id) {
            const target = this.formList.find((obj) => obj.id === response.data.id);

            if (target) {
              Object.assign(target, response.data);
            }
          }
        }).finally(() => this.loading = false)
    },
    disableForm(row) {
      if (row.aktiv) {
        this.handleEditDocument({row, key: 'RELEASE', value: null, objKey: 'dateRelease'})
      }
    },
    openReleaseModal(row) {
      this.selectedRow = row
      
      // if the form is already being released in the future the date can still be changed
      if (this.selectedRow.dateRelease) {
        this.releaseFormData.releaseNow = false
        this.releaseFormData.releaseDate = dayjs(this.selectedRow.dateRelease, 'DD.MM.YYYY', true)
      } else {
        this.releaseFormData.releaseNow = true
        this.releaseFormData.releaseDate = dayjs().format('DD.MM.YYYY')
      }
      this.$refs.releaseModal.open()
    },
    onConfirmReleaseModal() {
      const dateRelease = this.releaseFormData.releaseNow ? dayjs().format('DD.MM.YYYY') : this.releaseFormData.releaseDate

      this.handleEditDocument({row: this.selectedRow, key: 'RELEASE', value: dateRelease, objKey: 'dateRelease'})
    },
  },
  beforeRouteLeave (to, from, next) {

    this.$addBreadcrumb({
      label: `zurück zu den Maklerverträgen`, 
      to,
      from,
    });

    next()
  },
}
</script>
