var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.checkZIPAndUpdateValue(_vm.zip)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              ) {
                return null
              }
              return _vm.selectNext.apply(null, arguments)
            }
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: _vm.label,
              disabled: _vm.disabled,
              labelClass: _vm.labelClass,
              validateUntouched: _vm.validateUntouched,
              placeholder: _vm.placeholder,
              suppressValidationMessage: _vm.suppressValidationMessage,
              id: _vm.id,
              isEmbedded: _vm.isEmbedded,
              small: _vm.small,
              precision: _vm.precision,
              showConfirmationIcon: _vm.showConfirmationIcon,
              isComponentHalfSize: _vm.isComponentHalfSize,
              foreign_currency: _vm.foreign_currency,
              isNotReactive: _vm.isNotReactive
            },
            on: {
              input: function($event) {
                return _vm.checkZIPAndUpdateValue(_vm.zip)
              },
              searchAction: _vm.onEnter,
              blur: _vm.onBlur
            },
            model: {
              value: _vm.zip,
              callback: function($$v) {
                _vm.zip = $$v
              },
              expression: "zip"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          class: { "results-container": true, half: _vm.isComponentHalfSize }
        },
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "loading" },
                    [_c("AnimatedSpinner"), _vm._v(" Bitte warten... ")],
                    1
                  )
                : _vm._l(_vm.results, function(result, i) {
                    return _c(
                      "div",
                      { key: "zip" + i, staticClass: "autocomplete-result" },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "result-link": true,
                              selected: _vm.indexSelected === i
                            },
                            on: {
                              click: function($event) {
                                _vm.setResult(result), (_vm.indexSelected = i)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(result.zip) +
                                ", " +
                                _vm._s(result.city)
                            ),
                            result.state
                              ? _c("span", [
                                  _vm._v(", " + _vm._s(result.state))
                                ])
                              : _vm._e(),
                            _vm.land
                              ? _c("span", [_vm._v(", " + _vm._s(result.land))])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  })
            ],
            2
          )
        ]
      ),
      this.error.length > 0
        ? [
            _c(
              "div",
              {
                key: _vm.validation.updated,
                staticClass: "input-forms__errors-container"
              },
              [
                _c("div", { key: _vm.error, staticClass: "fc-form-danger" }, [
                  _vm._v(" " + _vm._s(this.error) + " ")
                ])
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }