import { mapGetters } from 'vuex';
import CORE_TYPES from "@/store/core/types";
import { ROLES, VIEW_ROLES } from '@/router/roles';

const mixin = {
    computed: {
        ...mapGetters({
            isBypass: CORE_TYPES.GETTERS.IS_BYPASS,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            isMaklerzugang: CORE_TYPES.GETTERS.IS_MAKLERZUGANG,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            origiginIsIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        isUserUnternr() {
            return this.hasRoles([ROLES.IS_USER_UNTERNR]);
        },
        isBrokerAsIntern() {
            return this.hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN]]);
          },
        isMitarbeitersicht() {
            return this.isMaklerzugang && this.isUserUnternr;
        },
        canEditFragebogen() {
            return !this.isMitarbeitersicht && !this.isBypass && !this.isBrokerAsIntern;
        }
    }
}

export default mixin;