import { EMPLOYEE_ROLES, ROLES, VIEW_ROLES, BROKER_LEVEL_ROLES } from '@/router/roles'
import { isRolesIncludes } from '@/router/guards'
import documentsRoutes from './documents';
import UndrawMailbox from '@/components/icons/undraw/UndrawMailbox.vue'
import UndrawDiary from '@/components/icons/undraw/UndrawDiary.vue'
import UndrawFolder from '@/components/icons/undraw/UndrawFolder.vue'

import { redirectToFirstAllowedChild, } from '@/router';

const COMMUNICATION_COMPONENT_HOME = () => import(/* webpackChunkName: "communicationoverview" */ '@/views/communication/CommunicationOverview.vue');

export default [
  {
    path: '',
    redirect: 'kommunikationsubersicht'
  },
  {
    path: 'kommunikationsubersicht',
    component: COMMUNICATION_COMPONENT_HOME,
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_CUSTOMER],
          [VIEW_ROLES.VIEW_INTERN],
        ]
      },
    },
  },
  {
    path: 'postfach',
    component: () => import(/* webpackChunkName: "postfach-view" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN]
        ]
      },
    },
    children: [
      {
        path: 'overview',
        component: () => import(/* webpackChunkName: "postfach-overview" */ '@/views/communication/PostfachOverviewCards.vue'),
      },
      {
        path: 'chats',
        component: () => import(/* webpackChunkName: "postfach-chats" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            name: 'chats',
            component: () => import(/* webpackChunkName: "postfach-chats" */ '@/views/communication/TicketsNachrichten.vue'),
          },
          {
            path: 'ticket',
            name: 'ticket',
            props: true,
            component: () => import(/* webpackChunkName: "Ticket" */ '@/views/communication/Ticket.vue'),
          },
          {
            path: 'chat/:chatId/:channelId/:emailId?',
            name: 'chat',
            props: true,
            component: () => import(/* webpackChunkName: "postfach-chat" */ '@/views/communication/Ticket.vue'),
          },
        ],
      },
      {
        path: 'emails',
        component: () => import(/* webpackChunkName: "postfach-emails" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: 'inbox',
          },
          {
            path: ':box',
            component: () => import(/* webpackChunkName: "postfach-emails" */ '@/views/communication/postfach/tables/PostfachTableEmail.vue'),
          },
          {
            path: ':box/email/:commId',
            component: () => import(/* webpackChunkName: "postfach-email" */ '@/views/communication/PostfachEmail.vue'),
          },
          {
            path: ':box/makler/:commId/:insuranceId/:userId',
            meta: {
              forward: true,
            },
            component: () => import(/* webpackChunkName: "postfach-email" */ '@/views/communication/PostfachEmail.vue'),
          },
          {
            path: 'email/:commId',
            component: () => import(/* webpackChunkName: "postfach-email" */ '@/views/communication/PostfachEmail.vue'),
          },
          {
            path: 'makler/:commId/:insuranceId/:userId',
            meta: {
              forward: true,
            },
            component: () => import(/* webpackChunkName: "postfach-email" */ '@/views/communication/PostfachEmail.vue'),
          },
        ],
      },
      {
        path: 'termine',
        component: () => import(/* webpackChunkName: "postfach-termin" */ '@/views/communication/postfach/tables/PostfachTableTermin.vue'),
        meta: {
          roles: {
            allowed: [EMPLOYEE_ROLES.ZUGRIFF_TERMINE],
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
      },
      {
        path: 'aktivitaeten',
        component: () => import(/* webpackChunkName: "postfach-aktivitaeten" */ '@/views/communication/postfach/tables/PostfachTableActivities.vue'),
        meta: {
          roles: {
            allowed: [EMPLOYEE_ROLES.ZUGRIFF_AKTIVITAETEN],
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
      },
      {
        path: 'aufgaben',
        component: () => import(/* webpackChunkName: "postfach-aktivitaeten" */ '@/views/communication/postfach/tables/PostfachTableAufgaben.vue'),
        meta: {
          roles: {
            allowed: [EMPLOYEE_ROLES.ZUGRIFF_AKTIVITAETEN],
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
      },
      {
        path: 'bemerkungen',
        component: () => import(/* webpackChunkName: "postfach-bemerkungen" */ '@/views/BaseRouterView.vue'),
        meta: {
          roles: {
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "postfach-bemerkungen" */ '@/views/communication/postfach/tables/PostfachTableBemerkung.vue'),
          },
          {
            path: 'bemerkung/:commId?',
            component: () => import(/* webpackChunkName: "postfach-bemerkung" */ '@/views/communication/PostfachBemerkung.vue'),
          },
        ],
      },
      {
        path: 'talks',
        component: () => import(/* webpackChunkName: "postfach-talks" */ '@/views/BaseRouterView.vue'),
        meta: {
          roles: {
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "postfach-talks" */ '@/views/communication/postfach/tables/PostfachTableTalks.vue'),
          },
          {
            path: 'talk/:commId',
            component: () => import(/* webpackChunkName: "postfach-talk" */ '@/views/communication/PostfachTalk.vue'),
          },
        ],
      },
      {
        path: 'schriftverkehr',
        component: () => import(/* webpackChunkName: "postfach-schriftverkehr" */ '@/views/communication/postfach/tables/PostfachTableSchriftverkehr.vue'),
        meta: {
          roles: {
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
      },
      {
        path: 'faxes',
        component: () => import(/* webpackChunkName: "postfach-fax" */ '@/views/communication/postfach/tables/PostfachTableFax.vue'),
      },
      {
        path: 'smses',
        component: () => import(/* webpackChunkName: "postfach-smsn" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "postfach-smsn" */ '@/views/communication/postfach/tables/PostfachTableSMS.vue'),
          },
          {
            path: 'sms/:commId',
            component: () => import(/* webpackChunkName: "postfach-sms" */ '@/views/communication/PostfachSMS.vue'),
          },
        ],
      },
    ],  
  },
  {
    path: 'todo-list',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
    component: () => import(/* webpackChunkName: "TodoList" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: () => ({ path: (isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER_ONLY]) ? 'offene-unterschriften' : 'offene-unterschriften') }),
      },
      {
        path: ':page',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          },
        },
        component: () => import(/* webpackChunkName: "TodoList" */ '@/views/communication/TodoList.vue'),
      },
    ]
  },
  {
    path: 'calendar',
    component: () => import(/* webpackChunkName: "base-calendar" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER_ONLY, EMPLOYEE_ROLES.ZUGRIFF_TERMINE], [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    children: [
      {
        path: '',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/home/Calendar.vue'),
        children: [
          {
            path: '',
            redirect: 'weekcalendar',
          },
          {
            path: 'monatcalendar',
            meta: {
              type: 'month',
            },
            component: () => import(/* webpackChunkName: "month-calendar" */ '@/components/calendar/MonthCalendar.vue'),
          },
          {
            path: 'weekcalendar',
            meta: {
              type: 'week',
            },
            component: () => import(/* webpackChunkName: "week-calendar" */ '@/components/calendar/WeekCalendar.vue'),
          },
          {
            path: 'arbeitswoche',
            meta: {
              type: 'arbeitswoche',
            },
            component: () => import(/* webpackChunkName: "arbeitswoche-calendar" */ '@/components/calendar/WeekCalendar.vue'),
          },
          {
            path: 'daycalendar',
            meta: {
              type: 'day',
            },
            component: () => import(/* webpackChunkName: "calendar-day" */ '@/components/calendar/Day.vue')
          },
        ],
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "calendar-settings" */ '@/components/calendar/settings/CalendarSettings.vue'),
      },
    ],  
  },
  {
    path: 'appointment',
    name: 'appointment',
    component: () => import(
      /* webpackChunkName: "Appointment" */
        '@/components/calendar/appointmentFiles/Appointment.vue'),
  },
  {
    path: 'appointment/:id',
    component: () => import(
      /* webpackChunkName: "AppointmentId" */
        '@/components/calendar/appointmentFiles/Appointment.vue'),
  },
  {
    path: 'mailcomposer',
    name: 'mailcomposer',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      type: 'ONE_MAIL',
      mailMode: 'OTHER',
      modus: 'ONE_MAIL',
      cacheParticipants: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      },
    },
  },
  {
    path: 'mailcomposer-postfach',
    name: 'mailcomposer-postfach',
    component: () => import(
      /* webpackChunkName: "MailComposerP" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      type: 'ONE_MAIL',
      mailMode: 'OTHER',
      modus: 'ONE_MAIL',
      postfach: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-bulk',
    name: 'mailcomposer-bulk',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'RUND_MAIL',
      type: 'MANY_MAIL',
      modus: 'MANY_MAIL',
      cacheParticipants: true,
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_INTERN]
        ]
      },
    },
  },
  {
    path: 'mailcomposer-single',
    name: 'mailcomposer-single',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      navBack: 'mailcomposer-single',
      type: 'ONE_MAIL',
      mailMode: 'OTHER',
      modus: 'ONE_MAIL',
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-insurance',
    name: 'mailcomposer-insurance',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'INSURANCE',
      type: 'MANY_MAIL',
      modus: 'ONE_MAIL',
      insurance: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-insurance-rund',
    name: 'mailcomposer-insurance-rund',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'INSURANCE',
      type: 'MANY_MAIL',
      modus: 'MANY_MAIL',
      insurance: true,
      insuranceRund: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
  },
  {
    path: 'mailcomposer-fondsAntragCheck/:id',
    name: 'mailcomposer-fondsAntragCheck',
    component: () => import(
      /* webpackChunkName: "fondsAntragCheck" */
        '@/components/mailcomposer/types/AntragMail.vue'),
    meta: {
      mailMode: 'FONDS_ANTRAG_CHECK',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      fondsAntragCheck:true,
      roles: {
        allowed: [ROLES.VIEW_ANTRAG_CHECK]
      },
    },
  },
  {
    path: 'mailcomposer-insurance-cancel',
    name: 'mailcomposer-insurance-cancel',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'INSURANCE_CANCEL_COMPANY',
      type: 'MANY_MAIL',
      modus: 'MANY_MAIL',
      insuranceCancel: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-insurance-cancel_one',
    name: 'mailcomposer-insurance-cancel_one',
    component: () => import(
      
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'INSURANCE_CANCEL_COMPANY',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      insuranceCancel: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  }, 

  {
    path: 'mailcomposer-investment/:id',
    name: 'mailcomposer-investment',
    component: () => import(
      /* webpackChunkName: "AnlageempfehlungMail" */
        '@/components/mailcomposer/types/AnlageempfehlungMail.vue'),
    meta: {
      mailMode: 'INVESTMENT',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      investment: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-antrag/:id',
    name: 'mailcomposer-antrag',
    component: () => import(
      /* webpackChunkName: "AntragMail" */
        '@/components/mailcomposer/types/AntragMail.vue'),
    meta: {
      mailMode: 'ANTRAG',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      antrag: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-appointment/:id',
    name: 'mailcomposer-appointment',
    component: () => import(
      /* webpackChunkName: "AppointmentMail" */
        '@/components/mailcomposer/types/AppointmentMail.vue'),
    meta: {
      mailMode: 'ANTRAG',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      appointment: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-ansprechpartner/:id',
    name: 'mailcomposer-ansprechpartner',
    component: () => import(
      /* webpackChunkName: "AnsprechpartnerMail" */
        '@/components/mailcomposer/types/AnsprechpartnerMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      ansprechpartner: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      },
    },
  },
  {
    path: 'mailcomposer-auftragsdokumente/:id',
    name: 'mailcomposer-auftragsdokumente',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'AUFTRAGSDOKUMENTE',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-schadensmeldung/:id',
    name: 'mailcomposer-schadensmeldung',
    component: () => import(
      /* webpackChunkName: "SchadensmeldunMail" */
        '@/components/mailcomposer/types/SchadensmeldunMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      schadensmeldung: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
  },
  {
    path: 'mailcomposer-fax-antrag/:id',
    name: 'mailcomposer-fax-antrag',
    component: () => import(
      /* webpackChunkName: "AntragMail" */
        '@/components/mailcomposer/types/AntragMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'FAX',
      modus: 'ONE_MAIL',
      antrag: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'mailcomposer-beratungsmappen/:id',
    name: 'mailcomposer-beratungsmappen',
    component: () => import(
      /* webpackChunkName: "BeratungsmappenMail" */
        '@/components/mailcomposer/types/BeratungsmappenMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      beratungsmappen: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
      routeId: '4e3599de-8e80-4bce-95c8-6b5d19fe2f56',
      breadcrumb: {
        label: 'Beratungsmappen',
      },
    },
  },
  {
    path: 'mailcomposer-vers-uebertrag-block-gesell/:id',
    name: 'mailcomposer-vers-uebertrag-block-gesell',
    component: () => import(
      /* webpackChunkName: "vuBlock" */
      '@/components/mailcomposer/types/VersUebertragBlockGesellMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      versUebertragBlockGesell: true,
      roles: {
        allowed: [ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN]
      },
    },
  },
  {
    path: 'mailcomposer-versdoku/:id',
    name: 'mailcomposer-versdoku',
    component: () => import(
      /* webpackChunkName: "vuBlock" */
      '@/components/mailcomposer/types/VersDokuMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'ONE_MAIL',
      modus: 'ONE_MAIL',
      versDoku: true,
      roles: {
        allowed: [ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN]
      },
    },
  },
  {
    path: 'mailcomposer-fax-versdoku/:id',
    name: 'mailcomposer-fax-versdoku',
    component: () => import(
      /* webpackChunkName: "vuBlock" */
      '@/components/mailcomposer/types/VersDokuMail.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'FAX',
      modus: 'ONE_MAIL',
      versDoku: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_BROKER]
      },
    },
  },
  {
    path: 'calendar-customer-search',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
    children: [
      {
        path: '',
        redirect: 'customer',
      },
      {
        path: ':folder',
        component: () => import(/* webpackChunkName: "calendar-customer-search" */ '@/components/customerSearch/CustomerSearchSelectItems.vue'),
      },
    ],
  },
  {
    path: 'calendar-makler-search',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
    component: () => import(/* webpackChunkName: "calendar-makler-search" */ '@/components/brokerSearch/BrokerSearchSelectItems.vue'),
  },
  {
    path: 'calendar-gesellschaften-search',
    component: () => import(/* webpackChunkName: "calendar-gesellschaften-search" */ '@/views/gesellschaft/broker/GesellschaftAnsprechpartnerAuswahl.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      }
    },
  },
  {
    path: 'calendar-kontakte-search',
    component: () => import(/* webpackChunkName: "calendar-kontakte-search" */ '@/components/calendar/appointmentFiles/KontakteAuswahl.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN]
      }
    },
  },
  {
    path: 'mailcomposer-customer-search/:type',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_INTERN]
      },
    },
    children: [
      {
        path: '',
        redirect: 'customer',
      },
      {
        path: ':folder',
        component: () => import(/* webpackChunkName: "mailcomposer-customer-search" */ '@/components/customerSearch/CustomerSearchSelectItems.vue'),
        meta: {
          isCache: true,
        },
      },
    ],
  },
  {
    path: 'mailcomposer-dokumentenarchiv/:type',
    component: () => import(/* webpackChunkName: "documents" */ '@/views/documents/MailComposerDocuments.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN]
      }
    },
    children: [
    ]
  },
  {
    path: 'mailcomposer-dokumentenarchiv/:type/*',
    component: () => import(/* webpackChunkName: "documents" */ '@/views/documents/MailComposerDocuments.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN]
      }
    },    
  },
  {
    path: 'schadensmeldung-dokumentenarchiv',
    component: () => import(/* webpackChunkName: "schadensmeldung-dokumentenarchiv" */ '@/views/documents/SchadensmeldungDocuments.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      }
    },
    children: [
    ]
  },   
  {
    path: 'schadensmeldung-dokumentenarchiv/*',
    component: () => import(/* webpackChunkName: "schadensmeldung-dokumentenarchiv" */ '@/views/documents/SchadensmeldungDocuments.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      }
    },
    children: [
    ]
  },    
  {
    path: 'mailcomposer-app',
    name: 'mailcomposer-app',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'MAIL_WITH_SMS',
      type: 'APP',
      modus: 'ONE_MAIL',
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      },
    },
  },
  {
    path: 'send-pin-via-sms',
    name: 'send-pin-via-sms',
    components: {
      default: COMMUNICATION_COMPONENT_HOME,
      modal: () => import(/* webpackChunkName: "send-pin-via-sms" */ '@/views/communication/SendPinViaSMS.vue'),
    },
    meta: {
      roles: {
        allowed: [[ROLES.ALLOW_SEND_PIN_VIA_SMS, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]],
      },
    },
  },
  {
    path: 'mailcomposer-brief',
    name: 'mailcomposer-brief',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'SERIAL_BRIEF',
      type: 'BRIEF',
      modus: 'MANY_MAIL',
      menu: {
        label: 'Brief an Kunde',
        component: UndrawMailbox,
      },
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      },
      routeId: '90754de3-829a-9868-ba06-152csdf754b8p',
      breadcrumb: {
        label: 'Brief an Kunde',
      },
    },
  },
  {
    path: 'mailcomposer-brief-email',
    name: 'mailcomposer-brief-email',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'SERIAL_BRIEF',
      type: 'BRIEF_MAIL',
      modus: 'MANY_MAIL',
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN]
      },
      routeId: '90754de3-829a-9868-ba06-152csdf754b8p',
      breadcrumb: {
        label: 'Brief an Kunde',
      },
    },
  },
  {
    path: 'mailcomposer-brief-insurance',
    name: 'mailcomposer-brief-insurance',
    component: () => import(
      /* webpackChunkName: "MailComposerBI" */
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'SERIAL_BRIEF',
      type: 'BRIEF',
      modus: 'MANY_MAIL',
      insuranceBrief: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
      routeId: '20754de3-829a-9868-ba06-152csdf754b91',
      breadcrumb: {
        label: 'Brief an Kunde',
      },
    },
  },
  {
    path: 'mailcomposer-brief-insurance-email',
    name: 'mailcomposer-brief-insurance-email',
    component: () => import(
      /* webpackChunkName: "MailComposerBI" */
        '@/components/mailcomposer/types/InsuranceMail.vue'),
    meta: {
      mailMode: 'SERIAL_BRIEF',
      type: 'BRIEF_MAIL',
      modus: 'MANY_MAIL',
      insuranceBrief: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER]
      },
      routeId: '20754de3-829a-5868-ba16-152csdf754b31',
      breadcrumb: {
        label: 'Brief an Kunde',
      },
    },
  },
  {
    path: 'mailcomposer-serienbrief',
    name: 'mailcomposer-serienbrief',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'SERIAL_BRIEF',
      type: 'BRIEF',
      modus: 'MANY_MAIL',
      serienbrief: true,
      menu: {  label: 'Serienbrief'},
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_INTERN]
        ]
      },
      routeId: '18754de3-829a-9868-ba06-152csdf75437p',
      breadcrumb: {
        label: 'Serienbrief',
      },
    },
  },
  {
    path: 'mailcomposer-fax',
    name: 'mailcomposer-fax',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'FAX',
      modus: 'ONE_MAIL',
      menu: {
        label: 'Fax an Kunde',
        component: UndrawDiary,
      },
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      },
      routeId: '75382de3-829a-9868-ba06-152csdf754b8p',
      breadcrumb: {
        label: 'Fax an Kunde',
      },
    },
  },
  {
    path: 'mailcomposer-fax-send',
    name: 'mailcomposer-fax-send',
    component: () => import(
      /* webpackChunkName: "MailComposer" */
        '@/components/mailcomposer/MailComposer.vue'),
    meta: {
      mailMode: 'OTHER',
      type: 'FAX',
      modus: 'MANY_MAIL',
      menu: {
        label: 'Fax versenden',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_INTERN]
        ]
      },
      routeId: '75382de3-829a-1313-ba06-152csdf754b8p',
      breadcrumb: {
        label: 'Fax versenden',
      },
    },
  },
  {
    path: 'tickets',
    component: () => import(/* webpackChunkName: "TicketsNachrichten" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: 'new-ticket',
        name: 'new-ticket',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER, ROLES.DATA_PRIVACY_APP],
              [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
              [VIEW_ROLES.VIEW_BROKER],
              [VIEW_ROLES.VIEW_INTERN]
            ],
          },
        },
        props: true,      
        component: () => import(/* webpackChunkName: "Ticket" */ '@/views/communication/Ticket.vue'),
      },
    ]
  },
  {
    path: 'email-not-recognized',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "email-not-recognized" */ '@/views/communication/EmailNotRecognized.vue')
  },
  {
    path: 'documents',
    component: () => import(/* webpackChunkName: "base-documents-view" */ '@/views/core/base-router-view/BaseDocumentsView.vue'),
    meta: {
      menu: { label: 'Dokumente', component: UndrawFolder, },
      breadcrumb: 'Dokumente',
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER],
          [VIEW_ROLES.VIEW_BROKER]
        ]
      }
    },
    children: [...documentsRoutes]
  },
  
  {
    path: 'versandprtokolle',
    component: () => import(/* webpackChunkName: "versandprtokolle" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN]
        ]
      }, 
    },
    children: [
      {
        path: '',
        name: 'versandprtokolle',
        component: () => import(/* webpackChunkName: "versandprtokolle" */ '@/views/communication/versandProtokolle/VersandProtokolle.vue'),
      },
      {
        path: 'eingang/:eingangId',
        component: () => import(/* webpackChunkName: "versandprtokolle-eingang" */ '@/views/communication/versandProtokolle/VpCommunications.vue'),
      },
      {
        path: 'pages/:eingangId',
        component: () => import(/* webpackChunkName: "versandprtokolle-eingang" */ '@/views/communication/versandProtokolle/VpPages.vue'),
        
      },
    ],
  },
  {
    path: 'setupemails',
    name: 'setupemails',
    component: () => import(/* webpackChunkName: "setupemails" */ '@/views/templatesEdit/SetupEmailTexts.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER], 
          [VIEW_ROLES.VIEW_INTERN], 
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
        ]
      },
    },
  },
  {
    path: 'mailvorlage',
    component: () => import(/* webpackChunkName: "mail-brief-vorlage-ubersicht" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    children: [
      {
        path: '',
        name: 'communication-mailvorlage',
        redirect: (to) => redirectToFirstAllowedChild(to, ['list', 'template-background']),
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-list" */ '@/views/templatesEdit/MailBriefVorlageList.vue'),
      },
      {
        path: 'template-background',
        component: () => import(/* webpackChunkName: "mail-brief-background-template" */ '@/views/templatesEdit/MailBriefeVorlageBackgroundTemplate.vue'),
      },
      {
        path: 'email/:id',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-edit" */ '@/views/templatesEdit/MailBriefVorlageEdit.vue'),
      },
      {
        path: 'word/:id',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-word-edit" */ '@/views/templatesEdit/MailBriefVorlageWordEdit.vue'),
      },
    ],
  },
  {
    path: 'html-shortcuts',
    component: () => import(/* webpackChunkName: "html-shortcuts-ubersicht" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [/*[VIEW_ROLES.VIEW_BROKER],*/ [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "html-shortcuts-edit" */ '@/views/templatesEdit/EditorShortcutsList.vue'),
      },
      {
        path: ':key',
        component: () => import(/* webpackChunkName: "html-shortcuts-edit" */ '@/views/templatesEdit/EditorShortcutsEdit.vue'),
      },
    ],
  },

  {
    path: 'rundmailstatus',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
      }
    },
    component: () => import(/* webpackChunkName: "rundmailstatus" */ '@/views/intern/RundmailStatus.vue'),
    children:[
    ]
  },      

  {
    path: 'dateihochladen',
    component: () => import(/* webpackChunkName: "Dateihochladen" */ '@/views/intern/PdfUpload.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]
        ]
      },
    }
  },
  {
    path: 'offene-unterschriften',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]
        ],
      }
    },
    component: () => import(/* webpackChunkName: "offene-unterschriften" */ '@/views/intern/OpenSignsBroker.vue'),
  },
  {
    path: 'kunden-aktionen',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER]],
      }
    },
    component: () => import(/* webpackChunkName: "kunden-aktionen" */ '@/views/communication/kundenAktionen/KundenAktionen.vue'),
  },
  {
    path: 'stoppuhr',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]],
      }
    },
    component: () => import(/* webpackChunkName: "stoppuhr" */ '@/views/communication/stoppuhr/Stoppuhr.vue'),
  },
  {
    path: 'sipgate',
    meta: {
      roles: {
        allowed: [ [ROLES.VIEW_SIPGATE]],
      },
      isMitarbeiter: true,
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/sipgate/Sipgate'),
    children:[
    ]
  },
  {
    path: 'kontakte',
    name: 'kontakte',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    children: [
      {
        path: '',
        redirect: 'contact',
      },
      {
        path: ':folder',
        component: () => import(/* webpackChunkName: "kontakte" */ '@/components/customerSearch/CustomerSearchSelectItems.vue'),
        props: {
          isExternalSelect: false,
        },
      },
    ],
  },
  
  {
    path: 'chat_test_session_query',
    meta: {
      roles: {
        allowed: [ [ROLES.CHAT_TEST_SESSION_QUERY]],
      },
     
    },
    component: () => import( '@/views/chattestquery/chattestquery.vue'),
  },
  
  
  {
    path: 'absence-note',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
      }
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/absenceNote/AbsenceNoteSettings.vue'),
    children:[
    ]
  },
]