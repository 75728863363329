var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: "f23501fe-86e6-4cd7-99b2-4884429d7425",
          title: "Auswahl Vertragsart",
          subtitle: _vm.categorySelected && _vm.categorySelected.name,
          defaultMenu: _vm.customOptionMenu,
          isLoading: _vm.loading
        }
      }),
      _vm.categorySelected && _vm.categorySelected.groups.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString("box__container-suchen")
              }
            },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Suchen")]),
              _c("InputField", {
                attrs: {
                  isComponentHalfSize: "",
                  placeholder: "Suchen nach Bezeichnung"
                },
                model: {
                  value: _vm.descriptionSearch,
                  callback: function($$v) {
                    _vm.descriptionSearch = $$v
                  },
                  expression: "descriptionSearch"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        _vm._l(_vm.groups, function(group) {
          return _c("div", { key: group.id, staticClass: "box__container" }, [
            _c("div", { staticClass: "group_title" }, [
              _vm._v(_vm._s(group.name))
            ]),
            _c(
              "div",
              { staticClass: "masonry-container group items" },
              _vm._l(_vm.filteredBezeichnungen(group), function(
                bezeichnung,
                subIndex
              ) {
                return _c(
                  "div",
                  {
                    key: subIndex,
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.handleLabelClick(bezeichnung)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(bezeichnung.label) + " ")]
                )
              }),
              0
            )
          ])
        }),
        0
      ),
      _vm.showAddVersicherungModal
        ? _c("AddVersicherungModal", {
            on: {
              close: function($event) {
                _vm.showAddVersicherungModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }