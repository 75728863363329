export const MUTATION_PREFIX = 'DEPOTPOSITIONENEDIT_MUTATION_: ';
export const ACTIONS_PREFIX = 'DEPOTPOSITIONENEDIT_ACTIONS_';
export const GETTERS_PREFIX = 'DEPOTPOSITIONENEDIT_GETTERS_';

export default {
  GETTERS: {
    FILTER: GETTERS_PREFIX + 'FILTER',
    TABLE_DATA: GETTERS_PREFIX + 'TABLE_DATA',
    DEL_BGS: GETTERS_PREFIX + 'GET_BGS',
    BGS: GETTERS_PREFIX + 'BGS',
    PARAM: MUTATION_PREFIX + 'PARAM',
    HINWEIS: MUTATION_PREFIX + 'HINWEIS',
    PAGE_INDEX: GETTERS_PREFIX + 'PAGE_INDEX',
    TOTAL_ROWS: GETTERS_PREFIX + 'TOTAL_ROWS',
    TRANSAKTIONEN_UEBERTRAGEN_DATA: GETTERS_PREFIX + 'TRANSAKTIONEN_UEBERTRAGEN_DATA',
  },
  MUTATIONS: {
    FILTER: MUTATION_PREFIX + 'FILTER',
    TABLE_DATA: MUTATION_PREFIX + 'TABLE_DATA',
    BGS: MUTATION_PREFIX + 'BGS',
    CLEAR: MUTATION_PREFIX + 'CLEAR',
    PARAM: MUTATION_PREFIX + 'PARAM',
    HINWEIS: MUTATION_PREFIX + 'HINWEIS',
    PATCH_BEMERKUNG_SUCCESS: MUTATION_PREFIX + 'PATCH_BEMERKUNG_SUCCESS',
    UPDATE_PAGE_INDEX: MUTATION_PREFIX + 'UPDATE_PAGE_INDEX',
    UPDATE_PAGE_LIMIT: MUTATION_PREFIX + 'UPDATE_PAGE_LIMIT',
    TRANSAKTIONEN_UEBERTRAGEN_DATA_SUCCESS: MUTATION_PREFIX + 'TRANSAKTIONEN_UEBERTRAGEN_DATA_SUCCESS',
  },
  ACTIONS: {
    FILTER: ACTIONS_PREFIX + 'FILTER',
    JOB: ACTIONS_PREFIX + 'JOB',
    XLS: ACTIONS_PREFIX + 'XLS',
    GET_BGS: ACTIONS_PREFIX + 'GET_BGS',
    DEL_BGS: ACTIONS_PREFIX + 'DEL_BGS',
    COURTAGE_BGS: GETTERS_PREFIX + 'COURTAGE_BGS',
    SAVE: ACTIONS_PREFIX + 'SAVE',
    SAVE_BGS: ACTIONS_PREFIX + 'SAVE_BGS',
    DEL_PF_KND: ACTIONS_PREFIX + 'DEL_PF_KND',
    GET_VAL: ACTIONS_PREFIX + 'GET_VAL',
    SAVE_COURTAGE: ACTIONS_PREFIX + 'SAVE_COURTAGE',
    PATCH_BEMERKUNG: ACTIONS_PREFIX + 'PATCH_BEMERKUNG',
    TABLE_DATA: ACTIONS_PREFIX + 'TABLE_DATA',
    GET_TRANSAKTIONEN_UEBERTRAGEN_DATA: ACTIONS_PREFIX + 'GET_TRANSAKTIONEN_UEBERTRAGEN_DATA',
    TRANSAKTIONEN_UEBERTRAGEN: ACTIONS_PREFIX + 'TRANSAKTIONEN_UEBERTRAGEN',
    CREATE_XLS_OR_PDF: ACTIONS_PREFIX + 'CREATE_XLS_OR_PDF',
    GETINFO: ACTIONS_PREFIX + 'GETINFO',
  },
}

