<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" 
    />
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  components: {
    OptionMenu,
  }
}
</script>
