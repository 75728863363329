<template>
    <div>
        <OptionMenu
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="$appNavigation.currentOptionMenu"
        />
        <PageHeaderTitleNavigation
            :id="$appNavigation.currentOptionMenuId"
            :title="selectedPositionLabel"
        />
        <div>
            <FondsFinderSuchMaske />

            <FondsFinderPositionsList
                :goBackEnabled="true"
                :singleSelectionProp="true"   
                zurueckButtonText="Zurück zur Wertpapierumschichtung" 
                @goBack="closeFondsfinder"
            />
        </div>
    </div>
</template>

<script>
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import wertpapiereMixin from './wertpapiere-mixin';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
    mixins: [wertpapiereMixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        FondsFinderPositionsList,
        FondsFinderSuchMaske,
    },
    computed: {
        lagerstelle() {
            return this.$route.query.lagerstelle || '';
        },
        wertpapier() {
            return '';
        }
    },
    methods: {
        closeFondsfinder(fonds) {   
            if (fonds?.length) {
                this.updateStore({tauschInIsin: fonds[0].isin, tauschInIsin_wertpapiername: fonds[0]?.wertpapiername})
            }
            this.$router.push({path: '/beratung/wertpapiere-alle-kunden/tausch/wertpapierauswahl', query: {backAction: 'true'}});
        }
    }
}
</script>

<style>

</style>