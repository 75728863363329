var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Wertpapiersuche"
        }
      }),
      _c("WertpapierSearch", {
        attrs: {
          name: "searchMenu",
          respond: 2,
          type: "I",
          withZ: "",
          isSelectable: "",
          defaultOptions: _vm.defaultOptions
        },
        on: {
          selected: function($event) {
            _vm.selectedRows = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "afterTable",
            fn: function() {
              return [
                _c("BaseButton", {
                  staticClass: "mt-2",
                  attrs: { isSecondary: "", label: "Zurück" },
                  on: {
                    click: function($event) {
                      return _vm.goToLastAction()
                    }
                  }
                }),
                _c(
                  "BaseButton",
                  {
                    staticClass: "mt-2",
                    attrs: {
                      disabled: !_vm.selectedRows.length || _vm.loading
                    },
                    on: { click: _vm.onSelectionDone }
                  },
                  [_vm._v(" Markierungen übernehmen ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }