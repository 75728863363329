var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Depotstammdaten"
        }
      }),
      _c(
        "div",
        { class: ["box__container dashboard-panel-widget", _vm.sizeClass] },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Kontonummer")]),
          _c("div", [_vm._v(_vm._s(_vm.kontonummer))])
        ]
      ),
      _c(
        "div",
        { class: ["box__container dashboard-panel-widget", _vm.sizeClass] },
        [
          _c(
            "div",
            { staticClass: "box__container-rows" },
            _vm._l(_vm.depotstammdatenComputed, function(stammdaten, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "dashboard-panel-widget__content label-value--list"
                },
                [
                  _c("div", { staticClass: "label-value--item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "label-value--item-label text-left font-bold"
                      },
                      [_vm._v(_vm._s(stammdaten.title) + ":")]
                    ),
                    _c(
                      "div",
                      { staticClass: "label-value--item-value text-right" },
                      [
                        stammdaten.format === "Currency"
                          ? _c("CurrencyLabel", {
                              attrs: { value: stammdaten.value, symb: "€" }
                            })
                          : stammdaten.format === "Procent"
                          ? _c("CurrencyLabel", {
                              attrs: { value: stammdaten.value, symb: "%" }
                            })
                          : stammdaten.format === "Double"
                          ? _c("CurrencyLabel", {
                              attrs: { value: stammdaten.value, symb: "" }
                            })
                          : _c("span", [_vm._v(_vm._s(stammdaten.value))])
                      ],
                      1
                    )
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }