<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      :actions="headerActions" 
      subtitle="Externe Zugänge einrichten" 
    />

    <div class="row my-0" v-if="configStep">
      <div class="d-lg-none col-12">
        <div class="antrag-step__container box__container">
          <Stepper2
            stepType="step"
            :visible="true"
            :selectedStepKey="selectedStep"
            :stepperService="getStepperMediator"
            @setStep="setSelectedStep"
            ref="pieStepper"
          ></Stepper2> 
        </div>
      </div>
    </div>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigVerticalStep
          ref="stepper"
          :configStep="configStep"
          :selectedStep="selectedStep"
          :configData="configData"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
        />
      </template>

      <template #content>
        <div :key="selectedStep">
          <ExterneZugaengeConfigStep :isLoading="loading" />
        </div>
      </template>
    </ContentWithStepper>
  </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigVerticalStep from '@/components/core/configs/ConfigVerticalStep.vue';

import ExterneZugaengeConfigStep from './steps/ExterneZugaengeConfigStep.vue';
import mixin from '@/mixins/externe-zugaenge/externe-zugaenge-mixin.js';
import Stepper2 from '@/components/stepper/Stepper2.vue';
import { StepperMediator } from '@/components/stepper/utils';
import { PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils';
import CORE_TYPES from "@/store/core/types";

export default {
  mixins: [mixin],
  data() {
    return {
      selectedStep: null,
      stepperCollapsed: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      externeZugangConfigData: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.CONFIG_DATA,
      selectedZugangsArt: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.SELECTED_ZUGANGSART,
      isBrokerFK: CORE_TYPES.GETTERS.IS_FK,
    }),
    zugangsArt() {
      return [ ...this.externeZugangConfigData?.zugangsArt || [], ];
    },
    configStep() {
      return [
        ...this.zugangsArt.map((art, index) => ({
          stepKey: art.id,
          label: art.title,
          index,
        })),
      ];
    },
    configData() {
      return {};
    },
    getStepperMediator() {
      if (this.configStep) {
        return new StepperMediator(JSON.parse(JSON.stringify(this.configStep)));
      }
      return null;
    },
    headerActions() {
      const actions = [];
      if(this.selectedStep=='BiPro' && this.isBrokerFK) {
        actions.push(PageHeaderDownloadLinkAction('DOWNLOAD', 'Benutzerleitfaden für Login-in Beantragung', 
          'Übersicht GS_Bipro_smartMSC_Stand 10.10.2023_aktiv.pdf', '/benutzerLeitfadenBipro'));
      }
      return actions;
    },
  },
  watch: {
    configStep: {
      handler() {
        if(!this.selectedStep && this.configStep?.length) {
          this.setSelectedStep(this.$route.query.backAction && this.selectedZugangsArt?.id ? this.selectedZugangsArt?.id : this.configStep?.[0]?.stepKey);
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_CONFIG_DATA);
      this.findList();
    },
    async findList() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_LIST);
      } finally {
        this.loading = false;
      }
    },
    discardChanges() {
    },
    setSelectedStep(stepKey) {
      this.selectedStep = stepKey;
      this.$store.commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_SELECTED_ZUGANGSART, stepKey);
    },
  },
  mounted() {
    this.init();
    this.checkForCallback();
  },
  async beforeRouteLeave(to, from, next) {
    this.$store.commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.RESET_STATE);
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    PageHeaderActionCard,
    ContentWithStepper,
    ConfigVerticalStep,
    ExterneZugaengeConfigStep,
    Stepper2,
  },
}
</script>
