<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <DashboardPanel
      id="5d00853a-a67d-4b41-81a7-872bef10f95d"
      :data="dashboardData"
      :externalConfig="externalWidgetsConfig"
      :isExternalLoading="externalLoading"
      @saved="saveInternOverviewConfiguration($event)"
      @restored="saveInternOverviewConfiguration($event)"
      @executeAction="handleExecuteAction($event)"
    >
    </DashboardPanel>
    
    <BaseModal ref="fkEventsModal" labelButtonCancel="Schließen" :showConfirmButton="false">
      <template v-slot:modalTitle>
        {{fkEventsTitle}}
      </template>
      <div>
        <EventsFkOverview />
      </div>
    </BaseModal>    
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DASHBOARD_TYPES from '@/components/dashboard/store/types';
import MENU_TYPES from '@/store/menu/types'
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import EventsFkOverview from '@/views/home/EventsFkOverview.vue';
import { mapGetters } from 'vuex';
import { EMPLOYEE_ROLES, ROLES,  } from '@/router/roles'
import CORE_TYPES from '@/store/core/types'
import BROKER_SEARCH_TYPES from '@/store/brokerSearch/types';

const MAP_WIDGET_NAME_TO_PARAMETER_NAME = {
  'MscNewsList': 'INTERN_UEBERSICHT_NEWS',
  'TermineList': 'INTERN_UEBERSICHT_KALENDER_HEUTE',
  'TicketsNachrichtenCard': 'INTERN_UEBERSICHT_TICKET',
  'FkEvents': 'FK_EVENTS',
};



export default {
  mixins: [],
  data() {
    return {
      externalLoading: false,
    }
  },  
  computed: {
    ...mapGetters({
      internOverviewConfiguration: DASHBOARD_TYPES.GETTERS.INTERN_OVERVIEW_CONFIGURATION,
      isOptionMenuPathVisibile: MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      // hatVertrag: CORE_TYPES.GETTERS.HAT_VERTRAG,
    }),    
    isFKEventsVisible() {
      const fullPath = this.$router.match({ name: 'fk-events'})?.fullPath || ''
      const isVisible = this.isOptionMenuPathVisibile(fullPath)

      return isVisible && this.hatVertrag && this.hasRoles([ROLES.SHOW_EVENTS]);
    },    
    externalWidgetsConfig() {
      const { internOverviewConfiguration, } = this;
      if(!internOverviewConfiguration) return {};

      const mapParameterNameToWidgetName = Object.keys(MAP_WIDGET_NAME_TO_PARAMETER_NAME)
        .reduce((acc, widgetName) => ({ ...acc, [MAP_WIDGET_NAME_TO_PARAMETER_NAME[widgetName]]: widgetName, }), {});

      return Object.keys(internOverviewConfiguration)
        .filter(paramName => paramName in mapParameterNameToWidgetName)
        .map(paramName => ({
          name: mapParameterNameToWidgetName[paramName],
          removed: internOverviewConfiguration[paramName] === 'Nein',
        }), []);
    },   
    hasTermineAccess() {
      return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_TERMINE]])
    },     
    dashboardData() {
      return {
        widgets: [
          {
            name: 'LastCustomersOpened',
            title: 'Zuletzt geöffnete Kunden',
            component: () => import('@/components/customerSearch/LastCustomersOpenedCarousel.vue'),
            props: {
              showTitle: false,
            },
          },
          {
            name: 'LastBrokersOpened',
            title: 'Zuletzt geöffnete Vermittler',
            component: () => import('@/components/customerSearch/LastBrokersOpenedCarousel.vue'),
            props: {
              showTitle: false,
            },
          },          
          {
            name: 'MscNewsList',
            title: 'News',
            component: () => import('@/components/mscnews/MscNewsList.vue'),
            props: {
              lastNews: true,
            },
            overrideDefaultAction: true,
          },
          {
            name: 'TermineList',
            title: 'Termine / Aufgaben',
            component: () => import('@/components/calendar/TermineList.vue'),
            props: {
              showTitle: false,
            },
            visible: () => this.hasTermineAccess,
            overrideDefaultAction: true,
          },
          {
            name: 'TicketsNachrichtenCard',
            title: 'Nachrichten',
            component: () => import('@/components/communication/TicketsNachrichtenCard.vue'),
            props: {
              showTitle: false,
            },
            overrideDefaultAction: true,
          },
          {
            name: 'FkEvents',
            title: this.fkEventsTitle,
            props: {
              showTitle: false,
            },
            component: () => import('@/views/home/EventsFkOverview.vue'),
            visible: () => this.isFKEventsVisible,
          },
        ],
      };
    },  
    fkEventsTitle() {
      return this.isFA ? 'FinanzAdmin Events' : 'FondsKonzept Events';
    }      
  },
  methods: {
    async saveInternOverviewConfiguration(widgets) {
      if(!widgets?.length) return;

      const parameters = widgets
        .filter(widget => !!MAP_WIDGET_NAME_TO_PARAMETER_NAME[widget.name])
        .reduce((acc, widget) => {
          acc[MAP_WIDGET_NAME_TO_PARAMETER_NAME[widget.name]] = widget.removed ? 'Nein' : 'Ja';
          return acc;
        }, {});
      
      try {
        this.externalLoading = true;
        await this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.SAVE_INTERN_OVERVIEW_CONFIGURATION, { parameters });
      } finally {
        this.externalLoading = false;
      }
    },
    handleExecuteAction(event) {
      if(!event?.widget || !event?.action) {
        return ;
      }

      if(event?.action?.action === 'BEARBEITEN_APP') {
        if(event?.action?.widget?.name === 'MscNewsList') {
          this.$router.push({name: 'news-msc'});
        }
        if(event?.action?.widget?.name === 'TermineList') {
          this.$router.push({ path: '/communication/postfach/termine' });
        }
        if(event?.action?.widget?.name === 'TicketsNachrichtenCard') {
          this.$router.push({ path: '/communication/postfach/chats' });
        }
        if(event?.action?.widget?.name === 'FkEvents') {
          this.$router.push({ name: 'fk-events' });
        }
      }

    },
  },
  mounted: function () {
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CONFIGURATION);
    this.externalLoading = true;
    this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.GET_INTERN_OVERVIEW_CONFIGURATION).then(() => this.externalLoading = false);
    this.$store.dispatch(BROKER_SEARCH_TYPES.ACTIONS.GET_LAST_BROKERS_OPENED);
  },  
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Übersicht', 
      to,
      from
    });

    next()
  },
  components: {
    OptionMenu,
    DashboardPanel,
    BaseModal,
    EventsFkOverview,
  }
}
</script>

<style scoped>

</style>