var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions,
          noPrimaryAction: !_vm.hasAddDocumentHeaderActions
        },
        on: {
          action: function($event) {
            return _vm.executeHeaderAction($event.value)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "clickable",
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "selectNavigationNode",
                            _vm.navigationArray[0]
                          )
                        }
                      }
                    },
                    [_vm._v(" Dokumentenarchiv ")]
                  )
                ]
              },
              proxy: true
            },
            _vm.navigationArray.length > 1 && !_vm.$isSmallScreen
              ? {
                  key: "subtitle",
                  fn: function() {
                    return [
                      _c("DokumenteBreadcrumb", {
                        staticClass: "explorer__navigation--breadcrumb",
                        attrs: {
                          navigationArray: _vm.navigationArray,
                          ignoreFirst: ""
                        },
                        on: {
                          "select-navigation-node": function($event) {
                            return _vm.$emit("selectNavigationNode", $event)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "BaseModal",
        {
          ref: "confirmationModal",
          attrs: {
            modalTitle: "Bestätigung",
            labelButtonConfirm: "Ok",
            showCancelButton: true
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmHeaderActionModal()
            }
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.confirmationMessage))])]
      ),
      _c(
        "BaseModal",
        {
          ref: "sendEmailModal",
          attrs: {
            modalTitle: "Dokumente versenden",
            showDefaultButtons: false
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeSendEmailModal()
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.confirmSendEmail()
                        }
                      }
                    },
                    [_vm._v("An Kunden")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.confirmSendEmail(true)
                        }
                      }
                    },
                    [_vm._v("An Gesellschaft")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" An wen möchten Sie die ausgewählten Dokumente versenden? ")]
      ),
      _c("EmailVersand"),
      _vm.files && _vm.currentAdditionalInputs
        ? _c("DocumentsUploadModal", {
            attrs: {
              files: _vm.files,
              additionalInputs: _vm.currentAdditionalInputs,
              parentId: _vm.nodeId,
              versicherungId: _vm.versicherungId,
              schadenId: _vm.schadenId,
              showOnlySchadenDocs: _vm.showOnlySchadenDocs,
              maxFileSizeMB: _vm.maxFileSizeMB
            },
            on: {
              close: function($event) {
                _vm.files = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }