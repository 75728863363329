<template>
  <div v-if="vertragsdatenFieldsDefinition">

    <div style="display:flex; flex-direction:column">

      <div class="row" v-if="vertragsdatenFieldsDefinition.gesellschaft.visible" :style="styleOrder('gesellschaft')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Gesellschaft"
            v-model="versicherung.gesellschaft.id"
            :values="gesellschaftList"
            :disabled="vertragsdatenFieldsDefinition.gesellschaft.disabled"
            :validateUntouched="true"
            @change="dataChanged('gesellschaft', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.displayFremdges.visible" :style="styleOrder('displayFremdges')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Zeige Fremdgesellschaften"
            v-model="versicherung.displayFremdges"
            :disabled="vertragsdatenFieldsDefinition.displayFremdges.disabled"
            @input="dataChanged('displayFremdges')"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('vertragssparte')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Vertragssparte"
            v-model="versicherung.description.id"
            :values="vertragsdatenFieldsDefinition.vertragssparte.value"
            :disabled="vertragsdatenFieldsDefinition.vertragssparte.disabled"
            :validateUntouched="true"
            @change="dataChanged('vertragssparte', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.aktuellerStatus.visible" :style="styleOrder('aktuellerStatus')">
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            v-model="versicherung.status"
            label="Aktueller Status"
            :disabled="vertragsdatenFieldsDefinition.aktuellerStatus.disabled"
            @input="dataChanged()"
          />
          <a class="align-end" style="flex-grow: 0;" @click="goToVertraghistory()">
            <PhPencilLine :size="24"/>
          </a>
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.polNr.visible" :style="styleOrder('nummer')">
        <div class="col-12 col-sm-6">
          <InputField
            id="versicherung_nummer"
            v-model="versicherung.nummer"
            :label="vertragsdatenFieldsDefinition.polNr.value"
            :disabled="vertragsdatenFieldsDefinition.polNr.disabled"
            :placeholder="vertragsdatenFieldsDefinition.polNr.validationMessage"
            :validateUntouched="true"
            @input="dataChanged('nummer')"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.produktbezeichnung.visible" :style="styleOrder('produktbezeichnung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.produktbez"
            label="Produktbezeichnung"
            :disabled="vertragsdatenFieldsDefinition.produktbezeichnung.disabled"
            :maxLength="300"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.vertragsbeginn.visible" :style="styleOrder('vertragsbeginn')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            v-model="versicherung.beginn"
            label="Vertragsbeginn"
            isValueAsString
            :disabled="vertragsdatenFieldsDefinition.vertragsbeginn.disabled"
            @input="laufzeitChanged()"
            :validateUntouched="isFPPlus"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.vertragsende.visible" :style="styleOrder('vertragsende')">
        <div class="col-12 col-sm-6 form-control">
          <InputRadioBoxGroup
            class="align-end"
            style="flex-grow: 0;"
            v-model="versicherung.anzeigeModusDauer"
            :values="[{ value: 'ENDE', label: '' }]"
            @input="versicherung.laufzeit=''"
          />
          <DatePickerField
            class="align-end"
            v-model="versicherung.ende"
            label="Vertragsende"
            isValueAsString
            :disabled="versicherung.anzeigeModusDauer === 'LAUFZEIT'"
            @input="dataChanged('vertragsende')"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.laufzeit.visible" :style="styleOrder('laufzeit')">
        <div class="col-12 col-sm-6 form-control">
          <InputRadioBoxGroup
            class="align-end"
            style="flex-grow: 0;"
            v-model="versicherung.anzeigeModusDauer"
            :values="[{ value: 'LAUFZEIT', label: '' }]"
            @input="versicherung.ende=''"
          />
          <InputField
            class="align-end"
            v-model="versicherung.laufzeit"
            label="Laufzeit (Jahre)"
            :disabled="versicherung.anzeigeModusDauer === 'ENDE'"
            @input="dataChanged('laufzeit')"
            :validateUntouched="isFPPlus"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.hauptfaelligkeit.visible" :style="styleOrder('hauptfaelligkeit')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            v-model="versicherung.hauptfaelligkeit"
            :label="vertragsdatenFieldsDefinition.hauptfaelligkeit.value"
            :disabled="vertragsdatenFieldsDefinition.hauptfaelligkeit.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieBrutto.visible" :style="styleOrder('pramieBrutto')">
        <div class="col-12 col-sm-6">
          <InputField
            id="input__field__pramieBrutto"
            type="currency"
            :precision="2"
            v-model="versicherung.praemiebto_zahlweise"
            label="Bruttoprämie gem. Zahlweise"
            :disabled="vertragsdatenFieldsDefinition.pramieBrutto.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>

      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieNetto.visible" :style="styleOrder('pramieNetto')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="versicherung.praemiento_zahlweise"
            label="Nettoprämie gem. Zahlweise"
            :disabled="vertragsdatenFieldsDefinition.pramieNetto.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.zahlrhythmus.visible" :style="styleOrder('zahlrhythmus')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Zahlweise"
            v-model="versicherung.zahlrhythm"
            :firstEmpty="true"
            :values="vertragsdatenFieldsDefinition.zahlrhythmus.value"
            :disabled="vertragsdatenFieldsDefinition.zahlrhythmus.disabled"
            @change="dataChanged('zahlrhythm', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.zahlungsart.visible" :style="styleOrder('zahlungsart')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Zahlungsart"
            v-model="versicherung.zahlungsart"
            :firstEmpty="true"
            :values="vertragsdatenFieldsDefinition.zahlungsart.value"
            :disabled="vertragsdatenFieldsDefinition.zahlungsart.disabled"
            @change="dataChanged('zahlungsart', $event)"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('bankverbindung')">
        <div class="col-12 col-sm-6">
          <EntitySelector
            label="Bankverbindung"
            v-model="versicherung.bankverbindung"
            :values="vertragsdatenFieldsDefinition.bankverbindung.value"
            @change="dataChanged('bankverbindung', $event)"
            @new="newBankverbindung()"
            @edit="editBankverbindung()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.externeKennung.visible" :style="styleOrder('externeKennung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.externeKennung"
            label="Ext. Kennung"
            :disabled="vertragsdatenFieldsDefinition.externeKennung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.eigenbestand.visible" :style="styleOrder('eigenbestand')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Eigenbestand"
            v-model="versicherung.isEigenerVertrag"
            :disabled="vertragsdatenFieldsDefinition.eigenbestand.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.fremdanlage.visible" :style="styleOrder('fremdanlage')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Fremdanlage"
            v-model="versicherung.fremdanlage"
            :disabled="vertragsdatenFieldsDefinition.fremdanlage.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.kundeEingelesen.visible" :style="styleOrder('kundeEingelesen')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Durch Kunde angelegt"
            v-model="versicherung.kundeEingelesen"
            :disabled="vertragsdatenFieldsDefinition.kundeEingelesen.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>


















      <!-- Fields whose order were not defined (See ticket MSC-27944) -->

   
       

      <div class="row" v-if="vertragsdatenFieldsDefinition.provisionsfrei.visible" :style="styleOrder('provisionsfrei')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Provisionsfrei"
            v-model="versicherung.provisionsfrei"
            :disabled="vertragsdatenFieldsDefinition.provisionsfrei.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.teilung.visible" :style="styleOrder('teilung')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Teilung geprüft"
            v-model="versicherung.teilung"
            :disabled="vertragsdatenFieldsDefinition.teilung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.anbindung.visible" :style="styleOrder('anbindung')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Anbindung"
            v-model="versicherung.anbindung"
            :values="vertragsdatenFieldsDefinition.anbindung.value"
            :disabled="vertragsdatenFieldsDefinition.anbindung.disabled"
            @change="dataChanged('anbindung', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.betreuungsstufe.visible" :style="styleOrder('betreuungsstufe')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Betreuungsstufe"
            v-model="versicherung.betreuungsstufe"
            :values="betreungsstufeComputedValues"
            :disabled="vertragsdatenFieldsDefinition.betreuungsstufe.disabled || !versicherung.anbindung || disableBetreungsstufe"
            @change="dataChanged('betreuungsstufe', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.konvertierung.visible" :style="styleOrder('konvertierung')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Konvertierung"
            v-model="versicherung.konvertierung"
            :disabled="vertragsdatenFieldsDefinition.konvertierung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.kennzeichen.visible" :style="styleOrder('kennzeichen')">
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            v-model="versicherung.kennzeichen"
            label="Kennzeichen"
            disabled
            :placeholder="vertragsdatenFieldsDefinition.kennzeichen.validationMessage"
          />
          <a v-if="!shouldHideFahrzeugDatenStep" class="align-end" style="flex-grow: 0;" @click="goToFahrzeugdaten()">
            <PhPencilLine :size="24"/>
          </a>
        </div>
      </div>    
      <div class="row" v-if="vertragsdatenFieldsDefinition.beitragszahlungsdauer.visible" :style="styleOrder('beitragszahlungsdauer')">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            v-model="versicherung.beitragszahlungsdauer"
            label="Beitragszahlungsdauer"
            :disabled="vertragsdatenFieldsDefinition.beitragszahlungsdauer.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.letzte_aenderung_gueltig_ab.visible" :style="styleOrder('letzte_aenderung_gueltig_ab')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            v-model="versicherung.letzteAenderungGueltigAb"
            isValueAsString
            label="letzte Vertragsänderung gültig ab"
            :disabled="vertragsdatenFieldsDefinition.letzte_aenderung_gueltig_ab.disabled"
            @input="dataChanged()"
          />
        </div>  
      </div>    
      <div class="row" v-if="vertragsdatenFieldsDefinition.aenderungs_grund.visible" :style="styleOrder('aenderungs_grund')">
        <div class="col-12 col-sm-6">
          <InputTextArea
            v-model="versicherung.aenderungsGrund"
            label="Änderungsgrund"
            :disabled="vertragsdatenFieldsDefinition.aenderungs_grund.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import { PhPencilLine, PhBookOpen, PhArrowSquareOut   } from 'phosphor-vue';
import validator from '@/mixins/validator';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import EntitySelector from '@/components/core/forms/EntitySelector.vue';

export default {
  mixins: [InsuranceDetailMixin, validator],
  components: {
    BoxContainer,
    InputRadioBoxGroup,
    ComboBox,
    InputField,
    DatePickerField,
    InputToggleSwitch,
    PhPencilLine,
    InputTextArea,
    PhBookOpen,
    PhArrowSquareOut,
    EntitySelector,
  },
  computed: {
    tableId() {
      const uniqueUUID = 'f3cfb91f-3016-4a8f-bef4-93c9e7c9a3b8';
      return uniqueUUID;
    },    

  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getMaklerGesellNrComboboxValues(value) {
      const treatedValue = value.replace(/\s/g, '_');

      if(!this.gesellschaftMaklerGesellNr[treatedValue]){
        const payload = {gesellschaftId: value, groupId: this.versicherung?.description?.id}
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES, payload).then(() => {
          this.vertragsdatenFieldsDefinition.maklerGesellNr.value = this.gesellschaftMaklerGesellNr[treatedValue];
        })
      } else {
        this.vertragsdatenFieldsDefinition.maklerGesellNr.value = this.gesellschaftMaklerGesellNr[treatedValue];
      }
    },
    getAnbindungComboboxValues(value) {
      if(value) {
        const payload = {gesellschaftId: value, groupId: this.versicherung?.description?.id}
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES, payload);
      } else {
        this.versicherung.nummer = '';
      }
    },
    getMaklerGesellNrComboboxItemSelectedMessage(maklerGesellNr) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_MESSAGE, {maklerGesellNr})
      .then(result => {
        if(result){
          this.$set(this.versicherung, 'vermittlernummerBemerkung', result);
        }
      })
    },
    async validateNummer(serverError) {
      const nummerPath = 'versicherung.nummer';

      this.validation.reset(nummerPath);
      if(!this.versicherung.gesellschaft.id) {
        return;
      }

      if(serverError) {
        this.$pushErrors(nummerPath, serverError);
        return;
      }

      const response = await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION, 
        { gesellschaftId: this.versicherung.gesellschaft.id, polNummer: this.versicherung.nummer });
      if(this.versicherung.nummer && !response?.valid){
        this.$pushErrors(nummerPath, response?.validationMessage || '');
      } else {
        this.validation.reset(nummerPath);
      }

      if(this?.versicherungFormValidation?.versicherung?.nummer.valid && this.versicherung?.fehler?.length){
        const index = this.versicherung?.fehler.findIndex(errorMsg => errorMsg.includes('Nummer'));
        if(index > -1) {
          this.versicherung.fehler.splice(index, 1);
        }
      }
    },      
    getPoliceNummerValidation(value){
      if(value) {
        const payload = {gesellschaftId: value}
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION, payload)
        .then(() => {
          this.validateNummer();
        })

      } else {
        this.versicherung.nummer = '';
      }
    },
    goToVertraghistory() {
      if(this.$route.params.id){
        this.$router.push({ path: `/home/versicherungen/insurance-group/vertrag-status/${this.$route.params.id}`})
      }
    },
    goToFahrzeugdaten() {
      this.$emit('changeStep', 'fahrzeugDaten');

    },
    navigateTo(event) {
      this.$router.push({
        path: event?.path ? event.path : event,
        query: {
          singleUser: this.$route.query?.singleUser,
          ...(event?.query ? event.query : {})
        },
      });
    },
  },
  mounted() {
    this.$configureValidators(this.fieldsValidators);
    if(this.versicherung?.gesellschaft?.id && this.vertragsdatenFieldsDefinition?.polNr){
      this.validateNummer();
    }
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 8px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>