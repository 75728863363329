<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Versicherungstarife" 
      :actions="headerActions" 
      @action-ADD="hinzufugen()" 
    />

    <BaseFilter 
      filterId="6552e591-cf1e-4737-ae8e-0e35ad3d7064"
      title="Versicherungstarife filtern" 
      :metadata="filterMetadata" 
      :defaultOptions="defaultOptionsData"
      v-if="gesellschaften && gesellschaften.length"
      @onFilter="filterForm($event)">
    </BaseFilter>
    <GhostLoading v-else-if="!gesellschaften || !gesellschaften.length" type="block" useBoxContainer />

    <div class="box__container" tid="f0741643-ed48-4d80-b9e1-d8e264690b01">
      <Table
        tableId="fb5a1434-a732-49c7-87ed-a43498bf9292"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :mobileConfig="tableMobileConfig"
        @click-bezeichnung="bearbeiten($event)"
        @action="executeAction($event)"
      >
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Tarif löschen" labelButtonConfirm="Löschen">
      Wollen Sie den {{selectedTarif.bezeichnung}}, {{selectedTarif.gesellschaftsname}} wirklich löschen?
    </BaseModal>


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERSICHERUNGSTARIFE_TYPES from "../../store/versicherungstarife/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, PillColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import {PhPencilLine, PhPencil, PhCheck, PhTrash, PhEnvelope} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Versicherungstarife Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    BaseButton
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        loading: false,
        defaultParamZeigeInaktive: "1",
        defaultParamNurMakler: "1",
      }
  },
  computed: {
    ...mapGetters({
      tarifeData: VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedTarif: VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_VERSICHERUNGSTARIF,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      gesellschaften: VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN,
      spartentypen: VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_SPARTENTYPEN,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Hinzufügen'),
      ];
    },
    tableHeaders() {
        return {
          lockedLeft: [
            TextColumn("bezeichnung", "Bezeichnung").makeLink().makeAlwaysVisible(),
            TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible(),
            TextColumn("sparteDescription", "Sparte"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };
    },    
    tableMobileConfig() {
      return {
        title: 'bezeichnung',
        headers: ['bezeichnung', 'gesellschaftsname', 'sparteDescription'],
        disableClickRow: false,
      }
    },
    tableRows () {
      if (!this.tarifeData?.result?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      return this.tarifeData?.result.map(data => ({
          gesellschaftsname: data.gesellschaft.zweigstelleName,
          bezeichnung: data.bezeichnung,
          sparteDescription: data?.spartentyp?.bezeichnung,
          id: data.id,
          actions: data.allowEdit ? fullActions : [],
          gesellschaft: data?.gesellschaft,
          spartentyp: data?.spartentyp,
          sparte: data?.sparte,
          besitzer: data?.besitzer,
          sehenStruktur: data?.sehenStruktur,
          inaktiv: data?.inaktiv,
          tarifgruppe: data?.tarifgruppe,
      }));
    },
    defaultOptionsData() {
      return {
          bezeichnung: {
              value: "",
          },        
          paramZeigeInaktive: {
              value: true,
          },
          paramNurMakler: {
              value: true,
          },
          ...(this.$route.query.gesellschaftId ? {[this.$route.query.gesellschaftId]: {value: true}} : {})
        } 
    },
    filterMetadata() {
        return [
          {
            type: 'group',
            key: 'allgemein',
            label: 'Allgemein',
            menuItems: [
              {
                type: "text",
                key: "bezeichnung",
                label: "Bezeichnung",
              },   
            ]  
          },     
          {
            type: 'group',
            key: 'gesellschaften',
            label: 'Gesellschaften',
            menuItems: this.gesellschaften || []
          },     
          {
            type: 'group',
            key: 'spartenTyp',
            label: 'Spartentyp',
            menuItems: this.spartentypen || []
          },                  
          {
            type: "group",
            key: "status",
            label: "Status",
            menuItems: [
              {
                type: "default",
                label: "zeige Inaktive",
                key: "paramZeigeInaktive",
              },
              {
                type: "default",
                label: "nur eigene Tarife anzeigen",
                key: "paramNurMakler",
              },
            ],
          },  
           
        ]; 
    },
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          this.bearbeiten(actionData.row);
          break;
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
      }
    },   
    confirmItemRemoval(row) {
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.REMOVE_ROW, {id: this.selectedTarif?.id}).then((response) => {
        this.retrieveData();  
      }); 
    },
    retrieveData(payload) {
      if (!payload) {
        payload = {
          paramZeigeInaktive: this.defaultParamZeigeInaktive,
          paramNurMakler: this.defaultParamNurMakler,
          paramGesellschaft: this.$route.query.gesellschaftId,
        };
      }

      this.loading = true;

      this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSTARIFE_DATA, payload).then((response) => {
        this.loading = false;
      });  
    },
    bearbeiten(row) {
        this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, row);
        this.$router.push({path: `/intern/versicherungstarife/tarifBearbeiten` });
    },
    hinzufugen() {
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE);
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, {gesellschaft: { id: null}, sparte: {values: []}});
      this.$router.push({path: `/intern/versicherungstarife/tarifBearbeiten` });
    },
    filterForm(filterConfig) {
      const paramZeigeInaktive = filterConfig.find(fc => fc.group=="status" && fc.key=="paramZeigeInaktive");
      const paramNurMakler = filterConfig.find(fc => fc.group=="status" && fc.key=="paramNurMakler");
      const paramBezeichnung = filterConfig.find(fc => fc.group=="allgemein" && fc.key=="bezeichnung");
      const paramGesellschaft = filterConfig.find(fc => fc.group=="gesellschaften");
      const paramSpartentyp = filterConfig.find(fc => fc.group=="spartenTyp");

      this.retrieveData(
        {
          paramZeigeInaktive: paramZeigeInaktive?.value, 
          paramNurMakler: paramNurMakler?.value,
          paramBezeichnung: paramBezeichnung?.value,
          paramGesellschaft: paramGesellschaft?.key,
          paramSpartentyp: paramSpartentyp?.key,
        }); 
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Versicherungstarife Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>