var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Archiv für Schema"
        }
      }),
      _c(
        "div",
        _vm._l(_vm.varianten, function(row, index) {
          return _c(
            "div",
            { key: index, staticClass: "box__container" },
            [
              _c("Table", {
                attrs: {
                  title: row && row.name,
                  headers: _vm.headers,
                  rows: (row && row.list) || [],
                  rowsPerPage: 20,
                  rowId: "vvarchivlist",
                  hidePagination: ""
                },
                on: { "click-statusPill": _vm.openVariante },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "wertpapiere",
                      fn: function(row) {
                        return [
                          row.wertpapiere && row.wertpapiere.length
                            ? _c(
                                "div",
                                _vm._l(row.wertpapiere, function(
                                  wertpapier,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _vm._v(" " + _vm._s(wertpapier) + " ")
                                  ])
                                }),
                                0
                              )
                            : _c("div")
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }