var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Formulare für Maklerauftrag Versicherungen",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.editOrCreate()
          },
          "action-TOGGLE_ACCESS": _vm.setAccessForBroker
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  hidePagination: "",
                  headers: _vm.headers,
                  rows: _vm.rows
                },
                on: {
                  "click-label": _vm.editFormDocuments,
                  "action-pdf": _vm.openPreview,
                  "action-COPY": _vm.openCopyFormModal,
                  "action-edit": _vm.editOrCreate,
                  "action-DELETE": _vm.deleteForm,
                  "action-release": _vm.openReleaseModal,
                  "action-disable": _vm.disableForm,
                  "action-transfer": _vm.transferToBroker
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "struktur",
                      fn: function(row) {
                        return [
                          row.owner
                            ? _c("InputToggleSwitch", {
                                attrs: {
                                  disabled: _vm.loading,
                                  value: row.struktur,
                                  inLineLabel: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.handleEditDocument({
                                      row: row,
                                      key: "STRUKTUR",
                                      value: $event,
                                      objKey: "struktur"
                                    })
                                  }
                                }
                              })
                            : _c("span")
                        ]
                      }
                    },
                    {
                      key: "beratung",
                      fn: function(row) {
                        return [
                          _c("ComboBox", {
                            attrs: {
                              value: row.beratung,
                              values: _vm.beratungValues,
                              disabled: _vm.loading
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleEditDocument({
                                  row: row,
                                  key: "BERATUNG",
                                  value: $event,
                                  objKey: "beratung"
                                })
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  656609909
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 5 } } }
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "releaseModal",
          attrs: {
            modalTitle: "Dokument veröffentlichen",
            labelButtonConfirm: "Veröffentlichen",
            confirmDisabled: _vm.isDateBeforeNow(
              _vm.releaseFormData.releaseDate
            )
          },
          on: { onConfirmButton: _vm.onConfirmReleaseModal }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              value: _vm.selectedRow.label,
              isComponentHalfSize: "",
              disabled: ""
            }
          }),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Veröffentlichungsdatum")
          ]),
          _c("InputToggleSwitch", {
            attrs: { label: "sofort" },
            model: {
              value: _vm.releaseFormData.releaseNow,
              callback: function($$v) {
                _vm.$set(_vm.releaseFormData, "releaseNow", $$v)
              },
              expression: "releaseFormData.releaseNow"
            }
          }),
          !_vm.releaseFormData.releaseNow
            ? _c("DatePickerField", {
                attrs: { label: "oder am", isValueAsString: "" },
                model: {
                  value: _vm.releaseFormData.releaseDate,
                  callback: function($$v) {
                    _vm.$set(_vm.releaseFormData, "releaseDate", $$v)
                  },
                  expression: "releaseFormData.releaseDate"
                }
              })
            : _vm._e(),
          _vm.isDateBeforeNow(_vm.releaseFormData.releaseDate)
            ? _c("div", { staticClass: "fc-form-danger" }, [
                _vm._v("Datum darf nicht in der Vergangenheit sein")
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createFormModal",
          attrs: {
            modalTitle: _vm.formData.id
              ? "Antrag bearbeiten"
              : "Antrag erstellen",
            labelButtonConfirm: _vm.formData.id ? "Speichern" : "Erstellen",
            confirmDisabled: !_vm.formData.label
          },
          on: { onConfirmButton: _vm.onConfirmCreateFormModal }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.formData.label,
              callback: function($$v) {
                _vm.$set(_vm.formData, "label", $$v)
              },
              expression: "formData.label"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.formData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.formData, "bemerkung", $$v)
              },
              expression: "formData.bemerkung"
            }
          }),
          _vm.isFPPlus
            ? _c("ComboBox", {
                attrs: {
                  label: "Anbindung",
                  values: _vm.uebertragTypeValues,
                  sortComboboxValues: false
                },
                model: {
                  value: _vm.formData.uebertragType,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "uebertragType", $$v)
                  },
                  expression: "formData.uebertragType"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "copyFormModal",
          attrs: {
            modalTitle: "Antrag kopieren",
            labelButtonConfirm: "Kopieren",
            confirmDisabled: !_vm.formData.label
          },
          on: { onConfirmButton: _vm.copyForm }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.formData.label,
              callback: function($$v) {
                _vm.$set(_vm.formData, "label", $$v)
              },
              expression: "formData.label"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.formData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.formData, "bemerkung", $$v)
              },
              expression: "formData.bemerkung"
            }
          }),
          _vm.isFPPlus
            ? _c("ComboBox", {
                attrs: {
                  label: "Anbindung",
                  values: _vm.uebertragTypeValues,
                  sortComboboxValues: false
                },
                model: {
                  value: _vm.formData.uebertragType,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "uebertragType", $$v)
                  },
                  expression: "formData.uebertragType"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "transferToBrokerModal",
          attrs: {
            modalTitle: "Antrag übertragen",
            labelButtonConfirm: "Übertragen"
          },
          on: {
            close: function($event) {
              _vm.transferId = null
            },
            onConfirmButton: _vm.onConfirmTransferToBrokerModal
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Makler an den der Antrag übertragen werden soll",
              placeholder: "Maklernummer",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.transferId,
              callback: function($$v) {
                _vm.transferId = $$v
              },
              expression: "transferId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }