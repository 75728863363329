var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Versicherungsdokumente hochladen/zuordnen (nur ZIP-Dateien)",
          actions: _vm.headerActions,
          en: ""
        },
        on: { "action-SELECT-FILE": _vm.onFileChange }
      }),
      _c("ComboBox", {
        attrs: {
          values: this.COMBO,
          firstEmpty: false,
          label: "Versicherungsanbieter"
        },
        model: {
          value: _vm.company,
          callback: function($$v) {
            _vm.company = $$v
          },
          expression: "company"
        }
      }),
      _vm.RESULT && _vm.RESULT.warning
        ? _c("div", { staticStyle: { color: "var(--color-danger)" } }, [
            _vm._v(" " + _vm._s(_vm.RESULT.warning))
          ])
        : _vm._e(),
      _c(
        "div",
        [
          this.FILE && this.FILE.id
            ? _c(
                "BaseButton",
                {
                  attrs: { disabled: this.worked },
                  on: {
                    click: function($event) {
                      return _vm.upload()
                    }
                  }
                },
                [_vm._v("Hochladen: " + _vm._s(this.FILE.name))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "BoxContainer",
        [
          _vm.RESULT && _vm.RESULT.result
            ? _c("InputTextArea", {
                attrs: { rows: 12, hideScrollBar: true, autoGrow: true },
                model: {
                  value: _vm.RESULT.result,
                  callback: function($$v) {
                    _vm.$set(_vm.RESULT, "result", $$v)
                  },
                  expression: "RESULT.result"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }