var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: { files: _vm.dropFile }
        },
        [
          _c(
            "div",
            { staticClass: "schadensmeldung" },
            [
              _c(
                "OptionMenu",
                _vm._b(
                  {
                    attrs: {
                      id: _vm.$appNavigation.currentOptionMenuId,
                      defaultMenu: _vm.$appNavigation.currentOptionMenu
                    }
                  },
                  "OptionMenu",
                  _vm.$attrs,
                  false
                )
              ),
              _c(
                "PageHeaderTitleNavigation",
                _vm._g(
                  {
                    attrs: {
                      id: _vm.$appNavigation.currentOptionMenuId,
                      actions: _vm.headerActions,
                      defaultMenu: _vm.customOptionsMenu
                    },
                    on: {
                      "action-EMAIL": function($event) {
                        return _vm.handleEmail()
                      },
                      "action-GESELLSCHAFT-HOME-PAGE": function($event) {
                        return _vm.handleGesellschaftHomePage()
                      },
                      "action-ADD-ACTIVITY": function($event) {
                        return _vm.handleAddActivity()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.gesellschaftName))]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _vm.bezeichnung
                              ? [
                                  _c("span", [_vm._v(_vm._s(_vm.bezeichnung))]),
                                  _c("span", [_vm._v(" | ")])
                                ]
                              : _vm._e(),
                            [_c("span", [_vm._v("Schadensmeldung")])]
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  _vm.$listeners
                )
              ),
              _vm.hasHinweis
                ? [
                    _c("div", { staticClass: "box__container" }, [
                      _c("div", { staticClass: "font-bold" }, [
                        _vm._v("Hinweise:")
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        _vm._l(_vm.hinweise, function(hinweis, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c("div", [_vm._v(_vm._s(hinweis.text))])
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                : _vm._e(),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.send.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.bearbeitenMode
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _vm.bearbeitenMode == true
                                ? _c("StepperForm", {
                                    attrs: {
                                      stepType: "step",
                                      stepperMediator: _vm.getStepperMediator,
                                      selectedStepKey: _vm.selectedStepKey,
                                      showSaveSpinner: _vm.loading
                                    },
                                    on: {
                                      "next-step": _vm.nextStep,
                                      "previous-step": _vm.previousStep,
                                      "on-fertig": _vm.fertig,
                                      "set-step": _vm.setStepByKey
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "contentTemplate",
                                          fn: function() {
                                            return [
                                              _vm.selectedStepKey ===
                                              "allgemeine"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("AllgemeineAngaben", {
                                                        attrs: {
                                                          vertragId:
                                                            _vm.vertragId,
                                                          schadenId:
                                                            _vm.schadenId,
                                                          value: _vm.ortDatum
                                                        },
                                                        on: {
                                                          sendInfo: function(
                                                            $event
                                                          ) {
                                                            return _vm.getInfo(
                                                              $event.target
                                                                .ortDatum
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm.selectedStepKey ===
                                                  "dokumenteUndFotos"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "DragnDropArea",
                                                        {
                                                          attrs: {
                                                            hoverText:
                                                              "Datei hier ablegen"
                                                          },
                                                          on: {
                                                            files: _vm.dropFile
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box__container"
                                                            },
                                                            [
                                                              _c(
                                                                "DocumentsTable",
                                                                {
                                                                  ref:
                                                                    "documentsTableEl",
                                                                  attrs: {
                                                                    title:
                                                                      "Dokumente",
                                                                    nodeId:
                                                                      _vm.nodeId,
                                                                    versicherungId:
                                                                      _vm.vertragId,
                                                                    schadenId:
                                                                      _vm.schadenId,
                                                                    rowsPerPage:
                                                                      _vm.rowsPerPage,
                                                                    unregisterEmailEventsBeforeDestroy: false,
                                                                    showOnlySchadenDocs:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    sendEmail:
                                                                      _vm.handleEmail
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm.selectedStepKey ===
                                                  "beteiligte"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box__container"
                                                    },
                                                    [
                                                      _c("BeteiligtePersonen", {
                                                        attrs: {
                                                          schadenId:
                                                            _vm.schadenId
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm.selectedStepKey ===
                                                  "geschaedigte"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box__container"
                                                    },
                                                    [
                                                      _c(
                                                        "GeschaedigteObjekte",
                                                        {
                                                          attrs: {
                                                            schadenId:
                                                              _vm.schadenId
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm.selectedStepKey ===
                                                    "zusammenfassung" &&
                                                  _vm.schadensmeldung
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("Zusammenfassung", {
                                                        attrs: {
                                                          summary:
                                                            _vm.schadensmeldung
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm.selectedStepKey ===
                                                    "maklernotizen" &&
                                                  _vm.schadensmeldung &&
                                                  _vm.isByPass
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box__container"
                                                    },
                                                    [
                                                      _c("Maklernotizen", {
                                                        attrs: {
                                                          schadenId:
                                                            _vm.schadenId
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3978306118
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm.schadensmeldung
                    ? _c("div", [
                        _c(
                          "div",
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v("Zusammenfassung")
                            ]),
                            _c("Zusammenfassung", {
                              attrs: {
                                summary: _vm.schadensmeldung,
                                bearbeitenMode: _vm.bearbeitenMode
                              },
                              on: {
                                "on-zuruck": _vm.zuruckAusZusammenfassung,
                                "on-bearbeiten": _vm.bearbeiten
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c(
                              "BaseButton",
                              {
                                staticClass: "m-0 mr-16px",
                                attrs: { isSecondary: "" },
                                on: { click: _vm.zuruckAusZusammenfassung }
                              },
                              [_vm._v("Zurück")]
                            ),
                            _c(
                              "BaseButton",
                              {
                                staticClass: "m-0",
                                on: { click: _vm.bearbeiten }
                              },
                              [_vm._v("Bearbeiten")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            2
          )
        ]
      ),
      _c("EmailVersand")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }