var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Übertrag von Transaktionen",
          actions: _vm.headerActions
        },
        on: { "action-umbuchen": _vm.umbuchen }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title mb-4" }, [
          _vm._v("Übertrag von Transaktionen")
        ]),
        _c("div", { staticClass: "box__container-wrap" }, [
          _c("div", { staticClass: "box__container-rows" }, [
            _c(
              "div",
              { staticClass: "label-value--list" },
              [
                _vm._m(0),
                _vm._l(_vm.aktuellBgsData, function(entry, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "label-value--item" },
                    [
                      _c("div", { staticClass: "label-value--item-label" }, [
                        _vm._v(_vm._s(entry.label))
                      ]),
                      _c("div", { staticClass: "label-value--item-value" }, [
                        _vm._v(_vm._s(entry.value))
                      ])
                    ]
                  )
                }),
                _vm.newBgsData.length
                  ? _c(
                      "div",
                      [
                        _vm._m(1),
                        _vm._l(_vm.newBgsData, function(entry, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "label-value--item " },
                            [
                              _c(
                                "div",
                                { staticClass: "label-value--item-label" },
                                [_vm._v(_vm._s(entry.label))]
                              ),
                              _c(
                                "div",
                                { staticClass: "label-value--item-value" },
                                [_vm._v(_vm._s(entry.value))]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-value--item" }, [
      _c("div", { staticClass: "font-strong" }, [
        _vm._v("Die Transaktionen des Vertrags:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-value--item mt-4" }, [
      _c("div", { staticClass: "font-strong" }, [
        _vm._v("könnten auf folgenden Vertrag übertragen werden:")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }