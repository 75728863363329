var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.appointmentConfig.title,
          actions: _vm.headerActions
        },
        on: {
          "action-BACK": function($event) {
            return _vm.back()
          },
          "action-SAVE": function($event) {
            return _vm.save()
          },
          "action-CANCEL": function($event) {
            return _vm.cancel()
          },
          "action-EVENT_INFO": function($event) {
            return _vm.eventInfo()
          },
          "action-DELETE": function($event) {
            return _vm.actionDelete()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("AppointmentEditCreate", {
            ref: "edit_create",
            attrs: {
              subject: _vm.appointmentConfig.subject,
              status: _vm.appointmentConfig.status,
              isActivity: _vm.appointmentConfig.isActivity,
              bereich: _vm.appointmentConfig.bereich,
              vsnr: _vm.appointmentConfig.vsnr,
              bereichId: _vm.appointmentConfig.bereichId,
              bereichText: _vm.appointmentConfig.bereichText,
              mode: _vm.appointmentConfig.currentMode,
              selectedDate: _vm.appointmentConfig.selectedDate,
              discardChanges: _vm.discardChanges,
              additionalBeteiligte: _vm.appointmentConfig.additionalBeteiligte
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }