var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "eFonds24",
          subtitle: "eZeichnungen ohne Zuweisung"
        }
      }),
      _c("div", { staticClass: "row my-0" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("AntragSidetext", {
              attrs: {
                antragId: _vm.id,
                antragData: _vm.data,
                warnings: _vm.warnings
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "701e2bd4-29b4-427e-96da-40ad35e57712",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "antragsnrIntern",
                    rowsPerPage: 20
                  },
                  on: {
                    "action-MAPPE": _vm.assignEZeichnungToMappe,
                    "action-EDIT": _vm.actionEdit,
                    "action-DELETE": _vm.actionDelete
                  }
                })
              : _c("NoData")
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "removeModal",
          attrs: { labelButtonCancel: "Nein", labelButtonConfirm: "Ja" },
          on: {
            onCancelButton: _vm.closeRemoveModal,
            onCloseButton: _vm.closeRemoveModal,
            onConfirmButton: function($event) {
              return _vm.removeAntrag($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Position löschen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v("Soll die folgende Position "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(
                _vm._s(_vm.antragToRemove && _vm.antragToRemove.wertpapiername)
              )
            ]),
            _vm._v(" ISIN: "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.antragToRemove && _vm.antragToRemove.isin))
            ]),
            _vm._v(" wirklich gelöscht werden?")
          ]),
          _c("div", { staticClass: "mt-3 font-bold" }, [_vm._v("Hinweis:")]),
          _c("div", [
            _vm._v(
              "Gelöschte Positionen müssen aktuell noch zusätzlich manuell im eFonds System gelöscht werden, um vollständig entfernt zu werden."
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "fehlerModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            showCloseButton: false
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("i", {
                    staticClass: "las la-exclamation-triangle mr-2",
                    staticStyle: { color: "orangered" }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v(_vm._s(_vm.errorStr))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }