<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      subtitle="Einstellungen Anlegerprofil" 
      :actions="headerActions"
      @action-DISCARD="discardChanges()" 
      @action-DELETE="deleteRisikoklassenDefinition()" 
    />

    <ConfigStepper3 
      v-if="isSmallOrMediumScreen"
      ref="stepper"
      :configStep="configStep"
      :selectedStep="selectedStep"
      :configData="configData"
      @setStep="setSelectedStep"
    />
    <ContentWithStepper v-else :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigVerticalStep
          ref="stepper"
          :configStep="configStep"
          :selectedStep="selectedStep"
          :configData="configData"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
        />
      </template>

      <template #content>
        <div>
          <Stammdaten v-if="selectedStep === 'stammdaten'" />
          <template v-for="index in RISIKOSTUFE_TOTAL">
            <Risikostufe v-if="selectedStep === `risikostufe_${index}`" :key="index" :index="index" />
          </template>
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal
      ref="editModal"
      :modalTitle="editModalTitle"
      :showDefaultButtons="false"
      :actions="modalActions"
      @action-DISCARD="discardChanges()"
      @close="saveChanges()"
    >
      <template #default>
        <div>
          <Stammdaten v-if="selectedStep === 'stammdaten'" />
          <template v-for="index in RISIKOSTUFE_TOTAL">
            <Risikostufe v-if="selectedStep === `risikostufe_${index}`" :key="index" :index="index" />
          </template>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import RISIKOKLASSEN_DEFINITION_TYPES from '@/store/risikoklassenDefinition/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigVerticalStep from '@/components/core/configs/ConfigVerticalStep.vue';
import ConfigStepper3 from '@/components/core/configs/ConfigStepper3.vue';
import BaseModal from '@/components/core/BaseModal.vue';

import Stammdaten from './steps/Stammdaten.vue';
import Risikostufe from './steps/Risikostufe.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction, } from '@/components/core/base-modal-actions/base-modal-actions-utils';

const RISIKOSTUFE_TOTAL = 5;
const RISIKOSTUFE_INDEXES = [...new Array(RISIKOSTUFE_TOTAL)].map((_, i) => i + 1);

export default {
  data() {
    return {
      selectedStep: null,
      stepperCollapsed: false,
      loading: false,
      RISIKOSTUFE_TOTAL,
    };
  },
  computed: {
    ...mapGetters({
      risikoklassenDefinition: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION,
      risikoklassenDefinitionEdited: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION_EDITED,
      hasRisikoklasseErrors: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.HAS_RISIKOKLASSE_ERRORS,
      isChangeAllowed: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.IS_CHANGE_ALLOWED,
    }),
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    editModalTitle() {
      const { selectedStep } = this;
      return this.configStep?.find(step => step.stepKey === selectedStep)?.label || 'Einstellung';
    },
    headerActions() {
      const { 
        isChangeAllowed, 
        hasDataChanged, 
        loading, 
        hasRisikoklassenDefinition, 
        isSmallOrMediumScreen, 
      } = this;

      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged)
          .withVisible(() => isChangeAllowed && !isSmallOrMediumScreen),
        PageHeaderSimpleAction('DELETE', 'Eigene Einstellungen löschen').withDisabled(() => loading || !hasRisikoklassenDefinition)
          .withVisible(() => isChangeAllowed),
      ];
    },
    modalActions() {
      const { isChangeAllowed, hasDataChanged, } = this;

      return [
        BaseModalSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged)
          .withVisible(() => isChangeAllowed),
      ];
    },
    configStep() {
      let index = 0;
      return [
        {
          label: 'Stammdaten',
          stepKey: 'stammdaten',
          index: index++,
        },
        ...(
          [ ...RISIKOSTUFE_INDEXES, ]
            .map(i => ({
              label: `Risikostufe ${i}`,
              stepKey: `risikostufe_${i}`,
              warning: this.hasRisikoklasseErrors(i),
              index: index++,
            }))
        ),
      ];
    },
    configData() {
      return {};
    },
    hasErrors() {
      return [...RISIKOSTUFE_INDEXES].some(i => this.hasRisikoklasseErrors(i));
    },
    hasDataChanged() {
      return Object.keys(this.risikoklassenDefinitionEdited || {}).length > 0;
    },
    hasRisikoklassenDefinition() {
      return Object.keys(this.risikoklassenDefinition?.einstellung || {}).length > 0;
    },
  },
  methods: {
    init() {
      if (!this.isSmallOrMediumScreen) {
        this.setSelectedStep(this.configStep[0].stepKey);
      }

      this.$store.dispatch(RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.GET_RISIKOKLASSEN_DEFINITION);
    },
    async setSelectedStep(stepKey) {
      await this.saveChanges();

      this.selectedStep = stepKey;

      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal?.open?.();
      }
    },
    discardChanges() {
      this.$store.commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET_RISIKOKLASSEN_DEFINITION_EDITED);
    },
    async saveChanges() {
      if(!this.hasErrors) {
        await this.$store.dispatch(RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.SAVE_RISIKOKLASSEN_DEFINITION);
      } else if(this.hasErrors && this.hasDataChanged) {
        await this.$confirmModal({
          title: 'Bitte bestätigen',
          message: 'Es sind Fehler aufgetreten! Möchten Sie die Seite verlassen ohne zu speichern?',
          labelButtonCancel: 'Nein',
          labelButtonConfirm: 'Ja',
        });
      }
    },
    deleteRisikoklassenDefinition() {
      this.$confirmModal({
        message: `Achtung: alle gemachten Änderungen an den Einstellungen gehen verloren.`,
        title: 'Einstellungen löschen',
        labelButtonConfirm: 'Löschen',
      })
      .then(async () => {
        await this.$store.dispatch(RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_DEFINITION)
      });
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges();

    this.$store.commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET);

    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ContentWithStepper,
    ConfigVerticalStep,
    ConfigStepper3,
    BaseModal,
    Stammdaten,
    Risikostufe,
  },
}
</script>
