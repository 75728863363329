var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Tarif Bearbeiten"
        }
      }),
      _vm.formData
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "a0c925e5-8662-454c-a04b-83abacf4d60b" }
            },
            [
              _c("InputField", {
                attrs: { label: "Bezeichnung", validateUntouched: true },
                model: {
                  value: _vm.formData.bezeichnung,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "bezeichnung", $$v)
                  },
                  expression: "formData.bezeichnung"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Gesellschaft",
                  values: _vm.gesellschaften,
                  disabled: _vm.formData.gesellschaft.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.updateSparte($event)
                  }
                },
                model: {
                  value: _vm.formData.gesellschaft.id,
                  callback: function($$v) {
                    _vm.$set(_vm.formData.gesellschaft, "id", $$v)
                  },
                  expression: "formData.gesellschaft.id"
                }
              }),
              _c("ComboBox", {
                attrs: { label: "Sparte", values: _vm.formData.sparte.values },
                model: {
                  value: _vm.formData.sparte.id,
                  callback: function($$v) {
                    _vm.$set(_vm.formData.sparte, "id", $$v)
                  },
                  expression: "formData.sparte.id"
                }
              }),
              _c("InputField", {
                attrs: { label: "Sichtbar für", disabled: true },
                model: {
                  value: _vm.formData.besitzer,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "besitzer", $$v)
                  },
                  expression: "formData.besitzer"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "inklusive Struktur",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.formData.sehenStruktur,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sehenStruktur", $$v)
                  },
                  expression: "formData.sehenStruktur"
                }
              }),
              _vm.isBroker && _vm.selectedTarif && _vm.selectedTarif.id
                ? _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.vorgabeTarifdaten()
                        }
                      }
                    },
                    [_vm._v("Vorgabe Tarifdaten")]
                  )
                : _vm._e(),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Tarif wird nicht mehr vertrieben",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.formData.inaktiv,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "inaktiv", $$v)
                  },
                  expression: "formData.inaktiv"
                }
              }),
              _c("p", [
                _vm._v("Dokumente für den jeweiligen Tarif hochladen.")
              ]),
              _c(
                "DragnDropArea",
                {
                  staticClass: "explorer__content",
                  attrs: {
                    hoverText: "Datei hier ablegen",
                    disabled: !this.selectedTarif || !this.selectedTarif.id
                  },
                  on: { files: _vm.onFileChange }
                },
                [
                  _vm.tarifDokumente &&
                  _vm.tarifDokumente.documents &&
                  _vm.tarifDokumente.documents.length
                    ? _c("Table", {
                        staticClass: "table-container",
                        attrs: {
                          tableId: "2e11d715-9529-4343-9733-4b700e43674b",
                          headers: _vm.tableHeaders,
                          rows: _vm.tableRows,
                          rowsPerPage: 20,
                          count: _vm.resultCount
                        },
                        on: {
                          action: function($event) {
                            return _vm.executeAction($event)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "bezeichnung",
                              fn: function(row) {
                                return [
                                  _c("DownloadLink", {
                                    attrs: {
                                      title: row.fileName || "unbenannt",
                                      filename: row.fileName,
                                      downloadServicePath: "/downloadFileById",
                                      queryParams: {
                                        id: row.id
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          371558415
                        )
                      })
                    : _vm.loading
                    ? _c("GhostLoading", { attrs: { type: "table" } })
                    : _c("NoData")
                ],
                1
              ),
              _vm.isFormValid
                ? _c("BaseFileSelect", { on: { files: _vm.onFileChange } }, [
                    _vm._v(" Dokumente hochladen ")
                  ])
                : _vm._e(),
              _c(
                "BaseModal",
                {
                  ref: "removeItemConfirmation",
                  attrs: {
                    modalTitle: "Tarif löschen",
                    labelButtonConfirm: "Löschen"
                  },
                  on: {
                    onConfirmButton: function($event) {
                      return _vm.removeItem()
                    }
                  }
                },
                [
                  _vm._v(
                    " Soll der Eintrage für " +
                      _vm._s(_vm.selectedDocument.fileName) +
                      " wirklich gelöscht werden? "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }