<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      :subtitle="$appNavigation.currentMenu.label"
      :defaultMenu="$appNavigation.currentTabMenu"
    />

    <BaseFilter 
      filterId="553dc811-be51-4b6a-87d4-dd44a7bb2456"
      title="Vermittlernummern filtern" 
      :metadata="filterMetadata" 
      :defaultOptions="defaultOptionsData"
      v-if="gesellschaften && gesellschaften.length"
      @onFilter="filterForm($event)">
    </BaseFilter>
    <GhostLoading v-else-if="!gesellschaften || !gesellschaften.length" type="block" :config="{ block: { height: 100, }, }" useBoxContainer />

    <div class="box__container" tid="7bf88b18-1485-4130-a282-2101d77f8608">
      <p v-if="!loading && tableRows.length && !isFA">Wir bitten Sie, alle Anträge über unser Haus einzureichen.</p>
      <p v-if="!loading && tableRows.length">Bei den Gesellschaften, für die noch keine Vermittlernummer vergeben wurde, erhalten Sie diese nach Einreichen des Erstantrags.</p>

      <Table
        tableId="230d9225-e318-4ab7-98d8-87da79a01c0e"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :headerActions="headerActions"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :exportConfig="{roottext: TABLE_TITLE}"
        @action="executeAction($event)"
        @headerAction-VERMITTLERNUMMER_ERSTELLEN="erstellen()"
      >
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Maklernummer löschen" labelButtonConfirm="Löschen">
      Soll die Maklernummer der Gesellschaft {{selectedFremdnr.gesellschaftId}} wirklich gelöscht werden?
    </BaseModal>


  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import VERMITTLERNUMMERN_TYPES from "../../store/vermittlernummern/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, PillColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import { PhPencil, PhCheck, PhTrash, PhEnvelope} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import BaseFilter from "@/components/core/BaseFilter.vue";
import { VIEW_ROLES } from '@/router/roles';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Vermittlernummern';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        unsignedAttachments: [],
        signedAttachments: [],
        currentFilename: null,
        downloadErrorMessage: null,
        filtered: false,
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      versicherungenData: VERMITTLERNUMMERN_TYPES.GETTERS.VERSICHERUNGEN_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedFremdnr: VERMITTLERNUMMERN_TYPES.GETTERS.SELECTED_FREMDNR,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    gesellschaften() {
      return this.versicherungenData?.gesellschaften || [];
    },
    canSeeSpecialColumns() {
      return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
    },
    tableHeaders() {
      if (this.isFA) {
        return this.tableHeadersFA;
      }
      return this.tableHeadersFK;
    },
    tableHeadersFA() {
      if (this.canSeeSpecialColumns) {
        return {
          lockedLeft: [
            TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible(),
            TextColumn("itemNummer", "Vermittlernummer"),
            IconColumn("iconGiltUntervermittler", "Gilt für Untervermittler").makeHidden(),
            TextColumn("itemZusatz", "Hauptnummer"),
            TextColumn("tooltip", "Tooltip").makeHidden(),
            PillColumn("legend", "Anbindungsart").makeHidden(),
            PillColumn("anbindungstyp", "Anbindungstyp"),
            TextColumn("status", "Status").makeHidden(),
            TextColumn("sichtbar", "Sichtbar").makeHidden(),
            TextColumn("mitButtons", "Mit Buttons").makeHidden(),
            TextColumn("mitgeteiltAm", "Mitgeteilt am"),
            TextColumn("bemerkung", "Bemerkung"),
            TextColumn("zweigstelle", "Zweigstelle"),
            IconColumn("iconItemBevorzugt", "Bevorzugt"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };

      }

      return {
        lockedLeft: [
          TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible(),
          TextColumn("itemNummer", "Vermittlernummer"),
          IconColumn("iconGiltUntervermittler", "Gilt für Untervermittler").makeHidden(),
          TextColumn("itemZusatz", "Hauptnummer"),
          TextColumn("tooltip", "Tooltip").makeHidden(),
          PillColumn("legend", "Anbindungsart").makeHidden(),
          PillColumn("anbindungstyp", "Anbindungstyp"),
          TextColumn("status", "Status").makeHidden(),
          TextColumn("sichtbar", "Sichtbar").makeHidden(),
          TextColumn("mitButtons", "Mit Buttons").makeHidden(),
          TextColumn("mitgeteiltAm", "Mitgeteilt am").makeHidden(),
          TextColumn("bemerkung", "Bemerkung").makeHidden(),
          TextColumn("zweigstelle", "Zweigstelle"),
          IconColumn("iconItemBevorzugt", "Bevorzugt"),
        ],
        lockedRight: [],   
      };      
    },
    tableHeadersFK() {
      if (this.canSeeSpecialColumns) {
        return {
          lockedLeft: [
            TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible(),
            TextColumn("itemNummer", "Vermittlernummer"),
            IconColumn("iconGiltUntervermittler", "Gilt für Untervermittler"),
            TextColumn("itemZusatz", "Hauptnummer").makeHidden(),
            TextColumn("tooltip", "Tooltip").makeHidden(),
            PillColumn("legend", "Anbindungsart"),
            PillColumn("anbindungstyp", "Anbindungstyp"),
            TextColumn("status", "Status").makeHidden(),
            TextColumn("sichtbar", "Sichtbar").makeHidden(),
            TextColumn("mitButtons", "Mit Buttons").makeHidden(),
            TextColumn("mitgeteiltAm", "Mitgeteilt am"),
            TextColumn("bemerkung", "Bemerkung"),
            TextColumn("zweigstelle", "Zweigstelle"),
            IconColumn("iconItemBevorzugt", "Bevorzugt"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };

      }

      return {
        lockedLeft: [
          TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible(),
          TextColumn("itemNummer", "Vermittlernummer"),
          TextColumn("itemZusatz", "Hauptnummer").makeHidden(),
          TextColumn("tooltip", "Tooltip").makeHidden(),
          PillColumn("legend", "Anbindungsart"),
          PillColumn("anbindungstyp", "Anbindungstyp"),
          IconColumn("iconGiltUntervermittler", "Gilt für Untervermittler"),
          TextColumn("status", "Status").makeHidden(),
          TextColumn("sichtbar", "Sichtbar").makeHidden(),
          TextColumn("mitButtons", "Mit Buttons").makeHidden(),
          TextColumn("mitgeteiltAm", "Mitgeteilt am").makeHidden(),
          TextColumn("bemerkung", "Bemerkung").makeHidden(),
          TextColumn("zweigstelle", "Zweigstelle"),
          IconColumn("iconItemBevorzugt", "Bevorzugt"),
        ],
        lockedRight: [],   
      };      
    },       
    tableRows () {
      if (!this.versicherungenData?.rows?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("EDIT_ITEM", PhPencil, "Bearbeiten"),
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
          SimpleAction("SEND_EMAIL", PhEnvelope, "Nachricht senden"),
      ];

      const onlyMailAction = [
          SimpleAction("SEND_EMAIL", PhEnvelope, "Nachricht senden"),
      ];        


      return this.versicherungenData.rows.map(data => ({
          gesellschaftsname: data.gesellschaftsname,
          itemNummer: data.itemNummer,
          itemZusatz: data.itemZusatz,
          tooltip: data.tooltip,
          legend: data.tooltip ? {
                  label: data.tooltip,
                  type: data.status,
              } : null,
          iconGiltUntervermittler: data?.status =='success' ? [Icon(PhCheck, "Ja", 16, "fill")] : [],
          status: data.status,
          sichtbar: data.sichtbar,
          mitButtons: data.mitButtons,
          mitgeteiltAm: data.mitgeteiltAm,
          bemerkung: data.bemerkung,
          zweigstelle: data.zweigstelle,
          itemBevorzugt: data.itemBevorzugt,
          iconItemBevorzugt: data.itemBevorzugt ? [Icon(PhCheck,"Ja", 16, "fill")] : [],
          actions: data.mitButtons ? fullActions : data.isInternOderFondsMasterMakler ? onlyMailAction : [],
          isExternerDirektvertrag: data.isExternerDirektvertrag,
          anbindungstyp: data.anbindungstyp ? {
                          label: data.anbindungstyp,
                          type: this.anbindungstypColoring(data.anbindungstyp)
          } : null,
          zweigstelleValues: data.zweigstelleValues,
          hauptNummer: data.hauptNummer,
          comboZweigstelle: data.comboZweigstelle,
          chkVererbung: data.chkVererbung,
          gesellschaftId: data.gesellschaftId,
          id: data.id,
      }));




    },
    defaultOptionsData() {
      return {
          vermittlernummer: {
              value: "",
          },
          gesellschaften: {
              value: "",
          },                    
        }; 
    }, 
    filterMetadata() {
         return [
            {
              type: "group",
              key: "allgemein",
              label: "Allgemein",
              menuItems: [
                {
                  type: 'text',
                  key: 'vermittlernummer',
                  label: 'Vermittlernummer',
                  inputmode: 'numeric',
                },
                {
                  type: "default",
                  key: "mitZweigstellen",
                  label: "Zweigstellen berücksichtigen",
                },                 
              ],
            },     
            {
              type: 'group',
              key: 'gesellschaften',
              label: 'Gesellschaften',
              menuItems: this.gesellschaften || []
            },               
          ];  
    },
    headerActions(){
      const actions = [PageHeaderSimpleAction('VERMITTLERNUMMER_ERSTELLEN', 'Vermittlernummer erstellen')];
      return actions;
    }
  },
  mounted() {
      this.retrieveData();  
  },
  methods: {
      /**
          possible return values:  
          --primary 
          --success 
          --warning 
          --info 
          --danger 
          --default 
          --black 
           */
    anbindungstypColoring(anbindungstyp){
      switch (anbindungstyp) {
                case 'Poolnummer':
                   return 'info';
                case 'Unternummer':
                   return 'warning';
                case 'Unterstellte Direktanbindung':
                  return 'success';
                case 'Direktanbindung':
                 return 'danger';
       }
       return null;
    },
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          this.bearbeiten(actionData.row);
          break;
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
        case 'SEND_EMAIL':
          this.sendEmail(actionData.row);
          break;                  
      }
    },   
    sendEmail(row) {
      const attachments = []

      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERMITTLERNUMMERN, {
        id: row.id,
        customCommit: true,
        gesellschaftsArt: 'Versicherung',
        mailGesellschaft: row.gesellschaftId,
        }).then(response => {
            const customResponse = {
              ...response.data,
              attachments: attachments,
            }
                

            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERMITTLERNUMMERN, customResponse);
            this.$router.push({path: '/communication/mailcomposer-single', query: {replaceContent: true}});

        })  
    },
    confirmItemRemoval(row) {
      this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.SELECTED_FREMDNR, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      this.$store.dispatch(VERMITTLERNUMMERN_TYPES.ACTIONS.REMOVE_ROW, {id: this.selectedFremdnr?.id}).then((response) => {
        this.retrieveData();  
      }); 
    },
    retrieveData(mitZweigstellen, vermittlernummer, gesellschaft) {
      this.loading = true;

      this.$store.dispatch(
              VERMITTLERNUMMERN_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGEN_DATA, {mitZweigstellen, vermittlernummer, gesellschaft}
            ).then(() => {
                this.loading = false;
            });  

    },
    bearbeiten(row) {
        this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.SELECTED_FREMDNR, row);
        this.$router.push({path: `/beratung/vermittlernummern-overview/fremdnrBearbeiten` });
    },
    filterForm(filterConfig) {
      const mitZweigstellen = filterConfig.find(fc => fc.key=="mitZweigstellen");
      const vermittlernummer = filterConfig.find(fc => fc.key=="vermittlernummer");
      const gesellschaften = filterConfig.find(fc => fc.group=="gesellschaften");

      this.retrieveData(mitZweigstellen?.value, vermittlernummer?.value, gesellschaften?.key) ;
    },
    erstellen(){
      this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.SELECTED_FREMDNR, {
          gesellschaftsname: null,
          itemNummer: null,
          itemZusatz: null,
          tooltip: null,
          legend: null,
          iconGiltUntervermittler: [],
          status: null,
          sichtbar: null,
          mitButtons: null,
          mitgeteiltAm: null,
          bemerkung: null,
          zweigstelle: null,
          itemBevorzugt: null,
          iconItemBevorzugt: [],
          actions: [],
          isExternerDirektvertrag: null,
          andbindungstyp: null,
          zweigstelleValues: null,
          hauptNummer: null,
          comboZweigstelle: null,
          chkVererbung: null,
          gesellschaftId: null,
          id: null,
        });
      this.$router.push({
        name: 'fremdnrBearbeiten', 
        params: { createVermittlerNummer: true} 
      });
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlernummern Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>