<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Wertpapiersuche" />

    <AntragFormsFondsFinder :goBackEnabled="false" @goBack="navigateBackToCurrentStep()"/>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import AntragFormsFondsFinder from '@/components/antrag/fondsfinder/AntragFormsFondsFinder.vue'

export default {
  mixins: [antragNavigationMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    AntragFormsFondsFinder
  }
}
</script>

<style>

</style>