import { PrimaryMenu, GroupMenu, MenuItem, TabMenu, } from './../menu-utils';

import { PhChats, } from 'phosphor-vue';

import UnreadMessages from '@/components/icons/undraw/UnreadMessages.vue';
import OpenSignatures from '@/components/icons/undraw/OpenSignatures.vue';
import UndrawEnvelope from '@/components/icons/undraw/UndrawEnvelope.vue';
import UndrawDevelopApp from '@/components/icons/undraw/UndrawDevelopApp.vue';
import UndrawMailbox from '@/components/icons/undraw/UndrawMailbox.vue';
import UndrawDiary from '@/components/icons/undraw/UndrawDiary.vue';
import UndrawTeamChat from '@/components/icons/undraw/UndrawTeamChat.vue';
import UndrawFolder from '@/components/icons/undraw/UndrawFolder.vue';
import UndrawOnlineCalendar from '@/components/icons/undraw/UndrawOnlineCalendar.vue';
import UndrawNewsletter from '@/components/icons/undraw/UndrawNewsletter.vue';
import UndrawEmailCapture from '@/components/icons/undraw/UndrawEmailCapture.vue';
import UndrawPreferences from '@/components/icons/undraw/UndrawPreferences.vue';
import UndrawOnlineFriends from '@/components/icons/undraw/UndrawOnlineFriends.vue';
import UndrawAddFile from '@/components/icons/undraw/UndrawAddFile.vue';
import UndrawTerms from '@/components/icons/undraw/UndrawTerms.vue';
import UndrawChatting from '@/components/icons/undraw/UndrawChatting.vue'
import UndrawMeeting from '@/components/icons/undraw/UndrawAnnotation.vue'
import UndrawAnnotation from '@/components/icons/undraw/UndrawMeeting.vue'
import UndrawFileAnalysis from '@/components/icons/undraw/UndrawFileAnalysis.vue'
import UndrawFiling from '@/components/icons/undraw/UndrawFiling.vue';
import UndrawTimeManagement from '@/components/icons/undraw/UndrawTimeManagement.vue';
import UndrawPeopleSearch from '@/components/icons/undraw/UndrawPeopleSearch.vue';

const POSTFACH_OVERVIEW_PATH = '/communication/postfach/overview';

export default PrimaryMenu('/communication', 'Kommunikation', PhChats, [
  MenuItem('/communication/postfach/overview', 'Übersicht', UndrawMailbox),

  GroupMenu('/communication/postfach', 'Postfach', UndrawMailbox, [
    MenuItem('/communication/postfach/overview', 'Übersicht', UndrawMailbox),
    MenuItem('/communication/postfach/chats', 'Nachrichten', UndrawChatting),
    MenuItem('/communication/postfach/emails', 'E-Mails', UndrawMailbox),
    MenuItem('/communication/postfach/termine', 'Termine', UndrawOnlineCalendar),
    MenuItem('/communication/postfach/aufgaben', 'Aufgaben', UndrawTerms),
   // MenuItem('/communication/postfach/aktivitaeten', 'Aktivitäten', UndrawOnlineCalendar),
    MenuItem('/communication/postfach/bemerkungen', 'Bemerkungen', UndrawAnnotation),
    MenuItem('/communication/postfach/talks', 'Gespräche', UndrawMeeting),
    MenuItem('/communication/postfach/schriftverkehr', 'Schriftverkehr', UndrawFiling),
    MenuItem('/communication/postfach/smses', 'SMS', UndrawDevelopApp),
    MenuItem('/communication/postfach/faxes', 'Faxe', UndrawFileAnalysis),
  ]).withAutoSelectTo(POSTFACH_OVERVIEW_PATH),
  MenuItem('/communication/todo-list', 'ToDo-Liste', OpenSignatures),
  MenuItem('/communication/mailcomposer', 'Neue <nobr>E-Mail</nobr>', UndrawEnvelope),
  MenuItem('/communication/mailcomposer-app', 'Kundenzugang versenden', UndrawDevelopApp),
  MenuItem('/communication/mailcomposer-brief', 'Neuer Brief', UndrawMailbox),
  MenuItem('/communication/mailcomposer-fax', 'Neues Fax an Kunde', UndrawDiary),
  MenuItem('/communication/calendar', 'Kalender', UndrawOnlineCalendar),
  MenuItem('/communication/mailcomposer-bulk', 'Neue Rundmail', UndrawNewsletter),
  MenuItem('/communication/kontakte', 'Kontakte', UndrawPeopleSearch),
  MenuItem('/communication/mailcomposer-serienbrief', 'Neuer Serienbrief'),
  MenuItem('/communication/mailcomposer-fax-send', 'Neues Fax', UndrawDiary),
  MenuItem('/communication/tickets/new-ticket', 'Neue Nachricht', UndrawTeamChat),
  MenuItem('/communication/documents', 'Dokumente', UndrawFolder),
  MenuItem('/communication/versandprtokolle', 'Versandprotokolle', UndrawDiary),
  MenuItem('/communication/setupemails', 'Einstellungen <nobr>E-Mail-Texte</nobr>', UndrawPreferences),
  MenuItem('/communication/mailvorlage', 'Vorlagen <nobr>Briefe / E-Mails</nobr>', UndrawPreferences, [
    TabMenu('/communication/mailvorlage/list', 'Vorlagen <nobr>Briefe / E-Mails</nobr>'),
    TabMenu('/communication/mailvorlage/template-background', 'PDF für Hintergrund Briefvorlage'),
  ]),
  MenuItem('/communication/html-shortcuts', 'Editor Shortcuts', UndrawPreferences),
  MenuItem('/communication/rundmailstatus', 'Rundmail Status', UndrawOnlineFriends),
  MenuItem('/communication/dateihochladen', 'Dokumente hinzufügen', UndrawAddFile),
  MenuItem('/communication/sipgate', 'Sipgate', UndrawOnlineFriends),
  MenuItem('/communication/offene-unterschriften', 'Offene Unterschriften', UndrawTerms),
  MenuItem('/communication/kunden-aktionen', 'Kunden-Aktionen', UndrawTerms),
  MenuItem('/communication/stoppuhr', 'Stoppuhr', UndrawTimeManagement),
  MenuItem('/communication/email-not-recognized', '<nobr>Nicht zugeordnete</nobr> <nobr>E-Mail-Adressen</nobr>', UndrawEmailCapture),
  MenuItem('/communication/send-pin-via-sms', 'PIN per SMS versenden'),
  MenuItem('/communication/chat_test_session_query', 'Einschaten/Auschalten neue Chatsabfrage', UndrawOnlineFriends),
  MenuItem('/communication/absence-note', 'Abwesenheitsnotiz', UndrawTimeManagement),
]).withAutoSelectTo(POSTFACH_OVERVIEW_PATH);
