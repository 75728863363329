var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Erfassung Position Papierantrag"
        }
      }),
      _vm.searchMode ? _c("FondsFinderSuchMaske") : _vm._e(),
      _vm.searchMode
        ? _c("FondsFinderPositionsList", {
            attrs: { goBackEnabled: true, singleSelectionProp: true },
            on: {
              goBack: function($event) {
                return _vm.onselectionDone($event)
              }
            }
          })
        : _vm._e(),
      !_vm.searchMode
        ? _c(
            "div",
            {
              staticClass: "row parameter-search__input-fields box__container"
            },
            [
              _vm.lastSelectedFund && _vm.lastSelectedFund.wertpapiername
                ? _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " + _vm._s(_vm.lastSelectedFund.wertpapiername) + " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v("Wertpapierauswahl")
                  ])
                ],
                1
              ),
              _vm.componentConfig &&
              _vm.componentConfig.positionSpecFields &&
              _vm.lastSelectedFund &&
              _vm.lastSelectedFund.isin
                ? _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("WertpapierAuswahlPositionFields", {
                        attrs: {
                          config: _vm.componentConfig,
                          specSelectedFund: _vm.lastSelectedFund,
                          spec: true,
                          categoryId: _vm.categoryId,
                          antragId: _vm.antragId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Fehler Liste",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Zurück zur Beratungsmappe"
          },
          on: {
            onCancelButton: function($event) {
              return _vm.cancelSaving()
            }
          }
        },
        [
          _vm.response
            ? _c(
                "div",
                _vm._l(_vm.response.errorTable, function(errorItem) {
                  return _c("div", { key: errorItem.value }, [
                    _vm._v(
                      " " +
                        _vm._s(errorItem.label + ": " + errorItem.value) +
                        " "
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }