var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Informationen zum Prüfbericht"
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading.init
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm._e(),
          _c("Table", {
            attrs: {
              rowId: "id",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10
            },
            scopedSlots: _vm._u([
              {
                key: "bezeichnung",
                fn: function(row) {
                  return [
                    _c("DownloadLink", {
                      attrs: {
                        title: row.bezeichnung,
                        filename: row.filename,
                        queryParams: {
                          dateCreated: row.dateCreated
                        },
                        downloadServicePath: "/get_allFiles_by_name"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }