<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Postfach" subtitle="Gespräch" />

    <div class="box__container" v-if="talk">
      <template v-if="isNew"> </template>
      <template v-else>
        <InputField
          v-if="talk.speaker"
          :value="talk.speaker"
          disabled
          label="Gespräch von"
        />
        <div style="display: flex; flex-direction: row">
          <div style="width: 50%; padding-right: 7px">
            <DatePickerField
              v-model="form.datum"
              label="Datum"
            />
          </div>
          <div style="width: 50%; padding-left: 7px">
            <TimePicker
              v-model="form.startTime"
              label="Uhrzeit (h:min)"
            />
          </div>
        </div>
        <TimePicker
           v-model="form.duration"
          label="Dauer (h:min:sec)"
          withSeconds
        />
        <InputField :value="userId()" disabled :label="nummerLabel()" />
      </template>
      <InputField :value="userName()" disabled :label="nameLabel()" />
      <ComboBox
        v-model="form.category"
        :values="categoryOptions"
        label="Kategorie"
      />
      <InputField
        v-model="form.topic"
        label="Thema"
      />
      <InputTextArea
        v-model="form.description"
        label="Beschreibung"
        class="text-input"
        autoGrow
      />
    </div>
  </div>
</template>

<script>
import COMMUNICATION_TYPES from "@/store/communication/types";
import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import TimePicker from "@/components/core/forms/TimePicker.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { DatePickerUtils } from "@/components/core/forms/DatePicker/date-picker-utils";
import validator from '@/mixins/validator/index';
import { required, regex, } from '@/mixins/validator/rules';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputTextArea,
    DatePickerField,
    TimePicker,
    ComboBox,
  },
  props: {},
  mixins: [validator],
  computed: {
    ...mapGetters({
      categoryOptions: COMMUNICATION_TYPES.GETTERS.TALK_CATEGORIES,
      talks: COMMUNICATION_TYPES.GETTERS.TALKS,
    }),
    talk() {
      return this.talks[this.commId];
    },
    defaultTime() {
      return (
        (this.talk &&
          this.talk.datum.getHours() + ":" + this.talk.datum.getMinutes()) ||
        ""
      );
    },
  },
  watch: {
    talk(newValue) {
      this.fillOutForm(newValue);  
    }
  },  
  data() {
    return {
      commId: this.$route.params.commId,
      isNew: !this.$route.params.commId, // it's a new talk if not opened by id
      form: {
        datum: null,
        startTime: null,
        duration: null,
        customerId: null,
        customerName: null,
        category: null,
        topic: null,
        description: null,
      },
    };
  },
  async mounted() {
    if (!this.talk && this.commId)
      await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TALK, this.commId);
    if (this.categoryOptions.length == 0)
      await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TALK_CATEGORIES);

    this.fillOutForm(this.talks[this.commId]);


  },
  validators: {
    form: {
      datum: [required()],
      startTime: [required()],
    },
  },  
  methods: {
    fillOutForm(newValue) {
      const payload = {
        // datum: newValue?.datum ? DatePickerUtils.toDateStr(newValue?.datum) : null,
        startTime:  newValue?.startTime,
        datum:  newValue?.datum,
        duration: newValue?.duration,
        category: newValue?.category,
        topic: newValue?.topic,
        description: newValue?.description,
      };

 
      Object.assign(this.form, payload);
    },
    save() {
      if(this.isFormInvalid()) return;

      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.SAVE_TALK, {
        id: this.commId, // null if the talk is new
        datum: this.form.datum ? DatePickerUtils.toDateStr(this.form.datum) : null,
        startTime: this.form.startTime,
        duration: this.form.duration,
        //customerId: this.form.customerId, // TODO: only needed when a new talk is created
        category: this.form.category,
        topic: this.form.topic,
        description: this.form.description,
      });
    },
    nameLabel() {
      let result = "Kundenname";
      if (this.talk.userType) {
        if (this.talk.userType == "MAKLER") {
          result = "Vermittlername";
        } else if (this.talk.userType == "MAKLER") {
          result = "Name ";
        }
        return result;
      }
    },
    userName() {
      if (this.talk.customerName) {
        return this.talk.customerName;
      } else {
        return this.talk.userName;
      }
    },
    userId() {
      if (this.talk.customerId) {
        return this.talk.customerId;
      } else {
        return this.talk.userId;
      }
    },
    nummerLabel() {
      let result = null;
      if (this.talk.userType) {
        if (this.talk.userType == "MAKLER") {
          result = "Vermittlernummer";
        } else if (this.talk.userType == "KUNDE") {
          result = "Kundennummer ";
        }
      }
      return result;
    },
    isFormInvalid() {
      return this.validation?.updated && this.validation?.isInvalid('form');
    },     
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Gespräche', 
      to,
      from,
      breadcrumb: "Gespräche"
    });

    this.save();

    next();
  },  
};
</script>

<style scoped>
.text-input {
  overflow: hidden;
}
</style>