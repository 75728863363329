var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.headerActions
        },
        on: {
          "action-EDIT-ROWS": function($event) {
            return _vm.$refs.externeZugangBankConfigModal.open()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.isLoading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "6deee8ba-8180-4eae-abeb-dab018b261ef",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "label",
                  rowsPerPage: 10,
                  exportConfig: { roottext: "Externe Zugaenge" }
                },
                on: { "click-label": _vm.openExterneZugangBank }
              })
            : _vm.isLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c("ExterneZugangBankConfigModal", {
        ref: "externeZugangBankConfigModal",
        attrs: { defaultList: _vm.list },
        on: {
          onSave: function($event) {
            return _vm.getConfig(true)
          },
          onRestoreDefault: function($event) {
            return _vm.getConfig(true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }