<template>
    <div class="cards__container">

        <div class="fondsfinder-button__container">
            <div class="input-forms__label-content--bigger mb-3" v-if="title">
                <ph-bank :size="16" />&nbsp;{{ title }}
            </div>
            <div v-if="config && config.buttonText">
                <BaseButton @click="openFormsFinder">{{
                    config.buttonText
                }}</BaseButton>
            </div>
        </div>

        <div v-if="hasPositions" class="cards__items">
            <div class="box__container cards__item" v-for="(pos, index) in positions" :key="pos.isin + index" 
                :class="getPositionClasses(pos)" ref="cards"
            >
                <PageHeaderTitleNavigation 
                    :id="pos.isin + index" 
                    :actions="headerActions(pos)" 
                    :noPrimaryAction="true"
                    :showBackButton="false"
                    v-on="$listeners"
                    @action-DELETE="openModalDeleteFond(pos)"
                >
                    
                    <template #title><slot name="title">
                        <div class="cards__item--header ">
                            <a @click="gotoFondsinfo(pos)">{{ pos.fondsname }}</a>
                        </div>
                    </slot></template>
                    <template #subtitle><slot name="subtitle">
                        <div class="font-bold">
                            <span>ISIN: {{pos.isin}}</span>
                        </div>
                    </slot></template>
                </PageHeaderTitleNavigation>      

                <div class="cards__item--inputs">  
                    <ComboBox
                        label="Ordertyp"
                        isEmbedded
                        :value="pos.modus"
                        :id="'modus' + pos.index"
                        :disabled="disabled"
                        :values="orderTypValues"
                        @change="updateModus($event, pos, 'modus')">
                    </ComboBox>                  
                    <InputField 
                        isEmbedded
                        type="currency" 
                        label="Anlagebetrag"
                        :value="!pos.betrag ? null : pos.betrag" 
                        :id="'betrag' + pos.index" 
                        :disabled="disabled"
                        :placeholder="pos.modus == 'SPARPLAN' ? 'mind.50' : 'mind.2500'"
                        @change="onChangeInputField($event, pos, 'betrag')"  />
                    <ComboBox v-if="pos.modus != 'SPARPLAN'"
                        isEmbedded
                        label="Währung"
                        :value="pos.waehrung"
                        :id="'waehrung' + pos.index"
                        :disabled="disabled"
                        :values="waehrungValues"
                        @change="onChangeInputField($event, pos, 'waehrung')">
                    </ComboBox>
                    <ComboBox v-if="pos.modus == 'SPARPLAN'"
                        label="Turnus"
                        isEmbedded
                        :value="pos.frequenz"
                        :id="'frequenz' + pos.index"
                        :disabled="disabled"
                        :values="turnusValues"
                        @change="onChangeInputField($event, pos, 'frequenz')">
                    </ComboBox>
                    <DatePickerField v-if="pos.modus == 'SPARPLAN'"
                        isEmbedded
                        label="Beginn"
                        :value="pos.beginn"
                        monthPicker 
                        :id="'beginn' + pos.index"
                        :disabled="disabled"
                        isValueAsString
                        @change="onChangeInputField($event, pos, 'beginn')" 
                    />
                    <InputField v-if="pos.modus == 'SPARPLAN'"
                        label="Dynamisierung"
                        isEmbedded
                        :id="'dynamisierung' + pos.index" 
                        :value="pos.dynamisierung"
                        :disabled="disabled"
                        @change="onChangeInputField($event, pos, 'dynamisierung')"
                    />
                </div>
            </div>
        </div>
        <DeletePositionModal ref="deleteModal" :position="positionToDelete"
          @delete="doDeletePosition()">
        </DeletePositionModal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import WertpapierAuswahlPositionsButtons from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import { PhWarning, PhBank } from "phosphor-vue";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  mixins: [antragNavigationMixin, validator, antragMixin],
  validators: {
    betrag: [required()],
  },
  mounted() {
    this.getWaehrungen();
    this.updateWarnings();
  },
  props: {
    title: {},
    antragData: {},
    config: {},
    antragId: {},
    antragTyp: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    }
  },
  components: {
    BaseButton,
    InputField,
    ComboBox,
    DatePickerField,
    WertpapierAuswahlPositionsButtons,
    WertpapierAuswahlPositionFields,
    DeletePositionModal,
    BaseModal,
    PhWarning,
    PhBank,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      categoryId: "KAUF",
      orderTypValues: [
        { label: "Einmalanlage", value: "KAUF" },
        { label: "Sparplan", value: "SPARPLAN" },
      ],
      waehrungValues: [],
      turnusValues: [
        { label: "monatlich", value: "MONATLICH" },
        { label: "zweimonatlich", value: "ZWEIMONATLICH" },
        { label: "vierteljährlich", value: "VIERTELJ" },
      ],
      positionToDelete: {},
    };
  },
  watch: {
    positions: {
      handler(value) {
        if (value && value.length > 4) {
          while (this.positionsAll[this.antragId][this.categoryId].length > 4) {
            this.positionsAll[this.antragId][this.categoryId].pop();
          }
          this.openFehlerModal();
        }
        this.updateWarnings();
      },
    },
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      if (this.antragId && this.positionsAll?.[this.antragId]) {
        let positions = this.positionsAll[this.antragId]?.[this.categoryId] || [];

        positions = positions.map((pos, index) =>
          Object.assign(pos, {
            ...pos,
            index: index,
            fondsname: this.getFondsName(pos),
            modus: this.getOrderTyp(pos),
            waehrung: pos.waehrung || 'EUR',
          }, 
          pos.modus == 'SPARPLAN' ? {dynamisierung: pos.hasOwnProperty('dynamisierung') ? pos.dynamisierung : 5} : {})
        );

        positions = positions.sort(function (p1, p2) {
          if (p1.isin < p2.isin) {
            return -1;
          }
          if (p1.isin > p2.isin) {
            return 1;
          }
          return 0;
        });
        return positions;
      }
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId];
      }
      return null;
    },
    warnings() {
      return this.antrag?.warnings?.positionWarnings;
    },
    hasPositions() {
      return this.positions?.length;
    },
  },
  methods: {
    updateWarnings() {
      if (this.warnings?.length) {
        for (const warn of this.warnings) {
          if (warn.posGrpId) {
            const index = this.positions?.filter(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId)?.[0]?.index;
            const substringIndex = warn.id?.indexOf('§§') || -1;
            if (index >= 0 && substringIndex >= 0 && substringIndex < (warn.id?.length - 3)) {
              this.$pushErrors(`${warn.id?.substring(substringIndex + 2)}${index}`, warn.message || "");
            }
          }
        }
      }
    },
    updateModus(modus, position) {
      if (modus && position && position?.modus !== modus) {
        const pos = {modus: modus, };
        if (modus === 'SPARPLAN') {
          pos.waerung = '';
          pos.dynamisierung = 5;
          pos.frequenz = !pos.frequenz ? null : pos.frequenz;
        } else if(modus === 'KAUF') {
          pos.frequenz = null;
          pos.beginn = '';
          pos.dynamisierung = null;
        }

        this.commitUpdatePositionsFromEvent(
          pos,
          position.isin,
        );
      }
    },
    getFondsName(pos) {
      return ((pos?.isin && this.positionInfo?.[pos.isin]?.wertpapiername) ||
        pos.fondsname || pos.wertpapiername);
    },
    getOrderTyp(pos) {
      if (pos) {

        if (pos.modus == "SPARPLAN" || pos.modus == "2") {
          return "SPARPLAN";
        } else {
          return "KAUF";
        }
      }
    },
    async getWaehrungen() {
      const response = await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_WAEHRUNG_VALUES);
      if (response) {
        this.waehrungValues = response.data;
      }
    },
    openFormsFinder() {
      if (this.positions?.length >= 4) {
        this.openFehlerModal();
      } else {
        this.goToFormsFinder(
          "KAUF",
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField(value, position, field) {
      if (!value || value === 'NaN') { 
        value = null;
      }
      this.commitUpdatePositionsFromEvent( { [field]: value }, position.isin );
    },
    openModalDeleteFond(fond) {
        this.positionToDelete = fond;
        this.$refs.deleteModal.open();
    },
    doDeletePosition() {
        this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
        this.positionToDelete = null;
    },
    getPositionClasses(pos) {
        return { 'has-warnings': this.hasWarnings(pos)};
    },
    hasWarnings(pos) {
      const test =  pos && this.warnings?.filter(warn => warn.posGrpId === pos.posGrpId)?.length
      return pos && this.warnings?.filter(warn => warn.posGrpId === pos.posGrpId)?.length ;
    },
    headerActions() {
        return [PageHeaderSimpleAction('DELETE', 'Position löschen')];
    },
    gotoFondsinfo(row) {
      this.$addBreadcrumb({
          fullPath: this.$route.fullPath,
      });
      this.$router.push(`/shared/vermogensubersicht/fondsinfo/${row.isin}`);
    },
    openFehlerModal() {
      this.$confirmModal({
        title: 'Fehler', 
        message: 'Sie haben die maximal mögliche Anzahl an Fonds erreicht. Es sind nur 4 Positionen möglich.', 
        showConfirmButton: false,
        labelButtonCancel: 'Ok'
      })
    }
  },
};
</script>

<style src='@/styles/antrag.scss' lang='scss' scoped></style>
