<template>
  <div>
    <BaseModal
        ref="uploadModal"
        modalTitle="Datei hochladen"
        :confirmDisabled="!canUpload"
        labelButtonConfirm="Hochladen"
        @onConfirmButton="doUpload"
        @close="close"
    >
      <div>
        <div class="grid" v-if="additionalInputs" >
          
          <FotoButton v-if="uploadType!= upload_Type.FROM_DOKUMENTENARCHIV && cameraAvailable && allowFotoUpload" isSecondary edgeCorrection highQuality 
            camera="back" @file="onFoto" class="mb-1" style="float:left">
            Fotografieren
          </FotoButton>
          <MultiFileSelect 
            v-model="fileList" 
            :disabled="uploadType == upload_Type.FROM_DOKUMENTENARCHIV" 
            :maxFileSizeMB="maxFileSizeMB"
            @input="dropFiles" 
            v-if="multipleFileUpload"
            :accept="accept"/>
          <BaseFileSelect :disabled="uploadType == upload_Type.FROM_DOKUMENTENARCHIV" v-else @files="dropFiles" :accept="accept"/>

          <UploadInputList  v-if="additionalInputs.length" v-model="formData" :inputs="additionalInputs"/>
        </div>
      </div>
    </BaseModal> 
  </div>
</template>
<script>
import MultiFileSelect from '@/components/fileUpload/MultiFileSelect.vue';
import {uploadFileChunked} from '@/helpers/upload-helper'
import DOKUMENTENARCHIV_TYPES from "@/store/dokumentenarchiv/types";
import UploadInputList from '@/components/fileUpload/UploadInputList.vue';
import expressionEvaluator from '@/mixins/expression/mixin.js';
import BaseModal from "@/components/core/BaseModal.vue";
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import FotoButton from '@/components/fileUpload/FotoButton.vue';
import BERATUNG_VERSICHERUNG_TYPES from "@/store/beratungVersicherung/types";
import LOG_TYPES from '@/store/log/types'
import { buildMessage} from '@/helpers/log-message-helper';
import { toDateString } from '@/filters.js';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";

const UPLOAD_TYPE = Object.freeze({"DEFAULT":1, "DOKUMENTE_HINZUFUGEN": 2, "CUSTOM": 3, "BEDARFSERMITTLUNG": 4, "FROM_DOKUMENTENARCHIV": 5});

export default {
  mixins: [expressionEvaluator],
  components: {
    MultiFileSelect,
    UploadInputList,
    BaseModal,
    BaseFileSelect,
    FotoButton,
  },
  props: {
    files: {
      default: null,
      type: Array,
    },
    accept: {
      default: '*',
      type: String,
    },
    additionalInputs: {
      required: true,
      type: Array,
    },
    parentId: {
      type: String,
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
    uploadType: {
      type: Number,
      default: 1,
    },
    documentString: {
      type: String,
    },   
    multipleFileUpload: {
      type: Boolean,
      default: true,    
    },
    customUpload: {
      type: Function, // Example: ({ id, formData }) => {}
    },
    versicherungId: {
      type: [String, Number],
      default: null,
      required: false
    },
    schadenId: {
      type: [String, Number],
      default: null,
      required: false
    },
    showOnlySchadenDocs: {
      type: Boolean,
      default: false
    },
    allowFotoUpload: {
      type: Boolean,
      default: true
    },
    suppressReload: {
      type: Boolean,
      default: false
    },
    maxFileSizeMB: {
      type: Number,
      default: 0
    }
  },
  data () {
    const formData = this.getFormData();
    return {
      fileList: this.files,
      ...(formData && Object.keys(formData).length ? {formData} :{}),
      cameraAvailable: false,
      bezeichnungFields: ['input_bezeichnung', 'INPUT_BEZEICHNUNG'],
    }
  },
  computed: {
    upload_Type() {
      return UPLOAD_TYPE;
    },
    canUpload() {
      if (this.fileList?.length == 0)
        return false
      if (this.additionalInputs.some(input => input.valid && !this.evaluateUploadExpression(input.valid)))
        return false
      return true
    },
  },
  watch: {
    additionalInputs() {
      this.formData = this.getFormData();
    }
  },
  mounted: function () {
    if (this.openOnMount) {
      this.$refs.uploadModal.open();
      this.guessBezeichnung()
    }
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
      });
    }
    if(this.formData?.INPUT_KATEGORIE && this.showOnlySchadenDocs) {
      this.formData.INPUT_KATEGORIE = "SCHADENMELDUNG";
    }
  },
  methods: {
    dropFiles(files) {
      this.fileList = files
      this.guessBezeichnung()
    },
    guessBezeichnung() {
      if (this.additionalInputs && this.fileList && this.fileList.length === 1) {
        const bezeichnungInput = this.additionalInputs.find(input => this.bezeichnungFields.includes(input.key))
        
        if (bezeichnungInput && !this.formData[bezeichnungInput.key]) {
          this.formData[bezeichnungInput.key] = this.fileList[0].name?.replace(/\.[^/.]+$/, "") || ""
        }
      }
    },
    evaluateUploadExpression(expression) {
      return this.evaluateExpression(this.formData, expression);
    },
    getFormData() {
      const formData = {}
      this.additionalInputs.forEach(input => {
        if (input.type == 'DATE') {
          formData[input.key] = toDateString(input.value)
        }
        else if (input.type != 'LABEL')
          formData[input.key] = input.value
      })
      return formData;
    },
    onFoto(file) {
      if (this.multipleFileUpload)
        this.fileList.push(file);
      else
        this.fileList = [file];

      this.guessBezeichnung()
    },
    doUpload() {
      const formData = {...this.formData}
      this.additionalInputs.forEach(input => {
        if (input.ignore && this.evaluateUploadExpression(input.ignore)) {
          formData[input.key] = null
          return
        }
      })
      this.fileList.forEach((file, index) => {
        switch (this.uploadType) {
          case UPLOAD_TYPE.DOKUMENTE_HINZUFUGEN:
            uploadFileChunked(file).then((id) => {
              this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.DOKUMENTE_HINZUFUGEN_UPLOAD, {
                documentString: decodeURIComponent(this.documentString),
                parentId: this.parentId,
                fileId: id,
                ...(formData && Object.keys(formData).length ? {formData} : {}),
              });
            });            
            break;
          case UPLOAD_TYPE.CUSTOM:
            if(this.customUpload) {
              uploadFileChunked(file).then((id) => {
                this.customUpload({ 
                  id, 
                  formData,
                });
              }).catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Datei kann nicht hochgeladen werden.', 'danger'));
              })
            } else {
              console.error('customUpload -> not valid function');
            }
            break;

          case UPLOAD_TYPE.BEDARFSERMITTLUNG:
            uploadFileChunked(file).then((id) => {
              this.$store.dispatch(BERATUNG_VERSICHERUNG_TYPES.ACTIONS.FINANZPORTAL24_ONLINE_FILELINK, {
                fileId: id,
                formData,
              }).then(response => {
                  console.log(response);
                  window.open(response, '_blank');
              });
            });            
            break;         
            
            case UPLOAD_TYPE.FROM_DOKUMENTENARCHIV:
            this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.UPLOAD_DOCUMENT, {
                fileId: file?.id,
                parentId: file?.nodeId,
                formData,
                versicherungId: file?.insuranceId,
                schadenId: file?.schadenId,
              });      
              this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SET_ATTACHMENTS, {action: 'clear'});  
            break;               

          default:
            uploadFileChunked(file).then((id) => {
              this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.UPLOAD_DOCUMENT, {
                fileId: id,
                parentId: this.parentId,
                formData,
                versicherungId: this.versicherungId,
                schadenId: this.schadenId,
                suppressReload: this.fileList.length - 1 !== index || this.suppressReload,
              }).then(() => {
                if (this.fileList.length - 1 == index) {
                  this.$emit("uploadComplete")
                }
              });
            });
            break;
        }
      })
    },
    close() {
      // nextTick is needed because the 2 lines will result in destroying the component and the html classes in baseModal will not be removed
      this.$nextTick(() => {
        this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS_SUCCESS, null);
        this.$emit("close");
      });
    },
    open() {
      this.$refs.uploadModal.open();
    },
  },
}
</script>
