import COMMUNICATION_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import dayjs from 'dayjs';
import { makeQueryParam, } from '@/helpers/utils-helper';
import {viewDocument} from '@/components/core/download/DownloadLink.vue';

const spinnerConfig = {
  defaultSpinner: true
};

export default {
  [COMMUNICATION_TYPES.ACTIONS.TEMPLATE]({ commit, state, rootState }) {
    axios.post(rootState.core.apiAddress + '/template', {}, spinnerConfig).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.TEMPLATE, response.data);
    }).catch(error => {
      commit(COMMUNICATION_TYPES.MUTATIONS.TEMPLATE, error.response && error.response.data);
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS]({ commit, getters, rootState, dispatch }) {
    const postfachDefaultFilters = getters[COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS];
    const { fromDate, toDate, } = postfachDefaultFilters;
    const params = makeQueryParam({ 
      fromDate: fromDate ? dayjs(fromDate).format('DD.MM.YYYY') : '01.01.2000', 
      toDate: toDate ? dayjs(toDate).format('DD.MM.YYYY') : dayjs(new Date()).format('DD.MM.YYYY'), 
    });
    axios.get(`${rootState.core.apiAddress}/postfach/unread?${params}`).then(response => {
      if (response && response.data != null) {
        commit(COMMUNICATION_TYPES.MUTATIONS.UNREAD_COMMUNICATIONS, response.data);
      }
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to get number of unread messages", error});
    })
  },
  async [COMMUNICATION_TYPES.ACTIONS.LOAD_COMMUNICATION_INSURANCE_LISTS]({ commit, state, rootState, dispatch }, id) {
    await axios.get(rootState.core.apiAddress + '/postfach/listForInsurance?id=' + id, spinnerConfig).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_COMMUNICATION_INSURANCE_LISTS, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load communication lists", error});
    })
  },
  async [COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL]({ commit, state, rootState, dispatch }, emailId) {
    return await new Promise((resolve, reject) => {
      axios.post(rootState.core.apiAddress + '/../mrsmailviewer', {
        commId: emailId,
        commType: "email",
      }, spinnerConfig).then(response => {
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_EMAIL, {id: emailId, data: response.data});
        resolve(response.data);
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load e-mail", error});
        reject();
      })
    });
  },
  [COMMUNICATION_TYPES.ACTIONS.LOAD_SMS]({ commit, state, rootState, dispatch }, smsId) {
    axios.post(rootState.core.apiAddress + '/../mrsmailviewer', {
      commId: smsId,
      commType: "sms",
    }, spinnerConfig).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_SMS, {id: smsId, data: response.data});
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load sms", error});
    })
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_TALK]({ commit, state, rootState, dispatch }, talkId) {
    axios.get(rootState.core.apiAddress + '/postfach/talk?id=' + talkId, spinnerConfig)
    .then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TALK, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load talk", error});
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.SAVE_TALK]({ commit, state, rootState, dispatch }, payload) {
    const params = new URLSearchParams();
    Object.keys(payload).forEach((key) => {
      if (payload[key] != null)
        params.append(key, payload[key]);
    });
    axios.post(rootState.core.apiAddress + '/postfach/talk', params, spinnerConfig)
    .then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TALK, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load talk", error});
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.LOAD_TALK_CATEGORIES]({ commit, state, rootState, dispatch }) {
    axios.get(rootState.core.apiAddress + '/postfach/talkCategories', spinnerConfig)
    .then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TALK_CATEGORIES, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load talk categories", error});
    })
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_BEMERKUNG]({ rootState, dispatch }, {id, typ, gesellschaftId}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({id, typ, gesellschaftId});

      axios.get(rootState.core.apiAddress + `/postfachBemerkung/bemerkung?${params}`, spinnerConfig)
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load bemerkung", error});
        reject()
      })
    });
  },
  [COMMUNICATION_TYPES.ACTIONS.SAVE_BEMERKUNG]({ rootState, dispatch }, {data, typ}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({typ});

      axios.post(rootState.core.apiAddress + `/postfachBemerkung/bemerkung?${params}`, data, spinnerConfig)
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to load bemerkung", error},);
        reject()
      })
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.UPLOAD_BEMERKUNG_ATTACHMENT]({ rootState, dispatch }, {bemerkungId, tempFileId, typ}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({id: bemerkungId, fileId: tempFileId, typ});
      
      axios.put(rootState.core.apiAddress + `/postfachBemerkung/bemerkungAnhang?${params}`, null, spinnerConfig)
      .then(() => {
        resolve()
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to delete bemerkung's attachment", error});
        reject()
      })
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.DELETE_BEMERKUNG_ATTACHMENT]({ rootState, dispatch }, {bemerkungId, fileId, typ}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({id: bemerkungId, fileId, typ});

      axios.delete(rootState.core.apiAddress + `/postfachBemerkung/bemerkungAnhang?${params}`, spinnerConfig)
      .then(() => {
        resolve()
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "failed to delete bemerkung's attachment", error});
        reject()
      })
    })
  },
  
  [COMMUNICATION_TYPES.ACTIONS.GET_DEFAULT_RECEIVER]({ commit, state, rootState, dispatch }) {
    axios.get(rootState.core.apiAddress + '/ticketsnachrichten/defaultReceiver', {defaultSpinner: true}).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.GET_DEFAULT_RECEIVER, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in GET_DEFAULT_RECEIVER', error})
    })
  },

  async [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN_LAZY]({ commit, rootState, getters }, params) {
    const canRunLazyLoad = getters[COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_CAN_EXECUTE_LAZY_LOAD]();
    if (!canRunLazyLoad) {
      return;
    }

    const requestConfig = {
      params,
      defaultSpinner: true,
    }

    try {
      commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, new Date());
      const response = await axios.get(rootState.core.apiAddress + '/ticketsnachrichten/getTickets', requestConfig);

      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_LAZY, response.data);
      
    } catch (error) {
      commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, null);
    }
  },

  async [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN]({ commit, rootState }, params) {
    const requestConfig = {
      params,
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Nachrichten'
      }
    }

    try {
      commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, new Date());

      const response = await axios.get(rootState.core.apiAddress + '/ticketsnachrichten/getTickets', requestConfig);
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN, response.data);
    } catch (error) {
      commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, null);
    }
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN_COMBOBOXES]({ commit, state, rootState, dispatch }) {
    axios.get(rootState.core.apiAddress + '/ticketsnachrichten/comboboxOptions', spinnerConfig).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_COMBOBOXES, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in LOAD_TICKETS_NACHRICHTEN_COMBOBOXES', error})
    });
  
  },

  

  [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_INFO]({ commit, state, rootState, dispatch }, channelId) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + '/ticketsnachrichten/getTicket', {params: {channelId}, defaultSpinner: true})
      .then(response => {
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_INFO_SUCCESS, response.data);
        resolve();
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in LOAD_TICKET', error})
        reject();
      })
    });
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_BEWERTUNG_INFO]({ commit, state, rootState, dispatch }, chatId) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + '/ticketsnachrichten/getBewertung', {params: {chatId}, defaultSpinner: true})
      .then(response => {
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_BEWERTUNG_INFO_SUCCESS, response.data);
        resolve();
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in LOAD_TICKET', error})
        reject();
      })
    });
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_COMBOBOXES]({ commit, state, rootState, dispatch }, chatPartner) {
    return new Promise((resolve, reject) => {
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_COMBOBOXES, {bearbeiter: null});
      axios.get(rootState.core.apiAddress + '/ticketsnachrichten/ticketOptions', {params: {chatPartner}, defaultSpinner: true})
      .then(response => {
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKET_COMBOBOXES, response.data);
        resolve();
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in LOAD_TICKET_COMBOBOXES', error})
        reject();
      })
    });
  },

  [COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH]({ commit, state, rootState, dispatch }, {id, url}) {
    return axios.delete(rootState.core.apiAddress + '/postfach/' + url + '?id=' + id, spinnerConfig).then(response => {
      commit(COMMUNICATION_TYPES.MUTATIONS.DELETE_FROM_POSTFACH, {id, type: url});
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in DELETE_FROM_POSTFACH ' + url, error})
    })
  },
  [COMMUNICATION_TYPES.ACTIONS.OPEN_ATTACHMENT]({ commit, state, rootState, dispatch }, {attId, tempFileId}) {

    axios.post(rootState.core.apiAddress + '/../mrsattachment', /*{attId}*/
    {
      attId: attId,
      tempFileId:tempFileId,
    }, spinnerConfig).then(response => {
      viewDocument({data: response.data, filename: response.headers["filename"] || "Dokument.pdf"})
  }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in OPEN_ATTACHMENT', error})
    })
  },

  async [COMMUNICATION_TYPES.ACTIONS.SEND_PIN_VIA_SMS]({ rootState, }) {
    return await axios.post(`${rootState.core.apiAddress}/sms/sendPin`, {}, spinnerConfig)
      .then(response => response?.data || '')
      .catch(error => console.log(error));
  },

  async [COMMUNICATION_TYPES.ACTIONS.LOAD_DOCUMENT_OFFENE_UNTERSCHRIFTEN]({getters}, payload) {
    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsoffeneunterschriften`, payload)
  },

  async [COMMUNICATION_TYPES.ACTIONS.GET_ZUSAETZLICHE_DOCUMENT_OFFENE_UNTERSCHRIFTEN]({getters}, payload) {
    return await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsoffeneunterschriften`, payload)
  },

  async [COMMUNICATION_TYPES.ACTIONS.GET_CONFIGURED_CHATPARTNER]({ rootState, getters, commit, }) {
    if(!getters[CORE_TYPES.GETTERS.IS_CUSTOMER]) return;

    const response = await axios.get(`${rootState.core.apiAddress}/ticketsnachrichten/configuredChatpartner`, spinnerConfig);
    commit(COMMUNICATION_TYPES.MUTATIONS.GET_CONFIGURED_CHATPARTNER_SUCCESS, response.data || {});
  },

  async [COMMUNICATION_TYPES.ACTIONS.FIND_CHAT_WITH_LAST_MESSAGE]({ rootState, dispatch, }, { chatId, }) {
    if(!chatId) return;
    return await axios.get(rootState.core.apiAddress + '/ticketsnachrichten/chatWithLastMessage', {params: { chatId, }, defaultSpinner: true})
      .then(response => ({ ...(response?.data || {}), }))
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in FIND_CHAT_WITH_LAST_MESSAGE', error})
      })
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_PDF]({rootState}, payload) {
    return axios.post(rootState.core.apiAddress + '/ticketsnachrichten//getPdfTicket', payload);
  },

  [COMMUNICATION_TYPES.ACTIONS.LOAD_ABSENCE_NOTE]({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + '/absence_note', { defaultSpinner: true})
      .then(response => {
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_ABSENCE_NOTE, response.data);
        resolve();
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in LOAD_ABSENCE_NOTE', error})
        reject();
      })
    });
  },

  [COMMUNICATION_TYPES.ACTIONS.SAVE_ABSENCE_NOTE]({ commit, state, rootState, dispatch }) {
    let parseDate = (date, time) => {
      let begin_date;

      if(!date) return null

      if (dayjs(date).isValid()) {
        begin_date = dayjs(date, 'DD.MM.YYYY').format("DD.MM.YYYY");
      } else {
        begin_date = date
      }

      let begin_time =  time !== "" ? time : "00:00";

      let startDayjs = dayjs(
        begin_date + " " + begin_time,
        "DD.MM.YYYY HH:mm"
      );

      return startDayjs.toDate()
    }

    return new Promise((resolve, reject) => {
      axios.patch(rootState.core.apiAddress + '/absence_note', { enabled: state.absenceNote.enabled, message: state.absenceNote.message, date_start: parseDate(state.absenceNote.from, state.absenceNote.from_time), date_end: parseDate(state.absenceNote.to, state.absenceNote.to_time) }, { defaultSpinner: true})
      .then(response => {
        resolve();
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'error in SAVE_ABSENCE_NOTE', error})
        reject();
      })
    });
  },

  // Action to check if the user can edit the chat topic
  async [COMMUNICATION_TYPES.ACTIONS.LOAD_CAN_EDIT_THEMA]({ commit, rootState, dispatch }, chatId) {
    try {
      const response = await axios.get(`${rootState.core.apiAddress}/ticketsnachrichten/getCanEditThema`, {
        params: { chatId },
        defaultSpinner: true,
      });
      commit(COMMUNICATION_TYPES.MUTATIONS.SET_CAN_EDIT_THEMA, response.data.canEditThema);
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, { message: 'Error in LOAD_CAN_EDIT_THEMA', error });
    }
  },

  // Action to set the new chat topic
  async [COMMUNICATION_TYPES.ACTIONS.SET_CHAT_THEMA]({ commit, rootState, dispatch }, { chatId, thema }) {
    try {
      const payload = { chatId, thema };
      await axios.post(`${rootState.core.apiAddress}/ticketsnachrichten/setThema`, payload, { defaultSpinner: true });
      commit(COMMUNICATION_TYPES.MUTATIONS.SET_CHAT_THEMA_SUCCESS, thema);
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, { message: 'Error in SET_CHAT_THEMA', error });
    }
  },
  
}