var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.title,
          actions: _vm.headerActions
        },
        on: { "action-NEW": _vm.newFactsheet }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 20,
              rowId: "vvfactsheethistory"
            },
            on: { "click-name": _vm.openFactsheet }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }