<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Gesellschaften" 
      :defaultMenu="customOptionMenu" 
    />

      <div class="box__container" :tid="_generateTidFromString('box__container')" v-if="gesellschaftenList && gesellschaftenList.length">
        <div class="box__title">{{currentMenu.label}}</div>
        
        <ComboBox 
          isComponentHalfSize
          firstEmpty
          :value="gesellschaft"
          :values="gesellschaftenList"
          @change="onChange($event)"/>
      </div>

        <div class="box__container" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" 
        v-if="ansprechpartnerList && ansprechpartnerList.length">
          <Table 
            tableId="0eca709b-022c-4078-871f-633007348bae"
            :tableData="tableData"
            :cardViewEnabled="false"
            :exportEnabled="false"
            :rowsPerPage="20"
            :paginationEnabled="true"
            @selectedRows="onSelectedRows"
            showCheckBoxBulkAction>
          </Table>

          <BaseButton @click="addAnsprechpartner()" :disabled="selectedAnsprechpartner && !selectedAnsprechpartner.length">
            Markierte hinzufügen
          </BaseButton>
        </div>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from '@/components/table/Table.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue'
import UndrawContainerShip from '@/components/icons/undraw/UndrawContainerShip.vue'
import axios from 'axios';
import { updateQueryStringParameter } from '@/helpers/commonfunctions.js'
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    PageHeaderTitleNavigation,
    Table,
    OptionMenu,
    ComboBox,
    BaseButton
  },
  data() {
    return {
      menuOptions: [],
      ansprechpartnerList: [],
      selectedAnsprechpartner: [],
      descriptionSearch: '',
      gesellschaft: null,
      gesellschaftenList: [],
      currentMenu: {},
      loading: false,
    }
  },

  computed: {
    customOptionMenu() {

      return this.menuOptions && this.menuOptions.map((menuItem) => ({
        label: menuItem.label,
        component: this.componentByLagerstelle(menuItem),
        textBold: () => this.currentMenu?.key === menuItem?.key,
        action: () => {
          this.selectFormsByType(menuItem);
        },
      }));
    },
    tableData() {
      const headers = {
          fullName: {
            dataType: "String",
            key: "fullName",
            label: "Name",
            visible: true,
          },   
      };

      const result = {
        headers: headers,
        records: this.ansprechpartnerList
      };

      if (this.descriptionSearch.length > 0) {
        result.records = result.records.filter(r => r.fullName.search(new RegExp(this.descriptionSearch, "i")) > -1);
      }

      return result;
    },    
  },
  watch: {
    menuOptions: {
      handler() {
        if(this.menuOptions.length) {
          this.selectFormsByType(this.menuOptions[0]);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.retrieveMainStructure();
  },
  methods: {
    componentByLagerstelle(lagerstelle) {
      switch (lagerstelle?.key) {
        case 'Fondsgesellschaft':
          return UndrawWallet;
        case 'Bank':
          return UndrawWallet;
        case 'Geschlossene Fonds':  
          return UndrawContainerShip;
        case 'Versicherung':
          return UndrawSecureServer;
        default:
          return UndrawSecureServer;
      }
    },
    retrieveMainStructure() {
      const config = {
        defaultSpinner: true
      };

      let serviceUrl = '/apAuswahlService/mainStructure';

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.menuOptions = response.data
        }      

      })
    },     
    onChange(event) {
      const config = {
        defaultSpinner: true
      };

      this.gesellschaft = event
      
      let serviceUrl = '/apAuswahlService/listAP';

      if (event && event.length) {
        this.ansprechpartnerList = []
        this.selectedAnsprechpartner = []
        serviceUrl = updateQueryStringParameter(serviceUrl, 'gesellschaftId', event);
      } else {
        return
      }

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.ansprechpartnerList = response.data
        }    

      })
    },
    onSelectedRows(values) {
      this.selectedAnsprechpartner = [...Object.values(values).filter(ap => ap?.personId)]
    },
    addAnsprechpartner() {
      if (this.$route.query && this.$route.query.back) {
        // General back / new appointment back
        this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, this.selectedAnsprechpartner);
        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
        this.$router.push({ path: `${this.$route.query.back}?usersselected=true&backAction=true` });
      }
    },
    selectFormsByType(lagerstelle) {
      if((this.loading || this.currentMenu?.key === lagerstelle?.key)) {
        return;
      }
      this.ansprechpartnerList = []
      this.gesellschaftenList = []
      
      this.loading = true;
      this.currentMenu = lagerstelle;

      const config = {
        defaultSpinner: true
      };

      let serviceUrl = '/apAuswahlService/list';

      if (lagerstelle?.key) {
        const filterArt = lagerstelle.key;    

        if (filterArt) {
          serviceUrl = updateQueryStringParameter(serviceUrl, 'art', filterArt);
        } else {
          return;
        }

      }    

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.gesellschaftenList = response.data
          
        }      
        this.loading = false
      })

    },
  },
}
</script>
