<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Favoritenliste Wertpapiere" 
      :actions="headerActions" 
      @action-CREATE="handleTableAction({key: 'CREATE'})"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        sortableRows
        @action="handleTableAction($event)"
        @orderChanged="orderChanged"
      >

          <template v-slot:label="row">
              <a :class="{'bold': row.primary && !row.primaryFondsshop}" @click="openFavoritenliste(row)">{{row.label}}</a>
          </template>

          <template v-slot:primary="row">
            <InputToggleSwitch 
              :disabled="loading || row.primaryFondsshop || row.primary"
              :value="row.primary && !row.primaryFondsshop"
              @input="editAction({row, key: 'PRIMARY', value: $event, objKey: 'primary'})"
            />
          </template>

          <template v-slot:fondsshop="row">
            <InputToggleSwitch 
              :disabled="loading || row.primaryFondsshop || !options.zugriffUneingeschraenkt"
              :value="row.fondsshop"
              @input="editAction({row, key: 'FONDSSHOP', value: $event, objKey: 'fondsshop'})"
            />
          </template>

          <template v-slot:struktur="row">
            <InputToggleSwitch 
              v-if="row.owner"
              :disabled="loading || row.primaryFondsshop"
              :value="row.struktur"
              @input="editAction({row, key: 'STRUKTUR', value: $event, objKey: 'struktur'})"
            />
            <span v-else></span>
          </template>

          <template v-slot:beratung="row">
            <ComboBox
              :value="row.beratung" 
              :values="beratungValues"
              :disabled="loading || row.primaryFondsshop"
              @change="editAction({row, key: 'BERATUNG', value: $event, objKey: 'beratung'})"
            />
          </template>

      </Table>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 10, }, }"/>
      <NoData v-else />
    </div>

    <div class="box__container" v-if="!options.favoritenRestricted">
      <div >
        <p>Diese Liste dient zur Zusammenstellung Ihrer eigenen Fonds-Favoriten, die dann z.B. in der Wertpapiersuche aufrufbar sind.</p>
        <p>Auf Wunsch werden nur die hier von Ihnen ausgewählten Wertpapiere bei der 
            Auswahl in der Wertpapierorder berücksichtigt. Wählen Sie hierzu die Option „Favoritenliste" im Wertpapierfilter 
            während der Eingabe der Wertpapierorder aus.</p>
        <p>
          - Mit der Einstellung „Für Struktur freigeschaltet“ legen Sie fest, welche Ihrer Favoritenlisten an Ihre Unterstruktur vererbt wird
        </p>
        <p>
          - Mit der Einstellung „Für Beratung verwendet“ entscheiden Sie, welche der Favoritenlisten Ihnen z.B. in der Wertpapierinfo oder in 
          der Anlageempfehlung angezeigt werden. Bitte beachten Sie, dass wenn Sie keine Liste aktivieren, Ihnen keine Liste in den jeweiligen Tools angezeigt wird
        </p>
      </div>
    </div>
      
    <BaseModal 
      ref="renameFavoritenlisteModal"  
      labelButtonConfirm="Umbenennen"
      modalTitle="Favoritenliste umbenennen"
      @onConfirmButton="renameFavoritenListe()">

      <InputField 
        label="Bezeichnung"
        :disabled="loading"
        v-model="newFavoritenlisteName"
      />
    </BaseModal>

    <BaseModal 
      ref="createFavoritenlisteModal"  
      modalTitle="Favoritenliste erstellen"
      labelButtonConfirm="Erstellen"
      @onConfirmButton="createFavoritenListe()">

      <InputField 
        label="Bezeichnung"
        :disabled="loading"
        v-model="newFavoritenlisteName"
      />
    </BaseModal>

    <BaseModal 
      ref="copyFavoritenlisteModal" 
      labelButtonConfirm="Kopieren"
      @onConfirmButton="copyFavoritenListe()"
      modalTitle="Favoritenliste kopieren">

      <InputField 
        label="Bezeichnung"
        :disabled="loading"
        v-model="newFavoritenlisteName"
      />
    </BaseModal>
  </div>
</template>

<script>

import FONDSFAVORITEN_TYPES from '@/store/fondsfavoriten/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue'
import axios from 'axios';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import {SlotColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { makeQueryParam } from '@/helpers/utils-helper';
import { mapGetters } from 'vuex';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    InputToggleSwitch,
    GhostLoading,
    BaseButton,
    BaseModal,
    NoData,
    ComboBox,
  },
  data() {
    return {
      selectedFavorit: {},
      loading: false,
      newFavoritenlisteName: null,
      beratungValues: [
        {
          label: 'Ja',
          value: 'JA',
        },
        {
          label: 'Ja (inkl. Struktur)',
          value: 'JA_STRUKTUR',
        },
        {
          label: 'Nein',
          value: 'NEIN',
        },
      ],
      orderHasChanged: false,
    }
  },
  computed: {  
    ...mapGetters({
      favoritenlist: FONDSFAVORITEN_TYPES.GETTERS.FAVORITENLISTS,
      options: FONDSFAVORITEN_TYPES.GETTERS.OPTIONS,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE', 'Favoritenliste erstellen')
          .withVisible(() => this.options?.favoritenRestricted === false)
          .withDisabled(() => this.loading),
      ];
    },
    headers() {
      const headers = {
        lockedLeft: [
          SlotColumn("label", "Bezeichnung", 80, 1)
        ],
        center: [],
        lockedRight: []
      };

      if (!this.options.favoritenRestricted) {
        headers.lockedLeft.push(SlotColumn("primary", "Primär", 80, 1))

        if (this.options.showFondsshopParams) {
          headers.lockedLeft.push(SlotColumn("fondsshop", "Fondsshop", 80, 1))
        }
        
        headers.lockedLeft.push(SlotColumn("struktur", "Für Struktur freigeschaltet", 80, 1))
        headers.lockedLeft.push(SlotColumn("beratung", "Für Beratung verwendet", 80, 1))

        headers.lockedRight.push(ActionColumn('actions', 'Aktionen'))
      }

      return headers;
    },
    rows() {
      return this.favoritenlist.map(row => {
        return {...row, data: row, actions: this.makeActions(row)};
      });
    },
  },
  mounted() {
    this.retrieveFavoritenList();
  },
  methods: {
    renameFavoritenListe() {
      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/rename?labelNew=${encodeURIComponent(this.newFavoritenlisteName)}`, this.selectedFavorit, config)
        .then(() => this.retrieveFavoritenList())
        .catch(() => this.loading = false)   
    },
    createFavoritenListe() {
      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/create?labelNew=${encodeURIComponent(this.newFavoritenlisteName)}`, {}, config)
        .then(() => this.retrieveFavoritenList())
        .catch(() => this.loading = false)
    },
    deleteFavoritenListe() {
      this.loading = true

      axios.delete(`${process.env.VUE_APP_API}/favoritenlisteService/delete?favoritenlisteId=${this.selectedFavorit.id}`, config)
        .then(() => this.retrieveFavoritenList())
        .catch(() => this.loading = false)
    },
    copyFavoritenListe() {
      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/copy?labelNew=${encodeURIComponent(this.newFavoritenlisteName)}`, this.selectedFavorit, config)
        .then(() => this.retrieveFavoritenList())
        .catch(() => this.loading = false)        
    },
    openFavoritenliste(row) {
      this.$addBreadcrumb({
        label: 'zurück zur Favoritenliste', 
        fullPath: this.$route.fullPath,
      });

      this.$router.push(`/intern/fondsfavoritenliste/wertpapiere/${row.id}`)
    },
    handleTableAction(actionData) {

      this.selectedFavorit = actionData.row
      switch(actionData.key) {
        case 'CREATE':
          this.newFavoritenlisteName = ''
          this.$refs.createFavoritenlisteModal.open()
          break;
        case 'RENAME':
          this.newFavoritenlisteName = this.selectedFavorit.labelShort
          this.$refs.renameFavoritenlisteModal.open()
          break;
        case 'DELETE':
          this.deleteFavoritenListe()
          break;
        case 'COPY':
          this.newFavoritenlisteName = this.selectedFavorit.labelShort
          this.$refs.copyFavoritenlisteModal.open()
          break;
        default:
          break;
      }
    },
    editAction({row, key, value, objKey}) {
      this.loading = true;

      const payload = {
        id: row.id,
        [objKey]: value
      }
      
      axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/executeAction?action=${key}`, payload, config)
        .then(() => this.retrieveFavoritenList())
        .catch(() => {
          this.loading = false
        });
    },
    makeActions(child) {
      const actions = [
        SimpleAction("COPY", "PhCopy", "Favoritenliste kopieren")
      ];      

      if (!child.primaryFondsshop) {

        if (child.owner) {
          actions.push(SimpleAction("RENAME", "PhPencilLine", "Favoritenliste umbenennen"))

          if (!child.primary) {
            actions.push(ConfirmedAction("DELETE", 'PhTrash', "Favoritenliste löschen", `Soll die Favoritenliste "${child.label}" wirklich gelöscht werden?`, 'Favoritenliste löschen', "Löschen"))
          }
        }
      }

      return actions;
    },
    retrieveFavoritenList() {
      this.loading = true;

      this.$store.dispatch(FONDSFAVORITEN_TYPES.ACTIONS.LOAD_FAVORITENLISTS)
        .finally(() => this.loading = false);
    },   
    orderChanged(rows) {
      this.orderHasChanged = true
      this.$store.commit(FONDSFAVORITEN_TYPES.MUTATIONS.LOAD_FAVORITENLISTS_SUCCESS, rows.map(row => ({...row.data})));
    },
    saveOrder() {
        if (!this.orderHasChanged) {
          return
        }

        this.loading = true;

        const params = makeQueryParam({favoritenlisteId: this.favoritenlist.map(row => row.id)})

        return axios.post(`${process.env.VUE_APP_API}/favoritenlisteService/saveOrder?${params}`, undefined, config)
        .then(() => {
          this.orderHasChanged = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveOrder()
    next();
  },
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>
