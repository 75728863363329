<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation :title="title" :id="$appNavigation.currentOptionMenuId" />
        <div class="flex-container mb-4">
            <div class="flex-table-item mr-4">
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }"
                    :repeat="Object.keys(labels).length" />
                <div v-else-if="values && Object.keys(values).length > 0">
                    <dl v-if="isBeratungsmappe" class="key-value--item">
                        <dt class="value--item-label">Mappenstatus:</dt>
                        <dd class="value--item-value">
                            <span class="color-danger" v-if="this.eingegangenesDokument.beratungsmappeHasError">Negativ</span>
                            <span class="color-success" v-else >Positiv</span>
                        </dd>
                    </dl>
                    <dl v-if="isBeratungsmappe" class="key-value--item">
                        <dt class="value--item-label">Anlegerprofil:</dt>
                        <dd class="value--item-value">
                            <span class="color-success" v-if="this.eingegangenesDokument.beratungsmappeIsAPCorrect">Fehlerfrei</span>
                            <span class="color-danger" v-else >Fehlt/fehlerhaft</span>
                        </dd>
                    </dl>
                    <dl class="key-value--item">
                        <dt class="value--item-label">Rahmenvereinbarung:</dt>
                        <dd class="value--item-value">
                            <span class="color-success" v-if="this.eingegangenesDokument.RAHMENVEREINBARUNG">Ja</span>
                            <span class="color-danger" v-else >Nein</span>
                        </dd>
                    </dl>                    
                    <dl class="key-value--item">
                        <dt class="value--item-label">Informationsblatt:</dt>
                        <dd class="value--item-value">
                            <span class="color-success" v-if="this.eingegangenesDokument.INFORMATIONSBLATT">Ja</span>
                            <span class="color-danger" v-else >Nein</span>
                        </dd>
                    </dl>
                    <dl class="key-value--item">
                        <dt class="value--item-label">Ausweis vorhanden:</dt>
                        <dd class="value--item-value">
                            <span class="color-success" v-if="this.eingegangenesDokument.LEGITIMIERTER_AUSWEIS_VORHANDEN">Ja</span>
                            <span class="color-danger" v-else >Nein</span>
                        </dd>
                    </dl>
                    <dl class="key-value--item" v-for="(label, key) of labels">
                        <dt class="value--item-label">{{ label + ': ' }}</dt>
                        <dd class="value--item-value"
                            @click="values[key].onClick && values[key].text ? values[key].onClick() : () => { }"
                            :class="{ link: !!values[key].onClick && !!values[key].text }">{{ values[key].text ?
                                values[key].text : '-' }}</dd> <!--maybe use anchor element instead of clickable style-->
                    </dl>
                </div>
            </div>
            <div class="flex-legidocs-item">
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 100, }, }" />
                <div v-else>
                    <LegiDocsView :signatureAuthor="signatureAuthor" :kunde="kunde ? kunde.person : null"
                        :zusaetzlichePersonen="zusaetzlichePersonen" />
                </div>
            </div>
        </div>
        <!--TODO MOVE SIGNATUREAUTHOR BAR UP AND ADD PARAMETER TO SIGNATURENVERGLEICHCARDS-->
        <GhostLoading v-if="loading" :repeat=3 type="block" :config="{ block: { height: 100, }, }" />
        <DokumenteVergleichCards v-else @signatureAuthor="onNewSignatureAuthor($event)" class="mb-4" />

        <div class="button-container">
            <BaseButton label="Weiter" isSecondary @click="goToNext" />
        </div>
    </div>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from 'vuex';
import EINGEGANGENE_DOKUMENTE_TYPES from '@/store/eingegangeneDokumente/types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import NoData from "@/components/core/NoData.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import DokumenteVergleichCards from "./DokumenteVergleichCards.vue";
import SuitabilityConfirmationModal from "./SuitabilityConfirmationModal.vue";
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import LegiDocsView from './LegiDocsView.vue';
import { buildMessageWith } from '@/helpers/log-message-helper';

export const SIGNATURE_AUTHOR_TYPES = {
    'ALLE': { label: 'Alle', key: 'ALLE' },
    'DEPOTINHABER_1': { label: '1. Depotinhaber', key: 'DEPOTINHABER_1' },
    'DEPOTINHABER_2': { label: '2. Depotinhaber', key: 'DEPOTINHABER_2' },
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        NoData,
        InputRadioBoxGroup,
        InputTextArea,
        GhostLoading,
        BaseButton,
        BoxContainer,
        DokumenteVergleichCards,
        SuitabilityConfirmationModal,
        LegiDocsView
    },
    data() {
        return {
            pruefungsErgebnis: 'AUSSTEHEND',
            hasDataChanged: false,
            loading: true,
            signatureAuthor: SIGNATURE_AUTHOR_TYPES.ALLE,
            faxEingangIDs: [],
            mappenID: -1,
            pruefungsstatusValues: [
                { label: 'Angenommen', value: 'ANGENOMMEN' },
                { label: 'Abgelehnt', value: 'ABGELEHNT' },
                { label: 'Keine Angabe', value: 'AUSSTEHEND' }
            ],
        }
    },
    computed: {
        ...mapGetters({
            eingegangenesDokument: EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENES_DOKUMENT_RESULT, //TODO: rename eingegangenesDokument to something more suitable; maybe also refactor eingegangenesDokument to contain a field kunde and a field faxe with list of document
            eingegangenesDokumentList: EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENE_DOKUMENTE
        }),
        title() {
            if (this.mappenID > 0) {
                return `Eingegangene Dokumente für Beratungsmappe ${this.mappenID}`
            } else {
                return `Eingegangenes Dokument | ${this.faxEingangIDs || ''}`;
            }
        },
        headerActions() {
            return [
                PageHeaderSimpleAction('CHECK_SIGNATURES', 'Signaturen überpüfen')
            ]
        },
        labels() {
            const labels = {
                mappenName: 'Beratungsmappe',
                maklerName: 'Makler',
                kundenname: 'Kunde',
                adresse: 'Adresse',
                gesamtwertKauf: 'Kaufsumme',
                gesamtwertVerkauf: 'Verkaufssumme',
                gesamtwertSparplan: "Sparplansumme"
            };
            if(this.secondDepotinhaber) {
                labels.secondDepotinhaber = 'Zweiter Depotinhaber';
            }
            return labels;
        },
        values() {
            if (this.eingegangenesDokument) {
                const values = {
                    ...this.eingegangenesDokument,
                    kundenname: { text: `${this.kunde?.person.personalDataAddress?.firstName} ${this.kunde?.person.personalDataAddress?.lastName} (${this.kunde?.customerID})`, onClick: () => this.openCustomer(this.kunde?.customerID) },
                    adresse: { text: `${this.kunde?.person.personalDataAddress?.street}, ${this.kunde?.person.personalDataAddress?.zip} ${this.kunde?.person.personalDataAddress?.city}` },
                    gesamtwertKauf: { text: this.eingegangenesDokument.gesamtwertKauf ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                    this.eingegangenesDokument.gesamtwertKauf) : '' },
                    gesamtwertVerkauf: { text: this.eingegangenesDokument.gesamtwertVerkauf ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                    this.eingegangenesDokument.gesamtwertVerkauf) : '' },
                    gesamtwertSparplan: { text: this.eingegangenesDokument.gesamtwertSparplan ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                    this.eingegangenesDokument.gesamtwertSparplan) : '' },
                    maklerName: { text: `${this.kunde?.maklerName} (${this.kunde?.maklernr})`, onClick: () => this.openBroker(this.kunde?.maklernr) },
                    mappenName: {text: ''}
                }
                if (this.mappenID > 0) {
                    values.mappenName = { text: this.mappenID, onClick: () => this.openBeratungsmappe(this.kunde?.customerID, this.mappenID) };
                }
                if(this.secondDepotinhaber) {
                values.secondDepotinhaber =  { text: `${this.secondDepotinhaber.personalDataAddress?.firstName} ${this.secondDepotinhaber.personalDataAddress?.lastName} (${this.secondDepotinhaber.personId})` };
            }
                return values;
            } else {
                return {};
            }
        },
        kunde() {
            return this.eingegangenesDokument ? this.eingegangenesDokument.kunde : null;
        },
        zusaetzlichePersonen() {
            return this.eingegangenesDokument ? this.eingegangenesDokument.kunde.additionalPersons : [];
        },
        secondDepotinhaber() {
            return this.zusaetzlichePersonen.find(p => {
                return p.relationship?.extraDepotOwner;
            })
        },
        isBeratungsmappe() {
           return this.mappenID > 0; //mappenID=-1  is a catch-all for documents without a connected beratungsmappe 
        }
    },
    mounted() {
        this.faxEingangIDs = this.$route.params.faxEingangIDs.split(",");
        if (this.$route.query.mappenID) {
            this.mappenID = this.$route.query.mappenID;
        }
        this.loadData();

    },
    watch: {
        pruefungsErgebnis: {
            handler() {
                this.hasDataChanged = true;
            },
            once: true
        },
        eingegangenesDokument: {
            handler() {
                this.hasDataChanged = true;
            },
            once: true
        }
    },
    methods: {
        loadData() {
            this.loading = true;
            Promise.all([this.$store.dispatch(EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_EINGEGANGENES_DOKUMENT_RESULT, { faxEingangIDs: this.faxEingangIDs, mappenID: this.mappenID > 0 ? this.mappenID: null }),
            this.$store.dispatch(EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_SIGNATURES, { faxEingangIDs: this.faxEingangIDs })])
                .then(() => {

                    if (this.$route.query.legiSeperate && this.$route.query.legiSeperate === "true") {
                        this.openLegiImages();
                    }
                }).finally(() => {
                    this.loading = false;
                });

            ;
        },
        openSuitabilityConfirmationModal() {
            this.$refs.suitabilityConfirmationModal.open({ bemerkungText: this.eingegangenesDokument.faxEingang.bemerkung, pruefungsErgebnis: this.pruefungsErgebnis });
        },
        getIconColor(status) {
            if (!status) {
                return 'color-text';
            }
            switch (status.toUpperCase()) {
                case 'AUSSTEHEND':
                case 'NIE':
                    return 'color-warning';
                case 'JA':
                case 'ANGENOMMEN':
                    return 'color-success';
                case 'FEHLER':
                case 'ABGELEHNT':
                    return 'color-danger';

            }
        },
        onNewSignatureAuthor(newAuthor) { //TODO consider refactoring
            this.signatureAuthor = newAuthor;
        },
        goToNext() { //TODO: this method is kinda hard to read consider refactoring or adding comments
            const indexCurrentMappe = Object.keys(this.eingegangenesDokumentList).findIndex((mappenID) => {
                return this.mappenID === mappenID;
            });
            if (indexCurrentMappe > -1 && Object.keys(this.eingegangenesDokumentList)[indexCurrentMappe + 1]) {
                this.mappenID = Object.keys(this.eingegangenesDokumentList)[indexCurrentMappe + 1];
                    //manually set data, because we stay in the same component and so the route seems to not be updated, although the url does
                    this.faxEingangIDs = this.eingegangenesDokumentList[this.mappenID].map(eingegangenesDokument => eingegangenesDokument.faxEingangID);
                    this.$router.push({ path: `/intern/eingegangene-dokumente/faxeingang/${this.faxEingangIDs.join(',')}`, query: { mappenID: this.mappenID } });
                    this.loadData();
                } else if (this.eingegangenesDokumentList[-1]) { //eingegangenesDokument[-1] contains all "loose" dokumente (Dokumente without a beratungsmappe)
                    //we are in loose documents since there is no beratungsmappe anymore, so look here instead    
                const indexCurrentEingang = this.eingegangenesDokumentList[-1].findIndex(doc => {
                        return this.faxEingangIDs[0] == doc.faxEingangID;
                    })
                    if (indexCurrentEingang + 1 >= this.eingegangenesDokumentList[-1].length) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                            message: "Es liegen keine weiteren Mappen für die angegebenen Suchparameter vor.",
                            type: 'warning',
                            timeout: 2500,
                        }));
                    } else {
                        this.faxEingangIDs = [this.eingegangenesDokumentList[-1][indexCurrentEingang >= 0 ? indexCurrentEingang + 1 : 0].faxEingangID];
                        this.$router.push({ path: `/intern/eingegangene-dokumente/faxeingang/${this.faxEingangIDs[0]}` });
                        this.loadData();
                    } 
                } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                    message: "Es liegen keine weiteren Mappen für die angegebenen Suchparameter vor.",
                    type: 'warning',
                    timeout: 2500,
                })); //not entirely true, if the user started in the middle of the list; TODO should we catch that case
            }
        },
        openLegiImages() {
            window.open(this.getImgSrcLegi(this.kunde?.legitimation?.bildVorderseite));
            this.zusaetzlichePersonen.filter(p => {
                return p.relationship.extraDepotowner
            }).forEach(p => {
                window.open(this.getImgSrcLegi(p.legitimation?.bildVorderseite));
            })
        },
        getImgSrcLegi(image) {
            if (image) {
                let byteArray = base64ToArrayBuffer(image.bytes);
                const typedArray = new Uint8Array(byteArray);
                const blob = new Blob([typedArray], { type: image.contentType });
                return URL.createObjectURL(blob);
            }
            return '';
        },
        openBroker(maklernr) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: maklernr });
        },
        openCustomer(kundennr) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: kundennr })
        },
        openBeratungsmappe(kundennr, mappenID) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: kundennr, path: `/beratung/beratung/bearbeiten?id=${mappenID}` })
        }
    }
}
</script>
<style>
.flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.button-container {
    padding-bottom: 1em;
}


.flex-legidocs-item {
    /* TODO highlight as a box or separate otherwise from other content */
    flex: 2 0;
}

.flex-table-item {
    flex: 1 50px;
    border-right: 2px lightgray solid;
}

.flex-textarea-item {
    flex: 2;
}

.value--item-label {
    width: 33%;
    min-width: 33%;
}

.link {
    cursor: pointer;
    text-decoration: underline;
}</style>