<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Einstellungen" 
      subtitle="Erlaubte Gesellschaften lt. Gewerbeordnung §34f Absatz 1 Satz 1 Nr. 1-3"
      :actions="headerActions"
      @action-DISCARD="discardChanges()" 
      @action-ADD="openAddGesellschaftModal()" 
    />

    <template v-if="isSmallOrMediumScreen">
      <GhostLoading v-if="isLoadingData" type="block" :config="{ block: { height: '120px', }, }" useBoxContainer />
      <ConfigStepper3 
        v-else
        ref="stepper"
        :configStep="configStep"
        :selectedStep="selectedStep"
        :configData="configData"
        @setStep="setSelectedStep"
      />
    </template>
    <ContentWithStepper v-else :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigVerticalStep
          ref="stepper"
          :configStep="configStep"
          :selectedStep="selectedStep"
          :configData="configData"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
        />
      </template>

      <template #content>
        <div :key="selectedStep">
          <Gesellschaft34fTyp />
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal
      ref="editModal"
      :modalTitle="editModalTitle"
      size="lg"
      :showDefaultButtons="false"
      :actions="modalActions"
      @action-DISCARD="discardChanges()" 
      @action-ADD="openAddGesellschaftModal()" 
      @close="saveChanges"
    >
      <template #default>
        <div :key="selectedStep">
          <Gesellschaft34fTyp />
        </div>
      </template>
    </BaseModal>

    <AddMakler34fGesellschaftModal ref="addMakler34fGesellschaftModal" :gesell34fTyp="gesellschaft34fTyp.id" />
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import MAKLER34F_TYPES from '@/store/makler34f/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigVerticalStep from '@/components/core/configs/ConfigVerticalStep.vue';
import ConfigStepper3 from '@/components/core/configs/ConfigStepper3.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import Gesellschaft34fTyp from './steps/Gesellschaft34fTyp.vue';
import AddMakler34fGesellschaftModal from './components/AddMakler34fGesellschaftModal.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction, } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  data() {
    return {
      selectedStep: null,
      stepperCollapsed: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      data: MAKLER34F_TYPES.GETTERS.DATA,
      gesellschaft34fTyp: MAKLER34F_TYPES.GETTERS.GESELLSCHAFT34F_TYP_SELECTED,
      isLoadingData: MAKLER34F_TYPES.GETTERS.IS_LOADING_DATA,
      gesellschaften34fTypEdited: MAKLER34F_TYPES.GETTERS.GESELLSCHAFTEN34F_TYP_EDITED,
      makler34fGesellschaftenEdited: MAKLER34F_TYPES.GETTERS.MAKLER34F_GESELLSCHAFTEN_EDITED,
    }),
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    editModalTitle() {
      const { configStep, selectedStep } = this;
      return configStep?.find(step => step.stepKey === selectedStep)?.label || 'Einstellung';
    },
    hasDataChanged() {
      return Object.keys(this.gesellschaften34fTypEdited ||{}).length > 0 || Object.keys(this.makler34fGesellschaftenEdited).length > 0;
    },
    configStep() {
      const steps = this.data?.gesellschaften34fTyp?.map((gesellschaftTyp, index) => ({
        label: gesellschaftTyp.title,
        stepKey: gesellschaftTyp.id,
        index,
      }));
      return [ ...steps || [], ];
    },
    configData() {
      return {};
    },
    headerActions() {
      const { isLoadingData, hasDataChanged, gesellschaft34fTyp, isSmallOrMediumScreen } = this;

      if (isSmallOrMediumScreen) return [];

      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged || isLoadingData),
        PageHeaderSimpleAction('ADD', 'neue Gesellschaft anlegen').withDisabled(() => !gesellschaft34fTyp?.erlaubnis || isLoadingData),
      ];
    },
    modalActions() {
      const { isLoadingData, hasDataChanged, gesellschaft34fTyp, } = this;
      return [
        BaseModalSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged || isLoadingData),
        BaseModalSimpleAction('ADD', 'neue Gesellschaft anlegen').withDisabled(() => !gesellschaft34fTyp?.erlaubnis || isLoadingData),
      ];
    },
  },
  watch: {
    configStep() {
      if(!this.isSmallOrMediumScreen && !this.selectedStep && this.configStep.length > 0) {
        this.setSelectedStep(this.configStep[0].stepKey);
      }
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.FIND_DATA);

      this.openImportGesellConfirmModal();
    },
    openImportGesellConfirmModal() {
      if(!this.data?.hasNewGesellschaften) return;

      this.$confirmModal({
        title: 'angebotene Gesellschaften',
        message: `Es wurden neue Gesellschaften für die Gesellschaftslisten gefunden. Möchten Sie diese zu den Listen hinzufügen?`,
        labelButtonConfirm: 'Ja',
        labelButtonCancel: 'Nein',
      })
      .then(() => this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.IMPORT_GESELLSCHAFT, { ankreuzung: true, }))
      .catch(() => this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.IMPORT_GESELLSCHAFT, { ankreuzung: false, }));
    },
    discardChanges() {
      this.$store.commit(MAKLER34F_TYPES.MUTATIONS.RESET_GESELLSCHAFTEN34F_TYP_EDITED);
      this.$store.commit(MAKLER34F_TYPES.MUTATIONS.RESET_MAKLER34F_GESELLSCHAFTEN_EDITED);
    },
    openAddGesellschaftModal() {
      this.$refs.addMakler34fGesellschaftModal.open();
    },
    async setSelectedStep(stepKey) {
      await this.saveChanges();

      this.selectedStep = stepKey;
      this.$store.commit(MAKLER34F_TYPES.MUTATIONS.SET_GESELLSCHAFT34F_TYP_SELECTED, stepKey);

      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal?.open();
      }
    },
    async saveChanges() {
      await this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.SAVE_CHECKED_GESELLSCHAFTEN);
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges();

    this.$store.commit(MAKLER34F_TYPES.MUTATIONS.RESET_STATE);

    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ContentWithStepper,
    ConfigVerticalStep,
    ConfigStepper3,
    BaseModal,
    GhostLoading,
    Gesellschaft34fTyp,
    AddMakler34fGesellschaftModal,
  },
}
</script>
