<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
  </div>
</template>

<script>

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'

export default {
    beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Dbm',
      to,
      from
    });

    next()
  },

 


  components: {
    OptionMenu
  },
}
</script>

<style scoped>

</style>