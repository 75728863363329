<template>
  <BaseModal
    ref="vorganglisteModal"
    :modalTitle="activity"
    labelButtonConfirm="Hinzufügen"
    :confirmDisabled="!selectedValidRows.length || !fondHasIsin"
    :showCancelButton="!$isSmallScreen"
    closeButtonLabel=""
    @onConfirmButton="addFondsToListe"
  >
    <GhostLoading v-if="loading" type="table" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      :mobileConfig="{title: 'label', headers: ['fondIncludedPill'], disableClickRow: true}"
      rowId="id"
      :rowsPerPage="15"
      :tableRowsPerPage="[]"
      v-model="selectedRows"
    >
      <template #label="row">
        <div v-if="row.id == -1">
          <InputField  v-model="newName" :placeholder="placeholder" :disabled="!neuSelected" :validateUntouched="neuSelected" />
        </div>
        <span v-else>{{row.label}}</span>
      </template>
      <template #isSparplan="row">
        <div v-if="row.id == -1">
          <InputToggleSwitch v-model="isSparplan" label="" inLineLabel  :disabled="!neuSelected" />
        </div>
        <span v-else>{{row.isSparplan}}</span>
      </template>
    </Table>
  </BaseModal>
</template>

<script>
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import Table from "@/components/table2/Table.vue";
import { PillColumn, SlotColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  mixins: [validator],
  validators: {
    newName: required('Bitte eine Bezeichnung eingeben.')
  },
  components: {
    Table, GhostLoading, BaseModal, InputField, InputToggleSwitch
  },
  props: {
  },
  data() {
    return {
      loading: false,
      selectedRows: [],
      fonds: [],
      activity: '',
      openIdPath: '',
      openListPath: '',
      rows: [],
      newName: '',
      placeholder: '',
      isSparplan: false,
    }
  },
  computed: {
    headers() {
      return {
        lockedLeft: [
          SlotColumn("label", "Bezeichnung", 200, 1),
        ].concat (this.activity == 'MyDepot' ? [SlotColumn("isSparplan", "Sparplan", 200, 1)] : []),
        center: [
          PillColumn("fondIncludedPill", ""),
        ],
        lockedRight: []
      };
    },
    isins() {
      return this.fonds.map(f => f.isin).join(',');
    },
    fondHasIsin() {
      return !!this.isins
    },
    selectedValidRows() {
      return this.selectedRows.filter(fav => !fav.fondIncluded)
    },
    neuSelected() {
      return !!this.selectedRows.find(r => r.id == -1)
    },
  },
  methods: {
    async loadAuswahlLists() {
      this.loading = true;
      let resp = null;
      if (this.activity == 'Favoritenlisten') {
        resp = await axios.get(process.env.VUE_APP_API + '/favoritenlisteService/favoritenlist?isin=' + this.isins, {defaultSpinner: true});
      } else if (this.activity == 'MyDepot') {
        resp = await axios.get(process.env.VUE_APP_API + '/musterdepotService/mydepotlist?isin=' + this.isins, {defaultSpinner: true});
      } else if (this.activity == 'Fondsvergleich') {
        resp = await axios.post(process.env.VUE_APP_API + '/consultation/fondsvergleich', {ask: 'get_fonds_add', params: {isin: this.isins}}, {defaultSpinner: true});
      }
      if (resp.statusText.charAt(0) == '2') {
          const liste = resp.data.favoritenList || resp.data.liste;
          if (liste) {
            this.rows = liste.map(row => ({
              ...row,
              selectedDisabled: row.fondIncluded,
              fondIncludedPill: row.fondIncluded ? {label: "bereits enthalten", type: "success"} 
              : (row.canInsIsin?.length < this.fonds.length ? {label: "teils enthalten", type: "info"} : null)
            }))
            this.rows.unshift({
              id: -1, 
              label: '', 
              canInsIsin: this.isins.split(','), 
              fondIncluded: false, 
              fondIncludedPill: {label: this.placeholder, type: "info"}
            });
            this.selectedRows = this.rows.filter(fav => fav.fondIncluded)
          }
        }
        this.loading = false
    },
    async addFondsToListe() {
      if (!this.fondHasIsin || this.neuSelected && !this.newName) {
        return
      }
      const validRows = JSON.parse(JSON.stringify(this.selectedValidRows));
      const root = process.env.VUE_APP_API;

      const idx = validRows.findIndex(r => r.id == -1)
      if (idx !== -1) {
        let neu = null;
        if (this.activity == 'Favoritenlisten') {
          neu = await axios.post(`${root}/favoritenlisteService/create?labelNew=${this.newName}`, null, {defaultSpinner: true});
        } else if (this.activity == 'MyDepot') {
          neu = await axios.post(`${root}/favoritenlisteService/create?labelNew=${this.newName}&isSparplan=${this.isSparplan}`, null, {defaultSpinner: true});
        } else if (this.activity == 'Fondsvergleich') {
          neu = await axios.post(`${root}/consultation/fondsvergleich`, {ask: 'create', params: {labelNew: this.newName}}, {defaultSpinner: true});
        }
        if (neu.statusText.charAt(0) != '2' || !neu.data.id ) {
          return
        }
        validRows[idx].id = neu.data.id;
        validRows[idx].label = neu.data.label;
        validRows[idx].labelShort = neu.data.labelShort || '';
      }
      const listIds = validRows.map(favList => favList.id);


      if (this.activity == 'Favoritenlisten') {
        const query = makeQueryParam({favoritenlistIds: listIds, isins: this.isins.split(',')});
        await axios.post(`${root}/favoritenlisteService/addFonds?${query}`, null, {defaultSpinner: true});
      } else if (this.activity == 'MyDepot') {
        const query = makeQueryParam({listIds: listIds, isins: this.isins.split(',')});
        await axios.post(`${root}/musterdepotService/addFonds?${query}`, null, {defaultSpinner: true});
      } else if (this.activity == 'Fondsvergleich') {
        await axios.post(`${root}/consultation/fondsvergleich`, {ask: 'add_fonds', params: {listIds: listIds.join(','), isins: this.isins}}, {defaultSpinner: true});
      }
      
      let openActivity = true;
      if (this.fonds.length == 1) {
        const validRowsLabel = validRows.map(row => '<li>' + (row.labelShort || row.label) + '</li>').join('');
        try {
          const confirmText = (validRows.length == 1 ? (validRows[0].labelShort || validRows[0].label) : this.activity) + ' öffnen'
          await this.$confirmModal({
            title: 'Erfolg',
            message: `Der Fond "${this.fonds[0].fondsname}" wurde erfolgreich den folgenden ${this.activity} hinzugefügt:<br>
            <ul>
              ${validRowsLabel}
            </ul>`, 
            labelButtonConfirm: confirmText,
            labelButtonCancel: 'Schließen',
          })
        } catch (e) {
          openActivity = false;
        }
      } else {
        const validRowsLabel = validRows.map(row => '<li>in ' + (row.labelShort || row.label) + ' wurden ' + row.canInsIsin.length  + ' Fonds eingefügt</li>').join('');
        try {
          const confirmText = (validRows.length == 1 ? (validRows[0].labelShort || validRows[0].label) : this.activity) + ' öffnen'
          await this.$confirmModal({
            title: 'Erfolg',
            message: `Fonds wurden erfolgreich den folgenden ${this.activity} hinzugefügt:<br>
            <ul>
              ${validRowsLabel}
            </ul>`, 
            labelButtonConfirm: confirmText,
            labelButtonCancel: 'Schließen',
          })
        } catch (e) {
          openActivity = false;
        }
      }
      if (openActivity) {
        this.$addBreadcrumb({
          label: "zurück",
        });
        if ( validRows.length == 1) {
          if (this.activity == 'Fondsvergleich') {
            const settings = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.SETTINGS, {updateMonth: '1'});
            const row = settings.fv_params.find(fv => fv.id==validRows[0].id);
            const fvp = !row 
            ? {              
              id: validRows[0].id,
              description: validRows[0].label,
              create: "", } 
            : {              
              id: row.id,
              description: row.description,
              create: row.dateCreated,
              params: row.params,
            };
            this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {fvp});
          }
          this.$router.push(this.openIdPath + validRows[0].id)
        } else {
          this.$router.push(this.openListPath )
        }
      }
    },
    open(rows, name) {
      this.fonds = rows;
      this.activity = name;
      let ok = true;
      this.newName = '';
      this.selectedRows = [];
      this.rows = [];
      switch (name) {
        case 'Favoritenlisten':
          this.openIdPath = '/intern/fondsfavoritenliste/wertpapiere/';
          this.openListPath = '/intern/fondsfavoritenliste/list';
          this.placeholder = 'neue Favoritenliste';
          break;

        case 'MyDepot':
          this.openIdPath = '/intern/mydepot/wertpapiere/';
          this.openListPath = '/intern/mydepot/list';
          this.placeholder = 'neues Musterdepot';
          break;

        case 'Fondsvergleich':
          this.openIdPath = '/beratung/fondsvergleich/';
          this.openListPath = '/beratung/fondsvergleich';
          this.placeholder = 'neuer Fondsvergleich';
          break;

        default:
          ok = false;
          break;
      }
      if (ok) {
        this.$nextTick(() => {
          this.loadAuswahlLists()
          this.$refs.vorganglisteModal.open()
        })
      }
    },
    // beforeRouteLeave(to, from, next) {
    //   console.log('beforeRouteLeave', to, from)
    //   this.$addBreadcrumb({
    //     label: "zurück",
    //     to,
    //     from,
    //   });
    //   next();
    // },
  },
}
</script>