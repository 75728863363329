import CORE_TYPES from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import sanitizeHtml from 'sanitize-html';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    template: {

    },
    unreadMessages: {},
    //communicationHeaders: null,
    communicationRecords: null,
    communicationInsuranceRecords: null,
    emails: {},
    smss: {},
    talks: {},
    talkCategories: [],
    bemerkungen: {},
    sanitizeHtmlConfig: {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'font']),
        allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            img: sanitizeHtml.defaults.allowedAttributes.img.concat(['width', 'height', 'style', 'alt', 'title']),
            table: ['width', 'height', 'cellpadding', 'cellspacing', 'style', 'border'],
            td: ['width', 'height', 'valign'],
            p: ['font-size', 'font-family', 'color', 'style'],
            span: ['font-size', 'font-family', 'color', 'style'],
            font: ['color', 'style'],
        },
        allowedSchemesByTag: {
            img: ['data', 'http', 'cid', 'https'],
        },
        allowedStyles: {
            '*': {
                'border': [/^.*$/i],
                'border-top': [/^.*$/i],
                'border-bottom': [/^.*$/i],
                'color': [/^.*$/i],
                'font-size': [/^\d+pt$/i],
                'font-family': [/^.*$/i],
                'width': [/^.*$/i],
                'height': [/^.*$/i],
                'text-align': [/^.*$/i],
            }
        }
    },
    defaultReceiver: "",
    ticketsNachrichten: [],
    ticketsNachrichtenLazy: [],
    ticketsNachrichtenLastLoadedTime: null,
    ticketsNachrichtenBearbeiter: [],
    ticketsNachrichtenStatuses: [],
    chatPartnerOptions: [],
    bearbeiterOptions: [],
    ticketInfo: {},
    bewertungInfo: {
      canClose: true,
    },
    configuredChatpartner: {},
    unsentNachricht: "",
    absenceNote: {
      enabled: false,
      from: '',
      from_time: '',
      to: '',
      to_time: '',
      message: '',
    },
    canEditThema: false,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}