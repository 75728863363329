var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Kontakten"
        }
      }),
      _vm.kontaktList && _vm.kontaktList.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: _vm._generateTidFromString("box__container") }
            },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.TABLE_TITLE))
              ]),
              _c("InputField", {
                attrs: {
                  isComponentHalfSize: "",
                  placeholder: "Suchen nach Name"
                },
                model: {
                  value: _vm.descriptionSearch,
                  callback: function($$v) {
                    _vm.descriptionSearch = $$v
                  },
                  expression: "descriptionSearch"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.kontaktList && _vm.kontaktList.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "cards__container layout__negative-margin--8"
                )
              }
            },
            [
              _c("Table", {
                attrs: {
                  tableId: "0eca709b-022c-4078-871f-633007348bae",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10
                },
                model: {
                  value: _vm.selectedKontakte,
                  callback: function($$v) {
                    _vm.selectedKontakte = $$v
                  },
                  expression: "selectedKontakte"
                }
              }),
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled:
                      _vm.selectedKontakte && !_vm.selectedKontakte.length
                  },
                  on: {
                    click: function($event) {
                      return _vm.addKontakte()
                    }
                  }
                },
                [_vm._v(" Markierte hinzufügen ")]
              ),
              _vm.isByPass
                ? _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.createKontakt()
                        }
                      }
                    },
                    [_vm._v(" Neuen Kontakt anlegen ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }