var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-edit-param-label__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Marketing Kampagne bearbeiten",
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions
        },
        on: { "action-NEW": _vm.newMarketing }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "input-forms__container" },
          [
            _c("InputField", {
              attrs: { label: "Titel", isComponentHalfSize: "" },
              on: {
                change: function($event) {
                  return _vm.setMarketingCampaignAsChanged(true)
                }
              },
              model: {
                value: _vm.campaign.title,
                callback: function($$v) {
                  _vm.$set(_vm.campaign, "title", $$v)
                },
                expression: "campaign.title"
              }
            }),
            _c("DatePickerField", {
              attrs: {
                isRangePicker: "",
                isComponentHalfSize: "",
                label: "Von / Bis",
                value: {
                  from: _vm.campaign.dateFrom,
                  to: _vm.campaign.dateUntil
                }
              },
              on: {
                onSelectRangeFrom: function($event) {
                  return _vm.setFromDate($event)
                },
                onSelectRangeTo: function($event) {
                  return _vm.setUntilDate($event)
                }
              }
            }),
            _c("InputToggleSwitch", {
              attrs: { label: "Ist Aktiv", inLineLabel: "" },
              on: {
                change: function($event) {
                  return _vm.setMarketingCampaignAsChanged(true)
                }
              },
              model: {
                value: _vm.campaign.active,
                callback: function($$v) {
                  _vm.$set(_vm.campaign, "active", $$v)
                },
                expression: "campaign.active"
              }
            }),
            _c("HtmlEditor", {
              attrs: { isFontParams: "" },
              on: {
                change: function($event) {
                  return _vm.setMarketingCampaignAsChanged(true)
                }
              },
              model: {
                value: _vm.campaign.body,
                callback: function($$v) {
                  _vm.$set(_vm.campaign, "body", $$v)
                },
                expression: "campaign.body"
              }
            }),
            _c("ParticipantInput", {
              attrs: {
                label: "Makler Blacklist",
                value: _vm.targetListExcluded,
                maxItemsVisible: _vm.maxItemsVisible,
                multipleReceivers: _vm.multipleReceivers,
                isUserSelect: _vm.isUserSelect,
                isMaklerOnly: true,
                availableUsers: _vm.availableUsers
              },
              on: {
                addItem: function($event) {
                  return _vm.clickReceiveUsers($event, "EXCLUDED")
                },
                deleteReceiveUsers: function($event) {
                  return _vm.deleteReceiveUsers($event)
                },
                deleteAllReceiveUsers: function($event) {
                  return _vm.deleteAllReceiveUsers($event)
                },
                handleAddMakler: function($event) {
                  return _vm.handleAddMakler("MAKLER", "EXCLUDED")
                },
                userPreview: function($event) {
                  _vm.isUserPreviewActivated = $event
                }
              }
            }),
            _c("ParticipantInput", {
              attrs: {
                label: "Makler Whitelist",
                value: _vm.targetListIncluded,
                maxItemsVisible: _vm.maxItemsVisible,
                multipleReceivers: _vm.multipleReceivers,
                isUserSelect: _vm.isUserSelect,
                isMaklerOnly: true,
                availableUsers: _vm.availableUsers
              },
              on: {
                addItem: function($event) {
                  return _vm.clickReceiveUsers($event, "INCLUDED")
                },
                deleteReceiveUsers: function($event) {
                  return _vm.deleteReceiveUsers($event)
                },
                deleteAllReceiveUsers: function($event) {
                  return _vm.deleteAllReceiveUsers($event)
                },
                handleAddMakler: function($event) {
                  return _vm.handleAddMakler("MAKLER", "INCLUDED")
                },
                userPreview: function($event) {
                  _vm.isUserPreviewActivated = $event
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }