import LIMIT_TYPES from './types';
import LOG_TYPES from '@/store/log/types'
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";

const config = {
  defaultSpinner: true
};

export default {

  // --- Depotlimit 

  [LIMIT_TYPES.ACTIONS.GET_DEPOTLIMIT]({ commit }, payload) {
    axios.get(`${process.env.VUE_APP_API}/limit/getDepotlimit?kundennr=${payload?.kundennr || ''}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_SUCCESS, response.data)
      }
    })
  },
 
  async [LIMIT_TYPES.ACTIONS.DELETE_DEPOTLIMIT]({ dispatch, commit }, payload) { 
    await axios.delete(`${process.env.VUE_APP_API}/limit/deleteDepotlimit?kundennr=${payload?.kundennr || ''}`, config).then(response => {
      if (response.data.errorStr) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
      } else {
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_SUCCESS, response.data)
      }
    })
  },
 
  async [LIMIT_TYPES.ACTIONS.DELETE_OVERTAKEN_DEPOTLIMITS]({ dispatch }, payload) { 
    await axios.delete(`${process.env.VUE_APP_API}/limit/deleteOvertakenDepotlimits?includeStruktur=${payload?.includeStruktur || ''}`, config).then(response => {
      if (response.data.errorStr) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
      }
    })
  },
 
  [LIMIT_TYPES.ACTIONS.SAVE_DEPOTLIMIT]({ commit }, payload) {
    axios.post(`${process.env.VUE_APP_API}/limit/saveDepotlimit?kundennr=${payload.kundennr || ''}`, payload, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_SUCCESS, response.data)
      }
    })
    .catch((err) => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(err?.message || err, 'danger')));
  },

  async [LIMIT_TYPES.ACTIONS.GET_DEPOTLIMIT_LIST]({ dispatch, commit }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/limit/getDepotlimitList?includeStruktur=${payload?.includeStruktur || ''}`, config).then(response => {
      if (response.data.errorStr) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
      } else {
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_LIST_SUCCESS, response.data)
      }
    })
  },
 
  // --- List of the Depotpositionslimits

  async [LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_LIST]({ dispatch, commit }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/limit/getDepotpositionLimitList?includeStruktur=${payload?.includeStruktur || ''}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        }
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS, response.data?.limits || []);
      }
    })
  },

  [LIMIT_TYPES.ACTIONS.DELETE_DEPOTPOSITION_LIMIT]({ dispatch, commit }, payload) {
    if (payload?.bgsNr) {
      axios.delete(`${process.env.VUE_APP_API}/limit/deleteDepotpositionlimit?bgsNr=${payload.bgsNr}&includeStruktur=${payload.includeStruktur || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS, response.data?.limits || [])
          }
        }
      })
    }
  },
 
  [LIMIT_TYPES.ACTIONS.DELETE_ALL_DEPOTPOSITION_LIMITS]({ dispatch, commit }, payload) {
    if (payload?.action) {
      axios.delete(`${process.env.VUE_APP_API}/limit/deleteAllDepotpositionlimits?action=${payload.action}&includeStruktur=${payload.includeStruktur || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            if (response.data?.info) {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.info, 'info'));
            }
            commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS, response.data?.limits || [])
          }
        }
      })
    }
  },

  // --- List of the automatische Depotpositionslimits

  async [LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST]({ dispatch, commit }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/limit/getAutomaticDepotpositionLimitList?includeStruktur=${payload?.includeStruktur || ''}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        }
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST_SUCCESS, response.data?.depotpositionLimitsAutomatic || []);
      }
    })
  },

  // --- Automatisches Depotpositionslimit

  [LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC]({ dispatch, commit }, payload) {
    axios.get(`${process.env.VUE_APP_API}/limit/getAutomaticDepotpositionLimit?id=${payload?.id || ''}&kundennr=${payload?.kundennr || ''}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        }
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_SUCCESS, response.data || []);
      }
    })
  },

  async [LIMIT_TYPES.ACTIONS.SAVE_DEPOTPOSITION_LIMIT_AUTOMATIC]({}, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API}/limit/saveAutomaticDepotpositionLimit?id=${payload?.id}&kundennr=${payload?.kundennr}&depotId=${payload?.depot}`, 
      payload, config);
    return response;
  },

  [LIMIT_TYPES.ACTIONS.DELETE_DEPOTPOSITION_LIMIT_AUTOMATIC]({ commit }, payload) {
    axios.delete(`${process.env.VUE_APP_API}/limit/deleteAutomaticDepotpositionlimit?id=${payload?.id}&includeStruktur=${payload?.includeStruktur}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST_SUCCESS, response.data.depotpositionLimitsAutomatic)
      }
    })
  },

  // --- Risikoklassenlimit

  [LIMIT_TYPES.ACTIONS.GET_RISIKOKLASSEN_LIMIT]({ dispatch, commit }, payload) {
    axios.get(`${process.env.VUE_APP_API}/limit/getRisikoklassenlimit?kundennr=${payload?.kundennr}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_SUCCESS, response.data || {});
        }
      }
    })
  },

  [LIMIT_TYPES.ACTIONS.SAVE_RISIKOKLASSEN_LIMIT]({ commit }, payload) {
    return new Promise((resolve) => {
      axios.post(`${process.env.VUE_APP_API}/limit/saveRisikoklassenlimit?kundennr=${payload?.kundennr}`, payload, {
        ...config,
        cancelableRequest: {
          title: 'Risikoklassenlimit wird gespeichert'
        },
      }).then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            resolve(response.data.errorStr);
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_SUCCESS, response.data || {});
            resolve(response.data);
          }
        }
      })
    });
  },

  [LIMIT_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_LIMIT]({ dispatch, commit }, payload) {
    axios.delete(`${process.env.VUE_APP_API}/limit/deleteRisikoklassenlimit?kundennr=${payload?.kundennr}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_SUCCESS, response.data)
        }
      }
    })
  },

  // --- List of Risikoklassenlimits

  async [LIMIT_TYPES.ACTIONS.GET_RISIKOKLASSEN_LIMIT_LIST]({ dispatch, commit }, payload) {
    await axios.get(`${process.env.VUE_APP_API}/limit/getRisikoklassenlimitList?includeStruktur=${payload?.includeStruktur}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_LIST_SUCCESS, response.data || {});
        }
      }
    })
  },

  [LIMIT_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST]({ dispatch, commit }, payload) {
    axios.delete(`${process.env.VUE_APP_API}/limit/deleteRisikoklassenlimit?kundennr=${payload?.kundennr}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(LIMIT_TYPES.MUTATIONS.DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST_SUCCESS, payload?.kundennr)
        }
      }
    })
  },

  [LIMIT_TYPES.ACTIONS.DELETE_ALL_OVERTAKEN_RISIKOKLASSEN_LIMITS]({ dispatch, commit }, payload) {
    axios.delete(`${process.env.VUE_APP_API}/limit/deleteAllOvertakenRisikoklassenlimits?includeStruktur=${payload?.includeStruktur}`, config).then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          if (response.data?.message) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'info'));
          }
          commit(LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_LIST_SUCCESS, response.data || {});
        }
      }
    })
  },

    // --- List of Wertpapierkategorie Limits

    async [LIMIT_TYPES.ACTIONS.GET_WERTPAPIERKATEGORIE_LIMIT_LIST]({ dispatch, commit }) {
      await axios.get(`${process.env.VUE_APP_API}/limit/getWertpapierkategorieLimitList`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIERKATEGORIE_LIMIT_LIST_SUCCESS, response.data || []);
          }
        }
      })
    },
    
    [LIMIT_TYPES.ACTIONS.GET_WERTPAPIERKATEGORIE_LIMIT]({ dispatch, commit }, payload) {
      axios.get(`${process.env.VUE_APP_API}/limit/getWertpapierkategorieLimit?kundennr=${payload?.kundennr}&depotId=${payload?.depotId}&kontoNr=${payload?.kontoNr}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIERKATEGORIE_LIMIT_SUCCESS, response.data || {});
          }
        }
      })
    },
    
    [LIMIT_TYPES.ACTIONS.SAVE_WERTPAPIERKATEGORIE_LIMIT]({ commit }, payload) {
      return new Promise((resolve) => {
        axios.post(`${process.env.VUE_APP_API}/limit/saveWertpapierkategorieLimit?kundennr=${payload?.kundennr}&depotId=${payload?.depotId}&kontoNr=${payload?.kontoNr}`, payload, config).then(response => {
          if (response.status === 200 && response.data) {
            if (response.data.errorStr) {
              resolve(response.data.errorStr);
            } else {
              commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIERKATEGORIE_LIMIT_SUCCESS, response.data || {});
              resolve();
            }
          }
        })
      });
    },
    
    async [LIMIT_TYPES.ACTIONS.DELETE_WERTPAPIERKATEGORIE_LIMIT]({}, payload) {
      const response = await axios.delete(`${process.env.VUE_APP_API}/limit/deleteWertpapierkategorieLimit?kundennr=${payload?.kundennr}&depotId=${payload?.depotId}&kontoNr=${payload?.kontoNr}`, config);
      return response;
    },

    async [LIMIT_TYPES.ACTIONS.RESET_WERTPAPIERKATEGORIE_LIMIT]({}, payload) {
      const response = await axios.post(`${process.env.VUE_APP_API}/limit/resetWertpapierkategorieLimit?kundennr=${payload?.kundennr}&depotId=${payload?.depotId}&kontoNr=${payload?.kontoNr}`, config);
      return response;
    },

    // --- List of global Wertpapier Limits

    async [LIMIT_TYPES.ACTIONS.GET_WERTPAPIER_LIMIT_LIST]({ dispatch, commit }, payload) {
      await axios.get(`${process.env.VUE_APP_API}/limit/getWertpapierLimitList?includeStruktur=${payload?.includeStruktur}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_LIST_SUCCESS, response.data || []);
          }
        }
      })
    },

    [LIMIT_TYPES.ACTIONS.DELETE_OVERTAKEN_WERTPAPIER_LIMITS]({ dispatch, commit }, payload) {
      axios.delete(`${process.env.VUE_APP_API}/limit/deleteOvertakenWertpapierlimits?includeStruktur=${payload?.includeStruktur}&modus=${payload?.modus}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            if (response.data.info) {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.info, 'info'));
            }
            switch (payload?.modus) {
              case 'LIMIT_KURS':
                commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_LIST_SUCCESS, response.data.limits || []);
                break;
              case 'LIMIT_GD200':
              case 'LIMIT_GD200_BGS':
                commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_LIST_SUCCESS, response.data.limits || []);
                break;
              default: break;
            
            }
          }
        }
      })
    },
    
    async [LIMIT_TYPES.ACTIONS.RESET_MORE_LIMITS]({ dispatch, commit, state }, payload) {
      const stateName = payload.stateName == 'gd200LimitListB' ? 'gd200LimitList' : payload.stateName;
      if (!stateName || state[stateName] === undefined) {
        return;
      }
      const response = await axios.put(`${process.env.VUE_APP_API}/limit/resetmorelimits?includeStruktur=${payload?.includeStruktur}&stateName=${payload?.stateName}`, config)
      if (response.status === 200 && response.data) {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          if (response.data.info) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.info, 'info'));
          }
          if (response.data.limits) {
            commit(LIMIT_TYPES.MUTATIONS.SET_MORE_LIMITS, {stateName, limits: response.data.limits || [] } );
          }
        }
      }
    },
    
    [LIMIT_TYPES.ACTIONS.GET_WERTPAPIER_LIMIT]({ dispatch, commit }, payload) {
      axios.get(`${process.env.VUE_APP_API}/limit/getWertpapierLimit?isin=${payload?.isin || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_SUCCESS, response.data || []);
          }
        }
      })
    },
    
    [LIMIT_TYPES.ACTIONS.SAVE_WERTPAPIER_LIMIT]({ commit }, payload) {
      return new Promise((resolve) => {
        axios.post(`${process.env.VUE_APP_API}/limit/saveWertpapierlimit?isin=${payload?.isin || ''}`, payload, config).then(response => {
          if (response.status === 200 && response.data) {
            if (response.data.errorStr) {
              resolve(response.data.errorStr);
            } else {
              commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_SUCCESS, response.data || {});
              resolve();
            }
          }
        })
      });
    },
     
    [LIMIT_TYPES.ACTIONS.DELETE_FONDS_LIMIT]({ dispatch, commit }, payload) {
      axios.delete(`${process.env.VUE_APP_API}/limit/deleteFondslimit?id=${payload?.id || ''}&modus=${payload?.modus}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            switch (payload?.modus) {
              case 'LIMIT_KURS':
                commit(LIMIT_TYPES.MUTATIONS.DELETE_WERTPAPIER_LIMIT, payload.id);
                commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_SUCCESS, response.data || {});
                break;
              case 'LIMIT_GD200':
              case 'LIMIT_GD200_BGS':
                commit(LIMIT_TYPES.MUTATIONS.DELETE_GD200_LIMIT, payload.id);
                commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_SUCCESS, response.data || {});
                break;
              default: break;
            }
          }
        }
      })
    },

    [LIMIT_TYPES.ACTIONS.RESET_WERTPAPIER_LIMIT]({ dispatch, commit }, isin) {
      axios.post(`${process.env.VUE_APP_API}/limit/resetWertpapierlimit?isin=${isin || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_SUCCESS, response.data || {});
          }
        }
      })
    },

    //  --- GD200-LIMITS -- Depotpositionen GD200-Limits

    async [LIMIT_TYPES.ACTIONS.GET_GD200_LIMIT_LIST]({ dispatch, commit }, payload) {
      await axios.get(`${process.env.VUE_APP_API}/limit/getGD200LimitList?includeStruktur=${payload?.includeStruktur}&modus=${payload?.modus}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_LIST_SUCCESS, response.data || []);
          }
        }
      })
    },

    [LIMIT_TYPES.ACTIONS.GET_GD200_LIMIT]({ dispatch, commit }, payload) {
      axios.get(`${process.env.VUE_APP_API}/limit/getGD200Limit?modus=${payload?.modus}&id=${payload?.id || ''}&isin=${payload?.isin}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_SUCCESS, response.data || []);
          }
        }
      })
    },

    [LIMIT_TYPES.ACTIONS.SAVE_GD200_LIMIT]({ commit }, payload) {
      return new Promise((resolve) => {
        axios.post(`${process.env.VUE_APP_API}/limit/saveGD200Limit?id=${payload?.id || ''}&isin=${payload?.isin || ''}&bgsNr=${payload?.bgsNr || ''}&modus=${payload?.modus || ''}`, payload, config).then(response => {
          if (response.status === 200 && response.data) {
            if (response.data.errorStr) {
              resolve(response.data.errorStr);
            } else {
              commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_SUCCESS, response.data || {});
              resolve();
            }
          }
        })
      });
    },
    
    [LIMIT_TYPES.ACTIONS.RESET_GD200_LIMIT]({ dispatch, commit }, payload) {
      axios.post(`${process.env.VUE_APP_API}/limit/resetGD200limit?id=${payload?.id || ''}&modus=${payload?.modus || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_SUCCESS, response.data || {});
          }
        }
      })
    },
}