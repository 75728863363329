<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Vermittlerwechsel Status Bearbeiten" />

    <div class="box__container" tid="787c911e-ad46-44ae-bfcb-7bdff06ebc05" v-if="formData && dataForEditing && dataForEditing.depotnrInputs">
      <ComboBox
          label="Gesellschaft"
          v-model="dataForEditing.selectedGesellschaft" 
          :values="gesellschaften"
          @change="getBeispielKontonr($event)">
      </ComboBox>    

       <InputField
        label="Depotnummer"
        v-model="dataForEditing.depotnrInputs[0].value">
      </InputField>    

      <div v-for="(input, count) in dataForEditing.depotnrInputs.slice(1, dataForEditing.depotnrInputs.length)" :key="count">
        <InputField
          label="weitere"
          :v-model="'formData.depotnummer_' + count">
        </InputField>        
      </div>

       <InputToggleSwitch v-model="formData.mitDepotnrUnbekannt" label="die Depotnummer ist nicht bekannt" inLineLabel suppressValidationMessage/>  


      <InputField
        label="Beispiel"
        :disabled="true"
        v-model="beispielkontonr.value">
      </InputField>    


             
       
           
    </div>    


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERMITTLERWECHSEL_STATUS_TYPES from "../../store/vermittlerwechselstatus/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    InputToggleSwitch,
  },
  data: function () {
      return {
        resultCount: null,
        loading: false,
        formData : null,
      }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedEintrag: VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.SELECTED_EINTRAG,
      gesellschaften: VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.GESELLSCHAFTEN,
      dataForEditing: VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.DATA_FOR_EDITING,
      beispielkontonr: VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.BEISPIELKONTONR,
    }),
       
  },
  mounted() {
    this.formData = this.selectedEintrag;

       const payload = {
        antragEinreichungBarcode: this.selectedEintrag?.editActionMetadata?.find(ea => ea.key =='PARAMETER_ANTRAG_EINREICHUNG_BARCODE')?.value,
      };

      this.$store.dispatch(
              VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.GET_DATA_FOR_EDITING, payload
            ).then(() => {
                this.loading = false;
            }).then(() => {
                this.getBeispielKontonr(this.dataForEditing.gesellschaft);
            });      


      
  },
  methods: {
    async saveBeforeLeave() {

      const payload = {
        selectedGesellschaft: this.dataForEditing?.selectedGesellschaft,
        antragEinreichungBarcode: this.selectedEintrag?.editActionMetadata?.find(ea => ea.key =='PARAMETER_ANTRAG_EINREICHUNG_BARCODE')?.value,
        inputs: [],
        mitDepotnrUnbekannt: this.formData.mitDepotnrUnbekannt,
      };

      for (let index = 0; index < this.dataForEditing.depotnrInputs.length; index++) {
        const input = this.dataForEditing.depotnrInputs[index];
        payload.inputs.push({key: 'INPUT_DEPOTNR'+index, value: input?.value});
      }

      // console.log(payload);

      this.$store.dispatch(
              VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.PERSIST_ROW, payload
            );  
    },
    getBeispielKontonr(gesellschaft) {
      if (gesellschaft) {
        const payload = {
          gesellschaft,
        };
  
        this.$store.dispatch(
                VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.GET_BEISPIELKONTONR, payload
              );        

      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlerwechsel Status Bearbeiten', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    await this.saveBeforeLeave();

    next();
  },


    
}
</script>