var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "other-products-search" },
    [
      !_vm.hideNavigation
        ? [
            _c("OptionMenu", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                defaultMenu: _vm.$appNavigation.currentOptionMenu
              }
            }),
            _c("PageHeaderTitleNavigation", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                title: _vm.pageMetadata.title,
                actions: _vm.headerActions
              },
              on: {
                "action-ADD": function($event) {
                  return _vm.addNewOtherProduct()
                }
              }
            })
          ]
        : _vm._e(),
      _c("BaseFilter", {
        attrs: {
          title: _vm.pageMetadata.searchTitle,
          metadata: _vm.searchMetadata,
          hasSmartSearch: "",
          configFilter: _vm.configFilter,
          showSaveButton: false,
          openFilterOnMount: false
        },
        on: {
          onFilter: function($event) {
            return _vm.search($event)
          }
        }
      }),
      _vm.pinName
        ? _c("HandleTablePin", { attrs: { keyOfPIN: _vm.pinName } })
        : _vm._e(),
      _vm.filtered
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.loading && _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "ad362da0-1e00-4c36-a734-1455868eb98e",
                      title: _vm.pageMetadata.title,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 25,
                      exportConfig: _vm.exportConfig,
                      mobileConfig: _vm.mobileConfig
                    },
                    on: {
                      "click-produktartText": function($event) {
                        return _vm.editOtherProduct($event)
                      },
                      clickRow: function($event) {
                        return _vm.editOtherProduct($event)
                      },
                      "action-EDIT": function($event) {
                        return _vm.editOtherProduct($event)
                      },
                      "action-DELETE": _vm.openDeleteModal
                    }
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.pageMetadata.title }
                  })
                : _c("NoData", { attrs: { title: _vm.pageMetadata.title } })
            ],
            1
          )
        : _vm._e(),
      _c("BaseModal", {
        ref: "otherProductDeleteModal",
        attrs: { size: "sm", modalTitle: "Vertrag löschen" },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteOtherProduct()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Wollen Sie das Produkt " +
                    _vm._s(_vm.otherProductToDelete.produktbez || "") +
                    " " +
                    _vm._s(_vm.otherProductToDelete.sprodNr || "") +
                    " wirklich löschen? "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }